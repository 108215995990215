import { IReduxStore } from '@/plugins/dynamic_forms/declarations/IReduxStore'

/**
 * https://redux.js.org/api/store
 * Использовать крайне осторожно!
 */
export class FormBuilderReduxStore {
  private readonly reduxStore: IReduxStore

  constructor (formBuilder = 'current') {
    try {
      this.reduxStore = application.form_builder[formBuilder].store
    } catch (e) {
      throw new Error(`Не найден существующий ${formBuilder} store`)
    }
  }

  getState () {
    return this.reduxStore.getState()
  }

  dispatch (type: string, payload: Record<string, any>) {
    this.reduxStore.dispatch({ type, payload })
  }

  subscribe (callback: (reduxStore: any) => void) {
    const unsubscribe = this.reduxStore.subscribe(() => callback(this.reduxStore))

    return { unsubscribe }
  }

  replaceReducer (reducer: Record<string, any>) {
    this.reduxStore.replaceReducer(reducer)
  }
}
