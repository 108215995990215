import { MClinicModel } from '@/_baseModels/MClinicModel'
import { IClinic } from '@/_declarations/IClinic'
import { MClinicPresenter } from '@/_api/MClinicApi/MClinicPresenter'
import { IApiErrors } from '@/_declarations/IApiErrors'

export class Clinic extends MClinicModel {
  submit (): Promise<IClinic | IApiErrors> {
    return new MClinicPresenter()
      .submit(this.getAttributes())
      .then((clinic) => {
        if (clinic?.id) {
          this.setValue('id', clinic.id)
          this.saveTaxRate()
        }

        return clinic
      })
  }
}
