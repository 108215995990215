<template>
  <m-panel
    class="measure-units__editor-wrapper"
    icon="measureUnits"
    use-close-button
    :title="title"
    :body-loading="loading"
    @close="visitShow"
  >
    <m-input
      :value="measureUnit.title"
      :label="t('title')"
      :disabled="isEditMode"
      required
      validator-name="title"
      @registerValidator="onRegisterValidator"
      @input="measureUnit.setValue('title', $event)"
    />

    <m-input
      :value="measureUnit.shortTitle"
      :label="t('abbr')"
      :disabled="isEditMode"
      required
      validator-name="abbr"
      @registerValidator="onRegisterValidator"
      @input="measureUnit.setValue('shortTitle', $event)"
    />

    <div class="measure-units__editor-buttons">
      <m-button-submit
        :class="{ invisible: isEditMode }"
        @click="onSubmit"
      />

      <m-button-delete
        v-show="isEditMode"
        :disabled="disabledConfig"
        @yes="onDelete"
      />
    </div>

    <replace-measure-unit-modal
      @registerApi="replaceMeasureUnitModalApi = $event"
    />
  </m-panel>
</template>

<script lang="ts">
import MInput from '@/vue_present/_base/inputs/MInput/MInput.vue'
import MButtonSubmit from '@/vue_present/_base/buttons/MButtonSubmit/MButtonSubmit.vue'
import MPanel from '@/vue_present/_base/MPanel/MPanel.vue'
import MButtonDelete from '@/vue_present/_base/buttons/MButtonDelete/MButtonDelete.vue'
import ReplaceMeasureUnitModal from './ReplaceMeasureUnitModal.vue'
import { MEASURE_UNITS_ROUTES_NAMES } from '../routes/measureUnitsRoutesNames'
import { ValidationParentMixin } from '@/vue_present/mixins/ValidationParentMixin'
import { i18nScopeMixin } from '@/vue_present/mixins/i18nScopeMixin'
import { MeasureUnitApi } from '../entities/MeasureUnitApi'
import { defineComponent } from 'vue'
import { IReplaceMeasureUnitModalApi } from '../interfaces/interfaces'
import { TCanManage } from '@/vue_present/mixins/DisabledMixinTypes'
import { Route } from 'vue-router'
import { editorDisabledConfigs as disabledConfigs } from '../const/const'
import { SpinnerHolder } from '@/vue_components/mixins/spinner_holder'

const visitShowArg = { name: MEASURE_UNITS_ROUTES_NAMES.SHOW }

export default defineComponent({
  name: 'MeasureUnitsEditor',

  components: {
    MInput,
    MButtonSubmit,
    MButtonDelete,
    MPanel,
    ReplaceMeasureUnitModal,
  },

  mixins: [
    ValidationParentMixin,
    i18nScopeMixin,
    SpinnerHolder,
  ],

  beforeRouteEnter (to, _, next) {
    next((vm) => vm.onRouteChange(to))
  },

  beforeRouteUpdate (to, _, next) {
    this.onRouteChange(to)
    next()
  },

  emits: [
    'onSubmit',
    'onDelete',
  ],

  data () {
    return {
      isEditMode: false,
      measureUnit: new MeasureUnitApi(),
      replaceMeasureUnitModalApi: null as IReplaceMeasureUnitModalApi,
    }
  },

  computed: {
    title (): string {
      return this.isEditMode
        ? this.t$('editing')
        : this.t$('creating')
    },

    disabledConfig (): TCanManage | string {
      return !this.loading && this.measureUnit.isEditable
        ? disabledConfigs.active
        : disabledConfigs.disabled
    },
  },

  created () {
    this.setI18nScope('measureUnits')
  },

  methods: {
    onRouteChange ({ name, params: { id } }: Route) {
      this.resetValidations()

      const currentRouteIsNew = name === MEASURE_UNITS_ROUTES_NAMES.NEW
      this.isEditMode = !currentRouteIsNew

      currentRouteIsNew
        ? this.clearMeasureUnit()
        : this.fetchMeasureUnit(id)
    },

    clearMeasureUnit () {
      this.measureUnit = new MeasureUnitApi()
    },

    /* Закрытие редактора */

    visitShow () {
      this.$router.push(visitShowArg)
    },

    visitShowWithReplace () {
      this.$router.replace(visitShowArg)
    },

    /* Запросы */

    async fetchMeasureUnit (id: number) {
      const errors = await this.withSpinnerSimple(this.measureUnit.fetch(id))
      if (errors) { this.visitShow() }
    },

    async onSubmit () {
      if (this.hasErrors()) { return }

      const errors = await this.withSpinnerSimple(this.measureUnit.submit())
      if (errors) { return }

      this.handleSuccessSubmit()
    },

    async onDelete () {
      const {
        cancel,
        idOfMeasureUnitForReplace,
      } = await this.replaceMeasureUnitModalApi.open(this.measureUnit.id)

      if (cancel) { return }

      const errors = await this.withSpinnerSimple(
        this.measureUnit.destroy(idOfMeasureUnitForReplace)
      )

      if (errors) { return }

      this.handleSuccessDelete()
    },

    /* Обработчики успешных запросов */

    handleSuccessSubmit () {
      this.clearMeasureUnit()
      this.$emit('onSubmit')
    },

    handleSuccessDelete () {
      this.visitShowWithReplace()
      this.$emit('onDelete')
    },
  },
})
</script>
