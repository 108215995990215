<template>
  <m-select-lazy
    class="reusable-clinical-guidelines-list"
    :fetch-method="(params) => api.fetch({ ...params, diseaseIds })"
    :placeholder="t('clinicalGuidelines.searchPlaceholder')"
    :additional-displayed-filter="additionalDisplayedFilter"
    use-first-time-fetch
    use-first-time-fetch-on-open
    filterable
    option-notice="diseases"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <template #optionNotice="{ optionNotice, option }">
      <span class="m-option__notice prompt-notice reusable-clinical-guidelines-list__option-notice">
        {{ option[optionNotice] }}
      </span>
    </template>
  </m-select-lazy>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import MSelectLazy from '@/vue_present/_base/MSelectLazy/MSelectLazy.vue'
import {
  ReusableClinicalGuidelinesListApi,
} from '@/vue_present/Reuse/Lists/ClinicalGuidelines/api/ReusableClinicalGuidelinesListApi'
import {
  IClinicalGuidelineListItem,
} from '@/vue_present/Reuse/Lists/ClinicalGuidelines/interfaces/IClinicalGuidelineListItem'

export default defineComponent({
  name: 'ReusableClinicalGuidelinesList',

  components: { MSelectLazy },

  props: {
    diseaseIds: { type: Array as PropType<number[]>, default: () => ([]) },
  },

  data () {
    return {
      api: new ReusableClinicalGuidelinesListApi(),
    }
  },

  methods: {
    additionalDisplayedFilter (searchQueryInLowerCase: string, item: IClinicalGuidelineListItem) {
      return item.diseases
        .toLowerCase()
        .includes(searchQueryInLowerCase)
    },
  },
})
</script>
