<template><span /></template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import { ISemdSource } from '@/vue_apps/Semds/declarations/ISemdSource'
import { SemdListItem } from '@/vue_apps/Semds/entities/list/SemdListItem'
import {
  ISemdsSourceConnectorAPI,
  ISemdsSourceConnectorAPIParams,
} from '@/vue_apps/Semds/SemdSourceConnector/declarations/semdsSourceConnectorDeclarations'
import { baseSemdSourceEditCheck } from '@/vue_apps/Semds/SemdSourceConnector/baseSemdSourceEditCheck'

export default defineComponent({
  name: 'SemdsSourceConnector',

  props: {
    source: { type: Object as PropType<ISemdSource>, required: true },
    semds: { type: Array as PropType<SemdListItem[]>, required: true, default: () => [] },
  },

  emits: [
    'registerApi',
  ],

  computed: {
    lastSemd (): SemdListItem {
      return this.semds[0] || null
    },
  },

  created () {
    this.$emit('registerApi', this.__getApi.bind(this)())
  },

  methods: {
    /**
     * отловить событие: @registerApi="connectorApi = $event"
     * положить в data компонента родителя: connectorApi: {} as ISemdsSourceConnector,
     */
    __getApi (): ISemdsSourceConnectorAPI {
      return {
        isLastSemdOutdated: () => this.lastSemd?.isOutdatedVersion() || false,
        isLastSemdSigned: () => this.lastSemd?.isSigned() || false,
        isLastSemdSuccessfullySent: () => this.lastSemd?.isSuccessFullySent() || false,
        isLastSemdRegisteredInEgisz: () => this.lastSemd?.isRegisteredInEgisz() || false,
        beforeEdit: this.beforeEdit,
      }
    },

    async beforeEdit (params?: ISemdsSourceConnectorAPIParams): Promise<{ cancel: boolean }> {
      if (params?.beforeChecks) {
        return await params.beforeChecks(this.source, this.semds)
      }

      return baseSemdSourceEditCheck(this.source, this.lastSemd)
    },
  },
})
</script>
