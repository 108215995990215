import {
  ISemdSelectProps,
} from '@/vue_apps/ProtocolsApp/_SemdProtocolEditor/_SemdEntities/SemdNsiDictionary/SemdNsiDictionary'
import { INsiEntry } from '@/_declarations/INsiEntry'
import { SemdEntity } from '@/vue_apps/ProtocolsApp/_SemdProtocolEditor/_SemdEntities/_semdBaseEntities/SemdEntity'

export declare interface ISemdMeasurementProps extends ISemdSelectProps {
  unitsTitle: string
}

export declare interface ISemdMeasurementValue {
  amount: number
  units: INsiEntry
}

export class SemdNsiMeasurement extends SemdEntity {
  nsiDictionary: string

  nsiDictionaryVersion?: string

  permittedValues = undefined

  unitsTitle: string

  value: ISemdMeasurementValue = {
    amount: 0,
    units: null,
  }

  constructor (props: ISemdMeasurementProps) {
    super(props)

    this.unitsTitle = props.unitsTitle

    this.nsiDictionary = props.nsiDictionary
    this.nsiDictionaryVersion = props.nsiDictionaryVersion
    this.permittedValues = props.permittedValues
  }

  drawElementValue (value: ISemdMeasurementValue) {
    if (!(value.amount > 0) || !value.units?.id) {
      this.element.innerHTML = ''

      return
    }

    this.element.innerHTML = `${value.amount} ${value.units?.title || ''}`
  }

  getContent (): ISemdMeasurementValue {
    return this.value
  }

  isValid (): boolean {
    if (this.isOptional) { return true }

    return Boolean(this.value?.amount && this.value?.units)
  }
}
