export const wsUserChannel = {
  namespaced: true,

  state: {
    isHandshakeComplete: false,
  },

  getters: {
    GET_IS_HANDSHAKE_COMPLETE: (state) => state.isHandshakeComplete,
  },

  mutations: {
    SET_IS_HANDSHAKE_COMPLETE (state, isHandshakeComplete) {
      state.isHandshakeComplete = isHandshakeComplete
    },
  },
}
