import {
  GRPCReceiverSendStrategyAbstract,
} from '@/_api/_classes/GRPC/Receiver/SendStrategy/GRPCReceiverSendStrategyAbstract'
import { Callback } from '@/_api/decorators/callback'
import { Api } from '@/_api/decorators/api/Api'
import { ICRUDReturn } from '@/_api/decorators/api/interfaces/ICRUDReturn'

export class GRPCReceiverSendStrategyHTTP<TData> extends GRPCReceiverSendStrategyAbstract<TData, Promise<void>> {
  @Callback('context.fetchCallback', 'context.requestErrorHandler')
  @Api.post('context.src')
  @Api.serverAdapter('context.toServer')
  send (payload: TData) {
    this.context.startLoading()

    return { payload } as ICRUDReturn as Promise<void>
  }
}
