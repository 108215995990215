import { MPresenterBase } from '@/_api/_requests/MPresenterBase'
import { camelToSnake, snakeToCamel } from '@/_api/_requests/helpers'
import { MRequestError } from '@/_api/_requests/MRequestError'
import {
  entriesListAdapter,
} from '@/vue_apps/MedicalRecords/MedicalRecordsTabs/components/EntriesTab/api/entriesListAdapter'
import { MRequestSuccess } from '@/_api/_requests/MRequestSuccess'
import { MRequestNotification } from '@/_api/_requests/MRequestNotification'
import { IApiErrors } from '@/_declarations/IApiErrors'
import { ICRUDReturn } from '@/_api/decorators/api/interfaces/ICRUDReturn'
import { Api } from '@/_api/decorators/api/Api'

export class MEntryPresenter extends MPresenterBase {
  constructor () {
    super({
      entity: 'entry',
      location: 'MEntryPresenter',
      routes: {
        all: Routes.api_internal_medical_records_entries_path,
        one: Routes.entry_path,
        list: Routes.list_api_internal_medical_records_entries_path,
      },
    })
  }

  /**
   * @param {number} entryTypeId
   * @param {number} clientId
   * @returns {Promise<boolean>}
   */
  async checkDuplicates (entryTypeId, clientId) {
    return this.post({
      url: Routes.duplicate_validation_entries_path(),
      data: camelToSnake({ entryTypeId, clientId }),
    }).promise
      .catch(MRequestError.onRequest(null))
  }

  async list (listData, config: { toClientAdapter?: Function } = { }) {
    return super.list(listData, {
      ...config,
      toJson: true,
      toClientAdapter: config.toClientAdapter || entriesListAdapter.toClient,
      toServerAdapter: entriesListAdapter.toServer,
    })
  }

  async changeState (id, state) {
    return this.update({ id, state }, {
      toServerAdapter: (entry) => ({ entry }),
      toJson: true,
    })
  }

  /**
   * @param clientId
   * @param entriesParams
   * @returns {Promise<{
   *   errors?: boolean,
   *   successfullySavedEntryTypeIds: number[],
   *   failedEntriesErrors: Record<number, {errors: string[]}>[]
   * }>}
   */
  async createByEntriesParams ({ clientId, entriesParams }) {
    const options = {
      url: Routes.create_from_entry_type_entries_path(),
      data: JSON.stringify(camelToSnake({ clientId, entriesParams })),
      dataType: 'json',
      contentType: 'application/json',
    }

    const notification = new MRequestNotification(
      this.location,
      'createMessage',
      'entries'
    )

    return this.post(options).promise
      .then((data) => {
        const { failedEntriesErrors } = data
        if (failedEntriesErrors?.length === entriesParams.length) {
          throw data
        }

        if (failedEntriesErrors?.length) {
          // @ts-ignore
          Utils.reportWarning(notification.location, t('transferSuccessPartially'))()

          return Promise.resolve({
            errors: true,
            ...data,
          })
        }

        return MRequestSuccess.withNotify(undefined, notification)(data)
      })
      .catch((error) => {
        const errorData = error?.responseJSON || error

        return MRequestError.withDefault(
          snakeToCamel({
            errors: true,
            ...errorData,
          }),
          notification
        )(error)
      })
  }

  @Api.notify('attachMessage', 'protocol')
  @Api.post(Routes.attach_protocol_api_internal_entries_path)
  attachProtocols (entryId: number, protocolIds: number[]) {
    return {
      payload: {
        entryId,
        protocolIds,
      },
    } as ICRUDReturn as Promise<void | IApiErrors>
  }

  @Api.notify('detachMessage', 'protocol')
  @Api.delete(Routes.detach_protocol_api_internal_entries_path)
  detachProtocols (entryId: number, protocolIds: number[]) {
    return {
      payload: {
        entryId,
        protocolIds,
      },
    } as ICRUDReturn as Promise<void | IApiErrors>
  }
}
