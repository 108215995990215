<template>
  <div class="protocol__form flex gap-indent-2">
    <!-- Мета протокола -->
    <div class="protocol__form-section flex flex-column flex-basis-50">
      <m-input
        v-tooltip="protocol.title"
        :label="t('title')"
        :value="protocol.title"
        required
        validator-name="title"
        @input="updateField('title', $event)"
        @registerValidator="$registerValidator($event)"
      />

      <m-select
        v-if="egiszModuleEnabled"
        :disabled="protocol.semdTypeChangingProhibited"
        :value="protocol.semdType"
        :label="t('semds.semdTypeLabel')"
        :items="semdTypes"
        :placeholder="t('semds.semdType.default')"
        full-width
        use-custom-result="simple"
        @change="$emit('semdTypeChange', $event)"
      />

      <m-date-picker
        :label="t('common.date')"
        :value="protocol.date"
        value-format="yyyy-MM-dd"
        required
        validator-name="date"
        :clearable="false"
        @change="updateField('date', $event)"
        @registerValidator="$registerValidator($event)"
      />

      <reusable-doctor-clinics-list
        full-width
        :value="protocol.clinic"
        required
        validator-name="clinic"
        @onClinicChange="updateField('clinic', $event)"
        @registerValidator="$registerValidator($event)"
      />

      <reusable-doctors-list
        :value="protocol.doctor"
        required
        validator-name="doctor"
        full-width
        option-label="fullName"
        m-fixed-height
        @onDoctorChange="updateField('doctor', $event)"
        @registerValidator="$registerValidator($event)"
      />

      <m-select-lazy
        v-show="false"
        :value="protocol.entries"
        :label="t('relatedEntries')"
        :fetch-method="() => {}"
        full-width
        multiple
      />

      <slot name="entries" />
    </div>

    <!-- Инструменты печати и прочие чекбоксы -->
    <div class="protocol__form-section flex flex-column gap-indent-small flex-basis-50">
      <m-checkbox
        :label="t('print_entry_title')"
        :value="protocol.printEntryTitle"
        @input="updateField('printEntryTitle', $event)"
      />

      <m-checkbox
        :label="t('print_entry_info')"
        :value="protocol.printEntryInfo"
        @input="updateField('printEntryInfo', $event)"
      />

      <m-checkbox
        :label="t('print_clinic_header')"
        :value="protocol.printClinicHeader"
        @input="updateField('printClinicHeader', $event)"
      />

      <m-checkbox
        :label="t('print_doctor_signature')"
        :value="protocol.printDoctorSignature"
        @input="updateField('printDoctorSignature', $event)"
      />

      <m-checkbox
        v-if="clientWidgetEnabled"
        :label="t('showInClientWidget')"
        :value="protocol.onlineAccess"
        @input="updateField('onlineAccess', $event)"
      />
    </div>

    <slot />
  </div>
</template>

<script>
import { ProtocolApi } from '@/vue_apps/ProtocolsApp/entities/ProtocolApi'
import MDatePicker from '@/vue_present/_base/inputs/MDatePicker/MDatePicker.vue'
import ReusableDoctorClinicsList from '@/vue_present/Reuse/Lists/Doctors/ReusableDoctorClinicsList.vue'
import MInput from '@/vue_present/_base/inputs/MInput/MInput.vue'
import MCheckbox from '@/vue_present/_base/inputs/MCheckbox/MCheckbox.vue'
import MSelectLazy from '@/vue_present/_base/MSelectLazy/MSelectLazy.vue'
import ReusableDoctorsList from '@/vue_present/Reuse/Lists/Doctors/ReusableDoctorsList.vue'
import MSelect from '@/vue_present/_base/inputs/MSelect/MSelect.vue'
import { getProtocolSemdTypes } from '@/vue_apps/ProtocolsApp/consts/getProtocolSemdTypes'

export default {
  name: 'ProtocolForm',

  components: {
    MSelect,
    ReusableDoctorsList,
    MSelectLazy,
    MCheckbox,
    MInput,
    ReusableDoctorClinicsList,
    MDatePicker,
  },

  props: {
    protocol: ProtocolApi,
  },

  emits: [
    'semdTypeChange',
  ],

  data () {
    return {
      semdTypes: getProtocolSemdTypes(),
    }
  },

  computed: {
    clientWidgetEnabled () {
      return gon.application.client_widget_enabled
    },

    egiszModuleEnabled () {
      return gon.application.egisz_module_enabled
    },
  },

  methods: {
    async updateField (field, value) {
      this.protocol.setValue(field, value)
    },
  },
}
</script>
