import UserException from '../components/exeptions'
import { FormBuilderReduxStore } from '@/vue_apps/ProtocolsApp/entities/reduxStore/FormBuilderReduxStore'
import Component from '@/plugins/dynamic_forms/components/base_component'
import Container from '@/plugins/dynamic_forms/_core/container/Container'

export interface IBaseTemplateComponent {
  init: (store: any, components: Record<string, any>) => void
}

export default class BaseTemplateBuilder {
  _components: Record<string, Component> = {}

  container: Container = new Container()

  store: Partial<FormBuilderReduxStore>

  constructor (store) {
    this.store = store
  }

  /**
   * @public
   * @param name
   * @param component
   */
  addComponent (name, component) {
    this._components[name] = component
  }

  removeComponent (name) {
    this._components[name] = null
    delete this._components[name]
  }

  /**
   * Run application
   * @public
   */
  run () {
    for (const component in this._components) {
      this._components[component].init(this.store, this._components)
    }
  }

  /**
   * @public
   * @abstract
   */
  initComponents () {
    throw new UserException('initComponents is abstract , please define method')
  }

  freeComponents () {}

  destroy () {
    return this.freeComponents()
  }

  /**
   * @public
   * @abstract
   */
  create () {
    throw new UserException('create is abstract , please define method')
  }

  /**
   *
   */
  setMapping () {}
}
