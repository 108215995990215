import { MPresenterBase } from '@/_api/_requests/MPresenterBase'
import { IFileUploaderFile } from '@/_api/_classes/FileUploader/interfaces/IFileUploaderFile'
import { IFIleUploaderConfig } from '@/_api/_classes/FileUploader/interfaces/IFIleUploaderConfig'

export class FileUploader<Presenter extends MPresenterBase> {
  private getNextId: () => number = Utils.getIdGenerator()

  private readonly presenter: Presenter

  private readonly notifyForTooLarge: boolean = true

  private readonly dontAppendTooLarge: boolean = true

  private readonly submitPropName: string = 'submit'

  private readonly filesPayloadPropName: string = 'files'

  private readonly injectFilesToPayload: boolean = true

  private files: Record<string, IFileUploaderFile> = {}

  constructor (presenter: Presenter, config: IFIleUploaderConfig = {}) {
    this.presenter = presenter

    this.notifyForTooLarge = config.notifyForTooLarge ?? true

    this.dontAppendTooLarge = config.dontAppendTooLarge ?? true

    if (config.submitPropName) this.submitPropName = config.submitPropName

    if (config.filesPayloadPropName) this.filesPayloadPropName = config.filesPayloadPropName

    this.injectFilesToPayload = config.injectFilesToPayload ?? true
  }

  getArr () {
    return Object.values(this.files)
  }

  getKeys () {
    return Object.keys(this.files)
  }

  append (fileList: FileList | File[]) {
    const newFiles = Object.values(fileList)
      .reduce((acc, file) => {
        const isTooLarge = this.isTooLarge(file)

        if (this.dontAppendTooLarge && isTooLarge) {
          return acc
        }

        acc[this.getNextId()] = {
          file,
          name: file.name,
          objectURL: URL.createObjectURL(file),
          type: file.type,
          size: file.size,
        }

        return acc
      }, {})

    this.files = Object.assign(this.files, newFiles)
  }

  remove (keys: string[]) {
    keys.forEach((key) => {
      URL.revokeObjectURL(this.files[key].objectURL)

      delete this.files[key]
    })

    this.files = { ...this.files }
  }

  purge () {
    this.remove(this.getKeys())
  }

  submit (payload: Record<string, unknown>, config = {}) {
    const modifiedPayload = this.injectFilesToPayload
      ? {
          ...payload,
          [this.filesPayloadPropName]: this.getArr(),
        }
      : payload

    return this.presenter[this.submitPropName](modifiedPayload, config)
      .finally(() => this.purge())
  }

  getTooLargeFiles () {
    return this.getArr()
      .filter(({ size }) => size > Utils.getMaxFileSize('Bit'))
  }

  hasFiles () {
    return Boolean(this.getKeys().length)
  }

  private isTooLarge (file: File) {
    const isTooLarge = file.size > Utils.getMaxFileSize('Bit')

    if (this.notifyForTooLarge && isTooLarge) {
      Utils.reportError(
        'fileUploader',
        t('utilsReport.errors.tooLargeFileMessage', { entity: file.name })
      )()
    }

    return isTooLarge
  }
}
