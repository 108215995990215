<template>
  <div class="clinical-guidelines-catalog-import-modal">
    <m-modal
      :dialog-title="t$('title')"
      :visible="modalVisible"
      :disabled="loading"
      modal-title-icon="import"
      width="1100px"
      @close="closeModal()"
    >
      <div
        v-loading="loading"
        class="clinical-guidelines-catalog-import-modal__body"
      >
        <div class="flex gap-indent-small">
          <m-button-upload
            type="primary"
            :accept="accept"
            @files="onFiles"
          />

          <m-alert
            v-show="fileName"
            :text="fileName"
            type="info"
            size="small"
            show-icon
            full-width
          />
        </div>

        <m-prompt-notice
          v-show="showDuplicateWarning"
          :text="t$('submitTooltip.duplicateWarning')"
          type="alert"
        />

        <m-si-generator
          :si-generator-schema="schema"
          :items="clinicalGuidelineImportItems"
          @onSetSelectedItems="onSetSelectedItems"
        />

        <m-switch
          :value="clinicalGuidelineImport.replaceExisting"
          :active-text="t$('replaceExistingText')"
          @change="clinicalGuidelineImport.setReplaceExisting($event, selectedItems)"
        />
      </div>

      <template #footer-left>
        <m-button
          v-tooltip="submitTooltip"
          :text="t('import')"
          type="success"
          icon="import"
          :disabled="submitDisabled"
          @click="onSubmit"
        />
      </template>
    </m-modal>

    <slot
      name="button"
      :open-modal="openModal"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { i18nScopeMixin } from '@/vue_present/mixins/i18nScopeMixin'
import { ModalMixin } from '@/vue_present/mixins/ModalMixins/ModalMixin'
import MModal from '@/vue_present/_base/MModal/MModal.vue'
import MButtonUpload from '@/vue_present/_base/buttons/MButtonUpload/MButtonUpload.vue'
import MSiGenerator from '@/vue_present/_base/Tables/MSiGenerator/MSiGenerator.vue'
import MButton from '@/vue_present/_base/buttons/MButton/MButton.vue'
import { MEDIA_TYPES } from '@/helpers/mediaTypes'
import {
  getClinicalGuidelineImportSchema,
} from '@/vue_apps/catalogs_root/ClinicalGuidelinesCatalog/consts/clinicalGuidelineImportSchema'
import MSwitch from '@/vue_present/_base/inputs/MSwitch/MSwitch.vue'
import {
  ClinicalGuidelineImportApi,
} from '@/vue_apps/catalogs_root/ClinicalGuidelinesCatalog/classes/ClinicalGuidelineImport/ClinicalGuidelineImportApi'
import {
  IClinicalGuidelineImportJson,
} from '@/vue_apps/catalogs_root/ClinicalGuidelinesCatalog/interfaces/IClinicalGuidelineImport'
import { SpinnerHolder } from '@/vue_components/mixins/spinner_holder'
import MAlert from '@/vue_present/_base/MAlert/MAlert.vue'
import { tScoped } from '@/helpers/i18nHelpers'
import {
  ClinicalGuidelineImportItem,
} from '@/vue_apps/catalogs_root/ClinicalGuidelinesCatalog/classes/ClinicalGuidelineImport/ClinicalGuidelineImportItem'
import MPromptNotice from '@/vue_present/_base/MPromptNotice/MPromptNotice.vue'
import { GRPCTransmitterJSON } from '@/_api/_classes/GRPC/Transmitter/GRPCTransmitterJSON'
import {
  clinicalGuidelineImportAdapter,
} from '@/vue_apps/catalogs_root/ClinicalGuidelinesCatalog/api/clinicalGuidelineImportAdapter'
import { NotifyMixin } from '@/vue_present/mixins/NotifyMixin'
import { EWSChannels } from '@/services/declarations/EWSChannels'

export default defineComponent({
  name: 'ClinicalGuidelinesCatalogImportModal',

  components: {
    MPromptNotice,
    MAlert,
    MSwitch,
    MButton,
    MSiGenerator,
    MButtonUpload,
    MModal,
  },

  mixins: [
    i18nScopeMixin,
    ModalMixin,
    SpinnerHolder,
    NotifyMixin,
  ],

  data () {
    return {
      clinicalGuidelineImport: new ClinicalGuidelineImportApi(),
      gRPCTransmitterJSON: new GRPCTransmitterJSON({
        wsChannel: EWSChannels.CLINICAL_GUIDELINES,
        wsAction: 'receive',
        wsMetaAction: 'import',
        adapter: clinicalGuidelineImportAdapter,
        successCallback: this.successCallback.bind(this),
        errorCallback: this.errorCallback.bind(this),
      }),
      schema: getClinicalGuidelineImportSchema(),
      accept: [MEDIA_TYPES.JSON],
      selectedItems: [],
      fileName: null,
    }
  },

  computed: {
    clinicalGuidelineImportItems () {
      return Object.values(this.clinicalGuidelineImport.clinicalGuidelines)
    },

    submitDisabled (): boolean {
      return this.loading || !this.selectedItems.length || this.showDuplicateWarning
    },

    submitTooltip (): string {
      const ts = tScoped('clinicalGuidelines.catalog.import.submitTooltip')

      if (!this.clinicalGuidelineImportItems.length) {
        return ts('importFileThatContainsClinicalGuidelines')
      }

      if (!this.selectedItems.length) {
        return ts('selectClinicalGuidelinesToImport')
      }

      if (this.showDuplicateWarning) {
        return ts('duplicateWarning')
      }

      return null
    },

    showDuplicateWarning (): boolean {
      if (!this.clinicalGuidelineImport.replaceExisting) { return false }

      return this.clinicalGuidelineImportItems
        .some((item: ClinicalGuidelineImportItem) => item.duplicateWarningCell)
    },
  },

  created () {
    this.setI18nScope('clinicalGuidelines.catalog.import')
  },

  methods: {
    closeModal (forceClose = false) {
      if (this.loading && !forceClose) { return }
      this.modalVisible = false
      this.selectedItems = []
      this.fileName = null
      this.clinicalGuidelineImport = new ClinicalGuidelineImportApi()
    },

    onSetSelectedItems (selectedItems: ClinicalGuidelineImportItem[]) {
      this.selectedItems = selectedItems
      this.clinicalGuidelineImport.setDuplicateWarning(selectedItems)
    },

    onFiles (fileList: FileList) {
      const file = fileList.item(0)
      if (!file) { return }

      this.loading = true
      const fileReader = new FileReader()

      fileReader.onload = (event: ProgressEvent<FileReader>) => {
        this.fileName = file.name
        this.selectedItems = []
        this.clinicalGuidelineImport.resetClinicalGuidelines()
        this.loading = false
        const { readyState, result } = event.target

        if (readyState !== FileReader.DONE) {
          return this.showErrorNotify('openMessage', 'attachment')
        }

        const parsedJson = JSON.parse(result as string) as IClinicalGuidelineImportJson
        if (!parsedJson.clinicalGuidelines) { return this.showErrorNotify('openMessage', 'attachment') }
        this.clinicalGuidelineImport.fillClinicalGuidelines(parsedJson)
      }

      fileReader.readAsText(file)
    },

    onSubmit () {
      this.loading = true
      this.gRPCTransmitterJSON.send({
        replaceExisting: this.clinicalGuidelineImport.replaceExisting,
        clinicalGuidelines: this.selectedItems,
      })
    },

    successCallback () {
      this.loading = false
      this.showSuccessNotify('createMessage', 'clinicalGuidelines')
      this.$pubSub.emit('clinicalGuidelineImport')
      this.closeModal(true)
    },

    errorCallback () {
      this.showErrorNotify('createMessage', 'clinicalGuidelines')
      this.loading = false
    },
  },
})
</script>
