import { FRONT_BOOK_ROUTES, getBaseName, getBaseRoute } from '@/vue_present/_FrontBook/router/routeNames'
import FrontBookIntro from '@/vue_present/_FrontBook/pages/FrontBookPageIntro'
import FrontBookPageMButton from '@/vue_present/_FrontBook/pages/FrontBookPageMButton'
import FrontBookPageMInput from '@/vue_present/_FrontBook/pages/FrontBookPageMInput'
import FrontBookPageMSelect from '@/vue_present/_FrontBook/pages/FrontBookPageMSelect'
import FrontBookPageMIcon from '@/vue_present/_FrontBook/pages/FrontBookPageMIcon'
import FrontBookPageDisabledMixin from '@/vue_present/_FrontBook/pages/FrontBookPageDisabledMixin'
import FrontBookPagePaginationMixin from '@/vue_present/_FrontBook/pages/FrontBookPagePaginationMixin'
import FrontBookPageValidationMixins from '@/vue_present/_FrontBook/pages/FrontBookPageValidationMixins'
import FrontBookPageMCheckbox from '@/vue_present/_FrontBook/pages/FrontBookPageMCheckbox'
import FrontBookPageMButtonsGroup from '@/vue_present/_FrontBook/pages/FrontBookPageMButtonsGroup'
import FrontBookPageMTimePicker from '@/vue_present/_FrontBook/pages/FrontBookPageMTimePicker'
import FrontBookPageMDatePicker from '@/vue_present/_FrontBook/pages/FrontBookPageMDatePicker'
import FrontBookPageMTextarea from '@/vue_present/_FrontBook/pages/FrontBookPageMTextarea'
import FrontBookPageMFormShow from '@/vue_present/_FrontBook/pages/FrontBookPageMFormShow'
import FrontBookPageMSiGenerator from '@/vue_present/_FrontBook/pages/FrontBookPageMSiGenerator.vue'
import FrontBookPageMCollapse from '@/vue_present/_FrontBook/pages/FrontBookPageMCollapse.vue'

export const navigation = Object.freeze({
  intro: {
    path: getBaseRoute(),
    name: FRONT_BOOK_ROUTES.INTRO,
    component: FrontBookIntro,
    meta: {
      label: 'Intro',
      icon: 'play',
    },
  },

  mIcon: {
    path: getBaseRoute('/m-icon'),
    name: FRONT_BOOK_ROUTES.M_ICON,
    component: FrontBookPageMIcon,
    meta: {
      label: 'MIcon',
      icon: 'icons',
    },
  },

  mButton: {
    path: getBaseRoute('/m-button'),
    name: FRONT_BOOK_ROUTES.M_BUTTON,
    component: FrontBookPageMButton,
    meta: {
      label: 'MButton',
      icon: 'square',
    },
  },

  mDatePicker: {
    path: getBaseRoute('/m-date-picker'),
    name: FRONT_BOOK_ROUTES.M_DATE_PICKER,
    component: FrontBookPageMDatePicker,
    meta: {
      label: 'MDatePicker',
      icon: 'calendar-plus',
    },
  },

  mTimePicker: {
    path: getBaseRoute('/m-time-picker'),
    name: FRONT_BOOK_ROUTES.M_TIME_PICKER,
    component: FrontBookPageMTimePicker,
    meta: {
      label: 'MTimePicker',
      icon: 'clock',
    },
  },

  mSiGenerator: {
    path: getBaseRoute('/m-si-generator'),
    name: FRONT_BOOK_ROUTES.M_SI_GENERATOR,
    component: FrontBookPageMSiGenerator,
    meta: {
      label: 'MSiGenerator Example',
      icon: 'table',
    },
  },

  mInput: {
    path: getBaseRoute('/m-input'),
    name: FRONT_BOOK_ROUTES.M_INPUT,
    component: FrontBookPageMInput,
    meta: {
      label: 'MInput',
      icon: 'keyboard',
    },
  },

  mTextarea: {
    path: getBaseRoute('/m-textarea'),
    name: FRONT_BOOK_ROUTES.M_TEXTAREA,
    component: FrontBookPageMTextarea,
    meta: {
      label: 'MTextarea',
      icon: 'align-justify',
    },
  },

  mSelect: {
    path: getBaseRoute('/m-select'),
    name: FRONT_BOOK_ROUTES.M_SELECT,
    component: FrontBookPageMSelect,
    meta: {
      label: 'MSelect',
      icon: 'caret-square-down',
    },
  },

  mCheckbox: {
    path: getBaseRoute('/m-checkbox'),
    name: FRONT_BOOK_ROUTES.M_CHECKBOX,
    component: FrontBookPageMCheckbox,
    meta: {
      label: 'MCheckbox',
      icon: 'check-square',
    },
  },

  mButtonsGroup: {
    path: getBaseRoute('/m-buttons-group'),
    name: getBaseName('m-buttons-group'),
    component: FrontBookPageMButtonsGroup,
    meta: {
      label: 'MButtonsGroup',
      tooltip: 'Производный от MButton, добавлен Radio режим',
      icon: 'brackets',
    },
  },

  mFormShow: {
    path: getBaseRoute('/m-form-show'),
    name: getBaseName('m-form-show'),
    component: FrontBookPageMFormShow,
    meta: {
      label: 'MFormShow',
      tooltip: 'Генератор компонента просмотра данных формы',
      icon: 'th-list',
    },
  },

  mCollapse: {
    path: getBaseRoute('/m-collapse'),
    name: FRONT_BOOK_ROUTES.M_COLLAPSE,
    component: FrontBookPageMCollapse,
    meta: {
      label: 'MCollapse',
      tooltip: 'Коллапс',
      icon: 'list',
    },
  },

  disabledMixin: {
    path: getBaseRoute('/disabled-mixin'),
    name: FRONT_BOOK_ROUTES.DISABLED_MIXIN,
    component: FrontBookPageDisabledMixin,
    meta: {
      label: 'DisabledMixin.js',
      icon: 'blender',
    },
  },

  paginationMixin: {
    path: getBaseRoute('/pagination-mixin'),
    name: FRONT_BOOK_ROUTES.PAGINATION_MIXIN,
    component: FrontBookPagePaginationMixin,
    meta: {
      label: 'PaginationMixin.js',
      icon: 'container-storage',
    },
  },

  validationMixins: {
    path: getBaseRoute('/validation-mixins'),
    name: FRONT_BOOK_ROUTES.VALIDATION_MIXINS,
    component: FrontBookPageValidationMixins,
    meta: {
      label: 'Миксины для валидации',
      icon: 'badge-check',
    },
  },

  introAll: {
    path: getBaseRoute('*'),
    name: FRONT_BOOK_ROUTES.INTRO_ALL,
    component: FrontBookIntro,
  },
})
