import { ACQUIRING_OPERATION } from '@/vue_apps/FinanceModule/FinanceModuleIndex/consts/Acquiring/AcquiringOperation'
import {
  IAcquiringRequestAwaitListItem,
} from '@/vue_apps/FinanceModule/FinanceModuleIndex/interfaces/Acquiring/IAcquiringRequestAwaitListItem'
import {
  ACQUIRING_FAILURE_TIMEOUT,
  ACQUIRING_LOG_TIMEOUT,
} from '@/vue_apps/FinanceModule/FinanceModuleIndex/consts/Acquiring/AcquiringTimeout'
import {
  IAcquiringAppendRequestToAwaitListConfig,
} from '@/vue_apps/FinanceModule/FinanceModuleIndex/interfaces/Acquiring/IAcquiringAppendRequestToAwaitListConfig'
import {
  TAcquiringRequestId,
} from '@/vue_apps/FinanceModule/FinanceModuleIndex/interfaces/Acquiring/TAcquiringRequestId'

export class AcquiringAwaitRequestListItem implements IAcquiringRequestAwaitListItem {
  requestId: TAcquiringRequestId

  operation: ACQUIRING_OPERATION

  logTimeout: NodeJS.Timeout

  failureTimeout: NodeJS.Timeout

  useWSCallbackLog: boolean

  constructor (
    requestId: TAcquiringRequestId,
    operation: ACQUIRING_OPERATION,
    logTimeoutCallback: () => void,
    failureTimeoutCallback: (requestId: TAcquiringRequestId, operation: ACQUIRING_OPERATION) => void,
    {
      useLog = true,
      acquiringFailureTimeoutCallback,
    }: IAcquiringAppendRequestToAwaitListConfig
  ) {
    this.requestId = requestId
    this.operation = operation

    if (useLog) {
      this.logTimeout = setTimeout(logTimeoutCallback, ACQUIRING_LOG_TIMEOUT)
    }

    this.failureTimeout = setTimeout(() => acquiringFailureTimeoutCallback
      ? acquiringFailureTimeoutCallback()
      : failureTimeoutCallback(requestId, operation),
    ACQUIRING_FAILURE_TIMEOUT)

    this.useWSCallbackLog = useLog
  }
}
