import { ProtocolApi } from '@/vue_apps/ProtocolsApp/entities/ProtocolApi'
import { PROTOCOLS_ID } from '@/vue_apps/MedicalRecords/MedicalRecordsTabs/components/DocumentsTab/consts'

interface IProtocolEditPathParams {
  protocol: ProtocolApi<any>
  medicalRecordId: number
}

export const extractProtocolEditPath = (params: IProtocolEditPathParams) => {
  const path = `${Routes.protocols_page_path(params.medicalRecordId, params.protocol.id)}/edit#${PROTOCOLS_ID}`
  const url = new URL(location.origin + path)

  params.protocol.newSignatureApproved && url.searchParams.append('newSignatureApproved', 'true')
  params.protocol.newSemdApproved && url.searchParams.append('newSemdApproved', 'true')

  return url.toString().replace(url.origin, '')
}
