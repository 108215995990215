import { PaymentModel } from '@/vue_apps/FinanceModule/FinanceModuleIndex/classes/Payment/PaymentModel'
import { IPaymentPayerClient } from '@/vue_apps/FinanceModule/FinanceModuleIndex/interfaces/IPaymentPayerClient'
import { IPaymentPayerCompany } from '@/vue_apps/FinanceModule/FinanceModuleIndex/interfaces/IPaymentPayerCompany'
import { IPaymentToClientDTO } from '@/vue_apps/FinanceModule/FinanceModuleIndex/interfaces/IPaymentToClientDTO'
import { IPaymentWithComment } from '@/vue_apps/FinanceModule/FinanceModuleIndex/interfaces/IPaymentWithComment'
import { PAYMENT_BASE_KIND } from '@/vue_apps/FinanceModule/FinanceModuleIndex/consts/paymentBaseKind'
import {
  PaymentDistributorFundLogic,
} from '@/vue_apps/FinanceModule/FinanceModuleIndex/classes/Payment/PaymentDistributorFundLogic'
import {
  PaymentDistributorRefundLogic,
} from '@/vue_apps/FinanceModule/FinanceModuleIndex/classes/Payment/PaymentDistributorRefundLogic'
import { IError } from '@/_declarations/IError'
import { IList } from '@/_declarations/IList'
import {
  IAcquiringSearchTransaction,
} from '@/vue_apps/FinanceModule/FinanceModuleIndex/interfaces/Acquiring/IAcquiringSearchTransaction'

export class PaymentLogic<
  Distributor extends PaymentDistributorFundLogic | PaymentDistributorRefundLogic
> extends PaymentModel<Distributor> {
  setPayerCompany (company: IPaymentPayerCompany | IError) {
    if ('errors' in company) { return }

    super.setValue('payerCompany', {
      id: company.id,
      title: company.title,
      finance: +company.finance || 0,
      companyFinance: {
        id: company.companyFinance.companyId,
        creditDebt: +company.companyFinance.creditDebt || 0,
      },
      deletedAt: company.deletedAt,
    })

    this.setPayer(this.payerCompany)
  }

  setPayerClient (client: IPaymentPayerClient) {
    super.setValue('payerClient', {
      id: client.id,
      fullName: client.fullName,
      finance: +client.finance || 0,
      creditDebt: +client.creditDebt || 0,
      deletedAt: client.deletedAt,
    })

    this.setPayer(this.payerClient)
  }

  setPaymentsWithComments (...paymentsWithComments: IPaymentWithComment[]) {
    this.setValue('paymentsWithComments', paymentsWithComments)
  }

  updatePayerFinance (value: number) {
    super.setValue('payer.finance', value)
    super.setValue('distributor.payer.finance', value)

    if (this.payerCompany) {
      return super.setValue('payerCompany.finance', value)
    }

    super.setValue('payerClient.finance', value)
  }

  setDistributor (baseKind: PAYMENT_BASE_KIND) {
    const distributor = baseKind === PAYMENT_BASE_KIND.FUND
      ? new PaymentDistributorFundLogic()
      : new PaymentDistributorRefundLogic()

    super.setValue('distributor', distributor)
  }

  protected _fillProps (props: IPaymentToClientDTO) {
    super._fillProps(props)
    this.distributor.fillProps(props)
    this.setPayer(props.payerCompany || props.payerClient)
  }

  protected setLastTransaction (transactionList: IList<IAcquiringSearchTransaction[]>) {
    const lastTransaction = transactionList.data.at(0) || null
    super.setValue('lastTransaction', lastTransaction)
    super.setValue('isLastTransactionCancelOrRefundAllowed', this.getLastTransactionCancelOrRefundAllowed())
  }

  private getLastTransactionCancelOrRefundAllowed () {
    if (!this.lastTransaction || !this.lastTransaction.allowedOperations) return false
    const { refund, cancel } = this.lastTransaction.allowedOperations

    return refund || cancel
  }

  private setPayer (payer: IPaymentPayerClient | IPaymentPayerCompany) {
    const payerWithType = {
      ...payer,
      isCompany: Boolean(this.payerCompany),
      isClient: Boolean(this.payerClient),
    }

    super.setValue('payer', payerWithType)
    super.setValue('distributor.payer', payerWithType)
  }
}
