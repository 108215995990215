<template>
  <m-panel
    :title="t$('terminal')"
    class="fiscal-printer-terminal"
  >
    <span>{{ terminalTitle }}</span>

    <fiscal-printer-status
      type="terminal"
      :status="status"
    />

    <div class="flex gap-indent-mid">
      <!-- Сверка итогов -->
      <m-button
        type="success"
        :text="t$('reconciliationOfResults')"
        :disabled="hasRequestInAwaitListOrLostConnection"
        @click="acquiring.reconciliationOfResults()"
      />

      <acquiring-terminal-connection
        :acquiring="acquiring"
        type="primary"
      />
    </div>

    <div class="flex gap-indent-mid">
      <!-- Краткий отчет -->
      <m-button
        type="warning"
        :text="t$('shortReport')"
        :disabled="hasRequestInAwaitListOrLostConnection"
        @click="acquiring.reportSummary()"
      />

      <!-- Полный отчет -->
      <m-button
        type="warning"
        :text="t$('fullReport')"
        :disabled="hasRequestInAwaitListOrLostConnection"
        @click="acquiring.reportFull()"
      />

      <!-- Копия чека -->
      <m-button
        type="warning"
        :text="t('print_receipt_copy')"
        :disabled="hasRequestInAwaitListOrLostConnection"
        @click="acquiring.copyOfReceipt()"
      />
    </div>

    <div class="flex gap-indent-mid flex-wrap">
      <m-textarea
        class="fiscal-printer-terminal__terminal-log"
        :value="terminalLogs"
        :label="t$('connectionWithTerminal')"
        :placeholder="t('finance.terminalLog.initialMessage')"
        :rows="16"
        readonly
      />
    </div>
  </m-panel>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import MPanel from '@/vue_present/_base/MPanel/MPanel.vue'
import { i18nScopeMixin } from '@/vue_present/mixins/i18nScopeMixin'
import { FISCAL_PRINTER_STATUSES } from '@/vue_apps/catalogs_root/Workplaces/consts/fiscalPrinterStatuses'
import MButton from '@/vue_present/_base/buttons/MButton/MButton.vue'
import MTextarea from '@/vue_present/_base/inputs/MTextarea/MTextarea.vue'
import FiscalPrinterStatus from '@/vue_apps/catalogs_root/Workplaces/components/FiscalPrinter/FiscalPrinterStatus.vue'
import { AcquiringTerminalApi } from '@/vue_apps/catalogs_root/Workplaces/classes/AcquiringTerminal/AcquiringTerminalApi'
import AcquiringTerminalConnection
  from '@/vue_present/Reuse/AcquiringTerminalConnection/AcquiringTerminalConnection.vue'
import { ACQUIRING_OPERATION } from '@/vue_apps/FinanceModule/FinanceModuleIndex/consts/Acquiring/AcquiringOperation'
import {
  ACQUIRING_TERMINAL_CONNECTION_STATUS,
} from '@/vue_apps/FinanceModule/FinanceModuleIndex/consts/Acquiring/AcquiringTerminalConnectionStatus'
import {
  getAcquiringReconciliationOfResultsStatus,
} from '@/vue_apps/catalogs_root/Workplaces/consts/getAcquiringReconciliationOfResultsStatus'

export default defineComponent({
  name: 'FiscalPrinterAcquiringTerminal',

  components: {
    AcquiringTerminalConnection,
    MTextarea,
    MButton,
    FiscalPrinterStatus,
    MPanel,
  },

  mixins: [i18nScopeMixin],

  data () {
    return {
      acquiring: new AcquiringTerminalApi(),
    }
  },

  computed: {
    terminalTitle () {
      const title = this.acquiring.terminalId || t('unknown').toLowerCase()

      return `${t('finance.terminalId')} - ${title}`
    },

    status () {
      const lastReconciliationOfResults = this.acquiring.lastReconciliationOfResults

      if (!lastReconciliationOfResults) {
        return FISCAL_PRINTER_STATUSES.UNKNOWN
      }

      return getAcquiringReconciliationOfResultsStatus(lastReconciliationOfResults)
    },

    terminalLogs () {
      return this.acquiring.terminalLogs.toReversed().join('\n')
    },

    hasRequestInAwaitListOrLostConnection () {
      return Boolean(Object.keys(this.acquiring.awaitRequestList).length) || !this.acquiring.isConnectionStatusActive()
    },

    workplaceId () {
      return +this.$route.params.workplaceId
    },
  },

  created () {
    this.setI18nScope('fiscal')
  },

  async mounted () {
    this.acquiring.setValue('workplaceId', this.workplaceId)

    if (!this.$m.modules.tradeAcquiring) { return }
    await this.acquiring.checkForTerminalAvailability()
    if (this.acquiring.isConnectionStatusNotConnected()) { return }

    this.subscribeToFiscalPrinterZMainReport()
    await this.initCheckConnection()
  },

  methods: {
    async initCheckConnection () {
      this.acquiring.setConnectionStatus(ACQUIRING_TERMINAL_CONNECTION_STATUS.CONNECTING)
      this.acquiring.requestHandler(
        ACQUIRING_OPERATION.CHECKING_CONNECTION,
        await this.acquiring.checkingConnectionRaw(),
        { useLog: false }
      )
    },

    subscribeToFiscalPrinterZMainReport () {
      Services.pubSub.subscribe('fiscalPrinterZMainReport', () => {
        this.acquiring.reconciliationOfResults()
      })

      Services.pubSub.subscribe('broadcast:fiscalPrinterZMainReport', () => {
        this.acquiring.reconciliationOfResults()
      })
    },
  },
})
</script>
