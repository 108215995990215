import { ItemBase } from '@/_api/_classes/ItemBase'
import { FastCreateClientLogic } from '@/vue_apps/CheckupsModule/classes/FastCreateClient/FastCreateClientLogic'
import { IFastCreate } from '@/vue_apps/CheckupsModule/interfaces/IFastCreate'

export class FastCreateModel extends ItemBase {
  _permit = [
    'responsibleId',
    'clinicId',
    'companyId',
    'department',
    'companyCheckupId',
    'kind',
    'status',
  ]

  _getNextId = Utils.getIdGenerator()

  responsibleId: number = null

  clinicId: number = null

  companyId: number = null

  department: string = null

  companyCheckupId: number = null

  kind: string = null

  status: string = null

  clientData: Record<string, FastCreateClientLogic> = {}

  constructor (props?: Partial<IFastCreate>) {
    super()

    this._fillProps(props)
  }

  // @ts-ignore
  getAttributes (clientDataKeys: string[], attributes: string[] = this._permit) {
    return clientDataKeys.map((client) => ({
      ...super.getAttributes(attributes),
      ...this.clientData[client].getAttributes(),
    }))
  }
}
