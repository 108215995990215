import { GRPCTransmitterAbstract } from '@/_api/_classes/GRPC/Transmitter/GRPCTransmitterAbstract'
import { StringHelpers } from '@/helpers/StringHelpers'
import { camelToSnake } from '@/_api/_requests/helpers'

export class GRPCTransmitterJSON<
  TSuccessCallbackData = unknown,
  TErrorCallbackData = unknown
> extends GRPCTransmitterAbstract<
  Object,
  string,
  TSuccessCallbackData,
  TErrorCallbackData> {
  protected getBatches (): string[] {
    const utf8MaxCharSizeInBytes = 4
    const batchSize = Utils.getMaxFileSize('Byte') / utf8MaxCharSizeInBytes
    const adaptedData = camelToSnake(this.toServer(this.data))

    return StringHelpers.splitByLength(JSON.stringify(adaptedData), batchSize)
  }
}
