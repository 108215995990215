import { ISemdSource } from '@/vue_apps/Semds/declarations/ISemdSource'
import { TSemdConnectorReturn } from '@/vue_apps/Semds/SemdSourceConnector/declarations/semdsSourceConnectorDeclarations'
import { MConfirm } from '@/vue_present/_base/MConfirm/MConfirm'

export const confirmResetSign =
  async (source: ISemdSource): TSemdConnectorReturn => {
    const { cancel } = await MConfirm.warning(t('semds.confirms.oldSigned'))
    if (cancel) { return { cancel } }

    source.setValue('newSignatureApproved', true)

    return { cancel: false }
  }
