import { getMap } from '../configuration/mapping'
import EmComplexMember from '../components/modal/em_complex_member_modal'
import FrontendEditor from '../components/editor/frontend_editor'
import ContextMenu from '../components/context_menu/editor_mode/EditModeContextMenu'
import BaseTemplateBuilder from './BaseTemplateBuilder'

export class EditModeBuilder extends BaseTemplateBuilder {
  constructor (store, params) {
    super(store)
    this.member = params.member
  }

  setMapping () {
    const customMap = {
      appId: 'complexMember',
      htmlInput: this.member.field('template_html')[0],
      dataInput: this.member.field('template_data')[0],
    }
    const baseMap = getMap('frontend')
    this.map = Object.assign(baseMap, customMap)
  }

  initComponents () {
    this.addComponent('modal', new EmComplexMember(this.map))
    this.addComponent('editor', new FrontendEditor(this.map))
    this.addComponent('contextMenu', new ContextMenu(this.map))
    window.onresize = () => this._components.modal.resizeBodyContainer()
  }

  create () {
    return this
  }
}
