<template>
  <el-collapse
    v-model="activeCollapseItems"
    class="m-collapse"
    @change="$emit('change', $event)"
  >
    <slot>
      <m-collapse-item
        v-for="item in items"
        :key="item[nameKey]"
        :title="item[titleKey]"
        :name="item[nameKey]"
        :active="activeCollapseItems.includes(item[nameKey])"
        :arrow-position="arrowPosition"
        :active-icon="activeIcon"
        :active-icon-color="activeIconColor"
        :inactive-icon="inactiveIcon"
        :inactive-icon-color="inactiveIconColor"
        :use-title-tooltip="useTitleTooltip"
        :loading="item.loading"
        :disabled="item.disabled"
      >
        <template #beforeTitle>
          <slot
            name="beforeTitle"
            :item="item"
          />
        </template>

        <template #title>
          <slot
            name="title"
            :item="item"
          />
        </template>

        <template #afterTitle>
          <slot
            name="afterTitle"
            :item="item"
          />
        </template>

        <slot
          name="body"
          :item="item"
        />
      </m-collapse-item>
    </slot>
  </el-collapse>
</template>

<script lang="ts">

import MCollapseItem from '@/vue_present/_base/MCollapse/MCollapseItem.vue'
import { defineComponent, PropType } from 'vue'
import { ICollapseItem } from '@/vue_present/_base/MCollapse/interfaces/ICollapseItem'

export default defineComponent({
  name: 'MCollapse',

  components: { MCollapseItem },

  props: {
    activeNames: { type: Array as PropType<Array<string | number>>, default: () => [] },
    items: { type: Array as PropType<ICollapseItem[]>, default: () => [] },

    nameKey: { type: String, default: 'id' },
    titleKey: { type: String, default: 'title' },

    activeIcon: { type: String, default: '' },
    activeIconColor: { type: String, default: '' },
    inactiveIcon: { type: String, default: '' },
    inactiveIconColor: { type: String, default: '' },

    arrowPosition: { type: String as PropType<'left' | 'right'>, default: 'left' },

    parentControl: Boolean,
    accordion: Boolean,
    useTitleTooltip: Boolean,
  },

  emits: [
    'update:activeNames',
    'change',
  ],

  data () {
    return {
      active: [] as Array<string | number>,
    }
  },

  computed: {
    activeCollapseItems: {
      get () {
        return this.parentControl
          ? this.activeNames
          : this.active
      },

      set (value: Array<string | number>) {
        this.parentControl
          ? this.$emit('update:activeNames', value)
          : this.active = value
      },
    },
  },

  watch: {
    items () {
      if (this.parentControl) { return }
      this.activeCollapseItems = []
    },
  },
})
</script>
