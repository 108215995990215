import baseTemplateComponent from '../base_component'

export default class Modal extends baseTemplateComponent {
  constructor (params, builder = false) {
    super(params, builder)
    if (this.modalWindow) {
      this.saveButton = this.modalWindow.querySelector('.modal-save')
      this.modalFooter = $(this.modalWindow.querySelector('.modal-footer'))
      this.closeButton = this.modalWindow.querySelector('.modal-header-close')
      this.modalBody = $(this.modalWindow.querySelector('.modal-body'))
    }

    this.contextMenu = this.container.get('contextMenu')
  }

  /**
   * @returns {boolean}
   */
  show () {
    return false
  }

  /**
   * @abstract
   * @protected
   */
  _open () {
    this.contextMenu.forceDelete()
    this.forceClose = false
    $(this.modalWindow).megamodal('show')
    // start spin
    $(this.modalBody).loadSpin('start')
    $(this.modalWindow.querySelector('#btn_fullscreen')).click()
    $(this.modalWindow).find('#btn_small').remove()
    this.saveButton.removeAttribute('disabled')
    this.modalFooter.insertAfter(this.editorContainer)

    this.modalFooter.css('paddingLeft', 0)

    //костыль для readOnly прав в шаблонах протоколов
    //START
    const canManageRightsCheck = Object.freeze({
      template_records: 'canManageTemplateRecord',
    })

    const saveButton = document.querySelector('.modal-save')
    let readOnlyRights = false

    Object.keys(canManageRightsCheck).forEach((key) => {
      if (gon.page.controller === key && !Services.security[canManageRightsCheck[key]]) {
        readOnlyRights = true
      }
    })

    if (readOnlyRights) { saveButton.classList.add('hide') }

    //костыль для readOnly прав
    //END
  }

  /**
   * @private
   */
  _save () {
    this.saveButton.setAttribute('disabled', true)
    $(this.modalWindow).megamodal('hide')
  }

  preparePrintPanel () {}

  _bindEvents () {
    $(this.modalWindow).bind('hidden.bs.modal', (e) => {
      $('.entries_page .btn').css('zIndex', '')
      $('.nested-diagnosis-checkboxes').css('zIndex', '')
      this.container.get('editor').unbind()
      this.container.get('contextMenu').forceDelete()
      //!!TODO remade shit
      if (this.appId === 'test_edit_mode') {
        const app =
          window.application.form_builder[Object.keys(window.application.form_builder)[0]]
        if (app) {
          window.application.form_builder.current = app
          app._components.modal.resizeBodyContainer()
          window.onresize = () => app._components.modal.resizeBodyContainer()
        }
      } else {
        window.onresize = null
      }
    })

    PubSub.on('initEditor' + this.appId, async (msg, data) => {
      setTimeout(() => this.resizeBodyContainer(), 200)
      window.onresize = () => this._components.modal.resizeBodyContainer()
      await this.load()
      if (this.mode === 'edit') {
        this.editor.prepareEntities()
        this.editor.update()
        this.preparePrintPanel()
      }
      if (this.mode === 'constructor') {
        this.editor._showHeadTypes(false)
      }
    })
  }

  async load () {
    throw new Error('load is abstract need implementation')
  }

  resizeBodyContainer () {
    const FUCKING_EDITOR_TOOLBAR_OFFSET = 150

    this.modalBody.css('overflowY', 'hidden')
    this.modalBody.css('max-height', '100%')
    this.editorContainer.style.height = window.innerHeight - this.EDITOR_OFFSET + 'px'
    this.editorWrap.style.height = window.innerHeight - this.EDITOR_OFFSET - FUCKING_EDITOR_TOOLBAR_OFFSET + 'px'
    $(this.editorWrap).css('overflowY', 'auto')
  }

  start (item, isFromSearch) {
    this._open()
    this.lazyLoad((result) => {
      $(this.modalBody).loadSpin('stop')
      this.load(result, false, isFromSearch)
    }, item)
  }

  //lazy load template
  lazyLoad (cb, item) {
    let request
    if (Utils.isPromise(item)) {
      request = item
    } else {
      request = new Promise((resolve, reject) => {
        $.ajax({
          url: Routes.template_record_path(item.id),
          success: resolve,
        })
      })
    }

    request
      .then(cb)
      .catch((error) => {
        $(this.modalBody).loadSpin('stop')
        alert(t('errors.error_has_occurred'))
        console.error(error)
      })
  }
}
