import { IPrintArea, TPrintAreaId } from '@/vue_apps/ProtocolsApp/entities/printAreas/IPrintArea'
import { PrintAreaWholeDocument } from '@/vue_apps/ProtocolsApp/entities/printAreas/PrintAreaWholeDocument'
import { PrintArea } from '@/vue_apps/ProtocolsApp/entities/printAreas/PrintArea'
import { ArrayHelpers } from '@/helpers/ArrayHelpers'

export class PrintAreas {
  static readonly ALL_PRINT_AREAS_ID = 'allPrintAreas'

  wholeDocument = new PrintAreaWholeDocument()

  printAreas: IPrintArea[] = []

  private printAreasMap: Record<TPrintAreaId, IPrintArea> = {} as Record<TPrintAreaId, IPrintArea>

  constructor (printAreas: Array<Partial<IPrintArea>>) {
    this.printAreas = printAreas.map((printArea) => new PrintArea({
      id: printArea.id,
      title: printArea.title,
    }))

    this.printAreasMap = ArrayHelpers.arrayToMap(this.printAreas)
  }

  get hasCheckedPrintAreas (): boolean {
    return this.printAreas.some((printArea) => printArea.checked)
  }

  execute (id: TPrintAreaId) {
    const printArea = this.printAreasMap[id]
    !printArea
      ? this.whenNotPrintArea(id)
      : this.whenPrintArea(printArea)
  }

  whenNotPrintArea (id: TPrintAreaId) {
    this.wholeDocument.setChecked(id === PrintAreaWholeDocument.WHOLE_DOCUMENT_ID)
    this.setPrintAreasChecked(id === PrintAreas.ALL_PRINT_AREAS_ID)
  }

  whenPrintArea (printArea: IPrintArea) {
    printArea.setChecked(!printArea.checked)
    this.wholeDocument.setChecked(!this.hasCheckedPrintAreas)
  }

  setPrintAreasChecked (value: boolean = true) {
    this.printAreas.forEach((printArea) => {
      printArea.setChecked(value)
    })
  }
}
