<template>
  <m-panel
    :title="t('clinicalGuidelines.catalog.entry.title')"
    icon="clinicalGuideline"
  >
    <m-si-generator
      :si-generator-schema="schema"
      :add-button-config="addButtonConfig"
      :items="items"
      @onAddItem="clinicalGuidelinesCatalogEditorEntryModalApi.openModal()"
      @onItemEdit="clinicalGuidelinesCatalogEditorEntryModalApi.openModal($event.id)"
      @onItemDelete="onItemDelete"
      @onItemClick="onItemClick"
    >
      <template #tr-arrowIconCell="{ item }">
        <m-icon
          :class="{ 'rotate-z-90': activeItemIds.includes(item.id) }"
          icon="right"
          color="grey-select-text"
        />
      </template>

      <template #afterRowItem="{ item }">
        <div
          v-show="activeItemIds.includes(item.id)"
          class="flex flex-column gap-indent-mid mt-10 mb-10"
        >
          <m-input
            :value="item.kind.title"
            :label="t('type')"
            :m-fixed-height="false"
            readonly
          />

          <m-textarea
            :value="item.commentShort"
            :label="t$('commentShort')"
            :m-fixed-height="false"
            readonly
          />

          <m-textarea
            :value="item.commentLong"
            :label="t$('commentLong')"
            :m-fixed-height="false"
            readonly
          />
        </div>
      </template>
    </m-si-generator>

    <clinical-guidelines-catalog-editor-entry-modal
      :clinical-guideline="clinicalGuideline"
      @registerApi="clinicalGuidelinesCatalogEditorEntryModalApi = $event"
    />
  </m-panel>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import {
  ClinicalGuidelineApi,
} from '@/vue_apps/catalogs_root/ClinicalGuidelinesCatalog/classes/ClinicalGuideline/ClinicalGuidelineApi'
import MSiGenerator from '@/vue_present/_base/Tables/MSiGenerator/MSiGenerator.vue'
import MPanel from '@/vue_present/_base/MPanel/MPanel.vue'
import {
  getClinicalGuidelineEntryAddButtonConfig,
  getClinicalGuidelineEntrySchema,
} from '@/vue_apps/catalogs_root/ClinicalGuidelinesCatalog/consts/clinicalGuidelineEntrySchema'
import ClinicalGuidelinesCatalogEditorEntryModal, {
  IClinicalGuidelinesCatalogEditorEntryModalApi,
} from '@/vue_apps/catalogs_root/ClinicalGuidelinesCatalog/components/ClinicalGuidelinesCatalogEditorEntryModal.vue'
import MInput from '@/vue_present/_base/inputs/MInput/MInput.vue'
import MTextarea from '@/vue_present/_base/inputs/MTextarea/MTextarea.vue'
import { i18nScopeMixin } from '@/vue_present/mixins/i18nScopeMixin'
import MIcon from '@/vue_present/_base/MIcon/MIcon.vue'
import {
  ClinicalGuidelineEntryItem,
} from '@/vue_apps/catalogs_root/ClinicalGuidelinesCatalog/classes/ClinicalGuidelineEntryItem/ClinicalGuidelineEntryItem'

export default defineComponent({
  name: 'ClinicalGuidelinesCatalogEditorEntries',

  components: {
    MIcon,
    MTextarea,
    MInput,
    ClinicalGuidelinesCatalogEditorEntryModal,
    MPanel,
    MSiGenerator,
  },

  mixins: [i18nScopeMixin],

  props: {
    clinicalGuideline: { type: ClinicalGuidelineApi, required: true },
  },

  data () {
    return {
      clinicalGuidelinesCatalogEditorEntryModalApi: null as IClinicalGuidelinesCatalogEditorEntryModalApi,
      schema: getClinicalGuidelineEntrySchema(),
      addButtonConfig: getClinicalGuidelineEntryAddButtonConfig(),
      activeItemIds: [],
    }
  },

  computed: {
    items () {
      return this.clinicalGuideline
        .clinicalGuidelineEntries
        .getArr()
    },
  },

  created () {
    this.setI18nScope('clinicalGuidelines.catalog.entry')
  },

  methods: {
    onItemClick (item: ClinicalGuidelineEntryItem) {
      const activeItemIdIndex = this.activeItemIds
        .findIndex((id: number) => id === item.id)

      activeItemIdIndex === -1
        ? this.activeItemIds.push(item.id)
        : this.activeItemIds.splice(activeItemIdIndex, 1)
    },

    async onItemDelete (item: ClinicalGuidelineEntryItem) {
      const hasErrors = await this.clinicalGuideline
        .destroyClinicalGuidelineEntry(item.dbId)

      if (hasErrors) { return }

      this.clinicalGuideline
        .clinicalGuidelineEntries
        .remove(item.id)
    },
  },
})
</script>
