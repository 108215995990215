import baseTemplateComponent from '../base_component'
import { DeleteEntity } from '../../events/EventEntities'
import { cleanUpEditor, VALID_WORD_ELEMENTS } from '@/plugins/dynamic_forms/tinymce_helpers'
import { HIDDEN_PRINT_CLASS, PRINT_INSTRUMENT_AREAS } from '@/plugins/dynamic_forms/components/editor/const'
import { specSymbolsSanitize } from '@/specific/entries/submit_sanitizer'
import { MConfirm } from '@/vue_present/_base/MConfirm/MConfirm'
import { getPrintingToolsValues, setPrintingToolsValuesToDOM } from '@/helpers/printing_tools'
import { cloneDeep } from 'lodash'
import { createSemdApp } from '@/vue_apps/ProtocolsApp/_SemdProtocolEditor'
import { createClinicalGuidelinesApp } from '@/vue_apps/ClinicalGuidelines'
import { MCEKeeperHTML } from '@/_api/_classes/MCEKeeper/MCEKeeperHTML'

export default class Editor extends baseTemplateComponent {
  constructor (params, builder) {
    super(params, builder)
    if (!this.editorId) throw new Error('editor not defined')
    if (!this.editorSettings) throw new Error('settings is not defined')
    this.contextMenu = this.container.get('contextMenu')
    this.entityFactory = this.container.get('entity_factory')
  }

  async run (printingToolsValues = null) {
    this.printingToolsValues = printingToolsValues || getPrintingToolsValues(gon.specific)
    setPrintingToolsValuesToDOM(this.printingToolsValues)
    this.currentPrintingToolsValues = cloneDeep(this.printingToolsValues)

    const editorContext = this
    const editorConfig = Object.assign(
      this.editorSettings,
      {
        setup: this.initHandle.bind(this),
        browser_spellcheck: true,
        paste_word_valid_elements: VALID_WORD_ELEMENTS,
        init_instance_callback (editor) {
          editorContext._tinymce = editor
        },
      }
    )
    await tinymce.init(editorConfig)

    this.showHeadTypes = false
  }

  async deleteContent () {
    const deleteElementStorage = []
    const activeEditor = tinymce.activeEditor
    const sel = activeEditor.selection
    const content = sel.getContent()

    const $content = $(`<div>${specSymbolsSanitize(content)}</div>`)

    if ($content.find('.semd-entity,.semd-area').length) {
      const { cancel } = await MConfirm.error(t('semds.delete.egiszLayout'))
      if (!cancel) { Services.pubSub.emit(`onDeleteContentArea:${activeEditor.id}`, $content) }

      return
    }

    const mceKeeperItems = $content.find(`.${MCEKeeperHTML.mceKeeperCssClass}`)
    if (mceKeeperItems.length) {
      const { cancel } = await MConfirm.error(t('delete_1'))
      if (!cancel) {
        const item = mceKeeperItems.get(0)
        const id = item.getAttribute('data-id')
        const dataType = item.getAttribute('data-type')

        Services.pubSub.emit(`MCEKeeper:${dataType}:Delete`, id)
      }

      return
    }

    const findMatches = content.match(/data-name="\w+\d+"/g)

    if (findMatches) {
      findMatches.forEach((item) => {
        const dynamicElemName = item.match(/\w+\d+/g)
        const dynamicElem = this.editorWrap
          .querySelector(`.t-element[data-name="${dynamicElemName}"]`)
        if (dynamicElem) deleteElementStorage.push(dynamicElem)
      })
    }

    const printAreaManager = this.container.get('print_area_manager')
    printAreaManager.deleteItemsByContent(content)
    sel.setContent('')
    if (deleteElementStorage) {
      deleteElementStorage.forEach((element, index) => {
        this.dispatcher.dispatch(new DeleteEntity(element))
      })
    }
  }

  deleteNode (entityElement) {
    if (!entityElement) return
    this.dispatcher.dispatch(new DeleteEntity(entityElement))
    this.container.get('contextMenu').forceDelete()
  }

  initHandle (editor) {
    this.addButtons(editor)
    this.addMenuItems(editor)
    this.bindEditorEvents(editor)
  }

  /**
   * @param {import('tinymce').Editor} editor
   */
  addButtons (editor) {
    const context = this
    editor.addButton('egiszMount', {
      text: t('egisz.title'),
      tooltip: t('egisz.title'),
      icon: 'custom fad fa-question-circle',
      onpostrender () {
        createSemdApp(`#${this._id}`, editor, context)
        Services.pubSub.emit('Editor:SemdReady')
      },
    })
    editor.addButton('clinicalGuidelines', {
      icon: 'custom fad fa-books-medical',
      classes: 'primary-btn ',
      tooltip: t('clinicalGuidelines.title'),
      onpostrender () {
        if (!gon.application.clinical_guidelines_module_enabled || !Services.security.canViewClinicalGuideline) { return }
        createClinicalGuidelinesApp()
      },
      onclick () {
        if (!gon.application.clinical_guidelines_module_enabled) {
          return Utils.reportError(
            'clinicalGuidelines.mce',
            t('introduction.moduleNotConnectedWithTitle', {
              title: t('clinicalGuidelines.title'),
            })
          )()
        }

        if (!Services.security.canViewClinicalGuideline) {
          return Utils.reportError(
            'clinicalGuidelines.mce',
            t('introduction.moduleConnectedByNotAuthorizedWithTitle', {
              title: t('clinicalGuidelines.title'),
            })
          )()
        }

        Services.pubSub.emit('MDraggableWindow:ClinicalGuidelines:Open')
        Services.pubSub.emit('ProtocolPopoverConnector:Deactivate')
      },
    })
  }

  /**
   * @param {import('tinymce').Editor} editor
   */
  addMenuItems (editor) {
    const _editor = this

    editor.addMenuItem('print_entry_title', {
      text: t('print_entry_title'),
      icon: 'custom',
      context: 'tools',
      data: {
        name: 'print_entry_title',
        printArea: PRINT_INSTRUMENT_AREAS.ENTRY_INFO,
        initialValue: _editor.printingToolsValues.entryTitle,
      },
      onclick () {
        const newValue = !_editor.getToolValue(this)
        _editor.setToolCheckbox(this, newValue)
        _editor.currentPrintingToolsValues.entryTitle = newValue
      },
      onpostrender () {
        _editor.setToolCheckbox(this, _editor.printingToolsValues.entryTitle)
      },
    })

    editor.addMenuItem('print_entry_info', {
      text: t('print_entry_info'),
      icon: 'custom',
      context: 'tools',
      data: {
        name: 'print_entry_info',
        printArea: PRINT_INSTRUMENT_AREAS.ENTRY_INFO,
        initialValue: _editor.printingToolsValues.entryInfo,
      },
      onclick () {
        const newValue = !_editor.getToolValue(this)
        _editor.setToolCheckbox(this, newValue)
        _editor.currentPrintingToolsValues.entryInfo = newValue
      },
      onpostrender () {
        _editor.setToolCheckbox(this, _editor.printingToolsValues.entryInfo)
      },
    })

    editor.addMenuItem('print_clinic_header', {
      text: t('print_clinic_header'),
      icon: 'custom',
      context: 'tools',
      data: {
        name: 'print_clinic_header',
        printArea: PRINT_INSTRUMENT_AREAS.CLINIC_HEADER,
        initialValue: _editor.printingToolsValues.clinicHeader,
      },
      onclick () {
        const newValue = !_editor.getToolValue(this)
        _editor.setToolCheckbox(this, newValue)
        _editor.currentPrintingToolsValues.clinicHeader = newValue
      },
      onpostrender () {
        _editor.setToolCheckbox(this, _editor.printingToolsValues.clinicHeader)
      },
    })

    editor.addMenuItem('print_doctor_signature', {
      text: t('print_doctor_signature'),
      icon: 'custom',
      context: 'tools',
      data: {
        name: 'print_doctor_signature',
        printArea: PRINT_INSTRUMENT_AREAS.DOCTOR_SIGNATURE,
        initialValue: _editor.printingToolsValues.doctorSignature,
      },
      onclick () {
        const newValue = !_editor.getToolValue(this)
        _editor.setToolCheckbox(this, newValue)
        _editor.currentPrintingToolsValues.doctorSignature = newValue
      },
      onpostrender () {
        _editor.setToolCheckbox(this, _editor.printingToolsValues.doctorSignature)
      },
    })
  }

  _replaceFormateTags (item, dataTarget) {
    if (dataTarget === 'bold') {
      if (item.style.fontWeight === 'bold') {
        item.style.fontWeight = ''
      } else {
        item.style.fontWeight = 'bold'
      }
    }

    if (dataTarget === 'italic') {
      if (item.style.fontStyle === 'italic') {
        item.style.fontStyle = ''
      } else {
        item.style.fontStyle = 'italic'
      }
    }
  }

  /**
   * Скрытие блоков шаблона в зависимости от инструментов
   * @param {String} printAreaName - значения из PRINT_INSTRUMENT_AREAS
   * @param {Number | Boolean} value - 0 / 1, true / false
   * @private
   */
  _setInstrumentsPrintAreaVisibility (printAreaName, value) {
    const cssClass = `.print-area__${printAreaName}`
    const el = document.querySelector(cssClass)

    if (!el) { return }

    value
      ? el.classList.remove(HIDDEN_PRINT_CLASS)
      : el.classList.add(HIDDEN_PRINT_CLASS)
  }

  setToolCheckbox (item, checked) {
    const newValue = checked ? 1 : 0
    const { data } = item.data

    $(`#${data.name}_wrapper input`).each((i, item) => {
      item.value = newValue
    })
    const icon = $(`#${item._id}`).find('.mce-ico')
    this._setStateCheckbox(icon, checked)

    this._setInstrumentsPrintAreaVisibility(data.printArea, checked)
  }

  getToolValue (item) {
    const selector = `#${item.data.data.name}_wrapper input[type="hidden"]`
    const toolValue = document.querySelector(selector)

    if (!toolValue) return 0

    return parseInt(toolValue.value)
  }

  _setStateCheckbox (icon, value) {
    icon.removeClass('mce-i-none')
    icon.addClass('custom fad')

    if (value) {
      icon.addClass('fa-check-square')
      icon.removeClass('fa-square fa-swap-opacity')
    } else {
      icon.addClass('fa-square fa-swap-opacity')
      icon.removeClass('fa-check-square')
    }
  }

  _handleInit (editor) {
    PubSub.publish('initEditor' + this.appId, { text: 'editor init' })
  }

  addUndo (editor, e) {
    if (e.level.content === '<p>&nbsp;<br></p>') return false
    if (e.level.content === '<p><br data-mce-bogus="1"></p>') return false
  }

  repairVariables () {
    const entityManager = this.container.get('entity_manager')
    entityManager.removeBrokenElements()
  }

  bindEditorEvents (editor) {
    editor.on('init', this._handleInit.bind(this))
    editor.on('BeforeAddUndo', (e) => this.addUndo(editor, e))

    if (this.container.get('contextMenu')) {
      editor.on('contextmenu', (e) => {
        window.application.form_builder.current.globalMap.currSelection =
          tinymce.activeEditor.selection.getSel().getRangeAt(0)
        this.container.get('contextMenu').notClose = false
        this.container.get('contextMenu').build(editor, e)
      })
    }

    editor.on('BeforeExecCommand', (data, event) => {
      if (data.command === 'mceToggleFormat' || data.command === 'FontName') {
        this.elements = this.manager.get('elements')
        this.elements.forEach((item) => {
          item.setAttribute('contenteditable', true)
        })
      }
    })

    editor.on('ExecCommand', (data) => {
      if (data.command === 'mceToggleFormat' || data.command === 'FontName') {
        this.elements.forEach((item) => {
          item.setAttribute('contenteditable', false)
          const firstChild = item.firstChild
          if (
            firstChild &&
            (firstChild.tagName === 'B' || firstChild.tagName === 'I')
          ) {
            this._replaceFormateTags(item, data.value)
            item.innerHTML = firstChild.innerHTML
          }
        })
      }
    })

    editor.on('paste', (event) => {
      const content = (event.originalEvent || event).clipboardData.getData('text/html') || ''

      if (content.includes('semd') && content.includes('t-element')) {
        event.preventDefault()
      }

      cleanUpEditor()
    })
  }

  unbind () {
    if (tinymce.editors[this.editorId]) {
      tinymce.editors[this.editorId].remove()
    }
  }

  loadData (data) {
    let storage = {}
    if (!data) {
      storage = {
        variables: [],
        calculate: [],
        fix_list: [],
        extensible_list: [],
        conclusions: [],
        print_area: [],
        semdEntities: {},
      }
    } else {
      storage = JSON.parse(data)
    }
    this.entityManager.load(storage)
  }

  loadHtml (html) {
    if (!html) html = '<p><br data-mce-bogus="1"></p>'
    if (html) {
      const htmlData = $('<div>' + html + '</div>')
      const variables = $(htmlData).find('.variable')
      variables.addClass('variables')
      variables.removeClass('variable')
      const calc = $(htmlData).find('.calc')
      calc.addClass('calculate')
      calc.removeClass('calc')
      this.editorMountPoint.innerHTML = htmlData.html()
    }
  }

  update () {
    this.entityManager.updateEntities()
  }

  buildCategory (e) {
    const modal = this.container.get('mkb_modal')
    if (modal) {
      return this.container.get('api').diseases()
        .then((tree) => { modal.mount(tree, false, true, false) })
        .then(() => modal.show())
    }

    Services.pubSub.emit('DiseasesModal:Open')
  }
}
