import { getMap } from '../configuration/mapping'
import TemplateRecordModal from '../components/modal/template_record_modal'
import BackendEditor from '../components/editor/backend'
import BaseTemplateBuilder from './BaseTemplateBuilder'
import MathEditor from '../components/modal/math_editor.js'
import VariableEditor from '../components/modal/variable_editor.js'
import ListEditor from '../components/modal/list_editor.js'
import ContextMenu from '../components/context_menu/constructor_mode_context_menu'

import EntitiesModal from '../components/modal/protocol_entities_modal'
import EntityManager from '../entities/EntityManager'
import EntityFactory from '../entities/factory'
import Dispatcher from '@/plugins/dynamic_forms/_core/dispatcher'
import { registerListeners } from '../listeners'
import Api from '../api/Api'
import EntityTree from '../tree/entity_tree'
import DiseasesTree from '../tree/diseases_tree'
import MkbModal from '../components/modal/mkb_modal'
import EntryVariableModal from '../components/modal/entry_variables_modal'
import CategoriesWrap from '../components/common/catogories_wrap'
import { entityConfigs } from '../configuration/main_config'
import PrintAreaManager from '@/plugins/dynamic_forms/entities/print_area_manager'
import PrintAreaModal from '@/plugins/dynamic_forms/components/modal/print_area_modal'

export class BackendEntryTypesBuilder extends BaseTemplateBuilder {
  setMapping () {
    const protocolModal = document.querySelector('#template_record_protokol')
    const customMap = {
      appId: 'entry_type_constuctor_mode',
      openButton: document.querySelector('#current-template-edit'),
      form: document.querySelector('#template-record-form'),
      categoryIdInput: document.querySelector('#template_record_category_id'),
      newItemButton: document.querySelector('#add-template-item'),
      unbindButton: document.querySelector('#current-template-unbind'),
      modalButton: document.querySelector('#template_builder_btn'),
      templateListModal: $('#template_record_protokol'),
      categoryTreeContainer: document.querySelector('#template-records_category_list'),
      categoriesListWrap: document.querySelector('#categories-list-wrap'),
      currentTemplateContainer: document.querySelector('.current-template-container'),
      templateRecordIdInput: document.querySelector('#entry_type_template_record_id'),
      htmlInput: document.querySelector('#template_record_template_html'),
      dataInput: document.querySelector('#template_record_template_data'),
      showHeadTypes: false,
      protocolModal,
      protocolSaveBtn: protocolModal.querySelector('.modal-save'),
    }

    const baseMap = getMap('backend')
    this.map = Object.assign(baseMap, customMap)
    this.panelMap = getMap('panel')
  }

  initComponents () {
    this.container.add('store', this.store)
    this.container.add('dispatcher', new Dispatcher(this.container))
    this.container.add('config', entityConfigs)
    const entityManager = new EntityManager(this.container)
    registerListeners(this.container)
    this.container.add('entity_manager', entityManager)
    this.container.add('contextMenu', new ContextMenu(this.map, this))
    this.container.add('entity_factory', new EntityFactory(this.map, this))

    this.container.add('editor', new BackendEditor(this.map, this))

    this.container.add('entities_modal', new EntitiesModal(this.container))
    this.container.add('entity_tree', new EntityTree(this.container))
    this.container.add('api', new Api(this.container))
    this.container.add('entity_factory', new EntityFactory(this.map, this))
    this.container.add('variables', new VariableEditor(this.panelMap.variables, this))
    this.container.add('calculate', new MathEditor(this.panelMap.math, this))
    this.container.add('list', new ListEditor(this.panelMap.list, this))
    this.container.add('mkb_modal', new MkbModal(this.container))
    this.container.add('diseases_tree', new DiseasesTree(this.container))
    this.container.add('print_area_manager', new PrintAreaManager(this.container))
    this.container.add('print_area_modal', new PrintAreaModal(this.container))
    this.container.add('entry_variables_modal', new EntryVariableModal(this.container))

    this.addComponent('categories', new CategoriesWrap(this.categoriesMap, this))
    this.addComponent('modal', new TemplateRecordModal(this.map, this))
    const entityModal = this.container.get('entities_modal')
    const mkbModal = this.container.get('mkb_modal')
    entityModal.saveButton.on('click', entityModal.save.bind(entityModal))
    mkbModal.saveButton.on('click', mkbModal.save.bind(mkbModal))
  }

  create () {
    return this
  }
}
