export enum ACQUIRING_PAYMENT_TYPE_CODE {
  CARD,
  SBP,
}

export enum ACQUIRING_PAYMENT_TYPE {
  SBP = 'sbp',
  CARD = 'card',
}

export const ACQUIRING_PAYMENT_TYPE_CODE_TO_TITLE_MAP = {
  [ACQUIRING_PAYMENT_TYPE_CODE.CARD]: ACQUIRING_PAYMENT_TYPE.CARD,
  [ACQUIRING_PAYMENT_TYPE_CODE.SBP]: ACQUIRING_PAYMENT_TYPE.SBP,
}
