<template>
  <catalogs-base
    :catalog-title="t('workplaces')"
    :can-manage="canManage"
    catalog-icon="rectangleLandscape"
    table-icon="rectangleLandscape"
    :table-title="t('workplace')"
    :mode="mode"
    @close="onClose"
  >
    <template #primary>
      <m-si-generator
        :si-generator-schema="msiSchema"
        :add-button-config="addButtonConfig"
        :active-item-id="currentWorkplaceId"
        :items="list.data"
        :current-page="list.currentPage"
        :page-count="list.totalPages"
        @update:currentPage="list.setPage($event)"
        @onAddItem="onAddItem"
        @onItemClick="onItemClick"
      />
    </template>

    <template #form>
      <router-view @updateWorkplacesList="list.fetchAll()" />
    </template>
  </catalogs-base>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import CatalogsBase from '@/vue_apps/catalogs_root/_catalog_base/CatalogsBase.vue'
import MSiGenerator from '@/vue_present/_base/Tables/MSiGenerator/MSiGenerator.vue'
import { MODES } from '@/vue_apps/catalogs_root/_catalog_base/const/const'
import { WORKPLACES_CATALOG_ROUTE_NAMES } from '@/vue_apps/catalogs_root/Workplaces/router/routeNames'
import { WorkplacesListItem } from '@/vue_apps/catalogs_root/Workplaces/classes/WorkplacesListItem'
import {
  getWorkplacesCatalogMsiSchema,
  getWorkplacesCatalogMsiSchemaButtonConfig,
} from '@/vue_apps/catalogs_root/Workplaces/consts/workplacesCatalogMsiConsts'
import { MListServiceApi } from '@/_api/_requests/MListServiceApi/MListServiceApi'

export default defineComponent({
  name: 'WorkplacesCatalogApp',

  components: {
    MSiGenerator,
    CatalogsBase,
  },

  data () {
    return {
      MODES,
      msiSchema: getWorkplacesCatalogMsiSchema(),
      addButtonConfig: getWorkplacesCatalogMsiSchemaButtonConfig(),

      list: new MListServiceApi({
        defaultFilters: { },
        src: Routes.list_api_internal_workplaces_path(),
        DataItemEntity: WorkplacesListItem,
      }),
    }
  },

  computed: {
    canManage () {
      return this.$security.canManageWorkplace
    },

    mode () {
      switch (this.$route.name) {
        case WORKPLACES_CATALOG_ROUTE_NAMES.EDIT:
          return MODES.EDIT

        case WORKPLACES_CATALOG_ROUTE_NAMES.NEW:
          return MODES.CREATE

        default:
          return MODES.SHOW
      }
    },

    currentWorkplaceId (): number | null {
      return +this.$route.params.workplaceId || null
    },
  },

  created () {
    this.list.fetchAll()
  },

  methods: {
    onAddItem () {
      if (!Services.security.canManageWorkplace) { return }
      if (this.$route.name === WORKPLACES_CATALOG_ROUTE_NAMES.NEW) { return }

      this.$router.replace({ name: WORKPLACES_CATALOG_ROUTE_NAMES.NEW })
    },

    onItemClick (item: WorkplacesListItem) {
      if (!Services.security.canManageWorkplace) { return }
      if (this.currentWorkplaceId === item.id) { return }

      this.$router.replace({
        name: WORKPLACES_CATALOG_ROUTE_NAMES.EDIT,
        params: {
          workplaceId: item.id.toString(),
        },
      })
    },

    onClose () {
      this.$router.replace({ name: WORKPLACES_CATALOG_ROUTE_NAMES.INDEX })
    },
  },
})
</script>
