import {
  PaymentDistributorModel,
} from '@/vue_apps/FinanceModule/FinanceModuleIndex/classes/Payment/PaymentDistributorModel'
import { PAYMENT_BASE_KIND } from '@/vue_apps/FinanceModule/FinanceModuleIndex/consts/paymentBaseKind'
import { PAYMENT_INPUT_FIELDS } from '@/vue_apps/FinanceModule/FinanceModuleIndex/consts/paymentInputField'

export class PaymentDistributorLogicBase extends PaymentDistributorModel {
  setBaseKind (baseKind: PAYMENT_BASE_KIND) {
    super.setValue('baseKind', baseKind)

    this.paymentEntries.forEach((entry) => {
      entry.setBaseKind(baseKind)
    })
  }

  protected resetTransfer () {
    const availableForTransfer = Utils.toMoney(this.totalRequired - this.byCard - this.byCashless - this.byBalance)
    if (this.byCash >= availableForTransfer) return
    super.setValue('transferSum', 0)
  }

  protected isInputField (operation: string) {
    return PAYMENT_INPUT_FIELDS.includes(operation as any)
  }
}
