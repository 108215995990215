import { GLOBAL_ENTRY_TYPES_KINDS } from '@/_global_scripts/GLOBAL_CONSTS'
import { vueFilters } from '@/plugins/vue/filtersPlugin/vueFilters'
import { IEntry } from '@/_declarations/IEntry'
import { IList } from '@/_declarations/IList'

export const protocolEntriesAdapter = {
  toClient (response: IList<IEntry>): IList<IEntry> {
    const baseData: IEntry[] = response.data.map((item) => {
      return {
        ...item,
        date: vueFilters.date(item.date),
      }
    })

    const data = baseData.reduce((acc, item) => {
      acc.push(item)
      // часть составляющих комплекса это тоже IEntry, они должны также быть добавлены в список
      item.members && item.members
        .filter((member) => member.kind !== GLOBAL_ENTRY_TYPES_KINDS.GOOD)
        .forEach((member) => {
          acc.push({ ...member, date: vueFilters.date(member.date) })
        })

      return acc
    }, [])

    return {
      ...response,
      data,
    }
  },
}
