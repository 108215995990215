import {
  AcquiringTerminalModelBase,
} from '@/vue_apps/catalogs_root/Workplaces/classes/AcquiringTerminalBase/AcquiringTerminalModelBase'
import { tScoped } from '@/helpers/i18nHelpers'
import {
  ACQUIRING_OPERATION,
  ACQUIRING_PAYMENT_OPERATIONS,
} from '@/vue_apps/FinanceModule/FinanceModuleIndex/consts/Acquiring/AcquiringOperation'
import { IAcquiringWSMeta } from '@/vue_apps/FinanceModule/FinanceModuleIndex/interfaces/Acquiring/IAcquiringWSMeta'
import {
  IAcquiringAppendRequestToAwaitListConfig,
} from '@/vue_apps/FinanceModule/FinanceModuleIndex/interfaces/Acquiring/IAcquiringAppendRequestToAwaitListConfig'
import {
  acquiringExtractError,
} from '@/vue_apps/FinanceModule/FinanceModuleIndex/consts/Acquiring/AcquiringExtractError'
import {
  ACQUIRING_STATUS,
  ACQUIRING_STATUS_CODE,
} from '@/vue_apps/FinanceModule/FinanceModuleIndex/consts/Acquiring/AcquiringStatus'
import {
  TAcquiringRequestId,
} from '@/vue_apps/FinanceModule/FinanceModuleIndex/interfaces/Acquiring/TAcquiringRequestId'
import {
  IAcquiringRequestAwaitListItem,
} from '@/vue_apps/FinanceModule/FinanceModuleIndex/interfaces/Acquiring/IAcquiringRequestAwaitListItem'
import {
  ACQUIRING_TERMINAL_CONNECTION_STATUS,
} from '@/vue_apps/FinanceModule/FinanceModuleIndex/consts/Acquiring/AcquiringTerminalConnectionStatus'
import { orNull } from '@/_declarations/orNull'
import {
  IAcquiringCheckingConnection,
} from '@/vue_apps/FinanceModule/FinanceModuleIndex/interfaces/Acquiring/IAcquiringCheckingConnection'
import { TAcquiringWSData } from '@/vue_apps/FinanceModule/FinanceModuleIndex/interfaces/Acquiring/IAcquiringWSData'
import { ACQUIRING_ACTION } from '@/vue_apps/FinanceModule/FinanceModuleIndex/consts/Acquiring/AcquiringAction'
import {
  IAcquiringTerminalReconciliationOfResults,
} from '@/vue_apps/catalogs_root/Workplaces/interfaces/Acquiring/IAcquiringReconciliationOfResults'
import {
  IAcquiringCopyOfReceipt,
} from '@/vue_apps/catalogs_root/Workplaces/interfaces/Acquiring/IAcquiringCopyOfReceipt'
import { IAcquiringErrors } from '@/vue_apps/FinanceModule/FinanceModuleIndex/interfaces/Acquiring/IAcquiringErrors'
import {
  extractAcquiringCustomMessageType,
} from '@/vue_apps/catalogs_root/Workplaces/consts/extractAcquiringCustomMessageType'
import { AcquiringAwaitRequestListItem } from '@/vue_apps/catalogs_root/Workplaces/classes/AcquiringAwaitRequestListItem'

const ts = tScoped('finance')

export class AcquiringTerminalLogicBase extends AcquiringTerminalModelBase {
  requestHandler (
    operation: ACQUIRING_OPERATION,
    response: IAcquiringWSMeta | IAcquiringErrors,
    config: IAcquiringAppendRequestToAwaitListConfig = {} as IAcquiringAppendRequestToAwaitListConfig
  ) {
    if (config?.useLog ?? true) {
      this.addInitialTerminalLog(operation)
    }

    if ('errors' in response) {
      return this.requestErrorsHandler(operation, response, config)
    }

    if (this.isPaymentOperation(operation)) {
      super.setValue('status', ACQUIRING_STATUS_CODE.IN_PROGRESS)
    }

    this.addRequestToAwaitList(operation, response as IAcquiringWSMeta, config)
  }

  protected removeRequestFromAwaitList (requestId: TAcquiringRequestId) {
    this.clearRequestTimeout(requestId)
    const awaitRequestListItem = this.awaitRequestList[requestId]
    delete this.awaitRequestList[requestId]
    this.awaitRequestList = { ...this.awaitRequestList }

    return awaitRequestListItem
  }

  protected checkForTerminalAvailabilityCallback (data: IAcquiringWSMeta | IAcquiringErrors) {
    if (('requestId' in data)) return

    this.setConnectionStatus(ACQUIRING_TERMINAL_CONNECTION_STATUS.NOT_CONNECTED)
  }

  protected addInitialTerminalLog (operation: ACQUIRING_OPERATION) {
    this.addTerminalLog(ts('terminalLog.operationInitiated', { operation: ts(`operations.${operation}`) }))
  }

  protected addSuccessTerminalLog (operation: ACQUIRING_OPERATION, customMessage?: orNull<string>) {
    customMessage
      ? this.addTerminalLogCustomMessage(ACQUIRING_STATUS.SUCCESS, operation, customMessage)
      : this.addTerminalLog(ts('terminalLog.operationSuccess', { operation: ts(`operations.${operation}`) }))
  }

  protected addFailureTerminalLog (operation: ACQUIRING_OPERATION, customMessage?: orNull<string>) {
    customMessage
      ? this.addTerminalLogCustomMessage(ACQUIRING_STATUS.FAILURE, operation, customMessage)
      : this.addTerminalLog(ts('terminalLog.operationFailed', { operation: ts(`operations.${operation}`) }))
  }

  protected checkingConnectionHandler (data: IAcquiringCheckingConnection, awaitRequestListItem?: IAcquiringRequestAwaitListItem) {
    this.setConnectionStatus(data.connectionStatus)
    super.setValue('terminalId', data.terminalId)
    super.setValue('lastReconciliationOfResults', data.lastReconciliationOfResults)

    if (!awaitRequestListItem?.useWSCallbackLog) { return }

    this.isConnectionStatusActive()
      ? this.addSuccessTerminalLog(ACQUIRING_OPERATION.CHECKING_CONNECTION)
      : this.addFailureTerminalLog(ACQUIRING_OPERATION.CHECKING_CONNECTION)
  }

  protected copyOfReceiptHandler (awaitRequestListItem: IAcquiringRequestAwaitListItem, data: IAcquiringCopyOfReceipt) {
    data.receipt
      ? this.addSuccessTerminalLog(awaitRequestListItem.operation, data.receipt)
      : this.addFailureTerminalLog(awaitRequestListItem.operation)
  }

  protected moduleDisabledHandler () {
    Object.entries(this.awaitRequestList).forEach(([requestId, awaitRequestListItem]) => {
      this.removeRequestFromAwaitList(requestId)
      this.addFailureTerminalLog(awaitRequestListItem.operation, ts('errors.acquiringModuleMustBeEnabled'))
    })
  }

  protected externalRequestHandler (action: ACQUIRING_ACTION, data: TAcquiringWSData, isSuccess: boolean) {
    if (!isSuccess) {
      if (action === ACQUIRING_ACTION.CHECKING_CONNECTION) {
        this.setConnectionStatus(ACQUIRING_TERMINAL_CONNECTION_STATUS.INACTIVE)
      }

      return
    }

    switch (action) {
      case ACQUIRING_ACTION.CHECKING_CONNECTION: return this.checkingConnectionHandler(data as IAcquiringCheckingConnection)
      case ACQUIRING_ACTION.RECONCILIATION_OF_RESULTS: return this.reconciliationOfResultsExternalHandler(data as IAcquiringTerminalReconciliationOfResults)
    }
  }

  protected isPaymentOperation (operation: ACQUIRING_OPERATION) {
    return ACQUIRING_PAYMENT_OPERATIONS.includes(operation)
  }

  private requestErrorsHandler (
    operation: ACQUIRING_OPERATION,
    response: IAcquiringErrors,
    { useLog = true }: IAcquiringAppendRequestToAwaitListConfig
  ) {
    if (operation === ACQUIRING_OPERATION.CHECKING_CONNECTION) {
      this.setConnectionStatus(ACQUIRING_TERMINAL_CONNECTION_STATUS.INACTIVE)
    }

    if (useLog) {
      this.addFailureTerminalLog(operation, acquiringExtractError(response))
    }
  }

  private addRequestToAwaitList (
    operation: ACQUIRING_OPERATION,
    response: IAcquiringWSMeta,
    config: IAcquiringAppendRequestToAwaitListConfig
  ) {
    const awaitRequestListItem = new AcquiringAwaitRequestListItem(
      response.requestId,
      operation,
      this.acquiringLogTimeoutCallback.bind(this),
      this.acquiringFailureTimeoutCallback.bind(this),
      config
    )

    this.awaitRequestList = {
      ...this.awaitRequestList,
      [response.requestId]: awaitRequestListItem,
    }
  }

  private acquiringLogTimeoutCallback () {
    this.addTerminalLog(ts('terminalLog.waitingForResponse'))
  }

  private acquiringFailureTimeoutCallback (requestId: TAcquiringRequestId, operation: ACQUIRING_OPERATION) {
    this.removeRequestFromAwaitList(requestId)

    if (operation === ACQUIRING_OPERATION.CHECKING_CONNECTION) {
      this.setConnectionStatus(ACQUIRING_TERMINAL_CONNECTION_STATUS.INACTIVE)
    }

    this.addTerminalLog(ts('terminalLog.operationTerminated', { operation: ts(`operations.${operation}`) }))

    if (!this.isPaymentOperation(operation)) { return }

    super.setValue('status', ACQUIRING_STATUS_CODE.EXECUTION_ERROR)
  }

  private addTerminalLog (message: string) {
    this.terminalLogs.push(`${Utils.getFormattedDate(undefined, Utils.timeFormat)} ${message}`)
  }

  private addTerminalLogCustomMessage (
    type: ACQUIRING_STATUS.SUCCESS | ACQUIRING_STATUS.FAILURE,
    operation: ACQUIRING_OPERATION,
    customMessage: string
  ) {
    const customMessageType = extractAcquiringCustomMessageType(type)

    this.addTerminalLog(ts(`terminalLog.${customMessageType}`, {
      customMessage,
      operation: ts(`operations.${operation}`),
    }))
  }

  private clearRequestTimeout (requestId: TAcquiringRequestId) {
    clearTimeout(this.awaitRequestList[requestId].logTimeout)
    clearTimeout(this.awaitRequestList[requestId].failureTimeout)
  }

  private reconciliationOfResultsExternalHandler (data: IAcquiringTerminalReconciliationOfResults) {
    super.setValue('lastReconciliationOfResults', data.lastReconciliationOfResults)
  }
}
