<template>
  <div class="workplaces-catalog-form">
    <!-- Наименование -->
    <m-input
      v-model="workplace.title"
      :label="t('title')"
      required
      validator-name="title"
      @registerValidator="onRegisterValidator"
      @input="workplace.setValue('title', $event)"
    />

    <!-- Ключ -->
    <m-input
      v-if="isEdit"
      :value="workplace.key"
      :label="t('key')"
      :required="isEdit"
      readonly
    />

    <!-- ККМ -->
    <div
      v-if="isEdit"
      class="workplaces-catalog-form__kkm"
    >
      <span class="workplaces-catalog-form__kkm-label">
        {{ t('kkm') }}
      </span>

      <div class="workplaces-catalog-form__kkm-value">
        <span
          class="m-link"
          @click="onFiscalPrinter"
        >
          {{ kkmTitle }}
        </span>
      </div>
    </div>

    <div class="workplaces-catalog-form__custom-cashier">
      <m-switch
        :value="workplace.useCurrentUser"
        class="mb-indent-validation"
        :active-text="t('fiscal.useCurrentUserText')"
        @change="onUseCurrentUser"
      />

      <reusable-doctors-list
        v-if="!workplace.useCurrentUser"
        v-model="workplace.cashier"
        :required="!workplace.useCurrentUser"
        :user-group="USER_GROUP_IDS.CLINIC_PERSONNEL"
        :label="t('employee')"
        validator-name="cashierId"
        m-fixed-height
        full-width
        @registerValidator="onRegisterValidator"
      />
    </div>

    <div class="flex gap-indent-mid mb-indent-validation">
      <m-button
        template="save"
        :disabled="loading || 'Workplace'"
        @click="onSubmit"
      />

      <!-- Обновить ключ -->
      <m-button
        v-if="isEdit"
        icon="key"
        :text="t('update_key')"
        type="warning"
        disabled="Workplace"
        @click="updateKey"
      />

      <m-button-delete
        v-if="isEdit"
        use-button
        :popover-message="deletePopoverMessage"
        disabled="Workplace"
        @yes="onDestroy"
      />
    </div>

    <m-panel
      icon="users"
      :title="t('employers')"
    >
      <m-si-generator
        :si-generator-schema="msiSchemaEmployers"
        :items="usersItems"
        @onItemDelete="onItemDelete"
      />
    </m-panel>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import MInput from '@/vue_present/_base/inputs/MInput/MInput.vue'
import { ValidationParentMixin } from '@/vue_present/mixins/ValidationParentMixin'
import { SpinnerHolder } from '@/vue_components/mixins/spinner_holder'
import MButton from '@/vue_present/_base/buttons/MButton/MButton.vue'
import { WORKPLACES_CATALOG_ROUTE_NAMES } from '@/vue_apps/catalogs_root/Workplaces/router/routeNames'
import MSiGenerator from '@/vue_present/_base/Tables/MSiGenerator/MSiGenerator.vue'
import MPanel from '@/vue_present/_base/MPanel/MPanel.vue'
import {
  getWorkplacesCatalogEmployersMsiSchema,
} from '@/vue_apps/catalogs_root/Workplaces/consts/workplacesCatalogMsiConsts'
import { WorkplaceApi } from '@/vue_apps/catalogs_root/Workplaces/classes/Workplace/WorkplaceApi'
import { MConfirm } from '@/vue_present/_base/MConfirm/MConfirm'
import MButtonDelete from '@/vue_present/_base/buttons/MButtonDelete/MButtonDelete.vue'
import { getFiscalPrinterStatus } from '@/vue_apps/catalogs_root/Workplaces/consts/getFiscalPrinterStatus'
import { IUser } from '@/_declarations/IUser'
import MSwitch from '@/vue_present/_base/inputs/MSwitch/MSwitch.vue'
import ReusableDoctorsList from '@/vue_present/Reuse/Lists/Doctors/ReusableDoctorsList.vue'
import { USER_GROUP_IDS } from '@/_global_scripts/GLOBAL_CONSTS'

export default defineComponent({
  name: 'WorkplacesCatalogForm',

  components: {
    ReusableDoctorsList,
    MSwitch,
    MButtonDelete,
    MPanel,
    MSiGenerator,
    MButton,
    MInput,
  },

  mixins: [
    ValidationParentMixin,
    SpinnerHolder,
  ],

  beforeRouteEnter (to, from, next) {
    next((vm: any) => {
      vm.onRouteChange(to.params.workplaceId)
    })
  },

  beforeRouteUpdate (to, from, next) {
    this.onRouteChange(to.params.workplaceId)

    // TODO VUE 3: убрать next() там он не нужОн
    next()
  },

  emits: ['updateWorkplacesList'],

  data () {
    return {
      msiSchemaEmployers: getWorkplacesCatalogEmployersMsiSchema(),
      workplace: new WorkplaceApi(),
      USER_GROUP_IDS,
    }
  },

  computed: {
    isNew (): boolean {
      return this.$route.name === WORKPLACES_CATALOG_ROUTE_NAMES.NEW
    },

    isEdit (): boolean {
      return this.$route.name === WORKPLACES_CATALOG_ROUTE_NAMES.EDIT
    },

    workplaceId (): string | undefined {
      return this.$route.params.workplaceId
    },

    deletePopoverMessage () {
      return this.usersItems.length
        ? t('deleteWorkplaceWithUsers')
        : t('deleteWorkplace')
    },

    usersItems () {
      return Object.values(this.workplace.users)
    },

    kkmTitle () {
      return this.workplace.kkm
        ? `${this.workplace.kkm.title} ${t(`fiscal.statuses.kkm.${getFiscalPrinterStatus(this.workplace.kkm)}`)}`
        : t('unknown')
    },
  },

  methods: {
    async onRouteChange (workplaceId?: string) {
      this.resetValidations()

      this.workplace = new WorkplaceApi()
      if (!workplaceId) { return }

      await this.workplace.fetchWorkplace(workplaceId)
      if (!this.workplace.id) { return }

      this.workplace.initWSEvents()
    },

    onFiscalPrinter () {
      this.$router.push({
        name: WORKPLACES_CATALOG_ROUTE_NAMES.FISCAL_PRINTER,
        params: {
          workplaceId: this.workplaceId.toString(),
          workplaceTitle: this.workplace.originalTitle,
        },
      })
    },

    async updateKey () {
      const { cancel } = await MConfirm.warning(t('update_api_key_po'))
      if (cancel) { return }
      await this.workplace.updateKey()
    },

    async onSubmit () {
      if (this.hasErrors()) { return }
      const data = await this.withSpinnerSimple(this.workplace.submit())
      if (data?.errors) { return this.resetValidations(data.errors) }

      this.$emit('updateWorkplacesList')
      if (this.isEdit) { return }
      await this.$router.push({ name: WORKPLACES_CATALOG_ROUTE_NAMES.EDIT, params: { workplaceId: this.workplace.id.toString() } })
    },

    async onDestroy () {
      const response = await this.workplace.destroy()
      if (response?.errors) { return }

      this.$emit('updateWorkplacesList')
      await this.$router.push({ name: WORKPLACES_CATALOG_ROUTE_NAMES.INDEX })
    },

    onItemDelete (user: IUser) {
      this.workplace.removeUser(user)
      this.workplace.submit(this.workplace.getUsersAttributes())
    },

    onUseCurrentUser (useCurrentUser: boolean) {
      useCurrentUser && this.removeValidator('cashierId')
      this.workplace.setValues({ useCurrentUser, cashier: null })
    },
  },
})
</script>
