import { TAcquiringWSData } from '@/vue_apps/FinanceModule/FinanceModuleIndex/interfaces/Acquiring/IAcquiringWSData'
import { ACQUIRING_ACTION } from '@/vue_apps/FinanceModule/FinanceModuleIndex/consts/Acquiring/AcquiringAction'
import {
  IAcquiringRequestAwaitListItem,
} from '@/vue_apps/FinanceModule/FinanceModuleIndex/interfaces/Acquiring/IAcquiringRequestAwaitListItem'
import {
  acquiringExtractError,
} from '@/vue_apps/FinanceModule/FinanceModuleIndex/consts/Acquiring/AcquiringExtractError'
import { IErrorArray } from '@/_declarations/IErrorArray'
import {
  IAcquiringTerminalReconciliationOfResults,
} from '@/vue_apps/catalogs_root/Workplaces/interfaces/Acquiring/IAcquiringReconciliationOfResults'
import {
  ACQUIRING_STATUS,
  ACQUIRING_STATUS_CODE,
} from '@/vue_apps/FinanceModule/FinanceModuleIndex/consts/Acquiring/AcquiringStatus'
import { IAcquiringReport } from '@/vue_apps/catalogs_root/Workplaces/interfaces/Acquiring/IAcquiringReport'
import {
  AcquiringTerminalApiBase,
} from '@/vue_apps/catalogs_root/Workplaces/classes/AcquiringTerminalBase/AcquiringTerminalApiBase'

export class AcquiringTerminalLogic extends AcquiringTerminalApiBase {
  protected actionHandler (action: ACQUIRING_ACTION, awaitRequestListItem: IAcquiringRequestAwaitListItem, data: TAcquiringWSData) {
    switch (action) {
      case ACQUIRING_ACTION.RECONCILIATION_OF_RESULTS: return this.reconciliationOfResultsHandler(awaitRequestListItem, data as IAcquiringTerminalReconciliationOfResults)
      case ACQUIRING_ACTION.REPORT: return this.reportHandler(awaitRequestListItem, data as IAcquiringReport)
    }
  }

  protected wsErrorsHandler (awaitRequestListItem: IAcquiringRequestAwaitListItem, data: TAcquiringWSData) {
    if ('description' in data) {
      super.setValue('transactionDescription', data.description)
    }

    this.addFailureTerminalLog(awaitRequestListItem.operation, acquiringExtractError(data as IErrorArray))
  }

  private reconciliationOfResultsHandler (awaitRequestListItem: IAcquiringRequestAwaitListItem, data: IAcquiringTerminalReconciliationOfResults) {
    if (data.zMainReportResult?.status === ACQUIRING_STATUS.SUCCESS) {
      Services.pubSub.emit('fiscalPrinterIsDayOpened', false, true)
    }

    if (data.status === ACQUIRING_STATUS_CODE.SUCCESS) {
      super.setValue('lastReconciliationOfResults', data.lastReconciliationOfResults)

      return this.addSuccessTerminalLog(awaitRequestListItem.operation, data.report)
    }

    this.addFailureTerminalLog(awaitRequestListItem.operation)
  }

  private reportHandler (awaitRequestListItem: IAcquiringRequestAwaitListItem, data: IAcquiringReport) {
    data.operationStatus === ACQUIRING_STATUS_CODE.SUCCESS
      ? this.addSuccessTerminalLog(awaitRequestListItem.operation, data.report)
      : this.addFailureTerminalLog(awaitRequestListItem.operation)
  }
}
