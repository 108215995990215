import { ICRUDReturn } from '@/_api/decorators/api/interfaces/ICRUDReturn'
import { Api } from '@/_api/decorators/api/Api'
import { Callback } from '@/_api/decorators/callback'
import { PaymentLogic } from '@/vue_apps/FinanceModule/FinanceModuleIndex/classes/Payment/PaymentLogic'
import { paymentAdapter } from '@/vue_apps/FinanceModule/FinanceModuleIndex/api/paymentAdapter'
import {
  paymentDestinationOrderAdapter,
} from '@/vue_apps/FinanceModule/FinanceModuleIndex/api/paymentDestinationOrderAdapter'
import { paymentsWithCommentsAdapter } from '@/vue_apps/FinanceModule/FinanceModuleIndex/api/paymentsWithCommentsAdapter'
import { PAYMENT_PAYER_TYPE } from '@/vue_apps/FinanceModule/FinanceModuleIndex/consts/paymentPayerType'
import { IPaymentPayerClient } from '@/vue_apps/FinanceModule/FinanceModuleIndex/interfaces/IPaymentPayerClient'
import { TStringNumber } from '@/_declarations/TStringNumber'
import {
  PaymentDistributorFundLogic,
} from '@/vue_apps/FinanceModule/FinanceModuleIndex/classes/Payment/PaymentDistributorFundLogic'
import {
  PaymentDistributorRefundLogic,
} from '@/vue_apps/FinanceModule/FinanceModuleIndex/classes/Payment/PaymentDistributorRefundLogic'

export class PaymentApi<
  Distributor extends PaymentDistributorFundLogic | PaymentDistributorRefundLogic
> extends PaymentLogic<Distributor> {
  @Callback('setLastTransaction')
  @Api.list(Routes.api_internal_finances_trade_acquiring_transactions_path)
  fetchLastTransactions (id: TStringNumber = this.id) {
    return {
      payload: {
        paymentId: +id,
      },
    } as ICRUDReturn as Promise<void>
  }

  @Callback('_fillProps')
  @Api.notify('fetchMessage', 'payment', { success: false })
  @Api.clientAdapter(paymentAdapter)
  @Api.get(Routes.api_internal_finances_payment_path)
  fetchPayment (id: TStringNumber) {
    return {
      routeArgs: [id],
    } as ICRUDReturn as Promise<void>
  }

  @Callback('_fillProps')
  @Api.notify('fetchMessage', 'order', { success: false })
  @Api.clientAdapter(paymentDestinationOrderAdapter)
  @Api.post(Routes.fetch_order_api_internal_finances_payments_path)
  fetchDestinationOrder (orderId: TStringNumber) {
    return {
      payload: { orderId },
    } as ICRUDReturn as Promise<void>
  }

  fetchPayer (payerId?: TStringNumber, payerType?: string) {
    if (!payerId) { return }

    if (payerType === PAYMENT_PAYER_TYPE.COMPANY) {
      return this.fetchPayerCompany(payerId)
    }

    return this.fetchPayerClient(payerId)
  }

  @Callback('setPaymentsWithComments')
  @Api.clientAdapter(paymentsWithCommentsAdapter)
  @Api.post(Routes.payments_with_comments_payments_path)
  fetchPaymentsWithComments () {
    return {
      payload: { clientId: this.payer.id },
    } as ICRUDReturn as Promise<Record<string, unknown>>
  }

  @Api.notify('createMessage', 'payment')
  @Api.post(Routes.api_internal_finances_payments_path)
  @Api.serverAdapter(paymentAdapter)
  submit () {
    return {
      payload: this.getAttributes(),
    } as ICRUDReturn as Promise<void>
  }

  @Callback('setPayerCompany')
  @Api.notify('fetchMessage', 'company', { success: false })
  @Api.get(Routes.fetch_company_company_path)
  private fetchPayerCompany (id: TStringNumber) {
    return {
      routeArgs: [id],
    } as ICRUDReturn as Promise<void>
  }

  private async fetchPayerClient (id: TStringNumber) {
    const client = await this.fetchClient(id)
    const creditDebt = await this.fetchClientCreditDebt(id)

    if (client.errors || creditDebt.errors) { return }

    this.setPayerClient({
      ...client,
      ...creditDebt,
    } as unknown as IPaymentPayerClient)
  }

  @Api.notify('fetchMessage', 'client', { success: false })
  @Api.get(Routes.credit_debt_client_path)
  private fetchClientCreditDebt (id: TStringNumber) {
    return {
      routeArgs: [id],
    } as ICRUDReturn as Promise<Record<string, unknown>>
  }

  @Api.notify('fetchMessage', 'client', { success: false })
  @Api.clientAdapter((payload: Record<string, any>) => ({ ...payload, fullName: payload.fullname }))
  @Api.get(Routes.find_client_path)
  private fetchClient (id: TStringNumber) {
    return {
      routeArgs: [id],
    } as ICRUDReturn as Promise<Record<string, unknown>>
  }
}
