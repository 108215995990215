
export const createTreeFromArray = (arr) => arr.map((item, id) => ({
  id,
  title: item,
}))

export const createTreeFromNestedArray = (data) => {
  const sortByName = (children) => {
    const items = children.filter((child) => !child.folder).sort((a, b) => a.title > b.title ? 1 : -1)
    const folders = children.filter((child) => child.folder).sort((a, b) => a.title > b.title ? 1 : -1)

    if (folders?.length) {
      folders.forEach((folder) => {
        folder.children = sortByName(folder.children || [])
      })
    }

    return folders.concat(items)
  }

  // build tree from nestes sets
  let result = []
  const tmp = {}

  data.sort((a, b) => a.depth - b.depth)
  data.forEach((node) => {
    if (node.depth === 0) {
      node.expanded = true
    }

    if (tmp[node.depth] === undefined) tmp[node.depth] = []
    tmp[node.depth].push(node)

    if (!node.parent_id) {
      result.push(node)
    } else {
      try {
        const parent = tmp[node.depth - 1].find((e) => e.id === node.parent_id)
        if (!parent.children) parent.children = []
        if (parent.selected) {
          node.selected = true
        }
        parent.children.push(node)
      } catch (err) {
        // console.debug(`node has no parent. title: ${node.title}, depth: ${node.depth}, id: ${node.id}`)
        // console.debug('err: ', err)
      }
    }
  })

  result = sortByName(result)

  return result
}

export const treeNodeRenderFunction = (h, { node, data, store }) => {
  const __class = data.folder
    ? node.expanded
      ? 'fa-folder-open warning'
      : 'fa-folder warning'
    : 'fa-file-medical'

  return h(
    'span',
    { class: 'custom-tree-node' },
    [
      h('i', { class: 'fad fa-fw mr-5 ' + __class }),
      h('span', { class: 'node-content' }, data.title),
    ]
  )
}

/**
 * @param {String} content
 * @param {String} value
 * @param {Number} caretPos
 * @param {String} separator
 * @param selectionStart
 * @param selectionEnd
 * @return {String}
 */
export const updateContent = (
  content,
  value,
  caretPos,
  separator,
  { selectionStart, selectionEnd }
) => {
  // БЛ: не вставлять разделитель, если был перевод строки
  let _separator = content[caretPos - 1] === '\n'
    ? ''
    : separator

  let _content = content

  const _caretPos = selectionStart === selectionEnd
    ? caretPos
    : selectionStart

  if (selectionStart !== selectionEnd) {
    _content = content.slice(0, selectionStart) + content.slice(selectionEnd)
    _separator = separator === ' ' ? '' : separator
  }

  return !_content.trim()
    ? value
    : Utils.insertToString(_content, `${_separator}${value}`, _caretPos)
}

/**
 * Частичное сравнение контента с тем, что сейчас выделено в window
 * @param {string} content
 * @return {boolean}
 */
export const isContentEqualsToWindowSelection = (content = '') => {
  const COMPARISON_SYMBOLS_COUNT = 20
  const windowSelectedContent = window.getSelection().toString()
  const windowSelectedContentStart = windowSelectedContent.trim().slice(COMPARISON_SYMBOLS_COUNT)
  const windowSelectedContentEnd = windowSelectedContent.trim().slice(-COMPARISON_SYMBOLS_COUNT)

  const contentStart = content.trim().slice(COMPARISON_SYMBOLS_COUNT)
  const contentEnd = content.trim().slice(-COMPARISON_SYMBOLS_COUNT)

  return windowSelectedContentStart === contentStart && windowSelectedContentEnd === contentEnd
}
