
export enum ETeethStates {
  normal = 'normal',
  artificial = 'artificial',
  crown = 'crown',
  pulpitis = 'pulpitis',
  periodontitis = 'periodontitis',
  absence = 'absence',
  implant = 'implant',
  implantWithCrown = 'implant_with_crown',
  orthodonticPathology = 'orthodontic_pathology',
  hardTissueDefect = 'hard_tissue_defect',
  mobility = 'mobility',
  caries = 'caries',
  root = 'root',
}
