import { PRINT_AREA_ACTIONS as actions } from '../actions/entityActions'

const printAreaReducer = (state = [], { type, payload }) => {
  const newState = [...state]

  switch (type) {
    case actions.ADD :
      newState.push(payload)

      return newState
    case actions.LOAD :
      return payload
    case actions.DELETE :
      const index = newState.findIndex((item) => item.id === payload.id)
      newState.splice(index, 1)

      return newState
    case actions.UPDATE :
      const item = newState.find((item) => item.id === payload.id)
      Object.assign(item, payload)

      return newState
    default:
      return state
  }
}

export default printAreaReducer
