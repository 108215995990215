<template>
  <clinical-guideline-collapse-item
    :collapse-item-id="collapseItemId"
    :active-names="activeNames"
  >
    <m-collapse-item
      v-for="entry in clinicalGuidelineEntries"
      :key="entry.dbId"
      :name="`entry-id-${entry.dbId}`"
      :title="entry.title"
      use-title-tooltip
      :active-icon="entry.iconCell.value"
      :active-icon-color="entry.iconCell.css"
      :active-icon-tooltip="entry.iconCell.tooltip"
    >
      <template #afterTitle>
        <div class="ml-auto">
          <m-button
            v-tooltip="t('add')"
            template="add"
            size="mini"
            @click.stop="onAdd(entry)"
          />
        </div>
      </template>

      <div class="clinical-guideline-collapse-item__container">
        <!-- Тип -->
        <clinical-guideline-collapse-item-labeled-value
          :label="t('type')"
          :value="entry.kind.title"
        />

        <!-- Комментарии -->
        <clinical-guideline-collapse-item-labeled-value
          :label="t$('commentShort')"
          :value="entry.commentShort"
        />

        <!-- Дополнительный комментарий -->
        <clinical-guideline-collapse-item-labeled-value
          :label="t$('commentLong')"
          :value="entry.commentLong"
        />

        <div class="flex gap-indent-mid">
          <!-- Уровень убедительности рекомендаций -->
          <clinical-guideline-collapse-item-labeled-value
            :label="t$('uurShort')"
            :value="entry.uur.title"
          />

          <!-- Уровень достоверности доказательств -->
          <clinical-guideline-collapse-item-labeled-value
            :label="t$('uddShort')"
            :value="entry.udd.title"
          />

          <!-- Входит в критерии оценки качества медицинской помощи -->
          <clinical-guideline-collapse-item-labeled-value
            :label="t$('healthcareBenchmarkShort')"
            :value="getHealthcareBenchmarkValue(entry.healthcareBenchmark)"
          />
        </div>
      </div>
    </m-collapse-item>
  </clinical-guideline-collapse-item>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import MCollapseItem from '@/vue_present/_base/MCollapse/MCollapseItem.vue'
import {
  ClinicalGuidelineEntryItem,
} from '@/vue_apps/catalogs_root/ClinicalGuidelinesCatalog/classes/ClinicalGuidelineEntryItem/ClinicalGuidelineEntryItem'
import ClinicalGuidelineCollapseItem from '@/vue_apps/ClinicalGuidelines/components/ClinicalGuidelineCollapseItem.vue'
import { CLINICAL_GUIDELINE_COLLAPSE_ITEM } from '@/vue_apps/ClinicalGuidelines/consts/clinicalGuidelineCollapseItems'
import ClinicalGuidelineCollapseItemLabeledValue
  from '@/vue_apps/ClinicalGuidelines/components/ClinicalGuidelineCollapseItemLabeledValue.vue'
import { i18nScopeMixin } from '@/vue_present/mixins/i18nScopeMixin'
import MButton from '@/vue_present/_base/buttons/MButton/MButton.vue'

export default defineComponent({
  name: 'ClinicalGuidelineCollapseItemEntry',

  components: {
    MButton,
    ClinicalGuidelineCollapseItemLabeledValue,
    ClinicalGuidelineCollapseItem,
    MCollapseItem,
  },

  mixins: [i18nScopeMixin],

  props: {
    activeNames: { type: Array as PropType<Array<string | number>>, required: true },
    collapseItemId: { type: String as PropType<CLINICAL_GUIDELINE_COLLAPSE_ITEM>, required: true },
    clinicalGuidelineEntries: { type: Array as PropType<ClinicalGuidelineEntryItem[]>, required: true },
  },

  created () {
    this.setI18nScope('clinicalGuidelines.catalog.entry')
  },

  methods: {
    getHealthcareBenchmarkValue (healthcareBenchmark: boolean) {
      return healthcareBenchmark
        ? t('yes')
        : t('no')
    },

    onAdd (item: ClinicalGuidelineEntryItem) {
      tinymce.activeEditor.insertContent(`${item.title} `)
    },
  },
})
</script>
