import TemplateRecordModal from '../components/modal/template_record_modal'
import { registerListeners } from '../listeners'

// user modules
import Dispatcher from '@/plugins/dynamic_forms/_core/dispatcher'
import BackendEditor from '../components/editor/backend'
import CategoriesWrap from '../components/common/catogories_wrap'
import BaseTemplateBuilder from './BaseTemplateBuilder'
import ContextMenu from '../components/context_menu/constructor_mode_context_menu'
import MathEditor from '../components/modal/math_editor.js'
import VariableEditor from '../components/modal/variable_editor.js'
import ListEditor from '../components/modal/list_editor.js'
import EntitiesModal from '../components/modal/protocol_entities_modal'
import Api from '../api/Api'
import EntityTree from '../tree/entity_tree'
import DiseasesTree from '../tree/diseases_tree'
import { entityConfigs } from '../configuration/main_config'
import { getMap } from '../configuration/mapping'
import EntityManager from '../entities/EntityManager'
import EntityFactory from '../entities/factory'
import MkbModal from '../components/modal/mkb_modal'
import EntryVariableModal from '../components/modal/entry_variables_modal'
import PrintAreaModal from '../components/modal/print_area_modal'
import PrintAreaManager from '../entities/print_area_manager'

export class TemplateRecordBuilder extends BaseTemplateBuilder {
  setMapping () {
    const customMap = {
      appId: 'template_record_constuctor_mode',
      newItemButton: document.querySelector('#add-item'),
      form: document.querySelector('#template-record-form'),
      categoryIdInput: document.querySelector('#template_record_category_id'),
      htmlInput: document.querySelector('#template_record_template_html'),
      dataInput: document.querySelector('#template_record_template_data'),
      categoryTreeContainer: document.querySelector('#category'),
    }

    const baseMap = getMap('backend')
    this.map = Object.assign({}, baseMap, customMap)
    this.panelMap = getMap('panel')
    this.categoriesMap = {
      autoRun: true,
      category: $('#category'),
      addCategoryButton: $('#add-category'),
      search: $('#templates_records_catalog_search'),
    }
  }

  initComponents () {
    this.container.add('store', this.store)
    this.container.add('dispatcher', new Dispatcher(this.container))
    const entityManager = new EntityManager(this.container)
    registerListeners(this.container)
    this.container.add('entity_manager', entityManager)
    this.container.add('contextMenu', new ContextMenu(this.map, this))
    this.container.add('config', entityConfigs)
    this.container.add('entities_modal', new EntitiesModal(this.container))
    this.container.add('entity_tree', new EntityTree(this.container))
    this.container.add('api', new Api(this.container))
    this.container.add('entity_factory', new EntityFactory(this.map, this))
    this.container.add('variables', new VariableEditor(this.panelMap.variables, this))
    this.container.add('calculate', new MathEditor(this.panelMap.math, this))
    this.container.add('list', new ListEditor(this.panelMap.list, this))
    this.container.add('mkb_modal', new MkbModal(this.container))
    this.container.add('diseases_tree', new DiseasesTree(this.container))
    this.container.add('entry_variables_modal', new EntryVariableModal(this.container))
    this.container.add('editor', new BackendEditor(this.map, this))
    this.container.add('print_area_manager', new PrintAreaManager(this.container))
    this.container.add('print_area_modal', new PrintAreaModal(this.container))

    // ==============
    this.addComponent('categories', new CategoriesWrap(this.categoriesMap, this))
    this.addComponent('modal', new TemplateRecordModal(this.map, this))
    // closure debug
    const entityModal = this.container.get('entities_modal')
    const mkbModal = this.container.get('mkb_modal')
    entityModal.saveButton.on('click', entityModal.save.bind(entityModal))
    mkbModal.saveButton.on('click', mkbModal.save.bind(mkbModal))
  }

  create () {
    return this
  }
}
