import { MListServiceNestedItemsItem } from '@/_api/_requests/MListServiceNestedItemsItem'

export class NestedItemsItem<ItemClass extends MListServiceNestedItemsItem, ItemProps> {
  private items: Record<string, ItemClass> = {}

  private getNextId: () => number = Utils.getIdGenerator()

  private readonly Class: new (...args: unknown[]) => ItemClass

  constructor (Class: new (...args: unknown[]) => ItemClass) {
    this.Class = Class
  }

  getMap () {
    return this.items
  }

  getArr (): ItemClass[] {
    return Object.values(this.items)
  }

  getItem (id: number | string): ItemClass | undefined {
    return this.items[id]
  }

  getItems (ids: Array<number | string>): Array<ItemClass | undefined> {
    return ids.map((id) => this.getItem(id))
  }

  set (itemsArr: ItemProps[]) {
    this.items = itemsArr.reduce((acc, item) => {
      const id = this.getNextId()

      acc[id] = new this.Class(id, item)

      return acc
    }, {})
  }

  append (item?: ItemProps) {
    return Object.values(
      this.appendMany([item || {} as ItemProps])
    )[0]
  }

  appendMany (items: ItemProps[]): Record<string, ItemClass> {
    if (!items.length) return {}

    const newItems: Record<string, ItemClass> = items.reduce((acc, item: ItemProps) => {
      const id = this.getNextId()

      acc[id] = new this.Class(id, item)

      return acc
    }, {})

    this.items = {
      ...this.items,
      ...newItems,
    }

    return newItems
  }

  replaceMany (items: Array<[number, ItemProps]>): Record<string, ItemClass> {
    if (!items.length) return {}

    const newItems: Record<string, ItemClass> = items.reduce((acc, [id, item]: [number, ItemProps]) => {
      acc[id] = new this.Class(id, item)

      return acc
    }, {})

    this.items = {
      ...this.items,
      ...newItems,
    }

    return newItems
  }

  replace (id: number, item: ItemProps) {
    return Object.values(
      this.replaceMany([[id, item]])
    ).at(0)
  }

  remove (id: number | string) {
    return this.removeMany([id])[id]
  }

  removeMany (ids: Array<number | string>) {
    if (!ids.length) return {}

    const items: Record<string, ItemClass> = ids.reduce((acc, id) => {
      acc[id] = this.items[id]

      delete this.items[id]

      return acc
    }, {})

    this.items = { ...this.items }

    return items
  }

  purge () {
    this.items = {}
  }

  getValues (): unknown[] {
    return this.getArr()
      .map((item) => item.getValues())
  }
}
