import { applicationConf, frontend } from '@/plugins/dynamic_forms/configuration/config'
import { registerListeners } from '@/plugins/dynamic_forms/listeners'
import EntityManager from '@/plugins/dynamic_forms/entities/EntityManager'
import Api from '@/plugins/dynamic_forms/api/Api'
import { entityConfigs } from '@/plugins/dynamic_forms/configuration/main_config'
import FrontendEditor from '@/plugins/dynamic_forms/components/editor/frontend_editor'
import PrintAreaManager from '@/plugins/dynamic_forms/entities/print_area_manager'
import Dispatcher from '@/plugins/dynamic_forms/_core/dispatcher'
import EntityFactory from '@/plugins/dynamic_forms/entities/factory'
import { PROTOCOL_BUILDER_NAME } from '@/vue_apps/ProtocolsApp/consts/const'
import { getSanitizedHtml } from '@/vue_apps/ProtocolsApp/entities/ProtocolBuilder/getSanitizedHtml'
import { getSanitizedData } from '@/vue_apps/ProtocolsApp/entities/ProtocolBuilder/getSanitizedData'
import BaseTemplateBuilder from '@/plugins/dynamic_forms/builders/BaseTemplateBuilder'
import { ProtocolApi } from '@/vue_apps/ProtocolsApp/entities/ProtocolApi'

export class ProtocolBuilder extends BaseTemplateBuilder {
  editor: FrontendEditor

  // @ts-ignore
  map: {
    appId: string
    mode: string
    editorToolbar: HTMLDivElement
    editorMountPoint: HTMLDivElement
    editorWrap: HTMLDivElement
    editorId: string
    editorSettings: Record<string, any>
    contextMEmitClass: string
  }

  setMapping () {
    const editorSettings = {
      ...frontend.editor,
      menubar: frontend.editor.menubar.replace('tools', ''),
      toolbar2: frontend.editor.toolbar2.replace('change_editor_margin', ''),
    }

    this.map = {
      appId: 'entry_edit_mode',
      mode: 'edit',
      editorToolbar: document.querySelector('#frontend-toolbar'),
      editorMountPoint: document.querySelector('#frontend-mount-point'),
      editorId: 'frontend-mount-point',
      editorSettings,
      contextMEmitClass: applicationConf.listClass,
      editorWrap: document.querySelector('#editor-body'),
    }
  }

  initComponents () {
    registerListeners(this.container)

    this.container.add('store', this.store)
    this.container.add('dispatcher', new Dispatcher(this.container))
    this.container.add('entity_manager', new EntityManager(this.container))
    this.container.add('api', new Api(this.container))
    this.container.add('config', entityConfigs)
    this.container.add('entity_factory', new EntityFactory(this.map, this))
    this.editor = new FrontendEditor(this.map, this)
    this.container.add('editor', this.editor)
    this.container.add('print_area_manager', new PrintAreaManager(this.container))
    this.container.add('builderName', PROTOCOL_BUILDER_NAME)
  }

  getSanitizedHtml (html: string) {
    return getSanitizedHtml(html)
  }

  getSanitizedData (state: Record<string, any>) {
    return getSanitizedData(state)
  }

  create () {
    return this
  }

  setProtocolApi (protocol: ProtocolApi<any>) {
    this.container.add('protocol', protocol)
  }
}
