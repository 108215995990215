import { SEMD_ENTITIES_ACTIONS } from '@/vue_apps/ProtocolsApp/_SemdProtocolEditor/reducers/semdEntitiesActions'
import { cloneDeep, omit } from 'lodash'

export const semdEntitiesReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case (SEMD_ENTITIES_ACTIONS.LOAD): {
      return cloneDeep(payload)
    }

    case (SEMD_ENTITIES_ACTIONS.SET_VALUE): {
      return { ...state, [payload.field]: payload.value }
    }

    case (SEMD_ENTITIES_ACTIONS.DROP_FIELD): {
      return omit(state, payload.field)
    }

    case (SEMD_ENTITIES_ACTIONS.CLEAR): {
      return {}
    }

    default: return state
  }
}
