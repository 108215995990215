import {
  ClinicalGuidelineLogic,
} from '@/vue_apps/catalogs_root/ClinicalGuidelinesCatalog/classes/ClinicalGuideline/ClinicalGuidelineLogic'
import { ICRUDReturn } from '@/_api/decorators/api/interfaces/ICRUDReturn'
import {
  clinicalGuidelineAdapter,
} from '@/vue_apps/catalogs_root/ClinicalGuidelinesCatalog/api/clinicalGuidelineAdapter'
import { Api } from '@/_api/decorators/api/Api'
import { Callback } from '@/_api/decorators/callback'
import {
  clinicalGuidelineEntryAdapter,
} from '@/vue_apps/catalogs_root/ClinicalGuidelinesCatalog/api/clinicalGuidelineEntryAdapter'
import {
  ClinicalGuidelineEntryItem,
} from '@/vue_apps/catalogs_root/ClinicalGuidelinesCatalog/classes/ClinicalGuidelineEntryItem/ClinicalGuidelineEntryItem'
import { IApiErrors } from '@/_declarations/IApiErrors'
import {
  clinicalGuidelinesCategoriesAdapter,
} from '@/vue_apps/catalogs_root/ClinicalGuidelinesCatalog/api/clinicalGuidelinesCategoriesAdapter'
import { TClinicalGuidelineProps } from '@/vue_apps/catalogs_root/ClinicalGuidelinesCatalog/interfaces/types'
import { IList } from '@/_declarations/IList'
import {
  IClinicalGuidelineListItem,
} from '@/vue_present/Reuse/Lists/ClinicalGuidelines/interfaces/IClinicalGuidelineListItem'
import {
  IClinicalGuidelineToClientDTO,
} from '@/vue_apps/catalogs_root/ClinicalGuidelinesCatalog/interfaces/IClinicalGuidelineToClientDTO'
import { ICatalog } from '@/_declarations/ICatalog'
import { orNull } from '@/_declarations/orNull'

export class ClinicalGuidelineApi extends ClinicalGuidelineLogic {
  @Callback('fillProps')
  @Api.notify('fetchMessage', 'clinicalGuideline', { success: false })
  @Api.clientAdapter(clinicalGuidelineAdapter)
  @Api.get(Routes.api_internal_clinical_guideline_path)
  fetch (id: number) {
    return {
      routeArgs: [id],
    } as ICRUDReturn as Promise<TClinicalGuidelineProps | IApiErrors>
  }

  @Callback('fillProps')
  @Api.notify('submitMessage', 'clinicalGuideline')
  @Api.clientAdapter(clinicalGuidelineAdapter)
  @Api.submit(Routes.api_internal_clinical_guidelines_path, Routes.api_internal_clinical_guideline_path)
  @Api.serverAdapter(clinicalGuidelineAdapter)
  submit () {
    return {
      routeArgs: [this.id],
      payload: this.getAttributes(),
    } as ICRUDReturn as Promise<IClinicalGuidelineToClientDTO | IApiErrors>
  }

  @Api.notify('destroyMessage', 'clinicalGuideline')
  @Api.delete(Routes.api_internal_clinical_guideline_path)
  destroy () {
    return {
      routeArgs: [this.id],
    } as ICRUDReturn as Promise<void | IApiErrors>
  }

  @Api.notify('catalogsMessage', 'clinicalGuideline', { success: false })
  @Api.clientAdapter(clinicalGuidelinesCategoriesAdapter)
  @Api.post(Routes.clinical_guidelines_api_internal_categories_path)
  fetchCategories () {
    return {} as ICRUDReturn as Promise<IList<IClinicalGuidelineListItem[]> | IApiErrors>
  }

  @Callback('setCategory')
  @Api.notify('fetchMessage', 'clinicalGuidelineCategory', { success: false })
  @Api.post(Routes.resolve_category_by_code_string_api_internal_clinical_guidelines_path)
  fetchCategoryByCodeString (codeString: string) {
    return {
      payload: {
        codeString,
      },
    } as ICRUDReturn as Promise<orNull<ICatalog<number>> | IApiErrors>
  }

  @Api.notify('updateMessage', 'clinicalGuideline')
  @Api.clientAdapter(clinicalGuidelineEntryAdapter)
  @Api.submit(Routes.api_internal_clinical_guidelines_entries_path, Routes.api_internal_clinical_guidelines_entry_path)
  @Api.serverAdapter(clinicalGuidelineEntryAdapter)
  submitClinicalGuidelineEntry (payload: ClinicalGuidelineEntryItem) {
    return {
      routeArgs: [payload.dbId],
      payload: {
        ...payload,
        clinicalGuidelineId: this.id,
      },
    } as ICRUDReturn as Promise<object | IApiErrors>
  }

  @Api.notify('updateMessage', 'clinicalGuideline')
  @Api.delete(Routes.api_internal_clinical_guidelines_entry_path)
  destroyClinicalGuidelineEntry (id: number) {
    return { routeArgs: [id] } as ICRUDReturn as Promise<void | IApiErrors>
  }
}
