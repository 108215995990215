import { MListServiceItem } from '@/_api/_requests/MListServiceItem'
import { MTableCell } from '@/vue_present/_base/Tables/MTableGenerator/MTableCell'
import { getPDFStatus } from '@/vue_apps/MedicalRecords/EntryProtocols/entities/getPDFStatus'
import { vueFilters } from '@/plugins/vue/filtersPlugin/vueFilters'
import { ProtocolApi } from '@/vue_apps/ProtocolsApp/entities/ProtocolApi'

export class ProtocolsListItem extends MListServiceItem {
  /**
   * @param {IProtocolListItem} data
   */
  constructor (data) {
    super()

    this.id = data.id

    const info = [
      data.title,
      data.doctor?.shortName,
    ].filter(Boolean).join(', ')

    this.title = new MTableCell(info)
      .addTooltip(true)
      .addCut()

    this.date = new MTableCell(vueFilters.date(data.date))

    this.info = info

    this.setEdit(data.doctor)
    this.setPdfGenerated(data.pdfGenerated)
    this.setDraft(false)

    this.newTab = new MTableCell()
      .addTooltip(t('open_new_tab'))
      .addTypeIcon('link')
      .addClasses('primary')
      .addPreventDefault()

    this.setDeletable(data)
  }

  setEdit (doctor) {
    const editEnabled = Services.security.canManageEntry ||
      (!Services.security.canManageEntry && doctor?.id === gon.application.current_user.id)

    this.edit = new MTableCell()
      .addTypeIcon('edit')

    if (editEnabled) {
      return this.edit
        .addTooltip(t('edit'))
        .addClasses('warning')
        .addPreventDefault()
    }

    this.edit
      .addTooltip(t('insufficient_access_rights'))
      .addClasses('grey')
  }

  setDeletable (data) {
    this.canDelete = Boolean(
      Services.security.canManageEntry ||
      data.doctor?.id === gon.application.current_user.id
    )

    this.dataAttributes = {
      ...this.dataAttributes,
      'data-cant-delete': !this.canDelete,
    }
  }

  setPdfGenerated (pdfGenerated) {
    const pdfStatus = getPDFStatus(pdfGenerated)
    this.pdfGenerated = new MTableCell()
      .addTypeIcon('filePdf')
      .addClasses(pdfStatus.color)
      .addTooltip(pdfStatus.tooltip)

    this.icon = {
      icon: 'filePdf',
      color: pdfStatus.color,
      tooltip: pdfStatus.tooltip,
    }
  }

  setDraft (value = true) {
    if (!value) {
      this.draft = new MTableCell()

      return
    }

    this.draft = new MTableCell()
      .addTypeIcon('history')
      .addTooltip(t('protocols.restoreFromDraft'))
      .addPreventDefault()
  }

  destroy () {
    return new ProtocolApi({ id: this.id }).delete()
  }
}
