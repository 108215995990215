<template>
  <el-card
    class="m-card"
    shadow="never"
  >
    <div class="m-card__body">
      <slot />
    </div>

    <div
      v-if="useFooter"
      class="m-card__footer"
    >
      <slot name="footer" />
    </div>
  </el-card>
</template>

<script>
export default {
  name: 'MCard',

  props: {
    useFooter: Boolean,
  },
}
</script>
