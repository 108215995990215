import { TLazyTreeNode, TLazyTreeRef } from '@/vue_present/Reuse/LazyTree/interfaces/types'
import { LazyTreeNode } from '@/vue_present/Reuse/LazyTree/store/LazyTreeNode'
import { get } from 'lodash/object'

const getBeforeNode = (parentNode: TLazyTreeNode, node: LazyTreeNode, sortBy: string) => {
  return parentNode.childNodes
    .find((childNode) =>
      get(childNode.data, sortBy).toLowerCase() > get(node, sortBy).toLowerCase())
}

export const appendLazyTreeNode = (
  treeRef: TLazyTreeRef,
  parentId: number,
  node: LazyTreeNode,
  sortBy = 'title'
) => {
  const parentNode = treeRef.getNode(parentId)
  if (!parentNode || !parentNode.loaded) { return }

  const nodeBefore = getBeforeNode(parentNode, node, sortBy)
  nodeBefore
    ? treeRef.insertBefore(node, nodeBefore.data)
    : treeRef.append(node, parentNode.data)
}
