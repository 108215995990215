import { ItemBase } from '@/_api/_classes/ItemBase'
import { IUser } from '@/_declarations/IUser'
import { ICatalog } from '@/_declarations/ICatalog'

const _permitType = <const> [
  'certificatePayment',
  'clinicIfnsCode',
  'clientIfnsCode',
  'signer',
  'mcdGuid',
]

export class TaxCertificate2024XmlModel extends ItemBase<typeof _permitType[number]> {
  _permit = Array.from(_permitType)

  certificatePayment: ICatalog = null

  clinicIfnsCode: string = ''

  clientIfnsCode: string = ''

  signer: IUser = null

  mcdGuid: string = ''
}
