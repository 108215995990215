import { ItemBase } from '@/_api/_classes/ItemBase'
import { TDateString } from '@/_declarations/TDateString'
import {
  ACQUIRING_TERMINAL_CONNECTION_STATUS,
} from '@/vue_apps/FinanceModule/FinanceModuleIndex/consts/Acquiring/AcquiringTerminalConnectionStatus'
import { ACQUIRING_STATUS_CODE } from '@/vue_apps/FinanceModule/FinanceModuleIndex/consts/Acquiring/AcquiringStatus'
import {
  TAcquiringRequestId,
} from '@/vue_apps/FinanceModule/FinanceModuleIndex/interfaces/Acquiring/TAcquiringRequestId'
import { AcquiringAwaitRequestListItem } from '@/vue_apps/catalogs_root/Workplaces/classes/AcquiringAwaitRequestListItem'

export class AcquiringTerminalModelBase extends ItemBase {
  _permit = [
    'terminalId',
    'connectionStatus',
  ]

  terminalId: string

  lastReconciliationOfResults: TDateString

  connectionStatus: ACQUIRING_TERMINAL_CONNECTION_STATUS

  status: ACQUIRING_STATUS_CODE = ACQUIRING_STATUS_CODE.INIT

  transactionDescription: string

  terminalLogs: string[] = []

  awaitRequestList: Record<TAcquiringRequestId, AcquiringAwaitRequestListItem> = {}

  workplaceId: number = gon.application.current_user.workplace_id

  constructor () {
    super()
    if (gon.application.trade_acquiring_module_enabled) { return }
    this.setConnectionStatus(ACQUIRING_TERMINAL_CONNECTION_STATUS.NOT_CONNECTED)
  }

  setConnectionStatus (connectionStatus: ACQUIRING_TERMINAL_CONNECTION_STATUS) {
    this.setValue('connectionStatus', connectionStatus)
  }

  isConnectionStatusActive () {
    return this.connectionStatus === ACQUIRING_TERMINAL_CONNECTION_STATUS.ACTIVE
  }

  isConnectionStatusInactive () {
    return this.connectionStatus === ACQUIRING_TERMINAL_CONNECTION_STATUS.INACTIVE
  }

  isConnectionStatusConnecting () {
    return this.connectionStatus === ACQUIRING_TERMINAL_CONNECTION_STATUS.CONNECTING
  }

  isConnectionStatusNotConnected () {
    return this.connectionStatus === ACQUIRING_TERMINAL_CONNECTION_STATUS.NOT_CONNECTED
  }

  isConnectionStatusNotConnectedOrConnecting () {
    return this.isConnectionStatusNotConnected() || this.isConnectionStatusConnecting()
  }

  protected hasRequestInAwaitList (requestId: TAcquiringRequestId) {
    return Boolean(this.awaitRequestList[requestId])
  }
}
