<template>
  <div class="fixed-document-block tax-certificate-2024-editor-personal-information">
    <m-prompt-notice :text="title" />

    <div class="tax-certificate-2024-editor-personal-information__person">
      <div class="flex position-relative">
        <!-- Фамилия -->
        <m-input
          :label="t('surname')"
          :value="client.surname"
          disabled
        />

        <a
          v-if="client.id"
          v-tooltip="t('infocard')"
          class="position-absolute"
          :href="$routes.client_path(client.id)"
          target="_blank"
          style="right: 7px; top: 7px"
        >
          <m-icon
            icon="link"
            color="primary"
          />
        </a>
      </div>

      <!-- ИНН -->
      <m-input
        :label="t('inn')"
        :value="client.inn"
        disabled
      />

      <!-- Имя -->
      <m-input
        :label="t('name')"
        :value="client.name"
        disabled
      />

      <!-- Дата рождения -->
      <m-date-picker
        :value="client.birthdate"
        :label="t('birthdate')"
        disabled
      />

      <!-- Отчество -->
      <m-input
        :label="t('second_name')"
        :value="client.secondName"
        disabled
      />
    </div>

    <m-prompt-notice
      v-show="hasInn"
      class="mb-indent-mid"
      type="alert"
      :text="t$('hasINNPromptNotice')"
    />

    <div
      v-show="!hasInn"
      class="tax-certificate-2024-editor-personal-information__document"
    >
      <!-- Вид документа -->
      <reusable-f-n-s-list
        :value="client.fnsIdCard"
        :required="!hasInn"
        :disabled="disabled"
        :validator-name="calcProp('fnsIdCard')"
        @change="setValue('fnsIdCard', $event)"
        @registerValidator="$registerValidator($event)"
      />

      <!-- Серия документа -->
      <m-input
        :value="client.idCard.series"
        :label="t('series')"
        :disabled="disabled"
        :max-length="30"
        :validator-name="calcProp('idCard.series')"
        @input="setValue('idCard.series', $event)"
        @registerValidator="$registerValidator($event)"
      />

      <!-- Номер документа -->
      <m-input
        :value="client.idCard.number"
        :label="t('id_card_number')"
        :required="!hasInn"
        :disabled="disabled"
        :max-length="30"
        :validator-name="calcProp('idCard.number')"
        @input="setValue('idCard.number', $event)"
        @registerValidator="$registerValidator($event)"
      />

      <!-- Дата выдачи документа -->
      <m-date-picker
        :value="client.idCard.issueDate"
        :label="t('document_issue_date')"
        value-format="yyyy-MM-dd"
        :required="issueDateRequired"
        :disabled="disabled"
        :validator-name="calcProp('issueDate')"
        @change="setValue('idCard.issueDate', $event)"
        @registerValidator="$registerValidator($event)"
      />
    </div>
  </div>
</template>

<script>
import MPromptNotice from '@/vue_present/_base/MPromptNotice/MPromptNotice.vue'
import MInput from '@/vue_present/_base/inputs/MInput/MInput.vue'
import { i18nScopeMixin } from '@/vue_present/mixins/i18nScopeMixin'
import MDatePicker from '@/vue_present/_base/inputs/MDatePicker/MDatePicker.vue'
import MIcon from '@/vue_present/_base/MIcon/MIcon.vue'
import {
  TaxCertificate2024,
} from '@/vue_apps/Documents/FixedDocuments/_documents/TaxCertificate2024/classes/TaxCertificate2024'
import ReusableFNSList from '@/vue_present/Reuse/Lists/FNS/ReusableFNSList.vue'
import { dateNotInFutureValidator } from '@/lib/validators/dateNotInFutureValidator'

export default {
  name: 'TaxCertificate2024EditorPersonalInformation',

  components: {
    ReusableFNSList,
    MIcon,
    MDatePicker,
    MPromptNotice,
    MInput,
  },

  mixins: [
    i18nScopeMixin,
  ],

  props: {
    title: { type: String, required: true },
    isTaxpayer: Boolean,
    certificate: TaxCertificate2024,
    disabled: Boolean,
  },

  computed: {
    clientProp () {
      return this.isTaxpayer
        ? 'taxpayer'
        : 'client'
    },

    /** @return {TaxCertificate2024Client} */
    client () {
      return this.certificate[this.clientProp]
    },

    hasInn () {
      return Boolean(this.client.inn)
    },

    issueDateRequired () {
      return !this.hasInn && this.dateNotInFutureValidator
    },
  },

  created () {
    this.setI18nScope('fixedDocument.taxCertificate2024')
  },

  methods: {
    dateNotInFutureValidator,

    calcProp (key) {
      return `${this.clientProp}.${key}`
    },

    setValue (key, value) {
      this.certificate.setValue(this.calcProp(key), value)
    },
  },
}
</script>
