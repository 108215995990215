<template>
  <m-modal
    class="measure-units__replace-measure-unit-modal"
    :visible="visible"
    :dialog-title="t$('deleting')"
    width="814px"
    @close="onClose"
  >
    <template #body>
      <div>
        <m-icon
          icon="warning"
          color="warning"
          class="measure-units__warning-icon"
        />

        {{ t$('deletingWarning') }}
      </div>

      <m-select-lazy
        :value="measureUnitForReplace"
        :fetch-method="MeasureUnitApi.list"
        :permanent-displayed-filter="omitDeletableMeasureUnitFilter"
        use-first-time-fetch
        :label="t$('title')"
        required
        full-width
        filterable
        validator-name="measure-unit"
        @registerValidator="onRegisterValidator"
        @changeByLazy="measureUnitForReplace = $event"
      />
    </template>

    <template #footer-left>
      <m-button-submit @click="save" />
    </template>
  </m-modal>
</template>

<script lang="ts">
import MModal from '@/vue_present/_base/MModal/MModal.vue'
import MSelectLazy from '@/vue_present/_base/MSelectLazy/MSelectLazy.vue'
import MButtonSubmit from '@/vue_present/_base/buttons/MButtonSubmit/MButtonSubmit.vue'
import MIcon from '@/vue_present/_base/MIcon/MIcon.vue'
import { ValidationParentMixin } from '@/vue_present/mixins/ValidationParentMixin'
import { i18nScopeMixin } from '@/vue_present/mixins/i18nScopeMixin'
import { defineComponent } from 'vue'
import { IMeasureUnit } from '@/_declarations/IMeasureUnit'
import {
  IReplaceMeasureUnitModalApi as IApi,
  TOpenReplaceMeasureUnitModalReturn as IOpenReturn,
} from '../interfaces/interfaces'
import { MeasureUnitApi } from '../entities/MeasureUnitApi'

export default defineComponent({
  name: 'ReplaceMeasureUnitModal',

  components: {
    MModal,
    MIcon,
    MSelectLazy,
    MButtonSubmit,
  },

  mixins: [
    ValidationParentMixin,
    i18nScopeMixin,
  ],

  emits: ['registerApi'],

  data () {
    return {
      visible: false,
      resolve: null as Function,
      measureUnitForReplace: null as IMeasureUnit,
      deletableMeasureUnitId: null as number,

      MeasureUnitApi,
    }
  },

  created () {
    this.setI18nScope('measureUnits')
    this.$emit('registerApi', { open: this.open } as IApi)
  },

  methods: {
    reset (deletableMeasureUnitId: number) {
      this.measureUnitForReplace = null
      this.deletableMeasureUnitId = deletableMeasureUnitId
      this.resetValidations()
    },

    open (deletableMeasureUnitId: number): IOpenReturn {
      this.reset(deletableMeasureUnitId)
      this.visible = true

      return new Promise((resolve) => { this.resolve = resolve })
    },

    close () {
      this.visible = false
    },

    save () {
      if (this.hasErrors()) { return }

      this.resolve({ idOfMeasureUnitForReplace: this.measureUnitForReplace.id })
      this.close()
    },

    onClose () {
      this.resolve({ cancel: true })
      this.close()
    },

    omitDeletableMeasureUnitFilter (_, measureUnitFromList: IMeasureUnit): boolean {
      return measureUnitFromList.id !== this.deletableMeasureUnitId
    },
  },
})
</script>
