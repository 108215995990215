import { appointment } from '@/vue_components/store/modules/appointment/appointment'
import Vue from 'vue/dist/vue.esm'
import Vuex from 'vuex'
import companies from './modules/companies/companies.js'
import feedbacks from './modules/feedbacks/feedbacks.js'
import employeesRating from './modules/feedbacks/employees_rating.js'
import nps from './modules/feedbacks/nps.js'
import doctorSchedules from './modules/doctor_schedules/doctor_schedules.js'
import { waitingList } from '@/vue_components/store/modules/waiting_list/waiting_list'
import { legalRepresentatives } from '@/vue_components/store/modules/legal_representatives/legal_representatives'
import { egisz } from '@/vue_components/store/modules/egisz'
import { medCasesState } from '@/vue_components/store/modules/medCases/medCasesState'
import { emdState } from '@/vue_components/store/modules/egiszEmd/emdState'
import { globalCatalogs } from '@/vue_components/store/modules/globalCatalogs/globalCatalogs'
import { windowStore } from '@/vue_components/store/modules/window'
import { actions, getters, mutations, state } from '@/vue_components/store/modules/global'
import { messagesStore } from '@/api_entities/messages/messagesStore'
import { appointmentTimeFinder } from '@/vue_apps/DoctorSchedules/AppointmentTimeFinder/store'
import { appointmentCallLogStore } from '@/vue_apps/DoctorSchedules/AppointmentCallLog/store/appointmentCallLogStore'
import { checkupsStore } from '@/vue_apps/CheckupsModule/store/checkupsStore'
import { headDesktopStore } from '@/vue_components/head_desktop/headDesktopStore'
import { wsStores } from '@/vue_components/store/modules/webSocket/wsStores'
import { acquiringStore } from '@/vue_apps/FinanceModule/FinanceModuleIndex/consts/Acquiring/acquiringStore'
import { weStore } from '@/vue_components/store/modules/we/weStore'

Vue.use(Vuex)

const modules = {
  feedbacks,
  employeesRating,
  nps,
  companies,
  doctorSchedules,
  waitingList,
  legalRepresentatives,
  appointment,
  egisz,
  globalCatalogs,
  medCases: medCasesState,
  egiszEmd: emdState,
  windowStore,
  appointmentTimeFinder,
  messagesStore,
  appointmentCallLogStore,
  checkupsStore,
  headDesktopStore,
  acquiringStore,
  weStore,
  ...wsStores,
}

export const store = new Vuex.Store({
  state,
  getters,
  mutations,
  actions,
  modules,
})

export const createStore = () => {
  store.dispatch('CONFIGURE_STORE')

  return store
}
