import { CatalogHelpers } from '@/helpers/CatalogHelpers'

export enum CLINICAL_GUIDELINE_ENTRY_KIND {
  CONSULTATION = 'consultation',
  PHYSICAL_EXAMINATION = 'physical_examination',
  LAB_EXAMINATION = 'lab_examination',
  INSTRUMENTAL_EXAMINATION = 'instrumental_examination',
  OTHER_EXAMINATION = 'other_examination',
  CONSERVATIVE_TREATMENT = 'conservative_treatment',
  SURGICAL_TREATMENT = 'surgical_treatment',
  OTHER_TREATMENT = 'other_treatment',
  MEDICAL_REHABILITATION = 'medical_rehabilitation',
  DISPENSARY_OBSERVATION = 'dispensary_observation',
  MEDICINE = 'medicine',
}

export const CLINICAL_GUIDELINE_ENTRY_KIND_ITEMS = CatalogHelpers.getCatalogItemsFromEnum<CLINICAL_GUIDELINE_ENTRY_KIND>(
  CLINICAL_GUIDELINE_ENTRY_KIND,
  'clinicalGuidelines.kind'
)
