<template>
  <m-form-show class="payment-order">
    <!-- Номер счета -->
    <m-form-show-row :label="t('order_number')">
      <template #value>
        <m-link
          :class="{ 'deleted': payment.destinationOrder.deletedAt}"
          :text="payment.destinationOrder.id"
          :href="destinationOrderLink"
        />
      </template>
    </m-form-show-row>

    <!-- Пациент -->
    <m-form-show-row :label="t('patient')">
      <template #value>
        <m-link
          :class="{ 'deleted': payment.destinationClient.deletedAt }"
          :text="payment.destinationClient.fullName"
          :href="destinationClientLink"
        />
      </template>
    </m-form-show-row>

    <!-- Врач -->
    <m-form-show-row :label="t('doctor')">
      <template #value>
        <div class="flex flex-wrap gap-indent-small">
          <m-link
            v-for="user in users"
            :key="user.id"
            :class="{ 'deleted': user.deletedAt }"
            :text="user.fullName"
            :href="user.link"
          />
        </div>
      </template>
    </m-form-show-row>

    <!-- Запись на прием -->
    <m-form-show-row
      v-if="payment.destinationOrder.appointments.length"
      :label="t('appointment')"
      :value="payment.comment"
    >
      <template #value>
        <div class="flex flex-column gap-indent-small">
          <div
            v-for="appointment in payment.destinationOrder.appointments"
            :key="appointment.id"
            class="flex flex-column"
          >
            <span>
              {{ t('custom_time') }}
              -
              {{ appointment.time }}
            </span>

            <span
              v-for="cabinet in appointment.cabinets"
              :key="cabinet.id"
            >
              {{ t('cabinet') }}
              {{ cabinet.title }}
            </span>
          </div>
        </div>
      </template>
    </m-form-show-row>

    <!-- Ассистент -->
    <m-form-show-row
      v-if="payment.destinationOrder.assistants.length"
      :label="t('assistant')"
    >
      <template #value>
        <div class="payment-order__assistant-list">
          <m-link
            v-for="assistant in payment.destinationOrder.assistants"
            :key="assistant.id"
            :class="{ 'deleted': assistant.deletedAt }"
            :text="assistant.fullName"
            :href="assistant.link"
          />
        </div>
      </template>
    </m-form-show-row>

    <!-- Дата -->
    <m-form-show-row
      :label="t('custom_date')"
      :value="payment.destinationOrder.date"
    />

    <!-- Создан -->
    <m-form-show-row
      :label="t('created_by')"
      :value="payment.destinationOrder.createdBy"
    />

    <!-- Сумма -->
    <m-form-show-row
      :label="t('sum')"
      :value="$filters.currency(payment.destinationOrder.finalSum)"
    />

    <!-- Рабочее место -->
    <m-form-show-row
      v-if="payment.workplace"
      :label="t('workplace')"
      :value="payment.workplace.title"
    />
  </m-form-show>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { PaymentApi } from '@/vue_apps/FinanceModule/FinanceModuleIndex/classes/Payment/PaymentApi'
import MFormShow from '@/vue_present/_base/MFormShow/MFormShow.vue'
import MFormShowRow from '@/vue_present/_base/MFormShow/MFormShowRow.vue'
import MLink from '@/vue_present/_base/MLink/MLink.vue'
import {
  TPaymentDestinationOrderUser,
} from '@/vue_apps/FinanceModule/FinanceModuleIndex/interfaces/TPaymentDestinationOrderUser'

export default defineComponent({
  name: 'PaymentShowOrder',

  components: {
    MLink,
    MFormShowRow,
    MFormShow,
  },

  props: {
    payment: PaymentApi,
  },

  computed: {
    destinationOrderLink () {
      return Routes.order_path(this.payment.destinationOrder.id)
    },

    destinationClientLink () {
      return Routes.client_path(this.payment.destinationClient.id)
    },

    users () {
      return this.payment.destinationOrder.users.map((user: TPaymentDestinationOrderUser) => ({
        ...user,
        link: Routes.user_path(user.id),
      }))
    },
  },
})
</script>
