export enum ACQUIRING_OPERATION {
  CHECKING_CONNECTION = 'checkingConnection',
  TERMINAL_INFO = 'terminalInfo',
  PRINT_LAST_OPERATION_RECEIPT = 'printLastOperationReceipt',
  PRINT_SLIP = 'printSlip',
  PAY_CARD = 'payCard',
  PAY_SBP = 'paySBP',
  REFUND_CARD = 'refundCard',
  REFUND_SBP = 'refundSBP',
  CANCEL_CARD = 'cancelCard',
  SBP_PAYMENT_STATUS = 'SBPPaymentStatus',
  SBP_REFUND_STATUS = 'SBPRefundStatus',
  FORCE_COMPLETE = 'forceComplete',
  RECONCILIATION_OF_RESULTS = 'reconciliationOfResults',
  COPY_OF_RECEIPT = 'copyOfReceipt',
  REPORT_FULL = 'reportFull',
  REPORT_SUMMARY = 'reportSummary',
}

export const ACQUIRING_PAYMENT_OPERATIONS = [
  ACQUIRING_OPERATION.PAY_CARD,
  ACQUIRING_OPERATION.PAY_SBP,
  ACQUIRING_OPERATION.REFUND_CARD,
  ACQUIRING_OPERATION.REFUND_SBP,
  ACQUIRING_OPERATION.CANCEL_CARD,
]
