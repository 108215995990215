<template>
  <m-modal
    :visible="visible"
    :dialog-title="dialogTitle"
    class="expense-modal"
    :type="dialogType"
    width="30%"
    @update:visible="$updateSync('visible', $event)"
  >
    <template #body>
      <m-input
        v-model="tempFormData.title"
        required
        :disabled="!canManage"
        validator-name="expense-title"
        :label="t('finance.expensesModalForm.title')"
        @registerValidator="onRegisterValidator"
      />

      <m-date-picker
        v-model="tempFormData.date"
        :date-format="dateFormat"
        value-format="yyyy-MM-dd"
        required
        :disabled="!canManage"
        validator-name="expense-date"
        :label="t('finance.expensesModalForm.date')"
        @registerValidator="onRegisterValidator"
      />

      <!-- Группа расходов -->
      <div class="expenses-groups flex gap-indent-small">
        <m-select
          v-model="tempFormData.expenseGroup"
          :items="groups"
          :disabled="!canManage"
          :label="t('finance.expensesModalForm.expenseGroupTitle')"
        />

        <m-button
          v-if="canManage"
          v-tooltip="t('finance.addExpenseGroup')"
          template="add"
          @click="createEGVisible = true"
        />

        <expenses-group-add-modal
          :visible.sync="createEGVisible"
          @expensesGroupSaved="expensesGroupSaved"
        />
      </div>

      <m-input
        v-model="tempFormData.price"
        type="number"
        :disabled="!canManage"
        validator-name="expense-price"
        :label="t('finance.expensesModalForm.orderId')"
        @registerValidator="onRegisterValidator"
      />

      <m-textarea
        v-model="tempFormData.additional"
        :label="t('finance.expensesModalForm.additional')"
        :autosize="{ minRows: 3, maxRows: 10 }"
        :disabled="!canManage"
      />
    </template>

    <template #footer-left>
      <m-button-delete
        v-if="canManage && tempFormData.number"
        use-button
        @yes="deleteExpense"
      />
    </template>

    <template #footer-right-action>
      <m-button
        v-if="canManage"
        class="expense-modal__save"
        template="save"
        :disabled="loading"
        @click="saveExpense"
      />
    </template>
  </m-modal>
</template>

<script>
import MModal from '@/vue_present/_base/MModal/MModal.vue'
import MButton from '@/vue_present/_base/buttons/MButton/MButton.vue'
import MInput from '@/vue_present/_base/inputs/MInput/MInput.vue'
import MDatePicker from '@/vue_present/_base/inputs/MDatePicker/MDatePicker.vue'
import MSelect from '@/vue_present/_base/inputs/MSelect/MSelect.vue'
import { MExpensePresenter } from '@/vue_apps/FinanceModule/FinanceModuleIndex/api/MExpensePresenter'
import { ValidationParentMixin } from '@/vue_present/mixins/ValidationParentMixin'
import MTextarea from '@/vue_present/_base/inputs/MTextarea/MTextarea.vue'
import ExpensesGroupAddModal from '@/vue_apps/FinanceModule/FinanceModuleIndex/components/ExpensesGroupAddModal.vue'
import MButtonDelete from '@/vue_present/_base/buttons/MButtonDelete/MButtonDelete.vue'
import { MODES } from '@/vue_apps/catalogs_root/_catalog_base/const/const.js'
import { SpinnerHolder } from '@/vue_components/mixins/spinner_holder'

export default {
  name: 'ExpenseModal',

  components: {
    MButtonDelete,
    ExpensesGroupAddModal,
    MTextarea,
    MSelect,
    MDatePicker,
    MInput,
    MButton,
    MModal,
  },

  mixins: [
    ValidationParentMixin,
    SpinnerHolder,
  ],

  props: {
    visible: Boolean,

    item: { type: Object, default: () => ({}) },

    mode: { type: String, default: '' },

    groups: {
      type: Array,
      default: () => [],
    },

    canManage: Boolean,
  },

  data () {
    return {
      tempFormData: this.item,
      dateFormat: Utils.elDateFormat,
      createEGVisible: false,
      modalExpenseGroupTitle: '',
      MODES,
    }
  },

  computed: {
    dialogTitle () {
      return this.mode === MODES.CREATE
        ? t('finance.expensesModalTitles.create')
        : t('finance.expensesModalTitles.edit')
    },

    dialogType () {
      return this.mode === MODES.CREATE
        ? 'success'
        : 'warning'
    },
  },

  methods: {
    onCloseModal () {
      this.$updateSync('visible', false)
      this.$emit('resetList')
    },

    async saveExpense () {
      if (this.hasValidationErrors()) { return }

      const res = await this.withSpinnerSimple(new MExpensePresenter().submit({
        ...this.tempFormData,
        id: this.tempFormData.number,
      }))

      if (res?.errors) { return this.resetValidations(res.errors) }

      this.onCloseModal()
    },

    async deleteExpense () {
      const res = await new MExpensePresenter().destroy(this.tempFormData.number)
      if (res?.errors) { return this.resetValidations(res.errors) }
      this.onCloseModal()
    },

    expensesGroupSaved (group) {
      this.tempFormData.expenseGroup = group

      this.$emit('resetList')
      this.$emit('resetExpensesGroupsList')
    },
  },
}
</script>
