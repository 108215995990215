import { CatalogHelpers } from '@/helpers/CatalogHelpers'

export enum CLINICAL_GUIDELINE_RELEASE_STATUS {
  ACTIVE = 'active',
  NOT_ACTIVE = 'not_active',
  DENIED = 'denied',
}

export const CLINICAL_GUIDELINE_RELEASE_STATUS_ITEMS = CatalogHelpers.getCatalogItemsFromEnum<CLINICAL_GUIDELINE_RELEASE_STATUS>(
  CLINICAL_GUIDELINE_RELEASE_STATUS,
  'clinicalGuidelines.releaseStatus'
)

export const getClinicalGuidelineReleaseStatusCssClassColor = (releaseStatus: CLINICAL_GUIDELINE_RELEASE_STATUS): string | undefined => {
  const map = {
    [CLINICAL_GUIDELINE_RELEASE_STATUS.ACTIVE]: 'success',
    [CLINICAL_GUIDELINE_RELEASE_STATUS.DENIED]: 'warning',
    [CLINICAL_GUIDELINE_RELEASE_STATUS.NOT_ACTIVE]: 'gray',
  }

  return map[releaseStatus]
}
