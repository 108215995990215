<template>
  <m-popover
    v-show="visibility"
    :popper-class="`entity-draggable-window draggable-window draggable-window_${popoverClassModifier}`"
    inherited-visibility
    visibility
  >
    <template #all>
      <div
        :data-id="DRAGGABLE_AREA"
        class="draggable-window__title draggable-window__draggable-area cut"
        :class="[`draggable-window__title_${headColor}`]"
      >
        {{ safeTitle }}
        <m-icon
          class="cursor-default ml-auto"
          icon="close"
          @click="$emit('cancel')"
        />
      </div>

      <div class="draggable-window__body">
        <component
          :is="currentComponent"
          v-if="entity"
          :entity="entity"
          :value.sync="value"
        />
      </div>

      <div
        v-if="requireFooter"
        class="draggable-window__footer"
      >
        <m-button
          v-if="!onlyClosableEntities"
          type="text"
          :text="t('cancel')"
          @click="$emit('cancel')"
        />

        <m-button-submit
          v-if="!onlyClosableEntities"
          @click="$emit('save', value)"
        />

        <m-button
          v-if="onlyClosableEntities"
          template="close"
          @click="$emit('cancel')"
        />
      </div>
    </template>
  </m-popover>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import MPopover from '@/vue_present/_base/MPopover/MPopover.vue'
import Entity from '@/plugins/dynamic_forms/entities/items/Entity'
import {
  DRAGGABLE_AREA,
} from '@/plugins/dynamic_forms/components/context_menu/editor_mode/ui/TextareaProxyRoot/draggable'
import MButton from '@/vue_present/_base/buttons/MButton/MButton.vue'
import MButtonSubmit from '@/vue_present/_base/buttons/MButtonSubmit/MButtonSubmit.vue'
import { DIAGNOSES_CONSTRUCTOR, FIX_LIST } from '@/plugins/dynamic_forms/configuration/reducer_types'
import MIcon from '@/vue_present/_base/MIcon/MIcon.vue'

export default defineComponent({
  name: 'EntityDraggableWindow',

  components: {
    MIcon,
    MButtonSubmit,
    MButton,
    MPopover,
  },

  props: {
    entity: { type: Object as PropType<Entity>, default: null },
  },

  emits: [
    'cancel',
    'save',
  ],

  data () {
    return {
      value: null,

      DRAGGABLE_AREA,
    }
  },

  computed: {
    currentComponent () {
      if (!this.entity) { return null }

      return this.entity.options.component
    },

    headColor () {
      return 'primary'
    },

    visibility () {
      return Boolean(this.entity)
    },

    safeTitle () {
      return this.entity?.options?.title || ''
    },

    popoverClassModifier () {
      return this.entity?.type || 'default'
    },

    requireFooter () {
      return ![FIX_LIST].includes(this.entity?.type)
    },

    onlyClosableEntities () {
      return [DIAGNOSES_CONSTRUCTOR].includes(this.entity?.type)
    },
  },
})
</script>
