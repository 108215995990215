<template>
  <validation-wrapper
    :errors="validationErrors"
    :m-fixed-height="mFixedHeight"
    class="position-relative"
    :class="specialClasses"
  >
    <el-select
      :id="id"
      ref="elSelect"
      v-tooltip="canManage.tooltip"
      v-el-select-lazy="() => { $emit('loadMore') } "
      class="m-select"
      :value-key="valueKey"
      :value="adaptedValue"
      :placeholder="placeholder"
      :class="{
        'w-100': fullWidth,
        'm-select_search-style': useSearchStyle
      }"
      :clearable="clearableStrict"
      :filterable="filterable"
      :filter-method="filterMethod"
      :disabled="canManage.disabled"
      :multiple="multiple"
      :multiple-limit="multipleLimit"
      :collapse-tags="collapseTags"
      :allow-create="allowCreate"
      :popper-class="poperClass"
      :no-data-text="noDataText"
      @focus="focused = true"
      @blur="focused = false; $emit('blur', $event); __validate()"
      @change="__onChange($event)"
      @visible-change="onVisibleChange"
      @remove-tag="$emit('removeTag', $event)"
      @handleOptionClick="$emit(
        'handleOptionClick',
        { option: $event.value, selected: !$event.itemSelected }
      )"
      @clear="$emit('clear')"
    >
      <template #prefix>
        <slot name="prefix">
          <user-avatar
            v-if="useUserMode"
            :user-id="__extractUserId(value)"
            :params="{ version: 'thumb16', tag: value && value.avatarTag }"
            :style="{ marginTop: '1px', marginLeft: '1px' }"
          />

          <template v-if="useIcon">
            <m-icon
              v-if="value && typeof value.icon === 'string'"
              :icon="value.icon"
              :color="value.color"
              :use-brand="value.useBrand"
              class="option-icon"
            />
            <m-icon
              v-else-if="value && typeof value.icon === 'object'"
              :icon="value.icon.icon"
              :color="value.icon.color || value.icon.css"
              :use-brand="value.useBrand"
              class="option-icon"
            />
          </template>
        </slot>
      </template>

      <slot name="options">
        <el-option
          v-for="(item, index) in displayedItems"
          :key="`option:${item[valueKey]}:${index}`"
          :value="item"
          :label="item[optionLabel]"
          :disabled="item.disabled"
          class="m-option"
        >
          <slot
            name="option"
            :option="item"
          >
            <div class="m-option__container">
              <user-avatar
                v-if="useUserMode"
                class="mr-5"
                :user-id="item[valueKey]"
                :params="{ version: 'thumb16', tag: item.avatarTag }"
              />

              <template v-if="useIcon">
                <m-icon
                  v-if="typeof item.icon === 'string'"
                  :icon="item.icon"
                  :color="item.color"
                  :use-brand="item.useBrand"
                  class="option-icon"
                />
                <m-icon
                  v-else-if="typeof item.icon === 'object'"
                  :icon="item.icon.icon"
                  :color="item.icon.color || item.icon.css"
                  :use-brand="item.useBrand"
                  class="option-icon"
                />
              </template>

              <span class="m-option__label">{{ item[optionLabel] }}</span>
            </div>
          </slot>

          <slot
            name="optionNotice"
            :item="item"
            :option-notice="optionNotice"
          >
            <span
              v-if="optionNotice && item[optionNotice]"
              class="m-option__notice prompt-notice"
            >
              {{ item[optionNotice] }}
            </span>
          </slot>
        </el-option>
      </slot>
    </el-select>
    <m-label
      v-if="label"
      :id="id"
      :label="label"
      :required="isRequiredField && !requiredWhenValue"
      :disabled="canManage.disabled"
      :focused="focused"
    />
  </validation-wrapper>
</template>

<script>
import { CommonInputProps } from '@/vue_present/_base/inputs/mixins/CommonInputProps'
import { CommonInputMethods } from '@/vue_present/_base/inputs/mixins/CommonInputMethods'
import { ValidationChildMixin } from '@/vue_present/mixins/ValidationChildMixin'
import ValidationWrapper from '@/vue_components/common/validation_wrapper'
import MLabel from '@/vue_present/_base/inputs/MLabel'
import { DisabledMixin } from '@/vue_present/mixins/DisabledMixin'
import { MSelectProps } from '@/vue_present/_base/inputs/MSelect/MSelectProps'
import UserAvatar from '@/vue_components/user_avatar.vue'
import { DEFAULT_RESULT_TYPES } from '@/vue_present/_base/buttons/MButtonsGroup/MButtonsGroupsConst'
import MIcon from '@/vue_present/_base/MIcon/MIcon.vue'
import { snakeToCamel } from '@/_api/_requests/helpers'

export default {
  name: 'MSelect',
  components: { MIcon, UserAvatar, MLabel, ValidationWrapper },
  mixins: [
    CommonInputProps,
    CommonInputMethods,
    DisabledMixin,
    ValidationChildMixin,
  ],

  model: {
    prop: 'value',
    event: 'change',
  },

  props: {
    ...MSelectProps,
    customFilter: { type: Function, default: null },
    useUserMode: Boolean,
    allowCreate: Boolean,
    useIcon: Boolean,
    multipleLimit: { type: Number, default: undefined },

    /**
     * 🩼🩼🩼
     * для избежания дублирования операций,
     * когда родитель это MSelectLazy:
     * 1) adaptedValue
     * 2) __onChange
     */
    useInParentSelect: Boolean,
  },

  emits: [
    'loadMore',
    'filterValueChange',
    'hideOptions',
    'changeItem',
    'openOptions',
    'handleOptionClick',
    'removeTag',
    'blur',
    'clear',
  ],

  data () {
    return {
      isFilterDebounce: null,
      displayedItems: this.items || [],
      focused: false,
      noDataText: null,
    }
  },

  computed: {
    specialClasses () {
      return {
        'w-100': this.fullWidth,
        'el-select-multi': this.multiple && this.collapseTags,
        'more-than-1': this.multiple && this.collapseTags && this.value?.length > 1,
      }
    },

    /**
     * Включает clearable только тогда, когда есть в value есть значение
     * @returns {boolean}
     */
    clearableStrict () {
      if (this.clearable === false) { return false }

      return Boolean(
        typeof this.value === 'object' && !Array.isArray(this.value)
          ? this.value?.[this.valueKey]
          : this.value
      )
    },

    adaptedValue () {
      if (this.useInParentSelect) { return this.value }
      if (!this.value || typeof this.value === 'object') { return this.value }
      if (this.useCustomResult === DEFAULT_RESULT_TYPES.OBJECT) { return this.value }

      const item = this.items.find((item) => item[this.valueKey] === this.value)
      if (item) { return item }

      return { [this.valueKey]: this.value }
    },
  },

  watch: {
    items (to) {
      this.displayedItems = [...to]
    },
  },

  created () {
    this.__setNoDataText('')
  },

  methods: {
    /**
     * Фильтрация по строке (минимум minFilterValueLength символов)
     * @param filterValue
     */
    filterMethod (filterValue) {
      if (!this.filterable) { return }

      this.__setNoDataText(filterValue)

      if (filterValue?.length < this.minFilterValueLength) { filterValue = '' }

      if (!filterValue) {
        this.$emit('filterValueChange', '')

        if (this.customFilter) {
          this.displayedItems = [...this.items]
        }

        return
      }

      if (this.customFilter) {
        this.$nextTick(() => {
          this.displayedItems = this.items.filter(this.customFilter(filterValue))
        })

        return
      }

      clearTimeout(this.isFilterDebounce)
      this.isFilterDebounce = setTimeout(() => {
        this.$emit('filterValueChange', filterValue)
      }, this.debounce)
    },

    __setNoDataText (filterValue) {
      this.noDataText =
          filterValue.length < this.minFilterValueLength &&
          this.filterable
            ? Utils.getPleaseEnterLeast(filterValue, this.minFilterValueLength)
            : this.noDataCustomText
    },

    __extractUserId (user) {
      if (this.useCustomResult === 'simple') {
        return user || undefined
      }

      if (!user || typeof user !== 'object') { return null }

      const camelUser = snakeToCamel(user)

      return Utils.ternary(
        camelUser.userId !== undefined,
        camelUser.userId,
        camelUser.id
      )
    },

    /**
     * Очистка фильтра + отображение полного списка при закрытии дропдауна
     * @param {Boolean} value
     */
    onVisibleChange (value) {
      if (value) { return this.$emit('openOptions') }
      this.focused = false
      setTimeout(() => {
        this.filterMethod('')
        this.$emit('hideOptions', value)
      }, 100)
    },

    __onChange (value) {
      if (this.useInParentSelect) { return this.onChange(value) }

      if (this.useCustomResult === DEFAULT_RESULT_TYPES.SIMPLE) {
        this.onChange(value ? value[this.valueKey] : null)
        this.$emit('changeItem', value)

        return
      }

      this.onChange(value)
    },
  },
}
</script>
