import {
  CLINICAL_GUIDELINE_RELEASE_STATUS,
} from '@/vue_apps/catalogs_root/ClinicalGuidelinesCatalog/consts/clinicalGuidelineReleaseStatus'
import { ICRUDReturn } from '@/_api/decorators/api/interfaces/ICRUDReturn'
import { IApiErrors } from '@/_declarations/IApiErrors'
import { Api } from '@/_api/decorators/api/Api'
import {
  clinicalGuidelineMassActionAdapter,
} from '@/vue_apps/catalogs_root/ClinicalGuidelinesCatalog/api/ClinicalGuidelineMassActionAdapter'

export class ClinicalGuidelineMassActionApi {
  @Api.notify('updateMessage', 'clinicalGuidelines')
  @Api.post(Routes.update_statuses_api_internal_clinical_guidelines_path)
  @Api.serverAdapter(clinicalGuidelineMassActionAdapter.changeReleaseStatusToServer)
  changeReleaseStatus (categoryIds: number[], clinicalGuidelineIds: number[], releaseStatus: CLINICAL_GUIDELINE_RELEASE_STATUS) {
    return {
      payload: {
        categoryIds,
        clinicalGuidelineIds,
        releaseStatus,
      },
    } as ICRUDReturn as Promise<void | IApiErrors>
  }

  @Api.notify('destroyMessage', 'clinicalGuidelines')
  @Api.post(Routes.batch_destroy_api_internal_clinical_guidelines_path)
  @Api.serverAdapter(clinicalGuidelineMassActionAdapter.baseAdapter)
  destroyMany (categoryIds: number[], clinicalGuidelineIds: number[]) {
    return {
      payload: {
        categoryIds,
        clinicalGuidelineIds,
      },
    } as ICRUDReturn as Promise<void | IApiErrors>
  }
}
