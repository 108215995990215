const BUTTONS_TEXT_SCOPE = 'front_book.button_text'

const text = (shortText) => { return t(shortText, { scope: BUTTONS_TEXT_SCOPE }) }

export const BUTTONS_TEMPLATES = Object.freeze({
  save: {
    text: text('save'),
    type: 'success',
    icon: 'save',
  },

  save_orange: {
    text: text('save'),
    type: 'warning',
    icon: 'save',
  },

  edit: {
    text: text('edit'),
    type: 'warning',
    icon: 'change',
  },

  delete: {
    text: text('delete'),
    type: 'danger',
    icon: 'delete',
  },

  close: {
    text: text('close'),
    type: 'primary',
    icon: 'close',
  },

  save_and_appointment: {
    text: text('save_and_create_appointment'),
    type: 'warning',
    icon: 'download',
  },

  clear: {
    text: text('clear'),
    type: 'primary',
    icon: 'clear',
  },

  refresh: {
    text: text('refresh'),
    type: 'primary',
    icon: 'reset',
  },

  unite: {
    text: text('unite'),
    type: 'primary',
    icon: 'unite',
  },

  bill: {
    text: text('bill'),
    type: 'default',
    icon: 'shopping-cart',
  },

  send: {
    text: text('send'),
    type: 'success',
    icon: 'send',
  },

  report: {
    text: text('report'),
    type: 'primary',
    icon: 'report',
  },

  cropImage: {
    type: 'success',
    icon: 'cut',
    text: text('cropImage'),
  },

  upload: {
    type: 'success',
    icon: 'upload',
    text: text('upload'),
  },

  confirm: {
    text: text('confirm'),
    type: 'primary',
  },

  print: {
    text: text('print'),
    type: 'dark',
    icon: 'print',
  },

  saveAndPrint: {
    text: text('saveAndPrint'),
    type: 'warning',
    icon: 'download',
  },

  addAndSave: {
    text: text('addAndSave'),
    type: 'success',
    icon: 'save',
  },

  saveAndCreate: {
    text: text('saveAndCreate'),
    type: 'warning',
    icon: 'download',
  },

  //Сокращённые кнопки без текста
  short_save: {
    type: 'success',
    icon: 'save',
    tooltip: text('save'),
  },

  short_save_orange: {
    type: 'warning',
    icon: 'save',
    tooltip: text('save'),
  },

  short_edit: {
    type: 'warning',
    icon: 'change',
    tooltip: text('change'),
  },

  short_delete: {
    type: 'danger',
    icon: 'delete',
    tooltip: text('delete'),
  },

  short_close: {
    type: 'primary',
    icon: 'close',
    tooltip: text('close'),
  },

  short_save_and_appointment: {
    type: 'warning',
    icon: 'download',
    tooltip: text('send'),
  },

  short_clear: {
    type: 'primary',
    icon: 'clear',
    tooltip: text('clear'),
  },

  short_refresh: {
    type: 'primary',
    icon: 'reset',
    tooltip: text('refresh'),
  },

  short_reset_green: {
    type: 'success',
    icon: 'reset',
    tooltip: text('reset'),
  },

  shortResetDefault: {
    type: 'default',
    icon: 'reset',
    tooltip: text('reset'),
  },

  short_refresh_green: {
    type: 'success',
    icon: 'reset',
    tooltip: text('refresh'),
  },

  shortUpload: {
    type: 'success',
    icon: 'upload',
    tooltip: text('upload'),
  },

  shortBill: {
    type: 'warning',
    icon: 'shopping-cart',
    tooltip: text('bill'),
  },

  shortPrint: {
    type: 'dark',
    icon: 'print',
    tooltip: text('print'),
  },

  add: {
    type: 'success',
    icon: 'plus',
  },

  callOut: {
    type: 'success',
    icon: 'callOut',
    tooltip: text('callOut'),
  },

  smsOut: {
    type: 'primary',
    icon: 'smsOut',
    tooltip: text('smsOut'),
  },

  whatsappOut: {
    type: 'success',
    icon: 'whatsappOut',
    tooltip: text('whatsappOut'),
  },

  transfer: {
    type: 'success',
    icon: 'transfer',
    text: text('add'),
  },
})
