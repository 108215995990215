import { ItemBase } from '@/_api/_classes/ItemBase'
import { IPaymentToClientDTO } from '@/vue_apps/FinanceModule/FinanceModuleIndex/interfaces/IPaymentToClientDTO'
import { PAYMENT_KINDS } from '@/vue_apps/FinanceModule/FinanceModuleIndex/consts/paymentKinds'
import { orNull } from '@/_declarations/orNull'
import { PAYMENT_DISTRIBUTION } from '@/vue_apps/FinanceModule/FinanceModuleIndex/consts/paymentDistribution'
import { PAYMENT_BASE_KIND } from '@/vue_apps/FinanceModule/FinanceModuleIndex/consts/paymentBaseKind'
import { PaymentEntryItem } from '@/vue_apps/FinanceModule/FinanceModuleIndex/classes/PaymentEntryItem'
import { IPaymentRefundable } from '@/vue_apps/FinanceModule/FinanceModuleIndex/interfaces/IPaymentRefundable'
import { ICatalog } from '@/_declarations/ICatalog'
import { PAYMENT_REFUND_METHODS } from '@/vue_apps/FinanceModule/FinanceModuleIndex/consts/paymentRefundMethods'
import {
  paymentRefundRefundMethodItems,
} from '@/vue_apps/FinanceModule/FinanceModuleIndex/consts/paymentRefundRefundMethodItems'
import { TPaymentPayer } from '@/vue_apps/FinanceModule/FinanceModuleIndex/interfaces/IPaymentPayer'
import { PAYMENT_RECALCULATE_EVENT } from '@/vue_apps/FinanceModule/FinanceModuleIndex/consts/paymentRecalculateEvent'

export class PaymentDistributorModel extends ItemBase {
  _permit = [
    'kind',
    'baseKind',
    'byCash',
    'byCashless',
    'byCard',
    'byBalance',
    'byCredit',
    'distribution',
    'deposit',
    'totalIncome',
    'totalPaid',
    'surrender',
    'refundable',
    'paymentEntries',
  ]

  byCash: number = 0

  byCashless: number = 0

  byCard: number = 0

  byBalance: number = 0

  byCredit: number = 0

  baseKind: PAYMENT_BASE_KIND

  kind: PAYMENT_KINDS

  initialKind: PAYMENT_KINDS

  distribution: orNull<PAYMENT_DISTRIBUTION> = PAYMENT_DISTRIBUTION.AUTO_DISTRIBUTION

  deposit: number = 0

  totalIncome: number = 0

  totalPaid: number = 0

  finalSum: number = 0

  notDistributed: number = 0

  transferSum: number = 0 // Сумма перевода сдачи на лицевой счёт

  surrender: number = 0

  totalRequired: number = null

  paymentEntries: PaymentEntryItem[] = []

  refundable: orNull<IPaymentRefundable>

  refundTo: ICatalog<PAYMENT_REFUND_METHODS> = paymentRefundRefundMethodItems.at(0)

  payer: TPaymentPayer

  recalculateEvent: orNull<PAYMENT_RECALCULATE_EVENT> = null

  constructor (props?: IPaymentToClientDTO) {
    super()

    this.fillProps(props)
  }

  getAttributes (attributes: string[] = this._permit): Record<string, unknown> {
    return {
      ...super.getAttributes(attributes),
      paymentEntries: this.paymentEntries.map((entry) => entry.getValues()),
    }
  }

  fillProps (props?: IPaymentToClientDTO) {
    if (!props) { return }

    this._fillProps(props)

    if (!props.destinationOrder) {
      return
    }

    const totalRequired = Utils.toMoney(props.destinationOrder.finalSum - props.destinationOrder.paidSum)
    super.setValue('totalRequired', totalRequired)

    const finalSum = Utils.toMoney(props.destinationOrder.finalSum)
    super.setValue('finalSum', finalSum)
  }
}
