import { ProtocolApi } from '@/vue_apps/ProtocolsApp/entities/ProtocolApi'
import { getActiveSemdEntitiesMap } from '@/vue_apps/ProtocolsApp/consts/getActiveSemdEntitiesMap'

export const onUpdateSemdDetails =
  async (protocol: ProtocolApi<any>) => {
    // todo: попробовать заменить getActiveSemdEntitiesMap на protocol.semdEntities

    protocol.fillSemdData({
      ...protocol.semdsDocumentDetailsAttributes?.data,
      ...getActiveSemdEntitiesMap(protocol.semdType, protocol.templateHtml),
    })

    return protocol.updateDetails()
  }
