import {
  ACQUIRING_OPERATION_TYPE,
} from '@/vue_apps/FinanceModule/FinanceModuleIndex/consts/Acquiring/AcquiringOperationType'

export enum ACQUIRING_OPERATION_TYPE_CODE {
    PAY,
    REFUND,
    CANCEL,
}

const operationTypeByCodeMap = {
  [ACQUIRING_OPERATION_TYPE_CODE.PAY]: ACQUIRING_OPERATION_TYPE.PAY,
  [ACQUIRING_OPERATION_TYPE_CODE.REFUND]: ACQUIRING_OPERATION_TYPE.REFUND,
  [ACQUIRING_OPERATION_TYPE_CODE.CANCEL]: ACQUIRING_OPERATION_TYPE.CANCEL,
}

export const getOperationTypeByCode = (operationTypeCode: ACQUIRING_OPERATION_TYPE_CODE) => {
  return operationTypeByCodeMap[operationTypeCode]
}
