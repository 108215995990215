export const GRPC_END_OF_STREAM = 'eof'

export enum GRPC_FORMAT_TYPE {
    JSON = 'JSON',
    CSV = 'CSV',
}

export enum GRPC_PAYLOAD_TYPE {
    TOTAL = 'total',
    DATA = 'data',
}

export enum GRPC_META_TYPE {
    RESULT = 'result',
    ERROR = 'error',
}
