import {
  TPrintRawReceiptInfoItem,
} from '@/vue_apps/catalogs_root/Workplaces/interfaces/PrintRawReceipt/IPrintRawReceiptInfo'
import { PropType } from 'vue'
import { greaterThanZeroValidator } from '@/lib/validators/greaterThanZeroValidator'

export const PrintRawReceiptModalItemsMixin = {

  data () {
    return {
      catalogItemsPrefix: null,
    }
  },

  props: {
    items: {
      type: Array as PropType<TPrintRawReceiptInfoItem[]>,
      required: true,
    },
  },

  computed: {
    catalogItems () {
      return this.items.map((item: TPrintRawReceiptInfoItem) => ({
        id: item,
        title: t(`${this.catalogItemsPrefix}.${item}`),
      }))
    },
  },

  methods: {
    greaterThanZeroValidator,

    setCatalogItemsPrefix (prefix: string) {
      this.catalogItemsPrefix = prefix
    },
  },
}
