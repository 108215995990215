import { IAcquiringFilters } from '@/vue_apps/FinanceModule/FinanceModuleIndex/interfaces/Acquiring/IAcquiringFilters'
import { DATE_PICKER_BUTTONS, dateButtons } from '@/vue_present/_base/MDatePickerButtons/helpers'
import { TDatePeriod } from '@/_declarations/TDatePeriod'
import {
  getDefaultStatusFilterItem,
} from '@/vue_apps/FinanceModule/FinanceModuleIndex/consts/Acquiring/statusFilterItems'

export const getAcquiringDefaultFilters = (): IAcquiringFilters => ({
  date: dateButtons[DATE_PICKER_BUTTONS.TODAY].value as unknown as TDatePeriod,
  operationType: null,
  user: null,
  status: getDefaultStatusFilterItem(),
  workplace: null,
  paymentType: null,
})
