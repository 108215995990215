import { IWeStoreState } from '@/vue_components/store/modules/we/IWeStoreState'

export const weStore = {
  namespaced: true,

  state: {
    modules: {
      acquiring: false,
      crypto: false,
      fiscalPrinter: false,
      general: false,
      laboratory: false,
      mdlpModule: false,

    },
  },

  getters: {
    vxGetAllModulesState: (state: IWeStoreState) => state.modules,
    vxGetAcquiringModuleState: (state: IWeStoreState) => state.modules.acquiring,
    vxGetCryptoModuleState: (state: IWeStoreState) => state.modules.crypto,
    vxGetFiscalPrinterModuleState: (state: IWeStoreState) => state.modules.fiscalPrinter,
    vxGetGeneralModuleState: (state: IWeStoreState) => state.modules.general,
    vxGetLaboratoryModuleState: (state: IWeStoreState) => state.modules.laboratory,
    vxGetMdlpModuleState: (state: IWeStoreState) => state.modules.mdlpModule,
  },

  mutations: {
    vxResetAllModules (state: IWeStoreState) {
      Object.keys(state.modules).forEach((key) => {
        state.modules[key] = false
      })
    },

    vxSetModuleState (state: IWeStoreState, payload: { module: string; value: boolean }) {
      state.modules[payload.module] = payload.value
    },
  },
}
