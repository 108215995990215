import { GRPCReceiverAbstract } from '@/_api/_classes/GRPC/Receiver/GRPCReceiverAbstract'
import { IgRPCReceiverConfig } from '@/_api/_classes/GRPC/interfaces/IgRPCReceiverConfig'
import { IgRPCBatch } from '@/_api/_classes/GRPC/interfaces/igRPCBatch'
import { orNull } from '@/_declarations/orNull'
import { MEDIA_TYPES } from '@/helpers/mediaTypes'
import { EEncoding } from '@/_declarations/enums/EEncoding'

export class GRPCBuilderJSON<
  SendStrategyData = Record<string, any>,
  SendStrategyResult = Promise<void>
> extends GRPCReceiverAbstract<object, object, object[] | void, SendStrategyData, SendStrategyResult> {
  private readonly fileName: orNull<string> = null

  private readonly encoding: EEncoding

  constructor (config: IgRPCReceiverConfig) {
    super(config)
    this.toServer = config?.adapter?.toServer || ((data: unknown) => data)
    this.fileName = config?.fileName ?? null
    this.encoding = config?.encoding ?? gon.application.export_encoding
  }

  send (payload: SendStrategyData): SendStrategyResult {
    return super.send(payload)
  }

  protected processData (data: IgRPCBatch[]) {
    this.data = data.reduce((
      acc: object[],
      { batch }) => acc.concat(batch),
    [])
  }

  protected fillData () {
    return this.fileName
      ? Utils.downloadExportFile(
        JSON.stringify(this.data),
        this.fileName,
        MEDIA_TYPES.JSON,
        this.encoding
      )
      : this.data
  }

  protected resetProps () {
    super.resetProps()
    this.data = []
  }
}
