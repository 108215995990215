import { ItemBase } from '@/_api/_classes/ItemBase'
import { ICatalog } from '@/_declarations/ICatalog'
import { ICheckupClient, ICheckupClientSearch } from '@/vue_apps/CheckupsModule/interfaces/ICheckupClientSearch'
import { FAST_CREATE_STATUSES } from '@/vue_apps/CheckupsModule/store/personalCheckupFastCreate/fastCreateStatuses'
import {
  FAST_CREATE_DEFAULT_PHONE,
  FAST_CREATE_NO_DUPLICATES,
} from '@/vue_apps/CheckupsModule/store/personalCheckupFastCreate/fastCreateConsts'
import { IFastCreateClient } from '@/vue_apps/CheckupsModule/interfaces/IFastCreateClient'

export class FastCreateClientModel extends ItemBase {
  _permit = [
    'id',
    'name',
    'surname',
    'secondName',
    'sex',
    'birthdate',
    'phone',
    'snils',
    'position',
    'nsiProfession',
    'patientCardNumber',
    'date',
    'nextDate',
    'factors',
  ]

  key: string = null

  status: FAST_CREATE_STATUSES = null

  selectedClient: ICheckupClient = null

  searchResult: ICheckupClientSearch = null

  useNSIProfession: boolean = gon.application.egisz_module_enabled

  disabledFields: Record<string, boolean> = {}

  id: number = null

  patientCardNumber: string = null

  name: string = null

  surname: string = null

  secondName: string = null

  sex: ICatalog = null

  birthdate: string = null

  phone: string = FAST_CREATE_DEFAULT_PHONE

  snils: string = null

  position: string = null

  nsiProfession: ICatalog = null

  department: string = null

  date: string[] = null

  nextDate: string = null

  factors: ICatalog[] = []

  constructor (props?: Partial<IFastCreateClient>) {
    super()

    this._fillProps(props)
  }

  get noDuplicates () {
    return this.searchResult?.data?.length === FAST_CREATE_NO_DUPLICATES
  }

  getAttributes (attributes: string[] = this._permit) {
    return {
      ...super.getAttributes(attributes),
      patientCardNumber: gon.application.changeable_medcard_numbers
        ? this.patientCardNumber
        : null,
    }
  }
}
