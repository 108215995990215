<template>
  <span>
    {{ semdEntity.title }}<required-mark v-if="!semdEntity.isOptional" />
  </span>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import { ISemdEntity } from '@/vue_apps/ProtocolsApp/_SemdProtocolEditor/interfaces/ISemdEntity'
import RequiredMark from '@/vue_components/common/required_mark.vue'

export default defineComponent({
  name: 'SemdEntityOptionLabel',

  components: { RequiredMark },

  props: {
    semdEntity: {
      type: Object as PropType<ISemdEntity>,
      required: true,
    },
  },
})
</script>
