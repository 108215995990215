import { IWorkplaceToServerDTO } from '@/vue_apps/catalogs_root/Workplaces/interfaces/IWorkplaceToServerDTO'
import { extractId, orNull } from '@/_medods_standart_library/msl'

export const workplaceAdapter = {
  toServer (data: IWorkplaceToServerDTO) {
    return {
      title: data.title,
      userIds: data.users.map(extractId),
      useCurrentUser: data.useCurrentUser,
      cashierId: orNull(data.cashier?.id),
    }
  },
}
