<template>
  <div class="clinical-guidelines-catalog-tree__actions">
    <clinical-guidelines-catalog-tree-change-release-status-popover
      :disabled="disabled"
      @onReleaseStatusChange="onReleaseStatusChange"
    />

    <m-button
      icon="export"
      :text="t('export')"
      type="warning"
      :disabled="disabled"
      @click="onExport"
    />

    <m-button-delete
      use-button
      :disabled="disabled"
      :popover-message="t('clinicalGuidelines.catalog.destroyManyPopoverMessage')"
      @yes="onDestroyMany"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import ClinicalGuidelinesCatalogTreeChangeReleaseStatusPopover
  from '@/vue_apps/catalogs_root/ClinicalGuidelinesCatalog/components/ClinicalGuidelinesCatalogTreeChangeReleaseStatusPopover.vue'
import MButton from '@/vue_present/_base/buttons/MButton/MButton.vue'
import MButtonDelete from '@/vue_present/_base/buttons/MButtonDelete/MButtonDelete.vue'
import { PSEUDO_ROOT_NODE_ID } from '@/vue_present/Reuse/LazyTree/const'
import { extractItemId } from '@/vue_present/Reuse/DocumentTree/store/extractItemId'
import { getLazyTreeLeafNodeId } from '@/vue_present/Reuse/LazyTree/store/lazyTreeLeafNodeIdPrefix'
import { ICatalog } from '@/_declarations/ICatalog'
import {
  CLINICAL_GUIDELINE_RELEASE_STATUS,
  CLINICAL_GUIDELINE_RELEASE_STATUS_ITEMS,
} from '@/vue_apps/catalogs_root/ClinicalGuidelinesCatalog/consts/clinicalGuidelineReleaseStatus'
import {
  ClinicalGuidelineMassActionApi,
} from '@/vue_apps/catalogs_root/ClinicalGuidelinesCatalog/api/ClinicalGuidelineMassActionApi'
import {
  ClinicalGuidelineApi,
} from '@/vue_apps/catalogs_root/ClinicalGuidelinesCatalog/classes/ClinicalGuideline/ClinicalGuidelineApi'
import { CatalogHelpers } from '@/helpers/CatalogHelpers'
import { updateLazyTreeNodeById } from '@/vue_present/Reuse/LazyTree/helpers/updateLazyTreeNodeById'
import {
  CLINICAL_GUIDELINES_CATALOG_ROUTE_NAMES,
} from '@/vue_apps/catalogs_root/ClinicalGuidelinesCatalog/router/clinicalGuidelinesCatalogRouteNames'
import { GRPCBuilderJSON } from '@/_api/_classes/GRPC/Receiver/GRPCBuilderJSON'
import {
  clinicalGuidelineMassActionAdapter,
} from '@/vue_apps/catalogs_root/ClinicalGuidelinesCatalog/api/ClinicalGuidelineMassActionAdapter'
import { EEncoding } from '@/_declarations/enums/EEncoding'
import { EWSChannels } from '@/services/declarations/EWSChannels'
import { GRPCReceiverSendStrategyWS } from '@/_api/_classes/GRPC/Receiver/SendStrategy/GRPCReceiverSendStrategyWS'

export default defineComponent({
  name: 'ClinicalGuidelinesCatalogTreeActions',

  components: {
    MButtonDelete,
    MButton,
    ClinicalGuidelinesCatalogTreeChangeReleaseStatusPopover,
  },

  props: {
    clinicalGuideline: { type: ClinicalGuidelineApi, required: true },
    treeRef: { type: Object, required: true },
    disabled: Boolean,
  },

  emits: [
    'setLoading',
    'resetCheckedNotes',
  ],

  data () {
    return {
      clinicalGuidelineMassAction: new ClinicalGuidelineMassActionApi(),
      jsonBuilder: new GRPCBuilderJSON({
        sendStrategy: GRPCReceiverSendStrategyWS,
        sendStrategyConfig: {
          action: 'receive',
          metaAction: 'export',
        },
        wsChannel: EWSChannels.CLINICAL_GUIDELINES,
        wsAction: 'clinical_guidelines',
        adapter: {
          toServer: clinicalGuidelineMassActionAdapter.baseAdapter,
        },
        src: Routes.export_api_internal_clinical_guidelines_path(),
        successCallback: this.onSuccessExport.bind(this),
        errorCallback: this.onErrorExport.bind(this),
      }),
    }
  },

  methods: {
    collectCheckedNotesIds () {
      return this.treeRef.getCheckedNodes()
        .reduce((acc, item) => {
          if (item.id === PSEUDO_ROOT_NODE_ID) { return acc }

          item.isLeaf
            ? acc.itemsIds.push(Number(extractItemId(item.id)))
            : acc.categoryIds.push(item.id)

          return acc
        }, { categoryIds: [], itemsIds: [] })
    },

    async onReleaseStatusChange (value: ICatalog<CLINICAL_GUIDELINE_RELEASE_STATUS>) {
      const { categoryIds, itemsIds } = this.collectCheckedNotesIds()
      const res = await this.clinicalGuidelineMassAction.changeReleaseStatus(categoryIds, itemsIds, value.id)
      if (res?.errors) { return }

      this.updateActiveFormReleaseStatus(categoryIds, itemsIds, value.id)
      this.updateNodesReleaseStatus(itemsIds, value.id)
      this.$emit('resetCheckedNotes')
    },

    updateActiveFormReleaseStatus (categoryIds: number[], itemsIds: number[], releaseStatus: CLINICAL_GUIDELINE_RELEASE_STATUS) {
      if (!this.clinicalGuideline.id) { return }
      if (!categoryIds.includes(this.clinicalGuideline.category.id) && !itemsIds.includes(this.clinicalGuideline.id)) { return }
      this.clinicalGuideline.setValue('releaseStatus', CatalogHelpers.getItemFromCatalogItems(CLINICAL_GUIDELINE_RELEASE_STATUS_ITEMS, releaseStatus))
    },

    updateNodesReleaseStatus (itemsIds: number[], releaseStatus: CLINICAL_GUIDELINE_RELEASE_STATUS) {
      itemsIds.forEach((itemId: number) => {
        const nodeId = getLazyTreeLeafNodeId(itemId)
        const { data } = this.treeRef.getNode(nodeId)
        const updateData = {
          title: data.title,
          extraAttributes: {
            categoryId: data.extraAttributes.categoryId,
            releaseStatus,
          },
        }

        updateLazyTreeNodeById(this.treeRef, data.extraAttributes.categoryId, nodeId, updateData)
      })
    },

    onExport () {
      this.$emit('setLoading', true)
      const { categoryIds, itemsIds } = this.collectCheckedNotesIds()
      this.jsonBuilder.send({
        categoryIds,
        clinicalGuidelineIds: itemsIds,
      })
    },

    onSuccessExport (data: object[]) {
      const fileName = `${t('clinicalGuidelines.title')} ${t('export').toLocaleLowerCase()}`
      const json = JSON.stringify({
        clinicalGuidelines: data,
      })

      this.$emit('resetCheckedNotes')
      this.$emit('setLoading', false)
      Utils.downloadExportFile(json, fileName, 'application/json', EEncoding.UTF8)
    },

    onErrorExport () {
      this.$emit('setLoading', false)
    },

    async onDestroyMany () {
      const { categoryIds, itemsIds } = this.collectCheckedNotesIds()
      this.$emit('setLoading', true)
      const data = await this.clinicalGuidelineMassAction.destroyMany(categoryIds, itemsIds)
      this.$emit('setLoading', false)
      if (data?.errors) { return }

      this.checkedNodesCloseActiveForm()
      this.$emit('resetCheckedNotes')
      itemsIds.forEach((id: number) => {
        this.treeRef.remove(getLazyTreeLeafNodeId(id))
      })
    },

    checkedNodesCloseActiveForm () {
      if (this.$route.name !== CLINICAL_GUIDELINES_CATALOG_ROUTE_NAMES.EDIT) { return }

      const isActiveFormChecked = this.treeRef.getCheckedNodes()
        .find((item) => item.isLeaf
          ? this.clinicalGuideline.id === Number(extractItemId(item.id))
          : this.clinicalGuideline.originCategory.id === item.id)

      if (!isActiveFormChecked) { return }
      this.$router.replace({ name: CLINICAL_GUIDELINES_CATALOG_ROUTE_NAMES.INDEX })
    },
  },
})
</script>
