<template>
  <div class="semd-entity-nsi-measurement">
    <m-input
      :value="semdEntity.value.amount"
      :label="semdEntity.title"
      :m-fixed-height="false"
      type="number"
      :precision="2"
      :min="0"
      controls
      class="semd-entity-nsi-measurement__amount"
      @input="onChange({ ...semdEntity.value, amount: $event })"
    />

    <reusable-local-n-s-i-list
      class="semd-entity-nsi-measurement__units"
      :label="semdEntity.unitsTitle"
      :value="semdEntity.value.units"
      :dictionary="semdEntity.nsiDictionary"
      :version="semdEntity.nsiDictionaryVersion"
      :permitted-values="semdEntity.permittedValues"
      :m-fixed-height="false"
      :placeholder="t('choose')"
      @changeByLazy="onChange({ ...semdEntity.value, units: $event })"
      @firstTimeFetched="onListFirstTimeFetched"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import MInput from '@/vue_present/_base/inputs/MInput/MInput.vue'
import ReusableLocalNSIList from '@/vue_present/Reuse/Lists/NSI/ReusableLocalNSIList.vue'
import {
  SemdNsiMeasurement,
} from '@/vue_apps/ProtocolsApp/_SemdProtocolEditor/_SemdEntities/SemdNsiMeasurement/SemdNsiMeasurement'
import { INsiEntry } from '@/_declarations/INsiEntry'
import { defaultSearchFilter } from '@/helpers/lambda'

export default defineComponent({
  name: 'SemdNsiMeasurementView',

  components: { ReusableLocalNSIList, MInput },

  props: {
    semdEntity: {
      type: Object as PropType<SemdNsiMeasurement>,
      required: true,
    },
  },

  emits: [
    'change',
  ],

  methods: {
    onChange (value) {
      this.$emit('change', {
        semdEntity: this.semdEntity,
        newValue: value,
      })
    },

    onListFirstTimeFetched (items: INsiEntry[]) {
      if (this.semdEntity.value?.units) { return }
      if (!this.semdEntity.defaultValueTitle) { return }

      const units = items.find(defaultSearchFilter(this.semdEntity.defaultValueTitle))
      if (!units) { return }

      this.onChange({ amount: 0, units })
    },
  },
})
</script>
