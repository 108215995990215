import { ACQUIRING_STATUS } from '@/vue_apps/FinanceModule/FinanceModuleIndex/consts/Acquiring/AcquiringStatus'
import { tScoped } from '@/helpers/i18nHelpers'

const ts = tScoped('finance.transactionStatus')

export const STATUS_FILTER_ITEMS = [
  {
    id: ACQUIRING_STATUS.INIT,
    title: ts('init'),
  },
  {
    id: ACQUIRING_STATUS.SUCCESS,
    title: ts('success'),
  },
  {
    id: ACQUIRING_STATUS.FAILURE,
    title: ts('failure'),
  },
  {
    id: ACQUIRING_STATUS.IN_PROGRESS,
    title: ts('inProgress'),
  },
  {
    id: ACQUIRING_STATUS.EXECUTION_ERROR,
    title: ts('executionError'),
  },
] as const

export const getDefaultStatusFilterItem = () => STATUS_FILTER_ITEMS.at(1)
