import { MSemdApi } from '@/vue_apps/Semds/entities/_SemdBase/MSemdApi'
import { ISemd119MetaData } from '@/vue_apps/Semds/entities/Semd119/ISemd119MetaData'
import {
  ISemd119SemdsDocumentDetailsAttributesData,
} from '@/vue_apps/Semds/entities/Semd119/ISemd119SemdsDocumentDetailsAttributesData'
import {
  CONSULTATION_ENTITY_NAMES,
} from '@/vue_apps/ProtocolsApp/_SemdProtocolEditor/entities/Semd119/semd119EntityNames'
import { SEMD_TYPE } from '@/vue_apps/Semds/const/semdTypes'
import { Semd119MetaData } from '@/vue_apps/Semds/entities/Semd119/Semd119MetaData'
import { MSemdPresenter } from '@/_api/MSemdApi/MSemdPresenter'
import { IApiErrors } from '@/_declarations/IApiErrors'
import { ISemd } from '@/_declarations/ISemd'
import { pick } from 'lodash'
import { orNull } from '@/_medods_standart_library/msl'
import { PROTOCOLS_ID } from '@/vue_apps/MedicalRecords/MedicalRecordsTabs/components/DocumentsTab/consts'

export class Semd119 extends MSemdApi<
  ISemd119MetaData,
  ISemd119SemdsDocumentDetailsAttributesData> {
  requiredEntities = [
    CONSULTATION_ENTITY_NAMES.NSI_MKB_DIAGNOSIS_GROUP,
    CONSULTATION_ENTITY_NAMES.NSI_CASE_VISIT_TYPE,
    CONSULTATION_ENTITY_NAMES.NSI_MEDICAL_CARE_PLACE,
    CONSULTATION_ENTITY_NAMES.NSI_PATIENT_CONDITION,
  ]

  requiredAreas = [
    CONSULTATION_ENTITY_NAMES.ANAMNESIS_OF_DISEASE,
    CONSULTATION_ENTITY_NAMES.ANAMNESIS_OF_LIFE,
    CONSULTATION_ENTITY_NAMES.OBJECTIVELY,
    CONSULTATION_ENTITY_NAMES.CONCLUSION,
    CONSULTATION_ENTITY_NAMES.DIAGNOSIS_BY_TEXT,
  ]

  constructor ({ id }) {
    super({
      id,
      semdType: SEMD_TYPE.CONSULTATION,
    })

    this.setValue('metaData', new Semd119MetaData())
  }

  async submit (data = this.getAttributes() as ISemd<ISemd119MetaData, ISemd119SemdsDocumentDetailsAttributesData>): Promise<IApiErrors> {
    return super.submit({
      ...data,
      metaData: {
        ...data.metaData,

        _documentModel:
          orNull(data.metaData._documentModel && pick(data.metaData._documentModel, ['id', 'title'])),
        _medicalPolicyModel:
          orNull(data.metaData._medicalPolicyModel && pick(data.metaData._medicalPolicyModel, ['id', 'title'])),
        _medicalCaseModel:
          pick(data.metaData._medicalCaseModel, ['id', 'title']),
        _companyModel:
          orNull(data.metaData._companyModel && pick(data.metaData._companyModel, ['id', 'title'])),

        medicalPolicyId: orNull(data.metaData._medicalPolicyModel?.id),
        documentId: orNull(data.metaData._documentModel?.id),
        companyId: orNull(data.metaData._companyModel?.id),
      },
    })
  }

  async fetchLastAppointment () {
    if (!this.client) { return }

    const result = await new MSemdPresenter().getLastAppointmentDateTime(this.client.id) || {}
    if (result?.errors) { return }
    this.fillLastAppointment(result)
  }

  protected generateOwnerUrlAfterLoad () {
    this.ownerUrl = Routes.protocols_page_path(this.owner.medicalRecordId, this.owner.id, { anchor: PROTOCOLS_ID })
  }

  protected fillLastAppointment ({ date, timeStart, timeEnd }) {
    this.setMetaDataValue('consultationDate', date)
    this.setMetaDataValue('consultationTimeStart', timeStart)
    this.setMetaDataValue('consultationTimeEnd', timeEnd)
  }
}
