import { request } from '@/lib/transport/request'
import { MEDIA_TYPES } from '@/helpers/mediaTypes'

export const paymentsEndpoint = {
  create (params) {
    return request({
      type: 'POST',
      url: Routes.api_internal_finances_payments_path(),
      data: JSON.stringify(params),
      contentType: MEDIA_TYPES.JSON,
      dataType: 'json',

    }).promise
  },

  createBulk (payments) {
    return request({
      type: 'POST',
      url: Routes.mass_pay_payments_path(),
      data: payments,
    }).promise
  },
}
