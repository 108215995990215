import {
  IClinicalGuidelineEntryToClientDTO,
} from '@/vue_apps/catalogs_root/ClinicalGuidelinesCatalog/interfaces/IClinicalGuidelineEntryToClientDTO'
import { CatalogHelpers } from '@/helpers/CatalogHelpers'
import {
  CLINICAL_GUIDELINE_ENTRY_KIND_ITEMS,
} from '@/vue_apps/catalogs_root/ClinicalGuidelinesCatalog/consts/clinicalGuidelineEntryKind'
import {
  CLINICAL_GUIDELINE_ENTRY_STEP_ITEMS,
} from '@/vue_apps/catalogs_root/ClinicalGuidelinesCatalog/consts/clinicalGuidelineEntryStep'
import {
  CLINICAL_GUIDELINE_ENTRY_UUR_ITEMS,
} from '@/vue_apps/catalogs_root/ClinicalGuidelinesCatalog/consts/clinicalGuidelineEntryUUR'
import {
  ClinicalGuidelineEntryItem,
} from '@/vue_apps/catalogs_root/ClinicalGuidelinesCatalog/classes/ClinicalGuidelineEntryItem/ClinicalGuidelineEntryItem'
import {
  IClinicalGuidelineEntryToServerDTO,
} from '@/vue_apps/catalogs_root/ClinicalGuidelinesCatalog/interfaces/IClinicalGuidelineEntryToServerDTO'

export const clinicalGuidelineEntryAdapter = {
  toClient (data: IClinicalGuidelineEntryToClientDTO) {
    return {
      ...data,
      kind: CatalogHelpers.getItemFromCatalogItems(CLINICAL_GUIDELINE_ENTRY_KIND_ITEMS, data.kind),
      step: CatalogHelpers.getItemFromCatalogItems(CLINICAL_GUIDELINE_ENTRY_STEP_ITEMS, data.step),
      uur: CatalogHelpers.getItemFromCatalogItems(CLINICAL_GUIDELINE_ENTRY_UUR_ITEMS, data.uur),
      udd: CatalogHelpers.buildCatalog(data.udd),
    }
  },

  toServer (data: ClinicalGuidelineEntryItem): IClinicalGuidelineEntryToServerDTO {
    return {
      title: data.title,
      clinicalGuidelineId: data.clinicalGuidelineId,
      commentShort: data.commentShort,
      commentLong: data.commentLong,
      healthcareBenchmark: data.healthcareBenchmark,
      kind: data.kind.id,
      step: data.step.id,
      uur: data.uur.id,
      udd: data.udd.id,
    }
  },
}
