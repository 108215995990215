<template>
  <div class="protocol-view flex flex-column w-100">
    <m-form-show :form="clientForm">
      <template #m-show-value-fio>
        <m-link
          :href="$routes.client_path(client.id)"
          :text="client.fullname"
          :open-in-new-tab="false"
        />
      </template>
    </m-form-show>

    <m-form-show
      v-if="branch.isMed"
      :form="medicalRecordForm"
    />

    <div
      v-if="branch.isDent && !branch.isMed"
      class="flex justify-content-center w-100"
    >
      <dental-card :dental-card="medicalRecord.dentalCard" />
    </div>
    <details
      v-else-if="branch.isCustom"
      class="med-card__details mt-indent-small"
    >
      <summary class="med-card__summary">
        <span class="med-card__summary-text">{{ t('dental_card') }}</span>
      </summary>
      <dental-card :dental-card="medicalRecord.dentalCard" />
    </details>

    <div class="flex flex-column">
      <m-button
        class="mt-indent-small align-self-start"
        template="edit"
        disabled="MedicalRecord"
        @click="onEdit"
      />
      <m-button-back :on-click-handler="replaceToClient" />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import { IClient } from '@/_declarations/IClient'
import MFormShow, { TFormShowForm } from '@/vue_present/_base/MFormShow/MFormShow.vue'
import MLink from '@/vue_present/_base/MLink/MLink.vue'
import { GLOBAL_GET_DEFAULT_SEXES } from '@/_global_scripts/GLOBAL_CONSTS'
import { ICatalog } from '@/_declarations/ICatalog'
import { DateHelpers } from '@/helpers/DateHelpers'
import { SNILS_THE_MASK } from '@/specific/clients/methods/snils'
import { IMedicalRecord } from '@/_declarations/IMedicalRecord'
import { StringHelpers } from '@/helpers/StringHelpers'
import DentalCard from '@/vue_apps/MedicalRecords/DentalCard/DentalCard.vue'
import MButton from '@/vue_present/_base/buttons/MButton/MButton.vue'
import MButtonBack from '@/vue_present/_base/buttons/MButtonBack/MButtonBack.vue'

const RH_MINUS_ID = 1
const ROMAN = ['', 'I', 'II', 'III', 'IV']

export default defineComponent({
  name: 'ProtocolView',

  components: { MButtonBack, MButton, DentalCard, MLink, MFormShow },

  props: {
    client: { type: Object as PropType<IClient>, required: true },
    medicalRecord: { type: Object as PropType<IMedicalRecord>, required: true },
  },

  emits: [
    'setProtocolTitle',
  ],

  computed: {
    branch () {
      return Utils.branch
    },

    clientForm () {
      return {
        fio: { label: t('full_name') },
        groups: {
          label: t('groups'),
          value: this.client.clientGroups.map((item) => item.title).join(', '),
        },
        sex: {
          label: t('sex'),
          value: (GLOBAL_GET_DEFAULT_SEXES() as ICatalog[])
            .find((item) => item.id === this.client.sexId)
            ?.title || '',
        },
        dob: {
          label: t('birthdate'),
          value: this.client.birthdate
            ? `${this.client.formattedBirthdate} (${DateHelpers.getAgeByBirthday(this.client.birthdate)})`
            : '',
        },
        address: {
          label: t('address'),
          value: this.client.formatAddress,
        },
        snils: {
          label: t('snils'),
          value: Utils.applyMask(this.client.snils, SNILS_THE_MASK),
        },
        sendDataToEgisz: {
          label: t('egisz.fields.send_data_to_egisz'),
          value: StringHelpers.yesOrNo(this.client.sendDataToEgisz),
        },
      } as TFormShowForm
    },

    medicalRecordForm () {
      return {
        separator1: { },
        separator2: { },

        allergy: {
          label: t('allergies'),
          value: this.medicalRecord.allergy,
        },
        growth: {
          label: t('growth'),
          value: this.medicalRecord.growth,
        },
        lifeConditions: {
          label: t('life_conditions'),
          value: this.medicalRecord.lifeConditions,
        },
        profession: {
          label: t('profession'),
          value: this.medicalRecord.profession,
        },
        workConditions: {
          label: t('work_conditions'),
          value: this.medicalRecord.workConditions,
        },
        weight: {
          label: t('weight'),
          value: this.medicalRecord.weight,
        },
        bloodGroup: {
          label: t('blood_group'),
          value: ROMAN[this.medicalRecord.bloodGroup],
        },
        rhFactor: {
          label: t('rh_factor'),
          value: this.medicalRecord.rhFactor === RH_MINUS_ID ? 'Rh-' : 'Rh+',
        },
        smoke: {
          label: t('smoke'),
          value: StringHelpers.yesOrNo(this.medicalRecord.smoke),
        },
        smokingHistory: {
          label: t('smoking_history'),
          value: this.medicalRecord.smokingHistory,
          hidden: !this.medicalRecord.smoke,
        },
        cigarettesPerDay: {
          label: t('cigarettes_per_day'),
          value: this.medicalRecord.cigarettesPerDay,
          hidden: !this.medicalRecord.smoke,
        },
        otherBadHabits: {
          label: t('other_bad_habits'),
          value: this.medicalRecord.otherBadHabits,
        },
        disability: {
          label: t('disability'),
          value: StringHelpers.yesOrNo(this.medicalRecord.disability),
        },
        disabilityType: {
          label: t('disability_type'),
          value: this.medicalRecord.disabilityType === 1 ? t('permanent') : t('temporary'),
          hidden: !this.medicalRecord.disability,
        },
        disabilityGroup: {
          label: t('disability_group'),
          value: ROMAN[this.medicalRecord.disabilityGroup],
          hidden: !this.medicalRecord.disability,
        },
        disabilityDate: {
          label: t('disability_date'),
          value: this.$filters.date(this.medicalRecord.disabilityDate),
          hidden: !this.medicalRecord.disability,
        },
        note: {
          label: t('additional'),
          value: this.medicalRecord.note,
        },
      } as TFormShowForm
    },
  },

  created () {
    this.$emit(
      'setProtocolTitle',
      `${t('medical_card')} № ${this.client.emkNumber} - ${this.client.fullname}`
    )
  },

  methods: {
    onEdit () {
      Turbolinks.visit(this.$routes.edit_medical_record_path(this.medicalRecord.id))
    },

    replaceToClient () {
      HistoryBrowser.goBackFrom('medical_record')
    },
  },
})
</script>
