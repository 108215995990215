import { ICatalog } from '@/_declarations/ICatalog'
import { TAX_RATES } from '@/vue_apps/Taxes/const/const'
import { MConfirm, MConfirmRenderList } from '@/vue_present/_base/MConfirm/MConfirm'

const TAX_RATES_NOT_SENDABLE_TO_DRIVER = [
  TAX_RATES.NDS_12,
  TAX_RATES.NDS_18,
  TAX_RATES.NDS_18_TO_118,
  TAX_RATES.NDS_25,
]

export const checkOFDBannedTaxSchemes =
  (paymentEntries: Array<ICatalog & { taxScheme: TAX_RATES }>, invalids = TAX_RATES_NOT_SENDABLE_TO_DRIVER): { cancel: boolean } => {
    const bannedPaymentEntries = (paymentEntries || []).filter(
      ({ taxScheme }) => invalids.includes(taxScheme)
    )

    if (!bannedPaymentEntries.length) { return { cancel: false } }

    MConfirm.error(
      t('fiscal.OFDBannedMessage') + MConfirmRenderList(bannedPaymentEntries.map((bPE) => `${bPE.title} - ${t(bPE.taxScheme)}`)),
      {
        showCancelButton: false,
        confirmButtonText: 'OK',
        dangerouslyUseHTMLString: true,
        customClass: 'w-600px',
      }
    ).then()

    return { cancel: true }
  }
