import { request } from '@/lib/transport/request'
import { getCertificatesAdapter } from '@/api_entities/workplaces/workplace_adapters'
import { Api } from '@/_api/decorators/api/Api'
import {
  TWorkplacesListItemToClientDTO,
} from '@/vue_apps/catalogs_root/Workplaces/interfaces/IWorkplacesListItemToClientDTO'
import { IWorkplacesListToServerDTO } from '@/vue_apps/catalogs_root/Workplaces/interfaces/IWorkplacesListToServerDTO'
import { ICRUDReturn } from '@/_api/decorators/api/interfaces/ICRUDReturn'

export class workplaceEndpoint {
  @Api.notify('fetchMessage', 'workplaces', { success: false })
  @Api.list(Routes.list_api_internal_workplaces_path)
  static getAll (payload: Partial<IWorkplacesListToServerDTO> = {}) {
    return {
      payload,
    } as ICRUDReturn as Promise<TWorkplacesListItemToClientDTO>
  }

  static getAllConnected () {
    return workplaceEndpoint.getAll({ connected: true })
  }

  static getCertificates (workplaceId: number | string, localMachine = false) {
    const options = {
      type: 'get',
      url: Routes.certificate_list_workplace_path(workplaceId),
      data: {
        local_machine: localMachine,
      },
    }

    return request(options, getCertificatesAdapter.toClient).promise
  }
}
