import {
  ACQUIRING_TERMINAL_CONNECTION_STATUS,
} from '@/vue_apps/FinanceModule/FinanceModuleIndex/consts/Acquiring/AcquiringTerminalConnectionStatus'
import { tScoped } from '@/helpers/i18nHelpers'

const ts = tScoped('finance')

export const CONNECTION_STATUS_ICON = {
  [ACQUIRING_TERMINAL_CONNECTION_STATUS.ACTIVE]: {
    icon: 'readyCircle',
    cssClass: 'success',
    tooltip: ts('connectionTooltip.active'),
  },

  [ACQUIRING_TERMINAL_CONNECTION_STATUS.INACTIVE]: {
    icon: 'notReadyCircle',
    cssClass: 'danger',
    tooltip: ts('connectionTooltip.inactive'),
  },

  [ACQUIRING_TERMINAL_CONNECTION_STATUS.CONNECTING]: {
    icon: 'reset',
    cssClass: 'warning',
    tooltip: ts('connectionTooltip.checking'),
  },

  [ACQUIRING_TERMINAL_CONNECTION_STATUS.NOT_CONNECTED]: {
    icon: 'ban',
    cssClass: 'grey',
    tooltip: ts('connectionTooltip.notConnected'),
  },
}
