import { WorkplaceLogic } from '@/vue_apps/catalogs_root/Workplaces/classes/Workplace/WorkplaceLogic'
import { Api } from '@/_api/decorators/api/Api'
import { ICRUDReturn } from '@/_api/decorators/api/interfaces/ICRUDReturn'
import { IWorkplace } from '@/vue_apps/catalogs_root/Workplaces/interfaces/IWorkplace'
import { Callback } from '@/_api/decorators/callback'
import { workplaceAdapter } from '@/vue_apps/catalogs_root/Workplaces/api/workplaceAdapter'
import { store } from '@/vue_components/store/store'
import { IError } from '@/_declarations/IError'
import { IWSMessage } from '@/_declarations/IWSMessage'
import { IWorkplaceGetInfo } from '@/vue_apps/catalogs_root/Workplaces/interfaces/IWorkplaceGetInfo'

export class WorkplaceApi extends WorkplaceLogic {
  @Callback('fillProps')
  @Api.notify('fetchMessage', 'workplace', { success: false })
  @Api.get(Routes.api_internal_workplace_path)
  fetchWorkplace (id: number | string) {
    return {
      routeArgs: [id],
    } as ICRUDReturn as Promise<IWorkplace | IError>
  }

  @Callback('setKey')
  @Api.notify('updateMessage', 'workplace')
  @Api.post(Routes.update_key_api_internal_workplace_path)
  updateKey () {
    return {
      routeArgs: [this.id],
    } as ICRUDReturn as Promise<IWorkplace | IError>
  }

  @Callback('submitCallback', true)
  @Api.notify('submitMessage', 'workplace', { useRawErrors: true })
  @Api.submit(Routes.api_internal_workplaces_path, Routes.api_internal_workplace_path)
  @Api.serverAdapter(workplaceAdapter)
  submit (payload = this.getAttributes()) {
    return {
      routeArgs: [this.id],
      payload,
    } as ICRUDReturn as Promise<IWorkplace | IError>
  }

  @Api.notify('destroyMessage', 'workplace')
  @Api.delete(Routes.api_internal_workplace_path)
  destroy () {
    return {
      routeArgs: [this.id],
    } as ICRUDReturn as Promise<IWorkplace | IError>
  }

  initWSEvents () {
    Services.pubSub.subscribe('workplace.get_info', (message: IWSMessage<IWorkplaceGetInfo>) => this.onGetInfo(message))

    const isHandshakeComplete = store.getters['wsUserChannel/GET_IS_HANDSHAKE_COMPLETE']
    if (isHandshakeComplete) {
      return Services.workplace.sendMessage('get_info', { workplace_id: this.id, data: { as_dictionary: true } })
    }

    Services.pubSub.subscribe('workplace.handshake', () => {
      Services.workplace.sendMessage('get_info', { workplace_id: this.id, data: { as_dictionary: true } })
    })
  }

  private onGetInfo (message: IWSMessage<IWorkplaceGetInfo>) {
    if (message.data.workplace_id !== this.id) return
    super.setValue('kkm', message.data.info)
  }
}
