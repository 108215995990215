import { MRequestHandler, TRequestOptions } from '@/_api/_requests/MRequestHandler'
import { MRequestNotification } from '@/_api/_requests/MRequestNotification'
import { extractRequestParams } from '@/_api/_requests/helpers'
import { MRequestError } from '@/_api/_requests/MRequestError'
import { MRequestSuccess } from '@/_api/_requests/MRequestSuccess'
import {
  IEntryTypeIdsParams,
  ITaxRatesApiUpdateURLsKinds as IUpdateURLsKinds,
  IUpdateTaxRatesForEntriesParams,
  IUpdateTaxRatesForEntryTypesParams,
  TTaxRatesApiUpdateResponse as TUpdateResponse,
  IUpdateTaxRatesForEntriesByClinicParams as IUpdateForEntriesByClinicParams,
  ITaxRatesApiRequestConfig as IRequestConfig,
} from './interfaces'
import { taxRatesAdapter } from './taxRatesAdapter'

export class MTaxRatesApi extends MRequestHandler {
  // Обновление НДС у энтрей по клинике
  updateForEntriesByClinic (data: IUpdateForEntriesByClinicParams): Promise<TUpdateResponse> {
    const notification = new MRequestNotification(
      'TaxRatesApi::updateForEntriesByClinic',
      'updateMessage',
      'entryTaxRate'
    )

    const url = Routes.batch_update_tax_scheme_by_clinic_api_internal_entries_path()

    const updateRequest = this.post(this.getPostOptions(data, { url })).promise

    return this.handleUpdate(updateRequest, notification)
  }

  // Обновление НДС у энтрей по id энтри тайпов и id категорий энтри тайпов
  updateForEntries (data: IUpdateTaxRatesForEntriesParams): Promise<TUpdateResponse> {
    const notification = new MRequestNotification(
      'TaxRatesApi::updateForEntries',
      'updateMessage',
      'entryTaxRate'
    )

    const urls = {
      byEntryTypes: Routes.batch_update_tax_scheme_by_entry_types_api_internal_entries_path(),
      byEntryTypeCategories: Routes.batch_update_tax_scheme_by_entry_type_categories_api_internal_entries_path(),
    }

    return this.updateByEntryTypeCatalog(data, urls, notification)
  }

  // Обновление НДС у энтри тайпов по их id и id их категорий
  updateForEntryTypes (data: IUpdateTaxRatesForEntryTypesParams): Promise<TUpdateResponse> {
    const notification = new MRequestNotification(
      'TaxRatesApi::updateForEntryTypes',
      'updateMessage',
      'entryTypeTaxRate'
    )

    const urls = {
      byEntryTypes: Routes.batch_update_tax_scheme_api_internal_entry_types_path(),
      byEntryTypeCategories: Routes.batch_update_tax_scheme_by_categories_api_internal_entry_types_path(),
    }

    return this.updateByEntryTypeCatalog(data, urls, notification)
  }

  protected updateByEntryTypeCatalog (
    data: IEntryTypeIdsParams,
    urls: IUpdateURLsKinds,
    notification: MRequestNotification
  ): Promise<TUpdateResponse> {
    const updateRequest = Promise.all([
      this.getRequestByEntryTypes(urls.byEntryTypes, data),
      this.getRequestByEntryTypeCategories(urls.byEntryTypeCategories, data),
    ])

    return this.handleUpdate(updateRequest, notification)
  }

  protected getRequestByEntryTypes (
    url: string,
    data: IEntryTypeIdsParams
  ): Promise<TUpdateResponse> {
    const needRequst = Boolean(data.entryTypeIds?.length)
    const requestConfig = {
      toServerAdapter: taxRatesAdapter.toServerByEntryTypes,
      url,
    }

    return this.getRequestOrStub(needRequst, data, requestConfig)
  }

  protected getRequestByEntryTypeCategories (
    url: string,
    data: IEntryTypeIdsParams
  ): Promise<TUpdateResponse> {
    const needRequst = Boolean(data.entryTypeCategoryIds?.length)
    const requestConfig = {
      toServerAdapter: taxRatesAdapter.toServerByEntryTypeCategories,
      url,
    }

    return this.getRequestOrStub(needRequst, data, requestConfig)
  }

  protected getRequestOrStub (
    needRequest: boolean,
    data: object,
    config: IRequestConfig
  ): Promise<TUpdateResponse> {
    return needRequest
      ? this.post(this.getPostOptions(data, config)).promise
      : Promise.resolve()
  }

  protected getPostOptions (
    rawData: object,
    { url, toServerAdapter = (data) => data }: IRequestConfig
  ): TRequestOptions {
    const adaptedRawData = toServerAdapter(rawData)
    const {
      data,
      headers,
    } = extractRequestParams(adaptedRawData, { toJSON: true })

    return {
      url,
      data,
      ...headers,
    }
  }

  protected handleUpdate (
    updateRequest: Promise<any>,
    notification: MRequestNotification
  ): Promise<TUpdateResponse> {
    return updateRequest
      .then(MRequestSuccess.withNotify(undefined, notification))
      .catch(MRequestError.onRequest(notification))
  }
}
