import { strToBoolean } from '@/helpers/typeHelpers'
import { camelToSnake } from '@/_api/_requests/helpers'

export class StringHelpers {
  static yesOrNo (value: boolean) {
    return value ? t('yes') : t('no')
  }

  static strToBoolean = strToBoolean

  static capitalizeFirstLetter (text: string) {
    return text.charAt(0).toUpperCase() + text.slice(1)
  }

  static convertToSnakeCase (str: string) {
    return Object.keys(camelToSnake({ [str]: null }))[0]
  }

  static splitByLength (str: string, size: number) {
    if (!str) { return [] }
    if (!size || size <= 0) { return str.split('') }

    const result = []

    for (let i = 0; i < str.length; i += size) {
      result.push(str.slice(i, i + size))
    }

    return result
  }
}
