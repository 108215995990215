<template>
  <m-panel class="checkup">
    <template #head>
      <m-icon
        class="m-panel__icon"
        icon="periodicCheckup"
      />
      <span class="cut">
        {{ title }}
      </span>
    </template>

    <div class="checkup__content">
      <div class="checkup__left-side">
        <!--  Информация  -->
        <company-checkup-info
          :company-checkup="companyCheckup"
          :is-show-mode="isShowMode"
          :is-edit-mode="isEditMode"
          :is-new-mode="isNewMode"
          @registerValidator="onRegisterValidator"
        />

        <!-- Документы -->
        <checkup-documents
          :checkup-documents-list="companyCheckup.documents"
          :company-id="companyCheckup.company?.id"
          :is-show-mode="isShowMode"
          :is-new-mode="isNewMode"
          :is-edit-mode="isEditMode"
          @createOldDocument="onCreateOldDocument"
          @delete="loadDocuments()"
        >
          <template #headExtra>
            <company-blade-runner-finals-editor
              :company-blade-runner="companyCheckup.bladeRunners"
              is-new-mode
              @finalsUpdated="onBladeRunnersCreated"
            >
              <template #reference>
                <m-button
                  size="mini"
                  type="dark"
                  button-style="fill"
                  icon="list"
                  disabled="CheckupsCompany"
                  :text="t('checkups.documents.bladeRunner')"
                />
              </template>
            </company-blade-runner-finals-editor>

            <m-button
              size="mini"
              type="dark"
              button-style="fill"
              icon="document"
              disabled="CheckupsCompany"
              :text="t('checkups.documents.companyFinalAct.buttonText')"
              @click="onCreateFinalAct"
            />
          </template>
        </checkup-documents>

        <!--  Врачебная комиссия и исследования  -->
        <company-checkup-doctor-and-tests
          :company-checkup="companyCheckup"
          :is-show-mode="isShowMode"
          :is-edit-mode="isEditMode"
          :is-new-mode="isNewMode"
          @registerValidator="onRegisterValidator"
        />
      </div>

      <div class="checkup__right-side">
        <!-- Медосмотры пациентов -->
        <company-checkup-personal-checkups
          v-if="id"
          :company-checkup-id="id"
          :company-checkup="companyCheckup"
          :is-show-mode="isShowMode"
          :is-edit-mode="isEditMode"
          :is-new-mode="isNewMode"
        />
      </div>
    </div>

    <template #footer>
      <div class="flex flex-grow-1 align-items-center center gap-indent">
        <div class="checkup__buttons flex-grow-1">
          <!-- Редактировать -->
          <m-button
            v-if="isShowMode"
            template="edit"
            disabled="CheckupsCompany"
            @click="onEditClick"
          />

          <!-- Сохранить -->
          <m-button-submit
            v-if="!isShowMode"
            :disabled="loading || 'CheckupsCompany'"
            @click="onSubmit"
          />

          <!-- удалить -->
          <m-button-delete
            v-if="isShowMode"
            use-button
            disabled="CheckupsCompany"
            @yes="onDelete"
          />

          <!-- Закрыть -->
          <m-button
            template="close"
            @click="onClose"
          />
        </div>

        <div class="checkup__created">
          <checkup-created
            v-if="companyCheckup.creator && !isNewMode"
            :checkup="companyCheckup"
          />
        </div>
      </div>
    </template>
  </m-panel>
</template>

<script>
import CompanyCheckupInfo from '@/vue_apps/CheckupsModule/components/CompanyCheckup/CompanyCheckupInfo.vue'
import CompanyCheckupPersonalCheckups
  from '@/vue_apps/CheckupsModule/components/CompanyCheckup/CompanyCheckupPersonalCheckups.vue'
import MPanel from '@/vue_present/_base/MPanel/MPanel.vue'
import MButtonSubmit from '@/vue_present/_base/buttons/MButtonSubmit/MButtonSubmit.vue'
import MButton from '@/vue_present/_base/buttons/MButton/MButton.vue'
import CheckupDocuments from '@/vue_apps/CheckupsModule/components/CheckupDocuments.vue'
import CheckupCreated from '@/vue_apps/CheckupsModule/components/CheckupCreated.vue'
import { ValidationParentMixin } from '@/vue_present/mixins/ValidationParentMixin'
import { CheckupsComputedMixin } from '@/vue_apps/CheckupsModule/mixins/CheckupsComputedMixin'
import { CHECKUPS_ROUTE_NAMES } from '@/vue_apps/CheckupsModule/router/routeNames'
import { CompanyCheckup } from '@/vue_apps/CheckupsModule/classes/CompanyCheckup/CompanyCheckup'
import CompanyCheckupDoctorAndTests
  from '@/vue_apps/CheckupsModule/components/CompanyCheckup/CompanyCheckupDoctorAndTests.vue'
import {
  MCompanyCheckupPresenter,
} from '@/vue_apps/CheckupsModule/api/MCompanyCheckupPresenter/MCompanyCheckupPresenter'
import { MConfirm } from '@/vue_present/_base/MConfirm/MConfirm'
import MButtonDelete from '@/vue_present/_base/buttons/MButtonDelete/MButtonDelete.vue'
import CompanyBladeRunnerFinalsEditor
  from '@/vue_apps/CheckupsModule/components/CheckupDocument/CompanyDocuments/CompanyBladeRunner/CompanyBladeRunnerFinalsEditor.vue'
import { getDocumentRoute } from '@/vue_apps/CheckupsModule/router/routeHelpers'
import MIcon from '@/vue_present/_base/MIcon/MIcon.vue'
import { isDocumentExist } from '@/vue_apps/CheckupsModule/store/isDocumentExist'
import { CHECKUP_DOCUMENT_API_MAP } from '@/vue_apps/CheckupsModule/store/checkupDocumentApiMap'
import { DOCUMENT_TYPES } from '@/vue_present/Reuse/DocumentTree/store/documentTypes'
import { PUB_SUB_BROADCAST_EVENTS } from '@/_declarations/pubSub/pubSubBroadcastEvents'
import { SpinnerHolder } from '@/vue_components/mixins/spinner_holder'

export default {
  name: 'CompanyCheckupEditor',

  components: {
    MIcon,
    CompanyBladeRunnerFinalsEditor,
    MButtonDelete,
    CompanyCheckupDoctorAndTests,
    CheckupCreated,
    CheckupDocuments,
    MButton,
    MButtonSubmit,
    MPanel,
    CompanyCheckupPersonalCheckups,
    CompanyCheckupInfo,
  },

  mixins: [
    ValidationParentMixin,
    CheckupsComputedMixin,
    SpinnerHolder,
  ],

  props: {
    id: { type: Number, default: null },
  },

  data () {
    return {
      /** @type {CompanyCheckup} */
      companyCheckup: new CompanyCheckup(),
    }
  },

  computed: {
    title () {
      const number = this.id

      if (this.isShowMode) {
        return t('checkups.company.showTitle', { number })
      }

      return this.isNewMode
        ? t('checkups.company.addTitle')
        : t('checkups.company.editTitle', { number })
    },
  },

  async created () {
    this.companyCheckup = await this.fetchCheckup()

    if (this.companyCheckup.id) {
      await this.companyCheckup.loadAggregateCheckupEntries()
      await this.loadDocuments()
    }

    this.subscribeToRemoveDocuments()
  },

  methods: {
    onEditClick () {
      this.$router.push({
        name: CHECKUPS_ROUTE_NAMES.COMPANY_CHECKUP_EDIT,
      })
    },

    subscribeToRemoveDocuments () {
      this.$pubSub.subscribe(PUB_SUB_BROADCAST_EVENTS.REMOVE_DOCUMENTS, (event) => {
        if (!isDocumentExist(this.companyCheckup.documents.data, event)) { return }

        this.loadDocuments()
      })
    },

    async fetchCheckup () {
      if (!this.id) { return new CompanyCheckup() }

      const companyCheckup = await new MCompanyCheckupPresenter().fetch(this.id)

      if (companyCheckup.errors?.error === 'company_checkup_not_found') {
        return this.$router.replace({ name: CHECKUPS_ROUTE_NAMES.CHECKUP_404 })
      }

      if (companyCheckup.errors) {
        return
      }

      return new CompanyCheckup(companyCheckup)
    },

    async onSubmit () {
      if (this.hasValidationErrors()) { return }

      const { errors, id } = await this.withSpinnerSimple(this.companyCheckup.save())
      if (errors) { return this.resetValidations(errors) }

      await this.$router.push({
        name: CHECKUPS_ROUTE_NAMES.COMPANY_CHECKUP_SHOW,
        params: { id },
      })
    },

    async onClose () {
      if (this.isNewMode || this.isEditMode) {
        const { cancel } = await MConfirm.warning(t('closeWithoutSaving?'))
          .catch(() => ({ cancel: true }))
        if (cancel) { return }
      }

      await this.$router.push({ name: CHECKUPS_ROUTE_NAMES.PERIODIC_CHECKUPS })
    },

    async onDelete () {
      const { errors } = await this.companyCheckup.delete()
      if (errors) { return }

      await this.$router.replace({ name: CHECKUPS_ROUTE_NAMES.PERIODIC_CHECKUPS })
    },

    async loadDocuments () {
      this.companyCheckup.initDocumentsFilters()
      await this.companyCheckup.documents.fetchAll()
    },

    onBladeRunnersCreated (documentId) {
      this.afterDocumentCreated(documentId, DOCUMENT_TYPES.CHECKUPS_COMPANY_ROUTES_LIST, true)
    },

    async onCreateFinalAct () {
      const { id } = await CHECKUP_DOCUMENT_API_MAP[DOCUMENT_TYPES.CHECKUPS_COMPANY_FINAL_ACT]
        .create(this.companyCheckup.id)

      if (id) { this.afterDocumentCreated(id, DOCUMENT_TYPES.CHECKUPS_COMPANY_FINAL_ACT, true) }
    },

    onCreateOldDocument ({ documentType, ...data }) {
      this.afterDocumentCreated(data.id, documentType)
    },

    afterDocumentCreated (documentId, documentType, isEdit = false) {
      if (!documentId) { return }

      window.open(getDocumentRoute({ documentId, documentType, isEdit }))

      this.loadDocuments()
    },
  },
}
</script>
