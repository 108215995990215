const TEXT_FIELD_PROMPT = 'text-field'
const textFieldToolbar = TEXT_FIELD_PROMPT

export const toolbar3Params = Object.freeze([
  TEXT_FIELD_PROMPT,
  {
    text: t('paragraphs_prompt'),
    icon: 'custom fal fa-fw fa-info-circle',
  },
])

export const backend = {
  editor: {
    back: true,
    selector: 'div#form_builder__mount-point',
    plugins: 'table noneditable image imagetools pagebreak stopdelete advlist lists lineheight paste',
    pagebreak_split_block: true,
    paste_remove_spans: true,
    paste_merge_formats: true,
    toolbar1: `
    formatselect fontselect fontsizeselect lineheightselect |
    `,
    toolbar2: `
    alignleft aligncenter alignright | underline bold italic | subscript superscript | bullist numlist outdent indent | pagebreak headtype | removeformat insert_template_record | image | testform | delete2 |
    `,
    toolbar3: '| print_area | entry-variables variables math_form select_field fix_list extended_list | complaints diagnoses conclusions recommendations | clinicalGuidelines | egiszMount',
    toolbar4: textFieldToolbar,
    theme: 'modern',
    menubar: 'edit table tools',
    images_upload_url: Routes.text_editor_entry_protocol_template_path(),
    relative_urls: false,
    lineheight_formats: '1 1.1 1.2 1.3 1.4 1.5 1.6 1.7 1.8 1.9 2.0',
    height: '100%',
    language: gon.localization.locale,
    indentation: '50px',
    fixed_toolbar_container: '#toolbar',
    resize: false,
    table_default_attributes: Utils.tinymceTableDefaults,
    table_default_styles: Utils.tinymceTableStyles,
    image_advtab: true,
    formats: {
      bold: { inline: 'b' },
      italic: { inline: 'i' },
    },
    inline: true,
    auto_focus: true,
    pagebreak_separator: "<span class='mce-pagebreak'></span>",
    end_container_on_empty_block: true,
    table_tab_navigation: false,
    protect: [
      /<% [a-z&_]+\.[a-z_]+\.[a-z]+ do \|[a-z]*\| %>/g,
      /<% @[a-z&_]+\.[a-z_]+\.[a-z]+ do \|[a-z]*\| %>/g,
      /<% [a-z]* %>/g,
    ],
  },
}

export const frontend = {
  editor: {
    front: true,
    selector: 'div#frontend-mount-point',
    plugins: 'table noneditable image imagetools pagebreak stopdelete advlist lists lineheight print paste',
    toolbar1: 'formatselect fontselect fontsizeselect lineheightselect',
    toolbar2: `
    alignleft aligncenter alignright | underline bold italic | subscript superscript | bullist numlist outdent indent | pagebreak headtype | image | delete2 | change_editor_margin removeformat |
    `,
    toolbar3: 'diagnoses | clinicalGuidelines | egiszMount',
    toolbar4: textFieldToolbar,
    paste_remove_spans: true,
    paste_merge_formats: true,
    theme: 'modern',
    menubar: 'edit table tools',
    images_upload_url: Routes.text_editor_entry_protocol_inheritor_path(),
    relative_urls: false,
    lineheight_formts: '1 1.1 1.2 1.3 1.4 1.5 1.6 1.7 1.8 1.9 2.0',
    language: gon.localization.locale,
    fixed_toolbar_container: '#frontend-toolbar',
    table_default_attributes: Utils.tinymceTableDefaults,
    table_default_styles: Utils.tinymceTableStyles,
    image_advtab: true,
    formats: {
      bold: { inline: 'b' },
      italic: { inline: 'i' },
    },
    inline: 'true',
    auto_focus: true,
    pagebreak_separator: "<span class='mce-pagebreak'></span>",
    table_tab_navigation: false,
    width: '210mm',
    padding: '15px',
    images_upload_credentials: false,
  },
}

export const applicationConf = {
  baseClass: 't-element',
  listClass: 'list,d_entity,diagnoses',
}
