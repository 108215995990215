import {
  TaxCertificate2024Base,
} from '@/vue_apps/Documents/FixedDocuments/_documents/TaxCertificate2024/classes/TaxCertificate2024Base'
import {
  ANNULLING_CERTIFICATE_CORRECTION_NUMBER,
  CERTIFICATE_TYPE,
  extractClinicFromGon,
  MIN_CERTIFICATE_NUMBER,
  PRIMARY_CERTIFICATE_CORRECTION_NUMBER,
} from '@/vue_apps/Documents/FixedDocuments/_documents/TaxCertificate2024/consts/taxCertificate2024EditorConsts'
import { omit, pick } from 'lodash'
import {
  TaxCertificate2024Presenter,
} from '@/vue_apps/Documents/FixedDocuments/_documents/TaxCertificate2024/api/TaxCertificate2024Presenter'
import { MClientPresenter } from '@/_api/MClient/MClientPresenter'
import {
  TaxCertificate2024Client,
} from '@/vue_apps/Documents/FixedDocuments/_documents/TaxCertificate2024/classes/TaxCertificate2024Client'
import { tScoped } from '@/helpers/i18nHelpers'

export class TaxCertificate2024 extends TaxCertificate2024Base {
  /**
   * @private
   * @type {(i18n: string, options?: {}) => string}
   */
  t = tScoped('fixedDocument.taxCertificate2024')

  /**
   * @private
   */
  presenter = new TaxCertificate2024Presenter()

  /**
   * @private
   */
  clientPresenter = new MClientPresenter()

  constructor (props = {}) {
    super(props)
  }

  /**
   * @private
   */
  periodHandler (value) {
    super.setValue('period', value || [])
    super.setValue('reportingYear', value
      ? moment(value.at(1)).year()
      : null
    )

    this.availableReportingYearsHandler()
    this.titleHandler()
  }

  /**
   * @private
   */
  clinicHandler (id) {
    const clinic = gon.application.current_user_clinics.find((clinic) => clinic.id === id)

    if (!clinic) { return }

    super.setValue('clinic', extractClinicFromGon(clinic))
  }

  /**
   * @private
   */
  async taxpayerHandler ({ id }) {
    super.setValue('taxpayer', new TaxCertificate2024Client(await this.clientPresenter.fetch(id)))

    this.titleHandler()
  }

  /**
   * @private
   */
  async clientHandler ({ id }) {
    super.setValue('taxpayerIsClient', id === this.taxpayer.id)

    super.setValue('client',
      this.taxpayerIsClient
        ? new TaxCertificate2024Client()
        : new TaxCertificate2024Client(await this.clientPresenter.fetch(id))
    )

    this.titleHandler()
  }

  /**
   * @private
   */
  individualEntrepreneurHandler (value) {
    super.setValue('individualEntrepreneur', value)

    if (value) {
      super.setValue('clinic.kpp', '')

      return
    }

    this.clinicHandler(this.clinic.id)
  }

  /**
   * @private
   */
  generateTitle () {
    const title = [this.t('title')]

    if (this.taxpayer.shortName) {
      title.push(this.taxpayer.shortName)
    }

    if (this.client.shortName) {
      title.push(`${t('for')} ${this.client.shortName}`)
    }

    title.push(`№${this.certificateNumber}`)

    title.push(`${this.t('shortCorrection')} ${this.correctionNumber}`)

    if (this.reportingYear) {
      title.push(`${t('year')} ${this.reportingYear}`)
    }

    return title
      .join(', ')
      .replace(',', '')
      .replace(',', '')
  }

  /**
   * @private
   */
  titleHandler (value) {
    if (typeof value === 'string' && !this.disableTitleGeneration) {
      super.setValue('disableTitleGeneration', true)
    }

    if (this.disableTitleGeneration) {
      if (typeof value !== 'string') { return }

      return super.setValue('title', value)
    }

    super.setValue('title', this.generateTitle())
  }

  /**
   * @private
   */
  availableReportingYearsHandler () {
    if (!this.period.length) {
      return super.setValue('availableReportingYears', [])
    }

    const minYear = moment(this.period.at(0)).year()
    const maxYear = moment(this.period.at(1)).year()

    super.setValue('availableReportingYears',
      Array.from(
        { length: maxYear - minYear + 1 },
        (_, index) =>
          ({ id: minYear + index, title: minYear + index })
      ))
  }

  /**
   * @private
   */
  correctionNumberHandler () {
    if (this.type === CERTIFICATE_TYPE.PRIMARY) {
      super.setValue('correctionNumber', PRIMARY_CERTIFICATE_CORRECTION_NUMBER)
    }

    // номер корректировки создаваемой справки должен быть на единицу больше,
    // чем номер корректировки выбранной справки.
    if (this.type === CERTIFICATE_TYPE.CORRECTIVE) {
      super.setValue('correctionNumber', this.originCorrectionNumber + 1)
    }

    if (this.type === CERTIFICATE_TYPE.ANNULLING) {
      super.setValue('correctionNumber', ANNULLING_CERTIFICATE_CORRECTION_NUMBER)
    }

    this.titleHandler()
  }

  /**
   * @private
   */
  typeHandler (value) {
    super.setValue('type', value)

    if (value === CERTIFICATE_TYPE.PRIMARY) {
      this.clear()

      return this.certificateNumberHandler()
    }

    this.correctionNumberHandler()
  }

  /**
   * @private
   */
  clear () {
    this._fillProps(
      pick(
        new TaxCertificate2024Base(),
        [
          'id',
          'isCertificateLoaded',
          'period',
          'clinic',
          'client',
          'individualEntrepreneur',
          'ignorePaymentsWithoutPrintedReceipts',
          'title',
          'correctionNumber',
          'originCorrectionNumber',
          'reportingYear',
          'individualEntrepreneurFullName',
          'sumOne',
          'sumTwo',
          'employee',
          'taxpayerIsClient',
          'disableTitleGeneration',
        ]
      )
    )
  }

  /**
   * @private
   */
  async representedPersonsHandler (clientId) {
    const data = await this.presenter.fetchRepresentedPersons(clientId)

    const representedPersons = data
      .filter((person) => person.id !== clientId)
      .map((person) => new TaxCertificate2024Client(person))

    super.setValue('representedPersons', representedPersons)
  }

  async clientSearch (queryParams) {
    const { data } = await this.clientPresenter.checkupSearch(queryParams)

    return data
  }

  async fetchCalculatedSum () {
    const { paidSum } = await this.presenter.fetchCalculatedSum(pick(this, this._permit))

    super.setValue('sumOne', paidSum)
    super.setValue('isCertificateLoaded', true)
  }

  async fetchCertificates (id) {
    const { data } = await this.presenter.list({
      id,
      type:
        [
          CERTIFICATE_TYPE.PRIMARY,
          CERTIFICATE_TYPE.CORRECTIVE,
        ],
      sort: {
        byId: 'desc',
      },
      limit: 50,
    })

    super.setValue('certificates', data)
  }

  async certificateNumberHandler () {
    const lastPrimaryCertificateNumber = await this.extractLastPrimaryCertificateNumber()

    this.certificateNumber = lastPrimaryCertificateNumber
      ? lastPrimaryCertificateNumber + 1
      : MIN_CERTIFICATE_NUMBER
  }

  /**
   * @private
   */
  async extractLastPrimaryCertificateNumber () {
    const { data } = await this.presenter.list({
      type: [CERTIFICATE_TYPE.PRIMARY],
      limit: 1,
      sort: {
        byId: 'desc',
      },
    })

    return +data.at(0)?.number || 0
  }

  /**
   * @private
   */
  appendCertificateIfNotExists (certificate, { skipChecking = false } = {}) {
    if (!skipChecking) {
      const hasCertificate = this.certificates
        .find((item) => item.id === certificate.id)

      if (hasCertificate) { return }
    }

    this.certificates.push({
      id: certificate.id,
      title: certificate.title,
    })
  }

  /**
   * @private
   */
  updateCertificateTitle (id, title) {
    const certificate = this.certificates
      .find((item) => item.id === id)

    if (!certificate) { return }

    certificate.title = title
  }

  /**
   * @private
   */
  removeUnusedAnnullingCertificates () {
    this.certificates = this.certificates
      .filter((certificate) =>
        certificate.type !== CERTIFICATE_TYPE.ANNULLING ||
        certificate.id === this.id
      )
  }

  /**
   * @private
   */
  setCertificateTypeInCertificates (id, certificateType) {
    const certificate = this.certificates
      .find((item) => item.id === id)

    if (!certificate) { return }

    certificate.type = certificateType
  }

  /**
   * @returns {TaxCertificate2024Client}
   */
  getTaxPayer () {
    return this.taxpayer?.id
      ? this.taxpayer
      : this.client
  }

  async fetchDocument ({ id = this.id, isEditMode } = {}) {
    let certificate = await this.presenter.fetch(id)

    if (certificate.errors) { return certificate }

    if (certificate.type === CERTIFICATE_TYPE.ANNULLING) {
      this.appendCertificateIfNotExists(certificate)
    }

    certificate = isEditMode
      ? certificate
      : omit(certificate, ['type'])

    if (this.type === CERTIFICATE_TYPE.CORRECTIVE) {
      certificate.period = this.period
      certificate.reportingYear = this.reportingYear
    }

    this._fillProps(certificate)

    super.setValue('disableTitleGeneration',
      this.generateTitle() !== certificate.title
    )

    if (!isEditMode) {
      this.correctionNumberHandler()
    }

    this.availableReportingYearsHandler()

    if (!isEditMode) {
      await this.fetchCalculatedSum()

      return
    }

    super.setValue('isCertificateLoaded', true)
  }

  async submit ({ isEdit } = {}) {
    const permit = isEdit
      ? this._permit
      : this._permit
        .filter((prop) => prop !== 'id')

    const certificate = await this.presenter.submit(pick(this, permit))

    if (certificate.errors) { return certificate }

    certificate.type === CERTIFICATE_TYPE.PRIMARY
      ? this.appendCertificateIfNotExists(certificate)
      : this.updateCertificateTitle(certificate.id, certificate.title)

    this.setCertificateTypeInCertificates(certificate.id, certificate.type)

    super.setValue('id', certificate.id)
    super.setValue('originCorrectionNumber', certificate.originCorrectionNumber)
    super.setValue('dateOfSignatureByEmployee', certificate.dateOfSignatureByEmployee)

    this.removeUnusedAnnullingCertificates()

    return certificate
  }

  async destroy () {
    const payload = await this.presenter.destroy(this.id)

    if (payload?.errors) { return payload }

    super.setValue('certificates', this.certificates.filter((certificate) => certificate.id !== this.id))
    super.setValue('id', null)
    super.setValue('isCertificateLoaded', false)

    return payload
  }

  setValue (key, value) {
    switch (key) {
      case 'period': return this.periodHandler(value)
      case 'type': return this.typeHandler(value)
      case 'title': return this.titleHandler(value)
      case 'representedPersons': return this.representedPersonsHandler(value)
      case 'clinic': return this.clinicHandler(value)
      case 'taxpayer': return this.taxpayerHandler(value)
      case 'client': return this.clientHandler(value)
      case 'individualEntrepreneur': return this.individualEntrepreneurHandler(value)
    }

    super.setValue(key, value)

    switch (key) {
      case 'certificateNumber': return this.titleHandler()
      case 'reportingYear': return this.titleHandler()
      case 'correctionNumber': return this.titleHandler()
    }
  }
}
