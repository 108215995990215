import { FINANCE_ROUTE_NAMES } from '@/vue_apps/FinanceModule/FinanceModuleIndex/router/routeNames'

export const PaymentModesMixin = {
  computed: {
    isNewMode () {
      return this.$route.name === FINANCE_ROUTE_NAMES.PAYMENT_NEW
    },

    isShowMode () {
      return this.$route.name === FINANCE_ROUTE_NAMES.PAYMENT_SHOW
    },
  },
}
