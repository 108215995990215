<template>
  <div class="protocol-save-as-template">
    <reusable-protocols-tree-modal
      ref="treeModal"
      :use-items="false"
      show-checkboxes
      check-strictly
      check-on-click-node
      use-radio-check
      use-root-node
      @onCheckChange="onCheckChange"
      @registerApi="modalApi = $event"
    >
      <template #beforeTree>
        <m-input
          v-model="title"
          required
          validator-name="title"
          :label="t('template_record_title')"
          @registerValidator="onRegisterValidator"
        />
      </template>

      <template #treeNode="{ node, data }">
        <lazy-tree-node-component
          :data="data"
          :node="node"
          editable
        >
          <template #actions>
            <m-icon
              v-tooltip="t('protocolEntities.addCatalog')"
              class="mr-5"
              icon="plus"
              color="success"
              @click.stop="onAddTemplateRecordCategory(data, node)"
            />
          </template>
        </lazy-tree-node-component>
      </template>

      <template #default="{ activator }">
        <m-button
          type="dark"
          size="mini"
          icon="save"
          :text="t('save_as_template')"
          disabled="TemplateRecord"
          @click="title = protocol.title; activator($event)"
        />
      </template>

      <template #footer-left>
        <m-button-submit
          v-tooltip="!currentCategory && t('enums.unselected.it')"
          v-loading="loading"
          :disabled="!currentCategory"
          @click="onSubmit"
        />
      </template>
    </reusable-protocols-tree-modal>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { ProtocolApi } from '@/vue_apps/ProtocolsApp/entities/ProtocolApi'
import MButton from '@/vue_present/_base/buttons/MButton/MButton.vue'
import ReusableProtocolsTreeModal from '@/vue_present/Reuse/ProtocolsTree/ReusableTemplateRecordsTreeModal.vue'
import { IElTreeCheckChangeArgs } from '@/_declarations/IElTreeCheckChangeArgs'
import MButtonSubmit from '@/vue_present/_base/buttons/MButtonSubmit/MButtonSubmit.vue'
import { TemplateRecordApi } from '@/vue_apps/catalogs_root/TemplateRecords/entities/TemplateRecordApi'
import { LazyTreeNode } from '@/vue_present/Reuse/LazyTree/store/LazyTreeNode'
import { SpinnerHolder } from '@/vue_components/mixins/spinner_holder'
import MInput from '@/vue_present/_base/inputs/MInput/MInput.vue'
import { ValidationParentMixin } from '@/vue_present/mixins/ValidationParentMixin'
import LazyTreeNodeComponent from '@/vue_present/Reuse/LazyTree/components/LazyTreeNodeComponent.vue'
import MIcon from '@/vue_present/_base/MIcon/MIcon.vue'
import { ProtocolsCategoriesPresenter } from '@/vue_present/Reuse/ProtocolsTree/api/ProtocolsCategoriesPresenter'
import { GLOBAL_CATEGORY_KINDS } from '@/_global_scripts/GLOBAL_CONSTS'
import { IElTreeNode } from '@/_declarations/IElTreeNode'
import { PSEUDO_ROOT_NODE_ID } from '@/vue_present/Reuse/LazyTree/const'
import { sortElTreeNode } from '@/vue_present/Reuse/LazyTree/helpers/sortElTreeNode'

export default defineComponent({
  name: 'ProtocolSaveAsTemplate',

  components: {
    MIcon,
    LazyTreeNodeComponent,
    MInput,
    MButtonSubmit,
    ReusableProtocolsTreeModal,
    MButton,
  },

  mixins: [
    SpinnerHolder,
    ValidationParentMixin,
  ],

  props: {
    protocol: ProtocolApi,
  },

  data () {
    return {
      modalApi: null as { closeModal: () => void },
      currentCategory: null as LazyTreeNode,
      title: this.protocol.title,
    }
  },

  methods: {
    onCheckChange (data: IElTreeCheckChangeArgs) {
      this.currentCategory =
          Utils.ternary(data.checked.checkedKeys.length, data.node)
    },

    async onSubmit () {
      if (!this.currentCategory) { return }
      if (this.hasValidationErrors()) { return }

      const api = new TemplateRecordApi({ id: null })
      api.fillByProtocol(this.protocol)
      api.setValue('title', this.title)
      api.setValue('categoryId', this.currentCategory.id)

      const result = await this.withSpinner(
        api.submit()
      )

      if (result?.errors) { return }

      this.currentCategory = null
      this.modalApi.closeModal()
    },

    async onAddTemplateRecordCategory (data: LazyTreeNode, node: IElTreeNode) {
      const { value: title, cancel } = await this.$prompt(t('new_category'), {
        inputPlaceholder: `${t('category')} "${data.title}"`,
      }).catch(() => ({ cancel: true }))

      if (cancel) { return }

      const result = await new ProtocolsCategoriesPresenter()
        .submit({
          title,
          parentId: data.id === PSEUDO_ROOT_NODE_ID ? 0 : data.id,
          kind: GLOBAL_CATEGORY_KINDS.TEMPLATE_RECORDS,
        })

      if (result?.errors) { return }

      const tree = this.$refs.treeModal.tree
      const treeRef = this.$refs.treeModal.treeRef

      const newNode = new LazyTreeNode(tree, result)
      treeRef.append(newNode, data)

      await node.expand(() => {
        treeRef.setChecked(newNode, true)
        this.currentCategory = newNode
        sortElTreeNode(treeRef.store.nodesMap, data)
      })
    },
  },
})
</script>
