import Container from '@/plugins/dynamic_forms/_core/container/Container'
import { IDynamicFormEntity } from '@/plugins/dynamic_forms/events/EventEntities'

/**
 * class for service for components events

 */
class Dispatcher {
  container: Container

  constructor (container: Container) {
    this.container = container
  }

  dispatch (event: IDynamicFormEntity) {
    const listenerName = event.getListener()
    const listener = this.container.get(listenerName)
    if (listener) {
      listener.handle(event)
    }
  }
}

export default Dispatcher
