import Variable from '../entities/items/variable'
import Conlusion from '../entities/items/conclusion'
import List from '../entities/items/List'
import Calculate from '../entities/items/calculate'
import Destination from '../entities/items/destination'
import Complaint from '../entities/items/complaint'
import Diagnose from '../entities/items/Diagnose'
import DiagnoseConstructor from '../entities/items/diagnose_constructor'
import EntriesVariable from '../entities/items/entries_variable'
import PrintArea from '../entities/items/print_area'
import {
  CALCULATE_ACTIONS,
  COMPLAINTS_ACTIONS,
  CONCLUSIONS_ACTIONS,
  DESTINATION_ACTIONS,
  DIAGNOSE_ACTIONS,
  DIAGNOSES_CONSTRUCTOR_ACTIONS,
  ENTRIES_VARIABLE_ACTIONS,
  EXTENSIBLE_LIST_ACTIONS,
  FIX_LIST_ACTIONS,
  PRINT_AREA_ACTIONS,
  VARIABLES_ACTIONS,
} from '../actions/entityActions'

import {
  CALCULATE,
  COMPLAINTS,
  CONCLUSIONS,
  DESTINATIONS,
  DIAGNOSE,
  DIAGNOSES_CONSTRUCTOR,
  ENTRIES_VARIABLE,
  EXTENSIBLE_LIST,
  FIX_LIST,
  PRINT_AREA,
  VARIABLES,
} from './reducer_types'
import { IEntityConfig } from '@/plugins/dynamic_forms/declarations/IEntityConfig'
import Entity from '@/plugins/dynamic_forms/entities/items/Entity'
import ProtocolEntityView
  from '@/vue_apps/ProtocolsApp/components/ProtocolEditorView/EntitiesViews/ProtocolEntityView.vue'
import ProtocolFixedListView
  from '@/vue_apps/ProtocolsApp/components/ProtocolEditorView/EntitiesViews/ProtocolFixedListView.vue'
import ProtocolDiagnoseConstructorView
  from '@/vue_apps/ProtocolsApp/components/ProtocolEditorView/EntitiesViews/ProtocolDiagnoseConstructorView.vue'
import ProtocolDiagnoseView
  from '@/vue_apps/ProtocolsApp/components/ProtocolEditorView/EntitiesViews/ProtocolDiagnoseView.vue'

export const entityConfigs: Record<string, IEntityConfig> = {
  [ENTRIES_VARIABLE]: {
    actions: ENTRIES_VARIABLE_ACTIONS,
    entityClass: EntriesVariable as unknown as Entity,
    dataType: ENTRIES_VARIABLE,
    title: t('entry_variable'),
    alias: 'ENTRY_VAR',
    class: `${ENTRIES_VARIABLE}`,
  },
  [DIAGNOSE]: {
    actions: DIAGNOSE_ACTIONS,
    entityClass: Diagnose as unknown as Entity,
    dataType: DIAGNOSE,
    title: t('diagnose'),
    alias: 'DYS',
    class: `${DIAGNOSE}`,
    component: ProtocolDiagnoseView,
  },
  [DIAGNOSES_CONSTRUCTOR]: {
    actions: DIAGNOSES_CONSTRUCTOR_ACTIONS,
    entityClass: DiagnoseConstructor as unknown as Entity,
    dataType: DIAGNOSES_CONSTRUCTOR,
    title: t('diagnose_constructor'),
    alias: 'DYS_C',
    class: `${DIAGNOSES_CONSTRUCTOR}`,
    component: ProtocolDiagnoseConstructorView,
  },
  [DESTINATIONS]: {
    fetch: Routes.protocol_recommendations_path(),
    actions: DESTINATION_ACTIONS,
    entityClass: Destination as unknown as Entity,
    dataType: DESTINATIONS,
    title: t('assigned_field'),
    alias: 'RR',
    class: `d_entity ${DESTINATIONS}`,
    component: ProtocolEntityView,
  },
  [COMPLAINTS]: {
    fetch: Routes.protocol_complaints_path(),
    actions: COMPLAINTS_ACTIONS,
    entityClass: Complaint as unknown as Entity,
    dataType: COMPLAINTS,
    title: t('complaint_field'),
    alias: 'CC',
    class: `d_entity ${COMPLAINTS}`,
    component: ProtocolEntityView,
  },
  [CONCLUSIONS]: {
    fetch: Routes.protocol_conclusions_path(),
    actions: CONCLUSIONS_ACTIONS,
    entityClass: Conlusion as unknown as Entity,
    dataType: CONCLUSIONS,
    title: t('conclusion_field'),
    alias: 'ZZ',
    class: `d_entity ${CONCLUSIONS}`,
    component: ProtocolEntityView,
  },
  [CALCULATE]: {
    actions: CALCULATE_ACTIONS,
    dataType: CALCULATE,
    title: t('formula'),
    alias: 'F',
    entityClass: Calculate as unknown as Entity,
    class: CALCULATE,
  },
  [VARIABLES]: {
    actions: VARIABLES_ACTIONS,
    dataType: VARIABLES,
    title: t('variable'),
    alias: 'X',
    class: VARIABLES,
    entityClass: Variable as unknown as Entity,
  },
  [FIX_LIST]: {
    actions: FIX_LIST_ACTIONS,
    entityClass: List as unknown as Entity,
    dataType: FIX_LIST,
    title: t('fix_list'),
    alias: 'Fix',
    class: `list ${FIX_LIST}`,
    component: ProtocolFixedListView,
  },
  [EXTENSIBLE_LIST]: {
    actions: EXTENSIBLE_LIST_ACTIONS,
    entityClass: List as unknown as Entity,
    dataType: EXTENSIBLE_LIST,
    title: t('open_list'),
    alias: 'Open',
    class: `list ${EXTENSIBLE_LIST}`,
    component: ProtocolEntityView,
  },
  [PRINT_AREA]: {
    actions: PRINT_AREA_ACTIONS,
    entityClass: PrintArea as unknown as PrintArea,
    dataType: PRINT_AREA,
    title: 'print_area',
    alias: 'PA',
    class: `${PRINT_AREA}`,
  },
}
