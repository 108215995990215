import { MPresenterBase } from '@/_api/_requests/MPresenterBase'
import { protocolListAdapter } from '@/vue_apps/ProtocolsApp/api/protocolListAdapter'
import { protocolAdapter } from '@/vue_apps/ProtocolsApp/api/protocolAdapter'
import { camelToSnake } from '@/_api/_requests/helpers'
import { Api } from '@/_api/decorators/api/Api'
import { ICRUDReturn } from '@/_api/decorators/api/interfaces/ICRUDReturn'
import { IProtocol } from '@/vue_apps/ProtocolsApp/declarations/IProtocol'
import { IApiErrors } from '@/_declarations/IApiErrors'

export class MProtocolPresenter extends MPresenterBase {
  /* Routes.synchronize_pdf_api_internal_entry_path */
  /* Routes.perform_pdf_api_internal_protocol_path */

  @Api.notify('fetchMessage', 'protocols', { success: false })
  @Api.post(() => '/api/internal/medical_records/protocols/fetch_for_print')
  static fetchForPrint (clientId: number) {
    return {
      payload: {
        clientId,
      },
    } as ICRUDReturn as Promise<Array<IProtocol<unknown>> | IApiErrors>
  }

  constructor () {
    super({
      routes: {
        all: Routes.api_internal_protocols_path,
        one: Routes.api_internal_protocol_path,
        list: Routes.list_api_internal_protocols_path,
      },
      entity: 'protocol',
      location: 'MProtocolPresenter',
    })
  }

  fetch (id, config = {}) {
    return super.fetch(id, {
      ...config,
      toClientAdapter: protocolAdapter.toClientAdapter,
    })
  }

  list (listData, config = {}) {
    return super.list(listData, config)
      .then(protocolListAdapter.toClient)
  }

  submit (protocol, config = {}) {
    return super.submit(protocol, {
      ...config,
      // @ts-ignore
      toJSON: true,
      toServerAdapter: (data) => ({
        protocol: camelToSnake(protocolAdapter.toServer(data)),
      }),
      toClientAdapter: protocolAdapter.toClientAdapter,
    })
  }

  generatePdf (protocolId) {
    return super.post({
      url: Routes.perform_pdf_api_internal_protocol_path(protocolId),
      // @ts-ignore
      dataType: 'json',
      contentType: 'application/json',
    }).promise
  }
}
