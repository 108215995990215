import { ICatalog } from 'src/_medods_standart_library/msl'
import {
  SYNC_ENTRIES_TAX_RATE_ANSWERS,
  SYNC_ENTRIES_TAX_RATE_ANSWERS_IDS,
  TAX_RATES, TAX_RATES_SENDABLE_TO_DRIVER, TAX_RATES_TEXTS,
} from './const'

const createTaxRateOption = (rate: string): ICatalog => ({
  id: rate,
  title: TAX_RATES_TEXTS[rate],
})

export const getTaxRatesOptions = (): ICatalog[] => {
  return Object
    .values(TAX_RATES)
    .map(createTaxRateOption)
}

export const getSyncEntriesTaxRateDefaultAnswer = (): ICatalog => {
  return SYNC_ENTRIES_TAX_RATE_ANSWERS
    .find(({ id }) => id === SYNC_ENTRIES_TAX_RATE_ANSWERS_IDS.NO)
}

export const checkTaxRateSendableToDriver = (taxRate: TAX_RATES): boolean => {
  return TAX_RATES_SENDABLE_TO_DRIVER.includes(taxRate)
}
