export enum PAYMENT_KINDS {
  ORDER_INITIAL = 'order_initial', // Первичный платеж
  ORDER_SUBSEQUENT = 'order_subsequent', // Оплата кредита
  ORDER_REFUND = 'order_refund', // Возврат оплаты
  CASH_IN = 'cash_in', // Внесение денег на лицевой счет
  CASH_IN_REFUND = 'cash_in_refund', // Возврат внесения на лицевой счет
  CASH_OUT = 'cash_out', // Вывод денег с лицевого счета
  ORDER_FULL = 'order_full', // Полная оплата счета
  ORDER_IN_CREDIT = 'order_in_credit', // Оплата в кредит
}
