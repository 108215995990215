import { TLazyTreeNode, TLazyTreeRef } from '@/vue_present/Reuse/LazyTree/interfaces/types'
import { defaultStringSorting } from '@/helpers/lambda'
import { get } from 'lodash/object'
import { isLeafNode } from '@/vue_present/Reuse/LazyTree/helpers/isLeafNode'

const initAlphabetSorting = (a: TLazyTreeNode, b: TLazyTreeNode, sortBy: string) =>
  () => defaultStringSorting(get(a.data, sortBy), get(b.data, sortBy))

export const sortLazyTreeNodeChildren = (
  treeRef: TLazyTreeRef,
  nodeId: number,
  {
    sortBy = 'title',
    catalogAlphabet = true,
    leafAlphabet = true,
    leafUnderCatalog = true,
  } = {}
) => {
  const node = treeRef.getNode(nodeId)
  if (!node) { return }

  node.childNodes = node.childNodes
    .toSorted((a, b) => {
      const aIsLeafNode = isLeafNode(a)
      const bIsLeafNode = isLeafNode(b)

      const alphabetSorting = initAlphabetSorting(a, b, sortBy)

      if (aIsLeafNode && bIsLeafNode) {
        return leafAlphabet
          ? alphabetSorting()
          : 0
      }

      if (!aIsLeafNode && !bIsLeafNode) {
        return catalogAlphabet
          ? alphabetSorting()
          : 0
      }

      if (leafUnderCatalog) {
        if (aIsLeafNode && !bIsLeafNode) { return 1 }
        if (!aIsLeafNode && bIsLeafNode) { return -1 }
      }

      return leafAlphabet
        ? alphabetSorting()
        : 0
    })
}
