import BaseTemplateBuilder from '@/plugins/dynamic_forms/builders/BaseTemplateBuilder'

/**
 *  use builder pattern director
 */
export class TemplateApplication<BuilderType extends BaseTemplateBuilder> {
  build (builder: BuilderType): BuilderType {
    builder.setMapping()
    builder.initComponents()

    // @ts-ignore
    return builder.create()
  }

  destroy (builder: BuilderType) {
    if (!builder.freeComponents) { return }
    builder.freeComponents()
  }
}
