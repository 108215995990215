export enum FISCAL_PRINTER_EVENT {
  HANDSHAKE = 'handshake',
  BEEP = 'beep',
  GET_INFO = 'get_info',
  OPEN_DAY = 'open_day',
  CANCEL_RECEIPT = 'cancel_receipt',
  PRINT_REPORT = 'print_report',
  ERROR = 'error',
}

export const getWorkplacesFiscalPrinterEvent = (type: FISCAL_PRINTER_EVENT) => `workplace.${type}`

export enum FISCAL_PRINTER_REPORT_TYPE {
  Z_MAIN = 'z_main',
  LAST_DOCUMENT = 'last_document',
  DOC_BY_NUMBER = 'doc_by_number',
  X_MAIN = 'x_main',
  X_BY_SECTIONS = 'x_by_sections',
  X_BY_HOURS = 'x_by_hours',
  X_BY_CASHIERS = 'x_by_cashiers',
  X_BY_AMOUNT = 'x_by_amount',
}
