import { store } from '@/vue_components/store/store'
import { IWorkplaceModulesStatuses } from '@/services/declarations/TWorkplaceModulesStatuses'
import { WORKPLACE_MODULE_STATUS } from '@/services/consts/WorkplaceModuleStatus'

export class WEService {
  redrawUi (modules?: IWorkplaceModulesStatuses) {
    this.indicatorElementsDisable()
    store.commit('weStore/vxResetAllModules')

    if (!modules) { return }

    for (const module in modules) {
      if (modules[module] !== WORKPLACE_MODULE_STATUS.ENABLED) { continue }
      this.moduleEnabled(module)
    }
  }

  private moduleEnabled (module: string) {
    store.commit('weStore/vxSetModuleState', {
      module,
      value: true,
    })

    this.indicatorElementEnable(module)
  }

  private indicatorElementsDisable () {
    const indicatorElements = document.getElementsByClassName('we')
    for (const indicatorElement of indicatorElements) {
      indicatorElement.classList.add('we-disabled')
    }
  }

  private indicatorElementEnable (module: string) {
    const moduleIndicators = document.getElementsByClassName(`we-${module}`)
    for (const moduleIndicator of moduleIndicators) {
      moduleIndicator.classList.remove('we-disabled')
    }
  }
}
