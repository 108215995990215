import {
  ClinicalGuidelineImportModel,
} from '@/vue_apps/catalogs_root/ClinicalGuidelinesCatalog/classes/ClinicalGuidelineImport/ClinicalGuidelineImportModel'

export const clinicalGuidelineImportAdapter = {
  toServer (data: ClinicalGuidelineImportModel) {
    return {
      replaceExisting: data.replaceExisting,
      clinicalGuidelines: data.clinicalGuidelines
        .map(({ clinicalGuideline }) => clinicalGuideline),
    }
  },
}
