const HANDSHAKE_PATH = '/_ws'

class WSSubscriptionCore {
  constructor () {
    this.wsConsumer = window.ActionCable.createConsumer(HANDSHAKE_PATH)
    this.subscriptions = Object.create(null)
    this.subscribers = Object.create(null)
    this.targetChannel = null
  }

  createChannel () {
    const core = this
    const targetChannel = this.targetChannel

    core.subscribers[targetChannel] = []
    core.subscriptions[targetChannel] = core
      .wsConsumer
      .subscriptions
      .create(
        targetChannel,
        { received (data) { core.subscribers[targetChannel].forEach((callback) => callback(data)) } }
      )
  }

  createChannelIfNotExists () {
    const core = this
    const targetChannel = this.targetChannel

    if (!core.subscribers[targetChannel]) core.createChannel()
  }
}

const core = new WSSubscriptionCore()

export class WSSubscriptionManagerBase {
  /**
   * @param {({
   *   action?: string,
   *   batch?: boolean,
   *   data?: Object,
   *   meta?: Object,
   *   type?: string,
   * }) => unknown} callback
   * @returns {*}
   */
  connect (callback) {
    const targetChannel = core.targetChannel

    core.createChannelIfNotExists()
    core.subscribers[targetChannel].push(callback)

    return core.subscriptions[targetChannel]
  }

  advancedConnection (wsConnectionObject) {
    const targetChannel = core.targetChannel

    return core.wsConsumer.subscriptions.create(targetChannel, wsConnectionObject)
  }

  send (message) {
    const targetChannel = core.targetChannel

    core.createChannelIfNotExists()
    core.subscriptions[targetChannel].send({ data: message })
  }

  reset () {
    const targetChannel = core.targetChannel

    try {
      core.subscriptions[targetChannel].unsubscribe()
      delete core.subscriptions[targetChannel]
      delete core.subscribers[targetChannel]
    } catch (e) {
      console.warn(e)
    }
  }

  resetSubscriptions () {
    Object.keys(core.subscriptions).forEach((key) => {
      core.subscriptions[key].unsubscribe()
      delete core.subscriptions[key]
    })
    if (core.wsConsumer.connection.isOpen()) {
      core.wsConsumer.connection.reopen()
    }
    core.wsConsumer.subscriptions.subscriptions.forEach((subscription) => subscription.unsubscribe())
    core.subscribers = Object.create(null)
  }
}

const manager = new WSSubscriptionManagerBase()

export default new Proxy(Object.create(null), {
  get (_, targetChannel) {
    if (targetChannel === 'resetSubscriptions') return manager.resetSubscriptions.bind(manager)
    core.targetChannel = `${targetChannel.charAt(0).toUpperCase() + targetChannel.slice(1)}Channel`

    return manager
  },
  set () { throw new Error('unsupported operation') },
})
