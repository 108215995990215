import { MListServiceApi } from '@/_api/_requests/MListServiceApi/MListServiceApi'
import { SemdListItem } from '@/vue_apps/Semds/entities/list/SemdListItem'
import { ProtocolApi } from '@/vue_apps/ProtocolsApp/entities/ProtocolApi'
import { TSemdConnectorReturn } from '@/vue_apps/Semds/SemdSourceConnector/declarations/semdsSourceConnectorDeclarations'
import { baseSemdSourceEditCheck } from '@/vue_apps/Semds/SemdSourceConnector/baseSemdSourceEditCheck'

interface ISemdFiltersMap {
  ownerType: string
  ownerId: number
}

export class SemdListServiceApi extends MListServiceApi<SemdListItem, ISemdFiltersMap> {
  constructor (config: ISemdFiltersMap) {
    super({
      defaultFilters: { ownerType: config.ownerType, ownerId: config.ownerId },
      src: Routes.search_api_internal_semds_path(),
      DataItemEntity: SemdListItem,
    })
  }

  get lastSemd (): SemdListItem | undefined {
    return this.data[0] as unknown as SemdListItem
  }

  async checkProtocolEditing (protocol: ProtocolApi<any>): TSemdConnectorReturn {
    await this.fetchAll()

    return baseSemdSourceEditCheck(protocol, this.lastSemd)
  }
}
