import Entity from './Entity'

export default class EntriesVariable extends Entity {
  static buildTemplate (data, options) {
    const transl = data.title
    const title = t(`${data.value.split('&').shift()}`) + `.${transl}`
    const template = `<span class="${options.class} mceNonEditable t-element" data-value="${data.value}" \
                    data-title="${data.title}" data-id="${data.id}" contenteditable="false" style="${data.style}"><${title}><span>`

    // removed erb tags
    return template
  }

  static buildSaveTemplate (data, options, element) {
    element.classList.add(options.class)
    element.dataset.value = data.value
    element.dataset.title = data.title
    element.dataset.id = data.id
    element.innerText = `<%= @${data.value} %>`
  }

  edit () {
    console.debug(this.data)
  }

  update () {
    this.element.setAttribute('contenteditable', false)

    return false
  }

  showValue () {
    // Сохранение стилей из шаблона (*backend*) cu-1phjt22
    // почему сделано именно так мне неизвестно,
    // но зачем-то происходит передел outerHTML DOM элемента
    // Здесь просто прокидываются стили элемента в объект,
    // а затем отрисовываются в buildTemplate()
    this.data.style = this.element && this.element.style && this.element.style.cssText

    this.element.outerHTML = this.constructor.buildTemplate(this.data, this.options)
  }

  get saveCollbak () {
    this.constructor.buildSaveTemplate(this.data, this.options, this.element)
  }

  /**
   *
   */
  elementHead (showTypes) {}

  updateElement (data) {
    this.element.setAttribute('data-id', data.id)
    this.element.setAttribute('data-name', data.name)
  }

  get switch () {
    return false
  }
}
