import {
  TLazyTreeKey,
  TLazyTreeNode,
  TLazyTreeNodeDataUpdate,
  TLazyTreeRef,
} from '@/vue_present/Reuse/LazyTree/interfaces/types'
import { cloneDeep } from 'lodash'

const getChildrenNodeIndex = (node: TLazyTreeNode) => {
  return node.parent.childNodes
    .findIndex((childNode) => childNode.id === node.id)
}

const patchNode = (node: TLazyTreeNode, updateData: TLazyTreeNodeDataUpdate) => {
  const nodeCopy = cloneDeep(node) as TLazyTreeNode
  const {
    extraAttributes,
    ...data
  } = updateData

  Object.keys(data ?? {}).forEach((key) => {
    nodeCopy.data[key] = data[key]
  })

  Object.keys(extraAttributes ?? {}).forEach((key) => {
    nodeCopy.data.extraAttributes[key] = extraAttributes[key]
  })

  return nodeCopy
}

export const updateLazyTreeNodeById = (
  treeRef: TLazyTreeRef,
  parentId: number,
  id: TLazyTreeKey,
  updateData: TLazyTreeNodeDataUpdate
) => {
  const parentNode = treeRef.getNode(parentId)
  if (!parentNode || !parentNode.loaded) { return }

  const node = treeRef.getNode(id)
  const index = getChildrenNodeIndex(node)
  const patchedNode = patchNode(node, updateData)

  const childrenData = node.parent.childNodes
    .toSpliced(index, 1, patchedNode)
    .map((node) => node.data)

  treeRef.updateKeyChildren(parentId, childrenData)
}
