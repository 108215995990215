import { MPresenterBase } from '@/_api/_requests/MPresenterBase'
import { camelToSnake } from '@/_api/_requests/helpers'
import { paymentsAdapter } from '@/vue_apps/FinanceModule/FinanceModuleIndex/api/paymentsAdapter'

export class MPaymentPresenter extends MPresenterBase {
  constructor () {
    super({
      entity: 'payment',
      location: 'MPaymentPresenter',
      routes: {
        one () {},
        all () {},
        list: Routes.list_api_internal_finances_payments_path,
      },
    })
  }

  list (data, config = {}) {
    return super.list(data, {
      toServerAdapter: (data) => camelToSnake(paymentsAdapter.toServer(data)),
      toJson: true,
      ...config,
    })
      .then((res) => paymentsAdapter.toClient(res))
  }
}
