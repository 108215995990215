import { ICatalog } from '@/_declarations/ICatalog'
import {
  CLINICAL_GUIDELINE_ENTRY_STEP,
} from '@/vue_apps/catalogs_root/ClinicalGuidelinesCatalog/consts/clinicalGuidelineEntryStep'
import {
  CLINICAL_GUIDELINE_ENTRY_KIND,
} from '@/vue_apps/catalogs_root/ClinicalGuidelinesCatalog/consts/clinicalGuidelineEntryKind'
import {
  CLINICAL_GUIDELINE_ENTRY_UUR,
} from '@/vue_apps/catalogs_root/ClinicalGuidelinesCatalog/consts/clinicalGuidelineEntryUUR'
import {
  TClinicalGuidelineEntryUur,
} from '@/vue_apps/catalogs_root/ClinicalGuidelinesCatalog/interfaces/TClinicalGuidelineEntryUur'
import { MTableCell } from '@/vue_present/_base/Tables/MTableGenerator/MTableCell'
import { MListServiceNestedItemsItem } from '@/_api/_requests/MListServiceNestedItemsItem'
import { TClinicalGuidelineEntryProps } from '@/vue_apps/catalogs_root/ClinicalGuidelinesCatalog/interfaces/types'

export class ClinicalGuidelineEntryItem extends MListServiceNestedItemsItem {
  static healthcareBenchmarkTitle = t('clinicalGuidelines.catalog.entry.healthcareBenchmark').toLowerCase()

  arrowIconCell = new MTableCell()

  iconCell: MTableCell

  clinicalGuidelineId: number

  title: string

  titleCell: MTableCell

  kind: ICatalog<CLINICAL_GUIDELINE_ENTRY_KIND>

  step: ICatalog<CLINICAL_GUIDELINE_ENTRY_STEP>

  stepCell: MTableCell

  commentShort: string

  commentLong: string

  uur: ICatalog<CLINICAL_GUIDELINE_ENTRY_UUR>

  uurCell: MTableCell

  udd: ICatalog<TClinicalGuidelineEntryUur>

  uddCell: MTableCell

  healthcareBenchmark = false

  constructor (id?: number, props?: TClinicalGuidelineEntryProps) {
    super(id, props)

    if (!props) { return }

    this.setIconCell(props)

    this.clinicalGuidelineId = props.clinicalGuidelineId
    this.title = props.title
    this.kind = props.kind
    this.step = props.step
    this.commentShort = props.commentShort
    this.commentLong = props.commentLong
    this.uur = props.uur
    this.udd = props.udd
    this.healthcareBenchmark = props.healthcareBenchmark

    this.titleCell = new MTableCell(this.title)
      .addTooltip(true)
      .addCut()

    this.stepCell = new MTableCell(this.step.title)
      .addTooltip(true)
    this.uurCell = new MTableCell(this.uur.title)
    this.uddCell = new MTableCell(this.udd.title)
  }

  private setIconCell (props: TClinicalGuidelineEntryProps) {
    this.iconCell = new MTableCell()
      .addTypeIcon(this.getIcon(props.kind.id))
      .addClasses(this.getIconColorClass(props.healthcareBenchmark))
      .addTooltip(this.getTooltip(props.kind.title, props.healthcareBenchmark))
  }

  private getIcon (kind: CLINICAL_GUIDELINE_ENTRY_KIND) {
    const map = {
      [CLINICAL_GUIDELINE_ENTRY_KIND.CONSULTATION]: 'referral',
      [CLINICAL_GUIDELINE_ENTRY_KIND.PHYSICAL_EXAMINATION]: 'physicalExamination',
      [CLINICAL_GUIDELINE_ENTRY_KIND.LAB_EXAMINATION]: 'laboratories',
      [CLINICAL_GUIDELINE_ENTRY_KIND.INSTRUMENTAL_EXAMINATION]: 'search',
      [CLINICAL_GUIDELINE_ENTRY_KIND.OTHER_EXAMINATION]: 'otherExamination',
      [CLINICAL_GUIDELINE_ENTRY_KIND.CONSERVATIVE_TREATMENT]: 'conservativeTreatment',
      [CLINICAL_GUIDELINE_ENTRY_KIND.SURGICAL_TREATMENT]: 'surgicalTreatment',
      [CLINICAL_GUIDELINE_ENTRY_KIND.OTHER_TREATMENT]: 'service',
      [CLINICAL_GUIDELINE_ENTRY_KIND.MEDICAL_REHABILITATION]: 'medicalRehabilitation',
      [CLINICAL_GUIDELINE_ENTRY_KIND.DISPENSARY_OBSERVATION]: 'dispensaryObservation',
      [CLINICAL_GUIDELINE_ENTRY_KIND.MEDICINE]: 'medicine',
    }

    return map[kind]
  }

  private getIconColorClass (healthcareBenchmark: boolean) {
    return healthcareBenchmark
      ? 'success'
      : 'grey'
  }

  private getTooltip (title: string, healthcareBenchmark: boolean) {
    return healthcareBenchmark
      ? `${title} - ${ClinicalGuidelineEntryItem.healthcareBenchmarkTitle}`
      : title
  }
}
