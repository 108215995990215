import { ORDER_PAID_STATUS, PAYMENT_ERRORS, PAYMENT_PARAMS } from './consts'
import Payment from './payment'
import { extractEntriesAndMembers } from '@/lib/payments/helpers'

let entriesMap = {}

export default class OrderPayment extends Payment {
  constructor (order, attr) {
    super(attr)
    Object.defineProperties(this, {
      _order: {
        value: order,
        enumerable: false,
      },
    })

    this.destination_order_id = this._orderId
    this.destination_client_id = this._order.client.id

    this.__setEntriesMap()

    this._summaryInput >= this._unpaid
      ? this._paymentFull()
      : this._paymentCredit()
  }

  /**
   * Хелпер для быстрого получения комплекса по member.complex_id
   * @private
   */
  __setEntriesMap () {
    const entries = this._orderEntries
      .filter((item) => !item.complex_id)
      .map((item) => {
        item.tempEntryUnpaidSum = Utils.toMoney(item.unpaid_sum)

        return item
      })

    entriesMap = Utils.arrayToMap(entries)
  }

  get totalIncome () {
    if (Utils.toMoney(this.by_balance) > 0) {
      return super.totalIncome
    }
    if (this._summaryInput >= this._unpaid) {
      return this._withDeposit(this._unpaid)
    }

    return this._summaryInput >= 0 ? this._withDeposit(this._summaryInput) : 0
  }

  _paymentFull () {
    this.kind = PAYMENT_PARAMS.KIND_PAYMENT.FULL

    if (this._orderInCredit) {
      this.kind = PAYMENT_PARAMS.KIND_PAYMENT.SUBSEQUENT
    }

    this.__collectPaymentEntriesAttributes(this._unpaid)
  }

  _paymentCredit () {
    this.byCredit = this._unpaid - this._summaryInput
    this.kind = PAYMENT_PARAMS.KIND_PAYMENT.INITIAL

    if (this._orderNotPaid && this._summaryInput <= 0) {
      this.byCredit = this._unpaid
      this.kind = PAYMENT_PARAMS.KIND_PAYMENT.CREDIT
    }
    if (this._orderInCredit) {
      this.errors.push(
        {
          code: PAYMENT_ERRORS.TYPES.CLIENT.ORDER_IN_CREDIT.CODE,
          [this._orderId]: [PAYMENT_ERRORS.MESSAGES.ORDER_IN_CREDIT],
        }
      )
    }

    this.__collectPaymentEntriesAttributes(this._summaryInput)
  }

  /**
   * Сборка payment_entries_attributes
   * @param {Number} unpaidOrderEntryRemain
   * @private
   */
  __collectPaymentEntriesAttributes (unpaidOrderEntryRemain) {
    const { entries, members } = extractEntriesAndMembers(this._orderEntries)

    this.__collectPaymentEntriesAttributesByEntries(entries, unpaidOrderEntryRemain)
    this.__collectPaymentEntriesAttributesByMembers(members)
    this.__cleanPaymentEntriesAttributes()
  }

  /**
   * Сборка payment_entries_attributes, только entries БЕЗ complex_id
   * @param {Array} entries
   * @param {Number} unpaidOrderEntryRemain
   * @private
   */
  __collectPaymentEntriesAttributesByEntries (entries, unpaidOrderEntryRemain) {
    entries.map((entry) => {
      const paymentEntry = this.__createPaymentEntry(entry, unpaidOrderEntryRemain)

      this.payment_entries_attributes.push(paymentEntry)
      unpaidOrderEntryRemain -= Utils.toMoney(entry.unpaid_sum)
    })
  }

  /**
   * Сборка payment_entries_attributes, только entries C complex_id
   * @param {Array} members
   * @private
   */
  __collectPaymentEntriesAttributesByMembers (members) {
    members.map((member) => {
      const complex = entriesMap[member.complex_id]
      const paymentEntry = this.__createPaymentEntry(member, complex.tempEntryUnpaidSum)

      this.payment_entries_attributes.push(paymentEntry)
      complex.tempEntryUnpaidSum -= Utils.toMoney(paymentEntry.tempEntryUnpaidSum)
    })
  }

  /**
   * Очистка мусора в payment_entries_attributes
   * @private
   */
  __cleanPaymentEntriesAttributes () {
    this.payment_entries_attributes = this.payment_entries_attributes.map((item) => {
      delete item.tempEntryUnpaidSum

      return item
    })
  }

  __createPaymentEntry (entry, remain) {
    const sum = Utils.toMoney(entry.final_sum)
    const unpaidSum = Utils.toMoney(entry.unpaid_sum)
    const entryPay = Utils.toMoney(remain >= 0 ? remain : 0)
    remain = Utils.toMoney(remain)
    const paymentEntryAttributes = {
      entry_id: entry.id,
      base_kind: PAYMENT_PARAMS.BASE_KIND.FUND,
      member: Boolean(entry.complex_id),
      tempEntryUnpaidSum: unpaidSum,
    }

    if (remain >= unpaidSum) {
      paymentEntryAttributes.sum = entry.unpaid_sum
      paymentEntryAttributes.kind = PAYMENT_PARAMS.KIND_ENTRY.FULL

      if (unpaidSum < sum || (unpaidSum === sum && this._orderInCredit)) {
        paymentEntryAttributes.kind = PAYMENT_PARAMS.KIND_ENTRY.SUBSEQUENT
      }
      if (unpaidSum === 0 && this._orderInCredit) {
        paymentEntryAttributes.kind = PAYMENT_PARAMS.KIND_ENTRY.EMPTY
      }

      return paymentEntryAttributes
    }

    paymentEntryAttributes.sum = entryPay
    paymentEntryAttributes.kind = PAYMENT_PARAMS.KIND_ENTRY.INITIAL

    if (unpaidSum === sum && remain <= 0) {
      paymentEntryAttributes.kind = PAYMENT_PARAMS.KIND_ENTRY.CREDIT
    }

    if (unpaidSum === 0) {
      paymentEntryAttributes.kind = PAYMENT_PARAMS.KIND_ENTRY.FULL
    }

    return paymentEntryAttributes
  }

  get _unpaid () {
    return Utils.toMoney(this._order.unpaid_sum)
  }

  get _orderId () {
    return Utils.toMoney(this._order.id)
  }

  get _orderEntries () {
    return this._order.entries
  }

  get _orderInCredit () {
    return this._order.paid_status === ORDER_PAID_STATUS.PAID_CREDIT
  }

  get _orderNotPaid () {
    return this._order.paid_status === ORDER_PAID_STATUS.NOT_PAID
  }
}
