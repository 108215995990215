const SAFE_LOAD_TIME_MS = 250

export const SpinnerHolder = {
  data () {
    return {
      loading: false,
      loadingTimeout: null,
    }
  },

  methods: {
    /**
     * @param {Promise<any>} promise
     * @param {String} [loadingProp]
     * @param {Boolean|Number} withBounce
     */
    withSpinner (promise, loadingProp = 'loading', withBounce = SAFE_LOAD_TIME_MS) {
      if (this.loadingTimeout) {
        clearTimeout(this.loadingTimeout)
        this.loadingTimeout = null
      }

      setTimeout(() => { this[loadingProp] = true })

      promise.finally(() => {
        this.loadingTimeout =
            setTimeout(() => { this[loadingProp] = false }, withBounce || 0)
      })

      return promise
    },

    /**
     * @param {Promise<Any>} promise
     * @param {string} loadingProp
     * @return {Promise<Any>}
     */
    async withSpinnerSimple (promise, loadingProp = 'loading') {
      this[loadingProp] = true
      const response = await promise
      this[loadingProp] = false

      return response
    },

    startLoading (loadingProp = 'loading') {
      this[loadingProp] = true
    },

    stopLoading (loadingProp = 'loading') {
      this[loadingProp] = false
    },
  },
}
