<template>
  <m-panel
    v-loading="loading"
    class="payment-page"
    :title="title"
    :icon="icon"
  >
    <template #headExtra>
      <payment-head-extra
        v-if="isShowMode"
        :payment="payment"
        @loading="loading = $event"
      />
    </template>

    <div
      v-if="!loading && payment.distributor.kind"
      class="payment-page__body"
    >
      <div class="payment-page__container">
        <payment-info :payment="payment" />

        <payment-fields
          v-if="showFields"
          :payment="payment"
          @registerValidator="onRegisterValidator"
        />
      </div>

      <div class="payment-page__container">
        <template v-if="payment.destinationOrder?.id">
          <payment-show-order
            v-if="isShowMode"
            :payment="payment"
          />

          <payment-order
            v-else
            :payment="payment"
          />
        </template>

        <payment-entries
          v-if="payment.destinationOrder?.id && showFields"
          :payment="payment"
        />
      </div>
    </div>

    <div
      v-if="payment.destinationOrder?.id && !showFields"
      class="mb-indent-validation"
    >
      <payment-entries
        :payment="payment"
      />
    </div>

    <div class="flex flex-column">
      <m-button
        v-if="showRefund"
        v-tooltip="refundTooltip"
        class="min-content"
        type="danger"
        :text="t('make_refund')"
        :disabled="Boolean(refundTooltip)"
        icon="undo"
        @click="onRefund"
      />

      <m-button-submit
        v-if="!isShowMode && !showRefund"
        class="min-content"
        :disabled="submitLoading"
        @click="onSubmit"
      />

      <m-button-back :on-click-handler="onBack" />
    </div>
  </m-panel>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import MPanel from '@/vue_present/_base/MPanel/MPanel.vue'
import { PaymentApi } from '@/vue_apps/FinanceModule/FinanceModuleIndex/classes/Payment/PaymentApi'
import PaymentInfo from '@/vue_apps/FinanceModule/FinanceModuleIndex/components/Payment/PaymentInfo.vue'
import { PAYMENT_BASE_KIND } from '@/vue_apps/FinanceModule/FinanceModuleIndex/consts/paymentBaseKind'
import MButton from '@/vue_present/_base/buttons/MButton/MButton.vue'
import PaymentEntries from '@/vue_apps/FinanceModule/FinanceModuleIndex/components/Payment/PaymentEntries.vue'
import { PaymentModesMixin } from '@/vue_apps/FinanceModule/FinanceModuleIndex/mixins/PaymentModesMixin'
import { PAYMENT_KINDS } from '@/vue_apps/FinanceModule/FinanceModuleIndex/consts/paymentKinds'
import { i18nScopeMixin } from '@/vue_present/mixins/i18nScopeMixin'
import PaymentHeadExtra from '@/vue_apps/FinanceModule/FinanceModuleIndex/components/Payment/PaymentHeadExtra.vue'
import PaymentShowOrder from '@/vue_apps/FinanceModule/FinanceModuleIndex/components/Payment/PaymentShowOrder.vue'
import PaymentOrder from '@/vue_apps/FinanceModule/FinanceModuleIndex/components/Payment/PaymentOrder.vue'
import PaymentFields from '@/vue_apps/FinanceModule/FinanceModuleIndex/components/Payment/PaymentFields.vue'
import { SpinnerHolder } from '@/vue_components/mixins/spinner_holder'
import MButtonSubmit from '@/vue_present/_base/buttons/MButtonSubmit/MButtonSubmit.vue'
import { MAlert } from '@/vue_present/_base/MMessageBox/MAlert'
import { MConfirm } from '@/vue_present/_base/MConfirm/MConfirm'
import { PAYMENT_PAYER_TYPE } from '@/vue_apps/FinanceModule/FinanceModuleIndex/consts/paymentPayerType'
import { ValidationParentMixin } from '@/vue_present/mixins/ValidationParentMixin'
import { IPaymentInitOptions } from '@/vue_apps/FinanceModule/FinanceModuleIndex/interfaces/IPaymentInitOptions'
import { mapActions } from 'vuex'
import MButtonBack from '@/vue_present/_base/buttons/MButtonBack/MButtonBack.vue'

export default defineComponent({
  name: 'PaymentPage',

  components: {
    MButtonSubmit,
    PaymentFields,
    PaymentShowOrder,
    PaymentHeadExtra,
    MButtonBack,
    PaymentEntries,
    PaymentOrder,
    MButton,
    PaymentInfo,
    MPanel,
  },

  mixins: [
    PaymentModesMixin,
    i18nScopeMixin,
    SpinnerHolder,
    ValidationParentMixin,
  ],

  beforeRouteUpdate (to, from, next) {
    this.withSpinner(this.init({
      id: to.params.id,
      kind: to.query.kind,
      payerId: to.query.payer_id,
      payerType: to.query.payer_type,
      orderId: to.query.order_id,
      refundableId: to.query.refundable_id,
    } as IPaymentInitOptions))

    this.initWorkplacePubSubSubscribes()

    // TODO VUE 3: убрать next() там он не нужОн
    next()
  },

  data () {
    return {
      PAYMENT_KINDS,
      payment: new PaymentApi(),
      submitLoading: false,
    }
  },

  computed: {
    title () {
      if (this.isShowMode) {
        return t('payment')
      }

      return this.payment.distributor.baseKind === PAYMENT_BASE_KIND.REFUND
        ? t('refund')
        : t('creating_payment')
    },

    icon () {
      return this.payment.distributor.baseKind === PAYMENT_BASE_KIND.REFUND
        ? 'undo'
        : 'moneyBillWave'
    },

    refundTooltip () {
      return this.payment.payer.deletedAt
        ? t('customerDeleted')
        : null
    },

    showFields () {
      if (this.isShowMode) { return false }

      switch (this.payment.distributor.initialKind) {
        case PAYMENT_KINDS.ORDER_FULL:
        case PAYMENT_KINDS.ORDER_IN_CREDIT: return false
        default: return true
      }
    },

    showRefund () {
      return this.payment.canRefund && this.payment.distributor.baseKind !== PAYMENT_BASE_KIND.REFUND
    },

    isAcquiringEnabledAndConfigured () {
      return this.$m.modules.tradeAcquiring && this.$store.getters['acquiringStore/vxGetHasAcquiringBank']
    },
  },

  created () {
    this.setI18nScope('finance')

    const query = this.$route.query
    const promise = Promise.all([
      this.vxFetchConfigurationsCashRegistersAndAcquiring(),
      this.init({
        id: this.$route.params.id,
        kind: query.kind,
        payerId: query.payer_id,
        payerType: query.payer_type,
        orderId: query.order_id,
        refundableId: query.refundable_id,
      }),
    ])

    this.withSpinner(promise)
    this.initWorkplacePubSubSubscribes()
  },

  methods: {
    ...mapActions('acquiringStore', {
      vxFetchConfigurationsCashRegistersAndAcquiring: 'vxFetchConfigurationsCashRegistersAndAcquiring',
    }),

    async init (options: IPaymentInitOptions) {
      const { id, refundableId, kind, payerId, payerType } = options

      const baseKind = gon.specific.payment.base_kind
      this.payment.setDistributor(baseKind)

      const hasPaymentId = Boolean(id || refundableId)
      if (hasPaymentId && (this.isShowMode || baseKind === PAYMENT_BASE_KIND.REFUND)) {
        await this.payment.fetchLastTransactions(id || refundableId)
      }

      if (this.isShowMode) {
        await this.payment.fetchPayment(id)
      }

      if (this.isNewMode) {
        await this.initNewMode(options)
      }

      if (!this.payment.payerClient || !this.payment.payerCompany) {
        await this.payment.fetchPayer(payerId, payerType)
      }

      this.payment.distributor.setBaseKind(baseKind)
      this.payment.distributor.setValue('initialKind', kind || gon.specific.payment?.kind)

      if (this.isNewMode) {
        this.payment.distributor.recalculate()
      }
    },

    async initNewMode ({ orderId, refundableId }: IPaymentInitOptions) {
      if (orderId) {
        await this.payment.fetchDestinationOrder(orderId)
      }

      if (!refundableId) return
      await this.payment.fetchPayment(refundableId)

      if (this.payment.distributor.refundable && !gon.specific.refundable) return

      const refundable = gon.specific.refundable
      this.payment.distributor.setValue('refundable', {
        id: refundable.id,
        totalPaid: +refundable.total_paid,
      })
    },

    initWorkplacePubSubSubscribes () {
      Services.pubSub.subscribe('workplace.print_receipt', () => {
        this.loading = false
      })

      Services.pubSub.subscribe('workplace.receipt_record', () => {
        this.loading = false

        Turbolinks.visit()
      })

      Services.pubSub.subscribe('workplace.error', (msg: Record<string, any>) => {
        this.loading = false

        MAlert.info(msg.data.human_message)
      })

      if (!this.isNewMode) { return }

      Services.pubSub.subscribe('PAYMENT.UPDATE_PAYER_FINANCE', (value: string) => {
        this.payment.updatePayerFinance(Utils.toMoney(value))

        this.payment.distributor.recalculate()
      })
    },

    onRefund () {
      const payerType = this.payment.payer.isClient
        ? PAYMENT_PAYER_TYPE.CLIENT
        : PAYMENT_PAYER_TYPE.COMPANY

      const urlParams = new URLSearchParams({
        payer_id: this.payment.payer.id,
        payer_type: payerType,
        refundable_id: this.payment.id,
        type: PAYMENT_BASE_KIND.REFUND,
      })

      Turbolinks.visit(`${Routes.new_payment_path()}?${urlParams.toString()}`)
    },

    async onSubmit () {
      if (this.payment.distributor.kind !== PAYMENT_KINDS.CASH_OUT && !await this.showSubmitConfirm()) { return }

      if (this.payment.distributor.byCredit && this.payment.distributor.initialKind === PAYMENT_KINDS.ORDER_INITIAL) {
        const message = `${Utils.toMoney(this.payment.distributor.byCredit)}${gon.application.currency.symbol_character} ${t('will_be_paid_by_credit')}`

        const { cancel } = await MConfirm.warning(message)
        if (cancel) { return }
      }

      const response = await this.withSpinnerSimple(this.payment.submit(), 'submitLoading')
      if (response.errors) {
        return this.resetValidations(response.errors)
      }

      Turbolinks.visit(Routes.payment_path(response.id))
    },

    async showSubmitConfirm () {
      const needFundWithAcquiring = this.payment.distributor.baseKind === PAYMENT_BASE_KIND.FUND &&
          this.isAcquiringEnabledAndConfigured &&
          Boolean(this.payment.distributor.byCard)
      const needRefundWithAcquiring = this.payment.isLastTransactionCancelOrRefundAllowed

      if (!needFundWithAcquiring && !needRefundWithAcquiring) { return true }

      const message = needRefundWithAcquiring
        ? this.t$('acquiringRefundSubmitAlert')
        : this.t$('acquiringFundSubmitAlert')

      const { close, cancel } = await MConfirm.warning(message)

      return !(cancel || close)
    },

    onBack () {
      HistoryBrowser.goBackFrom('payment')
    },
  },
})
</script>
