import {
  PersonalCheckupDoctorResultsModel,
} from '@/vue_apps/CheckupsModule/classes/PersonalCheckupDoctorResults/PersonalCheckupDoctorResultsModel'
import {
  PERSONAL_CHECKUP_DOCTOR_RESULTS_NO_MEDICAL_CONTRAINDICATIONS_NSI_ID,
} from '@/vue_apps/CheckupsModule/store/personalCheckupDoctorResults/personalCheckupDoctorResultsConst'

export class PersonalCheckupDoctorResultsLogic extends PersonalCheckupDoctorResultsModel {
  setDefaultMedicalAssessment () {
    this.medicalAssessment = this.medicalAssessments
      .find((item) =>
        +item.nsiId === PERSONAL_CHECKUP_DOCTOR_RESULTS_NO_MEDICAL_CONTRAINDICATIONS_NSI_ID)
  }

  append () {
    this.setUserDefault()

    this.nestedItems.append({
      specialty: this.specialty,
      user: this.user,
      date: this.date,
      medicalAssessment: this.medicalAssessment,
      foreignUser: this.foreignUser,
      foreignUserName: this.foreignUserName,
      foreignUserSurname: this.foreignUserSurname,
      foreignUserSecondName: this.foreignUserSecondName,
    })
  }

  clear () {
    this.specialty = null
    this.user = null
    this.date = Utils.getBaseFormattedDate()
    this.foreignUser = false
    this.setDefaultMedicalAssessment()
    this.setUserDefault()
  }

  private setUserDefault () {
    if (this.foreignUser) {
      this.user = null

      return
    }

    this.foreignUserSurname = null
    this.foreignUserName = null
    this.foreignUserSecondName = null
  }
}
