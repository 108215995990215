export const iconsMap = Object.freeze({
  // БАЗОВЫЕ
  save: 'fa-save',
  add: 'fa-plus',
  plus: 'fa-plus',
  delete: 'fa-trash',
  change: 'fa-pen',
  edit: 'fa-pen',
  clear: 'fa-eraser',
  reset: 'fa-sync-alt',
  download: 'fa-download',
  'open-plan': 'fa-lock-open',
  'close-plan': 'fa-lock',
  ready: 'fa-check',
  readyCircle: 'fa-check-circle',
  'not-ready': 'fa-circle',
  notReadyCircle: 'fa-times-circle',
  send: 'fa-share',
  protocol: 'fa-list-alt',
  catalogs: 'fa-books',
  sign: 'fa-signature',
  signature: 'fa-file-signature',
  signed: 'fa-file-signature green',
  signalStream: 'fa-signal-stream',
  'not-signed': 'fa-file-signature red',
  fileSignature: 'fa-file-signature',
  close: 'fa-times',
  collapse: 'fa-compress',
  expand: 'fa-expand',
  file: 'fa-file',
  fileAlt: 'fa-file-alt',
  filePlus: 'fa-file-plus',
  filePdf: 'fa-file-pdf',
  tooth: 'fa-tooth',
  briefcase: 'fa-briefcase',
  import: 'fa-file-import',
  export: 'fa-file-export',
  folder: 'fa-folder',
  folderOpen: 'fa-folder-open',
  menu: 'fa-bars',
  move: 'fa-person-carry',
  copy: 'fa-copy',
  referral: 'fa-user-md-chat',
  doctor: 'fa-user-md',
  doctors: 'fa-user-friends',
  patient: 'fa-user',
  users: 'fa-users',
  'patient-plus': 'fa-user-plus',
  warning: 'fa-exclamation-triangle',
  warningCircle: 'fa-exclamation-circle',
  info: 'fa-info-circle',
  infoLetter: 'fa-info',
  search: 'fa-search',
  filter: 'fa-filter',
  complex: 'fa-ballot-check',
  product: 'fa-cube',
  sms: 'fa-sms',
  whatsapp: 'fa-whatsapp',
  flash_call: 'fad fa-phone',
  phone: 'fad fa-phone',
  comments: 'fa-comments',
  comment: 'fa-comment-alt-lines',
  calendar: 'fa-calendar-edit',
  'menu-dots': 'fa-ellipsis-h',
  print: 'fa-print',
  notPrinted: 'fa-print-slash',
  unite: 'fa-object-group',
  'shopping-cart': 'fa-shopping-cart',
  company: 'fa-building',
  relations: 'fa-chart-network',
  more: 'fa-chevron-double-down',
  bonus: 'fa-tags',
  config: 'fa-cog',
  brackets: 'fa-brackets-curly',
  look: 'fa-eye',
  upload: 'fa-upload',
  list: 'fa-list',
  good: 'fa-archive',
  moneyBag: 'fa-sack',
  questionCircle: 'fa-question-circle',
  key: 'fa-key',
  transfer: 'fa-random',
  link: 'fa-external-link-square',
  externalLink: 'fa-external-link',
  report: 'fa-ballot',
  table: 'fa-table',
  betterCallSaul: 'fa-user-headset',
  excel: 'fa-file-excel',
  tasks: 'fa-tasks',
  calc: 'fa-calculator',
  worker: 'fa-user-hard-hat',
  gift: 'fa-gift',
  clinics: 'fa-hospitals',
  hospitalUser: 'fa-hospital-user',
  square: 'fa-square',
  chartBar: 'fa-chart-bar',
  rectangleLandscape: 'fa-rectangle-landscape',
  home: 'fa-home',
  undo: 'fa-undo',
  checkSquare: 'fa-check-square',
  ban: 'fa-ban',
  cut: 'fa-cut',
  attachment: 'fa-paperclip',
  pack: 'fa-cubes',
  sendAnalysisOrder: 'fa-box',
  bolt: 'fa-bolt',
  syringe: 'fa-syringe',
  virus: 'fa-virus',
  clipboardUser: 'fa-clipboard-user',
  fileCheck: 'fa-file-check',
  fileCode: 'fa-file-code',
  fileContract: 'fa-file-contract',
  paperScroll: 'fa-scroll',
  oldFile: 'fa-scroll-old',
  shareSquare: 'fa-share-square',
  filesMedical: 'fa-files-medical',
  letter: 'fa-envelope',
  tank: 'fa-conveyor-belt',
  conveyorAlt: 'fa-conveyor-belt-alt',
  switchOff: 'fa-toggle-off',
  switchOn: 'fa-toggle-on',
  star: 'fa-star',
  commentsAlt: 'fa-comments-alt',
  chartLine: 'fa-chart-line',
  modal: 'fa-window',
  taxCertificate: 'fa-file-invoice',
  history: 'fa-history',
  templateRecord: 'fa-book-medical',
  receipt: 'fa-receipt',
  envelope: 'fa-envelope',
  university: 'fa-university',
  longArrowAltLeft: 'fa-long-arrow-alt-left',
  plusSquare: 'fa-plus-square',
  minusSquare: 'fa-minus-square',
  clinicalGuideline: 'fa-books-medical',

  // ДЛЯ МЕНЮ
  client: 'fa-user',
  finance: 'fa-ruble-sign',
  order: 'fa-shopping-cart',
  payment: 'fa-money-bill-wave-alt',
  moneyBillWave: 'fa-money-bill-wave',
  expense: 'fa-dollar-sign',
  service: 'fa-hand-holding-medical',
  document: 'fa-copy',
  appointment: 'fa-calendar-day',
  medicalPolices: 'fa-shield-check',
  call: 'fa-phone-office',
  reminder: 'fa-tasks',
  notification: 'fa-sms',
  treatmentPlan: 'fa-notes-medical',
  dentalOrder: 'fa-gavel',
  analysis: 'fa-flask',
  medicalRecord: 'fa-id-card-alt',

  // иконки appointments
  needApproval: 'fa-circle-notch',
  approved: 'fa-circle',
  arrived: 'fa-user',
  serviced: 'fa-clipboard-check',
  billed: 'fa-credit-card',
  paid: 'fa-check-square',
  canceled: 'fa-times',
  failed: 'fa-user-times',
  confirmedByAdministrator: 'fad fa-fw fa-thumbs-up',

  // статусы сообщений
  allMessageStatuses: 'fa-comment-smile',
  deliveredMessage: 'fa-check',
  failedMessage: 'fa-comment-slash',
  startedMessage: 'fa-comment-dots',
  readMessage: 'fa-check-double',
  sentMessage: 'fa-comment-check',
  sent_to_transportMessage: 'fa-inbox-out',
  canceledMessage: 'fa-comment-times',

  // иконки для статусов подтверждения звонков
  pending: 'fa-phone-volume',
  confirmed: 'fa-phone-plus',
  clientCanceled: 'fa-user-times',
  failedToConnect: 'fa-phone-slash',

  // заметки
  noteEmpty: 'fa-comment-alt',
  noteFilled: 'fa-comment-alt-lines',

  // список услуг
  entryTypesEmpty: 'fa-clipboard',
  entryTypesFilled: 'fa-clipboard-list',

  // contacts
  callOut: 'fa-phone-alt',
  smsOut: 'fa-sms',
  whatsappOut: 'fa-whatsapp',

  // справочники
  attractionSources: 'fa-compress-arrows-alt',
  appointmentSources: 'fa-code-branch',
  laboratories: 'fa-microscope',
  messages: 'fa-comment',
  measureUnits: 'fa-weight-hanging',

  up: 'fa-chevron-up',
  down: 'fa-chevron-down',
  right: 'fa-chevron-right',
  left: 'fa-chevron-left',

  // типы платежей
  orderInitial: 'fa-money-bill',
  orderSubsequent: 'fa-cart-plus',
  orderRefund: 'fa-reply',
  cashIn: 'fa-user-plus',
  cashInRefund: 'fa-reply',
  cashOut: 'fa-user-minus',
  cashRegister: 'fa-cash-register',
  orderFull: 'fa-shopping-cart',
  orderInCredit: 'fa-credit-card',

  // профосмотры
  checkups: 'fa-stethoscope',
  periodicCheckup: 'fa-repeat',
  priorCheckup: 'fa-repeat-1',

  // профосмотры -> статусы
  plannedCheckupStatus: 'fa-spinner',
  inProgressCheckupStatus: 'fa-spinner-third',
  readyCheckupStatus: 'fa-check',
  canceledCheckupStatus: 'fa-times',

  // Заказы в лабораторию
  analysisOrderPrepared: 'fa-square',
  analysisOrderSent: 'fa-paper-plane',
  analysisOrderAccepted: 'fa-check',
  analysisOrderRefused: 'fa-times',
  analysisOrderReady: 'fa-check',
  analysisOrderPartiallyReady: 'fa-check',

  // Отзывы. Статусы
  awaitingClientNotification: 'fa-spinner',
  failedToNotifyClient: 'fa-ban',
  awaitingClientFeedback: 'fa-spinner-third',
  clientFeedbackReceived: 'fa-comment-dots',
  processing: 'fa-comment-plus',
  completed: 'fa-comment-check',

  // Клинические рекомендации
  medicine: 'fa-tablets',
  surgicalTreatment: 'fa-scalpel-path',
  dispensaryObservation: 'fa-pump-medical',
  medicalRehabilitation: 'fa-procedures',
  physicalExamination: 'fa-running',
  conservativeTreatment: 'fa-cauldron',
  otherExamination: 'fa-diagnoses',

  // ФИЛЬТР (держать всегда последним из-за фронтбука)
  // ВСЕГДА ПОСЛЕДНИМ, Я ГОВОРЮ!!!
  'no-filter': 'fa-filter-remove',
})
