import { MListServiceItem } from '@/_api/_requests/MListServiceItem'
import { MTableCell } from '@/vue_present/_base/Tables/MTableGenerator/MTableCell'
import { GLOBAL_DEFAULT_COLORS } from '@/_global_scripts/GLOBAL_CONSTS'
import {
  IWorkplacesListItemToClientDTO,
} from '@/vue_apps/catalogs_root/Workplaces/interfaces/IWorkplacesListItemToClientDTO'

export class WorkplacesListItem extends MListServiceItem {
  title: MTableCell

  icon: MTableCell

  constructor (item: IWorkplacesListItemToClientDTO) {
    super()

    this.id = item.id

    this.title = new MTableCell(item.title)
      .addTooltip(true)
      .addCut()

    this.icon = new MTableCell()
      .addTypeIcon('rectangleLandscape')
      .addClasses(GLOBAL_DEFAULT_COLORS.PRIMARY)
  }
}
