import { MConfirm } from '@/vue_present/_base/MConfirm/MConfirm'

export const onConfirmCancel = () =>
  MConfirm.confirm(t('save_protocol_content'), t('confirmation'), {
    distinguishCancelAndClose: true,
    showClose: true,
    customClass: 'with-title',
    confirmButtonText: t('yes'),
    cancelButtonText: t('closeWithoutSaving'),
  })
