import { orUndefined } from '@/_medods_standart_library/msl'
import { TAcquiringFilters } from '@/vue_apps/FinanceModule/FinanceModuleIndex/interfaces/Acquiring/IAcquiringFilters'
import { IList } from '@/_declarations/IList'
import { DateHelpers } from '@/helpers/DateHelpers'

export const acquiringListAdapter = {
  toServer (data: TAcquiringFilters) {
    return {
      clinicIds: [gon.application.current_clinic.id],
      date: orUndefined(DateHelpers.toBasePeriod(data.date), true),
      operationTypes: orUndefined(data.operationType) && [data.operationType.id],
      userIds: orUndefined(data.user) && [data.user.id],
      statuses: orUndefined(data.status) && [data.status.id],
      workplaceIds: orUndefined(data.workplace) && [data.workplace.id],
      paymentTypes: orUndefined(data.paymentType) && [data.paymentType.id],
      limit: data.limit,
      offset: data.offset,
    }
  },

  toClient (data: IList) {
    return {
      ...data,
      totals: {
        ...data.totals,
        count: data.totalItems,
      },
    }
  },
}
