<template>
  <m-button-edit
    v-if="isShowMode"
    :text="actionButton.text"
    :icon="actionButton.icon"
    @click="onEditClick"
  />
  <m-button-submit
    v-else
    @click="$emit('onSubmit', $event)"
  />
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import MButtonSubmit from '@/vue_present/_base/buttons/MButtonSubmit/MButtonSubmit.vue'
import MButtonEdit from '@/vue_present/_base/buttons/MButtonEdit.vue'

export default defineComponent({
  name: 'CheckupDocumentActionButton',
  components: { MButtonEdit, MButtonSubmit },
  props: {
    isShowMode: Boolean,
    notActual: Boolean,
  },

  emits: [
    'onSubmit',
    'onEdit',
    'onActualize',
  ],

  computed: {
    actionButton () {
      if (this.notActual) {
        return {
          text: t('refresh'),
          icon: 'reset',
        }
      }

      return {
        text: t('edit'),
        icon: 'edit',
      }
    },
  },

  methods: {
    onEditClick ($event) {
      if (this.notActual) {
        return this.$emit('onActualize', $event)
      }

      this.$emit('onEdit', $event)
    },
  },
})
</script>
