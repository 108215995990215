<template>
  <m-popover
    v-show="visibility"
    ref="popover"
    class="m-draggable-window"
    inherited-visibility
    :visibility="visibility"
  >
    <template #all>
      <m-panel
        class="h-100"
        :icon="icon"
        :title="title"
        :type="type"
        :head-attributes="headAttributes"
        head-css-classes="grab"
        :body-loading="bodyLoading"
        :use-type-by-route="false"
        use-close-button
        @close="$updateSync('visibility', false)"
      >
        <template #default>
          <slot />
        </template>

        <template #footer>
          <slot name="footer" />
        </template>

        <template #footer-left>
          <slot name="footer-left" />
        </template>

        <template #footer-right>
          <slot name="footer-right" />
        </template>
      </m-panel>
    </template>
  </m-popover>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import MPopover from '@/vue_present/_base/MPopover/MPopover.vue'
import MPanel from '@/vue_present/_base/MPanel/MPanel.vue'
import {
  draggablePopover,
} from '@/plugins/dynamic_forms/components/context_menu/editor_mode/ui/TextareaProxyRoot/draggable'
import {
  loadModalPositionFromCache,
} from '@/plugins/dynamic_forms/components/context_menu/editor_mode/ui/TextareaProxyRoot/cache'
import { mapGetters } from 'vuex'
import {
  BASE_WINDOW_HEIGHT,
  BASE_WINDOW_LEFT,
  BASE_WINDOW_TOP,
  BASE_WINDOW_WIDTH,
} from '@/plugins/dynamic_forms/components/context_menu/editor_mode/ui/TextareaProxyRoot/const'

export default defineComponent({
  name: 'MDraggableWindow',

  components: {
    MPanel,
    MPopover,
  },

  props: {
    // MPanel
    icon: { type: String, default: undefined },
    title: { type: String, default: undefined },
    type: { type: String, default: undefined },
    bodyLoading: Boolean,

    // MPopover
    visibility: Boolean,

    // MDraggableWindow
    draggableAreaDataId: { type: String, required: true },
    positionCacheKey: { type: String, required: true },
    pubSubEventName: { type: String, default: null },
    closeOnRouteChange: { type: Boolean, default: true },
  },

  emits: ['close'],

  computed: {
    ...mapGetters('windowStore', {
      windowSize: 'vxGetWindowSize',
    }),

    headAttributes () {
      return {
        'data-id': this.draggableAreaDataId,
      }
    },
  },

  watch: {
    windowSize ({ clientWidth }) {
      this.setWindowPosition({
        left: clientWidth > BASE_WINDOW_WIDTH + BASE_WINDOW_LEFT
          ? BASE_WINDOW_LEFT
          : clientWidth - BASE_WINDOW_WIDTH,
        top: BASE_WINDOW_TOP,
        height: BASE_WINDOW_HEIGHT,
        width: BASE_WINDOW_WIDTH,
      })
    },

    '$route.path' () {
      if (!this.closeOnRouteChange) { return }
      this.$updateSync('visibility', false)
    },
  },

  mounted () {
    this.setWindowPosition(loadModalPositionFromCache(this.positionCacheKey))
    draggablePopover(this.$refs.popover.$el, this.positionCacheKey)
    this.subscribes()
  },

  beforeDestroy () {
    if (!this.pubSubEventName) { return }
    this.$pubSub.reset(`MDraggableWindow:${this.pubSubEventName}:Open`)
    this.$pubSub.reset(`MDraggableWindow:${this.pubSubEventName}:Close`)
  },

  methods: {
    subscribes () {
      if (!this.pubSubEventName) { return }

      this.$pubSub.subscribe(
          `MDraggableWindow:${this.pubSubEventName}:Open`,
          () => { this.$updateSync('visibility', true) }
      )

      this.$pubSub.subscribe(
          `MDraggableWindow:${this.pubSubEventName}:Close`,
          () => { this.$updateSync('visibility', false) }
      )
    },

    setWindowPosition ({ left, top, height, width }) {
      const element: HTMLElement = this.$refs.popover.$el
      const popover: HTMLElement = element.querySelector('.m-popover')

      element.style.top = `${top}px`
      element.style.left = `${left}px`
      popover.style.height = `${height}px`
      popover.style.width = `${width}px`
    },
  },
})
</script>
