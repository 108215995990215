import { ItemBase } from '@/_api/_classes/ItemBase'
import { IGuaranteeLetter } from '@/_declarations/IGuaranteeLetter'

export class MGuaranteeLetterModel extends ItemBase implements IGuaranteeLetter {
  _permit = ['id', 'issueDate', 'medicalPolicyId', 'number', 'sum']

  id: number = null

  // @ts-ignore
  issueDate: string = Utils.getBaseFormattedDate()

  medicalPolicyId: number = null

  number: string = null

  sum: number = 0
}
