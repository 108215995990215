import { DateHelpers } from '@/helpers/DateHelpers'
import {
  TaxCertificate2024Client,
} from '@/vue_apps/Documents/FixedDocuments/_documents/TaxCertificate2024/classes/TaxCertificate2024Client'
import { vueFilters } from '@/plugins/vue/filtersPlugin/vueFilters'
import { DOCUMENT_TYPES } from '@/vue_present/Reuse/DocumentTree/store/documentTypes'
import { getDocumentsOwnersAttributes } from '@/vue_present/Reuse/DocumentTree/store/getDocumentsOwnersAttributes'
import { DOCUMENT_OWNER_TYPES } from '@/vue_present/Reuse/DocumentTree/store/documentOwnerTypes'

export const taxCertificate2024Adapter = {
  /**
   * @param {ITaxCertificate2024Base} payload
   */
  toServer (payload) {
    return {
      dateStart: payload.period.at(0),
      dateEnd: payload.period.at(1),

      clientId: payload.taxpayer.id,
      destinationClientId: payload.taxpayerIsClient
        ? payload.taxpayer.id
        : payload.client.id,

      clinicId: payload.clinic.id,

      kind: payload.type,

      payerData: {
        name: payload.taxpayer.name,
        surname: payload.taxpayer.surname,
        secondName: payload.taxpayer.secondName,
        inn: payload.taxpayer.inn,
        birthdate: payload.taxpayer.birthdate,
        phone: payload.taxpayer.phone,
      },

      payerIdentityDocumentData: getIdentityDocumentData(payload.taxpayer),

      title: payload.title,
      innValue: payload.clinic.inn,
      kppValue: payload.clinic.kpp,
      number: payload.certificateNumber,
      correctionNumber: payload.correctionNumber,
      reportingYear: payload.reportingYear,

      clientData: {
        name: payload.client.name,
        surname: payload.client.surname,
        secondName: payload.client.secondName,
        inn: payload.client.inn,
        birthdate: payload.client.birthdate,
        phone: payload.taxpayer.phone,
      },

      clientIdentityDocumentData: getIdentityDocumentData(payload.client),

      individualEntrepreneur: payload.individualEntrepreneur,

      ignorePaymentsWithoutPrintedReceipts: payload.ignorePaymentsWithoutPrintedReceipts,

      medicalOrganizationData: payload.individualEntrepreneur
        ? payload.individualEntrepreneurFullName
        : { name: payload.clinic.legalName },

      paymentsSumOneValue: payload.sumOne,
      paymentsSumTwoValue: payload.sumTwo,

      authenticatorData: {
        id: payload.employee.id.toString(),
        name: payload.employee.name,
        surname: payload.employee.surname,
        secondName: payload.employee.secondName,
      },

      payerAndPatientOnePersonValue: payload.taxpayerIsClient,

      documentsOwnersAttributes: getDocumentsOwnersAttributes(DOCUMENT_TYPES.CERTIFICATE_PAYMENT, [
        {
          ownerType: DOCUMENT_OWNER_TYPES.CLIENT,
          ownerId: payload.taxpayer.id,
        },
        {
          ownerType: DOCUMENT_OWNER_TYPES.PERSONAL_CHECKUP,
          ownerId: payload.client.id,
        },
      ]).filter(({ ownerId }) => ownerId),
    }
  },

  toClient (payload) {
    return {
      id: payload.id,

      period: DateHelpers.toBasePeriod([payload.dateStart, payload.dateEnd]),

      type: payload.kind,

      title: payload.title,

      clinic: {
        id: payload.clinicId,
        inn: payload.innValue,
        kpp: payload.kppValue,
        maskedKpp: Utils.applyMask(payload.kppValue, '## ## ## ###'),
        legalName: !payload.individualEntrepreneur
          ? payload.medicalOrganizationData.name
          : null,
      },

      ignorePaymentsWithoutPrintedReceipts: payload.ignorePaymentsWithoutPrintedReceipts,

      certificateNumber: payload.number,

      correctionNumber: payload.correctionNumber,
      originCorrectionNumber: payload.correctionNumber,

      reportingYear: payload.reportingYear,

      taxpayer: new TaxCertificate2024Client({
        id: payload.clientId,
        name: payload.payerData.name,
        secondName: payload.payerData.secondName ?? '',
        surname: payload.payerData.surname,
        shortName: vueFilters.shortName(payload.payerData),
        fullName: vueFilters.fullName(payload.payerData),
        inn: payload.payerData.inn,
        birthdate: payload.payerData.birthdate,
        phone: payload.payerData.phone ?? '',

        idCard: {
          fnsKind: payload.payerIdentityDocumentData.kind,
          number: payload.payerIdentityDocumentData.number,
          series: payload.payerIdentityDocumentData.series,
          issueDate: payload.payerIdentityDocumentData.issueDate,
        },
      }),

      client: payload.payerAndPatientOnePersonValue
        ? new TaxCertificate2024Client()
        : new TaxCertificate2024Client({
          id: payload.destinationClientId,
          name: payload.clientData.name,
          secondName: payload.clientData.secondName ?? '',
          surname: payload.clientData.surname,
          shortName: vueFilters.shortName(payload.clientData),
          fullName: vueFilters.fullName(payload.clientData),
          inn: payload.clientData.inn,
          birthdate: payload.clientData.birthdate,
          phone: payload.clientData.phone ?? '',

          idCard: {
            fnsKind: payload.clientIdentityDocumentData.kind,
            number: payload.clientIdentityDocumentData.number,
            series: payload.clientIdentityDocumentData.series,
            issueDate: payload.clientIdentityDocumentData.issueDate,
          },
        }),

      individualEntrepreneur: payload.individualEntrepreneur,

      individualEntrepreneurFullName: {
        surname: payload.medicalOrganizationData.surname,
        name: payload.medicalOrganizationData.name,
        secondName: payload.medicalOrganizationData.secondName ?? '',
      },

      sumOne: Utils.toMoney(payload.paymentsSumOneValue),

      sumTwo: Utils.toMoney(payload.paymentsSumTwoValue),

      employee: {
        id: +payload.authenticatorData.id,
        name: payload.authenticatorData.name,
        surname: payload.authenticatorData.surname,
        secondName: payload.authenticatorData.secondName,
        shortName: vueFilters.shortName(payload.authenticatorData),
      },

      taxpayerIsClient: payload.payerAndPatientOnePersonValue,

      dateOfSignatureByEmployee: payload.createdAt,

      xmlGenerated: payload.xmlGenerated,
    }
  },

  directorAdapter (director) {
    return {
      ...director,
      fullName: vueFilters.fullName(director),
      shortName: vueFilters.shortName(director),
    }
  },
}

const getIdentityDocumentData = (client) => {
  if (client.inn) { return }

  return {
    kind: client.fnsIdCard?.id,
    number: client.idCard.number,
    series: client.idCard.series,
    issueDate: client.idCard.issueDate,
  }
}
