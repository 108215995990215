import { IClientAdapter, TAdapter } from '@/_api/decorators/api/interfaces/IAdapter'
import { IResponse } from '@/_api/decorators/api/interfaces/IResponse'
import { get } from 'lodash'

export function ClientAdapter<Data = unknown, AdaptedData = unknown> (adapter: IClientAdapter<Data, AdaptedData> | TAdapter<Data, AdaptedData> | string) {
  return function<This, Args extends any[], Return> (
    target: (this: This, ...args: Args) => Return,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    context: ClassMethodDecoratorContext<This, (this: This, ...args: Args) => Return>
  ) {
    return async function (this: This, ...args: Args): Promise<Awaited<Return>> {
      const result = await target.call(this, ...args)

      if (typeof result !== 'object' || (result as IResponse).errors) {
        return result as Awaited<Return>
      }

      return (
        typeof adapter === 'function'
          ? adapter(result as Data)
          : typeof adapter === 'string'
            ? get(this, adapter)(result as Data)
            : adapter.toClient(result as Data)
      ) as Awaited<Return>
    }
  }
}
