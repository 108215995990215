import { omit } from 'lodash'
import { snakeToCamel } from '../_requests/helpers'

export const clinicAdapter = {
  toServer (clinic) {
    return {
      clinic: {
        ...omit(clinic, 'taxRate'),
        taxScheme: clinic.taxRate,
      },
    }
  },

  toClient (clinic) {
    const camelizedClinic = snakeToCamel(clinic)

    return {
      ...omit(camelizedClinic, 'taxScheme'),
      taxRate: camelizedClinic.taxScheme,
    }
  },
}
