import { MPresenterBase } from '@/_api/_requests/MPresenterBase'
import { camelToSnake } from '@/_api/_requests/helpers'
import { userListAdapter } from '@/_api/MUser/userListAdapter'

export class MUserPresenter extends MPresenterBase {
  constructor () {
    super({
      routes: { list: Routes.api_internal_selectors_users_path, one () {}, all () {} },
      entity: 'user',
      location: 'MUserPresenter',
    })
  }

  list (data, config = {}) {
    return super.list(data, {
      toServerAdapter: (data) => camelToSnake(userListAdapter.toServer(data)),
      toJson: true,
      ...config,
    })
      .then(config?.toClientAdapter || userListAdapter.toClient)
  }
}
