import { DIAGNOSE_ACTIONS as actions } from '../actions/entityActions'
import { findById, getNewEntity, sort } from './helpers'

const diagnoseReducer = (state = [], { type, payload }) => {
  let newState = [...state]
  let item = {}
  if (payload && payload.id) {
    item = findById(newState, payload.id)
  }

  switch (type) {
    case actions.LOAD: {
      return payload || newState
    }

    case actions.UPDATE: {
      item.value = payload.data

      return newState
    }

    case actions.RESTORE: {
      newState.push(payload.item)
      newState = sort(newState)

      return newState
    }

    case actions.ADD: {
      const newEntity = getNewEntity(newState, payload)
      newEntity.item = payload.data.item
      //from merge
      if (payload.data && payload.data.data) {
        newEntity.item = payload.data.data.item
      }
      newState.push(newEntity)

      return newState
    }

    case actions.DELETE: {
      newState = [...state].filter(
        (item) => item.id !== parseInt(payload)
      )

      return newState
    }

    default: return state
  }
}

export default diagnoseReducer
