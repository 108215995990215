<template>
  <m-modal
    :class="classCss"
    :visible="visible"
    :dialog-title="dialogTitle"
    :modal-title-icon="modalTitleIcon"
    width="max-content"
    @close="onClose"
  >
    <template #body>
      <slot
        name="body"
        :on-delete="onDelete"
      />

      <m-si-generator
        :items="items"
        :si-generator-schema="msiSchema"
        @onItemDelete="onDelete"
      />
    </template>

    <template #footer-right-action>
      <m-button
        template="save"
        :disabled="loading"
        @click="onSave"
      />
    </template>
  </m-modal>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import MModal from '@/vue_present/_base/MModal/MModal.vue'
import { PersonalCheckupApi } from '@/vue_apps/CheckupsModule/classes/PersonalCheckup/PersonalCheckupApi'
import { MConfirm } from '@/vue_present/_base/MConfirm/MConfirm'
import { CheckupsPropsMixin } from '@/vue_apps/CheckupsModule/mixins/CheckupsPropsMixin'
import MButton from '@/vue_present/_base/buttons/MButton/MButton.vue'
import MSiGenerator from '@/vue_present/_base/Tables/MSiGenerator/MSiGenerator.vue'
import { SpinnerHolder } from '@/vue_components/mixins/spinner_holder'

export default defineComponent({
  name: 'PersonalCheckupNestedItemsModalBase',

  components: { MSiGenerator, MButton, MModal },

  mixins: [
    CheckupsPropsMixin,
    SpinnerHolder,
  ],

  props: {
    classCss: { type: String, required: true },
    dialogTitle: { type: String, required: true },
    modalTitleIcon: { type: String, required: true },

    visible: Boolean,

    personalCheckup: PersonalCheckupApi,

    nestedItemsPropsName: { type: String, required: true },

    msiSchema: { type: Object, required: true },
  },

  emits: ['close'],

  computed: {
    items () {
      return this.personalCheckup[this.nestedItemsPropsName].getArr()
    },
  },

  methods: {
    onDelete ({ id }) {
      this.personalCheckup[this.nestedItemsPropsName].destroy(id)
    },

    async onSave () {
      if (this.isNewMode) {
        this.personalCheckup[this.nestedItemsPropsName].setKeep()

        return this.afterClose()
      }

      const errors = await this.withSpinnerSimple(
        this.personalCheckup[this.nestedItemsPropsName].submit()
      )

      if (errors) { return }

      this.afterClose()
    },

    async onClose () {
      if (this.personalCheckup[this.nestedItemsPropsName].getUnsaved().length) {
        const { cancel } = await MConfirm.warning(t('closeWithoutSaving?'))

        if (cancel) { return }

        this.personalCheckup[this.nestedItemsPropsName].removeUnsaved()
      }

      this.afterClose()
    },

    afterClose () {
      if (this.personalCheckup[this.nestedItemsPropsName].clear) {
        this.personalCheckup[this.nestedItemsPropsName].clear()
      }

      this.$emit('close')
    },
  },
})
</script>
