import { tScoped } from '@/helpers/i18nHelpers'

const ts = tScoped('finance.errors')

export enum ACQUIRING_ERROR {
    NO_WORKPLACE_ID_IS_SPECIFIED = 'no workplace_id is specified',
    ACQUIRING_MODULE_MUST_BE_ENABLED = 'acquiring module must be enabled',
    INTERNAL_ERROR = 'internal error',
    RECEIVE_MESSAGE_TIMEOUT = 'receive message timeout',
    OPERATION_IS_PROHIBITED = 'operation is prohibited',
    REQUIRED_STATUS_IS_EXECUTION_ERROR = 'required status is execution_error',
}

const ACQUIRING_ERROR_MESSAGES = {
  [ACQUIRING_ERROR.NO_WORKPLACE_ID_IS_SPECIFIED]: ts('noWorkplaceIdIsSpecified'),
  [ACQUIRING_ERROR.ACQUIRING_MODULE_MUST_BE_ENABLED]: ts('acquiringModuleMustBeEnabled'),
  [ACQUIRING_ERROR.INTERNAL_ERROR]: ts('workplaceInternalError'),
  [ACQUIRING_ERROR.RECEIVE_MESSAGE_TIMEOUT]: ts('receiveMessageTimeout'),
  [ACQUIRING_ERROR.OPERATION_IS_PROHIBITED]: ts('operationIsProhibited'),
  [ACQUIRING_ERROR.REQUIRED_STATUS_IS_EXECUTION_ERROR]: ts('requiredStatusIsExecutionError'),
}

export const getAcquiringError = (key: string) => ACQUIRING_ERROR_MESSAGES[key] || key
