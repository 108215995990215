export enum ACQUIRING_STATUS_CODE {
  INIT = 0,
  SUCCESS = 1,
  FAILURE = 2,
  IN_PROGRESS = 3,
  EXECUTION_ERROR = 4
}

export enum ACQUIRING_STATUS {
  INIT = 'init',
  SUCCESS = 'success',
  FAILURE = 'failure',
  IN_PROGRESS = 'in_progress',
  EXECUTION_ERROR = 'execution_error'
}

export const ACQUIRING_STATUS_CODE_TO_TITLE_MAP = {
  [ACQUIRING_STATUS_CODE.INIT]: ACQUIRING_STATUS.INIT,
  [ACQUIRING_STATUS_CODE.SUCCESS]: ACQUIRING_STATUS.SUCCESS,
  [ACQUIRING_STATUS_CODE.FAILURE]: ACQUIRING_STATUS.FAILURE,
  [ACQUIRING_STATUS_CODE.IN_PROGRESS]: ACQUIRING_STATUS.IN_PROGRESS,
  [ACQUIRING_STATUS_CODE.EXECUTION_ERROR]: ACQUIRING_STATUS.EXECUTION_ERROR,
} as const
