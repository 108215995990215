import { Api } from '@/_api/decorators/api/Api'
import { ICRUDReturn } from '@/_api/decorators/api/interfaces/ICRUDReturn'
import { IAcquiringWSMeta } from '@/vue_apps/FinanceModule/FinanceModuleIndex/interfaces/Acquiring/IAcquiringWSMeta'
import { IAcquiringErrors } from '@/vue_apps/FinanceModule/FinanceModuleIndex/interfaces/Acquiring/IAcquiringErrors'
import { IWSMessage } from '@/_declarations/IWSMessage'
import { snakeToCamel } from '@/_api/_requests/helpers'
import { TAcquiringWSData } from '@/vue_apps/FinanceModule/FinanceModuleIndex/interfaces/Acquiring/IAcquiringWSData'
import { ACQUIRING_ACTION } from '@/vue_apps/FinanceModule/FinanceModuleIndex/consts/Acquiring/AcquiringAction'
import { FiscalPrinterModuleService } from '@/services/FiscalPrinterModuleService'
import {
  getAcquiringReconciliationOfResultsStatus,
} from '@/vue_apps/catalogs_root/Workplaces/consts/getAcquiringReconciliationOfResultsStatus'
import { FISCAL_PRINTER_STATUSES } from '@/vue_apps/catalogs_root/Workplaces/consts/fiscalPrinterStatuses'
import {
  IAcquiringCheckingConnection,
} from '@/vue_apps/FinanceModule/FinanceModuleIndex/interfaces/Acquiring/IAcquiringCheckingConnection'
import {
  IAcquiringTerminalInfo,
} from '@/vue_apps/FinanceModule/FinanceModuleIndex/interfaces/Acquiring/IAcquiringTerminalInfo'
import { store } from '@/vue_components/store/store'
import {
  IAcquiringTerminalReconciliationOfResults,
} from '@/vue_apps/catalogs_root/Workplaces/interfaces/Acquiring/IAcquiringReconciliationOfResults'
import { ACQUIRING_STATUS_CODE } from '@/vue_apps/FinanceModule/FinanceModuleIndex/consts/Acquiring/AcquiringStatus'

export class TradeAcquiringService {
  static isFiscalModuleDisabledWithActiveAcquiring () {
    const acquiringModuleState = store.getters['weStore/vxGetAcquiringModuleState'] && gon.application.trade_acquiring_module_enabled
    const fiscalPrinterModuleState = store.getters['weStore/vxGetFiscalPrinterModuleState'] && gon.application.workplaces_module_enabled

    return !fiscalPrinterModuleState && acquiringModuleState
  }

  @Api.post(Routes.api_internal_acquiring_trade_operations_terminal_info_path)
  fetchTerminalInfo () {
    return {
      payload: {
        workplaceId: gon.application.current_user.workplace_id,
      },
    } as ICRUDReturn as Promise<IAcquiringWSMeta | IAcquiringErrors>
  }

  subscribe () {
    Services.wsSubscriptions.tradeAcquiring.connect((payload: IWSMessage<object, IAcquiringWSMeta>) => {
      const {
        action,
        data,
      } = snakeToCamel(payload) as IWSMessage<TAcquiringWSData, ACQUIRING_ACTION, IAcquiringWSMeta>

      if ('errors' in data || !TradeAcquiringService.isFiscalModuleDisabledWithActiveAcquiring()) { return }

      if ([ACQUIRING_ACTION.TERMINAL_INFO, ACQUIRING_ACTION.CHECKING_CONNECTION].includes(action)) {
        return this.updateFiscalPrinterIcon(data as IAcquiringCheckingConnection | IAcquiringTerminalInfo)
      }

      if (action === ACQUIRING_ACTION.RECONCILIATION_OF_RESULTS) {
        return this.reconciliationOfResultsHandler(data as IAcquiringTerminalReconciliationOfResults)
      }
    })
  }

  private updateFiscalPrinterIcon ({ lastReconciliationOfResults }: IAcquiringCheckingConnection | IAcquiringTerminalInfo) {
    if (!lastReconciliationOfResults) {
      return FiscalPrinterModuleService.updateIcon(null, true)
    }

    const isOver24H = getAcquiringReconciliationOfResultsStatus(lastReconciliationOfResults) === FISCAL_PRINTER_STATUSES.IS_OVER_24H
    FiscalPrinterModuleService.updateIcon(isOver24H, true)
  }

  private reconciliationOfResultsHandler (data: IAcquiringTerminalReconciliationOfResults) {
    const isClosed = data.status === ACQUIRING_STATUS_CODE.SUCCESS
    FiscalPrinterModuleService.updateIcon(!isClosed, true)
  }
}
