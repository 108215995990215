<template>
  <m-form-show class="payment-order">
    <!-- Номер счета -->
    <m-form-show-row
      :label="t('order_number')"
      :value="payment.destinationOrder.formattedId"
    />

    <!-- Лицевой счет плательщика -->
    <m-form-show-row
      class="hidden-print"
      :label="t('payer_account')"
    >
      <template #value>
        <span class="success bold">{{ $filters.currency(payment.payer.finance) }}</span>
      </template>
    </m-form-show-row>

    <!-- Комментарий -->
    <m-form-show-row
      v-if="payment.paymentsWithComments.length"
      class="hidden-print payment-comment"
      :label="t('comment')"
    >
      <template #value>
        <ul>
          <template v-for="paymentWithComment in payment.paymentsWithComments">
            <li :key="`createdAt-${paymentWithComment.id}`">
              {{ paymentWithComment.createdAt }}
            </li>

            <li
              :key="`deposit-${paymentWithComment.id}`"
              class="no_debt"
            >
              {{ paymentWithComment.deposit }}
            </li>

            <li
              :key="`comment-${paymentWithComment.id}`"
              class="payment-comment-text"
            >
              {{ paymentWithComment.comment }}
            </li>
          </template>
        </ul>
      </template>
    </m-form-show-row>

    <!-- Кредитная задолженность -->
    <m-form-show-row :label="t('credit_debt')">
      <template #value>
        <span
          v-if="payerCompanyCompanyFinance"
          :class="creditDebtCssClass"
        >
          {{ $filters.currency(payment.payerCompany.companyFinance.creditDebt) }}
        </span>

        <span
          v-else-if="payerClientDebt"
          :class="creditDebtCssClass"
        >
          {{ $filters.currency(payerClientDebt) }}
        </span>

        <span v-else>{{ $filters.currency(0) }}</span>
      </template>
    </m-form-show-row>

    <!-- Врач -->
    <m-form-show-row
      :label="t('doctor')"
      :value="users"
    />

    <!-- Ассистент -->
    <m-form-show-row
      v-if="payment.destinationOrder.assistants.length"
      :label="t('assistant')"
    >
      <template #value>
        <div class="payment-order__assistant-list">
          <m-link
            v-for="assistant in payment.destinationOrder.assistants"
            :key="assistant.id"
            :class="{ 'deleted': assistant.deletedAt }"
            :text="assistant.fullName"
            :href="!isRefund && assistant.link"
          />
        </div>
      </template>
    </m-form-show-row>

    <!-- Создан -->
    <m-form-show-row
      :label="t('created_by')"
      :value="payment.destinationOrder.createdBy"
    />
  </m-form-show>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import MFormShow from '@/vue_present/_base/MFormShow/MFormShow.vue'
import MFormShowRow from '@/vue_present/_base/MFormShow/MFormShowRow.vue'
import { PaymentApi } from '@/vue_apps/FinanceModule/FinanceModuleIndex/classes/Payment/PaymentApi'
import { usersAttributeToString } from '@/vue_apps/FinanceModule/FinanceModuleIndex/consts/usersAttributeToString'
import MLink from '@/vue_present/_base/MLink/MLink.vue'
import { PAYMENT_BASE_KIND } from '@/vue_apps/FinanceModule/FinanceModuleIndex/consts/paymentBaseKind'

export default defineComponent({
  name: 'PaymentOrder',

  components: {
    MLink,
    MFormShowRow,
    MFormShow,
  },

  props: {
    payment: PaymentApi,
  },

  computed: {
    payerClientDebt () {
      return this.payment.payerClient?.creditDebt
    },

    payerCompanyCompanyFinance () {
      return this.payment.payerCompany?.companyFinance
    },

    creditDebtCssClass () {
      if (this.payerCompanyCompanyFinance) {
        return this.payerCompanyCompanyFinance?.creditDebt
          ? 'debt credit-debt-val'
          : 'credit-debt-val'
      }

      return this.payerClientDebt
        ? 'debt credit-debt-val'
        : 'credit-debt-val'
    },

    users () {
      return usersAttributeToString(this.payment.destinationOrder.users)
    },

    assistants () {
      return usersAttributeToString(this.payment.destinationOrder.assistants)
    },

    isRefund () {
      return this.payment.distributor.baseKind === PAYMENT_BASE_KIND.REFUND
    },
  },

  created () {
    this.payment.fetchPaymentsWithComments()
  },
})
</script>
