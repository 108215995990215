<template>
  <m-panel
    v-if="type === 'panel'"
    :title="t('semds.semd')"
    class="semd-modal-wrapper hidden-print-fixed"
  >
    <m-si-generator
      class="mb-indent-validation"
      :items="semdList.data"
      :si-generator-schema="semdSchema"
      :page-count="semdList.totalPages"
      :current-page="semdList.currentPage"
      @update:currentPage="fetchPage($event)"
      @onItemClick="onOpenSemd({ id: $event.id })"
    >
      <template #add>
        <semd-modal-app
          class="inline-block"
          :is-semds-empty="isSemdsEmpty"
          :is-last-semd-outdated="isLastSemdOutdated"
          :is-new-source="!source.id"
          :is-source-changed="isSourceChanged"
          :has-semd-type="hasSemdType"
          :append-to-body="appendToBody"
          @registerOpenSemd="openSemd = $event"
          @updateList="onUpdateList"
          @submitClick="onSemdSubmitClick()"
        />
      </template>
    </m-si-generator>

    <slot
      name="source-connector"
      :semds="semds"
    />
  </m-panel>
  <div
    v-else
    class="semd-modal-wrapper semd-modal-wrapper_button"
  >
    <semd-modal-app
      class="inline-block semd-modal-wrapper__open-modal"
      :is-semds-empty="isSemdsEmpty"
      :is-last-semd-outdated="isLastSemdOutdated"
      :is-new-source="!source.id"
      :is-source-changed="isSourceChanged"
      :has-semd-type="hasSemdType"
      :append-to-body="appendToBody"
      @registerOpenSemd="openSemd = $event"
      @updateList="onUpdateList"
      @submitClick="onSemdSubmitClick()"
    />
    <m-drop-down
      v-if="source.id && semdList.data?.length"
      placement="top-start"
      :items="semdList.data"
      :use-search="false"
      class="semd-modal-wrapper__semds-dropdown"
      @itemClick="onOpenSemd($event)"
    >
      <m-button
        v-tooltip="lastSemd.state.tooltip"
        :type="lastSemd.state.css"
        :icon="lastSemd.state.value"
      />
      <template #dropdownItem="{ item }">
        <div class="flex align-center">
          <m-icon
            v-tooltip="item.state.tooltip"
            :icon="item.state.value"
            :color="item.state.css"
          />
          {{ item.title.value }}
        </div>
      </template>
    </m-drop-down>

    <slot
      name="source-connector"
      :semds="semds"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import SemdModalApp from '@/vue_apps/Semds/SemdModal/SemdModalApp.vue'
import MSiGenerator from '@/vue_present/_base/Tables/MSiGenerator/MSiGenerator.vue'
import MPanel from '@/vue_present/_base/MPanel/MPanel.vue'
import { MListService } from '@/_api/_requests/MListService'
import { MSemdPresenter } from '@/_api/MSemdApi/MSemdPresenter'
import { SemdListItem } from '@/vue_apps/Semds/entities/list/SemdListItem'
import { getSemdListSchema } from '@/vue_apps/Semds/entities/list/getSemdListSchema'
import { callAfterTimeout } from '@/helpers/ElementUI/callAfterTimeout'
import { ISemdSource } from '@/vue_apps/Semds/declarations/ISemdSource'
import { TMSiGeneratorSchema } from '@/vue_present/_base/Tables/MSiGenerator/MSiGeneratorTypes'
import MDropDown from '@/vue_present/_base/MDropDown/MDropDown.vue'
import MButton from '@/vue_present/_base/buttons/MButton/MButton.vue'
import MIcon from '@/vue_present/_base/MIcon/MIcon.vue'
import { ESemdStates } from '@/vue_apps/Semds/declarations/ESemdStates'
import { IWSMessage } from '@/_declarations/IWSMessage'

export declare interface ISemdModalWrapperApi {
  updateList: () => Promise<void>
}

export default defineComponent({
  name: 'SemdModalWrapper',

  components: {
    MIcon,
    MButton,
    MDropDown,
    MPanel,
    MSiGenerator,
    SemdModalApp,
  },

  props: {
    source: { type: Object as PropType<ISemdSource>, required: true },
    beforeOpenSemd: {
      type: Function as PropType< () => Promise<{ cancel: boolean }> >,
      default: null,
    },
    isSourceChanged: { type: Boolean, required: true },
    type: { type: String as PropType<'panel' | 'button'>, default: 'panel' },

    appendToBody: Boolean,
  },

  emits: [
    'registerApi',
  ],

  data () {
    return {
      componentId: `SemdModalWrapper:${Utils.newGUID()}`,

      /* eslint-disable */
      /**
       * @param {number} [id]
       * @param {PersonalElmkMedicalAssessmentApi} [source]
       * @param {number} [lastSemdId]
       */
      async openSemd ({ id, source, lastSemdId }) {},
      /* eslint-enable */

      semdList: new MListService(
        { ownerType: this.source.sourceType, ownerId: this.source.id },
        new MSemdPresenter(),
        { listItemEntity: SemdListItem }
      ),

      semdSchema: getSemdListSchema() as TMSiGeneratorSchema,
    }
  },

  computed: {
    hasSemdType () {
      return Boolean(this.source?.semdType)
    },

    isSemdsEmpty () {
      return !this.semdList.data.length
    },

    lastSemd (): SemdListItem {
      return this.semdList.data[0] || null
    },

    isLastSemdOutdated () {
      if (!this.lastSemd) { return false }

      return this.lastSemd.isOutdatedVersion()
    },

    semds (): SemdListItem[] {
      return this.semdList.data
    },
  },

  created () {
    const vm = this

    const api: ISemdModalWrapperApi = {
      updateList: () => vm.fetchPage(),
    }

    this.$emit('registerApi', api)

    callAfterTimeout(() => {
      this.fetchPage()
    })

    this.$pubSub.subscribe(`broadcast:${location.pathname}:updateSemdsList`, (componentId) => {
      if (componentId === this.componentId) { return }
      this.fetchPage()
    })

    Services.wsSubscriptions.egisz.connect(({ action, data }: IWSMessage<{ id: number }, ESemdStates>) => {
      if (!('id' in data)) { return }
      const semd: SemdListItem = this.semds.find((item) => item.id === data.id)
      if (!semd) { return }
      semd.setState(action)
    })
  },

  beforeDestroy () {
    this.$pubSub.unsubscribeAll(`broadcast:${location.pathname}:updateSemdsList`)
  },

  methods: {
    fetchPage (page = 1) {
      this.semdList.setFilterValue('ownerId', this.source.id)
      if (!this.source.id) { return }
      this.semdList.fetchPage(page)
    },

    onUpdateList () {
      this.fetchPage()
      this.$pubSub.emit(`broadcast:${location.pathname}:updateSemdsList`, this.componentId, true)
    },

    async onOpenSemd (params) {
      if (!this.$security.canViewEgisz) { return }

      await this.openSemd({
        ...params,
        source: this.source,
      })
    },

    async onSemdSubmitClick (id = this.lastSemd?.id || null) {
      if (this.beforeOpenSemd) {
        const { cancel } = await this.beforeOpenSemd() || {}
        if (cancel) { return }
      }

      const lastSemdId = (this.lastSemd?.isOutdatedVersion() && this.lastSemd.id) || undefined

      await this.onOpenSemd({ id, lastSemdId })
    },
  },
})
</script>
