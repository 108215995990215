import { set } from 'lodash/object'

export class Base<
    SetValueKeyType extends string | number | symbol = string,
    // @ts-ignore TS2526: A "this" type is available only in a non-static member of a class or interface
    ReturnThisClass = this
> {
  setValue (key: SetValueKeyType, value: unknown) {
    if (!key) { return }
    if (typeof key !== 'string') { return }
    if (key.includes('.')) {
      set(this, key, value)

      return
    }

    this[key as string] = value
  }

  setValues (values: Partial<Record<SetValueKeyType, unknown>>) {
    Object.entries(values).forEach(([key, value]) => {
      this.setValue(key as SetValueKeyType, value)
    })

    return this as unknown as ReturnThisClass
  }
}
