
export enum EMeasureUnitKinds {
  piece = 'piece',
  gram = 'gram',
  kilogram = 'kilogram',
  ton = 'ton',
  centimeter = 'centimeter',
  decimeter = 'decimeter',
  meter = 'meter',
  squareCentimeter = 'square_centimeter',
  squareDecimeter = 'square_decimeter',
  squareMeter = 'square_meter',
  milliliter = 'milliliter',
  liter = 'liter',
  cubicMeter = 'cubic_meter',
  kilowattHour = 'kilowatt_hour',
  gKal = 'gkal',
  day = 'day',
  hour = 'hour',
  minute = 'minute',
  seconds = 'seconds',
  kilobyte = 'kilobyte',
  megabyte = 'megabyte',
  gigabyte = 'gigabyte',
  terabyte = 'terabyte',
  other = 'other'
}
