import { ProtocolModel } from '@/vue_apps/ProtocolsApp/entities/ProtocolModel'
import { cloneDeep } from 'lodash'
import { SemdsUtils } from '@/vue_apps/Semds/entities/SemdsUtils'
import { ITemplateRecordsTreeItem } from '@/vue_present/Reuse/ProtocolsTree/ITemplateRecordsTreeItem'
import { IProtocolTemplateDataObject } from '@/vue_apps/ProtocolsApp/declarations/IProtocolTemplateDataObject'

export class Protocol<DocumentsDetails> extends ProtocolModel<DocumentsDetails> {
  storeState: IProtocolTemplateDataObject = {} as IProtocolTemplateDataObject

  semdTypeChangingProhibited = false

  constructor (props) {
    super(props)

    this.fillSemdData()
  }

  fillProps (props) {
    this._fillProps(props)
    this.setValue('clientId', props.client?.id)
  }

  buildStoreState () {
    try {
      this.storeState = JSON.parse(this.templateData)
    } catch (e) {
      this.storeState = {} as IProtocolTemplateDataObject
    }
  }

  setValue (key, value) {
    if (key === 'storeState') {
      this.storeState = value

      return
    }

    return super.setValue(key, value)
  }

  fillPrintingTools ({ entryInfo, entryTitle, clinicHeader, doctorSignature }) {
    this.setValue('printEntryInfo', entryInfo)
    this.setValue('printEntryTitle', entryTitle)
    this.setValue('printClinicHeader', clinicHeader)
    this.setValue('printDoctorSignature', doctorSignature)
  }

  getPrintingTools () {
    return {
      entryInfo: this.printEntryInfo,
      entryTitle: this.printEntryTitle,
      doctorSignature: this.printDoctorSignature,
      clinicHeader: this.printClinicHeader,
    }
  }

  fillByTemplate (templateRecord: ITemplateRecordsTreeItem) {
    this.fillPrintingTools({
      entryInfo: templateRecord.printEntryInfo,
      entryTitle: templateRecord.printEntryTitle,
      doctorSignature: templateRecord.printDoctorSignature,
      clinicHeader: templateRecord.printClinicHeader,
    })

    this.setValue('title', templateRecord.title)
    this.setValue('templateData', templateRecord.templateData)
    this.setValue('templateHtml', templateRecord.templateHtml)

    this.buildStoreState()

    if (templateRecord.semdTemplateDocumentDetailAttributes) {
      this.setValue(
        'semdType',
        SemdsUtils.extractSemdType(templateRecord.semdTemplateDocumentDetailAttributes.semdType)
      )
      this.semdTypeChangingProhibited = true
    }
  }

  fillSemdData (semdsDocumentDetailsAttributesData = {}) {
    const attributes = {
      id: this.semdsDocumentDetailsAttributes?.id,
      clinicId: this.clinic?.id,
      displayInWidgetsPersonalAccount: this.onlineAccess,
      data: cloneDeep(semdsDocumentDetailsAttributesData),
    }

    this.setValue(
      'semdsDocumentDetailsAttributes',
      attributes
    )
  }
}
