import { CatalogHelpers } from '@/helpers/CatalogHelpers'

export enum CLINICAL_GUIDELINE_AGE {
  ADULTS = 'adults',
  CHILDREN = 'children',
  BOTH = 'both'
}

export const CLINICAL_GUIDELINE_AGE_ITEMS = CatalogHelpers.getCatalogItemsFromEnum<CLINICAL_GUIDELINE_AGE>(
  CLINICAL_GUIDELINE_AGE,
  'clinicalGuidelines.age'
)
