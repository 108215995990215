import { CALCULATE_ACTIONS as actions } from '../actions/entityActions'
import { checkVariables, findById, getNewEntity, sort } from './helpers'

const calclulateRecucer = (state = [], { type, payload }) => {
  let newState = [...state]
  let item = false
  if (payload && payload.id) {
    item = findById(newState, payload.id)
  }

  switch (type) {
    case actions.LOAD:
      return payload || newState

    case actions.ADD : {
      const newEntity = getNewEntity(newState, payload)
      if (payload.data && payload.data.data) {
        newEntity.value = payload.data.data.value
      }
      newState.push(newEntity)

      return newState
    }

    case actions.DELETE :
      newState = [...state].filter(
        (item) => parseInt(item.id) !== parseInt(payload)
      )

      return newState

    case actions.RESTORE:
      newState.push(payload.item)
      newState = sort(newState)

      return newState

    case actions.UPDATES_DATA:
      newState.forEach((item, i) => {
        const value = item.value
        const mathData = checkVariables(value, [...payload], item, newState)
        if (mathData || mathData === 0) {
          newState[i].data = mathData
        }
      })

      return newState

    case actions.UPDATE:
      item.value = payload.value

      return newState

    default:
      return state
  }
}
export default calclulateRecucer
