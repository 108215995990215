import { ItemBase } from '@/_api/_classes/ItemBase'
import {
  PrintRawReceiptEntriesItem,
} from '@/vue_apps/catalogs_root/Workplaces/classes/PrintRawPeceipt/PrintRawReceiptEntriesItem'
import { IPrintRawReceiptInfo } from '@/vue_apps/catalogs_root/Workplaces/interfaces/PrintRawReceipt/IPrintRawReceiptInfo'
import { ICatalog } from '@/_declarations/ICatalog'
import { NestedItemsItem } from '@/_api/_classes/NestedItems/classes/NestedItemsItem'
import {
  IPrintRawReceiptEntriesItem,
} from '@/vue_apps/catalogs_root/Workplaces/interfaces/PrintRawReceipt/IPrintRawReceiptEntriesItem'
import {
  IPrintRawReceiptPaymentItem,
} from '@/vue_apps/catalogs_root/Workplaces/interfaces/PrintRawReceipt/IPrintRawReceiptPaymentItem'
import {
  PrintRawReceiptPaymentItem,
} from '@/vue_apps/catalogs_root/Workplaces/classes/PrintRawPeceipt/PrintRawReceiptPaymentItem'

export class PrintRawReceiptModel extends ItemBase {
  loading = false

  kind: ICatalog<string> = null

  entries =
    new NestedItemsItem<PrintRawReceiptEntriesItem, IPrintRawReceiptEntriesItem>(PrintRawReceiptEntriesItem)

  payments =
    new NestedItemsItem<PrintRawReceiptPaymentItem, IPrintRawReceiptPaymentItem>(PrintRawReceiptPaymentItem)

  receiptInfo: IPrintRawReceiptInfo = {
    correctionTypes: [],
    entryKinds: [],
    paymentKinds: [],
    receiptKinds: [],
  }

  receiptInfoLoaded = false
}
