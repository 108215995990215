import { ItemBase } from '@/_api/_classes/ItemBase'
import {
  ITemplateRecordSemdTemplateDocumentDetail,
} from '@/vue_apps/catalogs_root/TemplateRecords/interface/ITemplateRecordSemdTemplateDocumentDetail'

const _permitType = <const> [
  'id',
  'title',
  'templateData',
  'templateHtml',
  'categoryId',
  'printEntryInfo',
  'printEntryTitle',
  'printClinicHeader',
  'printDoctorSignature',
  'semdTemplateDocumentDetailAttributes',
]

export class TemplateRecordModel extends ItemBase {
  _permit = Array.from(_permitType)

  id: number = null

  title: string = null

  templateData: string = null

  templateHtml: string = null

  categoryId: number = null

  printEntryInfo: boolean = null

  printEntryTitle: boolean = null

  printClinicHeader: boolean = null

  printDoctorSignature: boolean = null

  semdTemplateDocumentDetailAttributes: ITemplateRecordSemdTemplateDocumentDetail<unknown> = null

  constructor (props) {
    super()

    if (!props) { return }

    this._fillProps(props)
  }

  _fillProps (props: { [key: string | 'semdTemplateDocumentDetail']: any } = {}) {
    super._fillProps(props)
    this.semdTemplateDocumentDetailAttributes = props.semdTemplateDocumentDetail
  }

  setValue<Keys extends typeof _permitType[number]> (key: Keys | string, value: unknown) {
    super.setValue(key, value)
  }
}
