import { PAYMENT_KINDS } from '@/vue_apps/FinanceModule/FinanceModuleIndex/consts/paymentKinds'
import { PAYMENT_INCOME_FIELDS } from '@/vue_apps/FinanceModule/FinanceModuleIndex/consts/paymentIncomeFields'
import {
  PaymentDistributorLogicBase,
} from '@/vue_apps/FinanceModule/FinanceModuleIndex/classes/Payment/PaymentDistributorLogicBase'

export class PaymentDistributorRefundLogic extends PaymentDistributorLogicBase {
  setValue (key: string, value: unknown): void {
    super.setValue(key, value)

    switch (key) {
      case 'initialKind': return this.setValue('kind', value)
      case 'refundTo': return this.recalculate()
      case 'byCash': return this.byCashInput()
    }

    if (this.isInputField(key)) {
      this.recalculate()
    }
  }

  recalculate () {
    if (this.kind !== PAYMENT_KINDS.CASH_OUT) return

    PAYMENT_INCOME_FIELDS.forEach((key) => {
      super.setValue(key, 0)

      if (this.refundTo.id === key) {
        super.setValue(key, this.totalPaid)
      }
    })

    super.setValue('totalIncome', this.totalPaid)
  }

  private byCashInput () {
    this.resetTransfer()
    this.recalculate()
  }
}
