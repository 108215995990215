import Entity from './Entity'

class Variable extends Entity {
  edit () {
    this.container.get('variables').show(this)
  }

  /**
   * decorate base method
   */
  formatHtml () {
    const result = parseFloat(super.formatHtml().replace(',', '.'))
    if (isNaN(result)) {
      return false
    }

    return result
  }

  /**
   *
   */
  elementHead (showTypes) {
    let head = false
    if (showTypes) {
      head = this.data.title
    } else {
      head = this.data.name
    }
    this.element.innerHTML = head
  }
}
export default Variable
