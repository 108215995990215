import { IPersonalCheckup } from '@/vue_apps/CheckupsModule/interfaces/IPersonalCheckup'
import { INsiEntry } from '@/_declarations/INsiEntry'
import {
  PersonalCheckupResearchResultsItemListItem,
} from '@/vue_apps/CheckupsModule/classes/PersonalCheckupResearchResults/PersonalCheckupResearchResultsItemListItem'
import { NestedItemsApi } from '@/_api/_classes/NestedItems/classes/NestedItemsApi'
import {
  PersonalCheckupInstrumentalResearchResultsPresenter,
} from '@/vue_apps/CheckupsModule/api/PersonalCheckupInstrumentalResearchResultsPresenter/PersonalCheckupInstrumentalResearchResultsPresenter'
import {
  IPersonalCheckupResearchResultsItem,
} from '@/vue_apps/CheckupsModule/interfaces/PersonalCheckupResearchResults/IPersonalCheckupResearchResultsItem'

export class PersonalCheckupInstrumentalResearchResultsModel extends NestedItemsApi<
  PersonalCheckupResearchResultsItemListItem,
  IPersonalCheckupResearchResultsItem,
  PersonalCheckupInstrumentalResearchResultsPresenter
> {
  date: string = Utils.getBaseFormattedDate()

  research: INsiEntry = null

  medicalAssessment: INsiEntry = null

  researches: INsiEntry[] = []

  medicalAssessments: INsiEntry[] = []

  constructor (props?: Pick<IPersonalCheckup, 'id' | 'instrumentalResearchResults'>) {
    super({
      ownerId: props?.id,
      nestedItemsProps: props?.instrumentalResearchResults,
      nestedItemsPropName: 'instrumentalResearchResults',
      nestedItemsClass: PersonalCheckupResearchResultsItemListItem,
      presenter: new PersonalCheckupInstrumentalResearchResultsPresenter(),
    })
  }
}
