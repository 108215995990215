import { DIAGNOSES_CONSTRUCTOR_ACTIONS as actions } from '../actions/entityActions'
import { findById, getNewEntity, sort } from './helpers'

const diagnoseConstructorReducer = (state = [], { type, payload }) => {
  let newState = [...state]
  let item = {}
  if (payload && payload.id) {
    item = findById(newState, payload.id)
  }

  switch (type) {
    case actions.LOAD :
      return payload || newState

    case actions.UPDATE:
      item.value = payload.data

      return newState

    case actions.RESTORE :
      newState.push(payload.item)
      newState = sort(newState)

      return newState

    case actions.ADD : {
      const newEntity = getNewEntity(newState, payload)
      newEntity.item = payload.data.item
      //from merge
      if (payload.data && payload.data.category_ids) {
        newEntity.categories_ids = payload.data.category_ids
      }
      newState.push(newEntity)

      return newState
    }
    case actions.CHANGE :
      //return newState
      item.categories_ids = payload.categories

      return newState

    case actions.DELETE :
      newState = [...state].filter(
        (item) => item.id !== parseInt(payload)
      )

      return newState

    default:
      return state
  }
}

export default diagnoseConstructorReducer
