import { FISCAL_PRINTER_REPORT_TYPE } from '@/vue_apps/catalogs_root/Workplaces/consts/fiscalPrinterEvent'

export const FISCAL_PRINTER_KKM_RECEIPT_ITEMS = [
  {
    id: FISCAL_PRINTER_REPORT_TYPE.X_MAIN,
    title: t('x_report_main'),
  },
  {
    id: FISCAL_PRINTER_REPORT_TYPE.X_BY_SECTIONS,
    title: t('x_report_by_sections'),
  },
  {
    id: FISCAL_PRINTER_REPORT_TYPE.X_BY_HOURS,
    title: t('x_report_by_hours'),
  },
  {
    id: FISCAL_PRINTER_REPORT_TYPE.X_BY_CASHIERS,
    title: t('x_report_by_cashiers'),
  },
  {
    id: FISCAL_PRINTER_REPORT_TYPE.X_BY_AMOUNT,
    title: t('x_report_by_amount'),
  },
]
