<template>
  <m-select-lazy
    ref="select"
    :value="value"
    :fetch-method="fetchMethod()"
    :label="t('relatedEntries')"
    full-width
    multiple
    filterable
    use-first-time-fetch
    :placeholder="placeholder"
    @changeByLazy="$emit('change', $event)"
    @syncItems="onSyncItems"
  >
    <template #option="{ option }">
      <div class="m-option__container text-wrap">
        <m-icon
          v-if="option.complexId"
          v-tooltip="t('protocols.complexMember')"
          icon="product"
          color="gray"
        />
        <span class="bold">{{ option.date }}</span>: {{ option.title }}
      </div>
    </template>
  </m-select-lazy>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import MSelectLazy from '@/vue_present/_base/MSelectLazy/MSelectLazy.vue'
import { MEntryPresenter } from '@/vue_apps/MedicalRecords/MedicalRecordsTabs/components/EntriesTab/api/MEntryPresenter'
import { ICatalog } from '@/_declarations/ICatalog'
import { orUndefined } from '@/_medods_standart_library/msl'
import { GLOBAL_ENTRY_TYPES_KINDS } from '@/_global_scripts/GLOBAL_CONSTS'
import MIcon from '@/vue_present/_base/MIcon/MIcon.vue'
import { protocolEntriesAdapter } from '@/vue_apps/ProtocolsApp/api/protocolEntriesAdapter'

export default defineComponent({
  name: 'ProtocolEntries',

  components: { MIcon, MSelectLazy },

  props: {
    value: { type: Array as PropType<ICatalog[]>, default: () => [] },
    clientId: { type: Number, required: true },
    withoutConsumables: { type: Boolean, default: false },
  },

  data () {
    return {
      presenter: new MEntryPresenter(),
      placeholderText: t('enums.unselected.they'),
    }
  },

  computed: {
    placeholder () {
      return this.value?.length
        ? ''
        : this.placeholderText
    },
  },

  methods: {
    fetchMethod () {
      const kinds = this.withoutConsumables && [
        GLOBAL_ENTRY_TYPES_KINDS.ANALYSIS,
        GLOBAL_ENTRY_TYPES_KINDS.SERVICE,
        GLOBAL_ENTRY_TYPES_KINDS.COMPLEX,
      ]

      return (params) => this.presenter.list({
        ...params,
        clientId: this.clientId,
        kinds: orUndefined(kinds),
      }, {
        toClientAdapter: protocolEntriesAdapter.toClient,
      })
    },

    onSyncItems () {
      if (!this.$refs.select) { return }
      const totalItems = this.$refs.select.totalItems || 0
      const entriesText = totalItems
        ? `${t('available')} ${totalItems}`
        : t('protocols.noAvailableEntries')

      this.placeholderText =
          `${t('enums.unselected.they')}. ${entriesText}`
    },
  },
})

</script>
