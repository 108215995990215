import {
  TaxCertificate2024XmlModel,
} from '@/vue_apps/Documents/FixedDocuments/_documents/TaxCertificate2024/classes/TaxCertificate2024Xml/TaxCertificate2024XmlModel'
import {
  TaxCertificate2024Base,
} from '@/vue_apps/Documents/FixedDocuments/_documents/TaxCertificate2024/classes/TaxCertificate2024Base'
import { pick } from 'lodash'
import {
  IUserWithMcdGuid,
} from '@/vue_apps/Documents/FixedDocuments/_documents/TaxCertificate2024/declarations/IUserWithMcdGuid'

export class TaxCertificate2024XmlLogic extends TaxCertificate2024XmlModel {
  mcdRequired = true

  _signerLocked = false

  get signerLocked () {
    return this._signerLocked
  }

  setMcdRequired (
    value: boolean,
    taxCertificate2024: TaxCertificate2024Base,
    signer: IUserWithMcdGuid
  ) {
    this.mcdRequired = value

    this.unlockSigner()

    if (value) { return }

    if (taxCertificate2024.individualEntrepreneur) {
      this.lockSigner()
      this.setValues({ signer: null, mcdGuid: null })

      return
    }

    this.setValues({
      signer: pick(signer, ['id', 'shortName', 'mcdGuid']),
      mcdGuid: signer.mcdGuid,
    })
  }

  saveFileToClient (body: string, fileName: string) {
    Utils.downloadExportFile(body, fileName, 'application/xml')
  }

  protected unlockSigner () {
    this._signerLocked = false
  }

  protected lockSigner () {
    this._signerLocked = true
  }
}
