import { SemdEntity } from '@/vue_apps/ProtocolsApp/_SemdProtocolEditor/_SemdEntities/_semdBaseEntities/SemdEntity'
import { ISemdNsiDictionary } from '@/vue_apps/ProtocolsApp/_SemdProtocolEditor/interfaces/ISemdNsiDictionary'
import { ICatalog } from '@/_declarations/ICatalog'

export declare interface ISemdSelectProps {
  id: string
  title: string
  nsiDictionary: string
  nsiDictionaryVersion?: string
  isOptional?: boolean
  permittedValues?: string[]
  multiple?: boolean
  defaultValueTitle?: string
}

export class SemdNsiDictionary extends SemdEntity implements ISemdNsiDictionary {
  nsiDictionary: string

  nsiDictionaryVersion?: string

  permittedValues = undefined

  multiple = false

  constructor (props: ISemdSelectProps) {
    super(props)

    this.nsiDictionary = props.nsiDictionary
    this.nsiDictionaryVersion = props.nsiDictionaryVersion
    this.permittedValues = props.permittedValues
    this.multiple = props.multiple
  }

  drawElementValue (value: ICatalog | ICatalog[]) {
    this.element.innerHTML = Array.isArray(value)
      ? value.map(({ title }) => title).join(', ')
      : value?.title || ''
  }

  getContent (): ICatalog | ICatalog[] {
    return super.getContent() as ICatalog | ICatalog[]
  }
}
