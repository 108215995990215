import { omit } from 'lodash'
import { IEntryTypeIdsParams } from './interfaces'

export const taxRatesAdapter = {
  toServerByEntryTypes (data: IEntryTypeIdsParams) {
    return omit(data, 'entryTypeCategoryIds')
  },

  toServerByEntryTypeCategories (data: IEntryTypeIdsParams) {
    return {
      ...omit(data, 'entryTypeIds', 'entryTypeCategoryIds'),
      categoryIds: data.entryTypeCategoryIds,
    }
  },
}
