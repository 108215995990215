<template>
  <div class="flex company-search-create">
    <m-select-lazy
      v-tooltip="value && value.title"
      :value="value"
      :items="items"
      :fetch-method="(params) => fetchMethod({ ...params, companyType })"
      full-width
      :label="label"
      :placeholder="placeholder"
      :disabled="disabled"
      :required="required"
      :validator-name="validatorName"
      :additional-displayed-filter="_innFilter()"
      :use-first-time-fetch="useFirstTimeFetch"
      filterable
      @change="$emit('change', $event); $updateSync('value', $event)"
      @registerValidator="$registerValidator($event)"
    />

    <slot name="button-with-modal">
      <m-button
        v-tooltip="t('create_new_company')"
        class="ml-5"
        icon="add"
        type="success"
        :disabled="disabled"
        @click="visible = true"
      />

      <m-modal
        :visible.sync="visible"
        class="company-wrapper-wrapper"
        type="success"
      >
        <template #header>
          <span class="flex align-items-center">
            <m-icon icon="company" />
            <span>{{ t('create_new_company') }}</span>
          </span>
        </template>

        <template #body>
          <company-wrapper
            v-if="visible"
            :company-type="companyType"
            @registerValidator="onRegisterValidator"
            @saveDataCallback="saveCompanyDataCallback = $event"
            @companyTabsCallback="setCompanyTab = $event"
          />
        </template>

        <template #footer-right-action>
          <m-button
            template="save"
            :disabled="loading"
            @click="createCompany"
          />
        </template>
      </m-modal>
    </slot>
  </div>
</template>

<script>
import MButton from '@/vue_present/_base/buttons/MButton/MButton.vue'
import MModal from '@/vue_present/_base/MModal/MModal.vue'
import MIcon from '@/vue_present/_base/MIcon/MIcon.vue'
import { ValidationParentMixin } from '@/vue_present/mixins/ValidationParentMixin'
import CompanyWrapper from '@/vue_apps/ClientsModule/components/Company/CompanySearchCreate/CompanyWrapper.vue'
import MSelectLazy from '@/vue_present/_base/MSelectLazy/MSelectLazy.vue'
import { companiesPresenter } from '@/api_entities/companies/companiesPresenter'
import { DEFAULT_TAB, TAB_CONTACTS } from '@/vue_apps/ClientsModule/components/Company/CompanySearchCreate/const'
import { SpinnerHolder } from '@/vue_components/mixins/spinner_holder'

export default {
  name: 'CompanySearchCreate',
  components: {
    MSelectLazy,
    CompanyWrapper,
    MIcon,
    MModal,
    MButton,
  },

  mixins: [
    ValidationParentMixin,
    SpinnerHolder,
  ],

  props: {
    value: { type: Object, default: null },
    disabled: Boolean,
    label: { type: String, default: t('company') },
    placeholder: { type: String, default: t('choose') },
    required: { type: [Boolean, Function], default: false },
    validatorName: { type: String, default: null },
    companyType: { type: String, default: undefined },
    useFirstTimeFetch: Boolean,
  },

  emits: [
    'onCreateCompany',
    'change',
    'update:value',
  ],

  data () {
    return {
      fetchMethod: companiesPresenter.find,
      visible: false,
      items: [],

      saveCompanyDataCallback: null,
      setCompanyTab: null,
    }
  },

  created () {
    if (this.value) {
      this.appendCompanyToItems(this.value)
    }
  },

  methods: {
    _innFilter () {
      return (searchQueryInLowerCase, filteredItem) =>
        filteredItem.individual_tax_number?.toLowerCase().includes(searchQueryInLowerCase)
    },

    close () {
      this.visible = false
    },

    appendCompanyToItems (newCompany) {
      this.items = [...this.items, newCompany]
    },

    __highLightTab (errorsTitles = []) {
      if (errorsTitles.includes('phone1') || errorsTitles.includes('phone2')) {
        return this.setCompanyTab(TAB_CONTACTS)
      }

      this.setCompanyTab(DEFAULT_TAB)
    },

    async createCompany () {
      if (this.hasErrors()) {
        this.setCompanyTab(DEFAULT_TAB)

        return
      }

      this.startLoading()
      const company = this.saveCompanyDataCallback()
      const newCompany = await this.withSpinner(companiesPresenter.create(company))

      if (!newCompany || newCompany?.errors) {
        this.__highLightTab(this.resetValidations(newCompany.errors))

        return
      }

      this.appendCompanyToItems(newCompany)
      this.$emit('onCreateCompany', newCompany)
      this.close()
    },
  },
}
</script>
