import { MListServiceItem } from '@/_api/_requests/MListServiceItem'
import {
  IClinicalGuidelineImport,
} from '@/vue_apps/catalogs_root/ClinicalGuidelinesCatalog/interfaces/IClinicalGuidelineImport'
import { MTableCell } from '@/vue_present/_base/Tables/MTableGenerator/MTableCell'

export class ClinicalGuidelineImportItem extends MListServiceItem<number> {
  titleCell: MTableCell

  duplicateWarningCell: MTableCell = null

  clinicalGuideline: IClinicalGuidelineImport

  constructor (index: number, clinicalGuideline: IClinicalGuidelineImport) {
    super()

    this.id = index

    this.titleCell = new MTableCell(clinicalGuideline.title)
      .addTooltip(true)

    this.clinicalGuideline = clinicalGuideline
  }

  setDuplicateWarning (value: boolean) {
    if (!value) {
      this.duplicateWarningCell = null

      return
    }

    this.duplicateWarningCell = new MTableCell()
      .addTypeIcon('warning')
      .addClasses('warning')
      .addTooltip(t('clinicalGuidelines.catalog.import.duplicateWarning'))
  }
}
