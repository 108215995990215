import { reportErrorText, reportSuccessText } from '@/_api/_requests/helpers'

export const NotifyMixin = {
  methods: {
    showSuccessNotify (message: string, entity: string, title?: string) {
      Utils.reportSuccess(
        reportSuccessText(message, entity),
        title
      )()
    },

    showErrorNotify (message: string, entity: string, title?: string) {
      Utils.reportError(
        this.$vnode.tag,
        reportErrorText(message, entity),
        title
      )()
    },
  },
}
