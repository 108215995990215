import { MPresenterBase } from '@/_api/_requests/MPresenterBase'
import { MRequestNotification } from '@/_api/_requests/MRequestNotification'
import { MRequestError } from '@/_api/_requests/MRequestError'
import { MListService } from '@/_api/_requests/MListService'

export class MWorkplacePresenter extends MPresenterBase {
  constructor () {
    super({
      entity: 'workplace',
      location: 'MWorkplacePresenter',
      routes: {
        one () {},
        all () {},
        list: Routes.list_api_internal_workplaces_path,
      },
    })
  }

  /**
   * @param data
   * @param config
   * @return {Promise<{totalItems: *, data: *, totalPages: number} | { errors: Record<string, string[]> }>}
   */
  list (data = { connected: true }, config = {}) {
    const notification = new MRequestNotification(
      this.location,
      'fetchAllMessage',
      'workplaces'
    )

    const _config = {
      toJson: true,
      ...config,
    }

    return super.list(data, _config)
      .catch(MRequestError.withDefault(MListService.getDefaultList(), notification))
  }
}
