import reducersStorage from './reducers'
import { TemplateRecordBuilder } from './builders/backend_templae_record_builder'
import { DocumentTypeBuilder } from './builders/backend_document_type_builder'
import { BackendEntryTypesBuilder } from './builders/backend_entry_types_builder'
import { FrontTestBuilder } from './builders/frontend_test_builder'
import { EditModeBuilder } from './builders/edit_mode_builder'

import { TemplateApplication } from './application_builder'
import { createStore } from 'redux'
import { ProtocolBuilder } from '@/vue_apps/ProtocolsApp/entities/ProtocolBuilder'
import { EApplicationBuilderType } from '@/plugins/dynamic_forms/declarations/EApplicationBuilderType'
import BaseTemplateBuilder from '@/plugins/dynamic_forms/builders/BaseTemplateBuilder'

let frontendBuilder = null
let applicationCached = null

const getBuilder = (
  type: EApplicationBuilderType,
  store: Record<string, any>,
  params: Record<string, any>
) => {
  switch (type) {
    case EApplicationBuilderType.backend:
      return new BackendEntryTypesBuilder(store)
    case EApplicationBuilderType.frontend: {
      if (frontendBuilder) { return frontendBuilder }
      frontendBuilder = new ProtocolBuilder(store)

      return frontendBuilder
    }
    case EApplicationBuilderType.templateRec :
      return new TemplateRecordBuilder(store)
    case EApplicationBuilderType.documentRec :
      return new DocumentTypeBuilder(store)
    case EApplicationBuilderType.test :
      return new FrontTestBuilder(store)
    case EApplicationBuilderType.complexMember:
      return new EditModeBuilder(store, params)
    default: return null
  }
}

const getApplication = (
  type: EApplicationBuilderType,
  params: Record<string, any>
) => {
  if ((type === EApplicationBuilderType.frontend) && applicationCached) {
    return applicationCached
  }
  const store = createStore(
    reducersStorage,
    /* @ts-ignore */
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
  )

  const builder = getBuilder(type, store, params)
  const templateApplicationManager = new TemplateApplication()

  const builtApp: BaseTemplateBuilder = templateApplicationManager.build(builder)
  applicationCached = builtApp

  builtApp.run()

  return builtApp
}

const applicationFactory = <BuilderType>(
  type: EApplicationBuilderType,
  mode: string | null = null,
  params: Record<string, any> = null
): BuilderType => {
  application.form_builder = {}

  if (!application.form_builder[type] || type === EApplicationBuilderType.complexMember) {
    console.debug('new template application')

    const appBuilder = getApplication(type, params)

    appBuilder.globalMap = {
      lastTarget: false,
      lastRange: false,
    }

    appBuilder.errors = []

    application.form_builder[type] = appBuilder
  }

  if (mode) {
    application
      .form_builder[type]
      ._components.modal.mode = mode
  }

  application
    .form_builder.current = application.form_builder[type]

  if (
    type === EApplicationBuilderType.templates ||
    type === EApplicationBuilderType.test ||
    type === EApplicationBuilderType.complexMember
  ) {
    application.form_builder[type]._components.modal._open()
  }

  return application.form_builder[type]
}

export default {
  generate: applicationFactory,
}
