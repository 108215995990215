<template>
  <div class="payment-head-extra">
    <!-- Печать -->
    <m-button
      :text="t('print')"
      button-style="fill"
      icon="print"
      type="dark"
      size="mini"
      @click="onPrint"
    />

    <!-- Копия чека -->
    <m-button
      v-if="$m.modules.workplaces"
      v-tooltip="receiptCopyTooltip"
      :text="t('print_receipt_copy')"
      button-style="fill"
      icon="copy"
      type="dark"
      size="mini"
      :disabled="receiptCopyDisabled"
      @click="onPrintReceiptCopy"
    />

    <!-- Печать чека(электронный) -->
    <m-button
      v-if="$m.modules.workplaces"
      :text="t('print_electronical_bill')"
      button-style="fill"
      icon="envelope"
      type="dark"
      size="mini"
      :disabled="!hasWorkplace"
      @click="onPrintReceipt(PAYMENT_PRINT_METHOD.ELECTRONICALLY)"
    />

    <!-- Печать чека -->
    <m-button
      v-if="$m.modules.workplaces"
      :text="t('print_bill')"
      button-style="fill"
      icon="print"
      type="dark"
      size="mini"
      :disabled="!hasWorkplace"
      @click="onPrintReceipt(PAYMENT_PRINT_METHOD.NORMAL)"
    />

    <print-receipt @openModal="openPrintReceiptModal = $event" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { PaymentApi } from '@/vue_apps/FinanceModule/FinanceModuleIndex/classes/Payment/PaymentApi'
import MButton from '@/vue_present/_base/buttons/MButton/MButton.vue'
import { onPrintReceiptCopy } from '@/vue_apps/FinanceModule/FinanceModuleIndex/consts/printReceiptCopy'
import { PAYMENT_PRINT_METHOD } from '@/vue_apps/FinanceModule/FinanceModuleIndex/consts/paymentPrintMethod'
import PrintReceipt from '@/vue_apps/FiscalService/printReceipt/PrintReceipt.vue'
import { MConfirm } from '@/vue_present/_base/MConfirm/MConfirm'
import { checkOFDBannedTaxSchemes } from '@/specific/payments/checkOFDBannedTaxSchemes'

export default defineComponent({
  name: 'PaymentHeadExtra',

  components: {
    PrintReceipt,
    MButton,
  },

  props: {
    payment: PaymentApi,
  },

  emits: ['loading'],

  data () {
    return {
      PAYMENT_PRINT_METHOD,
      openPrintReceiptModal: null,
      receiptRecords: gon.specific.receipt_records,
    }
  },

  computed: {
    hasWorkplace () {
      return Boolean(gon.application.current_user.workplace_id) && this.$store.getters['weStore/vxGetFiscalPrinterModuleState']
    },

    receiptCopyDisabled () {
      return !this.hasWorkplace || !this.receiptRecords.length
    },

    receiptCopyTooltip () {
      return this.hasWorkplace && !this.receiptRecords.length
        ? t('fiscal.printedReceiptsNotExist')
        : null
    },
  },

  methods: {
    onPrintReceiptCopy,

    onPrint () {
      window.print()
    },

    async onPrintReceipt (type: PAYMENT_PRINT_METHOD) {
      const checkOFDBannedTaxSchema = checkOFDBannedTaxSchemes(gon.specific.payment_entries_schemes)
      if (checkOFDBannedTaxSchema.cancel) { return }

      if (!this.receiptRecords.length) {
        if (type === PAYMENT_PRINT_METHOD.ELECTRONICALLY) {
          this.printReceipt(type)

          return
        }

        const { cancel } = await MConfirm.warning(t('fiscal.printReceipt?'))
        if (cancel) { return }

        this.printReceipt(type)

        return
      }

      const { cancel } = await MConfirm.error(t('fiscal.checkAlreadyPrintedMsg'))
      if (cancel) { return }

      this.printReceipt(type)
    },

    printReceipt (printMethod: PAYMENT_PRINT_METHOD) {
      if (printMethod === PAYMENT_PRINT_METHOD.ELECTRONICALLY) {
        this.openPrintReceiptModal()

        return
      }

      Services.workplace.sendMessage('print_receipt', {
        print_method: printMethod,
        payment_id: this.payment.id,
      })

      this.$emit('loading', true)
    },
  },
})
</script>
