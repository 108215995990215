<template>
  <div class="print-receipt-modal">
    <m-modal
      :visible.sync="modalVisible"
      :dialog-title="t('fiscal.enterRecipientInfo')"
      modal-title-icon="info"
    >
      <template #body>
        <m-select
          :value.sync="value"
          :items="items"
          :clearable="false"
          allow-create
          full-width
          filterable
          :required="phoneValidation"
          validator-name="contactsSelector"
          @registerValidator="onRegisterValidator"
        />
        <div class="print-receipt_input-clarification">
          {{ t('fiscal.modalInputClarification') }}
        </div>
      </template>

      <template #footer-left>
        <div class="print-receipt-buttons">
          <m-button
            :text="t('apply')"
            type="success"
            class="print-receipt-buttons_apply"
            @click="onSave"
          />
          <m-button
            :text="t('cancel')"
            type="primary"
            @click="closeModal"
          />
        </div>
      </template>

      <template #footer-right>
        <span />
      </template>
    </m-modal>
  </div>
</template>

<script>
import MSelect from '@/vue_present/_base/inputs/MSelect/MSelect.vue'
import MButton from '@/vue_present/_base/buttons/MButton/MButton.vue'
import MModal from '@/vue_present/_base/MModal/MModal.vue'
import { phoneValidation } from '@/vue_apps/FiscalService/printReceipt/scripts/phoneValidation'
import { ValidationParentMixin } from '@/vue_present/mixins/ValidationParentMixin'
import { ModalMixin } from '@/vue_present/mixins/ModalMixins/ModalMixin'

const DEFAULT_ITEM_TYPE = 'phone'

export default {
  name: 'PrintReceipt',

  components: {
    MModal,
    MButton,
    MSelect,
  },

  mixins: [
    ValidationParentMixin,
    ModalMixin,
  ],

  emits: ['openModal', 'loading'],

  data () {
    return {
      value: {},
      printMethod: 'electronically',
      phoneValidation,
    }
  },

  computed: {
    items () {
      return Object.entries(gon.specific.receiver_info)
        .filter(([, value]) => value)
        .map(([key, value]) => {
          if (key.includes(DEFAULT_ITEM_TYPE)) {
            const maskedPhone = Services.phoneMask.setMask(value.replace('+', ''))

            return {
              id: value,
              title: `+${maskedPhone}`,
              type: DEFAULT_ITEM_TYPE,
            }
          }

          return {
            id: value,
            title: value,
            type: key,
          }
        })
    },
  },

  mounted () {
    this.$emit('openModal', this.openModal)

    const defaultValue = this.items.find((item) => item.type === DEFAULT_ITEM_TYPE)

    if (!defaultValue) {
      return
    }

    this.value = defaultValue
  },

  methods: {
    onSave () {
      if (this.hasErrors()) { return }

      const value = this.value.id
        ? this.value.id
        : this.value

      Services.workplace.sendMessage('print_receipt', {
        print_method: this.printMethod,
        receiver_info: value,
        payment_id: gon.specific.payment.id,
      })

      this.closeModal()

      this.$emit('loading')
    },
  },
}
</script>
