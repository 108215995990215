<template>
  <div class="node-content flex align-center overflow-hidden">
    <div class="mr-5">
      <m-icon
        v-if="data.children"
        :icon="catalogIcon"
        color="warning"
      />

      <m-icon
        v-else
        v-tooltip="itemIconTooltip"
        :icon="itemIcon"
        :color="itemIconColor"
      />
    </div>

    <slot
      v-if="editable"
      name="actions"
    >
      <m-icon
        class="mr-5"
        icon="menu"
        color="gray"
        @click.stop="$emit('onEdit', $event)"
      />
    </slot>

    <slot name="title">
      <div
        v-tooltip="data.title"
        class="node-content__title cut"
      >
        <span>{{ data.title }}</span>
      </div>
    </slot>

    <slot />
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import MIcon from '@/vue_present/_base/MIcon/MIcon.vue'

export default defineComponent({
  name: 'LazyTreeNodeComponent',

  components: { MIcon },

  props: {
    node: { type: Object, required: true },
    data: { type: Object, required: true },
    itemIcon: { type: String, default: 'file' },
    itemIconColor: { type: String, default: 'gray' },
    itemIconTooltip: { type: String, default: null },
    editable: Boolean,
    useCheckboxes: Boolean,
  },

  emits: ['onEdit'],

  computed: {
    catalogIcon () {
      return this.node.expanded
        ? 'folderOpen'
        : 'folder'
    },
  },
})
</script>
