<template>
  <m-panel class="checkup">
    <!-- Кастомный заголовок -->
    <template #head>
      <m-icon
        class="m-panel__icon"
        :icon="icon"
      />
      <span class="cut">
        {{ title }}
      </span>
    </template>

    <div class="checkup__content">
      <div class="checkup__left-side">
        <!-- Информация -->
        <personal-checkup-info
          ref="personalCheckupInfo"
          :personal-checkup="personalCheckup"
          :readonly="isShowMode"
          :is-edit-mode="isEditMode"
          :is-new-mode="isNewMode"
          @registerValidator="onRegisterValidator"
        />

        <!-- Прививки и заболевания -->
        <personal-checkup-vaccinations-and-diseases
          :personal-checkup="personalCheckup"
          :readonly="isShowMode"
          :is-new-mode="isNewMode"
          :is-show-mode="isShowMode"
          :is-edit-mode="isEditMode"
        />

        <!-- Документы -->
        <checkup-documents
          :checkup-documents-list="personalCheckup.documents"
          :client-id="personalCheckup.client.id"
          :is-show-mode="isShowMode"
          :is-new-mode="isNewMode"
          :is-edit-mode="isEditMode"
          @registerValidator="onRegisterValidator"
          @createBladeRunner="onCreateBladeRunner"
          @createSimpleMedicalAssessment="onCreateSimpleMedicalAssessment"
          @createElmkMedicalAssessment="onCreateElmkMedicalAssessment"
          @createOldDocument="onCreateOldDocument"
          @delete="personalCheckup.documents.fetchAll()"
        />

        <!-- Файлы -->
        <personal-checkup-files
          :personal-checkup="personalCheckup"
          :is-show-mode="isShowMode"
          :is-new-mode="isNewMode"
          :is-edit-mode="isEditMode"
          @updatePersonalCheckupIdFilter="personalCheckupFilesUpdatePersonalCheckupIdFilter = $event"
        />

        <!-- Результаты лабораторных и инструментальных исследований -->
        <personal-checkup-laboratory-and-instrumental-research
          :personal-checkup="personalCheckup"
          :is-new-mode="isNewMode"
          :is-show-mode="isShowMode"
          :is-edit-mode="isEditMode"
        />

        <!-- Заключение -->
        <personal-checkup-conclusion
          :personal-checkup="personalCheckup"
          :is-new-mode="isNewMode"
          :is-show-mode="isShowMode"
          :is-edit-mode="isEditMode"
          @registerValidator="onRegisterValidator"
        />
      </div>

      <div class="checkup__right-side">
        <!-- Врачебная комиссия и исследования -->
        <personal-checkup-doctors-and-tests
          :is-show-mode="isShowMode"
          :is-edit-mode="isEditMode"
          :is-new-mode="isNewMode"
          :personal-checkup="personalCheckup"
          @registerValidator="onRegisterValidator"
        />

        <!-- Услуги -->
        <personal-checkup-entries
          v-if="!isNewMode"
          :is-show-mode="isShowMode"
          :is-edit-mode="isEditMode"
          :is-new-mode="isNewMode"
          :personal-checkup="personalCheckup"
        />
      </div>
    </div>

    <template #footer>
      <div class="flex flex-grow-1 align-items-center center gap-indent">
        <div class="checkup__buttons flex-grow-1">
          <!-- редактировать -->
          <m-button
            v-if="!isEditMode && !isNewMode"
            template="edit"
            disabled="CheckupsCompany"
            @click="onEditClick"
          />

          <!-- сохранить -->
          <m-button-submit
            v-if="!isShowMode"
            :disabled="loading || 'CheckupsCompany'"
            @click="onSubmit()"
          />

          <!-- сохранить и перейти к счёту -->
          <m-button-submit
            v-if="!isShowMode && !personalCheckup.orderId"
            :text="t('checkups.saveAndGoToOrder')"
            icon="order"
            :disabled="loading || 'CheckupsCompany'"
            @click="onSubmit(true)"
          />

          <!-- Создать счёт -->
          <m-button
            v-if="isShowMode && !personalCheckup.orderId"
            :text="t('checkups.createOrder')"
            icon="order"
            @click="onNewOrder(id)"
          />

          <!-- перейти к счёту -->
          <m-button
            v-if="personalCheckup.orderId"
            :text="t('checkups.goToOrder')"
            icon="order"
            @click="onOpenOrder"
          />

          <!-- удалить -->
          <m-button-delete
            v-if="isShowMode"
            use-button
            disabled="CheckupsCompany"
            @yes="onDelete"
          />

          <!-- закрыть -->
          <m-button
            template="close"
            @click="onClose"
          />
        </div>

        <div class="checkup__created">
          <checkup-created
            v-if="personalCheckup.creator && !isNewMode"
            :checkup="personalCheckup"
          />
        </div>
      </div>
    </template>
  </m-panel>
</template>

<script>
import MPanel from '@/vue_present/_base/MPanel/MPanel.vue'
import MButton from '@/vue_present/_base/buttons/MButton/MButton.vue'
import MButtonSubmit from '@/vue_present/_base/buttons/MButtonSubmit/MButtonSubmit.vue'
import CheckupDocuments from '@/vue_apps/CheckupsModule/components/CheckupDocuments.vue'
import PersonalCheckupInfo from '@/vue_apps/CheckupsModule/components/PersonalCheckup/PersonalCheckupInfo.vue'
import PersonalCheckupFiles from '@/vue_apps/CheckupsModule/components/PersonalCheckup/PersonalCheckupFiles.vue'
import PersonalCheckupConclusion
  from '@/vue_apps/CheckupsModule/components/PersonalCheckup/PersonalCheckupConclusion.vue'
import { CHECKUPS_ROUTE_NAMES } from '@/vue_apps/CheckupsModule/router/routeNames'
import { CHECKUP_KINDS, CHECKUP_KINDS_ICONS } from '@/vue_apps/CheckupsModule/store/checkupsKinds'
import CheckupCreated from '@/vue_apps/CheckupsModule/components/CheckupCreated.vue'
import { PersonalCheckupApi } from '@/vue_apps/CheckupsModule/classes/PersonalCheckup/PersonalCheckupApi'
import { ValidationParentMixin } from '@/vue_present/mixins/ValidationParentMixin'
import PersonalCheckupDoctorsAndTests
  from '@/vue_apps/CheckupsModule/components/PersonalCheckup/PersonalCheckupDoctorsAndTests.vue'
import { CheckupsComputedMixin } from '@/vue_apps/CheckupsModule/mixins/CheckupsComputedMixin'
import {
  MPersonalCheckupPresenter,
} from '@/vue_apps/CheckupsModule/api/MPersonalCheckupPresenter/MPersonalCheckupPresenter'
import { CompanyCheckup } from '@/vue_apps/CheckupsModule/classes/CompanyCheckup/CompanyCheckup'
import {
  MCompanyCheckupPresenter,
} from '@/vue_apps/CheckupsModule/api/MCompanyCheckupPresenter/MCompanyCheckupPresenter'
import MIcon from '@/vue_present/_base/MIcon/MIcon.vue'
import { CHECKUP_STATUS } from '@/vue_apps/CheckupsModule/store/checkupStatuses'
import { MConfirm, MConfirmRenderList } from '@/vue_present/_base/MConfirm/MConfirm'
import MButtonDelete from '@/vue_present/_base/buttons/MButtonDelete/MButtonDelete.vue'
import { getDocumentRoute } from '@/vue_apps/CheckupsModule/router/routeHelpers'
import { SpinnerHolder } from '@/vue_components/mixins/spinner_holder'
import PersonalCheckupEntries
  from '@/vue_apps/CheckupsModule/components/PersonalCheckup/PersonalCheckupEntries/PersonalCheckupEntries.vue'
import {
  PERSONAL_CHECKUP_POSITION_PROFILE_ITEMS,
} from '@/vue_apps/CheckupsModule/store/personalCheckupPositionProfileItems'
import PersonalCheckupVaccinationsAndDiseases
  from '@/vue_apps/CheckupsModule/components/PersonalCheckup/PersonalCheckupVaccinationsAndDiseases.vue'
import PersonalCheckupLaboratoryAndInstrumentalResearch
  from '@/vue_apps/CheckupsModule/components/PersonalCheckup/PersonalCheckupLaboratoryAndInstrumentalResearch.vue'
import { tScoped } from '@/helpers/i18nHelpers'
import { CHECKUP_DOCUMENT_API_MAP } from '@/vue_apps/CheckupsModule/store/checkupDocumentApiMap'
import { isDocumentExist } from '@/vue_apps/CheckupsModule/store/isDocumentExist'
import { PersonalCheckupEditorTitleMixin } from '@/vue_apps/CheckupsModule/mixins/PersonalCheckupEditorTitleMixin'
import { DOCUMENT_TYPES } from '@/vue_present/Reuse/DocumentTree/store/documentTypes'
import { DOCUMENT_OWNER_TYPES } from '@/vue_present/Reuse/DocumentTree/store/documentOwnerTypes'
import { PUB_SUB_BROADCAST_EVENTS } from '@/_declarations/pubSub/pubSubBroadcastEvents'

export default {
  name: 'PersonalCheckupEditor',
  components: {
    PersonalCheckupLaboratoryAndInstrumentalResearch,
    PersonalCheckupVaccinationsAndDiseases,
    PersonalCheckupEntries,
    MButtonDelete,
    MIcon,
    PersonalCheckupDoctorsAndTests,
    CheckupCreated,
    PersonalCheckupConclusion,
    PersonalCheckupFiles,
    PersonalCheckupInfo,
    CheckupDocuments,
    MButtonSubmit,
    MButton,
    MPanel,
  },

  mixins: [
    ValidationParentMixin,
    CheckupsComputedMixin,
    SpinnerHolder,
    PersonalCheckupEditorTitleMixin,
  ],

  props: {
    id: { type: Number, default: null },
    companyCheckupId: { type: Number, default: null },
  },

  data () {
    return {
      personalCheckup: new PersonalCheckupApi({}), // см. PersonalCheckupApi()
      companyCheckup: new CompanyCheckup(),
      personalCheckupFilesUpdatePersonalCheckupIdFilter: null,
    }
  },

  computed: {
    checkupKind () {
      return this.companyCheckupId
        ? CHECKUP_KINDS.PERIODIC
        : CHECKUP_KINDS.PRIOR
    },

    icon () {
      return CHECKUP_KINDS_ICONS[this.checkupKind]
    },
  },

  async created () {
    if (this.companyCheckupId) {
      this.companyCheckup = await new MCompanyCheckupPresenter().fetch(this.companyCheckupId)
    }

    this.personalCheckup = await this.fetchPersonalCheckup()

    this._subscribeToOrderUpdate()

    this._subscribeToCreatedDocument()
    this._subscribeToRemoveDocuments()

    if (this.isNewMode) { return }

    await this.$nextTick()

    this.personalCheckupFilesUpdatePersonalCheckupIdFilter &&
    this.personalCheckupFilesUpdatePersonalCheckupIdFilter(true)
  },

  methods: {
    /**
     * Триггер обновления списка медосмотров в профосмотре на другой вкладке
     */
    _tryUpdateCompanyCheckupPersonalCheckupsList () {
      this.$pubSub.emit('updatePersonalCheckupsList', null, true)
    },

    /**
     * Обновление orderId после сабмита на вкладке счёта
     * @private
     */
    _subscribeToOrderUpdate () {
      this.$pubSub.subscribe('broadcast:updatePersonalCheckupOrderId', ({ orderId, personalCheckupId }) => {
        if (this.personalCheckup.id !== personalCheckupId) { return }
        this.personalCheckup.orderId = orderId
      })
    },

    _subscribeToRemoveDocuments () {
      this.$pubSub.subscribe(PUB_SUB_BROADCAST_EVENTS.REMOVE_DOCUMENTS, (event) => {
        if (!isDocumentExist(this.personalCheckup.documents.data, event)) { return }

        this.personalCheckup.documents.fetchAll()
      })
    },

    _subscribeToCreatedDocument () {
      this.$pubSub.subscribe(PUB_SUB_BROADCAST_EVENTS.CREATED_DOCUMENT, (event) => {
        const isOwnerOfDocument = event.documentsOwners.some(({ ownerType, ownerId }) =>
          ownerType === DOCUMENT_OWNER_TYPES.PERSONAL_CHECKUP && ownerId === this.personalCheckup.id)

        if (!isOwnerOfDocument) { return }

        this.personalCheckup.documents.fetchAll()
      })
    },

    onEditClick () {
      if (this.companyCheckupId) {
        return this.$router.push({
          name: CHECKUPS_ROUTE_NAMES.PERSONAL_PERIODIC_CHECKUP_EDIT,
        })
      }

      this.$router.push({
        name: CHECKUPS_ROUTE_NAMES.PERSONAL_CHECKUP_EDIT,
      })
    },

    /**
     * Новый персональный медосмотр
     * БЛ:
     * 1) дата проведения заполняется из companyCheckup.plannedStartDate,
     *    если медосмотр - периодический + расчёт следуещего медосмотра
     * @return {PersonalCheckupApi}
     * @private
     */
    _createNewPersonalCheckup () {
      const personalCheckup = new PersonalCheckupApi({
        kind: this.checkupKind,
        department: this.companyCheckup?.department,
        company: this.companyCheckup?.company,
        responsible: this.companyCheckup?.responsible,
        companyCheckupId: this.companyCheckupId,
      })

      if (personalCheckup.isPeriodic) {
        personalCheckup.setValue('date', this.companyCheckup.plannedStartDate)
        personalCheckup.setValue(
          'nextDate',
          Utils.getBaseFormattedDate(moment(this.companyCheckup.plannedStartDate).add('1', 'year'))
        )
      }

      return personalCheckup
    },

    async _fetchExistsPersonalCheckup () {
      const personalCheckupRaw = await new MPersonalCheckupPresenter().fetch(this.id)

      if (personalCheckupRaw.errors?.error === 'personal_checkup_not_found') {
        return this.$router.replace({ name: CHECKUPS_ROUTE_NAMES.CHECKUP_404 })
      }

      if (personalCheckupRaw.errors) { return }

      const personalCheckup = new PersonalCheckupApi({
        ...personalCheckupRaw,
        kind: this.checkupKind,
        company: this.companyCheckup?.company || personalCheckupRaw.company,
        status: this.isNewMode ? CHECKUP_STATUS.IN_PROGRESS : personalCheckupRaw.status,
        client: {
          ...personalCheckupRaw.client,
          phone: Utils.extractDigits(personalCheckupRaw.client.phone),
          nsiProfession: personalCheckupRaw.nsiProfession,
        },
        positionProfile: PERSONAL_CHECKUP_POSITION_PROFILE_ITEMS
          .find(({ title }) => title === personalCheckupRaw.positionProfile),
      })

      personalCheckup.documents.fetchAll()

      return personalCheckup
    },

    async fetchPersonalCheckup () {
      if (!this.id) { return this._createNewPersonalCheckup() }

      return await this._fetchExistsPersonalCheckup()
    },

    async onSubmit (withNewOrder = false) {
      const validationErrors = this.hasValidationErrors(true)
      if (validationErrors) {
        if (validationErrors['client.sex'] || validationErrors['client.birthdate']) {
          this.$refs.personalCheckupInfo.triggerClientTooltip()
        }

        return
      }

      const { errors, id } = await this.withSpinnerSimple(this.personalCheckup.save())
      if (errors) { return this.resetValidations(errors) }

      if (this.isNewMode) {
        this.personalCheckup.initDocumentsFilters()

        this.personalCheckupFilesUpdatePersonalCheckupIdFilter &&
        this.personalCheckupFilesUpdatePersonalCheckupIdFilter()

        // вызов расчёта по факторам при создании медосмотра
        if (this.personalCheckup.canCalculateEntries && await this.calculateFactors()) { return }
      }

      this.personalCheckup.isPeriodic
        ? await this.onAfterSubmitPeriodic(id, withNewOrder)
        : await this.onAfterSubmitPrior(id, withNewOrder)
    },

    async calculateFactors () {
      const { errors } = await this.personalCheckup.calculateFactors()
      if (errors) { return errors }

      const personalCheckup = await this.personalCheckup.checkupEntries.saveCheckupEntries()

      if (personalCheckup.errors) { return personalCheckup.errors }

      this.personalCheckup.checkupEntries.setCheckupEntries(personalCheckup.checkupEntries)

      this.personalCheckup.checkupEntries.patchCheckupEntries()
    },

    async onAfterSubmitPeriodic (id, withNewOrder = false) {
      this._tryUpdateCompanyCheckupPersonalCheckupsList()

      await this.$router.push({
        name: CHECKUPS_ROUTE_NAMES.PERSONAL_PERIODIC_CHECKUP_SHOW,
        params: { id: this.companyCheckupId, personalCheckupId: id },
      })

      withNewOrder && this.onNewOrder(id)
    },

    async onAfterSubmitPrior (id, withNewOrder = false) {
      await this.$router.push({
        name: CHECKUPS_ROUTE_NAMES.PERSONAL_CHECKUP_SHOW,
        params: { id },
      })

      withNewOrder && this.onNewOrder(id)
    },

    async onDelete () {
      const { errors } = await this.personalCheckup.delete()
      if (errors) { return this.errorsHandler(errors) }

      this._tryUpdateCompanyCheckupPersonalCheckupsList()
      await this.$router.replace({ name: CHECKUPS_ROUTE_NAMES.PRIOR_CHECKUPS })
    },

    errorsHandler (errors) {
      if (errors === 'exists_error') {
        Notificator.error(t('semds.errors.checkupSemdsExists'))
      }

      if (errors === 'not_found') {
        Notificator.error(t('semds.errors.notFound'))
      }
    },

    async onClose () {
      if (this.isNewMode || this.isEditMode) {
        const { cancel } = await MConfirm.warning(t('closeWithoutSaving?'))
          .catch(() => ({ cancel: true }))
        if (cancel) { return }
      }

      if (this.personalCheckup.isPeriodic) {
        return window.close()
      }

      return await this.$router.replace({ name: CHECKUPS_ROUTE_NAMES.PRIOR_CHECKUPS })
    },

    onOpenOrder () {
      window.open(this.$routes.order_path(this.personalCheckup.orderId))
    },

    onNewOrder (id) {
      window.open(
        this.$routes.new_order_path({ client_id: this.personalCheckup.client.id, personal_checkup_id: id })
      )
    },

    async onCreateBladeRunner () {
      await this.afterDocumentCreated(
        await CHECKUP_DOCUMENT_API_MAP[DOCUMENT_TYPES.CHECKUPS_PERSONAL_ROUTES_LIST]
          .create(this.personalCheckup.id),
        DOCUMENT_TYPES.CHECKUPS_PERSONAL_ROUTES_LIST,
        true
      )
    },

    async onCreateSimpleMedicalAssessment () {
      await this.afterDocumentCreated(
        await CHECKUP_DOCUMENT_API_MAP[DOCUMENT_TYPES.CHECKUPS_PERSONAL_SIMPLE_MEDICAL_ASSESSMENT]
          .create(this.personalCheckup.id),
        DOCUMENT_TYPES.CHECKUPS_PERSONAL_SIMPLE_MEDICAL_ASSESSMENT
      )
    },

    async onCreateElmkMedicalAssessment () {
      const validationResult = await CHECKUP_DOCUMENT_API_MAP[DOCUMENT_TYPES.CHECKUPS_PERSONAL_ELMK_MEDICAL_ASSESSMENT]
        .validatePersonalCheckup(this.personalCheckup.id)

      if (!validationResult) { return }

      if (validationResult.result.length) {
        const ts = tScoped('checkups.documents.personalElmkMedicalAssessment')

        const errors = validationResult.result.map((message) =>
          ts(`errors.${message}`))

        const message = ts('hasValidationErrors') + MConfirmRenderList(errors)

        const { cancel } = await MConfirm.warning(message, {
          dangerouslyUseHTMLString: true,
        })

        if (cancel) { return }
      }

      await this.afterDocumentCreated(
        await CHECKUP_DOCUMENT_API_MAP[DOCUMENT_TYPES.CHECKUPS_PERSONAL_ELMK_MEDICAL_ASSESSMENT]
          .create(this.personalCheckup.id),
        DOCUMENT_TYPES.CHECKUPS_PERSONAL_ELMK_MEDICAL_ASSESSMENT
      )
    },

    onCreateOldDocument ({ documentType, ...data }) {
      this.afterDocumentCreated(data, documentType)
    },

    async afterDocumentCreated ({ id, errors }, documentType, isEdit = false) {
      if (errors) { return }

      Utils.openInNewTab(
        getDocumentRoute({
          documentId: id,
          documentType,
          isEdit,
        })
      )

      await this.personalCheckup.documents.fetchAll()
    },
  },
}
</script>
