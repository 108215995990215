<template>
  <div class="m-si-generator">
    <!-- Управление списком -->
    <div
      v-if="isListManageRender"
      class="m-si-generator__list-manage hidden-print"
    >
      <!-- Добавить -->
      <slot name="add">
        <template v-if="siGeneratorSchemaTyped.useAdd">
          <m-button
            class="m-si-generator__add"
            :plus-icon="!addButtonConfigTyped.hidePlusIcon"
            type="success"
            :icon="addButtonConfigTyped.icon"
            :disabled="addButtonConfigTyped.canManage"
            :no-use-fw="addButtonConfigTyped.useNoFw"
            :tooltip="addButtonConfigTyped.tooltip"
            :text="addButtonConfigTyped.text"
            @click="$emit('onAddItem', $event)"
          />
        </template>
      </slot>

      <!-- Поиск -->
      <slot name="search">
        <m-search-input
          v-if="siGeneratorSchemaTyped.useSearch"
          :search-placeholder="searchPlaceholder"
          :search-query="searchQuery"
          :search-min-length="searchMinLength"
          :m-fixed-height="false"
          @update:searchQuery="$updateSync('searchQuery', $event)"
        />
      </slot>

      <slot name="searchFilters" />
    </div>

    <!-- Контролы -->
    <div
      v-if="isFiltersRender"
      class="m-si-generator__filters hidden-print"
      :class="filtersClass"
    >
      <!-- Фильтры -->
      <slot name="filters" />

      <template v-if="siGeneratorSchemaTyped.useFilterButtons">
        <m-si-generator-filters-buttons
          @onRefreshClick="$emit('onRefreshClick')"
          @onResetClick="$emit('onResetClick')"
        />
      </template>
    </div>

    <slot>
      <!-- Таблица -->
      <m-table-generator
        v-if="hasItems"
        class="m-si-generator__table"
        :loading="loading"
        :items="items"
        :table-schema="siGeneratorSchemaTyped.tableSchema"
        :use-context-menu="useContextMenu"
        :total="total"
        :active-item-id="activeItemId"
        @sortChange="$emit('onSortChange', $event)"
        @onRowClick="$emit('onRowClick', $event)"
        @onItemClick="$emit('onItemClick', $event)"
        @onItemEdit="$emit('onItemEdit', $event)"
        @onItemDelete="$emit('onItemDelete', $event)"
        @setSelectedItems="$emit('onSetSelectedItems', $event)"
        @onItemContextMenuClick="$emit('onItemContextMenuClick', $event)"
        @onCellClick="$emit('onCellClick', $event)"
      >
        <template
          v-for="(slotContent, slotName) in $scopedSlots"
          #[slotName]="slotProps"
        >
          <slot
            :name="slotName"
            v-bind="slotProps"
          />
        </template>

        <template #caption>
          <slot name="tableCaption" />
        </template>

        <template #header>
          <slot name="tableHeader" />
        </template>

        <template #body>
          <slot name="tableBody" />
        </template>

        <template #footer>
          <slot name="tableFooter" />
        </template>

        <template #contextMenu>
          <slot name="contextMenu" />
        </template>
      </m-table-generator>
      <m-not-found-results
        v-else
        :small="nfrSmall"
        :filtered="nfrFiltered"
        :can-reset-filters="nfrCanResetFilters"
        :text="nfrText"
        @resetFilters="$emit('onResetClick')"
      />
    </slot>

    <!-- Дно -->
    <div
      v-if="siGeneratorSchemaTyped.usePagination && pageCount > 1"
      class="m-si-generator__footer"
    >
      <slot name="footer">
        <slot name="footer-pagination">
          <m-pagination
            :page-count="pageCount"
            :current-page="currentPage"
            @update:currentPage="$updateSync('currentPage', $event)"
          />
        </slot>
      </slot>
    </div>
  </div>
</template>

<script>
import MTableGenerator from '@/vue_present/_base/Tables/MTableGenerator/MTableGenerator.vue'
import MPagination from '@/vue_present/_base/Tables/MPagination/MPagination.vue'
import { MPaginationProps } from '@/vue_present/_base/Tables/MPagination/MPaginationProps'
import MButton from '@/vue_present/_base/buttons/MButton/MButton.vue'
import MSearchInput from '@/vue_present/_base/Tables/MSearchInput/MSearchInput.vue'
import { MSearchInputProps } from '@/vue_present/_base/Tables/MSearchInput/MSearchInputProps'
import { getAddButtonConfig } from '@/vue_present/_base/Tables/MSiGenerator/logic/getAddButtonConfig'
import { getSiGeneratorSchema } from '@/vue_present/_base/Tables/MSiGenerator/logic/getSiGeneratorSchema'
import { PropsTypes } from '@/vue_present/_base/PropsTypes'
import MNotFoundResults from '@/vue_present/_base/Tables/MNotFoundResults/MNotFoundResults.vue'
import MSiGeneratorFiltersButtons
  from '@/vue_present/_base/Tables/MSiGenerator/components/MSiGeneratorFiltersButtons.vue'

export default {
  name: 'MSiGenerator',

  components: { MSiGeneratorFiltersButtons, MNotFoundResults, MSearchInput, MButton, MPagination, MTableGenerator },

  props: {
    /** @type {TMSiGeneratorSchema | MSiSchema} */
    siGeneratorSchema: {
      type: Object,
      required: true,
    },

    /** @type {TTableSchemaItem[]} */
    items: {
      type: Array,
      required: true,
    },

    activeItemId: { type: [String, Number], default: null },

    /** @type {TTableSchemaItem} */
    total: PropsTypes.Object({}),

    /** @type {TAddButtonConfig} */
    addButtonConfig: PropsTypes.Object({}),

    nfrText: { type: String, default: undefined },
    nfrSmall: { type: Boolean, default: true },
    nfrFiltered: Boolean,
    nfrCanResetFilters: { type: Boolean, default: true },

    filtersClass: { type: [Object, String], default: null },

    loading: Boolean,
    useContextMenu: Boolean,

    ...MPaginationProps,
    ...MSearchInputProps,
  },

  emits: [
    /*** Управление списком ***/

    /* void */
    'onAddItem',
    /* string */
    'update:searchQuery',

    /*** Контролы ***/

    /* void */
    'onRefreshClick',
    /* void */
    'onResetClick',

    /*** Таблица ***/

    /* TSortOrder */
    'onSortChange',
    /*{ item: TTableSchemaItem, event: PointerEvent }*/
    'onRowClick',
    /* TTableSchemaItem */
    'onItemClick',
    /* TTableSchemaItem */
    'onItemEdit',
    /* TTableSchemaItem */
    'onItemDelete',
    /* TTableSchemaItem[] */
    'onSetSelectedItems',
    /* TTableSchemaItem */
    'onItemContextMenuClick',
    /*{ cell: string, item: TTableSchemaItem, event: PointerEvent }*/
    'onCellClick',

    /*** Пагинация ***/

    /* number */
    'update:currentPage',
  ],

  computed: {
    /** @return {TMSiGeneratorSchema} */
    siGeneratorSchemaTyped () {
      return getSiGeneratorSchema(this.siGeneratorSchema)
    },

    /** @returns {TTableSchemaItem[]} */
    itemsTyped () {
      return this.items
    },

    /** @return {TAddButtonConfig} */
    addButtonConfigTyped () {
      return getAddButtonConfig(this.addButtonConfig)
    },

    isListManageRender () {
      return this.$slots.add ||
        this.$slots.search ||
        this.$slots.searchFilters ||
        this.siGeneratorSchemaTyped.useSearch ||
        this.siGeneratorSchemaTyped.useAdd
    },

    isFiltersRender () {
      return this.$slots.filters
    },

    hasItems () {
      return this.items?.length
    },
  },
}
</script>
