<template>
  <div class="protocol-info">
    <div class="flex flex-column showed-print">
      <div
        v-if="protocol.printEntryTitle"
        class="showed-print font-size-15 mb-indent strong"
      >
        {{ protocol.title }}
      </div>

      <div
        v-if="protocol.printEntryInfo"
        class="flex gap-indent-mid"
      >
        <template v-for="printField in printFields">
          <span
            :key="printField.label"
            class="strong"
          >{{ printField.label }}:</span> {{ printField.value }}
        </template>
      </div>
    </div>

    <m-form-show
      :form="fields"
      class="hidden-print"
    >
      <template
        v-if="protocol.entries.length"
        #m-show-value-entry
      >
        <div class="flex flex-column entries-wrapper">
          <span
            v-for="(entry, index) in protocol.entries"
            :key="entry.id"
          >
            <span>{{ index+1 }}.</span>
            <m-link
              :text="entry.title"
              :href="$routes.medical_record_entry_path(medicalRecordId, entry.id)"
            />
          </span>
        </div>
      </template>
      <template
        v-else
        #m-show-value-entry
      >
        -
      </template>

      <template
        v-if="protocol.entries.length"
        #m-show-label-entry
      >
        {{ fields.entry.label }} ({{ protocol.entries.length }})
      </template>
    </m-form-show>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import { ProtocolApi } from '@/vue_apps/ProtocolsApp/entities/ProtocolApi'
import MFormShow, { TFormShowForm } from '@/vue_present/_base/MFormShow/MFormShow.vue'
import MLink from '@/vue_present/_base/MLink/MLink.vue'
import { SEMD_TYPE_INVERTED_ENUM } from '@/vue_apps/Semds/const/semdTypes'
import { IClient } from '@/_declarations/IClient'
import { vueFilters } from '@/plugins/vue/filtersPlugin/vueFilters'
import { DateHelpers } from '@/helpers/DateHelpers'
import { GLOBAL_GET_DEFAULT_SEXES } from '@/_global_scripts/GLOBAL_CONSTS'

export default defineComponent({
  name: 'ProtocolInfo',

  components: { MLink, MFormShow },

  props: {
    medicalRecordId: { type: Number, required: true },
    protocol: { type: ProtocolApi, required: true },
    client: { type: Object as PropType<IClient>, required: true },
    useTitle: Boolean,
  },

  computed: {
    fields () {
      return {
        title: {
          label: t('title'),
          value: this.protocol.title,
          hidden: !this.useTitle,
        },
        date: {
          label: t('custom_date'),
          value: this.$filters.date(this.protocol.date),
        },
        doctor: {
          label: t('doctor'),
          value: this.protocol.doctor?.fullName,
          hiddenPrint: true,
        },
        clinic: {
          label: t('clinic'),
          value: this.protocol.clinic?.title,
          hiddenPrint: true,
        },
        onlineAccess: {
          label: t('showInClientWidget'),
          value: t(`medical_records_protokol_${this.protocol.onlineAccess}`),
          hiddenPrint: true,
        },
        semdType: {
          label: t('semds.semdTypeLabel'),
          value: (
            SEMD_TYPE_INVERTED_ENUM[this.protocol.semdType] &&
              t(SEMD_TYPE_INVERTED_ENUM[this.protocol.semdType], { scope: 'semds.semdType' })
          ) || '-',
          hiddenPrint: true,
        },
        entry: {
          label: t('relatedEntries'),
          hiddenPrint: !this.protocol.printEntryInfo,
        },
      } as TFormShowForm
    },

    printFields () {
      return {
        date: {
          label: t('custom_date'),
          value: vueFilters.date(this.protocol.date),
        },
        client: {
          label: t('client'),
          value: Utils.joinTruthy([
            this.client.fullname,
            (this.client.birthdate && DateHelpers.getAgeByBirthday(this.client.birthdate)) || '',
          ], ', '),
        },
        sex: {
          label: t('sex'),
          value: GLOBAL_GET_DEFAULT_SEXES().find((id) => id.id === this.client.sexId)?.title || '-',
        },
      }
    },
  },
})
</script>
