<template>
  <reusable-local-n-s-i-list
    :label="semdEntity.title"
    :value="semdEntity.value"
    :dictionary="semdEntity.nsiDictionary"
    :version="semdEntity.nsiDictionaryVersion"
    :permitted-values="semdEntity.permittedValues"
    :multiple="semdEntity.multiple"
    :m-fixed-height="false"
    :placeholder="t('choose')"
    @changeByLazy="onChange"
  />
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import ReusableLocalNSIList from '@/vue_present/Reuse/Lists/NSI/ReusableLocalNSIList.vue'
import {
  SemdNsiDictionary,
} from '@/vue_apps/ProtocolsApp/_SemdProtocolEditor/_SemdEntities/SemdNsiDictionary/SemdNsiDictionary'
import { ICatalog } from '@/_declarations/ICatalog'

export default defineComponent({
  name: 'SemdNsiDictionaryView',

  components: { ReusableLocalNSIList },

  props: {
    semdEntity: {
      type: Object as PropType<SemdNsiDictionary>,
      required: true,
    },
  },

  emits: [
    'change',
  ],

  methods: {
    onChange (value?: ICatalog | ICatalog[]) {
      this.$emit('change', {
        semdEntity: this.semdEntity,
        newValue: value,
      })
    },
  },
})
</script>
