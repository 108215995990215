<template>
  <m-modal
    class="diseases-modal"
    :type="panelType"
    :visible="modalVisible"
    :dialog-title="t('select_categories')"
    modal-title-icon="folder"
    @close="closeModal()"
  >
    <diseases
      :use-items="useItems"
      show-checkboxes
      @setTreeRef="treeRef = $event"
      @onCheckChange="onCheckChange"
    />

    <template #footer-right>
      <m-button-submit
        v-tooltip="!leftSideKeys.length && t('protocolEntities.nothingSelected')"
        :disabled="!leftSideKeys.length"
        @click="onSubmit()"
      />
    </template>
  </m-modal>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import Diseases from '@/vue_apps/catalogs_root/Diseases/Diseases.vue'
import MModal from '@/vue_present/_base/MModal/MModal.vue'
import { ModalMixin } from '@/vue_present/mixins/ModalMixins/ModalMixin'
import { DiseasesLazyTree } from '@/vue_apps/catalogs_root/Diseases/store/DiseasesLazyTree'
import MButtonSubmit from '@/vue_present/_base/buttons/MButtonSubmit/MButtonSubmit.vue'
import { IElTreeCheckChangeArgs } from '@/_declarations/IElTreeCheckChangeArgs'

export default defineComponent({
  name: 'DiseasesModal',

  components: {
    MButtonSubmit,
    MModal,
    Diseases,
  },

  mixins: [ModalMixin],

  props: {
    panelType: { type: String, default: 'success' },
    useItems: { type: Boolean, default: true },
  },

  emits: [
    'onSubmit',
  ],

  data () {
    return {
      treeRef: null,
      leftSideKeys: [],
    }
  },

  created () {
    this.$pubSub.subscribe('DiseasesModal:Open', this.onDiseasesModalOpen.bind(this))
  },

  beforeDestroy () {
    this.$pubSub.unsubscribe('DiseasesModal:Open', this.onDiseasesModalOpen.bind(this))
  },

  methods: {
    onDiseasesModalOpen () {
      this.leftSideKeys = []
      this.openModal()
    },

    onCheckChange ({ node, checked }: IElTreeCheckChangeArgs) {
      const treeMap = (node.tree as DiseasesLazyTree).treeMap
      const { checkedKeys } = checked

      this.leftSideKeys = checkedKeys.filter((key) => {
        /** Если все элементы поддерева выбраны, то забираем только родителя */
        return !checkedKeys.includes(treeMap[key]?.parent?.id) || !treeMap[key]?.parent
      })
    },

    onSubmit () {
      this.$emit('onSubmit', this.leftSideKeys)
      this.closeModal()
      this.treeRef.setCheckedKeys([])
    },
  },
})
</script>
