import { last } from 'ramda'

export default class EntityFactory {
  constructor (params, builder) {
    this.container = builder.container
    this.config = this.container.get('config')
    this.store = this.container.get('store')
  }

  /**
   * build entity
   */
  build (type, data = false) {
    const options = this.config[type]
    this.store.dispatch({
      type: options.actions.ADD,
      payload: {
        title: options.title,
        alias: options.alias,
        data,
      },
    })

    const addedEntity = last(this.store.getState()[type])

    const entityData = Object.assign({}, addedEntity, data)
    options.entityClass.createElement(entityData, options)

    return addedEntity
  }
}
