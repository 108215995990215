<template>
  <div class="tax-certificate-2024-app">
    <fixed-document-editor-header v-if="isEditor" />

    <router-view :certificate="certificate" />

    <fixed-document-print-footer
      v-if="isShow"
      @onEditClick="onEditClick"
      @onDestroyClick="onDestroyClick"
    >
      <m-button
        template="saveAndPrint"
        type="success"
        :disabled="!certificate.isCertificateLoaded"
        :text="t$('xml.generateXML')"
        @click="onXmlGenerate"
      />
    </fixed-document-print-footer>

    <tax-certificate2024-xml-modal
      v-if="certificate.id"
      :tax-certificate2024="certificate"
      :current-user="currentUser"
      @registerApi="tc2024XmlModalApi = $event"
    />
  </div>
</template>

<script lang="ts">
import {
  TaxCertificate2024,
} from '@/vue_apps/Documents/FixedDocuments/_documents/TaxCertificate2024/classes/TaxCertificate2024'
import FixedDocumentEditorHeader from '@/vue_apps/Documents/FixedDocuments/components/FixedDocumentEditorHeader.vue'
import {
  TAX_CERTIFICATE_ROUTE_NAMES,
} from '@/vue_apps/Documents/FixedDocuments/_documents/TaxCertificate2024/router/routeNames'
import FixedDocumentPrintFooter from '@/vue_apps/Documents/FixedDocuments/components/FixedDocumentPrintFooter.vue'
import { scrollToTop } from '@/vue_apps/Documents/FixedDocuments/helpers/scrollToTop'
import TaxCertificate2024XmlModal, {
  TaxCertificate2024XmlModalAPI,
} from '@/vue_apps/Documents/FixedDocuments/_documents/TaxCertificate2024/components/TaxCertificate2024XmlModal.vue'
import { snakeToCamel } from '@/_api/_requests/helpers'
import { MConfirm } from '@/vue_present/_base/MConfirm/MConfirm'
import MButton from '@/vue_present/_base/buttons/MButton/MButton.vue'
import { i18nScopeMixin } from '@/vue_present/mixins/i18nScopeMixin'

export default {
  name: 'TaxCertificate2024App',

  components: {
    MButton,
    TaxCertificate2024XmlModal,
    FixedDocumentPrintFooter,
    FixedDocumentEditorHeader,
  },

  mixins: [i18nScopeMixin],

  beforeRouteUpdate (to, from, next) {
    if (to.name === TAX_CERTIFICATE_ROUTE_NAMES.SHOW &&
        to.query.generateXml
    ) {
      this.onXmlGenerate()
    }

    next()
  },

  props: {
    clientId: { type: Number, default: null },
    documentId: { type: Number, default: null },
  },

  emits: ['notFound'],

  data () {
    return {
      certificate: new TaxCertificate2024(),
      isShowAfterEdit: false,
      tc2024XmlModalApi: null as TaxCertificate2024XmlModalAPI,
      currentUser: snakeToCamel(gon.application.current_user),
    }
  },

  computed: {
    isShow () {
      return this.$route.name.endsWith('-show')
    },

    isEditor () {
      return !this.isShow
    },
  },

  created () {
    this.setI18nScope('fixedDocument.taxCertificate2024')
    this.certificate.setValue('representedPersons', this.clientId)
    this.certificate.fetchCertificates(this.clientId)

    this.documentId
      ? this.initEditMode()
      : this.initNewMode()
  },

  methods: {
    initNewMode () {
      this.certificate.setValue('taxpayer', { id: this.clientId })
      this.certificate.certificateNumberHandler()
    },

    async initEditMode () {
      const result = await this.certificate.fetchDocument({
        id: this.documentId,
        isEditMode: true,
      })

      if (result?.errors) { return this.$emit('notFound') }
    },

    onEditClick () {
      scrollToTop()

      this.$router.push({
        name: TAX_CERTIFICATE_ROUTE_NAMES.EDIT,
        query: this.$route.query,
      })
    },

    async onDestroyClick () {
      const response = await this.certificate.destroy()

      if (response?.errors) { return }

      HistoryBrowser.goBackFrom('fixedDocumentDestroy')
    },

    async onXmlGenerate () {
      if (this.certificate.xmlGenerated) {
        const { cancel } = await MConfirm.warning(this.t$('xml.xmlGeneratedConfirmText'))
        if (cancel) { return }
      }

      this.tc2024XmlModalApi.openModal()
    },
  },
}
</script>
