import {
  DEFAULT_CURRENT_PAGE,
  DEFAULT_LIMIT,
  DEFAULT_OFFSET,
  DEFAULT_SEARCH_VALUE,
  DEFAULT_TOTAL_ITEMS,
  DEFAULT_TOTAL_PAGES,
} from '@/vue_components/store/modules/filters_base'
import { cloneDeep, omit } from 'lodash'
import { MLocalStorage } from '@/_api/_storage/MLocalStorage'
import { orUndefined } from '@/_medods_standart_library/msl'

/**
 * @typedef {{
 *   cacheKey?: string,
 *   limit?: number,
 *   sortingMap?: Object,
 *   useCacheOffset?: boolean,
 *   listItemEntity?: Class,
 *   totalListItemEntity?: Class,
 * }} MListServiceConfig
 */

/**
 * @deprecated
 */
export class MListService {
  static getDefaultList () {
    return {
      data: [],
      totalItems: DEFAULT_TOTAL_ITEMS,
      totalPages: DEFAULT_TOTAL_PAGES,
    }
  }

  filtersData = {}

  data = []

  total

  searchQuery

  offset

  limit

  currentPage

  totalPages

  totalItems

  /**
   * Флаг для отображения процесса загрузки, требуется оборачивать запрос методом this.__withLoading()
   * @type {boolean}
   */
  loading = false

  filtered = false

  /** @private */
  _loadingTimeout = null

  /**
   * @private
   * @type {Class}
   */
  _listItemEntity = null

  /**
   * @private
   * @type {Class}
   */
  _totalListItemEntity = null

  /**
   * @param {Object} initialFilters
   * @param {MPresenterBase} presenter
   * @param {IMListServiceConfig | {listItemEntity: SemdListItem}} [config] - для бизнес-костылей
   */
  constructor (initialFilters, presenter, config) {
    this.__initPrivate(initialFilters, presenter, config || {})
    this.__mergeFilters()
    this.__initPublic(config || {})
    this.__applyCorrectionsByConfig(config || {})
  }

  /**
   * Инициализация приватных полей
   * @param {Object} initialFilters
   * @param {MPresenterBase} presenter
   * @param {IMListServiceConfig} [config] - для бизнес-костылей
   * @private
   */
  __initPrivate (initialFilters, presenter, config) {
    this._listItemEntity = config?.listItemEntity
    this._totalListItemEntity = config?.totalListItemEntity
    this.__initialFilters = initialFilters
    this.__presenter = presenter
    this.__cache = new MLocalStorage(config?.cacheKey)
    this.__cachedData = this.__cache.restore() || {}
  }

  /**
   * @private
   */
  __mergeFilters () {
    const cachedFilters = omit(this.__cachedData, ['searchQuery', 'offset', 'limit'])

    this.__setFilters({ ...this.__initialFilters, ...cachedFilters })
  }

  /**
   * Инициализация публичных полей
   * @private
   * @param {IMListServiceConfig} config
   */
  __initPublic (config) {
    this.searchQuery = DEFAULT_SEARCH_VALUE
    this.offset = DEFAULT_OFFSET
    this.limit = config.limit || DEFAULT_LIMIT

    this.currentPage = DEFAULT_CURRENT_PAGE
    this.totalPages = DEFAULT_TOTAL_PAGES
    this.totalItems = DEFAULT_TOTAL_ITEMS

    this.data = []
  }

  /**
   * @param {IMListServiceConfig} config
   * @private
   */
  __applyCorrectionsByConfig (config = {}) {
    if (config.useCacheOffset) {
      this.setPage(Math.ceil(this.__cachedData.offset / (this.limit || 1)) + 1)
    }
  }

  /**
   * Установка фильтров
   * @param {Object} filters
   * @private
   */
  __setFilters (filters) {
    this.filtersData = cloneDeep(filters)
  }

  /**
   * Флаг загрузки fetchAll
   * @param {Promise<any>} promise
   * @param withBounce
   * @protected
   */
  __withLoading (promise, withBounce = true) {
    if (this._loadingTimeout) {
      this._loadingTimeout = clearTimeout(this._loadingTimeout) || null
    }

    this.loading = true

    return promise.finally(() => {
      withBounce
        ? this._loadingTimeout = setTimeout(() => { this.loading = false }, 250)
        : this.loading = false
    })
  }

  /**************************************** Геттеры ********************************************/

  /**
   * Фильтры + поиск + пагинация
   * @returns {*&{searchQuery: string, offset: number, limit: number}}
   * @deprecated - не использовать, слишком много вызовов, нет кэширования,
   * лучше собирайте фильтры в computed (смотреть ниже)
   */
  get filters () {
    return {
      ...this.filtersData,
      searchQuery: this.searchQuery,
      offset: this.offset,
      limit: this.limit,
    }
  }

  /*
     // пример: list: MListService

      computed: {
        filters () {
          return {
            ...this.list.filtersData,
            searchQuery: this.list.searchQuery,
            offset: this.list.offset,
            limit: this.list.limit,
          }
        },
      },

      watch: {
        filters () {
          this.list.fetchAll()
        },
      },
   */

  /**
   * Признак фильтрации
   * @returns {boolean}
   * @deprecated - не рекомендуется использовать, слишком много вызовов, нет кэширования
   */
  get isFiltered () {
    let isFiltered = false
    Object.keys(this.filtersData).forEach((key) => {
      if (key[0] === '_') { return }
      const filter = this.filtersData[key]

      isFiltered = isFiltered ||
        Boolean(
          (filter && filter.length) ||
          (filter && !Array.isArray(filter) && typeof filter !== 'string')
        )
    })

    return Boolean(
      this.searchQuery.length ||
      this.offset ||
      isFiltered
    )
  }

  /**************************************** Публичные Методы ********************************************/

  /**
   * Установка значения конкретного фильтра
   * @param {string} field
   * @param {any} value
   */
  setFilterValue (field, value) {
    this.filtersData[field] = value
    this.setPage()
    this.filtered = true
  }

  /**
   * Переход по номеру страницы
   * @param {number} page
   */
  setPage (page = DEFAULT_CURRENT_PAGE) {
    this.currentPage = page
    this.offset = (page - 1) * this.limit
  }

  /**
   * Обновить данные
   */
  refreshFilters () {
    this.__setFilters(this.filtersData || {})
  }

  /**
   * Сбросить фильтры, поиск и страницу
   * @param {Object | null}filters
   */
  resetFilters (filters = this.__initialFilters) {
    this.__setFilters(filters || this.filtersData)
    this.__cache.save(this.filters)
    this.filtered = false
    this.searchQuery = DEFAULT_SEARCH_VALUE
    this.setPage()
  }

  resetData () {
    this.data = []
    this.total = {}
    this.totalPages = DEFAULT_TOTAL_PAGES
    this.totalItems = DEFAULT_TOTAL_ITEMS
  }

  resetCheckboxes () {
    this.data.forEach((item) => { item.__selected = false })
  }

  setSearchQuery (searchQuery) {
    this.searchQuery = searchQuery
  }

  /**
   * Метод для ручного запуска поиска
   * @param {string} searchQuery
   * @return {Promise<void>}
   */
  async search (searchQuery) {
    if (searchQuery === this.searchQuery || (!searchQuery && !this.searchQuery)) { return }
    this.setSearchQuery(searchQuery)
    this.setPage()

    return this.fetchAll()
  }

  /**
   * Метод для ручного запуска поиска без фильтров
   *
   * @param {string} searchQuery
   * @return {Promise<void>}
   */
  async searchWithoutFilters (searchQuery) {
    const keepFilters = cloneDeep(this.filtersData)

    this.__setFilters({})

    await this.search(searchQuery)

    this.__setFilters(keepFilters)
  }

  /**
   * Метод для ручного получения страницы
   *
   * @param page {number}
   * @param forceUpdate {boolean}
   * @return {Promise<void>}
   */
  async fetchPage (page, forceUpdate = false) {
    if (!forceUpdate && page === this.currentPage && this.currentPage !== DEFAULT_CURRENT_PAGE) { return }
    this.setPage(page)

    const promise = this.fetchAll()

    if (!forceUpdate) {
      return promise
    }

    await promise

    if (this.data.length || this.currentPage === DEFAULT_CURRENT_PAGE) { return }

    return this.fetchPage(this.currentPage - 1)
  }

  /**
   * Метод для ручной установки значения фильтра и загрузкой
   *
   * @param {string} field
   * @param {any} value
   * @return {Promise<void>}
   */
  async setFilterValueWithFetchAll (field, value) {
    this.setFilterValue(field, value)

    return this.fetchAll()
  }

  async resetWithFetch () {
    this.resetFilters()

    return this.fetchAll()
  }

  async refreshWithFetch () {
    this.refreshFilters()

    return this.fetchAll()
  }

  /**
   * @return {Promise<void>}
   */
  async fetchAll (withLoading = true) {
    if (!this.__presenter) { return }

    const promise = this.__presenter.list({
      ...this.filters,
      __sorting: orUndefined(this.__sorting),
    })

    const { errors, data, total, totalItems, totalPages } =
      withLoading
        ? await this.__withLoading(promise, false)
        : await promise

    if (errors) { return }

    this.data = this._listItemEntity
      ? data.map((item) => new this._listItemEntity(item))
      : data

    this.total = this._totalListItemEntity
      ? new this._totalListItemEntity(total)
      : total

    this.totalItems = totalItems
    this.totalPages = totalPages

    if (this.searchQuery) { return }

    this.__cache.save(this.filters)
  }
}
