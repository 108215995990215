var render = function render(){var _vm=this,_c=_vm._self._c;return _c('el-dialog',{staticClass:"m-modal",class:[`m-modal_${_vm.type}`, {
    'hide-footer': _vm.useHiddenFooter,
    'remove-body-top-padding': _vm.useBodyZeroTopPadding,
    'hidden-print': _vm.useHiddenPrint,
    'm-modal_height-100': _vm.useMaxHeight
  }],attrs:{"title":_vm.dialogTitle,"visible":_vm.visible,"width":_vm.width,"show-close":false,"close-on-click-modal":_vm.closeOnClickModal,"fullscreen":_vm.fullscreen || _vm.fullscreenState,"before-close":(done) => _vm.$emit('close', done),"modal-append-to-body":_vm.modalAppendToBody,"append-to-body":_vm.appendToBody},on:{"update:visible":function($event){return _vm.$updateSync('visible', $event)}},scopedSlots:_vm._u([{key:"footer",fn:function(){return [_vm._t("footer",function(){return [_c('div',{staticClass:"footer__left"},[_vm._t("footer-left")],2),_vm._v(" "),_c('div',{staticClass:"footer__right"},[_vm._t("footer-right-action"),_vm._v(" "),_vm._t("footer-right",function(){return [_c('close-button',{attrs:{"disabled":_vm.disabled},on:{"close":_vm.close}})]})],2)]})]},proxy:true}],null,true)},[_c('div',{staticClass:"m-modal__header cut flex flex-grow-1",attrs:{"slot":"title"},slot:"title"},[_vm._t("header",function(){return [_c('span',{staticClass:"header__title"},[(_vm.modalTitleIcon)?_c('m-icon',{staticClass:"mr-5",attrs:{"icon":_vm.modalTitleIcon}}):_vm._e(),_vm._v(" "+_vm._s(_vm.dialogTitle)+"\n      ")],1)]}),_vm._v(" "),(_vm.canFullscreen)?_c('i',{staticClass:"fad pointer m-modal__fullscreen mr-10",class:{
        'fa-expand': !_vm.fullscreenState,
        'fa-compress': _vm.fullscreenState
      },on:{"click":function($event){_vm.fullscreenState = !_vm.fullscreenState}}}):_vm._e(),_vm._v(" "),(_vm.canClose)?_c('i',{staticClass:"fad fa-times pointer m-modal__close",on:{"click":_vm.close}}):_vm._e()],2),_vm._v(" "),_vm._t("default",function(){return [_vm._t("body")]})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }