<template>
  <div class="semd-form consultation-form">
    <div class="consultation-form__left-side flex-basis-50">
      <consultation-form-document-area
        :semd="semd"
        :semd-meta-data="semd.metaData"
        :is-show-mode="isShowMode"
        :panel-type="modalType"
        @registerValidator="$registerValidator($event)"
      >
        <template #state>
          <slot name="state" />
        </template>

        <template #semdSource>
          <slot name="semdSource" />
        </template>
      </consultation-form-document-area>

      <consultation-form-medical-info-area
        :semd="semd"
        :is-show-mode="isShowMode"
        :panel-type="modalType"
        @registerValidator="$registerValidator($event)"
      >
        <template #documentAttributes>
          <consultation-document-attributes
            v-bind="semd.semdsDocumentDetailsAttributes.data"
          />
        </template>
      </consultation-form-medical-info-area>
    </div>

    <div class="consultation-form__right-side flex-basis-50">
      <consultation-form-metainfo-area
        :semd="semd"
        :is-create-mode="isCreateMode"
        :is-show-mode="isShowMode"
        :panel-type="modalType"
        :disable-document-field="disableDocumentField"
        :disable-medical-policy-field="disableMedicalPolicyField"
        :use-company="useCompany"
        @registerValidator="$registerValidator($event)"
      >
        <template #paymentSource>
          <m-select-lazy
            :value="semd.metaData.nsiPaymentSource"
            :fetch-method="fetchPaymentSourceList"
            :label="t('semds.semd119.formTitles.paymentSource')"
            :disabled="isShowMode"
            :clearable="false"
            use-first-time-fetch
            filterable
            required
            validator-name="payment_source"
            @registerValidator="$registerValidator($event)"
            @change="onChangePaymentSource"
          />
        </template>

        <template #foundationDocumentType>
          <reusable-local-n-s-i-list
            :value="semd.metaData.nsiFoundationDocumentType"
            :items="foundationDocumentTypes"
            :label="t('semds.semd119.formTitles.foundationDocumentType')"
            dictionary="foundation_document_types"
            full-width
            disabled
            @registerValidator="$registerValidator($event)"
          />
        </template>

        <template #foundationDocumentDateEnd>
          <m-date-picker
            v-if="isPayServiceFoundationDocument"
            value-format="yyyy-MM-dd"
            :value="semd.metaData.foundationDocumentDateEnd"
            :label="t('document_date_end')"
            :disabled="isShowMode"
            :clearable="false"
            required
            required-when-value
            validator-name="foundation_document_date_end"
            @change="semd.setMetaDataValue('foundationDocumentDateEnd', $event)"
            @registerValidator="$registerValidator($event)"
          />
        </template>

        <template #company>
          <company-search-create
            v-if="useCompany"
            :value="semd.metaData._companyModel"
            :required="useCompany"
            :disabled="isShowMode"
            required-when-value
            validator-name="company_id"
            @change="onSetCompany"
            @onCreateCompany="onSetCompany"
            @registerValidator="$registerValidator($event)"
          />
        </template>
      </consultation-form-metainfo-area>

      <slot name="registrationArea" />
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import ConsultationFormMedicalInfoArea from '@/vue_apps/Semds/SemdModal/components/ConsultationFormMedicalInfoArea.vue'
import ConsultationFormDocumentArea from '@/vue_apps/Semds/SemdModal/components/ConsultationFormDocumentArea.vue'
import ConsultationFormMetainfoArea from '@/vue_apps/Semds/SemdModal/components/ConsultationFormMetainfoArea.vue'
import { Semd119 } from '@/vue_apps/Semds/entities/Semd119/Semd119'
import ConsultationDocumentAttributes from '@/vue_apps/Semds/SemdModal/components/ConsultationDocumentAttributes.vue'
import { MSemdPresenter } from '@/_api/MSemdApi/MSemdPresenter'
import { SEMDS_OWNER_TYPES } from '@/vue_apps/Semds/SemdModal/const/const'
import { MSemdModel } from '@/vue_apps/Semds/entities/_SemdBase/MSemdModel'
import MSelectLazy from '@/vue_present/_base/MSelectLazy/MSelectLazy.vue'
import ReusableLocalNSIList from '@/vue_present/Reuse/Lists/NSI/ReusableLocalNSIList.vue'
import {
  BY_DMS,
  BY_DOCUMENT,
  BY_OMS,
  getFoundationDocumentTypeNsiIdByPaymentSourceNsiId,
} from '@/vue_apps/Semds/SemdModal/const/getFoundationDocumentTypeNsiIdByPaymentSourceNsiId'
import { MNSIPresenter } from '@/_api/AppConfiguration/MNSIPresenter'
import MDatePicker from '@/vue_present/_base/inputs/MDatePicker/MDatePicker.vue'
import CompanySearchCreate
  from '@/vue_apps/ClientsModule/components/Company/CompanySearchCreate/CompanySearchCreate.vue'
import {
  isThirdPartyLegalEntityPaymentSource,
} from '@/vue_apps/Semds/SemdModal/const/isThirdPartyLegalEntityPaymentSource'

export default defineComponent({
  name: 'Semd119Form',

  components: {
    CompanySearchCreate,
    MDatePicker,
    ReusableLocalNSIList,
    MSelectLazy,
    ConsultationFormMetainfoArea,
    ConsultationFormDocumentArea,
    ConsultationFormMedicalInfoArea,
    ConsultationDocumentAttributes,
  },

  props: {
    semd: Semd119,
    modalType: { type: String, default: 'primary' },
    isCreateMode: { type: Boolean, default: false },
    isShowMode: { type: Boolean, default: false },
    isEditMode: { type: Boolean, default: false },
  },

  emits: [
    'registerInitForm',
    'registerValidator',
    'resetValidations',
    'removeValidator',
  ],

  data () {
    return {
      foundationDocumentTypes: [],

      disableDocumentField: false,
      disableMedicalPolicyField: false,

      useCompany: false,
    }
  },

  computed: {
    isPayServiceFoundationDocument () {
      return this.semd
        .metaData
        .nsiFoundationDocumentType
        ?.title
        .includes('платных')
    },
  },

  mounted () {
    const vm = this
    this.$emit('registerInitForm', vm.onInitForm)
  },

  methods: {
    async onInitForm ({ lastSemdId }) {
      const { data } = await new MNSIPresenter().list({ dictionary: 'foundation_document_types' })
      this.foundationDocumentTypes = data

      if (!this.semd.id && !lastSemdId) {
        this.semd.fetchLastAppointment().then()
        this.tryDetectPaymentSource(this.semd.id).then()

        return
      }

      if (lastSemdId) {
        await this.restoreLastSemdFoundationDocumentsModels()
      }

      this.disableFields(this.semd.metaData.nsiFoundationDocumentType?.nsiId)
      this.setUseCompany()
    },

    async tryDetectPaymentSource (semdId) {
      const absolutelyNewSemd = !semdId && this.semd.version === MSemdModel.DEFAULT_VERSION
      if (!absolutelyNewSemd) { return }

      const { nsiPaymentSource } =
          await new MSemdPresenter().detectPaymentSource(SEMDS_OWNER_TYPES.PROTOCOL, this.semd.owner?.id)

      this.onChangePaymentSource(nsiPaymentSource)
    },

    async restoreLastSemdFoundationDocumentsModels () {
      const lastSemdDocument = this.semd.metaData._documentModel
      const lastSemdMedicalPolicy = this.semd.metaData._medicalPolicyModel

      await this.onChangePaymentSource(this.semd.metaData.nsiPaymentSource)

      if (lastSemdDocument) { this.semd.setMetaDataValue('_documentModel', lastSemdDocument) }
      if (lastSemdMedicalPolicy) { this.semd.setMetaDataValue('_medicalPolicyModel', lastSemdMedicalPolicy) }
    },

    onChangePaymentSource (paymentSource) {
      this.$emit('removeValidator', 'foundation_document_date_end')
      this.$emit('resetValidations')
      this.clearFields()

      this.semd.setMetaDataValue('nsiPaymentSource', paymentSource)
      this.setUseCompany()

      const foundationDocumentTypeNsiId =
          getFoundationDocumentTypeNsiIdByPaymentSourceNsiId(paymentSource?.nsiId)

      this.disableFields(foundationDocumentTypeNsiId)

      const foundationDocumentType = this.foundationDocumentTypes
        .find((f) => f.nsiId === foundationDocumentTypeNsiId)

      this.onChangeFoundationDocumentType(foundationDocumentType)
    },

    disableFields (foundationDocumentTypeNsiId) {
      this.disableDocumentField =
          (foundationDocumentTypeNsiId !== BY_DOCUMENT) ||
          (this.useCompany && !this.semd.metaData._companyModel)

      this.disableMedicalPolicyField =
          foundationDocumentTypeNsiId !== BY_OMS &&
          foundationDocumentTypeNsiId !== BY_DMS
    },

    onChangeFoundationDocumentType (type) {
      this.semd.setMetaDataValue('nsiFoundationDocumentType', type || null)

      this.$nextTick(() => {
        // БЛ: требование о дате окончания действия полиса в случае,
        // если тип документа основания - документ оказания платных медицинских услуг
        if (this.isPayServiceFoundationDocument &&
            !this.semd.metaData.foundationDocumentDateEnd
        ) {
          this.semd.setMetaDataValue(
            'foundationDocumentDateEnd',
            Utils.getBaseFormattedDate(moment().endOf('year'))
          )
        }
      })
    },

    clearDocumentFields () {
      this.semd.setMetaDataValue('_documentModel', null)
      this.semd.setMetaDataValue('documentId', null)
    },

    clearMedicalPolicyFields () {
      this.semd.setMetaDataValue('_medicalPolicyModel', null)
      this.semd.setMetaDataValue('medicalPolicyId', null)
    },

    clearCompanyFields () {
      this.semd.setMetaDataValue('_companyModel', null)
      this.semd.setMetaDataValue('companyId', null)
    },

    clearFields () {
      this.clearDocumentFields()
      this.clearMedicalPolicyFields()
      this.clearCompanyFields()
    },

    fetchPaymentSourceList (params) {
      return new MSemdPresenter().fetchSemdPaymentSourceList({ ...params })
    },

    setUseCompany () {
      this.useCompany =
         isThirdPartyLegalEntityPaymentSource(this.semd.metaData?.nsiPaymentSource?.nsiId)

      if (!this.useCompany) {
        this.$emit('removeValidator', 'company_id')
      }
    },

    onSetCompany (value) {
      this.semd.setMetaDataValue('_companyModel', value)
      this.clearDocumentFields()
      this.disableDocumentField = !value
    },
  },
})
</script>
