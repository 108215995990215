import { FiscalPrinterModel } from '@/vue_apps/catalogs_root/Workplaces/classes/FiscalPrinter/FiscalPrinterModel'

export class FiscalPrinterLogic extends FiscalPrinterModel {
  dayOpenedHandler (opened: boolean) {
    if (!this.info) { return }

    const info = {
      ...this.info,
      opened,
      expired: false,
    }

    super.setValue('info', info)
  }
}
