<template>
  <div class="semd-modal">
    <slot
      name="reference"
      :open-semd="openSemd"
    >
      <semd-submit
        v-if="useSemdSubmit"
        v-loading="semdSubmitLoading"
        :is-semds-empty="isSemdsEmpty"
        :is-last-semd-outdated="isLastSemdOutdated"
        :is-new-source="isNewSource"
        :is-source-changed="isSourceChanged"
        :has-semd-type="hasSemdType"
        @click="$emit('submitClick')"
      />
    </slot>

    <m-modal
      v-show="modalVisible"
      width="min(90%, 1550px)"
      :type="modalType"
      modal-title-icon="signature"
      :dialog-title="modalTitle"
      :append-to-body="appendToBody"
      visible
      @close="closeModal"
    >
      <template #body>
        <component
          :is="currentComponent"
          v-if="currentComponent"
          v-loading="formLoading"
          :semd="semd"
          :modal-type="modalType"
          :is-create-mode="isCreateMode"
          :is-show-mode="isShowMode"
          :is-edit-mode="isEditMode"
          @registerValidator="onRegisterValidator"
          @resetValidations="resetValidations()"
          @registerInitForm="initForm = $event"
          @removeValidator="removeValidator($event)"
        >
          <template #state>
            <semd-state-block
              :state="semd.state"
              class="mb-indent-validation"
            />
          </template>

          <template #registrationArea>
            <semd-registration-area
              v-if="semd.id && isShowMode"
              v-loading="loading"
              :panel-type="modalType"
              :errors="semd.netrikaErrors"
              :doctor-signer="semd.doctorSigner?.fullName"
              :organization-signer="semd.organizationSigner?.fullName"
              :disabled="!semd.id || semd.isOutdatedVersion() || semd.isSuccessfullySent()"
              :hide-signature-buttons="!semd.id"
              :doctor-signer-label="doctorSignerLabel"
              @onSign="onSemdSign"
              @onRemoveSign="onRemoveSign"
            />
          </template>

          <template #createdUpdatedByAt>
            <semd-created-updated-by-at
              v-if="semd.id"
              :semd="semd"
            />
          </template>

          <template
            v-if="semd.owner && semd.ownerUrl"
            #semdSource
          >
            <div class="position-relative pt-6 mb-indent-validation pl-indent-small">
              <m-link
                v-tooltip="semd.owner.title"
                :text="semd.owner.title"
                :href="semd.ownerUrl"
                open-in-new-tab
              >
                {{ semd.owner.title }}
              </m-link>

              <m-label
                :label="t('semds.document')"
                disabled
              />
            </div>
          </template>
        </component>
      </template>

      <template #footer-left>
        <template v-if="!semd.isOutdatedVersion()">
          <m-button-submit
            v-if="!isShowMode"
            :mode="mode"
            :use-mode-by-route="false"
            :disabled="disabledSubmit"
            @click="onSubmit"
          />

          <m-button
            v-else
            template="edit"
            :disabled="disabledEdit"
            @click="onEditModeClick"
          />

          <m-button
            v-if="isShowMode"
            type="success"
            :disabled="canSend"
            :text="t('egisz.register')"
            icon="send"
            @click="onRegisterClick"
          />

          <m-button
            v-if="canDownloadXML"
            :text="t('downloadXML')"
            type="warning"
            icon="fileCode"
            @click="onDownloadXML()"
          />
        </template>

        <m-button-delete
          v-if="!isCreateMode && !semd.isOutdatedVersion()"
          :use-button="true"
          :disabled="disabledDelete"
          @yes="onDelete"
        />
      </template>
    </m-modal>
  </div>
</template>

<script lang="ts">
import MButton from '@/vue_present/_base/buttons/MButton/MButton.vue'
import { SEMD_TYPE, SEMD_TYPE_ENUM, SEMD_TYPE_INVERTED_ENUM } from '@/vue_apps/Semds/const/semdTypes'
import SemdStateBlock from '@/vue_apps/Semds/SemdModal/components/shared/SemdStateBlock.vue'
import SemdRegistrationArea from '@/vue_apps/Semds/SemdModal/components/shared/SemdRegistrationArea.vue'
import { SemdErrorsDialogMixin } from '@/vue_apps/Semds/mixins/SemdErrorsDialogMixin'
import { MODES } from '@/vue_apps/catalogs_root/_catalog_base/const/const'
import { getSemdSignParams } from '@/vue_apps/Semds/SemdModal/const/getSemdSignParams'
import { ENTITIES } from '@/vue_components/store/modules/egisz/ws/egisz_ws'
import { SEMD_STATE_ENUM } from '@/vue_apps/Semds/SemdModal/const/const'
import MModal from '@/vue_present/_base/MModal/MModal.vue'
import MButtonSubmit from '@/vue_present/_base/buttons/MButtonSubmit/MButtonSubmit.vue'
import MButtonDelete from '@/vue_present/_base/buttons/MButtonDelete/MButtonDelete.vue'
import { mapGetters } from 'vuex'
import { MConfirm } from '@/vue_present/_base/MConfirm/MConfirm'
import { ModalMixin } from '@/vue_present/mixins/ModalMixins/ModalMixin'
import { ValidationParentMixin } from '@/vue_present/mixins/ValidationParentMixin'
import { getInvalidSemdEntitiesDialog } from '@/vue_apps/Semds/SemdModal/const/getInvalidSemdEntitiesDialog'
import Semd119Form from '@/vue_apps/Semds/SemdModal/views/Semd119Form.vue'
import { MSemdPresenter } from '@/_api/MSemdApi/MSemdPresenter'
import { SpinnerHolder } from '@/vue_components/mixins/spinner_holder'
import { callAfterTimeout } from '@/helpers/ElementUI/callAfterTimeout'
import { getForbiddenConfig } from '@/helpers/getForbiddenConfig'
import Semd230Form from '@/vue_apps/Semds/SemdModal/views/Semd230Form.vue'
import SemdSubmit from '@/vue_apps/Semds/SemdModal/components/shared/SemdSubmit.vue'
import { SemdsUtils } from '@/vue_apps/Semds/entities/SemdsUtils'
import SemdCreatedUpdatedByAt from '@/vue_apps/Semds/SemdModal/components/shared/SemdCreatedUpdatedByAt.vue'
import { GLOBAL_HOLDER_SCOPES } from '@/_global_scripts/GLOBAL_CONSTS'
import Semd224Form from '@/vue_apps/Semds/SemdModal/views/Semd224Form.vue'
import MLink from '@/vue_present/_base/MLink/MLink.vue'
import MIcon from '@/vue_present/_base/MIcon/MIcon.vue'
import { MSemdApi } from '@/vue_apps/Semds/entities/_SemdBase/MSemdApi'
import MLabel from '@/vue_present/_base/inputs/MLabel.vue'

const notifyError = (message) => {
  Notificator.error(message.join(', '))
}

export default {
  name: 'SemdModalApp',

  components: {
    MLabel,
    MIcon,
    MLink,
    SemdCreatedUpdatedByAt,
    SemdSubmit,
    MButtonDelete,
    MButtonSubmit,
    MModal,
    SemdStateBlock,
    SemdRegistrationArea,
    MButton,
    Semd119Form,
    Semd230Form,
    Semd224Form,
  },

  mixins: [
    ModalMixin,
    SpinnerHolder,
    SemdErrorsDialogMixin,
    ValidationParentMixin,
  ],

  props: {
    semdSubmitLoading: Boolean,
    hasSemdType: { type: Boolean, required: true },
    isSourceChanged: { type: Boolean, required: true },
    isNewSource: { type: Boolean, required: true },
    isLastSemdOutdated: { type: Boolean, required: true },
    isSemdsEmpty: { type: Boolean, required: true },
    appendToBody: Boolean,
    useSemdSubmit: { type: Boolean, default: true },
  },

  emits: [
    'registerOpenSemd',
    'updateList',
    'submitClick',
    'onSemdStateUpdate',
  ],

  data () {
    return {
      semd: new MSemdApi(),
      mode: MODES.SHOW,
      formLoading: false,

      async initForm () {},
    }
  },

  computed: {
    ...mapGetters('globalCatalogs/doctorStore', {
      vxGetDoctorClinicsIds: 'vxGetDoctorClinicsIds',
    }),

    semdType () {
      return SemdsUtils.extractSemdType(this.semd.semdType)
    },

    modalTitle () {
      const title = SemdsUtils.getTitle(this.semdType)

      if (this.semd.id) {
        return `${title} № ${this.semd.id}`
      }

      return title
    },

    currentComponent () {
      return SemdsUtils.getComponent(this.semdType)
    },

    isDoctorInCurrentClinic () {
      return this.vxGetDoctorClinicsIds.includes(this.semd.clinic?.id)
    },

    isCreateMode () {
      return this.mode === MODES.CREATE
    },

    isEditMode () {
      return this.mode === MODES.EDIT
    },

    isShowMode () {
      return this.mode === MODES.SHOW
    },

    modalType () {
      switch (this.mode) {
        case MODES.EDIT: return 'warning'
        case MODES.CREATE: return 'success'
        default: return 'primary'
      }
    },

    isSafeStatus () {
      return this.semd.state === SEMD_STATE_ENUM.NEED_DOCTOR_SIGNATURE ||
          this.semd.state === SEMD_STATE_ENUM.NEED_CLINIC_SIGNATURE ||
          this.semd.state === SEMD_STATE_ENUM.READY_TO_BE_SENT ||
          this.semd.state === SEMD_STATE_ENUM.SENDING_ERROR
    },

    disabledSubmit () {
      if (this.mode !== MODES.EDIT) { return false }

      if (!this.$security.canManageEgisz) {
        return getForbiddenConfig()
      }

      const disabled = !this.isSafeStatus

      return {
        disabled,
        tooltip: disabled && t('semds.errors.editDisabledTooltip'),
      }
    },

    disabledEdit () {
      if (!this.$security.canManageEgisz) {
        return getForbiddenConfig()
      }

      const disabled = !this.isSafeStatus

      return {
        disabled,
        tooltip: disabled && t('semds.errors.editDisabledTooltip'),
      }
    },

    disabledDelete () {
      if (!this.$security.canManageEgisz) {
        return getForbiddenConfig()
      }

      const disabled = !this.isSafeStatus

      return {
        disabled,
        tooltip: disabled && t('semds.errors.deleteDisabledTooltip'),
      }
    },

    /**
     * @return {{ tooltip: string, disabled: boolean } | string}
     */
    canSend () {
      if (!this.isDoctorInCurrentClinic) {
        return {
          disabled: true,
          tooltip: t('semds.semdSignModal.cantSendFromClinicTooltip'),
        }
      }

      if (!this.$security.canManageEgisz) {
        return 'Egisz'
      }

      const disabled = !(this.semd.state === SEMD_STATE_ENUM.READY_TO_BE_SENT ||
          this.semd.state === SEMD_STATE_ENUM.SENDING_ERROR)

      return {
        disabled,
        tooltip: disabled && t('semds.semdSignModal.canSendTooltip'),
      }
    },

    canDownloadXML () {
      return Utils.isSystemUser() && this.semd.xmlFileId
    },

    doctorSignerLabel () {
      if (this.semdType === SEMD_TYPE_ENUM[SEMD_TYPE.ELMK_MEDICAL_ASSESSMENT]) {
        return t('semds.semd230.doctorSigner')
      }

      return t('semds.semd119.formTitles.doctorSignature')
    },
  },

  created () {
    this.$emit('registerOpenSemd', this.openSemd.bind(this))

    Services.wsSubscriptions.egisz.connect(({ action, data, meta }) => {
      if (!this.semd.id) { return }
      if (data?.id !== this.semd.id) { return }
      if (meta?.object !== 'Semd') { return }

      switch (action) {
        case ENTITIES.ALL_SIGNATURES_REMOVED:
          return this.semd.setState(SEMD_STATE_ENUM.NEED_DOCTOR_SIGNATURE)
        case ENTITIES.ORGANIZATION_SIGNATURES_REMOVED:
          return this.semd.setState(SEMD_STATE_ENUM.NEED_CLINIC_SIGNATURE)
        case ENTITIES.SENDING_ERROR:
          return this.onSendingError(data)
        case ENTITIES.SUCCESSFULLY_SENT:
          return this.semd.setState(SEMD_STATE_ENUM.SUCCESSFULLY_SENT)
        case ENTITIES.REGISTERED_IN_EGISZ:
          return this.semd.setState(SEMD_STATE_ENUM.REGISTERED_IN_EGISZ)
      }
    })
  },

  methods: {
    /**
     * @param {number} id
     * @param {{
     *   semdType: number,
     *   semdsDocumentDetailsAttributes: {
     *     id: number,
     *     data: Record<string, string | ICatalog>
     *   }
     * }} source
     * @param {number} lastSemdId
     * @return {Promise<void>}
     */
    async openSemd ({ id, source, lastSemdId }) {
      if (!SEMD_TYPE_INVERTED_ENUM[source.semdType]) {
        return console.debug('Неизвестный тип СЭМД', source.semdType)
      }

      this.semd =
          SemdsUtils.getSemd({ id, semdType: source.semdType })

      await this.withSpinner(lastSemdId
        ? this.__fillSemdByLastSemd(lastSemdId)
        : this.__fillSemdBySource(source), 'formLoading')

      if (await this.__hasInvalids(source) !== false) { return }

      await this.openModal({ lastSemdId })
    },

    async __hasInvalids (source) {
      if (!source?.id) { return false }

      const invalids = SemdsUtils.validate(this.semd)
      const customValidationMessageOrIsValid = this.semd.getCustomValidationMessageOrIsValid()
      if (!invalids.length && typeof customValidationMessageOrIsValid === 'boolean') { return false }

      return this.showInvalidEntitiesDialog(
        source.semdType,
        invalids,
        customValidationMessageOrIsValid
      )
    },

    async __fillSemdBySource (source) {
      this.mode = this.semd.id
        ? MODES.SHOW
        : MODES.CREATE

      if (this.semd.id) {
        return await this.semd.load()
      }

      this.semd.fillBySource(source)
    },

    async __fillSemdByLastSemd (lastSemdId) {
      const lastSemd = await new MSemdPresenter().fetch(lastSemdId)
      if (lastSemd?.errors) { return }

      this.semd.fillByLastSemd(lastSemd)
      this.mode = MODES.CREATE
    },

    async openModal (params = {}) {
      this.modalVisible = true
      await this.initForm(params)
    },

    closeModal () {
      this.mode = MODES.SHOW
      this.modalVisible = false

      callAfterTimeout(() => {
        this.semd = new MSemdApi()
      })
    },

    async onSemdSign (signature) {
      const { signMethod, setValueMethod } = getSemdSignParams(signature.holderScope)

      const result = await this.withSpinner(this.semd.presenter[signMethod]({
        id: this.semd.id,
        semdType: SEMD_TYPE_INVERTED_ENUM[this.semd.semdType],
        signature,
      }))

      if (result?.errors) {
        if (typeof result.errors !== 'object') { return }

        return this.showErrorsDialog(result.errors)
      }

      this.semd[setValueMethod](result)
      this.$emit('updateList')
      this.$emit('onSemdStateUpdate', { id: this.semd.id, state: this.semd.state })
    },

    async onRemoveSign ({ holderScope }) {
      const result = await this.withSpinner(new MSemdPresenter().removeSignature(this.semd.id, holderScope))

      if (result?.errors) { return }

      this.$emit('updateList')

      this.semd.setValue('organizationSigner', null)
      this.semd.setState(SEMD_STATE_ENUM.NEED_CLINIC_SIGNATURE)

      if (holderScope === GLOBAL_HOLDER_SCOPES.ORGANIZATION) { return }

      this.semd.setValue('doctorSigner', null)
      this.semd.setState(SEMD_STATE_ENUM.NEED_DOCTOR_SIGNATURE)
    },

    onSendingError ({ error, timestamp }) {
      this.semd.setState(SEMD_STATE_ENUM.SENDING_ERROR)
      this.semd.appendError(error, timestamp)
    },

    async onRegisterClick () {
      this.semd.setState(SEMD_STATE_ENUM.ADDED_TO_QUEUE)
      await this.semd.register()
    },

    async onSubmit () {
      if (this.hasErrors()) { return }

      const response = await this.semd.submit()
      if (response?.errors) { return this.errorsHandler(response.errors) }

      this.mode = MODES.SHOW

      this.$emit('updateList')
    },

    async onDelete () {
      const response = await this.semd.destroy()
      if (response?.errors) { return }

      this.modalVisible = false
      this.$emit('updateList')
    },

    async onEditModeClick () {
      if (this.semd.state !== SEMD_STATE_ENUM.NEED_DOCTOR_SIGNATURE) {
        const { cancel } = await MConfirm.warning(t('semds.confirms.semdOldSigned'))
        if (cancel) { return }
      }

      this.mode = MODES.EDIT

      this.semd.setValue('doctorSigner', null)
      this.semd.setValue('organizationSigner', null)
      this.semd.setState(SEMD_STATE_ENUM.NEED_DOCTOR_SIGNATURE)
    },

    onDownloadXML () {
      const filename = `${this.modalTitle}-${this.semd.xmlFileId}`
      downloadFile(Routes.api_internal_semds_download_xml_path(this.semd.xmlFileId), filename)
    },

    /**  @param {IApiError} errors */
    errorsHandler (errors) {
      if (errors?.protocol_id) {
        notifyError(errors.protocol_id)
      }

      if (errors?.owner_id) {
        notifyError(errors.owner_id)
      }

      if (errors?.client_id) {
        notifyError(errors.client_id)
      }
    },

    showInvalidEntitiesDialog (semdType, invalids = [], customInvalidMessage: string | boolean) {
      const systemSemdType = SEMD_TYPE_INVERTED_ENUM[semdType]

      const invalidEntitiesTitles = invalids
        .map((invalid) => t(invalid, { scope: `semds.entities.${systemSemdType}` }))

      if (typeof customInvalidMessage === 'string') {
        invalidEntitiesTitles.push(customInvalidMessage)
      }

      return getInvalidSemdEntitiesDialog(
        invalidEntitiesTitles,
        t(systemSemdType, { scope: 'semds.semdType' })
      )
    },
  },
}
</script>
