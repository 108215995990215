<template>
  <m-collapse
    class="clinical-guideline__collapse"
    :active-names.sync="activeNames"
    parent-control
  >
    <!-- Метаинформация -->
    <clinical-guideline-collapse-item-meta-information
      :clinical-guideline="clinicalGuideline"
      :active-names="activeNames"
    />

    <!-- Анамнез -->
    <clinical-guideline-collapse-item-anamnesis
      :clinical-guideline="clinicalGuideline"
      :active-names="activeNames"
    />

    <!-- Диагностика -->
    <clinical-guideline-collapse-item-entry
      v-if="clinicalGuidelineEntriesDiagnostics"
      :active-names="activeNames"
      :collapse-item-id="CLINICAL_GUIDELINE_COLLAPSE_ITEM.DIAGNOSTICS"
      :clinical-guideline-entries="clinicalGuidelineEntriesDiagnostics"
    />

    <!-- Лечение -->
    <clinical-guideline-collapse-item-entry
      v-if="clinicalGuidelineEntriesTreatment"
      :active-names="activeNames"
      :collapse-item-id="CLINICAL_GUIDELINE_COLLAPSE_ITEM.TREATMENT"
      :clinical-guideline-entries="clinicalGuidelineEntriesTreatment"
    />

    <!-- Медицинская реабилитация -->
    <clinical-guideline-collapse-item-entry
      v-if="clinicalGuidelineEntriesRehabilitation"
      :active-names="activeNames"
      :collapse-item-id="CLINICAL_GUIDELINE_COLLAPSE_ITEM.MEDICAL_REHABILITATION"
      :clinical-guideline-entries="clinicalGuidelineEntriesRehabilitation"
    />

    <!-- Профилактика и диспансерное наблюдение -->
    <clinical-guideline-collapse-item-entry
      v-if="clinicalGuidelineEntriesPrevention"
      :active-names="activeNames"
      :collapse-item-id="CLINICAL_GUIDELINE_COLLAPSE_ITEM.PREVENTION_AND_DISPENSARY_OBSERVATION"
      :clinical-guideline-entries="clinicalGuidelineEntriesPrevention"
    />
  </m-collapse>
</template>
<script lang="ts">
import { defineComponent } from 'vue'
import MCollapse from '@/vue_present/_base/MCollapse/MCollapse.vue'
import {
  ClinicalGuidelineApi,
} from '@/vue_apps/catalogs_root/ClinicalGuidelinesCatalog/classes/ClinicalGuideline/ClinicalGuidelineApi'
import { CLINICAL_GUIDELINE_COLLAPSE_ITEM } from '@/vue_apps/ClinicalGuidelines/consts/clinicalGuidelineCollapseItems'
import ClinicalGuidelineCollapseItemMetaInformation
  from '@/vue_apps/ClinicalGuidelines/components/ClinicalGuidelineCollapseItemMetaInformation.vue'
import ClinicalGuidelineCollapseItemAnamnesis
  from '@/vue_apps/ClinicalGuidelines/components/ClinicalGuidelineCollapseItemAnamnesis.vue'
import {
  CLINICAL_GUIDELINE_ENTRY_STEP,
} from '@/vue_apps/catalogs_root/ClinicalGuidelinesCatalog/consts/clinicalGuidelineEntryStep'
import ClinicalGuidelineCollapseItemEntry
  from '@/vue_apps/ClinicalGuidelines/components/ClinicalGuidelineCollapseItemEntry.vue'
import {
  ClinicalGuidelineEntryItem,
} from '@/vue_apps/catalogs_root/ClinicalGuidelinesCatalog/classes/ClinicalGuidelineEntryItem/ClinicalGuidelineEntryItem'

export default defineComponent({
  name: 'ClinicalGuidelineCollapse',

  components: {
    ClinicalGuidelineCollapseItemEntry,
    MCollapse,
    ClinicalGuidelineCollapseItemMetaInformation,
    ClinicalGuidelineCollapseItemAnamnesis,
  },

  props: {
    clinicalGuideline: { type: ClinicalGuidelineApi, required: true },
  },

  data () {
    return {
      CLINICAL_GUIDELINE_COLLAPSE_ITEM,
      CLINICAL_GUIDELINE_ENTRY_STEP,
      activeNames: [
        CLINICAL_GUIDELINE_COLLAPSE_ITEM.DIAGNOSTICS,
      ],
    }
  },

  computed: {
    clinicalGuidelineEntriesByStep () {
      return Object.groupBy(
        this.clinicalGuideline.clinicalGuidelineEntries.getArr(),
        ({ step }: ClinicalGuidelineEntryItem) => step.id
      )
    },

    clinicalGuidelineEntriesDiagnostics () {
      return this.clinicalGuidelineEntriesByStep[CLINICAL_GUIDELINE_ENTRY_STEP.DIAGNOSTICS]
    },

    clinicalGuidelineEntriesTreatment () {
      return this.clinicalGuidelineEntriesByStep[CLINICAL_GUIDELINE_ENTRY_STEP.TREATMENT]
    },

    clinicalGuidelineEntriesRehabilitation () {
      return this.clinicalGuidelineEntriesByStep[CLINICAL_GUIDELINE_ENTRY_STEP.REHABILITATION]
    },

    clinicalGuidelineEntriesPrevention () {
      return this.clinicalGuidelineEntriesByStep[CLINICAL_GUIDELINE_ENTRY_STEP.PREVENTION]
    },
  },
})
</script>
