import { MListServiceItem } from '@/_api/_requests/MListServiceItem'
import { MTableCell } from '@/vue_present/_base/Tables/MTableGenerator/MTableCell'
import { findSemdState, SEMD_STATE_ENUM } from '@/vue_apps/Semds/SemdModal/const/const.js'
import { ESemdStates } from '@/vue_apps/Semds/declarations/ESemdStates'

interface ISemdListItemData {
  id: number
  title: string
  state: ESemdStates
  signed: boolean
}

export class SemdListItem extends MListServiceItem {
  id: number

  title: MTableCell

  state: MTableCell

  rawStatus: ESemdStates

  constructor (data: ISemdListItemData) {
    super()

    this.id = data.id

    this.title = new MTableCell(data.title)
      .addTooltip(true)
      .addCut()

    this.setState(data.state)
  }

  setState (state: ESemdStates) {
    this.rawStatus = state

    const iconObj = findSemdState(state)
    this.state = new MTableCell()
      .addTypeIcon(iconObj.icon)
      .addClasses(iconObj.color)
      .addTooltip(iconObj.title)
  }

  isRegisteredInEgisz () {
    return this.rawStatus === SEMD_STATE_ENUM.REGISTERED_IN_EGISZ
  }

  isSuccessFullySent () {
    return this.rawStatus === SEMD_STATE_ENUM.SUCCESSFULLY_SENT
  }

  isOutdatedVersion () {
    return this.rawStatus === SEMD_STATE_ENUM.OUTDATED_VERSION
  }

  isSigned () {
    return this.rawStatus !== SEMD_STATE_ENUM.NEED_DOCTOR_SIGNATURE
  }
}
