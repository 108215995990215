import { MemoRequest } from '@/lib/transport/MemoRequest'

const memoRequest = new MemoRequest()

/**
 * Делает запрос с помощью $.ajax и возвращает объект запроса и промис,
 * который завершится при завершении запроса.
 * Может быть полезно, если нужно прервать какой-то запрос, но при этом работать
 * с его результатом через интерфейс промисов.
 * Можно сконвертировать данные от сервера с помощью метода toClientDataAdapter.
 *
 * @param {JQuery.AjaxSettings | Object} options
 * @param {Function} [toClientDataAdapter]
 * @return {{promise: Promise<any>, ajax: JQuery.jqXHR}}
 */
export const request = (options, toClientDataAdapter) => {
  memoRequest.abort(options.url)

  const result = {}
  result.promise = new Promise((resolve, reject) => {
    result.ajax = $.ajax({
      ...options,
      success (data, textStatus, jqXHR) {
        if (toClientDataAdapter) {
          return resolve(
            toClientDataAdapter(data),
            textStatus,
            jqXHR
          )
        }

        return resolve(data, textStatus, jqXHR)
      },
      error (err) {
        /*
        * при запросе с dataType: 'json'
        * падаем в ошибку со статусом 200,
        * если ответ пустой (responseJSON)
        * Укажите правильный dataType обязательно
        * Если необходимо, добавьте
        * accepts: {
            xml: 'text/xml',
            json: 'application/json',
          },
        */
        if (err.status >= 200 && err.status < 300) { return resolve() }

        return reject(err)
      },
      complete () { memoRequest.remove(options.url) },
    })

    memoRequest.save(options.url, result.ajax)
  })

  return result
}

export const fakeRequest = (response, time = 200) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(response)
    }, time)
  })
}
