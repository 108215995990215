<template>
  <m-drop-down
    :hide-on-click="false"
    trigger="click"
    @itemClick="onClickItem"
  >
    <m-button
      size="mini"
      type="dark"
      :text="t('print_areas')"
      icon="print"
    />

    <template #dropdown>
      <el-dropdown-menu>
        <el-dropdown-item
          :command="PrintAreaWholeDocument.WHOLE_DOCUMENT_ID"
          :disabled="protocolPrintAreas.wholeDocument.disabled"
        >
          <div class="flex gap-indent-small align-items-center">
            <m-checkbox
              :value="protocolPrintAreas.wholeDocument.checked"
              :disabled="protocolPrintAreas.wholeDocument.disabled"
              @input="onClickItem(protocolPrintAreas.wholeDocument.id)"
            />{{ protocolPrintAreas.wholeDocument.title }}
          </div>
        </el-dropdown-item>

        <el-dropdown-item
          v-for="area in protocolPrintAreas.printAreas"
          :key="area.id"
          :command="area.id"
        >
          <div class="flex gap-indent-small align-items-center">
            <m-checkbox
              :value="area.checked"
              :disabled="area.disabled"
              @input="onClickItem(area.id)"
            />{{ area.title }}
          </div>
        </el-dropdown-item>

        <el-dropdown-item :command="ProtocolPrintAreas.ALL_PRINT_AREAS_ID">
          {{ t('selectAllPrintAreas') }}
        </el-dropdown-item>
      </el-dropdown-menu>
    </template>
  </m-drop-down>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import MCheckbox from '@/vue_present/_base/inputs/MCheckbox/MCheckbox.vue'
import MDropDown from '@/vue_present/_base/MDropDown/MDropDown.vue'
import MButton from '@/vue_present/_base/buttons/MButton/MButton.vue'
import { PrintAreas } from '@/vue_apps/ProtocolsApp/entities/printAreas/PrintAreas'
import { TPrintAreaId } from '@/vue_apps/ProtocolsApp/entities/printAreas/IPrintArea'
import { PrintAreaWholeDocument } from '@/vue_apps/ProtocolsApp/entities/printAreas/PrintAreaWholeDocument'
import htmlHelper from '@/plugins/dynamic_forms/helpers/htmlHelper'

export default defineComponent({
  name: 'PrintAreasView',

  components: {
    MButton,
    MDropDown,
    MCheckbox,
  },

  props: {
    protocolPrintAreas: {
      type: Object as PropType<PrintAreas>,
      required: true,
    },
  },

  data () {
    return {
      ProtocolPrintAreas: PrintAreas,
      PrintAreaWholeDocument,
    }
  },

  methods: {
    onClickItem (id: TPrintAreaId) {
      this.protocolPrintAreas.execute(id)
      this.$nextTick(() => {
        htmlHelper.clearHtml()
      })
    },
  },
})
</script>
