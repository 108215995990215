<template>
  <div class="payment-fields-refund">
    <m-form-show class="mb-indent-mid">
      <!-- Сумма возврата -->
      <m-form-show-row
        v-if="payment.distributor.refundable"
        :label="t('refunding_sum')"
      >
        <template #value>
          <span class="bold orange-dark">
            {{ $filters.currency(payment.distributor.refundable.totalPaid) }}
          </span>
        </template>
      </m-form-show-row>
    </m-form-show>

    <template v-if="payment.distributor.initialKind === PAYMENT_KINDS.CASH_OUT">
      <!-- Способ возврата -->
      <m-buttons-group
        :value="payment.distributor.refundTo"
        :items="paymentRefundRefundMethodItems"
        :label="t('refund_method')"
        radio
        @change="payment.distributor.setValue('refundTo', $event)"
      />

      <!-- Сумма -->
      <m-input
        class="payment-fields__field"
        type="number"
        :label="t('sum')"
        :value="payment.distributor.totalPaid"
        :max="totalPaidMaxValue"
        validator-name="total_paid"
        @registerValidator="$registerValidator($event)"
        @input="payment.distributor.setValue('totalPaid', $event)"
      />
    </template>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import { PaymentApi } from '@/vue_apps/FinanceModule/FinanceModuleIndex/classes/Payment/PaymentApi'
import {
  PaymentDistributorRefundLogic,
} from '@/vue_apps/FinanceModule/FinanceModuleIndex/classes/Payment/PaymentDistributorRefundLogic'
import { PAYMENT_KINDS } from '@/vue_apps/FinanceModule/FinanceModuleIndex/consts/paymentKinds'
import MInput from '@/vue_present/_base/inputs/MInput/MInput.vue'
import MFormShow from '@/vue_present/_base/MFormShow/MFormShow.vue'
import MFormShowRow from '@/vue_present/_base/MFormShow/MFormShowRow.vue'
import MButtonsGroup from '@/vue_present/_base/buttons/MButtonsGroup/MButtonsGroup.vue'
import {
  paymentRefundRefundMethodItems,
} from '@/vue_apps/FinanceModule/FinanceModuleIndex/consts/paymentRefundRefundMethodItems'
import { i18nScopeMixin } from '@/vue_present/mixins/i18nScopeMixin'
import { ValidationParentMixin } from '@/vue_present/mixins/ValidationParentMixin'

export default defineComponent({
  name: 'PaymentFieldsRefund',

  components: {
    MButtonsGroup,
    MFormShowRow,
    MFormShow,
    MInput,
  },

  mixins: [i18nScopeMixin, ValidationParentMixin],

  props: {
    payment: { type: Object as PropType<PaymentApi<PaymentDistributorRefundLogic>>, required: true },
  },

  data () {
    return {
      PAYMENT_KINDS,
      paymentRefundRefundMethodItems,
    }
  },

  computed: {
    totalPaidMaxValue () {
      return this.payment.distributor.initialKind === PAYMENT_KINDS.CASH_OUT
        ? this.payment.payer.finance
        : Infinity
    },
  },

  created () {
    this.setI18nScope('finance')
  },
})
</script>
