import { applicationConf } from '../../configuration/config'
import { IEntityConfig } from '@/plugins/dynamic_forms/declarations/IEntityConfig'
import { IEntityAction } from '@/plugins/dynamic_forms/declarations/IEntityAction'
import { IReduxStore } from '@/plugins/dynamic_forms/declarations/IReduxStore'
import Container from '@/plugins/dynamic_forms/_core/container/Container'

class Entity {
  //STATIC METHODS
  static readonly defaultHtml = String.fromCharCode(160)

  static buildTemplate (data, options) {
    return `<span data-id=${data.id} data-name="${data.name}" data-type="${options.dataType}" class="${options.class} mceNonEditable t-element">${data.name}</span>`
  }

  //create html component entity
  static createElement (data, options) {
    const template = this.buildTemplate(data, options)
    tinymce.activeEditor.insertContent(template)
  }

  id: number

  element: HTMLElement

  type: string

  options: IEntityConfig

  actions: IEntityAction

  container?: Container

  store?: IReduxStore

  constructor (id, element, options, container) {
    this.id = parseInt(id)
    this.element = element
    this.type = options.dataType
    this.options = options
    this.actions = options.actions

    this.container = container
    this.store = this.container.get('store')
  }

  /**
   * :void
   * update element
   * @deprecated
   */
  update () {
    const newValue = this.formatHtml()

    if (!newValue && Number(newValue) !== 0) {
      return false
    }

    this.__updateStore(newValue)
  }

  setValue (newValue) {
    this.element.innerText = newValue
    this.__updateStore(newValue)
  }

  /**
   * operation reverse update
   */
  updateElement (data) {
    this.element.setAttribute('data-id', data.id)
    this.element.setAttribute('data-name', data.name)
    this.elementHtml = data.name
  }

  formatHtml () {
    return this.elementHtml.replace('&nbsp;', '')
  }

  setEvents () {
    return false
  }

  /**
   * show entity value on frontentd
   */
  showValue () {
    this.elementHtml = this.value
  }

  /**
   * @return boolean
   * check entity on ident
   */
  isIdent (element) {
    return this.element === element
  }

  set elementHtml (html) {
    // check html
    this.element.innerHTML = html
  }

  get elementHtml () {
    return this.element.innerHTML
  }

  get value () {
    const data = this.data as Record<string, any>

    const value = (
      data.defaultValue ||
      data.value ||
      Entity.defaultHtml
    )

    return this.formatValue(value)
  }

  /**
   * check value on empty ent format this
   */
  formatValue (value) {
    if (value && value.length === 1 && value.charCodeAt(0) === 65279) {
      value = Entity.defaultHtml
    }

    return value
  }

  /**
   * check have entity context menu
   */
  get contexted () {
    let contexted = false
    applicationConf.listClass.split(',').forEach((className) => {
      // @ts-ignore
      if ([...this.element.classList].contains(className)) {
        contexted = true
      }
    })

    return contexted
  }

  // eslint-disable-next-line
  get isManualInput() {
    return false
  }

  // eslint-disable-next-line
  get saveCollbak() {
    return false
  }

  //switch on tab key
  // eslint-disable-next-line
  get switch() {
    return true
  }

  get data (): Record<string, any> | boolean {
    if (!this.store) {
      return false
    }
    let data = false
    data = this.store.getState()[this.type].find(
      (item) => parseInt(item.id) === this.id
    )

    return data
  }

  /**
   * abstract method
   */
  afterCreate () { }

  __updateStore (newValue) {
    this.store.dispatch({
      type: this.actions.UPDATE,
      payload: {
        id: this.id,
        value: newValue,
      },
    })
  }
}
export default Entity
