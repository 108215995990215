import {
  TaxCertificate2024XmlLogic,
} from '@/vue_apps/Documents/FixedDocuments/_documents/TaxCertificate2024/classes/TaxCertificate2024Xml/TaxCertificate2024XmlLogic'
import { Api } from '@/_api/decorators/api/Api'
import { ICRUDReturn } from '@/_api/decorators/api/interfaces/ICRUDReturn'
import { IApiErrors } from '@/_declarations/IApiErrors'
import { Callback } from '@/_api/decorators/callback'
import { MRequestError } from '@/_api/_requests/MRequestError'
import { MRequestNotification } from '@/_api/_requests/MRequestNotification'

export class TaxCertificate2024XmlAPI extends TaxCertificate2024XmlLogic {
  @Callback('extractXmlData')
  @Api.post(Routes.xml_file_api_internal_documents_certificate_payments_path, {
    accepts: {
      xml: 'text/xml',
      json: 'application/json',
    },
    dataType: 'text',
    resultType: 'ajax',
  })
  generate () {
    return {
      payload: {
        certificatePaymentId: this.certificatePayment.id,
        clinicIfnsCode: +this.clinicIfnsCode,
        clientIfnsCode: +this.clientIfnsCode,
        signerId: this.signer?.id,
        mcdGuid: this.mcdGuid,
        require_mcd: this.mcdRequired,
      },
    } as ICRUDReturn as Promise<unknown | IApiErrors>
  }

  extractXmlData (jqXHR) {
    const notification = new MRequestNotification(
      'extractXmlData',
      'fetchMessage',
      'xml'
    )

    jqXHR.then((data) => {
      this.saveFileToClient(
        data,
        jqXHR.getResponseHeader('content-disposition')
          .replace(/.*filename="([^"]+\.xml)".*/i, '$1')
          .replace('.xml', '')
      )
    })
      .fail(MRequestError.onRequest(notification))
  }
}
