import { ItemBase } from '@/_api/_classes/ItemBase'
import { IMeasureUnit } from '@/_declarations/IMeasureUnit'
import { EMeasureUnitKinds } from '@/_declarations/enums/EMeasureUnitKinds'

export class MeasureUnitModel extends ItemBase implements IMeasureUnit {
  protected _permit = [
    'id',
    'title',
    'shortTitle',
    'kind',
  ]

  id: number = null

  title: string = null

  shortTitle: string = null

  kind: EMeasureUnitKinds = EMeasureUnitKinds.other
}
