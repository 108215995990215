import { PAYMENT_INCOME_FIELDS } from '@/vue_apps/FinanceModule/FinanceModuleIndex/consts/paymentIncomeFields'
import { PAYMENT_KINDS } from '@/vue_apps/FinanceModule/FinanceModuleIndex/consts/paymentKinds'
import { PAYMENT_DISTRIBUTION } from '@/vue_apps/FinanceModule/FinanceModuleIndex/consts/paymentDistribution'
import { PaymentEntryItem } from '@/vue_apps/FinanceModule/FinanceModuleIndex/classes/PaymentEntryItem'
import {
  PaymentDistributorLogicBase,
} from '@/vue_apps/FinanceModule/FinanceModuleIndex/classes/Payment/PaymentDistributorLogicBase'
import { PAYMENT_RECALCULATE_EVENT } from '@/vue_apps/FinanceModule/FinanceModuleIndex/consts/paymentRecalculateEvent'

export class PaymentDistributorFundLogic extends PaymentDistributorLogicBase {
  setValue (key: string, value: unknown) {
    super.setValue(key, value)

    switch (key) {
      case 'initialKind': return this.initialKindTrigger(value as PAYMENT_KINDS)
      case 'distribution': return this.distributionTrigger(value as PAYMENT_DISTRIBUTION)
      case 'byCash': return this.byCashInput()
    }

    if (this.isInputField(key)) {
      this.recalculate()
    }
  }

  insertUnpaidSum (propName: string) {
    const isByBalanceProp = propName === 'byBalance'
    const isFullPaidByBalance = this.byBalance === this.payer.finance

    if (isByBalanceProp && isFullPaidByBalance) {
      return
    }

    this.resetFields()

    if (!isByBalanceProp) {
      return super.setValue(propName, this.totalRequired)
    }

    // Процесс снятия денег с лицевого счёта (стрелочкой)

    const unpaidSum = Utils.toMoney(this.totalRequired - this.payer.finance)
    unpaidSum > 0
      ? super.setValue(propName, this.payer.finance)
      : super.setValue(propName, this.totalRequired)
  }

  /**
   * БЛ: обнуление сдачи, при нажатии на перенос на лицевой счёт
   */
  transferToBalance () {
    const balanceCalculatedSum = Utils.toMoney(this.surrender + this.deposit)
    const newTransferSum = Utils.toMoney(this.transferSum + this.surrender)

    super.setValue('deposit', balanceCalculatedSum)
    super.setValue('notDistributed', balanceCalculatedSum)
    super.setValue('transferSum', newTransferSum)
    super.setValue('surrender', 0)

    this.recalculate()
  }

  addMoneyToEntry (entry: PaymentEntryItem) {
    const withdrawal = Math.min(this.notDistributed, entry.unpaidSum)
    entry.setSum(withdrawal)

    const newNotDistributed = Utils.toMoney(this.notDistributed - withdrawal)
    super.setValue('notDistributed', newNotDistributed)

    super.setValue('recalculateEvent', PAYMENT_RECALCULATE_EVENT.MANUAL_DISTRIBUTION)
    this.recalculate()
  }

  cancelMoneyFromEntry (entry: PaymentEntryItem) {
    const newNotDistributed = Utils.toMoney(entry.sum + this.notDistributed)
    super.setValue('notDistributed', newNotDistributed)

    entry.setSum(0)

    super.setValue('recalculateEvent', PAYMENT_RECALCULATE_EVENT.MANUAL_DISTRIBUTION)
    this.recalculate()
  }

  recalculate () {
    this.recalculateIncome()
    this.distribute() // Перерасчёт распределения денег в энтрях
    this.recalculateKind() // Перерасчётать тип платежа взависимости от перемещения денег
    this.recalculateSurrender() // Перерасчёт сдачи

    super.setValue('deposit', this.notDistributed)
    const newTotalIncome = Utils.toMoney(this.totalIncome - this.surrender)
    super.setValue('totalIncome', newTotalIncome)

    this.recalculateHiddenMembers() // Перерасчёт распределения денег в энтрях комплексов

    if (!this.recalculateEvent) return
    super.setValue('recalculateEvent', null)
  }

  private initialKindTrigger (kind: PAYMENT_KINDS) {
    super.setValue('kind', kind)

    this.paymentEntries
      .forEach((entry) => { entry.setKind(kind) })
  }

  private byCashInput () {
    const isPaymentKindCashIn = this.kind === PAYMENT_KINDS.CASH_IN
    if (isPaymentKindCashIn) { return this.recalculate() }

    this.resetTransfer()
    this.recalculate()
  }

  /**
   * Перерасчёт входящих денег из полей
   */
  private recalculateIncome () {
    super.setValue('totalIncome', 0)
    super.setValue('totalPaid', 0)
    super.setValue('byCredit', 0)

    PAYMENT_INCOME_FIELDS.forEach((field) => {
      const totalIncome = Utils.toMoney(this.totalIncome + this[field])
      super.setValue('totalIncome', totalIncome)
    })

    const totalPaid = Utils.toMoney(this.totalIncome + this.byBalance)
    super.setValue('totalPaid', totalPaid)

    this.recalculateCredit()
  }

  private recalculateCredit () {
    const enoughMoney = this.totalPaid >= this.finalSum
    const isCreditPayment = this.kind === PAYMENT_KINDS.ORDER_SUBSEQUENT

    if (enoughMoney || isCreditPayment) return

    const newByCredit = Utils.toMoney(this.totalRequired - this.totalPaid)
    super.setValue('byCredit', newByCredit)
  }

  private distributionTrigger (value: PAYMENT_DISTRIBUTION) {
    this.distribute(value)
    this.recalculate()
  }

  private distribute (value: PAYMENT_DISTRIBUTION = this.distribution) {
    super.setValue('notDistributed', this.totalPaid)

    if (!this.paymentEntries.length) { return }

    const isAuto = value === PAYMENT_DISTRIBUTION.AUTO_DISTRIBUTION
    this.paymentEntries.forEach((entry) => {
      entry.setActionVisibility(!isAuto)

      isAuto
        ? this.autoDistribute(entry)
        : this.manualDistribute(entry)
    })
  }

  private autoDistribute (entry: PaymentEntryItem) {
    const withdrawalSum = this.notDistributed > entry.unpaidSum
      ? entry.unpaidSum
      : this.notDistributed

    const notDistributed = Utils.toMoney(this.notDistributed - withdrawalSum)
    super.setValue('notDistributed', notDistributed)

    entry.setKind(this.initialKind, withdrawalSum)
    entry.setSum(withdrawalSum)
  }

  private manualDistribute (entry: PaymentEntryItem) {
    entry.setKind(this.initialKind)

    if (this.recalculateEvent !== PAYMENT_RECALCULATE_EVENT.MANUAL_DISTRIBUTION) {
      entry.setSum(0)
    }

    const notDistributed = Utils.toMoney(this.notDistributed - entry.sum)
    super.setValue('notDistributed', notDistributed)

    if (!entry.unpaidSum) {
      entry.setActionVisibility(false)
    }
  }

  private recalculateHiddenMembers () {
    let sumToDistribute = this.totalPaid

    this.paymentEntries.forEach((entry) => {
      entry.members.forEach((member) => {
        const withdrawalSum = sumToDistribute > member.unpaidSum
          ? member.unpaidSum
          : sumToDistribute

        sumToDistribute = Utils.toMoney(sumToDistribute - withdrawalSum)

        member.kind = PaymentEntryItem.getKind(this.initialKind, withdrawalSum, member.unpaidSum)
        member.sum = withdrawalSum
      })
    })
  }

  private recalculateKind () {
    if (this.initialKind !== PAYMENT_KINDS.ORDER_INITIAL) { return }

    const needToPay = Utils.toMoney(this.totalPaid - this.totalRequired)
    const isFullPaid = !this.byCredit &&
      this.totalRequired <= this.totalPaid &&
      this.notDistributed === needToPay
    if (isFullPaid) {
      return super.setValue('kind', PAYMENT_KINDS.ORDER_FULL)
    }

    const isZeroPaid = this.byCredit > 0 && !this.totalPaid && !this.notDistributed
    if (isZeroPaid) {
      return super.setValue('kind', PAYMENT_KINDS.ORDER_IN_CREDIT)
    }

    super.setValue('kind', PAYMENT_KINDS.ORDER_INITIAL)
  }

  private recalculateSurrender () {
    if (this.kind === PAYMENT_KINDS.CASH_IN) return

    const requiredFromCash = Utils.toMoney(this.totalRequired - this.byCard - this.byCashless - this.byBalance)
    const toSurrender = Utils.toMoney(this.byCash - requiredFromCash - this.transferSum)

    if (!(toSurrender > 0 && requiredFromCash > 0)) {
      return super.setValue('surrender', 0)
    }

    super.setValue('surrender', toSurrender)

    this.recalculateNotDistributed()
    this.recalculateTotalPaid()
  }

  private recalculateNotDistributed () {
    const newNotDistributed = Utils.toMoney(this.notDistributed - this.surrender)

    if (this.notDistributed) {
      super.setValue('notDistributed', newNotDistributed)
    }

    if (this.notDistributed < 0) {
      super.setValue('notDistributed', 0)
    }
  }

  private recalculateTotalPaid () {
    const newTotalPaid =
      Utils.toMoney(
        this.byCash +
        this.byCard +
        this.byCashless +
        this.byBalance -
        this.surrender
      )

    super.setValue('totalPaid', newTotalPaid)
  }

  private resetFields () {
    super.setValue('byCash', 0)
    super.setValue('byCard', 0)
    super.setValue('byCashless', 0)
    super.setValue('byBalance', 0)
  }
}
