import { TemplateRecordModel } from '@/vue_apps/catalogs_root/TemplateRecords/entities/TemplateRecordModel'
import { ProtocolApi } from '@/vue_apps/ProtocolsApp/entities/ProtocolApi'
import { SEMD_TYPE_INVERTED_ENUM } from '@/vue_apps/Semds/const/semdTypes'

export class TemplateRecordLogic extends TemplateRecordModel {
  fillByProtocol (protocol: Partial<ProtocolApi<any>>) {
    if (protocol.semdType) {
      this.setValue('semdTemplateDocumentDetailAttributes', {
        semdType: SEMD_TYPE_INVERTED_ENUM[protocol.semdType],
      })
    }

    this.setValue('printEntryInfo', protocol.printEntryInfo)
    this.setValue('printEntryTitle', protocol.printEntryTitle)
    this.setValue('printClinicHeader', protocol.printClinicHeader)
    this.setValue('printDoctorSignature', protocol.printDoctorSignature)

    this.setValue('templateData', protocol.templateData)
    this.setValue('templateHtml', protocol.templateHtml)
  }
}
