import Entity from '@/plugins/dynamic_forms/entities/items/Entity'

export class EntityWithDefaultHtml extends Entity {
  constructor (id, element, options, container = false) {
    super(id, element, options, container)

    this.element.oncontextmenu = (e) => {
      e.preventDefault()
      Services.pubSub.emit('ProtocolPopoverConnector:Activate', this)
    }
  }
}
