import { reportErrorText } from '@/_api/_requests/helpers'
import { flatObjectRecursive } from '@/helpers/flatObjectRecursive'

export class MRequestError {
  /**
   * @param {Error} error
   * @param {String} location
   * @param {String} message
   * @param {String} entity
   * @private
   */
  static __defaultNotify (error, { location, message, entity }) {
    const errorText = reportErrorText(message, entity)
    Utils.reportError(location, errorText)(error)
  }

  /**
   * @param {MRequestNotification} notification
   * @param toClientAdapter
   * @return {(function(*): (Promise<{errors: *}>))|*}
   */
  static onRequest (notification, toClientAdapter = (data) => data) {
    /**
     * @param {Error} error
     * @param {boolean} useRawErrors default: false
     * @return {Promise<unknown>}
     */
    return (error, useRawErrors = false) => {
      notification && this.__defaultNotify(error, notification)

      if (!error?.responseJSON) {
        return Promise.resolve({ errors: error.responseText })
      }

      const errors = error.responseJSON?.errors || error.responseJSON || {}

      const rawOrFlatObjectRecursive = useRawErrors
        ? errors
        : flatObjectRecursive(errors)

      return Promise.resolve({ errors: toClientAdapter(rawOrFlatObjectRecursive) || {} })
    }
  }

  /**
   * @param {*|null} defaultValue
   * @param {MRequestNotification} notification
   * @return {function(*): Promise<unknown|null>}
   */
  static withDefault (defaultValue = null, notification) {
    /**
     * @param {Error} error
     * @return {Promise<unknown>}
     */
    return (error) => {
      this.__defaultNotify(error, notification)

      return Promise.resolve(defaultValue)
    }
  }
}
