import { IWorkplaceGetInfo } from '@/vue_apps/catalogs_root/Workplaces/interfaces/IWorkplaceGetInfo'
import { IWSMessage } from '@/_declarations/IWSMessage'
import { TradeAcquiringService } from '@/services/TradeAcquiringService'
import { orNull } from '@/_declarations/orNull'

export class FiscalPrinterModuleService {
  static iconSelector = '.we-fiscalPrinter__icon'

  static updateIcon (opened: orNull<boolean>, removeDisabledClass: boolean = false) {
    const element = document.querySelector(FiscalPrinterModuleService.iconSelector)
    if (!element) return

    FiscalPrinterModuleService.updateIconTooltip()

    if (removeDisabledClass) {
      element.classList.remove('we-disabled')
    }

    element.classList.remove('we-fiscalPrinter_open-day', 'we-fiscalPrinter_close-day')

    if (opened === null) return

    opened
      ? element.classList.add('we-fiscalPrinter_open-day')
      : element.classList.add('we-fiscalPrinter_close-day')
  }

  static updateIconTooltip () {
    const element: Element & { _tippy: any } = document.querySelector(FiscalPrinterModuleService.iconSelector)
    if (!element) return

    const currentTooltip = element._tippy.props.content

    element._tippy.props.content = TradeAcquiringService.isFiscalModuleDisabledWithActiveAcquiring()
      ? t('fiscal.terminal')
      : t('fiscal_printer')

    if (currentTooltip === element._tippy.props.content) return
    Utils.updateTooltips()
  }

  subscribeToDayOpen () {
    Services.pubSub.subscribe('workplace.open_day', () => {
      FiscalPrinterModuleService.updateIcon(true)
    })

    Services.pubSub.subscribe('fiscalPrinterIsDayOpened', (opened: boolean) => {
      FiscalPrinterModuleService.updateIcon(opened)
    })

    Services.pubSub.subscribe('broadcast:fiscalPrinterIsDayOpened', (opened: boolean) => {
      FiscalPrinterModuleService.updateIcon(opened)
    })
  }

  subscribeToGetInfo () {
    Services.pubSub.subscribe('workplace.get_info', (message: IWSMessage<IWorkplaceGetInfo>) => {
      if (message.data.workplace_id !== gon.application.current_user?.workplace_id) return
      FiscalPrinterModuleService.updateIcon(message.data.info.opened)
    })
  }

  sendGetInfo () {
    const workplaceId = gon.application.current_user?.workplace_id
    if (!workplaceId) return

    Services.workplace.sendMessage('get_info', { workplace_id: workplaceId, data: { as_dictionary: true } })
  }
}
