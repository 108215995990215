<template>
  <div class="m-form-show__value">
    <slot>
      {{ value }}
    </slot>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'MFormShowValue',

  props: {
    value: { type: [String, Number], default: '' },
  },
})
</script>
