import { MTableSchema } from '@/vue_present/_base/Tables/MTableGenerator/MTableSchema'
import { MSiSchema } from '@/vue_present/_base/Tables/MSiGenerator/MSiSchema'
import { MTableHeader } from '@/vue_present/_base/Tables/MTableGenerator/MTableHeader'
import { tScoped } from '@/helpers/i18nHelpers'

const ts = tScoped('finance')

export const getAcquiringSchema = () =>
  new MSiSchema()
    .addPagination()
    .addTableSchema(
      new MTableSchema()
        .addHeaders({
          idCell: new MTableHeader('№').addFixedWidth('100px'),
          paymentTypeCell: new MTableHeader(ts('payMethod')).addFixedWidth('140px'),
          operationTypeCell: new MTableHeader(ts('operationType')).addFixedWidth('135px'),
          createdAtCell: new MTableHeader(t('common.date')).addFixedWidth('145px'),
          statusCell: new MTableHeader(t('status')).addFixedWidth('120px'),
          amountCell: new MTableHeader(t('sum')).addFixedWidth('200px'),
          paymentCell: new MTableHeader(t('payment')).addFixedWidth('90px'),
          userCell: new MTableHeader(t('employee')).addFixedWidth('200px'),
          completedByCell: new MTableHeader(`${t('employee')}*`)
            .addFixedWidth('200px')
            .addTooltip(t('tradeAcquiring.completedByFullName')),
          workplaceCell: new MTableHeader(t('workplace')).addFixedWidth('140px'),
        })
        .addFooter()
    )
