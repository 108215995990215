import { tScoped } from '@/helpers/i18nHelpers'
import {
  TRADE_ACQUIRING_CONFIGURATION_BANK,
} from '@/vue_apps/app_configuration/tradeAcquiring/consts/tradeAcquiringConfigurationBank'

const ts = tScoped('tradeAcquiring.banks')

export const TRADE_ACQUIRING_CONFIGURATION_NO_BANK = 'null'

export const getTradeAcquiringBankItem = (item: TRADE_ACQUIRING_CONFIGURATION_BANK) => ({
  id: item,
  title: ts(item),
})

export const TRADE_ACQUIRING_CONFIGURATION_ITEMS = Object.values(TRADE_ACQUIRING_CONFIGURATION_BANK).map(getTradeAcquiringBankItem)
