<template>
  <m-modal
    :visible.sync="modalVisibility"
    class="contacts-messages"
    width="500px"
  >
    <template #header>
      <span>
        <m-icon
          class="mr-5"
          :icon="modalTitleIcon"
          :use-brand="useBrand"
        />
        {{ modalTitle }}
      </span>
    </template>

    <template #body>
      <component
        :is="messageProvider"
        v-if="modalVisibility"
        v-bind="messageData"
        @setSendCallback="sendCallBack = $event"
        @close="hide"
      />
    </template>

    <template #footer-right>
      <div class="gap-indent-small">
        <m-button
          template="send"
          :disabled="loading"
          @click="onSend"
        />

        <m-button
          template="close"
          @click="hide"
        />
      </div>
    </template>
  </m-modal>
</template>

<script lang="ts">
import MModal from '@/vue_present/_base/MModal/MModal.vue'
import { MESSAGE_PROVIDERS } from '@/vue_apps/Contacts/const'
import MIcon from '@/vue_present/_base/MIcon/MIcon.vue'
import MButton from '@/vue_present/_base/buttons/MButton/MButton.vue'
import sms from '@/vue_apps/Contacts/ContactsMessages/forms/Sms.vue'
import whatsapp from '@/vue_apps/Contacts/ContactsMessages/forms/Whatsapp.vue'
import { MClientPresenter } from '@/_api/MClient/MClientPresenter'
import { IApiErrors } from '@/_declarations/IApiErrors'
import { SpinnerHolder } from '@/vue_components/mixins/spinner_holder'

export default {
  name: 'ContactsMessages',

  components: { MButton, MIcon, MModal, sms, whatsapp },

  mixins: [SpinnerHolder],

  data () {
    return {
      modalVisibility: false,

      /** @type {TMessageProvider} */
      messageProvider: MESSAGE_PROVIDERS.SMS,

      messageData: {
        clientId: null,
        phoneNumber: null,
        clientFullName: null,
      },

      sendCallBack: null as () => Promise<void | IApiErrors>,
    }
  },

  computed: {
    modalTitle () {
      switch (this.messageProvider) {
        case 'whatsapp': return t('sms.custom_whatsapp_sending')
        default: return t('sms.custom_message_sending')
      }
    },

    modalTitleIcon () {
      switch (this.messageProvider) {
        case 'whatsapp': return 'whatsapp'
        default: return 'sms'
      }
    },

    useBrand () {
      switch (this.messageProvider) {
        case 'whatsapp': return true
        default: return false
      }
    },
  },

  created () {
    this.subscribeToEvents()
  },

  methods: {
    subscribeToEvents () {
      this.$pubSub.subscribe('CUSTOM_MESSAGE_EDITOR.SHOW', this.onShow)
    },

    show () {
      this.modalVisibility = true
    },

    hide () {
      this.modalVisibility = false
    },

    /** @param {TMessageModalData} data */
    onShow (data) {
      this.messageData.clientId = data.clientId
      this.messageData.phoneNumber = data.phoneNumber
      this.messageProvider = data.messageProvider

      const lettersPattern = /[a-zA-Z]/
      const phoneIsMasked = lettersPattern.test(data.phoneNumber)

      if (phoneIsMasked && !this.messageData.clientId) {
        return Utils.reportError('ContactsMessages::onShow', t('whatsapp.messageSendingErrorModal'))()
      }

      this.show()

      if (data.clientId) {
        new MClientPresenter().fetchFullName(data.clientId)
          .then((fullName) => { this.messageData.clientFullName = fullName })
      }
    },

    async onSend () {
      if (!this.sendCallBack) { return }
      await this.withSpinnerSimple(this.sendCallBack())
    },
  },
}
</script>
