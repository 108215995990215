<template>
  <div class="payment-fields-fund">
    <!-- Наличными -->
    <m-input
      class="payment-fields__field"
      :value="payment.distributor.byCash"
      type="number"
      :min="0"
      :label="t('by_cash')"
      @input="payment.distributor.setValue('byCash', $event)"
    >
      <template #prefix>
        <m-icon
          icon="payment"
          color="grey"
        />
      </template>

      <template #suffix>
        <m-icon
          v-if="paymentAfterIcon"
          class="pointer"
          v-bind="paymentAfterIcon"
          @click="payment.distributor.insertUnpaidSum('byCash')"
        />
      </template>
    </m-input>

    <div class="payment-fields__card">
      <!-- Картой -->
      <m-input
        class="payment-fields__field"
        :value="payment.distributor.byCard"
        type="number"
        :min="0"
        :max="payment.distributor.totalRequired || undefined"
        :label="t('by_card')"
        @input="payment.distributor.setValue('byCard', $event)"
      >
        <template #prefix>
          <m-icon
            icon="billed"
            color="grey"
          />
        </template>

        <template #suffix>
          <m-icon
            v-if="paymentAfterIcon"
            class="pointer"
            v-bind="paymentAfterIcon"
            @click="payment.distributor.insertUnpaidSum('byCard')"
          />
        </template>
      </m-input>

      <acquiring-modal
        :operation-type="ACQUIRING_OPERATION_TYPE.PAY"
        :payment="payment"
      />
    </div>

    <!-- Перевод на расчетный счет -->
    <m-input
      class="payment-fields__field"
      :value="payment.distributor.byCashless"
      type="number"
      :min="0"
      :label="t('by_cashless')"
      @input="payment.distributor.setValue('byCashless', $event)"
    >
      <template #prefix>
        <m-icon
          icon="university"
          color="grey"
        />
      </template>

      <template #suffix>
        <m-icon
          v-if="paymentAfterIcon"
          class="pointer"
          v-bind="paymentAfterIcon"
          @click="payment.distributor.insertUnpaidSum('byCashless')"
        />
      </template>
    </m-input>

    <template v-if="payment.distributor.initialKind !== PAYMENT_KINDS.CASH_IN">
      <!-- С лицевого счета -->
      <m-input
        v-tooltip="byBalanceTooltip"
        class="payment-fields__field"
        :value="payment.distributor.byBalance"
        type="number"
        :max="byBalanceMaxValue"
        :min="0"
        :disabled="!byBalanceMaxValue"
        :label="t('by_balance')"
        @input="payment.distributor.setValue('byBalance', $event)"
      >
        <template #prefix>
          <m-icon
            icon="cashOut"
            color="grey"
          />
        </template>

        <template #suffix>
          <m-icon
            v-if="paymentAfterIcon && byBalanceMaxValue"
            class="pointer"
            v-bind="paymentAfterIcon"
            @click="payment.distributor.insertUnpaidSum('byBalance')"
          />
        </template>
      </m-input>

      <!-- Кредитом -->
      <m-input
        v-if="payment.distributor.initialKind === PAYMENT_KINDS.ORDER_INITIAL"
        class="payment-fields__field"
        :value="payment.distributor.byCredit"
        type="number"
        disabled
        :label="t('by_credit')"
      >
        <template #prefix>
          <m-icon
            icon="orderInitial"
            color="grey"
          />
        </template>
      </m-input>

      <!-- Распределение суммы между услугами -->
      <m-switch
        class="mb-indent-validation"
        :value="payment.distributor.distribution"
        :active-text="t$('autoDistribution')"
        :active-value="PAYMENT_DISTRIBUTION.AUTO_DISTRIBUTION"
        :inactive-value="PAYMENT_DISTRIBUTION.MANUAL_DISTRIBUTION"
        @change="payment.distributor.setValue('distribution', $event)"
      />

      <!-- Сдача -->
      <m-input
        class="payment-fields__field"
        :value="payment.distributor.surrender"
        type="number"
        :label="t('surrender')"
        disabled
      >
        <template #prefix>
          <div class="flex flex-column align-items-center">
            <m-icon
              class="no-margin-important"
              icon="longArrowAltLeft"
              color="grey"
              size="13px"
            />

            <m-icon
              class="no-margin-important"
              icon="orderInitial"
              color="grey"
              size="13px"
            />
          </div>
        </template>
      </m-input>
    </template>

    <!-- На лицевой счет -->
    <m-input
      class="payment-fields__field"
      :value="payment.distributor.deposit"
      type="number"
      :label="t('to_balance')"
      disabled
      validator-name="deposit"
      @registerValidator="$registerValidator($event)"
    >
      <template #prefix>
        <m-icon
          icon="patient-plus"
          color="grey"
        />
      </template>

      <template #suffix>
        <m-icon
          v-if="paymentAfterIcon"
          class="pointer"
          v-bind="{
            ...paymentAfterIcon,
            tooltip: t('surrender_to_deposit')
          }"
          @click="onDeposit"
        />
      </template>
    </m-input>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import { PaymentApi } from '@/vue_apps/FinanceModule/FinanceModuleIndex/classes/Payment/PaymentApi'
import {
  PaymentDistributorFundLogic,
} from '@/vue_apps/FinanceModule/FinanceModuleIndex/classes/Payment/PaymentDistributorFundLogic'
import MInput from '@/vue_present/_base/inputs/MInput/MInput.vue'
import MIcon from '@/vue_present/_base/MIcon/MIcon.vue'
import MSwitch from '@/vue_present/_base/inputs/MSwitch/MSwitch.vue'
import { MConfirm } from '@/vue_present/_base/MConfirm/MConfirm'
import { paymentAfterIcon } from '@/vue_apps/FinanceModule/FinanceModuleIndex/consts/paymentAfterIcon'
import { PAYMENT_KINDS } from '@/vue_apps/FinanceModule/FinanceModuleIndex/consts/paymentKinds'
import { PAYMENT_DISTRIBUTION } from '@/vue_apps/FinanceModule/FinanceModuleIndex/consts/paymentDistribution'
import { i18nScopeMixin } from '@/vue_present/mixins/i18nScopeMixin'
import { ValidationParentMixin } from '@/vue_present/mixins/ValidationParentMixin'
import {
  ACQUIRING_OPERATION_TYPE,
} from '@/vue_apps/FinanceModule/FinanceModuleIndex/consts/Acquiring/AcquiringOperationType'
import AcquiringModal from '@/vue_apps/FinanceModule/FinanceModuleIndex/components/Payment/Acquiring/AcquiringModal.vue'

export default defineComponent({
  name: 'PaymentFieldsFund',

  components: {
    AcquiringModal,
    MSwitch,
    MIcon,
    MInput,
  },

  mixins: [i18nScopeMixin, ValidationParentMixin],

  props: {
    payment: { type: Object as PropType<PaymentApi<PaymentDistributorFundLogic>>, required: true },
  },

  data () {
    return {
      PAYMENT_KINDS,
      PAYMENT_DISTRIBUTION,
      ACQUIRING_OPERATION_TYPE,
    }
  },

  computed: {
    paymentAfterIcon () {
      return this.payment.destinationOrder?.id
        ? paymentAfterIcon
        : null
    },

    byBalanceMaxValue () {
      return Math.min(this.payment.payer.finance, this.payment.destinationOrder.finalSum)
    },

    byBalanceTooltip () {
      return !this.byBalanceMaxValue && this.t$('byBalanceNoFunds')
    },
  },

  created () {
    this.setI18nScope('finance')
  },

  methods: {
    async onDeposit () {
      if (!this.payment.distributor.surrender) { return }

      if (this.payment.distributor.deposit > 0) {
        Services.ui
          .notificator()
          .error(t('simultaneous_finance_operations_are_impossible'), t('error'), 0)

        return
      }

      const { cancel } = await MConfirm.warning(t('transfer_surrender_confirm'))
      if (cancel) { return }
      this.payment.distributor.transferToBalance()
    },
  },
})
</script>
