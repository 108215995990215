import { IListStrategy } from '@/_api/_requests/MListServiceApi/strategies/_base/IListStrategy'
import { IList } from '@/_declarations/IList'
import { MListServiceApi } from '@/_api/_requests/MListServiceApi/MListServiceApi'
import { IMListServiceApiConfig } from '@/_declarations/IMListServiceConfigApi'
import { Callback } from '@/_api/decorators/callback'
import { Api } from '@/_api/decorators/api/Api'
import { ICRUDReturn } from '@/_api/decorators/api/interfaces/ICRUDReturn'
import { TAdapter } from '@/_api/decorators/api/interfaces/IAdapter'

export class MCommonListStrategy<DataItem, FiltersMap> implements IListStrategy<DataItem, FiltersMap> {
  context: MListServiceApi<DataItem, FiltersMap>

  config: IMListServiceApiConfig<DataItem, FiltersMap>

  toServer: TAdapter

  toClient: TAdapter

  src: string

  constructor (
    context: MListServiceApi<DataItem, FiltersMap>,
    config: IMListServiceApiConfig<DataItem, FiltersMap>
  ) {
    this.context = context
    this.config = config
    this.src = config.src
    this.toServer = config?.adapter?.toServer || ((data: unknown) => data)
    this.toClient = config?.adapter?.toClient || ((data: unknown) => data)
  }

  @Callback('fillData')
  @Api.clientAdapter('toClient')
  @Api.list('src')
  @Api.serverAdapter('toServer')
  fetchAll (payload: Record<string, any>): Promise<void> {
    return { payload } as ICRUDReturn as Promise<void>
  }

  fillData (response: IList<DataItem, DataItem>) {
    this.context
      .setData(response.data.map((item) => new this.config.DataItemEntity(item)))

    if (response.totals && this.config.TotalsItemEntity) {
      this.context.setTotals(new this.config.TotalsItemEntity(response.totals))
    }

    this.context.setTotalItems(response.totalItems)
    this.context.setTotalPages(response.totalPages)

    if (!this.context.searchQuery) {
      this.context.getCache().save(this.context.filters)
    }

    this.context.stopLoading()
  }
}
