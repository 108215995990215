<template>
  <m-modal
    :visible="visible"
    :dialog-title="t('tax_rate_changing')"
    class="update-tax-rates-modal"
    :class="{ 'update-tax-rates-modal_datepicker-visible': isAnswerMeansUpdate }"
    :can-close="withEntryTypesUpdate"
    width="756px"
    @close="close"
  >
    <template #body>
      <div class="update-tax-rates-modal__body">
        <reusable-tax-rates-list
          v-if="withEntryTypesUpdate"
          v-model="taxRate"
          use-custom-result="simple"
          :m-fixed-height="false"
        />

        <div>
          <m-icon
            v-if="!withEntryTypesUpdate"
            icon="warning"
            color="warning"
            class="update-tax-rates-modal__warning-icon"
          />

          {{ questionText }}

          <m-select
            v-model="syncAnswer"
            class="update-tax-rates-modal__answer-select"
            full-width
            :items="ANSWERS"
            :clearable="false"
            :m-fixed-height="false"
          />
        </div>

        <m-date-picker
          v-show="isAnswerMeansUpdate"
          v-model="datePeriod"
          type="daterange"
          :label="t('common.date')"
          :full-width="false"
          value-format="yyyy-MM-dd"
          required
          validator-name="datePeriod"
          @registerValidator="onRegisterValidator"
        />
      </div>
    </template>

    <template #footer-left>
      <m-button
        template="save"
        @click="save"
      />
    </template>

    <template #footer-right>
      <m-button
        v-if="withEntryTypesUpdate"
        template="close"
        :text="t('cancel')"
        @click="close"
      />

      <div v-else />
    </template>
  </m-modal>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import MModal from '@/vue_present/_base/MModal/MModal.vue'
import MSelect from '@/vue_present/_base/inputs/MSelect/MSelect.vue'
import MDatePicker from '@/vue_present/_base/inputs/MDatePicker/MDatePicker.vue'
import MButton from '@/vue_present/_base/buttons/MButton/MButton.vue'
import MIcon from '@/vue_present/_base/MIcon/MIcon.vue'
import ReusableTaxRatesList from '@/vue_present/Reuse/Lists/TaxRates/ReusableTaxRatesList.vue'
import {
  SYNC_ENTRIES_TAX_RATE_ANSWERS as ANSWERS,
  SYNC_ENTRIES_TAX_RATE_ANSWERS_IDS as ANSWERS_IDS,
  TAX_RATES,
} from '../const/const'
import { ValidationParentMixin } from '@/vue_present/mixins/ValidationParentMixin'
import { DateHelpers } from '@/helpers/DateHelpers'
import { getSyncEntriesTaxRateDefaultAnswer as getDefaultAnswer } from '../const/helpers'
import { MTaxRatesApi } from '@/_api/MTaxRates/MTaxRatesApi'
import {
  IOpenUpdateTaxRatesModalParams as IOpenModalParams,
  IUpdateTaxRatesModalApi as IModalApi,
} from '../UpdateTaxRatesModal/interfaces'
import { TTaxRatesApiUpdateResponse } from 'src/_api/MTaxRates/interfaces'

export default defineComponent({
  name: 'UpdateTaxRatesModal',

  components: {
    MModal,
    MSelect,
    MDatePicker,
    MButton,
    MIcon,
    ReusableTaxRatesList,
  },

  mixins: [ValidationParentMixin],

  props: {
    useByClinicEntriesUpdate: Boolean,
    withEntryTypesUpdate: Boolean,
  },

  emits: ['registerApi'],

  data () {
    return {
      visible: false,
      syncAnswer: getDefaultAnswer(),
      datePeriod: DateHelpers.getCurrentMonthStartFromPeriod(),
      entryTypeIds: [] as number[],
      entryTypeCategoryIds: [] as number[],
      taxRate: null as TAX_RATES,

      taxRatesApi: new MTaxRatesApi(),

      ANSWERS,
    }
  },

  computed: {
    isAnswerMeansUpdate (): boolean {
      return this.syncAnswer.id !== ANSWERS_IDS.NO
    },

    questionText (): string {
      return this.withEntryTypesUpdate
        ? t('sync_entries_tax_rate_question_short')
        : t('sync_entries_tax_rate_question')
    },
  },

  mounted () {
    this.$emit('registerApi', {
      openModal: this.open,
    } as IModalApi)
  },

  methods: {
    reset ({
      entryTypeIds = [],
      entryTypeCategoryIds = [],
      taxRate = null,
    }: IOpenModalParams) {
      this.entryTypeIds = entryTypeIds
      this.entryTypeCategoryIds = entryTypeCategoryIds
      this.taxRate = taxRate
      this.syncAnswer = getDefaultAnswer()
      this.datePeriod = DateHelpers.getCurrentMonthStartFromPeriod()
    },

    open (data: IOpenModalParams) {
      this.reset(data)
      this.visible = true
    },

    close () {
      this.visible = false
    },

    updateEntries (): Promise<TTaxRatesApiUpdateResponse> {
      const [dateStart, dateEnd] = this.datePeriod

      const baseRequestArgs = {
        isWithoutOrder: this.syncAnswer.id === ANSWERS_IDS.YES_WITHOUT_ORDER,
        taxScheme: this.taxRate,
        dateStart,
        dateEnd,
      }

      return this.useByClinicEntriesUpdate
        ? this.taxRatesApi.updateForEntriesByClinic({
          clinicId: gon.application.current_clinic.id,
          ...baseRequestArgs,
        })
        : this.taxRatesApi.updateForEntries({
          entryTypeIds: this.entryTypeIds,
          entryTypeCategoryIds: this.entryTypeCategoryIds,
          ...baseRequestArgs,
        })
    },

    updateEntryTypes (): Promise<TTaxRatesApiUpdateResponse> {
      return this.taxRatesApi.updateForEntryTypes({
        entryTypeIds: this.entryTypeIds,
        entryTypeCategoryIds: this.entryTypeCategoryIds,
        taxScheme: this.taxRate,
      })
    },

    async save () {
      if (this.hasErrors()) { return }

      if (this.withEntryTypesUpdate) {
        const res = await this.updateEntryTypes()
        if (res?.errors) { return }
      }

      if (this.isAnswerMeansUpdate) {
        const res = await this.updateEntries()
        if (res?.errors) { return }
      }

      this.close()
    },
  },
})
</script>
