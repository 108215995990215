<template>
  <div
    v-loading="loading"
    class="workplace-list-wrapper gap-indent-small"
  >
    <simple-select
      :value="value"
      :validation-messages="validationMessages"
      :items="workplaceList"
      clearable
      @change="$emit('change', $event)"
    />

    <button
      v-tooltip="t('reload')"
      type="button"
      class="btn btn-sm btn-primary btn-with-icon max-h-30"
      @click="loadWorkplaceList"
    >
      <i class="fad fa-sync mr-0" />
    </button>
  </div>
</template>

<script>
import SimpleSelect from '@/vue_components/common/select/base_select/SimpleSelect'
import { SpinnerHolder } from '@/vue_components/mixins/spinner_holder'
import { workplaceEndpoint } from '@/api_entities/workplaces/workplace_endpoint'
import { DEFAULT_LIMIT } from '@/vue_components/store/modules/filters_base'

export default {
  name: 'WorkplaceList',
  components: { SimpleSelect },

  mixins: [SpinnerHolder],

  model: {
    prop: 'value',
    event: 'change',
  },

  props: {
    value: {
      type: Number,
      default: null,
    },

    validationMessages: {
      type: Array,
      default: () => [],
    },
  },

  data () {
    return {
      workplaceList: [],
    }
  },

  methods: {
    loadWorkplaceList () {
      const promise = workplaceEndpoint.getAllConnected({ limit: DEFAULT_LIMIT * 2 })
        .then((data) => { this.workplaceList = data?.data ?? [] })
        .catch(Utils.reportError('WorkplaceList:loadWorkplaceList()'))

      this.withSpinner(promise)
    },
  },
}
</script>
