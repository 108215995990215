import { ElMessageBoxOptions } from 'element-ui/types/message-box'
import { MMessageBoxBase } from '@/vue_present/_base/MMessageBox/MMessageBoxBase'

export class MAlert {
  static success (message: string, config: ElMessageBoxOptions = {}) {
    return this.show(message, {
      type: 'success',
      ...config,
    })
  }

  static info (message: string, config: ElMessageBoxOptions = {}) {
    return this.show(message, {
      type: 'info',
      ...config,
    })
  }

  static warning (message: string, config: ElMessageBoxOptions = {}) {
    return this.show(message, {
      type: 'warning',
      ...config,
    })
  }

  static error (message: string, config: ElMessageBoxOptions = {}) {
    return this.show(message, {
      type: 'error',
      ...config,
    })
  }

  static show (message: string, config: ElMessageBoxOptions = {}) {
    return MMessageBoxBase.show('alert', message, config)
  }
}
