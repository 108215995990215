import { ItemBase } from '@/_api/_classes/ItemBase'
import {
  ClinicalGuidelineImportItem,
} from '@/vue_apps/catalogs_root/ClinicalGuidelinesCatalog/classes/ClinicalGuidelineImport/ClinicalGuidelineImportItem'

export class ClinicalGuidelineImportModel extends ItemBase {
  clinicalGuidelines: Record<number, ClinicalGuidelineImportItem> = {}

  replaceExisting: boolean = false
}
