import {
  SemdNsiDictionary,
} from '@/vue_apps/ProtocolsApp/_SemdProtocolEditor/_SemdEntities/SemdNsiDictionary/SemdNsiDictionary'
import {
  CONSULTATION_ENTITY_NAMES,
} from '@/vue_apps/ProtocolsApp/_SemdProtocolEditor/entities/Semd119/semd119EntityNames'
import { SemdArea } from '@/vue_apps/ProtocolsApp/_SemdProtocolEditor/_SemdEntities/_semdBaseEntities/SemdArea'
import { NSI_DICTIONARIES } from '@/vue_present/Reuse/Lists/NSI/nsiDictionaries'
import {
  SemdListSeparator,
} from '@/vue_apps/ProtocolsApp/_SemdProtocolEditor/_SemdEntities/_semdBaseEntities/SemdListSeparator'

const $t = (key) => t(key, { scope: 'semds.entities.consultation_protocol' })

export const getSemd119Entities = () => {
  return [
    new SemdListSeparator({ title: t('semds.layout.lists'), id: 'layout.lists' }),

    new SemdNsiDictionary({
      id: CONSULTATION_ENTITY_NAMES.NSI_MKB_DIAGNOSIS_GROUP,
      title: $t(CONSULTATION_ENTITY_NAMES.NSI_MKB_DIAGNOSIS_GROUP),
      nsiDictionary: NSI_DICTIONARIES.MKB10,
    }),

    new SemdNsiDictionary({
      id: CONSULTATION_ENTITY_NAMES.NSI_CASE_VISIT_TYPE,
      title: $t(CONSULTATION_ENTITY_NAMES.NSI_CASE_VISIT_TYPE),
      nsiDictionary: NSI_DICTIONARIES.CASE_VISIT_TYPES,
    }),

    new SemdNsiDictionary({
      id: CONSULTATION_ENTITY_NAMES.NSI_MEDICAL_CARE_PLACE,
      title: $t(CONSULTATION_ENTITY_NAMES.NSI_MEDICAL_CARE_PLACE),
      nsiDictionary: NSI_DICTIONARIES.MEDICAL_CARE_PLACES,
      nsiDictionaryVersion: '4.3',
      permittedValues: [
        'Амбулаторно-поликлиническое учреждение',
        'На дому',
        'Центр здоровья',
        'Дистанционно',
      ],
    }),

    new SemdNsiDictionary({
      id: CONSULTATION_ENTITY_NAMES.NSI_PATIENT_CONDITION,
      title: $t(CONSULTATION_ENTITY_NAMES.NSI_PATIENT_CONDITION),
      nsiDictionary: NSI_DICTIONARIES.PATIENT_CONDITIONS,
      nsiDictionaryVersion: '2.1',
      permittedValues: [
        'Крайне тяжелое',
        'Удовлетворительное',
        'Средней тяжести',
        'Тяжелое',
      ],
    }),

    new SemdNsiDictionary({
      id: CONSULTATION_ENTITY_NAMES.NSI_IDENTIFIED_PATHOLOGY,
      title: $t(CONSULTATION_ENTITY_NAMES.NSI_IDENTIFIED_PATHOLOGY),
      nsiDictionary: NSI_DICTIONARIES.IDENTIFIED_PATHOLOGIES,
      isOptional: true,
    }),

    new SemdListSeparator({ title: t('semds.layout.areas'), id: 'layout.areas' }),

    new SemdArea({
      id: CONSULTATION_ENTITY_NAMES.ANAMNESIS_OF_DISEASE,
      title: $t(CONSULTATION_ENTITY_NAMES.ANAMNESIS_OF_DISEASE),
    }),

    new SemdArea({
      id: CONSULTATION_ENTITY_NAMES.ANAMNESIS_OF_LIFE,
      title: $t(CONSULTATION_ENTITY_NAMES.ANAMNESIS_OF_LIFE),
    }),

    new SemdArea({
      id: CONSULTATION_ENTITY_NAMES.OBJECTIVELY,
      title: $t(CONSULTATION_ENTITY_NAMES.OBJECTIVELY),
    }),

    new SemdArea({
      id: CONSULTATION_ENTITY_NAMES.CONCLUSION,
      title: $t(CONSULTATION_ENTITY_NAMES.CONCLUSION),
    }),

    new SemdArea({
      id: CONSULTATION_ENTITY_NAMES.DIAGNOSIS_BY_TEXT,
      title: $t(CONSULTATION_ENTITY_NAMES.DIAGNOSIS_BY_TEXT),
    }),
  ]
}
