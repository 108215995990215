import BaseEditor from './base_editor'
import { toolbar3Params } from '@/plugins/dynamic_forms/configuration/config'

export default class EditModeEditor extends BaseEditor {
  _tinymce

  isFromScratch () {
    return !gon.specific.entry.template_html && !this.protocolId
  }

  prepareEntities () {
    this.entityManager.showValues()
  }

  bindEditorEvents (editor) {
    super.bindEditorEvents(editor)
    editor.on('change', this._handleChange.bind(this))
    if (this.contextMenu) {
      editor.on('click', this.contextMenu.delete.bind(this.contextMenu))
    }
    editor.on('undo', this.setEntityEvents.bind(this))
    editor.on('redo', this.setEntityEvents.bind(this))
    editor.on('keyup', this.onKeyUp.bind(this))
    editor.on('mouseup', this.onMouseUp.bind(this))
  }

  _handleInit (editor) {
    super._handleInit(editor)
    const leftMargin = store.get('edit_mode_lmargin')
    if (leftMargin && this.modalContainer) {
      this.modalContainer.style.marginLeft = leftMargin
    }
  }

  setEntityEvents () {
    this.entityManager.showValues()
  }

  changeEditorPosition () {
    let newLeftMargin = ''
    const leftMargin = this.modalContainer.style.marginLeft
    if (leftMargin === '0px') {
      newLeftMargin = 'auto'
    } else {
      newLeftMargin = '0px'
    }
    this.modalContainer.style.marginLeft = newLeftMargin
    store.set('edit_mode_lmargin', newLeftMargin)
  }

  _handleChange () {
    this.update()
  }

  onKeyUp () {
    Services.pubSub.emit('ProtocolEditorView:onTemplateHtmlChange')
  }

  onMouseUp () { }

  addButtons (editor) {
    super.addButtons(editor)

    editor.addButton('delete2', {
      icon: 'custom fad fa-trash',
      tooltip: t('delete_selection'),
      onclick: this.deleteContent.bind(this),
      onPostRender () {
        const ctrl = this
        editor.on('NodeChange', function (e) {
          const sel = tinymce.activeEditor?.selection
          if (!sel) { return }
          const content = sel.getContent()
          ctrl.disabled(content.length === 0)
        })
      },
    })
    editor.addButton('change_editor_margin', {
      icon: 'custom fad fa-exchange',
      tooltip: t('change_editor_position'),
      onclick: this.changeEditorPosition.bind(this),
    })
    editor.addButton('diagnoses', {
      icon: 'custom fad fa-users',
      classes: 'protocol-entities ',
      tooltip: t('diagnoses'),
      onclick: () => {
        this.buildCategory()
      },
    })

    editor.addButton(...toolbar3Params)
  }
}
