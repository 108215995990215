import { MAlert } from '@/vue_present/_base/MMessageBox/MAlert'
import { MConfirm } from '@/vue_present/_base/MConfirm/MConfirm'

export const onPrintReceiptCopy = async () => {
  const receiptRecords = gon.specific.receipt_records

  if (!receiptRecords.length) {
    await MAlert.warning(t('fiscal.printedReceiptsNotExist'))

    return
  }

  if (receiptRecords.length === 1) {
    if (receiptRecords.at(0).document_number) {
      return printReceiptCopy(receiptRecords.at(0).document_number)
    }

    return MAlert.warning(t('fiscal.fdNumberNotFound')) as unknown as void
  }

  const { cancel } = await MConfirm.warning(t('fiscal.lastReceiptCopy'))
  if (cancel) { return }
  printReceiptCopy(receiptRecords.at(-1).document_number)
}

const printReceiptCopy = (documentNumber: number | string) => {
  const workplaceId = gon.application.current_user.workplace_id

  Services.workplace.printReport('doc_by_number', workplaceId, { doc_number: documentNumber.toString() })
}
