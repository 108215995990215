export const taxCertificate2024ListAdapter = {
  toServer (payload) {
    return {
      clientId: payload.id,
      kind: payload.type,
      limit: payload.limit,
      offset: payload.offset,
      sort: payload.sort,
    }
  },
}
