import { combineReducers } from 'redux'
import complaints from './complaints_reducer'
import destinations from './destinations_reducer'
import diagnoses from './disagnoses_reducer'
import diagnosesConstructor from './disagnoses_constructor_reducer'
import conclusions from './conclusions_reducer'
import variables from './variables_reducer'
import calculate from './calculate_reducer'
import fixList from './fix_list'
import extList from './open_list'
import entriesVariable from './entries_variable'
import printArea from './print_area_reducer'
import editorOpened from '@/plugins/dynamic_forms/reducers/editor_opened'
import { semdEntitiesReducer } from '@/vue_apps/ProtocolsApp/_SemdProtocolEditor/reducers/semdEntitiesReducer'

const reducersStorage = combineReducers({
  calculate,
  variables,
  conclusions,
  diagnoses,
  diagnoses_constructor: diagnosesConstructor,
  destinations,
  complaints,
  fix_list: fixList,
  ext_list: extList,
  entries_variable: entriesVariable,
  print_area: printArea,
  editorOpened,
  semdEntities: semdEntitiesReducer,
})

export default reducersStorage
