<template>
  <clinical-guideline-collapse-item
    class="clinical-guideline-collapse-item__anamnesis"
    :collapse-item-id="CLINICAL_GUIDELINE_COLLAPSE_ITEM.ANAMNESIS"
    :active-names="activeNames"
  >
    <m-textarea
      :value="clinicalGuideline.anamnesis"
      :rows="8"
      :m-fixed-height="false"
      readonly
    />
  </clinical-guideline-collapse-item>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import {
  ClinicalGuidelineApi,
} from '@/vue_apps/catalogs_root/ClinicalGuidelinesCatalog/classes/ClinicalGuideline/ClinicalGuidelineApi'
import ClinicalGuidelineCollapseItem from '@/vue_apps/ClinicalGuidelines/components/ClinicalGuidelineCollapseItem.vue'
import { CLINICAL_GUIDELINE_COLLAPSE_ITEM } from '@/vue_apps/ClinicalGuidelines/consts/clinicalGuidelineCollapseItems'
import MTextarea from '@/vue_present/_base/inputs/MTextarea/MTextarea.vue'

export default defineComponent({
  name: 'ClinicalGuidelineCollapseItemAnamnesis',

  components: { MTextarea, ClinicalGuidelineCollapseItem },

  props: {
    activeNames: { type: Array as PropType<Array<string | number>>, default: () => [] },
    clinicalGuideline: { type: ClinicalGuidelineApi, required: true },
  },

  data () {
    return {
      CLINICAL_GUIDELINE_COLLAPSE_ITEM,
    }
  },
})
</script>
