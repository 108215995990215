import { IPersonalCheckup } from '@/vue_apps/CheckupsModule/interfaces/IPersonalCheckup'
import { IUser } from '@/_declarations/IUser'
import {
  IPersonalCheckupDoctorResultsSpecialty,
} from '@/vue_apps/CheckupsModule/interfaces/PersonalCheckupDoctorResults/IPersonalCheckupDoctorResultsSpecialty'
import { INsiEntry } from '@/_declarations/INsiEntry'
import { NestedItemsApi } from '@/_api/_classes/NestedItems/classes/NestedItemsApi'
import {
  PersonalCheckupDoctorResultsListItem,
} from '@/vue_apps/CheckupsModule/classes/PersonalCheckupDoctorResults/PersonalCheckupDoctorResultsListItem'
import {
  IPersonalCheckupDoctorResultsItem,
} from '@/vue_apps/CheckupsModule/interfaces/PersonalCheckupDoctorResults/IPersonalCheckupDoctorResultsItem'
import {
  PersonalCheckupDoctorResultsPresenter,
} from '@/vue_apps/CheckupsModule/api/PersonalCheckupDoctorResultsPresenter/PersonalCheckupDoctorResultsPresenter'

export class PersonalCheckupDoctorResultsModel extends NestedItemsApi<
  PersonalCheckupDoctorResultsListItem,
  IPersonalCheckupDoctorResultsItem,
  PersonalCheckupDoctorResultsPresenter
> {
  specialists: IPersonalCheckupDoctorResultsSpecialty[] = []

  medicalAssessments: INsiEntry[] = []

  specialty: IPersonalCheckupDoctorResultsSpecialty = null

  user: IUser = null

  date: string = Utils.getBaseFormattedDate()

  medicalAssessment: INsiEntry = null

  foreignUser: boolean = false

  foreignUserSurname: string = null

  foreignUserName: string = null

  foreignUserSecondName: string = null

  constructor (props?: Pick<IPersonalCheckup, 'id' | 'doctorResults'>) {
    super({
      ownerId: props?.id,
      nestedItemsProps: props?.doctorResults,
      nestedItemsPropName: 'doctorResults',
      nestedItemsClass: PersonalCheckupDoctorResultsListItem,
      presenter: new PersonalCheckupDoctorResultsPresenter(),
    })
  }
}
