enum RECEIPT_STATUS {
  NOT_PRINTED,
  PRINTED,
  PRINTED_NOT_ONCE,
}

export const getReceiptInfo = () => {
  const receiptRecords: unknown[] = gon.specific.receipt_records

  if (!receiptRecords) { return null }

  const template = {
    [RECEIPT_STATUS.NOT_PRINTED]: t('not_printed'),
    [RECEIPT_STATUS.PRINTED]: t('printed'),
    [RECEIPT_STATUS.PRINTED_NOT_ONCE]: `${t('printed_not_once')} (${receiptRecords.length})`,
  }

  const receiptStatus = receiptRecords.length > 1
    ? RECEIPT_STATUS.PRINTED_NOT_ONCE
    : receiptRecords.length

  return {
    cssClass: `receipt_info_${receiptStatus}`,
    value: template[receiptStatus],
  }
}
