import { MListServiceModel } from '@/_api/_requests/MListServiceApi/MListServiceModel'
import {
  DEFAULT_CURRENT_PAGE,
  DEFAULT_LIMIT,
  DEFAULT_SEARCH_VALUE,
  DEFAULT_TOTAL_ITEMS,
  DEFAULT_TOTAL_PAGES,
} from '@/vue_components/store/modules/filters_base'
import { IDataItem } from '@/_declarations/IMListServiceConfigApi'

export class MListServiceLogic <DataItem, FiltersMap>
  extends MListServiceModel<DataItem, FiltersMap> {
  protected _loading: boolean = false

  get currentPage () {
    return this.offset / (this.limit || DEFAULT_LIMIT) + 1
  }

  setPage (page = DEFAULT_CURRENT_PAGE) {
    this.offset = (page - 1) * (this.limit || DEFAULT_LIMIT)
  }

  setFilterValue (
    filterName: keyof FiltersMap,
    value: any
  ) {
    this._filters[filterName] = value
    this.setFiltered()
  }

  resetFilters (filters = this.initialFilters) {
    this.initFilters(filters)
    this.setFiltered(false)
    this.search(DEFAULT_SEARCH_VALUE)
    this._cache.save(filters)
  }

  refreshFilters () {
    this.initFilters(this._filters)
  }

  resetData (params?: Record<string, any>) {
    this._data = []
    this._totals = null
    this._totalPages = DEFAULT_TOTAL_PAGES
    this._totalItems = DEFAULT_TOTAL_ITEMS
    this.limit = params?.limit || this.limit
  }

  search (searchQuery: string) {
    this._searchQuery = searchQuery
  }

  /**
   * @protected
   */
  startLoading () {
    this._loading = true
  }

  /**
   * @protected
   */
  stopLoading () {
    setTimeout(() => {
      this._loading = false
    })
  }

  getCache () {
    return this._cache
  }

  setData (data: Array<IDataItem<DataItem>>) {
    this._data = data
  }

  setTotals (totals: IDataItem<DataItem>) {
    this._totals = totals
  }

  setTotalPages (totalPages: number) {
    this._totalPages = totalPages
  }

  setTotalItems (totalItems: number) {
    this._totalItems = totalItems
  }

  protected isSearchRepeatedOrEmpty (searchQuery: string) {
    return searchQuery === this._searchQuery ||
      (!searchQuery && !this._searchQuery)
  }
}
