<template>
  <div class="payments-filters flex flex-wrap gap-indent-small">
    <!-- Дата-кнопки -->
    <m-date-picker-buttons
      :value="filters.date"
      :use-only="useOnly"
      :m-fixed-height="false"
      :use-omit="['tomorrow']"
      value-format="yyyy-MM-dd"
      @change="onFilterChange('date', $event)"
    />

    <!-- Дата-пикер -->
    <m-date-picker
      v-if="usePicker"
      :value="filters.date"
      type="daterange"
      value-format="yyyy-MM-dd"
      :m-fixed-height="false"
      @change="onFilterChange('date', $event)"
    />

    <!-- Типы оплат -->
    <m-select
      :value="filters.paymentSpecies"
      :items="paymentSpeciesList"
      :placeholder="t('finance.allPaymentSpecies')"
      :m-fixed-height="false"
      use-custom-result="simple"
      @change="onFilterChange('paymentSpecies', $event)"
    />

    <!-- Администраторы -->
    <reusable-doctors-list
      :value="filters.user"
      :user-group="USER_GROUP_IDS.STAFF"
      option-label="fullName"
      label=""
      :placeholder="t('finance.allAdministrators')"
      :m-fixed-height="false"
      @onDoctorChange="onFilterChange('user', $event)"
    />

    <!-- Типы платежей -->
    <m-select
      :value="filters.kind"
      :items="paymentKinds"
      :placeholder="t('finance.allPaymentKinds')"
      :m-fixed-height="false"
      use-custom-result="simple"
      @change="onFilterChange('kind', $event)"
    />

    <!--    Статусы чеков -->
    <m-select
      v-if="workplaceEnabled"
      :value="filters.receiptStatus"
      :items="receiptStatuses"
      :placeholder="t('finance.allReceiptStatuses')"
      use-custom-result="simple"
      :m-fixed-height="false"
      @change="onFilterChange('receiptStatus', $event)"
    />

    <!-- Рабочие места -->
    <m-select-lazy
      v-if="workplaceEnabled"
      :value="filters.workplaceId"
      :fetch-method="(params) => workplaceEndpoint.getAll(params)"
      :placeholder="t('finance.allWorkplaces')"
      :m-fixed-height="false"
      use-first-time-fetch
      use-custom-result="simple"
      @changeByLazy="onFilterChange('workplaceId', $event)"
    />

    <!-- Типы заказчиков -->
    <m-select
      :value="filters.customerType"
      :items="CUSTOMER_TYPES"
      :placeholder="t('finance.allCustomerTypes')"
      :m-fixed-height="false"
      use-custom-result="simple"
      @change="onFilterChange('customerType', $event)"
    />

    <!-- Кнопки обновления и сброса -->
    <m-si-generator-filters-buttons
      @onRefreshClick="refreshData"
      @onResetClick="resetData"
    />
  </div>
</template>

<script>
import MSelect from '@/vue_present/_base/inputs/MSelect/MSelect.vue'
import {
  getPaymentKindsList,
  getPaymentSpeciesList,
} from '@/vue_apps/FinanceModule/FinanceModuleIndex/scripts/paymentsFiltersConsts'
import { MListService } from '@/_api/_requests/MListService'
import MDatePicker from '@/vue_present/_base/inputs/MDatePicker/MDatePicker.vue'
import MDatePickerButtons from '@/vue_present/_base/MDatePickerButtons/MDatePickerButtons.vue'
import { getReceiptStatusLabels } from '@/vue_apps/FinanceModule/FinanceModuleIndex/scripts/getReceiptIcons'
import { CUSTOMER_TYPES } from '@/vue_apps/FinanceModule/FinanceModuleIndex/scripts/consts'
import MSiGeneratorFiltersButtons
  from '@/vue_present/_base/Tables/MSiGenerator/components/MSiGeneratorFiltersButtons.vue'
import ReusableDoctorsList from '@/vue_present/Reuse/Lists/Doctors/ReusableDoctorsList.vue'
import { USER_GROUP_IDS } from '@/_global_scripts/GLOBAL_CONSTS'
import MSelectLazy from '@/vue_present/_base/MSelectLazy/MSelectLazy.vue'
import { workplaceEndpoint } from '@/api_entities/workplaces/workplace_endpoint'

export default {
  name: 'PaymentsFilters',
  components: {
    MSelectLazy,
    ReusableDoctorsList,
    MSiGeneratorFiltersButtons,
    MDatePickerButtons,
    MDatePicker,
    MSelect,
  },

  props: {
    list: MListService,
  },

  data () {
    return {
      paymentSpeciesList: getPaymentSpeciesList(),
      paymentKinds: getPaymentKindsList(),
      receiptStatuses: getReceiptStatusLabels(),
      CUSTOMER_TYPES,
      USER_GROUP_IDS,
      workplaceEndpoint,
    }
  },

  computed: {
    filters () {
      return this.list.filtersData
    },

    useOnly () {
      return gon.application.security.current_user_permissions.Finance
    },

    usePicker () {
      return gon.application.security.current_user_permissions.Finance
        .includes('Period')
    },

    workplaceEnabled () {
      return gon.application.workplaces_module_enabled
    },
  },

  methods: {
    refreshData () {
      this.list.refreshFilters()
      this.list.fetchAll()
    },

    resetData () {
      this.list.resetFilters()
      this.list.fetchAll()
    },

    onFilterChange (name, value) {
      this.list.setFilterValue(name, value)
      this.list.fetchAll()
    },
  },
}
</script>
