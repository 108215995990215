import { ItemBase } from '@/_api/_classes/ItemBase'
import { IPaymentToClientDTO } from '@/vue_apps/FinanceModule/FinanceModuleIndex/interfaces/IPaymentToClientDTO'
import { orNull } from '@/_declarations/orNull'
import { ICatalog } from '@/_declarations/ICatalog'
import { IUser } from '@/_declarations/IUser'
import { IPaymentPayerClient } from '@/vue_apps/FinanceModule/FinanceModuleIndex/interfaces/IPaymentPayerClient'
import { IPaymentPayerCompany } from '@/vue_apps/FinanceModule/FinanceModuleIndex/interfaces/IPaymentPayerCompany'
import { TPaymentPayer } from '@/vue_apps/FinanceModule/FinanceModuleIndex/interfaces/IPaymentPayer'
import {
  IPaymentDestinationOrder,
} from '@/vue_apps/FinanceModule/FinanceModuleIndex/interfaces/IPaymentDestinationOrder'
import {
  PaymentDistributorFundLogic,
} from '@/vue_apps/FinanceModule/FinanceModuleIndex/classes/Payment/PaymentDistributorFundLogic'
import {
  PaymentDistributorRefundLogic,
} from '@/vue_apps/FinanceModule/FinanceModuleIndex/classes/Payment/PaymentDistributorRefundLogic'
import {
  IAcquiringSearchTransaction,
} from '@/vue_apps/FinanceModule/FinanceModuleIndex/interfaces/Acquiring/IAcquiringSearchTransaction'

export class PaymentModel<
  Distributor extends PaymentDistributorFundLogic | PaymentDistributorRefundLogic
> extends ItemBase {
  _permit = [
    'id',
    'clinic',
    'user',
    'destinationOrder',
    'destinationClient',
    'payerCompany',
    'payerClient',
    'comment',
    'workplace',
    'canRefund',
    'createdBy',
    'createdAt',
  ]

  id: number

  clinic: ICatalog<number> = {
    id: gon.application.current_clinic.id,
    title: gon.application.current_clinic.title,
  }

  user: orNull<Pick<IUser, 'id' | 'shortName'>>

  destinationOrder: orNull<IPaymentDestinationOrder>

  destinationClient: orNull<Pick<IUser, 'id' | 'fullName' | 'deletedAt'>>

  payerCompany: orNull<IPaymentPayerCompany>

  payerClient: orNull<IPaymentPayerClient>

  payer: TPaymentPayer

  comment: orNull<string>

  workplace: orNull<ICatalog<string>>

  canRefund: boolean

  createdAt: string

  createdBy: string

  paymentsWithComments: Array<Pick<IPaymentToClientDTO, 'id' | 'createdAt' | 'deposit' | 'comment'>> = []

  distributor: Distributor

  lastTransaction: orNull<IAcquiringSearchTransaction> = null

  isLastTransactionCancelOrRefundAllowed: boolean = false

  getAttributes (attributes: string[] = this._permit): Record<string, unknown> {
    return {
      ...super.getAttributes(attributes),
      ...this.distributor.getAttributes(),
    }
  }
}
