<template>
  <div class="print-raw-receipt-modal-receipt-payments-item">
    <!-- Тип оплаты -->
    <m-select
      :value="paymentItem.kind"
      :items="catalogItems"
      :label="t$('kind')"
      required
      validator-name="kind"
      @registerValidator="onRegisterValidator"
      @change="paymentItem.setValue('kind', $event)"
    />

    <!-- Сумма оплаты -->
    <m-input
      class="flex-grow-1"
      :value="paymentItem.sum"
      :label="t$('sum')"
      type="number"
      :min="0"
      :required="greaterThanZeroValidator"
      validator-name="sum"
      @registerValidator="onRegisterValidator"
      @input="paymentItem.setValue('sum', $event)"
    />

    <m-button-delete
      class="mb-indent-validation"
      @yes="$emit('onDelete')"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import {
  PrintRawReceiptPaymentItem,
} from '@/vue_apps/catalogs_root/Workplaces/classes/PrintRawPeceipt/PrintRawReceiptPaymentItem'
import { i18nScopeMixin } from '@/vue_present/mixins/i18nScopeMixin'
import { ValidationParentMixin } from '@/vue_present/mixins/ValidationParentMixin'
import MSelect from '@/vue_present/_base/inputs/MSelect/MSelect.vue'
import MInput from '@/vue_present/_base/inputs/MInput/MInput.vue'
import MButtonDelete from '@/vue_present/_base/buttons/MButtonDelete/MButtonDelete.vue'
import {
  PrintRawReceiptModalItemsMixin,
} from '@/vue_apps/catalogs_root/Workplaces/mixins/PrintRawReceiptModalItemsMixin'

export default defineComponent({
  name: 'PrintRawReceiptModalReceiptPaymentsItem',

  components: {
    MButtonDelete,
    MInput,
    MSelect,
  },

  mixins: [
    i18nScopeMixin,
    ValidationParentMixin,
    PrintRawReceiptModalItemsMixin,
  ],

  props: {
    paymentItem: PrintRawReceiptPaymentItem,
  },

  emits: ['onDelete'],

  created () {
    this.setI18nScope('fiscal.receiptPayment')
    this.setCatalogItemsPrefix('fiscal.paymentKinds')
  },
})
</script>
