<template>
  <validation-wrapper
    class="m-search-input-lazy"
    :m-fixed-height="mFixedHeight"
  >
    <m-drop-down
      class="w-100"
      :items="list.data"
      :value-key="valueKey"
      :option-label="optionLabel"
      :use-search="{ searchQuery: list.searchQuery, loading }"
      :disabled="disabled"
      placement="bottom-start"
      @itemClick="onItemClick"
    >
      <m-search-input
        ref="mSearchInput"
        :search-query="value"
        :label="label"
        :m-fixed-height="false"
        :use-search-style="useSearchStyle"
        :search-placeholder="placeholder"
        :required="required"
        :disabled="disabled"
        @blur="__validate()"
        @update:searchQuery="onSearchQueryUpdate"
      />
    </m-drop-down>
  </validation-wrapper>
</template>

<script>
import MDropDown from '@/vue_present/_base/MDropDown/MDropDown.vue'
import MSearchInput from '@/vue_present/_base/Tables/MSearchInput/MSearchInput.vue'
import { MInputsWrapperMiddlewareMixin } from '@/vue_present/mixins/MInputsWrapperMiddlewareMixin'
import { MListService } from '@/_api/_requests/MListService'
import ValidationWrapper from '@/vue_components/common/validation_wrapper.vue'
import { ValidationChildMixin } from '@/vue_present/mixins/ValidationChildMixin'
import { SpinnerHolder } from '@/vue_components/mixins/spinner_holder'
import { MListServiceApi } from '@/_api/_requests/MListServiceApi/MListServiceApi'

export default {
  name: 'MSearchInputLazy',

  components: { ValidationWrapper, MSearchInput, MDropDown },

  mixins: [
    MInputsWrapperMiddlewareMixin,
    ValidationChildMixin,
    SpinnerHolder,
  ],

  model: {
    prop: 'value',
    event: 'input',
  },

  props: {
    list: { type: [MListService, MListServiceApi], required: true },
    value: { type: String, default: '' },
    valueKey: { type: String, default: 'id' },
    optionLabel: { type: String, default: 'title' },
    useSearchStyle: { type: Boolean, default: true },
    placeholder: { type: String, default: t('search') },
    disabled: Boolean,
    clearInputAfterItemClick: Boolean,
  },

  emits: [
    'input',
    'registerValidator',
    'onItemClick',
  ],

  created () {
    if (!this.list) { throw new Error('list is required') }
  },

  methods: {
    onInput (value = '') {
      this.$emit('input', value)
    },

    onSearchQueryUpdate (searchQuery = '') {
      this.onInput(searchQuery)
      if (!searchQuery) { return }
      this.loadMore(searchQuery)
    },

    loadMore (searchQuery) {
      if (searchQuery === this.value) { return }

      if (this.list instanceof MListServiceApi) {
        return this.list.search(searchQuery)
      }

      this.list.setSearchQuery(searchQuery)
      this.withSpinner(this.list.fetchAll())
    },

    onItemClick (item) {
      const value = this.clearInputAfterItemClick
        ? ''
        : item[this.optionLabel]

      this.$refs.mSearchInput.setInputValue(value)
      this.onInput(value)
      this.$emit('onItemClick', item)
    },
  },
}
</script>
