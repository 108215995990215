import { MeasureUnitLogic } from './MeasureUnitLogic'
import { Api } from '@/_api/decorators/api/Api'
import { ICRUDReturn } from '@/_api/decorators/api/interfaces/ICRUDReturn'
import { IList } from '@/_declarations/IList'
import { IMeasureUnit } from '@/_declarations/IMeasureUnit'
import { EMeasureUnitKinds } from '@/_declarations/enums/EMeasureUnitKinds'
import { Callback } from '@/_api/decorators/callback'
import { IApiError } from '@/_declarations/IApiErrors'

export class MeasureUnitApi extends MeasureUnitLogic {
  @Api.notify('listMessage', 'measureUnit', { success: false })
  @Api.list(Routes.list_api_internal_catalogs_measure_units_path)
  static list (payload = {}) {
    return {
      payload,
    } as ICRUDReturn as Promise<IList<IMeasureUnit[]>>
  }

  @Callback('fillPropsIfSuccess')
  @Api.notify('fetchMessage', 'measureUnit', { success: false })
  @Api.get(Routes.api_internal_catalogs_measure_unit_path)
  fetch (id: number) {
    return {
      routeArgs: [id],
    } as ICRUDReturn as Promise<IMeasureUnit>
  }

  @Callback('fillPropsIfSuccess')
  @Api.notify('submitMessage', 'measureUnit')
  @Api.submit(
    Routes.api_internal_catalogs_measure_units_path,
    Routes.api_internal_catalogs_measure_unit_path
  )
  submit () {
    return {
      routeArgs: [this.id],
      payload: this.getAttributes(),
    } as ICRUDReturn as Promise<IMeasureUnit>
  }

  @Callback('checkErrors')
  @Api.notify('destroyMessage', 'measureUnit')
  @Api.delete(Routes.api_internal_catalogs_measure_unit_path)
  destroy (idOfMeasureUnitForReplace: number) {
    return {
      routeArgs: [this.id],
      payload: {
        newMeasureUnitId: idOfMeasureUnitForReplace,
        kind: EMeasureUnitKinds.other,
      },
    } as ICRUDReturn as Promise<void>
  }

  private checkErrors (res): IApiError | void {
    if (res?.errors) { return res.errors }
  }

  private fillPropsIfSuccess (res): IApiError | void {
    const errors = this.checkErrors(res)
    if (errors) { return errors }

    this._fillProps(res)
  }
}
