<template>
  <m-input
    :value.sync="inputValue"
    class="w-100"
    :class="{ 'm-search-input': useSearchStyle }"
    :placeholder="searchPlaceholder"
    :m-fixed-height="mFixedHeight"
    :validator-name="validatorName"
    :required="required"
    :label="label"
    :disabled="disabled"
    @input="onInput"
    @change="onChange"
    @blur="$emit('blur', $event)"
    @registerValidator="$registerValidator($event)"
  />
</template>

<script>
import MInput from '@/vue_present/_base/inputs/MInput/MInput.vue'
import { MSearchInputProps } from '@/vue_present/_base/Tables/MSearchInput/MSearchInputProps'
import { PropsTypes } from '@/vue_present/_base/PropsTypes'
import { DEFAULT_FILTRATION_DEBOUNCE } from '@/vue_components/store/modules/filters_base'
import { MInputsWrapperMiddlewareMixin } from '@/vue_present/mixins/MInputsWrapperMiddlewareMixin'

export default {
  name: 'MSearchInput',

  components: { MInput },

  mixins: [
    MInputsWrapperMiddlewareMixin,
  ],

  props: {
    ...MSearchInputProps,
    debounce: PropsTypes.Number(DEFAULT_FILTRATION_DEBOUNCE),
    useSearchStyle: PropsTypes.Boolean(true),
    disabled: Boolean,
  },

  emits: [
    'update:searchQuery',
    'blur',
  ],

  data () {
    return {
      inputValue: this.searchQuery || '',
      timeout: null,
      firstTime: true,
    }
  },

  watch: {
    searchQuery (to) {
      if (this.firstTime) {
        this.inputValue = this.searchQuery

        return
      }

      if (to) { return }

      this.inputValue = null
    },
  },

  methods: {
    onChange (searchQuery) {
      if (searchQuery) { return }
      this.$emit('update:searchQuery', null)
    },

    onInput (searchQuery = '') {
      clearTimeout(this.timeout)

      this.timeout = setTimeout(() => {
        this.$emit(
          'update:searchQuery',
          searchQuery?.length < this.searchMinLength
            ? ''
            : searchQuery
        )
      }, this.debounce)
    },

    setInputValue (value = '') {
      this.inputValue = value
    },
  },
}
</script>
