<template>
  <div
    v-loading="loading"
    class="common-sms-settings-wrapper"
  >
    <div
      v-if="smsModuleEnabled"
      class="common-sms-settings"
    >
      <div class="common-sms-settings__authorization-parameters mb-25">
        <div class="title autorization-parameters__title">
          {{ t('whatsapp.authorizationParameters') }}
        </div>
        <div class="explanation explanation_bold explanation_small mb-indent-validation">
          {{ t('whatsapp.autorizationParametersClarification') }}
        </div>
        <div class="authorization-parameters__login">
          <m-input
            :label="t('whatsapp.login')"
            :value="smsLogin"
            @input="setSmsSettingsLogin"
          />
        </div>
        <div class="authorization-parameters__password">
          <m-input
            :label="t('whatsapp.password')"
            type="password"
            :value="smsPassword"
            @input="setSmsSettingsPassword"
          />
        </div>

        <m-button
          icon="key"
          :text="t('whatsapp.checkAutorization')"
          :disabled="!isAuthParamsEntered"
          type="success"
          @click="refreshBalanceValue"
        />
      </div>

      <div class="common-sms-settings__account-info">
        <div class="account-info__account-balance mb-25">
          <div class="account-balance__title title mb-indent-small">
            {{ t('whatsapp.accountBalance') }}
          </div>
          <div>
            <span class="account-balance__value">
              {{ smsBalanceValue }}
            </span>
            <m-button
              template="short_refresh_green"
              size="mini"
              :disabled="!isAuthParamsEntered"
              @click="refreshBalanceValue"
            />
          </div>
        </div>

        <div class="account-info__sending-time">
          <m-time-select
            :label="t('sms.message_sending_time')"
            :start-time="sendFromTime"
            :end-time="sendToTime"
            :required="isSendingTimeLimit"
            time-range
            validator-name="sending-time-limit"
            @changeStartTime="setSmsSendFromTime"
            @changeEndTime="setSmsSendToTime"
            @registerValidator="onRegisterValidator"
          />
          <div class="explanation explanation_bold explanation_solo explanation_small">
            {{ t('whatsapp.sendingTimeClarification') }}
          </div>
        </div>

        <div class="low-balance-notification">
          <m-switch
            :value="balanceNotification"
            :active-text="t('whatsapp.lowBalanceNotification')"
            class="mb-25"
            @change="setBalanceNotification"
          />

          <div
            v-if="balanceNotification"
            class="low-balance-notification__settings"
          >
            <m-input
              :label="t('whatsapp.balanceValue')"
              :value="balanceNotificationValue"
              type="number"
              validator-name="balance-notification-value"
              :required="balanceNotificationValidation"
              :disabled="!balanceNotification"
              @input="setBalanceNotificationValue"
              @registerValidator="onRegisterValidator"
            />

            <reusable-doctors-list
              :value="balanceNotifyUsers"
              :label="t('whatsapp.responsibleList')"
              :disabled="!balanceNotification"
              :required="true"
              validator-name="balance-notify-users"
              multiple
              option-label="shortName"
              :user-group="USER_GROUP_IDS.CLINIC_PERSONNEL"
              :use-user-mode="false"
              m-fixed-height
              @onDoctorChange="setBalanceNotifyUsers"
              @registerValidator="onRegisterValidator"
            />
          </div>
        </div>
      </div>

      <m-button
        template="save"
        @click="saveForm"
      />
    </div>

    <introduction
      v-else
      :introduction-text="t('introduction.sms')"
      icon="fad fa-comments"
    />
  </div>
</template>

<script>
import MInput from '@/vue_present/_base/inputs/MInput/MInput.vue'
import MButton from '@/vue_present/_base/buttons/MButton/MButton.vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import MTimeSelect from '@/vue_present/_base/inputs/MTimeSelect/MTimeSelect.vue'
import { MSmsConfigurationsPresenter } from '@/_api/Deliveries/MSmsConfigurationsPresenter'
import MSwitch from '@/vue_present/_base/inputs/MSwitch/MSwitch.vue'
import Introduction from '@/vue_components/introduction/introduction.vue'
import { ValidationParentMixin } from '@/vue_present/mixins/ValidationParentMixin'
import { MMessagesConfigurationPresenter } from '@/_api/Deliveries/MMessagesConfigurationPresenter'
import { SpinnerHolder } from '@/vue_components/mixins/spinner_holder'
import ReusableDoctorsList from '@/vue_present/Reuse/Lists/Doctors/ReusableDoctorsList.vue'
import { USER_GROUP_IDS } from '@/_global_scripts/GLOBAL_CONSTS'

export default {
  name: 'CommonSmsSettings',

  components: {
    ReusableDoctorsList,
    MTimeSelect,
    Introduction,
    MSwitch,
    MButton,
    MInput,
  },

  mixins: [ValidationParentMixin, SpinnerHolder],

  data () {
    return {
      USER_GROUP_IDS,
    }
  },

  computed: {
    ...mapGetters('messagesStore', {
      smsLogin: 'vxGetSmsSettingsLogin',
      smsPassword: 'vxGetSmsSettingsPassword',
      smsBalance: 'vxGetSmsBalance',
      balanceNotification: 'vxGetBalanceNotification',
      balanceNotificationValue: 'vxGetBalanceNotificationValue',
      balanceNotifyUsers: 'vxGetBalanceNotifyUsers',
      sendFromTime: 'vxGetSmsSendFromTime',
      sendToTime: 'vxGetSmsSendToTime',
    }),

    isAuthParamsEntered () {
      return this.smsLogin && this.smsPassword
    },

    smsBalanceValue () {
      return this.smsBalance?.balance ? this.smsBalance.balance : t('whatsapp.autorizePlease')
    },

    smsModuleEnabled () {
      return gon.application.sms_module_enabled || gon.application.sms_dispatch_module_enabled
    },

    isSendingTimeLimit () {
      return () => {
        if (this.sendFromTime && this.sendToTime) { return [] }

        return [t('errors.filled?')]
      }
    },
  },

  watch: {
    balanceNotification (to) {
      if (to) { return }
      this.setBalanceNotifyUsers([])
    },
  },

  created () {
    this.withSpinner(
      this.loadDoctors()
        .then(() => { this.vxFetchSmsConfigurations() })
    )
  },

  methods: {
    ...mapActions('globalCatalogs/doctorStore', {
      loadDoctors: 'loadDoctors',
    }),

    ...mapActions('messagesStore', {
      vxFetchSmsConfigurations: 'vxFetchSmsConfigurations',
      vxFetchSmsBalance: 'vxFetchSmsBalance',
    }),

    ...mapMutations('messagesStore', {
      setSmsSettingsLogin: 'vxSetSmsSettingsLogin',
      setSmsSettingsPassword: 'vxSetSmsSettingsPassword',
      setBalanceNotification: 'vxSetBalanceNotification',
      setBalanceNotificationValue: 'vxSetBalanceNotificationValue',
      setBalanceNotifyUsers: 'vxSetBalanceNotifyUsers',
      setSmsSendFromTime: 'vxSetSmsSendFromTime',
      setSmsSendToTime: 'vxSetSmsSendToTime',
    }),

    refreshBalanceValue () {
      this.vxFetchSmsBalance({ login: this.smsLogin, password: this.smsPassword })
    },

    balanceNotificationValidation () {
      return this.balanceNotificationValue && this.balanceNotificationValue < 0
        ? [t('errors.cannotBeNegative?')]
        : Number.isInteger(this.balanceNotificationValue)
          ? []
          : [t('errors.canOnlyBeInteger')]
    },

    saveForm () {
      if (this.hasErrors()) { return }

      const presenterSms = new MSmsConfigurationsPresenter()
      const presenterMessages = new MMessagesConfigurationPresenter()
      const balanceNotifyUserIds = this.balanceNotifyUsers.map((item) => item.id)

      const messagesConfigurations = {
        sendFromTime: this.sendFromTime,
        sendToTime: this.sendToTime,
      }

      const smsConfigurations = {
        login: this.smsLogin,
        password: this.smsPassword,
        balanceNotification: this.balanceNotification,
        balanceNotificationValue: this.balanceNotificationValue,
        balanceNotifyUserIds,
      }

      Promise.all([
        presenterSms.updateSmsConfigurations(smsConfigurations),
        presenterMessages.update(messagesConfigurations),
      ])
    },
  },
}
</script>
