<template>
  <div class="tax-rate-select">
    <m-select
      v-bind="$attrs"
      :value="value"
      class="tax-rate-select__select"
      full-width
      :label="label"
      :placeholder="t('nds_unspecified')"
      :items="items"
      filterable
      :min-filter-value-length="1"
      :custom-filter="defaultSearchFilter"
      v-on="$listeners"
      @change="$emit('change', $event)"
    />

    <m-icon
      v-if="!isTaxRateSendableToDriver"
      v-tooltip="t('tax_rate_not_sendable_to_driver')"
      class="tax-rate-select__icon"
      icon="warning"
      color="warning"
    />
  </div>
</template>

<script>
import MSelect from '@/vue_present/_base/inputs/MSelect/MSelect.vue'
import MIcon from '@/vue_present/_base/MIcon/MIcon.vue'
import { getTaxRatesOptions } from '@/vue_apps/Taxes/const/helpers'
import { TAX_RATES_SENDABLE_TO_DRIVER } from '@/vue_apps/Taxes/const/const'
import { defaultSearchFilter } from '@/helpers/lambda'

export default {
  name: 'ReusableTaxRatesList',

  components: {
    MSelect,
    MIcon,
  },

  model: {
    prop: 'value',
    event: 'change',
  },

  props: {
    value: { type: [Object, String], default: null },
    label: { type: String, default: t('nds') },
  },

  data () {
    return {
      items: getTaxRatesOptions(),
      defaultSearchFilter,
    }
  },

  computed: {
    isTaxRateSendableToDriver () {
      return !this.value || TAX_RATES_SENDABLE_TO_DRIVER
        .includes(this.value.id ?? this.value)
    },
  },
}
</script>
