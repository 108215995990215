<template>
  <div class="acquiring-modal__operation-type-buttons">
    <template v-if="acquiring.operationType === ACQUIRING_OPERATION_TYPE.PAY">
      <!-- Карта -->
      <m-button
        :text="t$('byCard')"
        type="primary"
        :disabled="byCardDisabled"
        @click="acquiring.pay(ACQUIRING_PAYMENT_TYPE.CARD)"
      />

      <!-- СБП -->
      <m-button
        :text="t$('bySBP')"
        type="primary"
        :disabled="bySBPDisabled"
        @click="acquiring.pay(ACQUIRING_PAYMENT_TYPE.SBP)"
      />
    </template>

    <template v-else>
      <!-- Отмена -->
      <m-button
        v-if="isCancelOrRefund"
        v-tooltip="byCardCancelDisabledTooltip"
        :text="t$('operationTypes.cancel')"
        type="primary"
        :disabled="byCardCancelDisabled"
        @click="acquiring.refund(ACQUIRING_OPERATION_TYPE.CANCEL)"
      />

      <!-- Возврат -->
      <m-button
        :text="t$('operationTypes.refund')"
        type="primary"
        :disabled="paymentActionDisabled"
        @click="acquiring.refund(ACQUIRING_OPERATION_TYPE.REFUND)"
      />
    </template>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import MButton from '@/vue_present/_base/buttons/MButton/MButton.vue'
import { AcquiringApi } from '@/vue_apps/FinanceModule/FinanceModuleIndex/classes/Acquiring/AcquiringApi'
import {
  ACQUIRING_PAYMENT_TYPE,
} from '@/vue_apps/FinanceModule/FinanceModuleIndex/consts/Acquiring/AcquiringPaymentType'
import {
  ACQUIRING_OPERATION_TYPE,
} from '@/vue_apps/FinanceModule/FinanceModuleIndex/consts/Acquiring/AcquiringOperationType'
import { i18nScopeMixin } from '@/vue_present/mixins/i18nScopeMixin'

export default defineComponent({
  name: 'AcquiringModalOperationTypeButtons',

  components: { MButton },

  mixins: [i18nScopeMixin],

  props: {
    acquiring: { type: Object as PropType<AcquiringApi>, required: true },
    paymentActionDisabled: Boolean,
    isCancelOrRefund: Boolean,
  },

  data () {
    return {
      ACQUIRING_OPERATION_TYPE,
      ACQUIRING_PAYMENT_TYPE,
    }
  },

  computed: {
    byCardDisabled () {
      return this.paymentActionDisabled || (this.acquiring.paymentType && this.acquiring.paymentType !== ACQUIRING_PAYMENT_TYPE.CARD)
    },

    bySBPDisabled () {
      return this.paymentActionDisabled || (this.acquiring.paymentType && this.acquiring.paymentType !== ACQUIRING_PAYMENT_TYPE.SBP)
    },

    byCardCancelDisabled () {
      return this.paymentActionDisabled || this.acquiring.operationType === ACQUIRING_OPERATION_TYPE.REFUND
    },

    byCardCancelDisabledTooltip () {
      return this.byCardCancelDisabled
        ? this.t$('cancelTransactionInCurrentOperationDayTooltip')
        : null
    },
  },

  created () {
    this.setI18nScope('finance')
  },
})
</script>
