<template>
  <el-collapse-item
    v-loading="loading"
    class="m-collapse-item"
    :class="cssClass"
    :name="name"
    :disabled="disabled"
  >
    <template #title>
      <div class="m-collapse-item__title">
        <m-icon
          v-if="icon"
          v-tooltip="iconTooltip"
          class="m-collapse-item-title__icon"
          no-use-fw
          :icon="icon"
          :color="iconColor"
        />

        <slot name="beforeTitle" />

        <slot name="title">
          <span
            v-tooltip="titleTooltip"
            class="m-collapse-item-title__text"
          >
            {{ title }}
          </span>
        </slot>

        <slot name="afterTitle" />
      </div>
    </template>

    <div class="m-collapse-item__body">
      <slot />
    </div>
  </el-collapse-item>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import MIcon from '@/vue_present/_base/MIcon/MIcon.vue'
import { orNull } from '@/_medods_standart_library/msl'

export default defineComponent({
  name: 'MCollapseItem',

  components: { MIcon },

  props: {
    name: { type: [String, Number], required: true },

    title: { type: String, default: '' },
    useTitleTooltip: Boolean,

    activeIcon: { type: String, default: '' },
    activeIconColor: { type: String, default: '' },
    activeIconTooltip: { type: String, default: '' },

    inactiveIcon: { type: String, default: '' },
    inactiveIconColor: { type: String, default: '' },
    inactiveIconTooltip: { type: String, default: '' },

    arrowPosition: { type: String as PropType<'left' | 'right'>, default: 'left' },

    active: Boolean,
    disabled: Boolean,
    loading: Boolean,
  },

  computed: {
    cssClass () {
      return `m-collapse-item_${this.arrowPosition}-arrow`
    },

    titleTooltip () {
      return orNull(this.useTitleTooltip && this.title)
    },

    icon () {
      return this.active
        ? this.activeIcon
        : this.inactiveIcon || this.activeIcon
    },

    iconColor () {
      return this.active
        ? this.activeIconColor
        : this.inactiveIconColor || this.activeIconColor
    },

    iconTooltip () {
      return this.active
        ? this.activeIconTooltip
        : this.inactiveIconTooltip || this.activeIconTooltip
    },
  },
})
</script>
