import { AcquiringPresenter } from '@/vue_apps/FinanceModule/FinanceModuleIndex/api/AcquiringPresenter'
import {
  TRADE_ACQUIRING_CONFIGURATION_NO_BANK,
} from '@/vue_apps/app_configuration/tradeAcquiring/consts/tradeAcquiringConfigurationItems'

const presenter = new AcquiringPresenter()

export const acquiringStore = {
  namespaced: true,

  state (): IAcquiringStoreState {
    return {
      isLoaded: false,
      hasAcquiringBank: false,
      reconciliationTheResultsWithZMainReport: false,
    }
  },

  getters: {
    vxGetHasAcquiringBank: (state: IAcquiringStoreState) => state.hasAcquiringBank,
    vxGetReconciliationTheResultsWithZmainReport: (state: IAcquiringStoreState) => state.reconciliationTheResultsWithZMainReport,
  },

  mutations: {
    vxSetIsLoaded (state: IAcquiringStoreState, value: boolean) {
      state.isLoaded = value
    },
    vxSetHasAcquiringBank (state: IAcquiringStoreState, value: boolean) {
      state.hasAcquiringBank = value
    },
    vxSetReconciliationTheResultsWithZmainReport (state: IAcquiringStoreState, value: boolean) {
      state.reconciliationTheResultsWithZMainReport = value
    },
  },

  actions: {
    async vxFetchConfigurationsCashRegistersAndAcquiring ({ state, commit }, force = false) {
      if (state.isLoaded && !force) return
      const data = await presenter.fetchAcquiringConfigurations()
      if ('errors' in data) { return }

      commit('vxSetHasAcquiringBank', data.tradeAcquiringBank !== TRADE_ACQUIRING_CONFIGURATION_NO_BANK)
      commit('vxSetReconciliationTheResultsWithZmainReport', data.reconciliationTheResultsWithZMainReport)
      commit('vxSetIsLoaded', true)
    },
  },
}
