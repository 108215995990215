import { ElMessageBoxOptions } from 'element-ui/types/message-box'
import { MessageBox } from 'element-ui'
import { IMMessageBoxReturn } from '@/vue_present/_base/MMessageBox/interfaces/IMMessageBoxReturn'

export class MMessageBoxBase {
  static show (
    kind: 'alert' | 'confirm' | 'prompt',
    message: string,
    config: ElMessageBoxOptions = {}
  ): Promise<IMMessageBoxReturn> {
    return MessageBox[kind](message, config)
      .then((result) =>
        typeof result === 'string'
          ? { [result]: true }
          : { [result.action]: true, value: result.value }
      )
      .catch((result) => ({ [result]: true }))
  }
}
