<template>
  <m-collapse
    :items="items"
    active-icon="open-plan"
    active-icon-color="success"
    inactive-icon="close-plan"
    inactive-icon-color="danger"
    use-title-tooltip
  >
    <template #beforeTitle>
      <span>Что-то перед title - </span>
    </template>

    <template #afterTitle="{ item }">
      <m-button
        template="add"
        size="mini"
        @click.stop="item.loading = true"
      />
    </template>

    <template #body="{ item }">
      <m-input
        :value="item.id"
        label="id"
      />

      <m-input
        :value="item.title"
        label="title"
      />
    </template>
  </m-collapse>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import MCollapse from '@/vue_present/_base/MCollapse/MCollapse.vue'
import MButton from '@/vue_present/_base/buttons/MButton/MButton.vue'
import MInput from '@/vue_present/_base/inputs/MInput/MInput.vue'

export default defineComponent({
  name: 'FrontBookPageMCollapse',

  components: { MInput, MButton, MCollapse },

  data () {
    return {
      items: [
        {
          id: 1,
          title: 'title1 очень очень очень очень очень очень очень очень очень очень очень очень очень очень очень очень очень очень очень очень очень очень очень очень очень очень очень очень очень очень длинное название',
          loading: false,
        },
        {
          id: 2,
          title: 'title2',
          loading: false,
        },
        {
          id: 3,
          title: 'title3',
          loading: false,
        },
        {
          id: 4,
          title: 'title4',
          loading: false,
          disabled: true,
        },
        {
          id: 5,
          title: 'title5',
          loading: false,
        },
      ],
    }
  },
})
</script>
