export const CONFIRM = 'confirm'

/**
 * @typedef {{
 *   title: string,
 *   message: string,
 *   dangerouslyUseHTMLString: boolean,
 *   type: string
 *   iconClass: string
 *   customClass: string
 *   callback: Function
 *   showClose: boolean
 *   beforeClose: Function
 *   distinguishCancelAndClose: boolean
 *   lockScroll: boolean
 *   showCancelButton: boolean
 *   showConfirmButton: boolean
 *   cancelButtonText: string
 *   confirmButtonText: string
 *   cancelButtonClass: string
 *   confirmButtonClass: string
 *   closeOnClickModal: boolean
 *   closeOnPressEscape: boolean
 *   closeOnHashChange: boolean
 *   showInput: boolean
 *   inputPlaceholder: string
 *   inputType: string
 *   inputValue: string
 *   inputPattern: RegExp
 *   inputValidator: Function
 *   inputErrorMessage: string
 *   center: boolean
 *   roundButton: boolean
 * }} MConfirmOptions
 */

/**
 * @param {String} message
 * @param {String} [title]
 * @param {Partial<MConfirmOptions>} [options] - см: https://element.eleme.io/#/en-US/component/message-box#options
 * @return {Promise<undefined | { cancel: true, close: undefined } | { close: true, cancel: undefined }>}
 */
const confirm = (message, title, options) => {
  const _options = {
    confirmButtonClass: 'm-button is-plain',
    cancelButtonClass: 'm-button is-plain',
    ...options,
  }

  const promise = title
    ? window.Econfirm(message, title, _options)
    : window.Econfirm(message, _options)

  return promise.catch((err) => ({ [err]: true }))
}

/**
 * При отмене или закрытии окна возвращается promise с { cancel: true }.
 * Если указана опция distinguishCancelAndClose: true,
 *   то возвращается promise с { close: true } при закрытии и { cancel: true } при отмене.
 * Опция customClass: 'with-title' отображает заголовок.
 */
export const MConfirm = {
  /**
   * @param {String} message
   * @param {Partial<MConfirmOptions>} options
   * @return {Promise<any>}
   */
  success (message, options = {}) {
    const __options = {
      type: 'success',
      confirmButtonText: t('ok'),
      cancelButtonText: t('cancel'),
      confirmButtonClass: 'm-button is-plain el-button--success',
      cancelButtonClass: 'm-button is-plain',
      closeOnClickModal: false,
      showInput: false,
      ...options,
    }

    return confirm(
      message,
      t('dialogs.title.success'),
      __options
    )
  },

  /**
   * @param {String} message
   * @param {Partial<MConfirmOptions>} options
   * @return {Promise<any>}
   */
  info (message, options = {}) {
    const __options = {
      type: 'info',
      confirmButtonText: t('ok'),
      cancelButtonText: t('cancel'),
      confirmButtonClass: 'm-button is-plain m-button__dark',
      cancelButtonClass: 'm-button is-plain',
      closeOnClickModal: false,
      showInput: false,
      ...options,
    }

    return confirm(
      message,
      t('dialogs.title.info'),
      __options
    )
  },

  /**
   * @param {String} message
   * @param {Partial<MConfirmOptions>} options
   * @return {Promise<any>}
   */
  warning (message, options = {}) {
    const __options = {
      type: 'warning',
      confirmButtonText: t('yes'),
      cancelButtonText: t('no'),
      confirmButtonClass: 'm-button is-plain el-button--warning',
      cancelButtonClass: 'm-button is-plain',
      closeOnClickModal: false,
      showInput: false,
      ...options,
    }

    return confirm(
      message,
      t('dialogs.title.warning'),
      __options
    )
  },

  /**
   * @param {String} message
   * @param {Partial<MConfirmOptions>} options
   * @return {Promise<any>}
   */
  error (message, options = {}) {
    const __options = {
      type: 'error',
      confirmButtonText: t('yes'),
      cancelButtonText: t('no'),
      confirmButtonClass: 'm-button is-plain el-button--danger',
      cancelButtonClass: 'm-button is-plain',
      closeOnClickModal: false,
      showInput: false,
      ...options,
    }

    return confirm(
      message,
      t('dialogs.title.error'),
      __options
    )
  },

  confirm,
}

export const MConfirmRenderList = (messages = [], { tag = 'ol', cssClasses = '' } = {}) => {
  const messagesHTML = messages.map((message) => `<li>${message}</li>`).join('')

  return `<${tag} class="${cssClasses}">${messagesHTML}</${tag}>`
}
