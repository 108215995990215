import { getSemd119Entities } from '@/vue_apps/ProtocolsApp/_SemdProtocolEditor/entities/Semd119/getSemd119Entities'
import { SEMD_TYPE, SEMD_TYPE_ENUM } from '@/vue_apps/Semds/const/semdTypes'
import { getSemd224Entities } from '@/vue_apps/ProtocolsApp/_SemdProtocolEditor/entities/Semd224/getSemd224Entities'

export const getSemdEntities = (semdType) => {
  switch (semdType) {
    case SEMD_TYPE_ENUM[SEMD_TYPE.CONSULTATION]: return getSemd119Entities()
    case SEMD_TYPE_ENUM[SEMD_TYPE.INSTRUMENTAL_RESEARCH_PROTOCOL]: return getSemd224Entities()

    default: return []
  }
}
