import {
  ClinicalGuidelineModel,
} from '@/vue_apps/catalogs_root/ClinicalGuidelinesCatalog/classes/ClinicalGuideline/ClinicalGuidelineModel'
import {
  IClinicalGuidelineToServerDTO,
} from '@/vue_apps/catalogs_root/ClinicalGuidelinesCatalog/interfaces/IClinicalGuidelineToServerDTO'
import { extractId, orUndefined } from '@/_medods_standart_library/msl'
import {
  IClinicalGuidelineToClientDTO,
} from '@/vue_apps/catalogs_root/ClinicalGuidelinesCatalog/interfaces/IClinicalGuidelineToClientDTO'
import { CatalogHelpers } from '@/helpers/CatalogHelpers'
import {
  CLINICAL_GUIDELINE_AGE_ITEMS,
} from '@/vue_apps/catalogs_root/ClinicalGuidelinesCatalog/consts/clinicalGuidelineAge'
import {
  CLINICAL_GUIDELINE_RELEASE_STATUS_ITEMS,
} from '@/vue_apps/catalogs_root/ClinicalGuidelinesCatalog/consts/clinicalGuidelineReleaseStatus'
import {
  clinicalGuidelineEntryAdapter,
} from '@/vue_apps/catalogs_root/ClinicalGuidelinesCatalog/api/clinicalGuidelineEntryAdapter'
import { ArrayHelpers } from '@/helpers/ArrayHelpers'

export const clinicalGuidelineAdapter = {
  toClient (data: IClinicalGuidelineToClientDTO) {
    return {
      ...data,
      age: CatalogHelpers.getItemFromCatalogItems(CLINICAL_GUIDELINE_AGE_ITEMS, data.age),
      releaseStatus: CatalogHelpers.getItemFromCatalogItems(CLINICAL_GUIDELINE_RELEASE_STATUS_ITEMS, data.releaseStatus),
      releaseYear: CatalogHelpers.buildCatalog(data.releaseYear),
      clinicalGuidelineEntries: ArrayHelpers.orArray(data.clinicalGuidelineEntries)
        .map(clinicalGuidelineEntryAdapter.toClient),
    }
  },

  toServer (data: ClinicalGuidelineModel): IClinicalGuidelineToServerDTO {
    return {
      title: data.title,
      ministryId: data.ministryId,
      diseaseIds: data.diseases.map(extractId) as number[],
      age: data.age.id,
      releaseYear: data.releaseYear.id,
      releaseStatus: data.releaseStatus.id,
      anamnesis: data.anamnesis,
      ministryUrl: data.ministryUrl,
      categoryId: orUndefined(data.category?.id),
    }
  },
}
