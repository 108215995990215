<template>
  <div class="protocol-entity-connector">
    <entity-draggable-window
      ref="popover"
      :entity="currentEntity"
      @cancel="setCurrentEntity()"
      @save="onSave($event)"
    />

    <diseases-modal
      :panel-type="panelType"
      :use-items="false"
      @onSubmit="onDiseasesCategoriesSubmit"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { mapGetters } from 'vuex'
import {
  BASE_WINDOW_HEIGHT,
  BASE_WINDOW_LEFT,
  BASE_WINDOW_TOP,
  BASE_WINDOW_WIDTH,
} from '@/plugins/dynamic_forms/components/context_menu/editor_mode/ui/TextareaProxyRoot/const'
import Entity from '@/plugins/dynamic_forms/entities/items/Entity'
import {
  draggablePopover,
} from '@/plugins/dynamic_forms/components/context_menu/editor_mode/ui/TextareaProxyRoot/draggable'
import {
  loadModalPositionFromCache,
} from '@/plugins/dynamic_forms/components/context_menu/editor_mode/ui/TextareaProxyRoot/cache'
import EntityDraggableWindow from '@/vue_apps/ProtocolsApp/components/ProtocolEditorView/EntityDraggableWindow.vue'
import DiseasesModal from '@/vue_apps/catalogs_root/Diseases/DiseasesModal.vue'
import { DIAGNOSES_CONSTRUCTOR } from '@/plugins/dynamic_forms/configuration/reducer_types'

export default defineComponent({
  name: 'ProtocolEntityConnector',

  components: {
    DiseasesModal,
    EntityDraggableWindow,
  },

  props: {
    panelType: { type: String, default: 'success' },
  },

  data () {
    return {
      currentEntity: null as Entity | null,
    }
  },

  computed: {
    ...mapGetters('windowStore', {
      windowSize: 'vxGetWindowSize',
    }),
  },

  watch: {
    windowSize ({ clientWidth }) {
      this.setTemplatePopoverPosition({
        left: clientWidth > BASE_WINDOW_WIDTH + BASE_WINDOW_LEFT
          ? BASE_WINDOW_LEFT
          : clientWidth - BASE_WINDOW_WIDTH,
        top: BASE_WINDOW_TOP,
        height: BASE_WINDOW_HEIGHT,
        width: BASE_WINDOW_WIDTH,
      })
    },
  },

  mounted () {
    this.setTemplatePopoverPosition(loadModalPositionFromCache())
    draggablePopover(this.$refs.popover.$el)

    this.$pubSub.subscribe('ProtocolPopoverConnector:Activate', (entity: Entity) => {
      this.setCurrentEntity(entity)
    })

    this.$pubSub.subscribe('ProtocolPopoverConnector:Deactivate', () => {
      this.setCurrentEntity()
    })
  },

  beforeDestroy () {
    this.$pubSub.reset('ProtocolPopoverConnector:Activate')
    this.$pubSub.reset('ProtocolPopoverConnector:Deactivate')
  },

  methods: {
    setCurrentEntity (entity: Entity = null) {
      this.currentEntity = null
      this.$nextTick(() => {
        this.currentEntity = entity
      })
    },

    setTemplatePopoverPosition ({ left, top, height, width }) {
      /** @type {HTMLElement} element */
      const element = this.$refs.popover.$el
      const realPopover = element.querySelector('.m-popover')

      element.style.top = top + 'px'
      element.style.left = left + 'px'
      realPopover.style.height = height + 'px'
      realPopover.style.width = width + 'px'
      element.style.position = 'absolute'
    },

    onSave (value) {
      this.currentEntity.setValue(value)
      this.$pubSub.emit('ProtocolEditorView:onTemplateHtmlChange')
      this.setCurrentEntity()
    },

    onDiseasesCategoriesSubmit (categoryIds: number[]) {
      application.form_builder.current.container.get('entity_manager')
        .build(DIAGNOSES_CONSTRUCTOR, {
          category_ids: categoryIds,
          caretRng: tinymce.activeEditor.selection.getRng(),
        })

      this.$pubSub.emit('ProtocolEditorView:onTemplateHtmlChange')
    },
  },
})
</script>
