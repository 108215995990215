//user modules
import Dispatcher from '@/plugins/dynamic_forms/_core/dispatcher'
import { getMap } from '../configuration/mapping'
import FrontModalTest from '../components/modal/front_test_modal'
import SelectMkbModal from '../components/modal/select_mkb_modal'
import FrontendEditor from '../components/editor/frontend_editor'
import ContextMenu from '../components/context_menu/editor_mode/EditModeContextMenu'
import BaseTemplateBuilder from './BaseTemplateBuilder'
import Api from '../api/Api'
import AssignEntitiesTree from '../tree/assigned_entities_tree'
import SelectMkbTree from '../tree/select_mkb_tree'
import { registerListeners } from '../listeners'
import { entityConfigs } from '../configuration/main_config'
import EntityManager from '../entities/EntityManager'
import EntityFactory from '../entities/factory'

export class FrontTestBuilder extends BaseTemplateBuilder {
  setMapping () {
    const customMap = {
      appId: 'test_edit_mode',
    }
    const baseMap = getMap('frontend')
    this.map = Object.assign(baseMap, customMap)
  }

  initComponents () {
    this.container.add('store', this.store)
    this.container.add('dispatcher', new Dispatcher(this.container))
    const entityManager = new EntityManager(this.container)
    registerListeners(this.container)
    this.container.add('entity_manager', entityManager)
    this.container.add('api', new Api(this.container))
    this.container.add(
      'assigned_items_tree',
      new AssignEntitiesTree(this.container)
    )
    const cMenu = new ContextMenu(this.map, this)
    this.container.add('config', entityConfigs)
    this.container.add('entity_factory', new EntityFactory(this.map, this))
    this.container.add('contextMenu', cMenu)
    this.container.add('editor', new FrontendEditor(this.map, this))
    this.addComponent('contextMenu', cMenu)

    this.addComponent('modal', new FrontModalTest(this.map, this))
    this.container.add('select_mkb_modal', new SelectMkbModal(this.container))
    this.container.add('select_mkb_tree', new SelectMkbTree(this.container))
    window.onresize = () => this._components.modal.resizeBodyContainer()
    const selectMkbModal = this.container.get('select_mkb_modal')
    selectMkbModal.saveButton.on('click', selectMkbModal.save.bind(selectMkbModal))
  }

  create () {
    return this
  }
}
