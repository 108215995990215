<template>
  <m-alert
    :type="alertType"
    :closable="false"
  >
    <m-icon v-bind="icon" />

    <span class="ml-5">
      {{ t$(status) }}
    </span>
  </m-alert>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import MIcon from '@/vue_present/_base/MIcon/MIcon.vue'
import { FISCAL_PRINTER_STATUSES } from '@/vue_apps/catalogs_root/Workplaces/consts/fiscalPrinterStatuses'
import { i18nScopeMixin } from '@/vue_present/mixins/i18nScopeMixin'
import MAlert from '@/vue_present/_base/MAlert/MAlert.vue'

export default defineComponent({
  name: 'FiscalPrinterStatus',

  components: { MAlert, MIcon },

  mixins: [i18nScopeMixin],

  props: {
    status: { type: String as PropType<FISCAL_PRINTER_STATUSES>, required: true },
    type: { type: String as PropType<'kkm' | 'terminal'>, required: true },
  },

  computed: {
    icon () {
      switch (this.status) {
        case FISCAL_PRINTER_STATUSES.IS_CLOSED:
          return {
            icon: 'close-plan',
            color: 'success',
          }

        case FISCAL_PRINTER_STATUSES.IS_OPENED:
        case FISCAL_PRINTER_STATUSES.IS_LESS_24H:
          return {
            icon: this.type === 'kkm'
              ? 'open-plan'
              : 'chartBar',
            color: this.type === 'kkm'
              ? 'warning'
              : 'success',
          }

        case FISCAL_PRINTER_STATUSES.IS_OVER_24H:
          return {
            icon: 'warning',
            color: 'error',
          }

        case FISCAL_PRINTER_STATUSES.UNKNOWN: {
          return {
            icon: 'notReadyCircle',
            color: 'info',
          }
        }

        default:
          return null
      }
    },

    alertType () {
      if (!this.icon) { return }

      return this.icon.color
    },
  },

  created () {
    this.setI18nScope(`fiscal.statuses.${this.type}`)
  },
})
</script>
