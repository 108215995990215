<template>
  <m-modal
    class="tax-certificate-2024-xml-modal"
    modal-title-icon="fileCode"
    type="success"
    width="800px"
    :visible="modalVisible"
    :dialog-title="t$('modalTitle')"
    @close="closeModal()"
  >
    <div class="tc2024-xml-form">
      <!-- Коды -->
      <div class="tc2024-xml-form__section">
        <div class="tc2024-xml-form__row">
          <div class="flex-basis-50 prompt-notice">
            {{ t$('receiverCodeSectionText') }}
          </div>

          <div class="flex-basis-50 prompt-notice">
            {{ t$('receiverInfoCodeSectionText') }}
          </div>
        </div>

        <div class="tc2024-xml-form__row">
          <m-input
            :value="taxCertificate2024Xml.clinicIfnsCode"
            :label="t$('IFNSClinicCode')"
            placeholder="____"
            class="flex-basis-50"
            use-numbers-only
            :max-length="4"
            :required="ifnsValidation"
            validator-name="clinic_ifns_code"
            @registerValidator="onRegisterValidator"
            @input="taxCertificate2024Xml.setValue('clinicIfnsCode', $event)"
          />

          <m-input
            :value="taxCertificate2024Xml.clientIfnsCode"
            :label="t$('IFNSClientCode')"
            class="flex-basis-50"
            placeholder="____"
            use-numbers-only
            :max-length="4"
            :required="ifnsValidation"
            validator-name="client_ifns_code"
            @registerValidator="onRegisterValidator"
            @input="taxCertificate2024Xml.setValue('clientIfnsCode', $event)"
          />
        </div>
      </div>

      <!-- Всё для подписи -->
      <div class="tc2024-xml-form__section">
        <div class="tc2024-xml-form__row prompt-notice">
          {{ t$('signerSectionText') }}
        </div>

        <div class="tc2024-xml-form__row">
          <div class="flex align-baseline gap-indent-small">
            <m-checkbox
              :value="taxCertificate2024Xml.mcdRequired"
              :label="t$('MCDRequired')"
              @input="onMcdRequiredChange"
            />
            <m-icon
              v-tooltip="t$('MCDRequiredTooltip')"
              icon="questionCircle"
              color="gray"
              class="tc2024-xml-form__mcd-icon"
            />
          </div>
        </div>

        <div class="tc2024-xml-form__row">
          <reusable-doctors-list
            :value="taxCertificate2024Xml.signer"
            :fetch-method="fetchUsers()"
            :label="t$('signer')"
            class="flex-basis-50"
            full-width
            use-first-time-fetch
            :disabled="taxCertificate2024Xml.signerLocked"
            :required="taxCertificate2024Xml.mcdRequired"
            validator-name="signer"
            @registerValidator="onRegisterValidator"
            @onDoctorChange="setUser"
          />
          <m-input
            class="flex-basis-50"
            :value="taxCertificate2024Xml.mcdGuid"
            :label="t$('MCDGUID')"
            :disabled="mcdGuidFieldDisabled"
            :required="mcdGuidValidation"
            :placeholder="t$('MCDGUIDPlaceholder')"
            validator-name="mcd_guid"
            @registerValidator="onRegisterValidator"
            @input="taxCertificate2024Xml.setValue('mcdGuid', $event)"
          />
        </div>
      </div>
    </div>

    <template #footer-left>
      <m-button
        template="save"
        :text="t('create')"
        @click="onCreateClick"
      />
    </template>
  </m-modal>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import MModal from '@/vue_present/_base/MModal/MModal.vue'
import MInput from '@/vue_present/_base/inputs/MInput/MInput.vue'
import { i18nScopeMixin } from '@/vue_present/mixins/i18nScopeMixin'
import MCheckbox from '@/vue_present/_base/inputs/MCheckbox/MCheckbox.vue'
import MIcon from '@/vue_present/_base/MIcon/MIcon.vue'
import { ModalMixin } from '@/vue_present/mixins/ModalMixins/ModalMixin'
import {
  TaxCertificate2024XmlAPI,
} from '@/vue_apps/Documents/FixedDocuments/_documents/TaxCertificate2024/classes/TaxCertificate2024Xml/TaxCertificate2024XmlAPI'
import { ValidationParentMixin } from '@/vue_present/mixins/ValidationParentMixin'
import ReusableDoctorsList from '@/vue_present/Reuse/Lists/Doctors/ReusableDoctorsList.vue'
import {
  TaxCertificate2024,
} from '@/vue_apps/Documents/FixedDocuments/_documents/TaxCertificate2024/classes/TaxCertificate2024'
import {
  TaxCertificate2024Presenter,
} from '@/vue_apps/Documents/FixedDocuments/_documents/TaxCertificate2024/api/TaxCertificate2024Presenter'
import MButton from '@/vue_present/_base/buttons/MButton/MButton.vue'
import {
  IUserWithMcdGuid,
} from '@/vue_apps/Documents/FixedDocuments/_documents/TaxCertificate2024/declarations/IUserWithMcdGuid'
import { MConfirm } from '@/vue_present/_base/MConfirm/MConfirm'

export declare interface TaxCertificate2024XmlModalAPI {
  openModal: () => void
}

export default defineComponent({
  name: 'TaxCertificate2024XmlModal',

  components: {
    MButton,
    ReusableDoctorsList,
    MIcon,
    MCheckbox,
    MInput,
    MModal,
  },

  mixins: [
    i18nScopeMixin,
    ModalMixin,
    ValidationParentMixin,
  ],

  props: {
    visible: Boolean,
    taxCertificate2024: TaxCertificate2024,
    currentUser: {
      type: Object as PropType<IUserWithMcdGuid>,
      required: true,
    },
  },

  emits: ['registerApi'],

  data () {
    return {
      taxCertificate2024Xml: new TaxCertificate2024XmlAPI()
        .setValues({
          certificatePayment: this.taxCertificate2024,
          clinicIfnsCode: gon.application.current_clinic.ifns_code,
          signer: this.currentUser,
          mcdGuid: this.currentUser.mcdGuid,
        }),
      clinicDirector: null,
    }
  },

  computed: {
    isMcdRequired () {
      return this.taxCertificate2024Xml.mcdRequired
    },

    userHasMcdGuid () {
      return Boolean(this.taxCertificate2024Xml.signer?.mcdGuid)
    },

    mcdGuidFieldDisabled () {
      return this.taxCertificate2024Xml.signerLocked || !this.isMcdRequired || this.userHasMcdGuid
    },
  },

  created () {
    this.setI18nScope('fixedDocument.taxCertificate2024.xml')
    this.fetchDirector()

    this.$emit('registerApi', {
      openModal: this.openModal.bind(this),
    })
  },

  methods: {
    fetchUsers () {
      return (params) => new TaxCertificate2024Presenter().fetchUsers({
        ...params,
        searchString: params.searchQuery,
      })
    },

    async fetchDirector () {
      const director = await new TaxCertificate2024Presenter()
        .fetchDirector(gon.application.current_clinic.id)
      if (!director) { return }

      this.clinicDirector = director
    },

    setUser (user) {
      this.taxCertificate2024Xml.setValue('signer', user)
      this.taxCertificate2024Xml.setValue('mcdGuid', user?.mcdGuid)
    },

    onMcdRequiredChange (value: boolean) {
      const signer = value
        ? this.currentUser
        : this.clinicDirector

      if (value) {
        this.setUser(signer)
      }

      this.taxCertificate2024Xml.setMcdRequired(
        value,
        this.taxCertificate2024,
        signer
      )
    },

    isValidGuid () {
      const guid = this.taxCertificate2024Xml?.mcdGuid
      const guidPattern = /^[\da-fA-F]{8}-[\da-fA-F]{4}-[\da-fA-F]{4}-[\da-fA-F]{4}-[\da-fA-F]{12}$/

      return guid && guid.length > 35 && guidPattern.test(guid)
    },

    mcdGuidValidation () {
      return !this.taxCertificate2024Xml.mcdRequired
        ? []
        : this.isValidGuid()
          ? []
          : [this.t$('incorrectLength36')]
    },

    ifnsValidation (value) {
      if (!value) { return [this.t$('notFilled')] }

      return value.length === 4
        ? []
        : [this.t$('incorrectLength4')]
    },

    async onCreateClick () {
      if (this.hasValidationErrors()) { return }

      const body = await this.taxCertificate2024Xml.generate()
      if (body?.errors) { return this.resetValidations(body.errors) }

      if (this.taxCertificate2024Xml.mcdRequired && !this.taxCertificate2024Xml.signer.mcdGuid) {
        await this.onUserMcdGuidChange()
      }

      this.taxCertificate2024.setValue('xmlGenerated', true)
      this.closeModal()
    },

    async onUserMcdGuidChange () {
      const { cancel } = await MConfirm.warning(this.t$('saveMcdGuidToUser?'))
      if (cancel) { return }

      const result = new TaxCertificate2024Presenter()
        .submitMcdGuid(
          this.taxCertificate2024Xml.signer.id,
          { mcd_guid: this.taxCertificate2024Xml.mcdGuid }
        )

      if (result?.errors) { return }

      this.taxCertificate2024Xml.setValue('signer.mcdGuid', this.taxCertificate2024Xml.mcdGuid)
    },
  },
})
</script>
