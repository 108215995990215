<template>
  <m-card class="protocol-draft-card">
    <m-icon
      class="protocol-draft-card__icon"
      icon="history"
      color="primary"
      no-use-fw
    />

    <div class="protocol-draft-card__content">
      <div class="protocol-draft-card__content-title">
        {{ t('protocols.foundDraft') }}
      </div>
      <div class="flex justify-content-end gap-indent-small mt-indent-mid">
        <m-button
          :text="t('protocols.restoreDraft')"
          type="primary"
          size="mini"
          @click="$emit('yes')"
        />
        <m-button
          :text="t('protocols.removeDraft')"
          type="danger"
          class="danger"
          size="mini"
          @click="$emit('no')"
        />
      </div>
    </div>
  </m-card>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import MCard from '@/vue_present/_base/MCard/MCard.vue'
import MIcon from '@/vue_present/_base/MIcon/MIcon.vue'
import MButton from '@/vue_present/_base/buttons/MButton/MButton.vue'

export default defineComponent({
  name: 'ProtocolDraftCard',

  components: {
    MButton,
    MIcon,
    MCard,
  },

  emits: [
    'yes',
    'no',
  ],
})
</script>
