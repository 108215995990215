import { ICRUDReturn } from '@/_api/decorators/api/interfaces/ICRUDReturn'
import { Api } from '@/_api/decorators/api/Api'
import {
  TWorkplacesListItemToClientDTO,
} from '@/vue_apps/catalogs_root/Workplaces/interfaces/IWorkplacesListItemToClientDTO'
import { IWorkplacesListToServerDTO } from '@/vue_apps/catalogs_root/Workplaces/interfaces/IWorkplacesListToServerDTO'

export class FiltersPresenter {
  @Api.notify('fetchMessage', 'workplaces', { success: false })
  @Api.list(Routes.list_api_internal_workplaces_path)
  fetchWorkplaces (payload: Partial<IWorkplacesListToServerDTO> = {}) {
    return {
      payload,
    } as ICRUDReturn as Promise<TWorkplacesListItemToClientDTO>
  }
}
