<template>
  <m-popover
    :disabled="canManage.disabled"
    :message="message"
    @yes="$emit('yes')"
    @no="$emit('no')"
  >
    <template #reference>
      <m-button
        v-if="useButton"
        :disabled="disabled"
        :button-style="buttonStyle"
        :template="template"
        :text="buttonText"
        :size="size"
      />
      <m-icon
        v-else
        v-tooltip="canManage.tooltip || t('delete')"
        :class="icon.cursorClass"
        icon="delete"
        :color="icon.color"
        @click.stop="() => {}"
      />
    </template>
  </m-popover>
</template>

<script>
import MPopover from '@/vue_present/_base/MPopover/MPopover.vue'
import MIcon from '@/vue_present/_base/MIcon/MIcon.vue'
import { DisabledMixin } from '@/vue_present/mixins/DisabledMixin'
import { props } from '@/vue_present/_base/buttons/MButtonDelete/props'
import MButton from '@/vue_present/_base/buttons/MButton/MButton.vue'
import { disabledIconConfig, activeIconConfig } from './const'

export default {
  name: 'MButtonDelete',

  components: { MButton, MIcon, MPopover },

  mixins: [DisabledMixin],

  props,

  emits: [
    'yes',
    'no',
  ],

  computed: {
    buttonText () {
      return this.useText
        ? this.text
        : ''
    },

    icon () {
      return this.canManage.disabled
        ? disabledIconConfig
        : activeIconConfig
    },

    message () {
      return this.usePopoverMessageEntry
        ? t('deleteConfirmationWithEntry', { entryName: this.popoverMessage })
        : this.popoverMessage
    },
  },
}
</script>
