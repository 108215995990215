import {
  ClinicalGuidelineModel,
} from '@/vue_apps/catalogs_root/ClinicalGuidelinesCatalog/classes/ClinicalGuideline/ClinicalGuidelineModel'
import { TClinicalGuidelineProps } from '@/vue_apps/catalogs_root/ClinicalGuidelinesCatalog/interfaces/types'
import { ICatalog } from '@/_declarations/ICatalog'
import { orNull } from '@/_declarations/orNull'

export class ClinicalGuidelineLogic extends ClinicalGuidelineModel {
  fillProps (props: TClinicalGuidelineProps) {
    const { clinicalGuidelineEntries, ...restProps } = props

    super._fillProps(restProps)
    super.setValue('originCategory', props.category)

    if (!clinicalGuidelineEntries) { return props }
    this.clinicalGuidelineEntries.set(clinicalGuidelineEntries)

    return props
  }

  updateOriginCategory (value = this.category) {
    super.setValue('originCategory', value)
  }

  setCategory (value: orNull<ICatalog<number>>, manual: boolean = false) {
    super.setValue('category', value)
    super.setValue('categoryManual', manual)
  }
}
