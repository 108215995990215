import { WorkplaceModel } from '@/vue_apps/catalogs_root/Workplaces/classes/Workplace/WorkplaceModel'
import { IWorkplace } from '@/vue_apps/catalogs_root/Workplaces/interfaces/IWorkplace'
import { IUser } from '@/_declarations/IUser'
import { IError } from '@/_declarations/IError'
import { IWorkplaceValidationErrors } from '@/vue_apps/catalogs_root/Workplaces/interfaces/IWorkplaceValidationErrors'
import { snakeToCamel } from '@/_api/_requests/helpers'

export class WorkplaceLogic extends WorkplaceModel {
  removeUser (user: IUser) {
    delete this.users[user.id]

    this.users = { ...this.users }
  }

  protected setKey (workplace: IWorkplace | IError) {
    if ('errors' in workplace) { return }

    this.key = workplace.key
  }

  protected submitCallback (data: IWorkplace | IError) {
    return 'errors' in data
      ? this.submitErrorCallback(snakeToCamel(data))
      : this.submitSuccessCallback(data)
  }

  private submitSuccessCallback (data: IWorkplace) {
    this.fillProps(data)

    return data
  }

  private submitErrorCallback (data: IError) {
    this.resetUserErrors()

    if ('userIds' in data.errors) {
      this.setUsersErrors(data as IWorkplaceValidationErrors)
    }

    return data
  }

  private setUsersErrors ({ errors }: IWorkplaceValidationErrors) {
    errors.userIds.forEach((userErrorObj) =>
      Object.entries(userErrorObj)
        .forEach(([userId, errors]) =>
          this.users[userId].setValidationErrors(errors)))
  }

  private resetUserErrors () {
    Object.values(this.users)
      .forEach((user) => user.resetValidationErrors())
  }
}
