import { MPresenterBase } from '@/_api/_requests/MPresenterBase'
import { clinicAdapter } from './clinicAdapter'

export class MClinicPresenter extends MPresenterBase {
  constructor () {
    super({
      location: 'MClinicPresenter',
      entity: 'clinic',
      routes: {
        all: Routes.clinics_path,
        one: Routes.clinic_path,
        list: Routes.catalogs_clinics_list_path,
      },
    })
  }

  submit (data) {
    return super.submit(data, {
      toServerAdapter: clinicAdapter.toServer,
      toJson: true,
    })
  }

  fetch (id) {
    return super.fetch(id, {
      toClientAdapter: clinicAdapter.toClient,
    })
  }
}
