<template>
  <div class="protocol-diagnose-constructor-view flex flex-column overflow-x-hidden">
    <m-search-input-lazy
      :list="diseasesList"
      clear-input-after-item-click
      option-label="fullTitle"
      @onItemClick="onNodeClick"
    />
    <el-tree
      :data="treeData"
      :load="loadNode"
      :props="treeProps"
      lazy
      @node-click="onNodeClick"
    >
      <template #default="{ node, data }">
        <lazy-tree-node-component
          :data="data"
          :node="node"
          class="py-indent-small"
        >
          <template #title>
            <span
              v-tooltip="{ tooltip: data.title, placement: 'top-start' }"
              class="cut"
            >
              <template v-if="!data.isLeaf">
                {{ data.title }}
              </template>

              <template v-else>
                <b>{{ data.code_string }}</b>
                <span>{{ data.title }}</span>
              </template>
            </span>
          </template>
        </lazy-tree-node-component>
      </template>
    </el-tree>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import Api from '@/plugins/dynamic_forms/api/Api'
import DiagnoseConstructor from '@/plugins/dynamic_forms/entities/items/diagnose_constructor'
import {
  createTreeFromNestedArray,
} from '@/plugins/dynamic_forms/components/context_menu/editor_mode/ui/TextareaProxyRoot/logic'
import LazyTreeNodeComponent from '@/vue_present/Reuse/LazyTree/components/LazyTreeNodeComponent.vue'
import { ICatalog } from '@/_declarations/ICatalog'
import { LazyTreeNode } from '@/vue_present/Reuse/LazyTree/store/LazyTreeNode'
import EntityManager from '@/plugins/dynamic_forms/entities/EntityManager'
import { DIAGNOSE } from '@/plugins/dynamic_forms/configuration/reducer_types'
import Diagnose from '@/plugins/dynamic_forms/entities/items/Diagnose'
import { IElTreeNode } from '@/_declarations/IElTreeNode'
import MSearchInputLazy from '@/vue_present/_base/MSearchInputLazy/MSearchInputLazy.vue'
import { MListServiceApi } from '@/_api/_requests/MListServiceApi/MListServiceApi'
import {
  Disease,
} from '@/vue_apps/ProtocolsApp/components/ProtocolEditorView/EntitiesViews/ProtocolDiagnoseConstructorView/Disease'
import { CategoriesSearchStrategy } from '@/_api/_requests/MListServiceApi/strategies/CategoriesSearchStrategy'

let api: Api

const ROOT = 0
export default defineComponent({
  name: 'ProtocolDiagnoseConstructorView',

  components: {
    MSearchInputLazy,
    LazyTreeNodeComponent,
  },

  props: {
    entity: { type: Object as PropType<DiagnoseConstructor>, required: true },
  },

  data () {
    return {
      treeData: [],
      treeProps: {
        label: 'title',
        isLeaf: 'isLeaf',
        children: 'children',
      },

      diseasesList: new MListServiceApi({
        src: Routes.catalogs_diseases_path(),
        DataItemEntity: Disease,
        defaultFilters: { title: '' },
        Strategy: CategoriesSearchStrategy,
      }),
    }
  },

  created () {
    api = this.entity.container.get('api')
    this.fetchCategories()
  },

  methods: {
    async fetchCategories () {
      const categoriesIds = this.entity.data.categories_ids || []

      this.treeData = (createTreeFromNestedArray(
        await api.getAssignDiagnosesCategories(categoriesIds)
      ) as any[]).map((catalog) => ({ ...catalog, children: [] }))
    },

    async loadNode (node: IElTreeNode, resolve) {
      const id = (node.data as ICatalog).id
      if (!id) { return }

      const result = await api.diseases(id)
      const categoriesIds = this.entity.data.categories_ids || []
      const catalogs = result.catalogs.map((catalog) => ({
        ...catalog,
        title: `${catalog.info} ${catalog.title}`,
        children: [],
      }))
        .filter((catalog) =>
          categoriesIds.includes(catalog.parent_id) ||
            categoriesIds.includes(catalog.id) ||
            categoriesIds[0] === ROOT
        )

      const items = result.items.map((item) => ({
        ...item,
        isLeaf: true,
      }))

      resolve(catalogs.concat(items))
    },

    onNodeClick (item: LazyTreeNode) {
      if (!item.isLeaf) { return }

      if (this.__hasDiagnosis(item.id)) {
        return Notificator.info(t('diagnosis_already_exist'))
      }

      const entityManager: EntityManager = this.entity.container.get('entity_manager')
      const diagnose: Diagnose = entityManager
        .build(DIAGNOSE, { item, emitter: this.entity.element })

      diagnose.showValue()
      Services.pubSub.emit('ProtocolEditorView:onTemplateHtmlChange')
    },

    __hasDiagnosis (diagnoseId) {
      const diagnoses = this.entity.container.get('store').getState().diagnoses

      return diagnoses.find((e) => e.item.id === diagnoseId)
    },
  },
})
</script>
