import { DIAGNOSES_CONSTRUCTOR } from '../../configuration/reducer_types'
import { EntityCollection } from '@/plugins/dynamic_forms/_core/libs/tree'

export default class MkbModal {
  constructor (container) {
    this.container = container
    this.entityManager = this.container.get('entity_manager')
    this.tree = this.container.get('diseases_tree')

    this.modal = ModalFactory({
      btnResize: false,
      title: t('select_categories'),
    })
    this.window = this.modal.find('.modal-body')
    this.window.append($(this.tpl))
    this.mountPoint = this.modal.find('.body-container')
    this.modalBody = this.window.find('.mount-body')
    this.saveButton = this.modal.find('.modal-save')
    this.saveButton.text(t('add'))
  }

  mount (tree, categories = false, isNew = false, emitter) {
    this.isNew = isNew
    this.emitter = emitter
    this.container.get('diseases_tree').build(
      tree,
      this.mountPoint,
      categories
    )
  }

  /**
   * base behaviour
   */
  show () {
    this.modal.megamodal('show')
    this.caretRng = tinymce.activeEditor.selection.getRng()
  }

  /**
   * base behaviour
   */
  hide () {
    this.modal.megamodal('hide')
  }

  save () {
    const selectedNodes = this.container.get('diseases_tree').selectedNodes
    const itemCollection = new EntityCollection(selectedNodes)
    const ids = itemCollection.get('id')
    const cbFilter = (node) => !ids.includes(node.parent.data.id) && node.folder
    const assignItemsIds = itemCollection.filter(cbFilter).get('id')

    if (assignItemsIds.length > 0) {
      if (this.isNew) {
        this.addDiseaseConstructor(assignItemsIds)
      } else {
        this.entity = this.container.get('entity_manager').getEntity(this.emitter)
        this.container.get('store').dispatch({
          type: this.entity.actions.CHANGE,
          payload: {
            id: this.entity.id,
            categories: assignItemsIds,
          },
        })
      }
      this.hide()
    } else {
      Notificator.info(t('select_categories'))
    }
  }

  addDiseaseConstructor (ids) {
    return new Promise((resolve, reject) => {
      const entity = this.entityManager.build(DIAGNOSES_CONSTRUCTOR, { category_ids: ids, caretRng: this.caretRng })
      if (entity) {
        resolve(entity)
      }
    }).catch((err) => console.debug(err))
  }

  get tpl () {
    return '<div class=\'body-container\'></div>'
  }
}
