import { AcquiringLogic } from '@/vue_apps/FinanceModule/FinanceModuleIndex/classes/Acquiring/AcquiringLogic'
import { ICRUDReturn } from '@/_api/decorators/api/interfaces/ICRUDReturn'
import { Api } from '@/_api/decorators/api/Api'
import {
  ACQUIRING_PAYMENT_TYPE,
} from '@/vue_apps/FinanceModule/FinanceModuleIndex/consts/Acquiring/AcquiringPaymentType'
import { Callback } from '@/_api/decorators/callback'
import { IAcquiringWSMeta } from '@/vue_apps/FinanceModule/FinanceModuleIndex/interfaces/Acquiring/IAcquiringWSMeta'
import { ACQUIRING_OPERATION } from '@/vue_apps/FinanceModule/FinanceModuleIndex/consts/Acquiring/AcquiringOperation'
import { acquiringAdapter } from '@/vue_apps/FinanceModule/FinanceModuleIndex/api/acquiringAdapter'
import { ACQUIRING_ACTION } from '@/vue_apps/FinanceModule/FinanceModuleIndex/consts/Acquiring/AcquiringAction'
import { IAcquiringData } from '@/vue_apps/FinanceModule/FinanceModuleIndex/interfaces/Acquiring/IAcquiringData'
import {
  IAcquiringSBPStatus,
} from '@/vue_apps/FinanceModule/FinanceModuleIndex/interfaces/Acquiring/IAcquiringSBPStatus'
import {
  IAcquiringRequestAwaitListItem,
} from '@/vue_apps/FinanceModule/FinanceModuleIndex/interfaces/Acquiring/IAcquiringRequestAwaitListItem'
import { TAcquiringWSData } from '@/vue_apps/FinanceModule/FinanceModuleIndex/interfaces/Acquiring/IAcquiringWSData'
import {
  ACQUIRING_OPERATION_TYPE,
} from '@/vue_apps/FinanceModule/FinanceModuleIndex/consts/Acquiring/AcquiringOperationType'
import { ACQUIRING_STATUS_CODE } from '@/vue_apps/FinanceModule/FinanceModuleIndex/consts/Acquiring/AcquiringStatus'
import { IAcquiringErrors } from '@/vue_apps/FinanceModule/FinanceModuleIndex/interfaces/Acquiring/IAcquiringErrors'

export class AcquiringApi extends AcquiringLogic {
  constructor () {
    super()

    this.subscribeToWS(
      this.wsHandler.bind(this),
      this.wsErrorsHandler.bind(this),
      this.externalRequestHandler.bind(this)
    )
  }

  @Callback(function (data: IAcquiringWSMeta | IAcquiringErrors) {
    this.requestHandler(ACQUIRING_OPERATION.PRINT_LAST_OPERATION_RECEIPT, data)
  }, true)
  @Api.post(Routes.api_internal_acquiring_trade_operations_copy_of_receipt_path)
  printLastOperationReceipt () {
    return {
      payload: {
        lastReceipt: true,
        workplaceId: this.workplaceId,
      },
    } as ICRUDReturn
  }

  @Callback(function () {
    this.addInitialTerminalLog(ACQUIRING_OPERATION.PRINT_SLIP)
  }, true)
  @Api.post(Routes.print_slip_api_internal_acquiring_trade_transaction_path)
  printSlip () {
    return {
      routeArgs: [this.lastTransaction.id],
    } as ICRUDReturn
  }

  @Callback('forceCompleteHandler', true)
  @Api.post(Routes.force_complete_api_internal_acquiring_trade_transaction_path)
  forceComplete () {
    return {
      routeArgs: [this.lastTransaction.id],
      payload: {
        status: this.manualOperationType.id,
      },
    } as ICRUDReturn
  }

  pay (paymentType: ACQUIRING_PAYMENT_TYPE) {
    super.setValue('lastTransaction', null)
    super.setValue('status', ACQUIRING_STATUS_CODE.IN_PROGRESS)
    super.setValue('paymentType', paymentType)

    const paymentAttributes = this.payment.getAttributes()

    if (paymentType === ACQUIRING_PAYMENT_TYPE.CARD) {
      return this.payCard(paymentAttributes)
    }

    return this.paySBP(paymentAttributes)
  }

  refund (operationType: ACQUIRING_OPERATION_TYPE = this.operationType) {
    super.setValue('lastTransaction', null)
    super.setValue('status', ACQUIRING_STATUS_CODE.IN_PROGRESS)

    return this.payment.lastTransaction.paymentType === ACQUIRING_PAYMENT_TYPE.CARD
      ? operationType === ACQUIRING_OPERATION_TYPE.CANCEL
        ? this.cancelCard()
        : this.refundCard()
      : this.refundSBP()
  }

  fetchSBPStatus () {
    return this.operationType === ACQUIRING_OPERATION_TYPE.PAY
      ? this.fetchSBPOperationStatus()
      : this.fetchSBPRefundStatus()
  }

  @Callback(function (data: IAcquiringWSMeta | IAcquiringErrors) {
    this.requestHandler(ACQUIRING_OPERATION.SBP_PAYMENT_STATUS, data)
  }, true)
  @Api.post(Routes.payment_status_api_internal_acquiring_trade_operations_sbp_path)
  private fetchSBPOperationStatus () {
    return {
      payload: {
        transactionId: this.lastTransaction.id,
      },
    } as ICRUDReturn
  }

  @Callback(function (data: IAcquiringWSMeta | IAcquiringErrors) {
    this.requestHandler(ACQUIRING_OPERATION.SBP_REFUND_STATUS, data)
  }, true)
  @Api.post(Routes.refund_status_api_internal_acquiring_trade_operations_sbp_path)
  private fetchSBPRefundStatus () {
    return {
      payload: {
        transactionId: this.lastTransaction.id,
      },
    } as ICRUDReturn
  }

  @Callback(function (data: IAcquiringWSMeta | IAcquiringErrors) {
    this.requestHandler(ACQUIRING_OPERATION.REFUND_CARD, data)
  }, true)
  @Api.post(Routes.refund_api_internal_acquiring_trade_operations_card_path)
  private refundCard () {
    return {
      payload: {
        transactionId: this.payment.lastTransaction.id,
        payment: this.payment.getAttributes(),
      },
    } as ICRUDReturn
  }

  @Callback(function (data: IAcquiringWSMeta | IAcquiringErrors) {
    this.requestHandler(ACQUIRING_OPERATION.REFUND_SBP, data)
  }, true)
  @Api.post(Routes.refund_api_internal_acquiring_trade_operations_sbp_path)
  private refundSBP () {
    return {
      payload: {
        transactionId: this.payment.lastTransaction.id,
        payment: this.payment.getAttributes(),
      },
    } as ICRUDReturn
  }

  @Callback(function (data: IAcquiringWSMeta | IAcquiringErrors) {
    this.requestHandler(ACQUIRING_OPERATION.CANCEL_CARD, data)
  }, true)
  @Api.post(Routes.cancel_api_internal_acquiring_trade_operations_card_path)
  private cancelCard () {
    return {
      payload: {
        transactionId: this.payment.lastTransaction.id,
        payment: this.payment.getAttributes(),
      },
    } as ICRUDReturn
  }

  @Callback(function (data: IAcquiringWSMeta | IAcquiringErrors) {
    this.requestHandler(ACQUIRING_OPERATION.PAY_CARD, data)
  }, true)
  @Api.post(Routes.pay_api_internal_acquiring_trade_operations_card_path)
  @Api.serverAdapter(acquiringAdapter)
  private payCard (paymentAttributes: Record<string, unknown>) {
    return {
      payload: {
        payment: paymentAttributes,
      },
    } as ICRUDReturn
  }

  @Callback(function (data: IAcquiringWSMeta | IAcquiringErrors) {
    this.requestHandler(ACQUIRING_OPERATION.PAY_SBP, data)
  }, true)
  @Api.post(Routes.pay_api_internal_acquiring_trade_operations_sbp_path)
  @Api.serverAdapter(acquiringAdapter)
  private paySBP (paymentAttributes: Record<string, unknown>) {
    return {
      payload: {
        payment: paymentAttributes,
      },
    } as ICRUDReturn
  }

  private wsHandler (action: ACQUIRING_ACTION, awaitRequestListItem: IAcquiringRequestAwaitListItem, data: TAcquiringWSData) {
    switch (action) {
      case ACQUIRING_ACTION.CHECKING_CONNECTION: return this.setRefundOperationType()

      case ACQUIRING_ACTION.PAY:
      case ACQUIRING_ACTION.REFUND:
      case ACQUIRING_ACTION.CANCEL:
        this.startSBPFetchStatusTimeout(
          awaitRequestListItem,
          data,
          this.fetchSBPOperationStatus.bind(this),
          this.fetchSBPRefundStatus.bind(this)
        )

        return this.transactionHandler(awaitRequestListItem, data as IAcquiringData)

      case ACQUIRING_ACTION.PAYMENT_STATUS: return this.sbpStatusHandler(data as IAcquiringSBPStatus, awaitRequestListItem, this.fetchSBPOperationStatus.bind(this))
      case ACQUIRING_ACTION.REFUND_STATUS: return this.sbpStatusHandler(data as IAcquiringSBPStatus, awaitRequestListItem, this.fetchSBPRefundStatus.bind(this))
    }
  }
}
