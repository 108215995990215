<template>
  <m-panel
    class="acquiring-page"
    :title="t$('acquiringOperations')"
    icon="billed"
  >
    <m-si-generator
      v-if="showPage"
      v-loading="list.loading"
      :items="list.data"
      :si-generator-schema="schema"
      :current-page="list.currentPage"
      :page-count="list.totalPages"
      :total="list.totals"
      @update:currentPage="list.setPage($event)"
      @onRowClick="onRowClick"
    >
      <template #filters>
        <acquiring-filters :list="list" />
      </template>
    </m-si-generator>

    <introduction
      v-else
      :introduction-text="introductionText"
      :can-view="canView"
      icon="fad fa-credit-card"
    />

    <context-popup
      ref="contextMenu"
      v-click-outside="onClickOutside"
      class="acquiring-page__context-menu"
    >
      <!-- Статус операции вручную -->
      <m-select
        :value="manualTransactionStatus"
        :items="ACQUIRING_MANUAL_TRANSACTION_STATUS_ITEMS"
        :label="t$('transactionStatusManually')"
        full-width
        @change="manualTransactionStatus = $event"
      />

      <m-button
        template="save"
        full-width
        @click="onForceComplete"
      />

      <m-button
        template="close"
        @click="onClickOutside"
      />
    </context-popup>
  </m-panel>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import MPanel from '@/vue_present/_base/MPanel/MPanel.vue'
import { i18nScopeMixin } from '@/vue_present/mixins/i18nScopeMixin'
import MSiGenerator from '@/vue_present/_base/Tables/MSiGenerator/MSiGenerator.vue'
import { MListServiceApi } from '@/_api/_requests/MListServiceApi/MListServiceApi'
import { AcquiringListItem } from '@/vue_apps/FinanceModule/FinanceModuleIndex/classes/Acquiring/AcquiringListItem'
import { getAcquiringSchema } from '@/vue_apps/FinanceModule/FinanceModuleIndex/consts/Acquiring/getAcquiringSchema'
import AcquiringFilters from '@/vue_apps/FinanceModule/FinanceModuleIndex/components/AcquiringFilters.vue'
import {
  getAcquiringDefaultFilters,
} from '@/vue_apps/FinanceModule/FinanceModuleIndex/consts/Acquiring/getAcquiringDefaultFilters'
import { acquiringListAdapter } from '@/vue_apps/FinanceModule/FinanceModuleIndex/api/acquiringListAdapter'
import { IAcquiringFilters } from '@/vue_apps/FinanceModule/FinanceModuleIndex/interfaces/Acquiring/IAcquiringFilters'
import { AcquiringPresenter } from '@/vue_apps/FinanceModule/FinanceModuleIndex/api/AcquiringPresenter'
import ContextPopup from '@/vue_present/Reuse/ContextPopup/ContextPopup.vue'
import MSelect from '@/vue_present/_base/inputs/MSelect/MSelect.vue'
import {
  ACQUIRING_MANUAL_TRANSACTION_STATUS_ITEMS,
} from '@/vue_apps/FinanceModule/FinanceModuleIndex/consts/Acquiring/AcquiringManualTransactionStatusItems'
import MButton from '@/vue_present/_base/buttons/MButton/MButton.vue'
import { ACQUIRING_ERROR } from '@/vue_apps/FinanceModule/FinanceModuleIndex/consts/Acquiring/AcquiringError'
import {
  IAcquiringTransaction,
} from '@/vue_apps/FinanceModule/FinanceModuleIndex/interfaces/Acquiring/IAcquiringTransaction'
import { IError } from '@/_declarations/IError'
import { ACQUIRING_STATUS } from '@/vue_apps/FinanceModule/FinanceModuleIndex/consts/Acquiring/AcquiringStatus'
import {
  AcquiringListITotalItem,
} from '@/vue_apps/FinanceModule/FinanceModuleIndex/classes/Acquiring/AcquiringListITotalItem'
import Introduction from '@/vue_components/introduction/introduction.vue'
import { MAlert } from '@/vue_present/_base/MMessageBox/MAlert'

export default defineComponent({
  name: 'AcquiringPage',

  components: {
    Introduction,
    MButton,
    MSelect,
    ContextPopup,
    AcquiringFilters,
    MSiGenerator,
    MPanel,
  },

  mixins: [i18nScopeMixin],

  data () {
    return {
      ACQUIRING_MANUAL_TRANSACTION_STATUS_ITEMS,
      schema: getAcquiringSchema(),
      acquiringPresenter: new AcquiringPresenter(),

      list: new MListServiceApi<AcquiringListItem, IAcquiringFilters>({
        src: Routes.api_internal_finances_trade_acquiring_transactions_path(),
        DataItemEntity: AcquiringListItem,
        TotalsItemEntity: AcquiringListITotalItem,
        defaultFilters: getAcquiringDefaultFilters(),
        adapter: acquiringListAdapter,
        cacheKey: 'finance-acquiring-list',
      }),

      idTransaction: null,
      manualTransactionStatus: null,
    }
  },

  computed: {
    canView () {
      return Services.security.canViewAcquiringTrade
    },

    canManage () {
      return Services.security.canManageAcquiringTrade
    },

    showPage () {
      return this.canView && this.$m.modules.tradeAcquiring
    },

    introductionText () {
      return this.$m.modules.tradeAcquiring
        ? t('introduction.module_connected_by_not_authorized')
        : t('introduction.trade_acquiring')
    },
  },

  created () {
    this.setI18nScope('finance')
    this.list.fetchAll()
  },

  methods: {
    onRowClick ({ item, event }: { item: AcquiringListItem; event: PointerEvent }) {
      if (item.status !== ACQUIRING_STATUS.EXECUTION_ERROR) { return }
      if (!this.canManage) { return this.showCantManageAlert() }

      this.idTransaction = item.id
      this.manualTransactionStatus = null

      setTimeout(() => this.$refs.contextMenu.open(event.clientX, event.clientY))
    },

    showCantManageAlert () {
      return MAlert.warning(t('introduction.access_denied'), { dangerouslyUseHTMLString: true })
    },

    onClickOutside () {
      this.$refs.contextMenu.close()
    },

    async onForceComplete () {
      if (!this.manualTransactionStatus) { return }
      const data: IAcquiringTransaction | IError = await this.acquiringPresenter.forceComplete(this.idTransaction, this.manualTransactionStatus.id)
      const hasErrors = 'errors' in data
      const hasTransaction = hasErrors && 'transaction' in data.errors

      if (!hasErrors || (hasErrors && !hasTransaction)) {
        return this.list.setPage(this.list.currentPage)
      }

      if (data.errors.transaction !== ACQUIRING_ERROR.REQUIRED_STATUS_IS_EXECUTION_ERROR) { return }
      Utils.reportError(null, this.t$('errors.requiredStatusIsExecutionError'))()

      return this.list.setPage(this.list.currentPage)
    },
  },
})
</script>
