import { tScoped } from '@/helpers/i18nHelpers'
import { ACQUIRING_STATUS } from '@/vue_apps/FinanceModule/FinanceModuleIndex/consts/Acquiring/AcquiringStatus'

const ts = tScoped('finance.transactionStatus')

export enum ACQUIRING_MANUAL_TRANSACTION_STATUS {
  SUCCESS_MANUALLY = ACQUIRING_STATUS.SUCCESS,
  FAILURE_MANUALLY = ACQUIRING_STATUS.FAILURE,
}

export const ACQUIRING_MANUAL_TRANSACTION_STATUS_ITEMS = [
  {
    id: ACQUIRING_MANUAL_TRANSACTION_STATUS.SUCCESS_MANUALLY,
    title: ts('approvedManually'),
  },
  {
    id: ACQUIRING_MANUAL_TRANSACTION_STATUS.FAILURE_MANUALLY,
    title: ts('rejectedManually'),
  },
]
