import { MListServiceItem } from '@/_api/_requests/MListServiceItem'
import { IWorkplaceUser } from '@/vue_apps/catalogs_root/Workplaces/interfaces/IWorkplaceUser'
import { MTableCell } from '@/vue_present/_base/Tables/MTableGenerator/MTableCell'
import { IWorkplaceValidationErrorsUser } from '@/vue_apps/catalogs_root/Workplaces/interfaces/IWorkplaceValidationErrors'

export class WorkplaceUsersListItem extends MListServiceItem {
  avatar: MTableCell

  fullName: MTableCell

  shortName: string

  status: MTableCell

  specialties: MTableCell

  validationErrorsIcon: MTableCell = new MTableCell()

  constructor (item: IWorkplaceUser) {
    super()

    this.id = item.id

    this.avatar = new MTableCell()
      .addTypeUserWithAvatar(this.id, +item.avatarTag)

    this.fullName = new MTableCell(item.fullName)
      .addTypeUrl(Routes.edit_user_path(this.id))
      .addTooltip(true)
      .addCut()

    this.shortName = item.shortName

    this.specialties = new MTableCell(this.extractSpecialtiesTitles(item.specialties || []))
      .addTooltip(true)
      .addCut()

    this.status = new MTableCell(item.status?.title || '')
      .addTooltip(true)
      .addCut()
  }

  setValidationErrors (errors: IWorkplaceValidationErrorsUser) {
    const tooltip = Object.entries(errors)
      .map(([key, value]) => `${t(key)}: ${value.join(', ')}`).join('\n')

    this.validationErrorsIcon = new MTableCell()
      .addTypeIcon('warning')
      .addClasses('danger')
      .addTooltip(tooltip)
  }

  resetValidationErrors () {
    this.validationErrorsIcon = new MTableCell()
  }

  private extractSpecialtiesTitles (specialties: IWorkplaceUser['specialties']) {
    return specialties.map(({ title }) => title).join(', ')
  }
}
