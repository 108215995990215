<template>
  <m-card class="protocol-editor-view m-indent">
    <m-card
      class="protocol-editor-view__form"
      use-footer
    >
      <protocol-form
        v-loading="loading"
        :protocol="protocol"
        class="pt-6"
        @registerValidator="onRegisterValidator"
        @semdTypeChange="onSemdTypeChange"
      >
        <m-select
          v-if="flags.requireLegalRepresentative"
          :value="protocol.selectedLegalRepresentativeId"
          class="mt-indent-validation"
          full-width
          option-label="shortInfo"
          use-custom-result="simple"
          :items="client.legalRepresentatives"
          :label="t('legal_representative')"
          :placeholder="t('enums.unselected.he')"
          required
          validator-name="selectedLegalRepresentativeId"
          @changeItem="protocol.setValue('selectedLegalRepresentativeId', $event.id)"
          @registerValidator="onRegisterValidator"
        />

        <template #entries>
          <protocol-entries
            v-tooltip="protocolEntries"
            :value="protocol.entries"
            :client-id="client.id"
            without-consumables
            @change="protocol.setValue('entries', $event)"
          />
        </template>

        <transition name="fade">
          <protocol-draft-card
            v-if="protocolDraftData"
            class="mt-15"
            @yes="restoreDraft"
            @no="dropDraft"
          />
        </transition>

        <semd-modal-wrapper
          v-if="protocolReady && protocol.semdType"
          class="mt-auto mb-indent"
          type="button"
          :source="protocol"
          :is-source-changed="flags.protocolChanged"
          :before-open-semd="submitBeforeOpenSemd()"
        >
          <template #source-connector="{ semds }">
            <semds-source-connector
              :source="protocol"
              :semds="semds"
              @registerApi="connectorApi = $event"
            />
          </template>
        </semd-modal-wrapper>
      </protocol-form>

      <template #footer>
        <m-button-submit
          :disabled="loading || submitDisabled"
          @click="onSubmitClick"
        />
        <m-button
          :text="t('close')"
          type="primary"
          icon="close"
          @click="onCancelClick"
        />
      </template>
    </m-card>

    <div
      v-loading="loading"
      class="flex flex-column"
    >
      <div
        id="frontend-toolbar"
        class="fix-toolbar"
      />
      <div
        id="editor-body"
        class="frontend-form__editor-body editor-body"
      >
        <div
          id="frontend-mount-point"
          ref="frontendMountPoint"
          class="editor-mount"
        />
      </div>
    </div>

    <protocol-entity-connector v-if="protocol" />
  </m-card>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import { IClient } from '@/_declarations/IClient'
import { ProtocolApi } from '@/vue_apps/ProtocolsApp/entities/ProtocolApi'
import MCard from '@/vue_present/_base/MCard/MCard.vue'
import ApplicationFactory from '@/plugins/dynamic_forms/application_factory'
import { ProtocolBuilder } from '@/vue_apps/ProtocolsApp/entities/ProtocolBuilder'
import ProtocolForm from '@/vue_apps/ProtocolsApp/components/AddProtocolButton/ProtocolForm.vue'
import MButtonSubmit from '@/vue_present/_base/buttons/MButtonSubmit/MButtonSubmit.vue'
import MButton from '@/vue_present/_base/buttons/MButton/MButton.vue'
import { ValidationParentMixin } from '@/vue_present/mixins/ValidationParentMixin'
import { PROTOCOLS_ID } from '@/vue_apps/MedicalRecords/MedicalRecordsTabs/components/DocumentsTab/consts'
import { EApplicationBuilderType } from '@/plugins/dynamic_forms/declarations/EApplicationBuilderType'
import ProtocolEntityConnector from '@/vue_apps/ProtocolsApp/components/ProtocolEditorView/ProtocolEntityConnector.vue'
import EntityManager from '@/plugins/dynamic_forms/entities/EntityManager'
import { SpinnerHolder } from '@/vue_components/mixins/spinner_holder'
import MSelect from '@/vue_present/_base/inputs/MSelect/MSelect.vue'
import { SemdListServiceApi } from '@/vue_apps/ProtocolsApp/entities/SemdListServiceApi'
import ProtocolEntries from '@/vue_apps/ProtocolsApp/components/ProtocolEditorView/ProtocolEntries.vue'
import { IMedicalRecord } from '@/_declarations/IMedicalRecord'
import { onNew } from '@/vue_apps/ProtocolsApp/logic/ProtocolEditorView/onNew'
import { TProtocolQueryParams } from '@/vue_apps/ProtocolsApp/entities/AddProtocolFabric'
import {
  onSubmitPrepareProtocolEntities,
} from '@/vue_apps/ProtocolsApp/logic/ProtocolEditorView/onSubmitPrepareProtocolEntities'
import { onConfirmCancel } from '@/vue_apps/ProtocolsApp/logic/ProtocolEditorView/onConfirmCancel'
import {
  onSubmitPrepareProtocolData,
} from '@/vue_apps/ProtocolsApp/logic/ProtocolEditorView/onSubmitPrepareProtocolData'
import { onUpdateSemdDetails } from '@/vue_apps/ProtocolsApp/logic/ProtocolEditorView/onUpdateSemdDetails'
import { onConfirmSemdLayoutClear } from '@/vue_apps/ProtocolsApp/logic/onConfirmSemdLayoutClear'
import { ProtocolDraftStorage } from '@/plugins/dynamic_forms/components/editor/ProtocolDraftStorage'
import { TTinyMCEEditor } from '@/vue_apps/ProtocolsApp/_SemdProtocolEditor/interfaces/TTinyMCE'
import { debounce } from 'lodash'
import { GLOBAL_DOUBLE_DEBOUNCE_DEFAULT } from '@/_global_scripts/GLOBAL_CONSTS'
import { extractId } from '@/_medods_standart_library/msl'
import ProtocolDraftCard from '@/vue_apps/ProtocolsApp/components/ProtocolEditorView/ProtocolDraftCard.vue'
import { IProtocol } from '@/vue_apps/ProtocolsApp/declarations/IProtocol'
import { WindowCreatedByController } from '@/vue_apps/ProtocolsApp/logic/WindowCreatedByController'
import { EAddProtocolSources } from '@/vue_apps/ProtocolsApp/consts/addProtocolSources'
import SemdModalWrapper from '@/vue_apps/Semds/SemdModal/SemdModalWrapper.vue'
import { PROTOCOLS_ROUTE_NAMES } from '@/vue_apps/ProtocolsApp/router/protocolsRouteNames'
import SemdsSourceConnector from '@/vue_apps/Semds/SemdSourceConnector/SemdsSourceConnector.vue'
import {
  ISemdsSourceConnectorAPI,
} from '@/vue_apps/Semds/SemdSourceConnector/declarations/semdsSourceConnectorDeclarations'

let protocolBuilder: ProtocolBuilder = null

export default defineComponent({
  name: 'ProtocolEditorView',

  components: {
    SemdsSourceConnector,
    SemdModalWrapper,
    ProtocolDraftCard,
    ProtocolForm,
    ProtocolEntries,
    MSelect,
    ProtocolEntityConnector,
    MButton,
    MButtonSubmit,
    MCard,
  },

  mixins: [
    ValidationParentMixin,
    SpinnerHolder,
  ],

  props: {
    medicalRecord: { type: Object as PropType<IMedicalRecord>, required: true },
    id: { type: Number, default: null },
    client: { type: Object as PropType<IClient>, required: true },
  },

  emits: [
    'setProtocolTitle',
  ],

  data () {
    return {
      protocol: new ProtocolApi(),
      semdList: null as SemdListServiceApi,
      protocolDraftStorage: new ProtocolDraftStorage(this.id, this.client.id),
      protocolDraftData: null as IProtocol<unknown>,
      connectorApi: null as ISemdsSourceConnectorAPI,

      flags: {
        requireLegalRepresentative: false,
        protocolChanged: false,
        editorReady: false,
        semdEntitiesAppReady: false,
      },

      submitDisabled: false,
    }
  },

  computed: {
    panelTitle () {
      return [
        this.client.fullname,
        `${t('emk')}:`,
        this.client.emkNumber,
        ' - ',
        this.protocol.title,
      ].join(' ')
    },

    protocolEntries () {
      return (this.protocol.entries || []).map(({ title }) => title).join(' | ')
    },

    protocolReady () {
      return this.flags.editorReady && this.flags.semdEntitiesAppReady
    },
  },

  created () {
    this.$pubSub.subscribe('Editor:SemdReady', () => {
      this.flags.semdEntitiesAppReady = true
    })

    this.$pubSub.subscribe('ProtocolEditorView:onTemplateHtmlChange', this.onTemplateHtmlChange.bind(this))
  },

  async mounted () {
    this.startLoading()
    this.protocol = this.id
      ? await this.loadProtocol()
      : await onNew({ query: this.$route.query as TProtocolQueryParams, clientId: this.client.id })

    if (!this.id) {
      this.flags.protocolChanged = true
    }

    this.protocolDraftData = this.protocolDraftStorage.restore()
    if (this.protocolDraftData && this.$route.query[EAddProtocolSources.byDraft]) {
      await this.restoreDraft()
    }

    this.$emit('setProtocolTitle', this.panelTitle)

    if (this.protocolDraftData && this.$route.query[EAddProtocolSources.byDraft]) { return }
    this.$nextTick(() => { this.initEditor() })
  },

  beforeDestroy () {
    ;[
      'Editor:SemdReady',
      'FrontendEditor:KeyUp',
      'ProtocolEditorView:onTemplateHtmlChange',
    ].forEach((eventName) => { this.$pubSub.unsubscribe(eventName) })

    Object.keys(this.flags)
      .forEach((flag) => { this.flags[flag] = false })

    protocolBuilder = null
  },

  methods: {
    async initEditor () {
      await tinymce.remove()

      this.$refs.frontendMountPoint.innerHTML =
          this.protocol.templateHtml
            .replace(/<p>(?:(?!\uFEFF)(?:&nbsp;|\s|<br>))*<\/p>/g, ' ')
            .replace(/\r\n/g, ' ')
            .replace(/\n/g, '<br>')

      protocolBuilder = ApplicationFactory.generate(EApplicationBuilderType.frontend)
      protocolBuilder.setProtocolApi(this.protocol)
      ;(protocolBuilder.container.get('entity_manager') as EntityManager)
        .load(this.protocol.storeState)

      this.setLegalRepresentative()

      protocolBuilder.editor.prepareEntities() /* прежде всего чтобы не потерять "Обычные переменные" */
      await protocolBuilder.editor.run()

      await this.$nextTick()

      protocolBuilder.editor.prepareEntities()
      protocolBuilder.editor.update()

      this.stopLoading()

      this.flags.editorReady = true

      // @ts-ignore
      ;(protocolBuilder.editor._tinymce as TTinyMCEEditor)
        .on('input keyup', this.onTemplateHtmlChange.bind(this))
    },

    async onSemdTypeChange (semdType) {
      if (this.protocol.semdType && this.protocol.semdEntities.length) {
        const { cancel } = await onConfirmSemdLayoutClear()
        if (cancel) { return }
      }

      this.protocol.setValue('semdType', semdType)
      this.__saveDraft()
    },

    async onSubmitClick ({ stayOnPage = false } = { stayOnPage: false }) {
      if (this.hasValidationErrors()) { return }

      if (this.protocol.semdType) {
        const { cancel } = await this.connectorApi.beforeEdit()
        if (cancel) { return }
      }

      this.startLoading('submitDisabled')

      onSubmitPrepareProtocolEntities(protocolBuilder.container.get('entity_manager'))
      this.prepareProtocolData()

      const submitResult = await this.withSpinner(this.protocol.save())
      if (submitResult?.errors) { return }

      if (this.protocol.semdType) {
        const updateDetailsResult = await this.withSpinner(onUpdateSemdDetails(this.protocol))
        if (updateDetailsResult?.errors) { return }
      }

      this.dropDraft()

      await this.protocol.generatePdf()

      this.flags.protocolChanged = false

      this.$pubSub.emit(
        'ProtocolEditorView:ProtocolChanged',
        {
          id: this.protocol.id,
          entryIds: this.protocol.entries.map(extractId),
        },
        true
      )

      this.stopLoading('submitDisabled')

      !stayOnPage && await this.onCompleteSubmit()
      if (stayOnPage) {
        return { success: true }
      }
    },

    async onCompleteSubmit () {
      if (WindowCreatedByController.is('entries')) {
        return window.close()
      }

      await this.$router.push(
        Routes.protocols_page_path(this.medicalRecord.id, this.protocol.id, { anchor: `${PROTOCOLS_ID}` })
      )
    },

    prepareProtocolData () {
      const { templateHtml, templateData } =
          onSubmitPrepareProtocolData(protocolBuilder, this.$refs.frontendMountPoint)
      this.protocol.setValue('templateHtml', templateHtml || '<p></p>')
      this.protocol.setValue('templateData', templateData)
      this.protocol.setValue('clientId', this.client.id)
    },

    async onCancelClick () {
      if (!this.flags.protocolChanged) { return this.back() }

      const { cancel, close } = await onConfirmCancel()
      if (close) { return }
      if (cancel) {
        this.dropDraft()
        this.back()

        return
      }

      await this.onSubmitClick()
    },

    async loadProtocol () {
      const protocol = new ProtocolApi({ id: this.id })
      await protocol.load()

      this.$route.query.newSignatureApproved && protocol.setValue('newSignatureApproved', true)
      this.$route.query.newSemdApproved && protocol.setValue('newSemdApproved', true)
      Utils.URL.silentClearSearchParams()

      this.semdList = new SemdListServiceApi({
        ownerType: protocol.sourceType,
        ownerId: protocol.id,
      })

      this.semdList.fetchAll().then()

      return protocol
    },

    setLegalRepresentative () {
      this.removeValidator('selectedLegalRepresentativeId')
      this.flags.requireLegalRepresentative = protocolBuilder.store.getState()
        .entries_variable
        .some((e) => e.value?.startsWith('legal_representative'))

      if (this.flags.requireLegalRepresentative) {
        this.resetValidations({ selectedLegalRepresentativeId: [] })
        this.protocol.setValue(
          'selectedLegalRepresentativeId',
          this.client.legalRepresentatives[0]?.id || null
        )
      }
    },

    onTemplateHtmlChange () {
      if (!this.protocolReady) { return }
      this.flags.protocolChanged = true
      this.saveDraft()
    },

    async restoreDraft () {
      this.protocol.fillProps(this.protocolDraftData)
      this.protocol.buildStoreState()
      await this.initEditor()
      this.resetProtocolDraftData()
    },

    saveDraft: debounce(function () { this.__saveDraft() }, GLOBAL_DOUBLE_DEBOUNCE_DEFAULT),

    __saveDraft () {
      if (!this.protocolReady) { return }
      this.prepareProtocolData()
      this.protocolDraftStorage.save(this.protocol.getAttributes())
    },

    resetProtocolDraftData () {
      this.protocolDraftData = null
    },

    dropDraft () {
      this.resetProtocolDraftData()
      this.protocolDraftStorage.drop()
    },

    back () {
      window.close()
    },

    submitBeforeOpenSemd () {
      const vm = this

      return async () => {
        if (!vm.flags.protocolChanged) { return }

        const res = await vm.onSubmitClick({ stayOnPage: true })
        protocolBuilder.container.get('entity_manager').showValues()
        if (!vm.id) {
          await vm.$router.replace({ name: PROTOCOLS_ROUTE_NAMES.EDIT, params: { id: vm.protocol.id } })
        }

        return { cancel: !res?.success }
      }
    },
  },
})
</script>
