<template>
  <div class="clinical-guideline-collapse-item-labeled-value">
    <b class="clinical-guideline-collapse-item-labeled-value__label">
      {{ label.trim() }}:
    </b>

    <span
      class="clinical-guideline-collapse-item-labeled-value__value"
      :class="valueCssClass"
    >
      {{ value }}
    </span>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ClinicalGuidelineCollapseItemLabeledValue',

  props: {
    label: { type: String, default: '' },
    value: { type: [String, Number], default: '' },
    valueCssClass: { type: String, default: '' },
  },
})
</script>
