<template>
  <textarea-proxy
    :id="id"
    ref="popover"
    :content-value.sync="contentValue"
    :target="target"
    :tree-data="treeData"
    :name="name"
    @save="save"
    @close="close"
  />
</template>

<script>
import TextareaProxy
  from '@/plugins/dynamic_forms/components/context_menu/editor_mode/ui/TextareaProxyRoot/TextareaProxy'
import {
  draggablePopover,
} from '@/plugins/dynamic_forms/components/context_menu/editor_mode/ui/TextareaProxyRoot/draggable'
import {
  loadModalPositionFromCache,
} from '@/plugins/dynamic_forms/components/context_menu/editor_mode/ui/TextareaProxyRoot/cache'
import { mapGetters } from 'vuex'
import {
  BASE_WINDOW_HEIGHT,
  BASE_WINDOW_LEFT,
  BASE_WINDOW_TOP,
  BASE_WINDOW_WIDTH,
} from '@/plugins/dynamic_forms/components/context_menu/editor_mode/ui/TextareaProxyRoot/const'
import { getReduxStore } from '@/specific/entries/utils'

let reduxStore = null

export default {
  name: 'TextAreaProxyRoot',

  components: { TextareaProxy },

  props: {
    id: {
      type: String,
      required: true,
    },

    target: {
      type: HTMLElement,
      required: true,
    },

    actions: {
      type: Object,
      required: true,
    },

    elementOffset: { type: Object, default: null },

    defaultValue: { type: String, default: '' },

    api: { type: Function, default: null },

    name: { type: String, default: null },
  },

  data () {
    return {
      treeData: null,
      contentValue: this.defaultValue,
    }
  },

  computed: {
    ...mapGetters('windowStore', {
      windowSize: 'vxGetWindowSize',
    }),

    targetData () {
      return {
        id: +this.target.dataset.id,
      }
    },
  },

  watch: {
    windowSize ({ clientWidth }) {
      this.setTemplatePopoverPosition({
        left: clientWidth > BASE_WINDOW_WIDTH + BASE_WINDOW_LEFT
          ? BASE_WINDOW_LEFT
          : clientWidth - BASE_WINDOW_WIDTH,
        top: BASE_WINDOW_TOP,
        height: BASE_WINDOW_HEIGHT,
        width: BASE_WINDOW_WIDTH,
      })
    },
  },

  async created () {
    reduxStore = getReduxStore()
    this.treeData = await this.api()
  },

  mounted () {
    this.setTemplatePopoverPosition(loadModalPositionFromCache())
    draggablePopover(this.$refs.popover.$el)
  },

  methods: {
    close () {
      this.$parent.$destroy()
    },

    save () {
      const target = this.target
      target.innerText = this.contentValue
      this.saveState()
      this.close()
    },

    saveState () {
      reduxStore.dispatch({
        type: this.actions.UPDATE,
        payload: {
          id: this.targetData.id,
          value: this.contentValue,
        },
      })
    },

    setTemplatePopoverPosition ({ left, top, height, width }) {
      /** @type {HTMLElement} element */
      const element = this.$refs.popover.$el
      const realPopover = element.querySelector('.m-popover')

      element.style.top = top + 'px'
      element.style.left = left + 'px'
      realPopover.style.height = height + 'px'
      realPopover.style.width = width + 'px'
      element.style.position = 'absolute'
    },
  },
}
</script>
