import { TDateString } from '@/_declarations/TDateString'
import {
  IAcquiringTransaction,
} from '@/vue_apps/FinanceModule/FinanceModuleIndex/interfaces/Acquiring/IAcquiringTransaction'
import {
  IAcquiringSearchTransaction,
} from '@/vue_apps/FinanceModule/FinanceModuleIndex/interfaces/Acquiring/IAcquiringSearchTransaction'

export const isTransactionInCurrentOperationDay = (transaction: IAcquiringTransaction | IAcquiringSearchTransaction, lastReconciliationOfResults?: TDateString) => {
  if (!lastReconciliationOfResults) return true

  const transactionMoment = moment(transaction.createdAt)
  const lastReconciliationOfResultsMoment = moment(lastReconciliationOfResults)

  return transactionMoment.isAfter(lastReconciliationOfResultsMoment)
}
