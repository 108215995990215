<template>
  <div class="acquiring-modal">
    <m-modal
      width="770px"
      :visible="modalVisible"
      :dialog-title="t$('acquiring')"
      modal-title-icon="billed"
      @close="onCloseModal"
    >
      <div class="acquiring-modal__info">
        <!-- Тип операции -->
        <m-input
          :value="operationTypeTitle"
          :label="t$('operationType')"
          readonly
          :m-fixed-height="false"
        />

        <!-- Сумма операции -->
        <m-input
          :label="t$('operationSum')"
          :value="amount"
          readonly
          :m-fixed-height="false"
        />

        <!-- Нормер терминала -->
        <m-input
          :value="acquiring.terminalId"
          :label="t('finance.terminalId')"
          full-width
          readonly
          :m-fixed-height="false"
        />
      </div>

      <m-prompt-notice
        v-if="isCancelOrRefund"
        :text="t$('cancelOrRefundPromptNotice')"
        class="mb-indent-mid"
        type="alert"
      />

      <div class="flex gap-indent-mid mb-indent-mid">
        <!-- Карта\СБП\\Отмена\Возврат -->
        <acquiring-modal-operation-type-buttons
          :acquiring="acquiring"
          :payment-action-disabled="paymentActionDisabled"
          :is-cancel-or-refund="isCancelOrRefund"
        />

        <acquiring-terminal-connection
          :acquiring="acquiring"
        />
      </div>

      <!-- Связь с терминалом -->
      <m-textarea
        class="mb-indent-mid"
        :value="terminalLogs"
        :label="t$('terminalLogs')"
        :placeholder="t$('terminalLog.initialMessage')"
        :rows="6"
        readonly
        :m-fixed-height="false"
      />

      <div class="flex gap-indent-mid">
        <!-- Статус операции -->
        <m-input
          v-tooltip="acquiring.transactionDescription"
          :value="acquiring.transactionDescription"
          :label="t$('operationStatus')"
          class="acquiring-modal__long-field"
          readonly
        />

        <!-- Запрос статуса оплаты СБП -->
        <m-button
          v-tooltip="fetchSBPStatusTooltip"
          type="primary"
          icon="reset"
          :disabled="!fetchSBPStatusEnabled"
          @click="acquiring.fetchSBPStatus()"
        />

        <!-- Слип-чека -->
        <m-button
          :text="t$('slipReceipt')"
          type="primary"
          :disabled="!slipReceiptEnabled"
          @click="acquiring.printSlip()"
        />

        <!-- Слип-чек последней операции -->
        <m-button
          class="acquiring-modal__button-full"
          type="primary"
          :text="t$('slipReceiptLastTransaction')"
          :disabled="!slipReceiptLastTransactionEnabled"
          @click="acquiring.printLastOperationReceipt()"
        />
      </div>

      <m-prompt-notice
        :text="t$('manualReceiptNotice')"
        class="mb-indent-validation"
      />

      <m-alert
        v-show="!manualOperationTypeDisabled"
        class="mb-indent-validation-important"
        show-icon
        type="error"
        :text="t$('transactionStatusAlert')"
      />

      <div class="flex gap-indent-mid mb-indent-mid">
        <!-- Статус операции вручную -->
        <m-select
          class="acquiring-modal__long-field"
          :value="acquiring.manualOperationType"
          :label="t$('transactionStatusManually')"
          :items="ACQUIRING_MANUAL_TRANSACTION_STATUS_ITEMS"
          :disabled="manualOperationTypeDisabled"
          :m-fixed-height="false"
          @change="acquiring.setValue('manualOperationType', $event)"
        />

        <!-- Изменить статус операции -->
        <m-button
          v-loading="forceCompleteSpinner"
          type="warning"
          icon="edit"
          :text="t$('changeTransactionStatusManually')"
          :disabled="!acquiring.manualOperationType"
          @click="onForceComplete()"
        />
      </div>

      <template #footer-right>
        <close-button
          :disabled="closeModalDisabled"
          @close="onCloseModal"
        />
      </template>
    </m-modal>

    <div
      v-if="payment.isLastTransactionCancelOrRefundAllowed || payment.distributor.baseKind === PAYMENT_BASE_KIND.FUND"
      v-loading="loading"
      class="flex gap-indent-mid align-items-center"
    >
      <m-button
        v-tooltip="openModalDisabledWithTooltip.tooltip"
        icon="billed"
        :type="openModalButtonStyle"
        :disabled="openModalDisabledWithTooltip.disabled"
        @click="openModal"
      />

      <span v-if="operationType !== ACQUIRING_OPERATION_TYPE.PAY">
        {{ t$('acquiringRefundNotice') }}
      </span>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import MModal from '@/vue_present/_base/MModal/MModal.vue'
import { i18nScopeMixin } from '@/vue_present/mixins/i18nScopeMixin'
import MButton from '@/vue_present/_base/buttons/MButton/MButton.vue'
import MInput from '@/vue_present/_base/inputs/MInput/MInput.vue'
import { AcquiringApi } from '@/vue_apps/FinanceModule/FinanceModuleIndex/classes/Acquiring/AcquiringApi'
import MTextarea from '@/vue_present/_base/inputs/MTextarea/MTextarea.vue'
import MPromptNotice from '@/vue_present/_base/MPromptNotice/MPromptNotice.vue'
import MSelect from '@/vue_present/_base/inputs/MSelect/MSelect.vue'
import {
  ACQUIRING_MANUAL_TRANSACTION_STATUS_ITEMS,
} from '@/vue_apps/FinanceModule/FinanceModuleIndex/consts/Acquiring/AcquiringManualTransactionStatusItems'
import { ModalMixin } from '@/vue_present/mixins/ModalMixins/ModalMixin'
import {
  IAcquiringOpenModalDisabledWithTooltip,
} from '@/vue_apps/FinanceModule/FinanceModuleIndex/interfaces/Acquiring/IAcquiringOpenModalDisabledWithTooltip'
import {
  ACQUIRING_PAYMENT_TYPE,
} from '@/vue_apps/FinanceModule/FinanceModuleIndex/consts/Acquiring/AcquiringPaymentType'
import {
  ACQUIRING_OPERATION_TYPE,
  ACQUIRING_OPERATION_TYPE_TITLES,
} from '@/vue_apps/FinanceModule/FinanceModuleIndex/consts/Acquiring/AcquiringOperationType'
import { ACQUIRING_STATUS_CODE } from '@/vue_apps/FinanceModule/FinanceModuleIndex/consts/Acquiring/AcquiringStatus'
import CloseButton from '@/vue_components/common/buttons/CloseButton.vue'
import { ACQUIRING_OPERATION } from '@/vue_apps/FinanceModule/FinanceModuleIndex/consts/Acquiring/AcquiringOperation'
import {
  ACQUIRING_TERMINAL_CONNECTION_STATUS,
} from '@/vue_apps/FinanceModule/FinanceModuleIndex/consts/Acquiring/AcquiringTerminalConnectionStatus'
import { reportErrorText, reportSuccessText } from '@/_api/_requests/helpers'
import { PaymentApi } from '@/vue_apps/FinanceModule/FinanceModuleIndex/classes/Payment/PaymentApi'
import { PAYMENT_BASE_KIND } from '@/vue_apps/FinanceModule/FinanceModuleIndex/consts/paymentBaseKind'
import { SpinnerHolder } from '@/vue_components/mixins/spinner_holder'
import AcquiringModalOperationTypeButtons
  from '@/vue_apps/FinanceModule/FinanceModuleIndex/components/Payment/Acquiring/AcquiringModalOperationTypeButtons.vue'
import MAlert from '@/vue_present/_base/MAlert/MAlert.vue'
import AcquiringTerminalConnection
  from '@/vue_present/Reuse/AcquiringTerminalConnection/AcquiringTerminalConnection.vue'

export default defineComponent({
  name: 'AcquiringModal',

  components: {
    AcquiringTerminalConnection,
    AcquiringModalOperationTypeButtons,
    MAlert,
    CloseButton,
    MSelect,
    MPromptNotice,
    MTextarea,
    MInput,
    MButton,
    MModal,
  },

  mixins: [
    i18nScopeMixin,
    ModalMixin,
    SpinnerHolder,
  ],

  props: {
    payment: PaymentApi,
    operationType: { type: String as PropType<ACQUIRING_OPERATION_TYPE>, default: null },
  },

  data () {
    return {
      PAYMENT_BASE_KIND,
      ACQUIRING_OPERATION_TYPE,
      ACQUIRING_OPERATION_TYPE_TITLES,
      ACQUIRING_MANUAL_TRANSACTION_STATUS_ITEMS,
      ACQUIRING_PAYMENT_TYPE,
      acquiring: new AcquiringApi(),
      forceCompleteSpinner: false,
    }
  },

  computed: {
    isAcquiringEnabledAndConfigured () {
      return this.$m.modules.tradeAcquiring &&
          !this.acquiring.isConnectionStatusNotConnected() &&
          this.$store.getters['acquiringStore/vxGetHasAcquiringBank']
    },

    isTerminalConnected () {
      return this.acquiring.isConnectionStatusActive()
    },

    isCancelOrRefund () {
      if (!this.payment.lastTransaction) return false
      const allowedOperations = this.payment.lastTransaction.allowedOperations

      return allowedOperations[ACQUIRING_OPERATION_TYPE.CANCEL] && allowedOperations[ACQUIRING_OPERATION_TYPE.REFUND]
    },

    operationTypeTitle () {
      if (this.isCancelOrRefund) {
        return this.t$('operationTypes.cancelOrRefund')
      }

      return ACQUIRING_OPERATION_TYPE_TITLES[this.acquiring.operationType]
    },

    openModalDisabledWithTooltip (): IAcquiringOpenModalDisabledWithTooltip {
      if (!this.$m.modules.tradeAcquiring) {
        return {
          tooltip: this.t$('tradeAcquiringModuleDisabled'),
          disabled: true,
        }
      }

      if (!this.isAcquiringEnabledAndConfigured) {
        return {
          tooltip: this.t$('acquiringOperationsNotAvailableSetUpAcquiring'),
          disabled: true,
        }
      }

      if (!this.payment.distributor.byCard && this.operationType) {
        return {
          tooltip: this.t$('amountNotEntered'),
          disabled: true,
        }
      }

      return {
        tooltip: this.t$('acquiringOperations'),
        disabled: false,
      }
    },

    amount () {
      return this.$filters.currency(this.payment.distributor.byCard || this.payment.lastTransaction?.amount || 0)
    },

    openModalButtonStyle () {
      return this.operationType === ACQUIRING_OPERATION_TYPE.PAY
        ? 'primary'
        : 'warning'
    },

    terminalLogs () {
      return this.acquiring.terminalLogs.toReversed().join('\n')
    },

    fetchSBPStatusEnabled () {
      return this.isAwaitRequestListEmpty &&
          this.acquiring.lastTransaction &&
          this.acquiring.paymentType === ACQUIRING_PAYMENT_TYPE.SBP &&
          this.acquiring.status === ACQUIRING_STATUS_CODE.IN_PROGRESS
    },

    fetchSBPStatusTooltip () {
      return this.fetchSBPStatusEnabled
        ? this.t$('fetchSBPStatusEnableTooltip')
        : this.t$('fetchSBPStatusDisableTooltip')
    },

    slipReceiptEnabled () {
      return this.acquiring.lastTransaction?.allowedOperations?.printReceipt && this.isAwaitRequestListEmpty
    },

    slipReceiptLastTransactionEnabled () {
      return this.isTerminalConnected && this.isAwaitRequestListEmpty
    },

    manualOperationTypeDisabled () {
      return !this.acquiring.lastTransaction?.id || this.acquiring.status !== ACQUIRING_STATUS_CODE.EXECUTION_ERROR
    },

    isAwaitRequestListEmpty () {
      return !Object.keys(this.acquiring.awaitRequestList).length
    },

    closeModalDisabled () {
      return !this.isAwaitRequestListEmpty && (this.acquiring.status === ACQUIRING_STATUS_CODE.IN_PROGRESS ||
          (this.acquiring.status === ACQUIRING_STATUS_CODE.EXECUTION_ERROR && !this.acquiring.manualOperationType))
    },

    paymentActionDisabled () {
      // Лочим кнопки оплат(карта\СБП) и возрата. (Пометка: без this.acquiring.status === ACQUIRING_STATUS_CODE.SUCCESS будут активны оплаты\возрата после успешного выполнения операции)
      return !this.isTerminalConnected || this.acquiring.status === ACQUIRING_STATUS_CODE.IN_PROGRESS || this.acquiring.status === ACQUIRING_STATUS_CODE.SUCCESS
    },
  },

  async created () {
    this.setI18nScope('finance')
    this.acquiring.setValue('payment', this.payment)

    if (!this.$m.modules.tradeAcquiring) { return }
    await this.acquiring.checkForTerminalAvailability()

    this.operationType
      ? this.initFund()
      : await this.withSpinner(this.initRefund())
  },

  methods: {
    async initCheckConnection () {
      if (!this.isAcquiringEnabledAndConfigured) return

      this.acquiring.setConnectionStatus(ACQUIRING_TERMINAL_CONNECTION_STATUS.CONNECTING)

      this.acquiring.requestHandler(
        ACQUIRING_OPERATION.CHECKING_CONNECTION,
        await this.acquiring.checkingConnectionRaw(),
        {
          useLog: false,
          acquiringFailureTimeoutCallback: () => {
            this.acquiring.setConnectionStatus(ACQUIRING_TERMINAL_CONNECTION_STATUS.INACTIVE)
            Utils.reportError('payment', reportErrorText('checkConnectionMessage', 'terminal'))()
          },
        })
    },

    initFund () {
      this.initCheckConnection()
      this.acquiring.setValue('operationType', this.operationType)
    },

    async initRefund () {
      if (!this.payment.lastTransaction) return
      await this.initCheckConnection()
    },

    onForceComplete () {
      this.withSpinner(this.acquiring.forceComplete(), 'forceCompleteSpinner')
    },

    async onCloseModal () {
      if (this.closeModalDisabled) return

      if (this.acquiring.status === ACQUIRING_STATUS_CODE.SUCCESS) {
        await Utils.reportSuccess(reportSuccessText('createMessage', 'payment'))()
        Turbolinks.visit(Routes.payment_path(this.acquiring.lastTransaction.paymentId))
      }

      this.closeModal()
    },
  },
})
</script>
