<template>
  <m-form-show class="payment-info">
    <!-- Тип платежа -->
    <m-form-show-row
      :label="t$('paymentKind')"
      :value="t$(`paymentsKinds.${payment.distributor.initialKind}`)"
    />

    <!-- Заказчик -->
    <m-form-show-row
      :label="t$('paymentsTableTitles.customer')"
    >
      <template #value>
        <m-link
          :text="payer.title"
          :href="payer.link"
          :class="{ 'deleted': payer.deletedAt }"
        />
      </template>
    </m-form-show-row>

    <!-- Пациент -->
    <m-form-show-row
      v-if="showClient"
      :label="t('patient')"
    >
      <template #value>
        <m-link
          :text="payment.destinationClient.fullName"
          :href="destinationClientLink"
        />
      </template>
    </m-form-show-row>

    <!-- Создан -->
    <m-form-show-row
      v-if="isShowMode && payment.distributor.initialKind === PAYMENT_KINDS.CASH_IN"
      :label="t('created_by')"
      :value="payment.createdBy"
    />

    <template v-if="showOrderSum">
      <!-- Сумма по счету -->
      <m-form-show-row
        class="bold"
        :label="t('order_sum')"
        :value="$filters.currency(payment.distributor.finalSum)"
      />

      <!-- Неоплаченная сумма по счету -->
      <m-form-show-row :label="t('required_sum_by_order')">
        <template #value>
          <span class="bold danger">{{ $filters.currency(payment.distributor.totalRequired) }}</span>
        </template>
      </m-form-show-row>
    </template>

    <!-- Наличными -->
    <m-form-show-row
      v-if="isShowMode"
      :class="{ 'hidden-print ': !payment.distributor.byCash }"
      :label="t$('paymentsPaymentSpecies.byCash')"
      :value="$filters.currency(payment.distributor.byCash)"
    />

    <!-- Перевод на расчетный счет -->
    <m-form-show-row
      v-if="isShowMode"
      :class="{ 'hidden-print ': !payment.distributor.byCashless }"
      :label="t$('paymentsPaymentSpecies.byCashless')"
      :value="$filters.currency(payment.distributor.byCashless)"
    />

    <!-- Картой -->
    <m-form-show-row
      v-if="isShowMode"
      :class="{ 'hidden-print ': !payment.distributor.byCard }"
      :label="t$('paymentsPaymentSpecies.byCard')"
      :value="$filters.currency(payment.distributor.byCard)"
    />

    <!-- С лицевого счета / На лицевой счет -->
    <m-form-show-row
      v-if="isShowMode && payment.distributor.initialKind !== PAYMENT_KINDS.CASH_OUT"
      :class="{ 'hidden-print ': !payment.distributor.byBalance }"
      :value="$filters.currency(payment.distributor.byBalance)"
      :label="byBalanceLabel"
    />

    <!-- Кредитом -->
    <m-form-show-row
      v-if="isShowMode"
      :class="{ 'hidden-print ': !payment.distributor.byCredit }"
      :label="t$('paymentsTableTitles.by_credit')"
      :value="$filters.currency(payment.distributor.byCredit)"
    />

    <template v-if="isShowMode && payment.distributor.baseKind === PAYMENT_BASE_KIND.FUND">
      <!-- Итого -->
      <m-form-show-row

        :label="t('total')"
        :value="$filters.currency(payment.distributor.totalPaid)"
      />

      <!-- Внесено на баланс -->
      <m-form-show-row
        :label="t('added_to_finance')"
        :value="$filters.currency(payment.distributor.deposit)"
      />
    </template>

    <!-- Комментарий -->
    <m-form-show-row
      v-if="isShowMode"
      :class="{ 'hidden-print ': !payment.comment }"
      :label="t('comment')"
      :value="payment.comment"
    />

    <!-- Чек -->
    <m-form-show-row
      v-if="isShowMode && $m.modules.workplaces && receiptInfo"
      class="hidden-print"
      :label="t('receipt')"
    >
      <template #value>
        <span
          :class="receiptInfo.cssClass"
        >{{ receiptInfo.value }}</span>
      </template>
    </m-form-show-row>

    <!-- Эквайринг -->
    <m-form-show-row
      v-if="acquiringTransactionShow"
      class="hidden-print"
      :label="t$('acquiring')"
      :value="acquiringTransactionText"
    />
  </m-form-show>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { PaymentModesMixin } from '@/vue_apps/FinanceModule/FinanceModuleIndex/mixins/PaymentModesMixin'
import { i18nScopeMixin } from '@/vue_present/mixins/i18nScopeMixin'
import MFormShow from '@/vue_present/_base/MFormShow/MFormShow.vue'
import MFormShowRow from '@/vue_present/_base/MFormShow/MFormShowRow.vue'
import { PAYMENT_KINDS } from '@/vue_apps/FinanceModule/FinanceModuleIndex/consts/paymentKinds'
import MLink from '@/vue_present/_base/MLink/MLink.vue'
import { PAYMENT_BASE_KIND } from '@/vue_apps/FinanceModule/FinanceModuleIndex/consts/paymentBaseKind'
import { PaymentApi } from '@/vue_apps/FinanceModule/FinanceModuleIndex/classes/Payment/PaymentApi'
import { getReceiptInfo } from '@/vue_apps/FinanceModule/FinanceModuleIndex/consts/getReceiptInfo'
import {
  ACQUIRING_PAYMENT_TYPE,
} from '@/vue_apps/FinanceModule/FinanceModuleIndex/consts/Acquiring/AcquiringPaymentType'
import { camelCase } from 'lodash'

export default defineComponent({
  name: 'PaymentInfo',

  components: {
    MLink,
    MFormShowRow,
    MFormShow,
  },

  mixins: [
    PaymentModesMixin,
    i18nScopeMixin,
  ],

  props: {
    payment: PaymentApi,
  },

  data () {
    return {
      PAYMENT_BASE_KIND,
      PAYMENT_KINDS,
      receiptInfo: getReceiptInfo(),
    }
  },

  computed: {
    showClient () {
      if (this.isShowMode) {
        switch (this.payment.distributor.initialKind) {
          case PAYMENT_KINDS.ORDER_SUBSEQUENT:
          case PAYMENT_KINDS.ORDER_REFUND: return false
        }
      }

      return this.isOrderKind
    },

    byBalanceLabel () {
      return this.payment.distributor.baseKind === PAYMENT_BASE_KIND.FUND
        ? t('by_balance')
        : t('to_balance')
    },

    showOrderSum () {
      if (this.isShowMode) {
        switch (this.payment.distributor.initialKind) {
          case PAYMENT_KINDS.ORDER_SUBSEQUENT:
          case PAYMENT_KINDS.ORDER_INITIAL:
          case PAYMENT_KINDS.ORDER_REFUND: return false
        }
      }

      return this.isOrderKind
    },

    isOrderKind () {
      switch (this.payment.distributor.initialKind) {
        case PAYMENT_KINDS.ORDER_INITIAL:
        case PAYMENT_KINDS.ORDER_SUBSEQUENT:
        case PAYMENT_KINDS.ORDER_REFUND: return true
        default: return false
      }
    },

    destinationClientLink () {
      if (!this.showClient) { return }

      return Routes.client_path(this.payment.destinationClient.id)
    },

    payer () {
      if (this.payment.payerCompany) {
        const { id, title, deletedAt } = this.payment.payerCompany

        return {
          link: Routes.company_path(id),
          title,
          deletedAt,
        }
      }

      const { id, fullName, deletedAt } = this.payment.payerClient

      return {
        title: fullName,
        link: Routes.client_path(id),
        deletedAt,
      }
    },

    acquiringTransactionShow () {
      return this.isShowMode || this.payment.distributor.baseKind === PAYMENT_BASE_KIND.REFUND
        ? this.$m.modules.tradeAcquiring || Boolean(this.payment.lastTransaction)
        : false
    },

    acquiringTransactionText () {
      const transaction = this.payment.lastTransaction
      if (!transaction) {
        return this.t$('noTransactions')
      }

      const operationType = this.t$(`operationTypes.${transaction.operationType}`)
      const paymentType = transaction.paymentType === ACQUIRING_PAYMENT_TYPE.CARD
        ? this.t$('byCard').toLowerCase()
        : this.t$('bySBP')
      const status = this.t$(`transactionStatus.${camelCase(transaction.status)}`)

      return `${operationType} ${paymentType} - ${status}`
    },
  },

  created () {
    this.setI18nScope('finance')
  },
})
</script>
