import { MSiSchema } from '@/vue_present/_base/Tables/MSiGenerator/MSiSchema'
import { MTableSchema } from '@/vue_present/_base/Tables/MTableGenerator/MTableSchema'
import { MTableHeader } from '@/vue_present/_base/Tables/MTableGenerator/MTableHeader'

export const showPaymentEntriesMsiSchema = new MSiSchema()
  .addTableSchema(
    new MTableSchema()
      .addHeaders({
        title: new MTableHeader(t('title')).addFixedWidth('800px'),
        finalSumCell: new MTableHeader(t('sum')).addFixedWidth('300px'),
        sumCell: new MTableHeader(t('paid_sum')).addFixedWidth('300px'),
      })
  )

export const showRefundPaymentEntriesMsiSchema = new MSiSchema()
  .addTableSchema(
    new MTableSchema()
      .addHeaders({
        title: new MTableHeader(t('title')).addFixedWidth('800px'),
        finalSumCell: new MTableHeader(t('sum')).addFixedWidth('300px'),
        sumCell: new MTableHeader(t('refund')).addFixedWidth('300px'),
      })
  )

export const fundPaymentEntriesMsiSchema = new MSiSchema()
  .addTableSchema(
    new MTableSchema()
      .addHeaders({
        title: new MTableHeader(t('title')).addFixedWidth('350px'),
        finalSumCell: new MTableHeader(t('sum')).addFixedWidth('150px'),
        paidSumCell: new MTableHeader(t('paided')).addFixedWidth('150px'),
        sumCell: new MTableHeader(t('to_pay')).addFixedWidth('110px'),
        action: new MTableHeader().addFixedWidth('40px'),
      })
  )

export const refundPaymentEntriesMsiSchema = new MSiSchema()
  .addTableSchema(
    new MTableSchema()
      .addHeaders({
        title: new MTableHeader(t('title')),
        sumCell: new MTableHeader(t('refund')),
      })
  )
