<template>
  <m-modal
    class="clinical-guidelines-catalog-editor-entry-modal"
    :dialog-title="title"
    :visible="modalVisible"
    width="800px"
    modal-title-icon="clinicalGuideline"
    @close="closeModal"
  >
    <div
      v-loading="loading"
      class="clinical-guidelines-catalog-editor-entry-modal__body"
    >
      <!-- Наименование -->
      <m-input
        :value="clinicalGuidelineEntryItem.title"
        :label="t('title')"
        required
        validator-name="title"
        @registerValidator="onRegisterValidator"
        @input="clinicalGuidelineEntryItem.setValue('title', $event)"
      />

      <div class="clinical-guidelines-catalog__line-container">
        <!-- Тип -->
        <m-select
          :value="clinicalGuidelineEntryItem.kind"
          :items="CLINICAL_GUIDELINE_ENTRY_KIND_ITEMS"
          :label="t('type')"
          required
          validator-name="kind"
          @registerValidator="onRegisterValidator"
          @change="clinicalGuidelineEntryItem.setValue('kind', $event)"
        />

        <!-- Этап лечения -->
        <m-select
          :value="clinicalGuidelineEntryItem.step"
          :items="CLINICAL_GUIDELINE_ENTRY_STEP_ITEMS"
          :label="t$('step')"
          required
          validator-name="step"
          @registerValidator="onRegisterValidator"
          @change="clinicalGuidelineEntryItem.setValue('step', $event)"
        />
      </div>

      <!-- Комментарии -->
      <m-textarea
        :value="clinicalGuidelineEntryItem.commentShort"
        :label="t$('commentShort')"
        @input="clinicalGuidelineEntryItem.setValue('commentShort', $event)"
      />

      <!-- Дополнительный комментарий -->
      <m-textarea
        :value="clinicalGuidelineEntryItem.commentLong"
        :label="t$('commentLong')"
        @input="clinicalGuidelineEntryItem.setValue('commentLong', $event)"
      />

      <div class="clinical-guidelines-catalog__line-container">
        <!-- Уровень убедительности рекомендаций -->
        <m-buttons-group
          :value="clinicalGuidelineEntryItem.uur"
          :label=" t$('uurLong')"
          :items="CLINICAL_GUIDELINE_ENTRY_UUR_ITEMS"
          required
          radio
          validator-name="uur"
          @registerValidator="onRegisterValidator"
          @change="clinicalGuidelineEntryItem.setValue('uur', $event)"
        />

        <!-- Уровень достоверности доказательств -->
        <m-buttons-group
          :value="clinicalGuidelineEntryItem.udd"
          :label=" t$('uddLong')"
          :items="CLINICAL_GUIDELINE_ENTRY_UDD_ITEMS"
          required
          radio
          validator-name="udd"
          @registerValidator="onRegisterValidator"
          @change="clinicalGuidelineEntryItem.setValue('udd', $event)"
        />
      </div>

      <!-- Входит в критерии оценки качества медицинской помощи -->
      <m-switch
        :value="clinicalGuidelineEntryItem.healthcareBenchmark"
        :active-text="t$('healthcareBenchmark')"
        @change="clinicalGuidelineEntryItem.setValue('healthcareBenchmark', $event)"
      />
    </div>

    <template #footer-left>
      <m-button-submit
        :use-mode-by-route="false"
        :mode="buttonMode"
        :disabled="loading"
        @click="onSave()"
      />

      <m-button
        template="saveAndCreate"
        :type="buttonMode"
        :disabled="loading"
        @click="onSave(false)"
      />
    </template>
  </m-modal>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import MModal from '@/vue_present/_base/MModal/MModal.vue'
import { ModalMixin } from '@/vue_present/mixins/ModalMixins/ModalMixin'
import MButtonSubmit from '@/vue_present/_base/buttons/MButtonSubmit/MButtonSubmit.vue'
import MInput from '@/vue_present/_base/inputs/MInput/MInput.vue'
import MSelect from '@/vue_present/_base/inputs/MSelect/MSelect.vue'
import MTextarea from '@/vue_present/_base/inputs/MTextarea/MTextarea.vue'
import {
  CLINICAL_GUIDELINE_ENTRY_KIND_ITEMS,
} from '@/vue_apps/catalogs_root/ClinicalGuidelinesCatalog/consts/clinicalGuidelineEntryKind'
import { ValidationParentMixin } from '@/vue_present/mixins/ValidationParentMixin'
import {
  ClinicalGuidelineApi,
} from '@/vue_apps/catalogs_root/ClinicalGuidelinesCatalog/classes/ClinicalGuideline/ClinicalGuidelineApi'
import {
  CLINICAL_GUIDELINE_ENTRY_STEP_ITEMS,
} from '@/vue_apps/catalogs_root/ClinicalGuidelinesCatalog/consts/clinicalGuidelineEntryStep'
import { i18nScopeMixin } from '@/vue_present/mixins/i18nScopeMixin'
import {
  CLINICAL_GUIDELINE_ENTRY_UUR_ITEMS,
} from '@/vue_apps/catalogs_root/ClinicalGuidelinesCatalog/consts/clinicalGuidelineEntryUUR'
import {
  CLINICAL_GUIDELINE_ENTRY_UDD_ITEMS,
} from '@/vue_apps/catalogs_root/ClinicalGuidelinesCatalog/consts/clinicalGuidelineEntryUDD'
import MSwitch from '@/vue_present/_base/inputs/MSwitch/MSwitch.vue'
import {
  ClinicalGuidelineEntryItem,
} from '@/vue_apps/catalogs_root/ClinicalGuidelinesCatalog/classes/ClinicalGuidelineEntryItem/ClinicalGuidelineEntryItem'
import { SpinnerHolder } from '@/vue_components/mixins/spinner_holder'
import MButton from '@/vue_present/_base/buttons/MButton/MButton.vue'
import MButtonsGroup from '@/vue_present/_base/buttons/MButtonsGroup/MButtonsGroup.vue'

export interface IClinicalGuidelinesCatalogEditorEntryModalApi {
  openModal(clinicalGuidelineEntryId?: number): void
}

export default defineComponent({
  name: 'ClinicalGuidelinesCatalogEditorEntryModal',

  components: {
    MButtonsGroup,
    MButton,
    MSwitch,
    MTextarea,
    MSelect,
    MInput,
    MButtonSubmit,
    MModal,
  },

  mixins: [
    i18nScopeMixin,
    ModalMixin,
    ValidationParentMixin,
    SpinnerHolder,
  ],

  props: {
    clinicalGuideline: { type: ClinicalGuidelineApi, required: true },
  },

  emits: ['registerApi'],

  data () {
    return {
      isNewMode: false,
      clinicalGuidelineEntryItem: new ClinicalGuidelineEntryItem(),
      CLINICAL_GUIDELINE_ENTRY_KIND_ITEMS,
      CLINICAL_GUIDELINE_ENTRY_STEP_ITEMS,
      CLINICAL_GUIDELINE_ENTRY_UUR_ITEMS,
      CLINICAL_GUIDELINE_ENTRY_UDD_ITEMS,
    }
  },

  computed: {
    title () {
      const modeTitle = this.isNewMode
        ? t('creating')
        : t('editing')

      return `${t('clinicalGuidelines.title')} - ${modeTitle.toLowerCase()}`
    },

    buttonMode () {
      return this.isNewMode
        ? 'success'
        : 'warning'
    },
  },

  created () {
    this.setI18nScope('clinicalGuidelines.catalog.entry')
    this.$emit('registerApi', {
      openModal: this.openModal,
    })
  },

  methods: {
    beforeOpenModal (clinicalGuidelineEntryId?: number) {
      this.isNewMode = !clinicalGuidelineEntryId

      this.clinicalGuidelineEntryItem = this.isNewMode
        ? new ClinicalGuidelineEntryItem()
        : this.clinicalGuideline.clinicalGuidelineEntries.getItem(clinicalGuidelineEntryId)
    },

    openModal (clinicalGuidelineEntryId?: number) {
      this.beforeOpenModal(clinicalGuidelineEntryId)
      this.modalVisible = true
    },

    beforeCloseModal () {
      this.resetValidations()
    },

    closeModal () {
      this.beforeCloseModal()
      this.modalVisible = false
    },

    resetModal () {
      this.beforeCloseModal()
      this.beforeOpenModal()
    },

    async onSave (closeModal = true) {
      if (this.hasErrors()) { return }
      this.startLoading()

      const clinicalGuidelineEntry = await this.clinicalGuideline
        .submitClinicalGuidelineEntry(this.clinicalGuidelineEntryItem.getValues())

      if ('errors' in clinicalGuidelineEntry) { return }

      this.isNewMode
        ? this.clinicalGuideline.clinicalGuidelineEntries.append(clinicalGuidelineEntry)
        : this.clinicalGuideline.clinicalGuidelineEntries.replace(this.clinicalGuidelineEntryItem.id, clinicalGuidelineEntry)

      closeModal
        ? this.closeModal()
        : this.resetModal()

      this.stopLoading()
    },
  },
})
</script>
