/**
 *
 * @param {string} [message]
 * @constructor
 */
export function NotFoundError (message = T.nothing_has_found) {
  Error.call(this, message)
  this.name = 'NotFoundError'
}

Utils.inherit(NotFoundError, Error)

const timeoutNotify = (error) => {
  const timeout = error?.responseJSON?.error?.timeout
  if (timeout) {
    Notificator.error(t('errors.timeoutForbidden', { timeout: moment.utc(timeout * 1000).format('mm мин. ss сек.') }))

    return true
  }

  return false
}

/**
 * Логгирует ошибку в консоль и показывает сообщение пользователю.
 *
 * @param {string} location
 * @param {string} message
 * @param {string} title
 * @return {(function(Error): void)}
 */
Utils.reportError = (location, message = t('abstract_error_message'), title) => (error) => {
  console.error(location, error)

  if (error && error.status === 422) {
    if (error.responseJSON && error.responseJSON._popup_) {
      Notificator.error(error.responseJSON._popup_.join('; '))

      return
    }

    try {
      const strError = JSON.parse(error.responseJSON)
      if (typeof strError === 'string') { return Notificator.error(strError) }
    } catch (e) { }
  }

  if (error && error.status === 403) {
    if (timeoutNotify(error)) { return }

    Notificator.error(t('insufficient_access_rights'))

    return
  }

  // для егисз, ошибки от нетрики
  if (error && error.status === 500 && error.responseText) {
    if (timeoutNotify(error)) { return }

    if (error.responseText.includes(NETRIKA_TIMEOUT_ERROR_TEXT)) {
      Notificator.error(t('egisz.messages.errors.request.netrika_tcp_address'), t('egisz.netrika'))

      return
    }

    if (error.responseText.includes('netrika internal error')) {
      Notificator.error(t('egisz.messages.errors.request.internalError'), t('egisz.netrika'))

      return
    }

    if (error.responseText.includes('table of \\"id_card_types\\" dictionary does not exist')) {
      Notificator.error(t('nsi.errors.idCardTypesNotFound'))

      return
    }

    if (error.responseText.includes('GUID')) {
      Notificator.error(t('nsi.errors.guidShit'), t('egisz.netrika'))

      return
    }
  }

  Notificator.error(message, title)
}

Utils.isPermissionError = (error) => error.status === 403

export const NETRIKA_TIMEOUT_ERROR_TEXT = 'dialing to the given TCP address timed out'

Utils.reportSuccess = (message = t('abstract_success_message'), title) => (data) => {
  Notificator.success(message, title)
}

Utils.reportWarning = (location = '', message = '', title = '') => (data) => {
  Notificator.warning(message, title)
}
