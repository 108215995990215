import { IList } from '@/_declarations/IList'
import {
  IClinicalGuidelineListItemToClientDTO,
} from '@/vue_present/Reuse/Lists/ClinicalGuidelines/interfaces/IClinicalGuidelineListItem'
import { ArrayHelpers } from '@/helpers/ArrayHelpers'
import {
  IClinicalGuidelineListToServer,
} from '@/vue_present/Reuse/Lists/ClinicalGuidelines/interfaces/IClinicalGuidelineListToServerDTO'

export const reusableClinicalGuidelinesListAdapter = {
  toServer (data: IClinicalGuidelineListToServer) {
    return {
      ...data,
      diseaseIds: data.searchQuery || !data.diseaseIds.length
        ? undefined
        : data.diseaseIds,
    }
  },

  toClient (data: IList<IClinicalGuidelineListItemToClientDTO>) {
    return {
      ...data,
      data: data.data.map((item) => ({
        ...item,
        diseases: ArrayHelpers.orArray(item.diseases)
          .join(', '),
      })),
    }
  },
}
