<template>
  <m-panel
    :title="t('kkm')"
    class="fiscal-printer-kkm"
  >
    <span>{{ title }}</span>

    <fiscal-printer-status
      type="kkm"
      :status="status"
    />

    <div class="flex gap-indent-mid">
      <m-button
        v-tooltip="t('sound_signal')"
        icon="signalStream"
        type="primary"
        :disabled="disabled"
        @click="fiscalPrinter.beep()"
      />

      <template v-if="!disabled">
        <!-- Открыть смену -->
        <m-button
          v-if="!isDayOpened"
          type="success"
          :text="t('open_day')"
          @click="fiscalPrinter.openDay()"
        />

        <!-- Отчет с гашением -->
        <m-button
          v-else
          type="success"
          :text="t('z_report_main')"
          @click="onPrintZMainReport()"
        />
      </template>
    </div>

    <div class="flex gap-indent-mid">
      <!-- Отчет -->
      <m-select
        :value="fiscalPrinter.receipt"
        :items="FISCAL_PRINTER_KKM_RECEIPT_ITEMS"
        :label="t('reports.report')"
        :disabled="disabled"
        :m-fixed-height="false"
        @change="fiscalPrinter.setValue('receipt', $event)"
      />

      <m-button
        v-tooltip="printTooltip"
        type="primary"
        icon="print"
        :disabled="printDisabled"
        @click="fiscalPrinter.printReport()"
      />
    </div>

    <div class="flex gap-indent-mid flex-wrap">
      <print-raw-receipt-modal v-slot="{ openModal }">
        <!-- Печать произвольного чека -->
        <m-button
          type="warning"
          :text="t('printCustomReceipt')"
          :disabled="disabled"
          @click="openModal"
        />
      </print-raw-receipt-modal>

      <!-- Копия последнего документа -->
      <m-button
        type="warning"
        :text="t('last_document')"
        :disabled="disabled"
        @click="fiscalPrinter.printReport(FISCAL_PRINTER_REPORT_TYPE.LAST_DOCUMENT)"
      />

      <!-- Печать документа по номеру -->
      <m-button
        type="warning"
        :text="t('document_by_number')"
        :disabled="disabled"
        @click="onDocumentByNumber"
      />

      <!-- Отмена чека -->
      <m-button
        type="danger"
        :text="t('cancel_receipt')"
        :disabled="disabled"
        @click="fiscalPrinter.cancelReceipt()"
      />
    </div>
  </m-panel>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import MPanel from '@/vue_present/_base/MPanel/MPanel.vue'
import { i18nScopeMixin } from '@/vue_present/mixins/i18nScopeMixin'
import MButton from '@/vue_present/_base/buttons/MButton/MButton.vue'
import MSelect from '@/vue_present/_base/inputs/MSelect/MSelect.vue'
import PrintRawReceiptModal from '@/vue_apps/catalogs_root/Workplaces/components/PrintRawReceiptModal.vue'
import FiscalPrinterStatus from '@/vue_apps/catalogs_root/Workplaces/components/FiscalPrinter/FiscalPrinterStatus.vue'
import { MAlert } from '@/vue_present/_base/MMessageBox/MAlert'
import { MPrompt } from '@/vue_present/_base/MMessageBox/MPrompt'
import {
  FISCAL_PRINTER_KKM_RECEIPT_ITEMS,
} from '@/vue_apps/catalogs_root/Workplaces/consts/fiscalPrinterKKMReceiptItems'
import { MConfirm } from '@/vue_present/_base/MConfirm/MConfirm'
import { getFiscalPrinterStatus } from '@/vue_apps/catalogs_root/Workplaces/consts/getFiscalPrinterStatus'
import { FISCAL_PRINTER_STATUSES } from '@/vue_apps/catalogs_root/Workplaces/consts/fiscalPrinterStatuses'
import { IWSMessage } from '@/_declarations/IWSMessage'
import { IWorkplaceError } from '@/vue_apps/catalogs_root/Workplaces/interfaces/IWorkplaceError'
import {
  FISCAL_PRINTER_EVENT,
  FISCAL_PRINTER_REPORT_TYPE,
  getWorkplacesFiscalPrinterEvent,
} from '@/vue_apps/catalogs_root/Workplaces/consts/fiscalPrinterEvent'
import { FiscalPrinterApi } from '@/vue_apps/catalogs_root/Workplaces/classes/FiscalPrinter/FiscalPrinterApi'
import { TStringNumber } from '@/_declarations/TStringNumber'
import { mapActions } from 'vuex'

export default defineComponent({
  name: 'FiscalPrinterKKM',

  components: {
    PrintRawReceiptModal,
    FiscalPrinterStatus,
    MSelect,
    MButton,
    MPanel,
  },

  mixins: [
    i18nScopeMixin,
  ],

  data () {
    return {
      FISCAL_PRINTER_REPORT_TYPE,
      FISCAL_PRINTER_KKM_RECEIPT_ITEMS,
      fiscalPrinter: new FiscalPrinterApi(),
    }
  },

  computed: {
    disabled () {
      return !this.fiscalPrinter.info
    },

    isDayOpened () {
      if (this.disabled) { return false }

      return this.fiscalPrinter.info.opened
    },

    printDisabled () {
      return this.disabled || !this.fiscalPrinter.receipt
    },

    title () {
      return this.disabled
        ? t('unknown')
        : this.fiscalPrinter.info.title
    },

    status () {
      return this.disabled
        ? FISCAL_PRINTER_STATUSES.UNKNOWN
        : getFiscalPrinterStatus(this.fiscalPrinter.info)
    },

    workplaceId (): string {
      return this.$route.params.workplaceId
    },

    printTooltip () {
      return this.fiscalPrinter.receipt
        ? this.t$('printReport', { title: this.fiscalPrinter.receipt.title })
        : this.t$('selectReportToPrint')
    },

    isZmainReportWithReconciliationTheResults () {
      return this.$store.getters['acquiringStore/vxGetReconciliationTheResultsWithZmainReport']
    },

    hasAcquiringBank () {
      return this.$store.getters['acquiringStore/vxGetHasAcquiringBank']
    },
  },

  mounted () {
    this.setI18nScope('fiscal')
    if (!this.workplaceId) { return }

    this.fiscalPrinter.setValue('workplaceId', +this.workplaceId)
    this.vxFetchConfigurationsCashRegistersAndAcquiring()
    this.initSubscribes()
  },

  methods: {
    ...mapActions('acquiringStore', {
      vxFetchConfigurationsCashRegistersAndAcquiring: 'vxFetchConfigurationsCashRegistersAndAcquiring',
    }),

    initSubscribes () {
      Services.pubSub.subscribe(getWorkplacesFiscalPrinterEvent(FISCAL_PRINTER_EVENT.ERROR), (msg: IWSMessage<IWorkplaceError>) => {
        if (!msg.data.human_message) { return }
        MAlert.info(msg.data.human_message)
      })

      this.fiscalPrinter.initSubscribes()
    },

    async onDocumentByNumber () {
      const { cancel, value } = await MPrompt.show(this.t$('docByNumber'), {
        inputPlaceholder: t('enterNumberFiscalDocument'),
        inputType: 'number',
      })

      if (cancel || !value) { return }

      this.fiscalPrinter.printDocumentByNumber(value as TStringNumber)
    },

    async onPrintZMainReport () {
      const { cancel } = await MConfirm.warning(t('close_the_day?'))
      if (cancel) { return }

      this.$m.modules.tradeAcquiring && this.hasAcquiringBank && this.isZmainReportWithReconciliationTheResults
        ? Services.pubSub.emit('fiscalPrinterZMainReport', true, true)
        : this.fiscalPrinter.printReport(FISCAL_PRINTER_REPORT_TYPE.Z_MAIN)
    },
  },
})
</script>
