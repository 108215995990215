<template>
  <catalogs-base
    class="clinical-guidelines-catalog"
    :catalog-title="t('clinicalGuidelines.title')"
    catalog-icon="clinicalGuideline"
    :table-title="$route.meta.title"
    table-icon="clinicalGuideline"
    :mode="mode"
    :can-manage="$security.canManageClinicalGuideline"
    :can-view="canView"
    @close="onClose"
  >
    <template #primaryHeadExtra>
      <clinical-guidelines-catalog-import-modal>
        <template #button="{ openModal }">
          <m-button
            v-if="$security.canManageClinicalGuideline"
            v-tooltip="importTooltip"
            :text="t('imports')"
            type="dark"
            button-style="fill"
            size="mini"
            :disabled="importDisabled"
            @click="openModal"
          />
        </template>
      </clinical-guidelines-catalog-import-modal>
    </template>

    <template #primary>
      <clinical-guidelines-catalog-tree
        v-if="canView"
        v-loading="loading"
        :clinical-guideline="clinicalGuideline"
        @setLoading="loading = $event"
      />

      <introduction
        v-else
        icon="fad fa-books-medical"
        :introduction-text="t('introduction.clinicalGuidelines')"
        :hide-button="$m.modules.clinicalGuidelines"
      />
    </template>

    <template #form>
      <router-view
        v-loading="loading"
        :clinical-guideline="clinicalGuideline"
        @setLoading="loading = $event"
        @close="onClose"
      />
    </template>
  </catalogs-base>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import ClinicalGuidelinesCatalogTree
  from '@/vue_apps/catalogs_root/ClinicalGuidelinesCatalog/components/ClinicalGuidelinesCatalogTree.vue'
import CatalogsBase from '@/vue_apps/catalogs_root/_catalog_base/CatalogsBase.vue'
import MButton from '@/vue_present/_base/buttons/MButton/MButton.vue'
import { MODES } from '@/vue_apps/catalogs_root/_catalog_base/const/const'
import {
  CLINICAL_GUIDELINES_CATALOG_ROUTE_NAMES,
} from '@/vue_apps/catalogs_root/ClinicalGuidelinesCatalog/router/clinicalGuidelinesCatalogRouteNames'
import {
  ClinicalGuidelineApi,
} from '@/vue_apps/catalogs_root/ClinicalGuidelinesCatalog/classes/ClinicalGuideline/ClinicalGuidelineApi'
import ClinicalGuidelinesCatalogImportModal
  from '@/vue_apps/catalogs_root/ClinicalGuidelinesCatalog/components/ClinicalGuidelinesCatalogImportModal.vue'
import Introduction from '@/vue_components/introduction/introduction.vue'
import { SpinnerHolder } from '@/vue_components/mixins/spinner_holder'

export default defineComponent({
  name: 'ClinicalGuidelinesCatalog',

  components: {
    Introduction,
    ClinicalGuidelinesCatalogImportModal,
    MButton,
    CatalogsBase,
    ClinicalGuidelinesCatalogTree,
  },

  mixins: [SpinnerHolder],

  beforeRouteEnter (to, from, next) {
    next((vm: any) => {
      vm.onRouteChange()
    })
  },

  beforeRouteUpdate (to, from, next) {
    this.onRouteChange()

    // TODO VUE 3: убрать next() там он не нужОн
    next()
  },

  data () {
    return {
      clinicalGuideline: new ClinicalGuidelineApi(),
    }
  },

  computed: {
    id () {
      return +this.$route.params.id || null
    },

    mode () {
      switch (this.$route.name) {
        case CLINICAL_GUIDELINES_CATALOG_ROUTE_NAMES.EDIT:
          return MODES.EDIT

        case CLINICAL_GUIDELINES_CATALOG_ROUTE_NAMES.NEW:
          return MODES.CREATE

        default:
          return MODES.SHOW
      }
    },

    canView () {
      return this.$m.modules.clinicalGuidelines && this.$security.canViewClinicalGuideline
    },

    importDisabled () {
      return this.loading || !this.$security.canManageImport
    },

    importTooltip () {
      return this.$security.canManageImport
        ? null
        : t('no_permission')
    },
  },

  methods: {
    onRouteChange () {
      this.clinicalGuideline = new ClinicalGuidelineApi()
    },

    onClose () {
      this.$router.replace({ name: CLINICAL_GUIDELINES_CATALOG_ROUTE_NAMES.INDEX })
    },
  },
})
</script>
