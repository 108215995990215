import { MRequestNotification } from '@/_api/_requests/MRequestNotification'
import { INotifyConfig } from '@/_api/decorators/api/interfaces/INotifyConfig'
import { MRequestSuccess } from '@/_api/_requests/MRequestSuccess'
import { MRequestError } from '@/_api/_requests/MRequestError'
import { IResponse } from '@/_api/decorators/api/interfaces/IResponse'

export function Notify (
  message: string,
  entity: string,
  config: INotifyConfig = {}
) {
  return function<This, Args extends any[], Return> (
    target: (this: This, ...args: Args) => Return,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    context: ClassMethodDecoratorContext<This, (this: This, ...args: Args) => Return>
  ) {
    return async function (this: This, ...args: Args): Promise<Awaited<Return>> {
      const {
        success,
        failure,
        defaultValue,
        useRawErrors,
      } = {
        success: true,
        failure: true,
        defaultValue: undefined,
        useRawErrors: false,
        ...config,
      }

      const result = await target.call(this, ...args)

      const notification = (message && entity && new MRequestNotification(
        context.name as string,
        message,
        entity
      )) || null

      const hasErrors = Boolean((result as IResponse)?.errors)

      if (!hasErrors && success) {
        return await MRequestSuccess
          .withNotify(undefined, notification)(result) as Awaited<Return>
      }

      if (hasErrors && failure) {
        if (defaultValue === undefined) {
          return await MRequestError
            .onRequest(notification)(result.errors, useRawErrors) as Awaited<Return>
        }

        return await MRequestError
          .withDefault(defaultValue, notification)(result.errors) as Awaited<Return>
      }

      return result as Awaited<Return>
    }
  }
}
