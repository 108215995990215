<template>
  <div class="protocols-for-print">
    <m-button-back class="mb-indent mt-0" />
    <div
      v-if="loading"
      v-loading="loading"
      class="protocols-for-print__protocol protocols-for-print__protocol_stub"
    />
    <div v-if="!loading && !protocols.length">
      <m-prompt-notice :text="t('there_are_not_protocols_with_filled_conclusions')" />
    </div>

    <div
      v-for="(protocol, page) in protocols"
      v-show="!loading"
      :key="protocol.id"
      class="protocols-for-print__protocol"
    >
      <protocol-info
        :medical-record-id="medicalRecord.id"
        :protocol="protocol"
        :client="client"
        class="w-100"
        use-title
      />

      <div class="protokol-content-wrapper flex-grow-1">
        <div class="protocol-content-margins">
          <div
            id="protokol-content"
            ref="protokolContent"
            class="editor-mount"
            v-html="protocol.templateHtml"
          />

          <div
            v-if="protocol.printDoctorSignature"
            class="protokol-content-wrapper__doctor-signature"
          >
            {{ protocol.doctor.fullName }} _____________________
          </div>
        </div>
      </div>

      <br class="hidden-print">
      <div class="prompt-notice hidden-print">
        {{ t('page') }}: {{ page + 1 }}
      </div>
      <div class="mce-pagebreak" />
      <br class="hidden-print">
    </div>

    <m-button-back v-if="!loading && protocols.length" />
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import { IClient } from '@/_declarations/IClient'
import { IProtocol } from '@/vue_apps/ProtocolsApp/declarations/IProtocol'
import { MProtocolPresenter } from '@/vue_apps/ProtocolsApp/api/MProtocolPresenter'
import { ProtocolApi } from '@/vue_apps/ProtocolsApp/entities/ProtocolApi'
import ProtocolInfo from '@/vue_apps/ProtocolsApp/components/ProtocolShowView/ProtocolInfo.vue'
import { IMedicalRecord } from '@/_declarations/IMedicalRecord'
import MButtonBack from '@/vue_present/_base/buttons/MButtonBack/MButtonBack.vue'
import { SpinnerHolder } from '@/vue_components/mixins/spinner_holder'
import htmlHelper from '@/plugins/dynamic_forms/helpers/htmlHelper'
import MPromptNotice from '@/vue_present/_base/MPromptNotice/MPromptNotice.vue'

export default defineComponent({
  name: 'ProtocolPrintView',

  components: {
    MPromptNotice,
    MButtonBack,
    ProtocolInfo,
  },

  mixins: [SpinnerHolder],

  props: {
    client: { type: Object as PropType<IClient>, required: true },
    medicalRecord: { type: Object as PropType<IMedicalRecord>, required: true },
  },

  data () {
    return {
      protocols: [] as Array<ProtocolApi<unknown>>,
    }
  },

  created () {
    this.$emit(
      'setProtocolTitle',
        `${t('medical_card')} № ${this.client.emkNumber} - ${this.client.fullname} - ${t('print')}`
    )

    this.fetch()
  },

  methods: {
    async fetch () {
      const protocols = await this.withSpinner(MProtocolPresenter.fetchForPrint(this.client.id)) as Array<IProtocol<unknown>> || []
      if ('errors' in protocols) { return }
      this.protocols = protocols.map((protocol) => new ProtocolApi(protocol))

      await this.$nextTick()
      htmlHelper.clearHtml()
    },
  },
})
</script>
