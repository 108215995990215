
export class WindowCreatedByController {
  static set (_window, controller = '') {
    _window.__CREATED_BY_CONTROLLER__ = controller
  }

  static get () {
    return window.__CREATED_BY_CONTROLLER__
  }

  static is (controller = '') {
    return window.__CREATED_BY_CONTROLLER__ === controller
  }
}
