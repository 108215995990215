import { TCallback } from '@/_api/decorators/interfaces/TCallback'
import { extractArray } from '@/helpers/extractArray'
import { get } from 'lodash'

const isClassMember = (callback: TCallback | string) => typeof callback === 'string'

const getContext = <This>(context: This, path: string | Function) => {
  if (typeof path === 'function') { return context }

  const parts = path.split('.')

  return parts.length === 1
    ? context
    : get(context, parts.slice(0, -1).join('.'))
}

export function Callback (callback: TCallback | string, catchCallback?: TCallback | string | true) {
  return function<This, Args extends any[], Return> (
    target: (this: This, ...args: Args) => Return,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    context: ClassMethodDecoratorContext<This, (this: This, ...args: Args) => Return>
  ) {
    return function (this: This, ...args: Args) {
      const result = target.call(this, ...args)

      const callable: TCallback = isClassMember(callback)
        ? get(this, callback as string)
        : callback

      const callableCatch: TCallback = catchCallback === true
        ? callable
        : isClassMember(catchCallback)
          ? get(this, catchCallback as string)
          : catchCallback

      /**
       * Синхронный метод
       */
      if (!(result instanceof Promise)) {
        return callable.call(getContext(this, callback), ...extractArray(result))
      }

      /**
       * Асинхронный метод
       */
      const promise = result
        .then((args: unknown[]) => callable.call(getContext(this, callback), ...extractArray(args)) as Promise<Awaited<Return>>)

      if (!callableCatch) { return promise }

      const callableCatchContext = catchCallback === true
        ? getContext(this, callback)
        : getContext(this, catchCallback)

      return promise
        .catch((args: unknown[]) => callableCatch.call(callableCatchContext, ...extractArray(args))) as Promise<Awaited<Return>>
    }
  }
}
