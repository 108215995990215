import { IWSMessage } from '@/_declarations/IWSMessage'
import { WEService } from '@/services/WEService'
import { FiscalPrinterModuleService } from '@/services/FiscalPrinterModuleService'
import { TradeAcquiringService } from '@/services/TradeAcquiringService'
import { IWorkplaceModulesStatuses } from '@/services/declarations/TWorkplaceModulesStatuses'
import { WORKPLACE_MODULE_STATUS } from '@/services/consts/WorkplaceModuleStatus'
import { WORKPLACE_CHANNEL_MESSAGE_TYPE } from '@/services/consts/WorkplaceChannelMessageType'
import { snakeToCamel } from '@/_api/_requests/helpers'
import { store } from '@/vue_components/store/store'

export class WorkplaceChannelService {
  private subscription: any // TODO типизовать Services.wsSubscriptions

  private weService = new WEService()

  private fiscalPrinterModuleService = new FiscalPrinterModuleService()

  private tradeAcquiringService = new TradeAcquiringService()

  init () {
    this.weService.redrawUi()
    this.fiscalPrinterModuleService.subscribeToDayOpen()
    this.fiscalPrinterModuleService.subscribeToGetInfo()
    this.tradeAcquiringService.subscribe()
    this.subscribe()
  }

  private subscribe () {
    this.subscription = Services.wsSubscriptions.workplace.advancedConnection({
      received: this.onReceived.bind(this),
      connected: this.onConnected.bind(this),
    })
  }

  private onReceived (payload: IWSMessage) {
    const { data } = snakeToCamel(payload) as IWSMessage
    const validDataType = [
      WORKPLACE_CHANNEL_MESSAGE_TYPE.CHECK_MODULE_STATUSES,
      WORKPLACE_CHANNEL_MESSAGE_TYPE.MODULE_STATUSES,
    ]

    if (!validDataType.includes(data.type as WORKPLACE_CHANNEL_MESSAGE_TYPE)) { return }
    this.onUpdateModuleStatuses(data.modules as IWorkplaceModulesStatuses)
  }

  private onConnected () {
    this.subscription.send({ meta: { action: 'check_module_statuses' } })
  }

  private onUpdateModuleStatuses (modules: IWorkplaceModulesStatuses) {
    if (!this.isModuleStatesChanged(store.getters['weStore/vxGetAllModulesState'], modules)) { return }

    if (modules?.fiscalPrinter === WORKPLACE_MODULE_STATUS.ENABLED) {
      this.fiscalPrinterModuleService.sendGetInfo()
    }

    this.weService.redrawUi(modules)

    if (!TradeAcquiringService.isFiscalModuleDisabledWithActiveAcquiring()) { return }
    this.tradeAcquiringService.fetchTerminalInfo()
  }

  private isModuleStatesChanged (oldState: IWorkplaceModulesStatuses<boolean>, newState: IWorkplaceModulesStatuses) {
    return Object.keys(oldState)
      .some((key) => oldState[key] !== (newState[key] === WORKPLACE_MODULE_STATUS.ENABLED))
  }
}
