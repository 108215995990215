import { vueFilters } from '@/plugins/vue/filtersPlugin/vueFilters'
import { IPaymentWithComment } from '@/vue_apps/FinanceModule/FinanceModuleIndex/interfaces/IPaymentWithComment'

export const paymentsWithCommentsAdapter = {
  toClient (payload: IPaymentWithComment[]) {
    return payload.map((item) => ({
      ...item,
      createdAt: Utils.getFormattedDate(item.createdAt),
      deposit: vueFilters.currency(item.deposit),
    }))
  },
}
