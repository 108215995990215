import { orUndefined } from '@/_medods_standart_library/msl'

const baseAdapter = (categoryIds: number[], clinicalGuidelineIds: number[]) => ({
  categoryIds: orUndefined(categoryIds, true),
  clinicalGuidelineIds: orUndefined(clinicalGuidelineIds, true),
})

export const clinicalGuidelineMassActionAdapter = {
  changeReleaseStatusToServer (data) {
    return {
      ...baseAdapter(data.categoryIds, data.clinicalGuidelineIds),
      releaseStatus: data.releaseStatus,
    }
  },

  baseAdapter (data) {
    return baseAdapter(data.categoryIds, data.clinicalGuidelineIds)
  },
}
