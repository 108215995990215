import { ItemBase } from '@/_api/_classes/ItemBase'
import { ICatalog } from '@/_declarations/ICatalog'

export class TemplateRecordsListItem extends ItemBase {
  _permit = ['id', 'title', 'categoryId']

  id: number

  title: string

  categoryId: number

  isLeaf = true

  constructor (props: ICatalog & { categoryId: number }) {
    super()
    this._fillProps(props)
    this.categoryId = props.categoryId
  }
}
