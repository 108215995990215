export const formatTree = (data, options = false) => {
  // build tree from nestes sets
  const result = []
  const tmp = {}

  data.sort((a, b) => a.depth - b.depth)
  data.forEach((node) => {
    if (options.selected) {
      node.selected = options.selected.indexOf(node.id) !== -1
    }
    if (node.depth === 0) {
      node.expanded = true
    }

    if (tmp[node.depth] === undefined) tmp[node.depth] = []
    tmp[node.depth].push(node)

    if (!node.parent_id) {
      result.push(node)
    } else {
      try {
        const parent = tmp[node.depth - 1].find((e) => e.id === node.parent_id)
        if (!parent.children) parent.children = []
        if (parent.selected) {
          node.selected = true
        }
        parent.children.push(node)
      } catch (err) {
        console.error(`node has no parent. title: ${node.title}, depth: ${node.depth}, id: ${node.id}`)
        console.error('err: ', err)
      }
    }
  })

  return result
}

export class EntityItem {

}

export class EntityCollection {
  constructor (collection) {
    this.collection = collection || []
  }

  add (entity) {
    this.collection.push(entity)
  }

  filter (cb) {
    return new EntityCollection(this.collection.filter(cb))
  }

  getAll () {
    return this.collection
  }

  setCollection (collection) {
    this.collection = collection || []
  }

  getDescendants () {
    let descendants = []
    this.collection.forEach((entity) => {
      descendants = descendants.concat(entity.findAll((desc) => true))
    })

    return new EntityCollection(descendants)
  }

  selfAndDescendants (collection = true) {
    const result = this.getAll().concat(this.getDescendants().getAll())
    if (collection) return new EntityCollection(result)

    return result
  }

  // after
  remove (entity) {}

  get (prop) {
    return this.collection.map((entity) => entity.data[prop])
  }
}
