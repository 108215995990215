import { MTableCell } from '@/vue_present/_base/Tables/MTableGenerator/MTableCell'
import { IAcquiringReportTotal } from '@/vue_apps/ReportsModule/AcquiringReport/interfaces/IAcquiringReportTotal'
import { vueFilters } from '@/plugins/vue/filtersPlugin/vueFilters'

export class AcquiringListITotalItem {
  idCell: MTableCell

  constructor (item: IAcquiringReportTotal) {
    this.idCell = new MTableCell(t('reports.acquiring.total', {
      count: item.count,
      amount: vueFilters.currency(item.amount),
    }))
      .addClasses('absolute')
  }
}
