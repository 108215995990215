<template>
  <div class="diseases-app">
    <diseases-editor
      :last-context-node="lastContextNode"
      :presenter="presenter"
      @registerEditorApi="editorApi = $event"
      @onSuccessSubmitted="onSuccessSubmitted"
    />

    <reusable-lazy-tree
      :tree="tree"
      :use-items="useItems"
      :show-checkboxes="showCheckboxes"
      :use-root-node="{ title: t('diagnoses') }"
      @setTreeRef="onSetTreeRef"
      @onNodeClick="$emit('onNodeClick', $event)"
      @onCheckChange="$emit('onCheckChange', $event)"
    >
      <template #default="{ node, data }">
        <slot v-bind="{ node, data }">
          <lazy-tree-node-component
            :data="data"
            :node="node"
            :editable="node.level > EDITABLE_NODE_LEVEL && editingEnabled"
            @onEdit.stop="onContextNodeClick({ data, event: $event })"
          >
            <template #title>
              <span
                v-tooltip="{ tooltip: data.title, placement: 'top-start' }"
                class="cut"
              >
                <template v-if="!data.isLeaf">
                  {{ data.title }}
                </template>

                <template v-else>
                  <b>{{ data.extraAttributes.codeString }}</b>
                  <span>{{ data.title }}</span>
                </template>
              </span>
            </template>
          </lazy-tree-node-component>
        </slot>
      </template>
    </reusable-lazy-tree>

    <context-popup ref="contextPopup">
      <slot name="contextBody">
        <catalog-tree-node-actions
          v-if="lastContextNode"
          :node="lastContextNode"
          :omits="currentNodeActionsOmits"
          :delegates="NODE_ACTIONS_DELEGATES"
          @onDelete="onNodeDelete"
          @onAdd="editorApi.open"
          @onEdit="editorApi.open"
          @clear="closeContext"
        />
      </slot>
    </context-popup>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import ReusableLazyTree from '@/vue_present/Reuse/LazyTree/ReusableLazyTree.vue'
import { DiseasesLazyTree } from '@/vue_apps/catalogs_root/Diseases/store/DiseasesLazyTree'
import { MDiseasePresenter } from '@/_api/catalogs/Disease/MDiseasePresenter'
import LazyTreeNodeComponent from '@/vue_present/Reuse/LazyTree/components/LazyTreeNodeComponent.vue'
import ContextPopup from '@/vue_present/Reuse/ContextPopup/ContextPopup.vue'
import CatalogTreeNodeActions from '@/vue_present/Reuse/CatalogTree/CatalogTreeNodeActions.vue'
import { LazyTreeNode } from '@/vue_present/Reuse/LazyTree/store/LazyTreeNode'
import { defaultStringSorting } from '@/helpers/lambda'
import DiseasesEditor from './store/DiseasesEditor.vue'
import { IDisease } from './store/types'
import {
  DISEASES_TREE_EDITABLE_NODE_LEVEL as EDITABLE_NODE_LEVEL,
  DISEASES_TREE_NODE_ACTIONS_DELEGATES as NODE_ACTIONS_DELEGATES,
  DISEASES_TREE_NODE_ACTIONS_OMITS as NODE_ACTIONS_OMITS,
} from './store/const'
import { extractLeafNodeId } from './store/helpers'

export default defineComponent({
  name: 'Diseases',

  components: {
    LazyTreeNodeComponent,
    ReusableLazyTree,
    ContextPopup,
    CatalogTreeNodeActions,
    DiseasesEditor,
  },

  props: {
    editable: Boolean,
    useItems: { type: Boolean, default: true },
    showCheckboxes: Boolean,
  },

  emits: [
    'setTreeRef',
    'onNodeClick',
    'onCheckChange',
  ],

  data () {
    const presenter = new MDiseasePresenter()
    const lazyTreeConfig = { extraAttributes: ['codeString'] }

    return {
      tree: new DiseasesLazyTree(presenter, lazyTreeConfig),
      treeRef: null,
      lastContextNode: null,
      presenter,

      editorApi: {
        open: null,
        close: null,
      },

      NODE_ACTIONS_DELEGATES,
      EDITABLE_NODE_LEVEL,
    }
  },

  computed: {
    editingEnabled (): boolean {
      return Services.security.canManageDisease && this.editable
    },

    currentNodeActionsOmits (): string[] {
      if (!this.lastContextNode) { return [] }

      return this.lastContextNode.isLeaf
        ? NODE_ACTIONS_OMITS.LEAF
        : NODE_ACTIONS_OMITS.CATEGORY
    },
  },

  methods: {
    onSetTreeRef (treeRef) {
      this.treeRef = treeRef
      this.$emit('setTreeRef', treeRef)
    },

    onContextNodeClick ({ data, event }: { data: LazyTreeNode; event: PointerEvent }) {
      this.$refs.contextPopup.onContextMenuHandler(event, {
        customX: event.clientX - (document.querySelector('#catalogs-list')?.clientWidth || 0),
        customY: event.clientY - 80,
      })
      this.lastContextNode = data
    },

    closeContext () {
      this.$refs.contextPopup.close()
    },

    async onNodeDelete (node) {
      const res = await this.presenter.destroy(extractLeafNodeId(node))
      if (res?.errors) { return }

      this.onSuccessDeleted(node)
    },

    /* Обработчики успешных запросов */

    onSuccessSubmitted ({ id, codeString: code, title }: IDisease, isUpdate: boolean) {
      isUpdate
        ? this.onSuccessUpdated(code, title)
        : this.onSuccessCreated(id, code, title)

      this.editorApi.close()
      this.closeContext()
      this.lastContextNode = null
    },

    onSuccessUpdated (code: string, title: string) {
      this.lastContextNode.title = title
      this.lastContextNode.extraAttributes.codeString = code
    },

    onSuccessCreated (id: string | number, code: string, title: string) {
      const newNodeRaw = {
        id,
        title,
        codeString: code,
        parentId: this.lastContextNode.id,
      }

      const newNode = new LazyTreeNode(this.tree, newNodeRaw, true, ['codeString'])
      this.__addNodeToTree(newNode, this.lastContextNode)
    },

    onSuccessDeleted (node: LazyTreeNode) {
      this.__removeNodeFromTree(node)
      this.closeContext()
    },

    /* Функции обновления моделек дерева */

    __removeNodeFromTree (node: LazyTreeNode) {
      this.treeRef.remove(node)
    },

    __addNodeToTree (node: LazyTreeNode, parentNode: LazyTreeNode) {
      this.treeRef.append(node, parentNode)
      this.__sortTreeNode(parentNode)
    },

    __sortTreeNode ({ id }: LazyTreeNode) {
      const treeParentNode = this.treeRef.store.nodesMap[id]
      if (!treeParentNode) { return }

      const childNodes = treeParentNode.childNodes || []
      const childItems = childNodes
        .sort((a, b) => defaultStringSorting(
          a.data.extraAttributes.codeString,
          b.data.extraAttributes.codeString
        ))

      treeParentNode.childNodes = childItems
    },
  },
})
</script>
