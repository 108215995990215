import { IgRPCMeta } from '@/_api/_classes/GRPC/interfaces/IgRPCMeta'
import { orNull } from '@/_declarations/orNull'
import { GRPC_END_OF_STREAM } from '@/_api/_classes/GRPC/consts/consts'
import { IApiError } from '@/_declarations/IApiErrors'

export class GRPCStream<BatchData = string> {
  static timeout = 15_000

  static isEndPartOfStream (data: string) {
    return data === GRPC_END_OF_STREAM
  }

  private readonly requestId: IgRPCMeta['requestId']

  private streamLength: orNull<number> = null

  private batches: BatchData[] = []

  private errors: orNull<IApiError> = null

  private readonly abandonedCallback: (requestId: IgRPCMeta['requestId']) => void

  private timeoutId: orNull<NodeJS.Timeout> = null

  constructor (
    requestId: IgRPCMeta['requestId'],
    abandonedCallback?: (requestId: IgRPCMeta['requestId']) => void
  ) {
    this.requestId = requestId
    this.abandonedCallback = abandonedCallback ?? (() => {})
    this.setTimeout()
  }

  getRequestId (): IgRPCMeta['requestId'] { return this.requestId }

  getBatches (): BatchData[] { return this.batches }

  getErrors (): orNull<IApiError> { return this.errors }

  setStreamLength (streamLength: number) {
    this.streamLength = streamLength
  }

  setErrors (errors: IApiError) {
    this.errors = errors
  }

  appendBatch (index: number, data: BatchData) {
    this.batches[index] = data
    this.setTimeout()
  }

  hasAllParts () {
    return Object.keys(this.batches).length === this.streamLength
  }

  destroy () {
    clearTimeout(this.timeoutId)
  }

  private setTimeout () {
    clearTimeout(this.timeoutId)

    this.timeoutId = setTimeout(() => {
      this.abandonedCallback(this.requestId)
    }, GRPCStream.timeout)
  }
}
