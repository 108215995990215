<template>
  <div
    v-tooltip="canManage.tooltip"
    class="m-button-wrapper inline-block"
  >
    <el-button
      v-bind="$attrs"
      :id="id"
      v-tooltip="buttonTooltip"
      class="m-button"
      :class="{
        'button-with-icon': isButtonWithIconCssRequired,
        'm-button__dark': isDark,
        'is-plain': isPlain
      }"
      :[buttonStyle]="buttonStyle"
      :type="buttonType"
      :loading="loading"
      :disabled="canManage.disabled"
      :size="size"
      :autofocus="autofocus"
      @click="$emit('click', $event)"
    >
      <slot>
        <m-icon
          v-if="isButtonWithIconCssRequired"
          custom-class="button-icon"
          :icon="isButtonWithIconCssRequired"
          :size="iconSize"
          :color="iconColor"
          :no-use-fw="noUseFw"
          :use-brand="useBrandIcon"
          :use-solid="useSolidIcon"
        />

        <m-icon
          v-if="plusIcon"
          custom-class="button-icon"
          icon="add"
          :size="iconSize"
          :color="iconColor"
        />

        <span
          class="m-button__text"
          :class="{ 'use-indent': buttonText}"
        >
          {{ buttonText }}
        </span>
      </slot>
    </el-button>
  </div>
</template>

<script>
import MIcon from '@/vue_present/_base/MIcon/MIcon'
import { DisabledMixin } from '@/vue_present/mixins/DisabledMixin'
import { props } from '@/vue_present/_base/buttons/MButton/props'
import { computed } from '@/vue_present/_base/buttons/MButton/computed'

export default {
  name: 'MButton',

  components: { MIcon },

  mixins: [DisabledMixin],

  props,

  computed,
}
</script>
