<template>
  <div class="print-raw-receipt-modal-receipt-entries-item">
    <!-- Наименование позиции -->
    <m-input
      class="print-raw-receipt-modal-receipt-entries-item__title"
      :value="entryItem.title"
      :label="t$('title')"
      required
      full-width
      validator-name="title"
      @registerValidator="onRegisterValidator"
      @input="entryItem.setValue('title', $event)"
    />

    <div class="print-raw-receipt-modal-receipt-entries-item__row">
      <!-- Тип позиции -->
      <m-select
        :value="entryItem.kind"
        :items="catalogItems"
        :label="t$('kind')"
        :placeholder="t('select')"
        required
        validator-name="kind"
        @registerValidator="onRegisterValidator"
        @change="entryItem.setValue('kind', $event)"
      />

      <!-- Цена -->
      <m-input
        class="print-raw-receipt-modal-receipt-entries-item__price-and-amount"
        :value="entryItem.price"
        :label="t$('price')"
        type="number"
        :min="0"
        :required="greaterThanZeroValidator"
        validator-name="price"
        @registerValidator="onRegisterValidator"
        @input="entryItem.setValue('price', $event)"
      />

      <!-- Количество -->
      <m-input
        class="print-raw-receipt-modal-receipt-entries-item__price-and-amount"
        :value="entryItem.amount"
        :label="t$('amount')"
        type="number"
        :min="0"
        :precision="0"
        :required="greaterThanZeroValidator"
        validator-name="amount"
        @registerValidator="onRegisterValidator"
        @input="entryItem.setValue('amount', $event)"
      />

      <!-- Единица измерения -->
      <m-select-lazy
        :value="entryItem.measureUnit"
        :label="t$('measureUnit')"
        :placeholder="t('select')"
        :fetch-method="MeasureUnitApi.list"
        use-first-time-fetch
        option-label="shortTitle"
        filterable
        :clearable="false"
        required
        validator-name="measureUnit"
        @registerValidator="onRegisterValidator"
        @changeByLazy="entryItem.setValue('measureUnit', $event)"
      />

      <!-- НДС -->
      <reusable-tax-rates-list
        :value="entryItem.taxScheme"
        :label="t('nds')"
        @change="entryItem.setValue('taxScheme', $event)"
      />

      <m-button-delete
        class="ml-auto mb-indent-validation"
        @yes="$emit('onDelete')"
      />
    </div>

    <div class="print-raw-receipt-modal-receipt-entries-item__row">
      <!-- Итого -->
      <m-input
        :value="entryItem.sum"
        :label="t$('sum')"
        :m-fixed-height="false"
        readonly
      />

      <!-- НДС -->
      <m-input
        :value="entryItem.ndsValue"
        :label="t$('ndsSum')"
        :m-fixed-height="false"
        readonly
      />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import MInput from '@/vue_present/_base/inputs/MInput/MInput.vue'
import { i18nScopeMixin } from '@/vue_present/mixins/i18nScopeMixin'
import { ValidationParentMixin } from '@/vue_present/mixins/ValidationParentMixin'
import MSelect from '@/vue_present/_base/inputs/MSelect/MSelect.vue'
import MButtonDelete from '@/vue_present/_base/buttons/MButtonDelete/MButtonDelete.vue'
import {
  PrintRawReceiptEntriesItem,
} from '@/vue_apps/catalogs_root/Workplaces/classes/PrintRawPeceipt/PrintRawReceiptEntriesItem'
import {
  PrintRawReceiptModalItemsMixin,
} from '@/vue_apps/catalogs_root/Workplaces/mixins/PrintRawReceiptModalItemsMixin'
import MSelectLazy from '@/vue_present/_base/MSelectLazy/MSelectLazy.vue'
import { MeasureUnitApi } from '@/vue_apps/catalogs_root/MeasureUnits/entities/MeasureUnitApi'
import ReusableTaxRatesList from '@/vue_present/Reuse/Lists/TaxRates/ReusableTaxRatesList.vue'

export default defineComponent({
  name: 'PrintRawReceiptModalReceiptEntriesItem',

  components: {
    ReusableTaxRatesList,
    MSelectLazy,
    MButtonDelete,
    MSelect,
    MInput,
  },

  mixins: [
    i18nScopeMixin,
    ValidationParentMixin,
    PrintRawReceiptModalItemsMixin,
  ],

  props: {
    entryItem: PrintRawReceiptEntriesItem,
  },

  emits: ['onDelete'],

  data () {
    return {
      MeasureUnitApi,
    }
  },

  created () {
    this.setI18nScope('fiscal.receiptEntry')
    this.setCatalogItemsPrefix('fiscal.entryKinds')
  },
})
</script>
