import { ICRUDReturn } from '@/_api/decorators/api/interfaces/ICRUDReturn'
import { Api } from '@/_api/decorators/api/Api'
import {
  IClinicalGuidelineListItem,
} from '@/vue_present/Reuse/Lists/ClinicalGuidelines/interfaces/IClinicalGuidelineListItem'
import { IList } from '@/_declarations/IList'
import {
  reusableClinicalGuidelinesListAdapter,
} from '@/vue_present/Reuse/Lists/ClinicalGuidelines/api/reusableClinicalGuidelinesListAdapter'
import {
  IClinicalGuidelineListToServer,
} from '@/vue_present/Reuse/Lists/ClinicalGuidelines/interfaces/IClinicalGuidelineListToServerDTO'

export class ReusableClinicalGuidelinesListApi {
  @Api.notify('listMessage', 'clinicalGuidelines', { success: false })
  @Api.clientAdapter(reusableClinicalGuidelinesListAdapter)
  @Api.list(Routes.list_api_internal_clinical_guidelines_path)
  @Api.serverAdapter(reusableClinicalGuidelinesListAdapter)
  fetch (payload: IClinicalGuidelineListToServer) {
    return {
      payload,
    } as ICRUDReturn as Promise<IList<IClinicalGuidelineListItem[]>>
  }
}
