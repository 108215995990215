import LazyTree from '@/vue_present/Reuse/LazyTree/store/LazyTree'

export class DiseasesLazyTree extends LazyTree {
  /**
   * @private
   * @type {number}
   */
  categoryType = 2

  fetchNode (id, categoryType = this.categoryType, config = {}) {
    return super.fetchNode(id, categoryType, config)
  }
}
