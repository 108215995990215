import { MExpirableCache } from '@/_api/_storage/MExpirableCache'
import { GLOBAL_TIMES } from '@/_global_scripts/GLOBAL_CONSTS'
import { ProtocolApi } from '@/vue_apps/ProtocolsApp/entities/ProtocolApi'

export declare interface IProtocolDraft {
  templateData: string
  templateHtml: string
  semdType: number
  printingTools: {
    entryInfo: boolean
    entryTitle: boolean
    clinicHeader: boolean
    doctorSignature: boolean
  }
}

export class ProtocolDraftStorage extends MExpirableCache {
  static KEY = 'protocolDraft'

  static DEFAULT_PROTOCOL_ID = 'new'

  static KEY_DELIMITER = '::'

  static setProtocolValues (protocol: ProtocolApi<any>, source: IProtocolDraft) {
    protocol.fillPrintingTools(source.printingTools)
    protocol.setValue('templateData', source.templateData)
    protocol.setValue('templateHtml', source.templateHtml)
    protocol.setValue('semdType', source.semdType)
  }

  static getProtocolIds () {
    return Object.keys(localStorage)
      .filter((key) => key.startsWith(ProtocolDraftStorage.KEY))
      .map((key) => +key.split(ProtocolDraftStorage.KEY_DELIMITER)[2])
  }

  protected protocolId: string | number = ProtocolDraftStorage.DEFAULT_PROTOCOL_ID

  constructor (protocolId?: number, clientId = gon.specific.client.id as number) {
    if (!clientId) {
      throw new Error('clientId is not defined')
    }

    const currentProtocolId = protocolId || ProtocolDraftStorage.DEFAULT_PROTOCOL_ID

    const key = [
      ProtocolDraftStorage.KEY,
      clientId,
      currentProtocolId,
    ].join(ProtocolDraftStorage.KEY_DELIMITER)

    super(key, { timeout: GLOBAL_TIMES.ONE_WEEK })

    this.protocolId = currentProtocolId
  }

  save (data) {
    super.save(data)
    this.sendDraftSignal('ProtocolDraftStorage:Saved')
  }

  drop (key = this.key) {
    super.drop(key)
    this.sendDraftSignal('ProtocolDraftStorage:Dropped')
  }

  cleanExpired () {
    super.cleanExpired(ProtocolDraftStorage.KEY)
  }

  protected sendDraftSignal (target = '') {
    if (!target) { return }
    Services.pubSub.emit(target, { protocolId: this.protocolId }, true)
  }
}
