<template>
  <clinical-guideline-collapse-item
    class="clinical-guideline-collapse-item__meta-information"
    :collapse-item-id="CLINICAL_GUIDELINE_COLLAPSE_ITEM.META_INFORMATION"
    :active-names="activeNames"
  >
    <template #afterTitle>
      <m-icon
        v-if="clinicalGuideline.releaseStatus.id !== CLINICAL_GUIDELINE_RELEASE_STATUS.ACTIVE"
        v-tooltip="clinicalGuideline.releaseStatus.title"
        icon="warning"
        color="warning"
      />
    </template>

    <div class="clinical-guideline-collapse-item__container">
      <!-- Статус -->
      <clinical-guideline-collapse-item-labeled-value
        :label="t('status')"
        :value="clinicalGuideline.releaseStatus.title"
        :value-css-class="releaseStatusCssClass"
      />

      <!-- Категория -->
      <clinical-guideline-collapse-item-labeled-value
        :label="t('category')"
        :value="clinicalGuideline.age.title"
      />

      <!-- Год выхода -->
      <clinical-guideline-collapse-item-labeled-value
        :label="t$('catalog.releaseYear')"
        :value="clinicalGuideline.releaseYear.title"
      />

      <!-- ID МинЗдрава -->
      <clinical-guideline-collapse-item-labeled-value
        :label="t$('catalog.ministryId')"
        :value="clinicalGuideline.ministryId"
      />
    </div>
  </clinical-guideline-collapse-item>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import {
  ClinicalGuidelineApi,
} from '@/vue_apps/catalogs_root/ClinicalGuidelinesCatalog/classes/ClinicalGuideline/ClinicalGuidelineApi'
import ClinicalGuidelineCollapseItem from '@/vue_apps/ClinicalGuidelines/components/ClinicalGuidelineCollapseItem.vue'
import { CLINICAL_GUIDELINE_COLLAPSE_ITEM } from '@/vue_apps/ClinicalGuidelines/consts/clinicalGuidelineCollapseItems'
import { i18nScopeMixin } from '@/vue_present/mixins/i18nScopeMixin'
import {
  CLINICAL_GUIDELINE_RELEASE_STATUS,
  getClinicalGuidelineReleaseStatusCssClassColor,
} from '@/vue_apps/catalogs_root/ClinicalGuidelinesCatalog/consts/clinicalGuidelineReleaseStatus'
import ClinicalGuidelineCollapseItemLabeledValue
  from '@/vue_apps/ClinicalGuidelines/components/ClinicalGuidelineCollapseItemLabeledValue.vue'
import MIcon from '@/vue_present/_base/MIcon/MIcon.vue'

export default defineComponent({
  name: 'ClinicalGuidelineCollapseItemMetaInformation',

  components: {
    MIcon,
    ClinicalGuidelineCollapseItemLabeledValue,
    ClinicalGuidelineCollapseItem,
  },

  mixins: [i18nScopeMixin],

  props: {
    activeNames: { type: Array as PropType<Array<string | number>>, default: () => [] },
    clinicalGuideline: { type: ClinicalGuidelineApi, required: true },
  },

  data () {
    return {
      CLINICAL_GUIDELINE_COLLAPSE_ITEM,
      CLINICAL_GUIDELINE_RELEASE_STATUS,
    }
  },

  computed: {
    releaseStatusCssClass (): string | undefined {
      return getClinicalGuidelineReleaseStatusCssClassColor(this.clinicalGuideline.releaseStatus.id)
    },
  },

  created () {
    this.setI18nScope('clinicalGuidelines')
  },
})
</script>
