<template>
  <div class="m-si-generator__filters-buttons">
    <!-- Обновить фильтры -->
    <m-button
      v-if="useRefresh"
      class="m-si-generator__refresh"
      :template="refreshButtonTemplate"
      :disabled="disabled"
      @click="$emit('onRefreshClick')"
    />

    <!-- Сбросить фильтры -->
    <m-button
      v-if="useReset"
      class="m-si-generator__reset"
      type="warning"
      :tooltip="t('reset')"
      :disabled="disabled"
      no-use-fw
      icon="no-filter"
      @click="$emit('onResetClick')"
    />
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import MButton from '@/vue_present/_base/buttons/MButton/MButton.vue'

export default defineComponent({
  name: 'MSiGeneratorFiltersButtons',
  components: { MButton },

  props: {
    disabled: Boolean,
    refreshButtonTemplate: { type: String, default: 'short_refresh' },
    useRefresh: { type: Boolean, default: true },
    useReset: { type: Boolean, default: true },
  },

  emits: [
    'onRefreshClick',
    'onResetClick',
  ],
})
</script>
