import { ISemdSource } from '@/vue_apps/Semds/declarations/ISemdSource'
import { TSemdConnectorReturn } from '@/vue_apps/Semds/SemdSourceConnector/declarations/semdsSourceConnectorDeclarations'
import { confirmNewSemd } from '@/vue_apps/Semds/SemdSourceConnector/confirmNewSemd'
import { confirmResetSign } from '@/vue_apps/Semds/SemdSourceConnector/confirmResetSign'
import { SemdListItem } from '@/vue_apps/Semds/entities/list/SemdListItem'

/**
 * Базовая БЛ для SemdSource (протокол, медзаключение ЛЭМК и т.д.):
 * 1) устаревший сэмд --> пропускаем
 * 2) сэмд зарегистрирован --> подтверждаем новый сэмд, версия++
 * 3) сэмд отправлен, но не зарегистрирован --> сорян, редактировать нельзя
 * 4) у сэмд есть подпись --> подтверждаем изменение source
 *
 * При необходимости подтверждения можно переопределить методами:
 * 1) lastSemdOutdatedCheck
 * 2) newSemdApprovedCheck
 * 3) newSignatureApprovedCheck
 */
export const baseSemdSourceEditCheck =
  async (source: ISemdSource, lastSemd: SemdListItem): TSemdConnectorReturn => {
    if (!lastSemd) { return { cancel: false } }

    if (lastSemd.isOutdatedVersion()) {
      return { cancel: false }
    }

    if (lastSemd.isRegisteredInEgisz() && !source.newSemdApproved) {
      return await confirmNewSemd(source)
    }

    if (lastSemd.isSuccessFullySent()) {
      Notificator.warning(t('semds.errors.successfullySentSemdsExists'))

      return { cancel: true }
    }

    if (lastSemd.isSigned() && !source.newSignatureApproved) {
      return await confirmResetSign(source)
    }

    return { cancel: false }
  }
