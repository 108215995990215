<template>
  <div class="acquiring-filters flex flex-wrap gap-indent-small">
    <!-- Дата-кнопки -->
    <m-date-picker-buttons
      :value="filters.date"
      :use-only="useOnly"
      :m-fixed-height="false"
      :use-omit="['tomorrow']"
      value-format="yyyy-MM-dd"
      @change="list.setFilterValue('date', $event)"
    />

    <!-- Дата-пикер -->
    <m-date-picker
      v-if="usePicker"
      :value="filters.date"
      type="daterange"
      value-format="yyyy-MM-dd"
      :m-fixed-height="false"
      @change="list.setFilterValue('date', $event)"
    />

    <!-- Тип операции -->
    <m-select
      :value="filters.operationType"
      :items="OPERATION_TYPE_FILTER_ITEMS"
      :label="t$('operationType')"
      :placeholder="t$('allOperationType')"
      :m-fixed-height="false"
      @change="list.setFilterValue('operationType', $event)"
    />

    <reusable-doctors-list
      :value="filters.user"
      :label="t('user')"
      :placeholder="t('all_employees')"
      current-clinic
      :user-group="USER_GROUP_IDS.CLINIC_PERSONNEL"
      @onDoctorChange="list.setFilterValue('user', $event)"
    />

    <!-- Статус -->
    <m-select
      :value="filters.status"
      :items="STATUS_FILTER_ITEMS"
      :label="t('status')"
      :placeholder="t$('allStatuses')"
      :m-fixed-height="false"
      @change="list.setFilterValue('status', $event)"
    />

    <!-- Рабочее место -->
    <m-select-lazy
      :value="filters.workplace"
      :label="t('workplace')"
      :fetch-method="(params) => filtersPresenter.fetchWorkplaces(params)"
      :placeholder="t$('allWorkplaces')"
      :m-fixed-height="false"
      use-first-time-fetch
      @changeByLazy="list.setFilterValue('workplace', $event)"
    />

    <!-- Способ оплаты -->
    <m-buttons-group
      class="acquiring-filters_payment-type"
      :value="filters.paymentType"
      :items="PAYMENT_TYPE_FILTER_ITEMS"
      :m-fixed-height="false"
      radio
      unselect
      @change="list.setFilterValue('paymentType', $event)"
    />

    <!-- Кнопки обновления и сброса -->
    <m-si-generator-filters-buttons
      @onRefreshClick="list.refreshFilters()"
      @onResetClick="list.resetFilters()"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import MDatePickerButtons from '@/vue_present/_base/MDatePickerButtons/MDatePickerButtons.vue'
import { MListServiceApi } from '@/_api/_requests/MListServiceApi/MListServiceApi'
import MSiGeneratorFiltersButtons
  from '@/vue_present/_base/Tables/MSiGenerator/components/MSiGeneratorFiltersButtons.vue'
import MDatePicker from '@/vue_present/_base/inputs/MDatePicker/MDatePicker.vue'
import MSelect from '@/vue_present/_base/inputs/MSelect/MSelect.vue'
import { i18nScopeMixin } from '@/vue_present/mixins/i18nScopeMixin'
import {
  OPERATION_TYPE_FILTER_ITEMS,
} from '@/vue_apps/FinanceModule/FinanceModuleIndex/consts/Acquiring/operationTypeFilterItems'
import ReusableDoctorsList from '@/vue_present/Reuse/Lists/Doctors/ReusableDoctorsList.vue'
import MSelectLazy from '@/vue_present/_base/MSelectLazy/MSelectLazy.vue'
import MButtonsGroup from '@/vue_present/_base/buttons/MButtonsGroup/MButtonsGroup.vue'
import { STATUS_FILTER_ITEMS } from '@/vue_apps/FinanceModule/FinanceModuleIndex/consts/Acquiring/statusFilterItems'
import { FiltersPresenter } from '@/vue_apps/ReportsModule/_filters/api/FiltersPresenter'
import {
  PAYMENT_TYPE_FILTER_ITEMS,
} from '@/vue_apps/FinanceModule/FinanceModuleIndex/consts/Acquiring/paymentTypeFilterItems'
import { IAcquiringFilters } from '@/vue_apps/FinanceModule/FinanceModuleIndex/interfaces/Acquiring/IAcquiringFilters'
import { AcquiringListItem } from '@/vue_apps/FinanceModule/FinanceModuleIndex/classes/Acquiring/AcquiringListItem'
import { USER_GROUP_IDS } from '@/_global_scripts/GLOBAL_CONSTS'

export default defineComponent({
  name: 'AcquiringFilters',

  components: {
    MButtonsGroup,
    MSelectLazy,
    ReusableDoctorsList,
    MSelect,
    MDatePicker,
    MSiGeneratorFiltersButtons,
    MDatePickerButtons,
  },

  mixins: [i18nScopeMixin],

  props: {
    list: { type: Object as PropType<MListServiceApi<AcquiringListItem, IAcquiringFilters>>, required: true },
  },

  data () {
    return {
      filtersPresenter: new FiltersPresenter(),
      OPERATION_TYPE_FILTER_ITEMS,
      STATUS_FILTER_ITEMS,
      PAYMENT_TYPE_FILTER_ITEMS,
      USER_GROUP_IDS,
    }
  },

  computed: {
    filters () {
      return this.list.filters
    },

    useOnly () {
      return gon.application.security.current_user_permissions.Finance
    },

    usePicker () {
      return gon.application.security.current_user_permissions.Finance
        .includes('Period')
    },
  },

  created () {
    this.setI18nScope('finance')
  },
})
</script>
