<template>
  <div class="fixed-document-block tax-certificate2024-editor pt-6">
    <div class="tax-certificate2024-editor__body fixed-document-block mb-indent">
      <m-prompt-notice
        class="mb-indent-mid"
        type="alert"
        :text="t$('clientPromptNotice')"
      />

      <tax-certificate2024-editor-main-block
        :certificate="certificate"
        :is-primary="isPrimary"
        :is-corrective="isCorrective"
        :is-annulling="isAnnulling"
        :is-edit="isEdit"
        @registerValidator="onRegisterValidator"
      />

      <m-prompt-notice
        class="mb-indent-mid"
        type="alert"
        :text="t('fixedDocument.base.checkFieldsAreFilledInCorrectlyAndSaveDocument')"
      />

      <tax-certificate2024-editor-second-block
        :certificate="certificate"
        :is-primary="isPrimary"
        :is-corrective="isCorrective"
        :is-annulling="isAnnulling"
        :disabled="!certificate.isCertificateLoaded"
        @registerValidator="onRegisterValidator"
      />

      <tax-certificate2024-editor-performing-clinic
        :certificate="certificate"
        :is-primary="isPrimary"
        :is-corrective="isCorrective"
        :is-annulling="isAnnulling"
        :disabled="!certificate.isCertificateLoaded"
        @registerValidator="onRegisterValidator"
      />

      <tax-certificate2024-editor-personal-information
        :title="t$('taxpayerInformation')"
        is-taxpayer
        :certificate="certificate"
        :is-primary="isPrimary"
        :is-corrective="isCorrective"
        :is-annulling="isAnnulling"
        :disabled="!certificate.isCertificateLoaded"
        @registerValidator="onRegisterValidator"
      />

      <!-- Сумма расходов по коду услуги 1 -->
      <tax-certificate2024-editor-sum
        :value="certificate.sumOne"
        :service-code="1"
        :disabled="!certificate.isCertificateLoaded"
        @input="certificate.setValue('sumOne', $event)"
      />

      <!-- Сумма расходов по коду услуги 2 -->
      <tax-certificate2024-editor-sum
        :value="certificate.sumTwo"
        :service-code="2"
        :disabled="!certificate.isCertificateLoaded"
        @input="certificate.setValue('sumTwo', $event)"
      />

      <m-prompt-notice :text="t$('infoAboutEmployeeWhoCreatedCertificate')" />

      <!-- Сотрудник -->
      <reusable-doctors-list
        :label="t('employee')"
        :value="certificate.employee"
        :clearable="false"
        :disabled="!certificate.isCertificateLoaded"
        @onDoctorChange="certificate.setValue('employee', $event)"
      />

      <tax-certificate2024-editor-personal-information
        v-if="!certificate.taxpayerIsClient"
        :title="t$('infoAboutPatientToWhomServicesWereProvided')"
        :certificate="certificate"
        :is-primary="isPrimary"
        :is-corrective="isCorrective"
        :is-annulling="isAnnulling"
        :disabled="!certificate.isCertificateLoaded"
        @registerValidator="onRegisterValidator"
      />
    </div>

    <div class="tax-certificate2024-editor__footer gap-indent-small">
      <div class="tax-certificate2024-editor__buttons">
        <m-button
          template="save"
          :disabled="!certificate.isCertificateLoaded"
          @click="submit"
        />

        <m-button
          template="saveAndPrint"
          :disabled="!certificate.isCertificateLoaded"
          @click="submit({ print: true })"
        />

        <m-button
          template="saveAndPrint"
          :disabled="!certificate.isCertificateLoaded"
          :text="t$('xml.saveAndGenerateXML')"
          @click="submit({ generateXml: true })"
        />

        <m-button-delete
          v-if="certificate.id"
          use-button
          :popover-message="t('fixedDocument.base.deleteDocument')"
          :disabled="!certificate.isCertificateLoaded"
          style="margin-right: auto"
          @yes="onDestroy"
        />
      </div>

      <m-back-button />
    </div>
  </div>
</template>

<script>
import { i18nScopeMixin } from '@/vue_present/mixins/i18nScopeMixin'
import MPromptNotice from '@/vue_present/_base/MPromptNotice/MPromptNotice.vue'
import MButton from '@/vue_present/_base/buttons/MButton/MButton.vue'
import MBackButton from '@/vue_present/_base/buttons/MButtonBack/MButtonBack.vue'
import TaxCertificate2024EditorSum
  from '@/vue_apps/Documents/FixedDocuments/_documents/TaxCertificate2024/components/TaxCertificate2024Editor/TaxCertificate2024EditorSum.vue'
import TaxCertificate2024EditorPersonalInformation
  from '@/vue_apps/Documents/FixedDocuments/_documents/TaxCertificate2024/components/TaxCertificate2024Editor/TaxCertificate2024EditorPersonalInformation.vue'
import TaxCertificate2024EditorPerformingClinic
  from '@/vue_apps/Documents/FixedDocuments/_documents/TaxCertificate2024/components/TaxCertificate2024Editor/TaxCertificate2024EditorPerformingClinic.vue'
import TaxCertificate2024EditorSecondBlock
  from '@/vue_apps/Documents/FixedDocuments/_documents/TaxCertificate2024/components/TaxCertificate2024Editor/TaxCertificate2024EditorSecondBlock.vue'
import TaxCertificate2024EditorMainBlock
  from '@/vue_apps/Documents/FixedDocuments/_documents/TaxCertificate2024/components/TaxCertificate2024Editor/TaxCertificate2024EditorMainBlock.vue'
import {
  TaxCertificate2024,
} from '@/vue_apps/Documents/FixedDocuments/_documents/TaxCertificate2024/classes/TaxCertificate2024'
import ReusableDoctorsList from '@/vue_present/Reuse/Lists/Doctors/ReusableDoctorsList.vue'
import { ValidationParentMixin } from '@/vue_present/mixins/ValidationParentMixin'
import MButtonDelete from '@/vue_present/_base/buttons/MButtonDelete/MButtonDelete.vue'
import {
  CERTIFICATE_TYPE,
} from '@/vue_apps/Documents/FixedDocuments/_documents/TaxCertificate2024/consts/taxCertificate2024EditorConsts'
import { mapActions } from 'vuex'
import {
  TAX_CERTIFICATE_ROUTE_NAMES,
} from '@/vue_apps/Documents/FixedDocuments/_documents/TaxCertificate2024/router/routeNames'
import { scrollToTop } from '@/vue_apps/Documents/FixedDocuments/helpers/scrollToTop'

export default {
  name: 'TaxCertificate2024Editor',

  components: {
    MButtonDelete,
    ReusableDoctorsList,
    MBackButton,
    MButton,
    MPromptNotice,
    TaxCertificate2024EditorSum,
    TaxCertificate2024EditorPersonalInformation,
    TaxCertificate2024EditorPerformingClinic,
    TaxCertificate2024EditorSecondBlock,
    TaxCertificate2024EditorMainBlock,
  },

  mixins: [i18nScopeMixin, ValidationParentMixin],

  props: {
    certificate: TaxCertificate2024,
  },

  data () {
    return {
      CERTIFICATE_TYPE,
      isEdit: false,
    }
  },

  computed: {
    isPrimary () {
      return this.certificate.type === CERTIFICATE_TYPE.PRIMARY
    },

    isCorrective () {
      return this.certificate.type === CERTIFICATE_TYPE.CORRECTIVE
    },

    isAnnulling () {
      return this.certificate.type === CERTIFICATE_TYPE.ANNULLING
    },
  },

  created () {
    this.setI18nScope('fixedDocument.taxCertificate2024')

    this.isEdit = this.$route.name.endsWith('-edit')

    this.vxLoadNSIIdCardTypes()
  },

  methods: {
    ...mapActions('globalCatalogs/nsiStore/idCardTypesDictionaryStore', {
      vxLoadNSIIdCardTypes: 'vxLoadNSIIdCardTypes',
    }),

    async submit ({ print: immediatePrint, generateXml } = {}) {
      if (this.hasErrors()) { return }

      const response = await this.certificate.submit({ isEdit: this.isEdit })
      if (response?.errors) { return }

      const { id: documentId } = response

      scrollToTop()

      await this.$router.push({
        name: TAX_CERTIFICATE_ROUTE_NAMES.SHOW,
        params: { documentId },
        query: {
          ...this.$route.query,
          immediatePrint,
          generateXml,
        },
      })
    },

    async onDestroy () {
      await this.certificate.destroy()
      HistoryBrowser.goBackFrom('fixedDocumentDestroy')
    },
  },
}
</script>
