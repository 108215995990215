<template>
  <div class="print-raw-receipt-modal">
    <m-modal
      :visible="modalVisible"
      :dialog-title="t('printCustomReceipt')"
      modal-title-icon="receipt"
      width="1118px"
      @close="closeModal"
    >
      <template #body>
        <div
          v-loading="receipt.loading"
          class="print-raw-receipt-modal__body"
        >
          <!-- Тип чека -->
          <m-select
            :value="receipt.kind"
            :items="receiptKindsItems"
            :label="t$('receiptKind')"
            :placeholder="t('select')"
            required
            validator-name="receiptKind"
            @registerValidator="onRegisterValidator"
            @change="receipt.setValue('kind', $event)"
          />

          <print-raw-receipt-modal-correction-receipt
            v-if="correctionReceipt"
            ref="correctionReceipt"
            :items="receipt.receiptInfo.correctionTypes"
            @collectCorrectionReceipt="collectCorrectionReceipt = $event"
          />

          <m-prompt-notice :text="entriesNotice" />

          <div class="flex flex-column gap-indent-validation mb-indent-validation">
            <m-card
              v-for="item in receiptEntries"
              :key="item.id"
            >
              <print-raw-receipt-modal-receipt-entries-item
                :ref="`receiptEntries-${item.id}`"
                :entry-item="item"
                :items="receipt.receiptInfo.entryKinds"
                @onDelete="receipt.entries.remove(item.id)"
              />
            </m-card>
          </div>

          <m-button
            v-show="addEntryButtonShow"
            class="mb-indent-validation"
            type="success"
            :text="t$('receiptEntry.add')"
            @click="receipt.entries.append()"
          />

          <m-prompt-notice :text="paymentItemsNotice" />

          <div class="flex flex-column">
            <print-raw-receipt-modal-receipt-payments-item
              v-for="item in receiptPayments"
              :key="item.id"
              :ref="`receiptPayments-${item.id}`"
              :payment-item="item"
              :items="receipt.receiptInfo.paymentKinds"
              @onDelete="receipt.payments.remove(item.id)"
            />
          </div>

          <m-button
            type="success"
            :text="t$('receiptPayment.add')"
            @click="receipt.payments.append()"
          />
        </div>
      </template>

      <template #footer-right-action>
        <m-button
          template="save"
          @click="onSubmit"
        />
      </template>
    </m-modal>

    <slot
      :open-modal="openModal"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { ModalMixin } from '@/vue_present/mixins/ModalMixins/ModalMixin'
import MModal from '@/vue_present/_base/MModal/MModal.vue'
import MButton from '@/vue_present/_base/buttons/MButton/MButton.vue'
import MSelect from '@/vue_present/_base/inputs/MSelect/MSelect.vue'
import { i18nScopeMixin } from '@/vue_present/mixins/i18nScopeMixin'
import { ValidationParentMixin } from '@/vue_present/mixins/ValidationParentMixin'
import MPromptNotice from '@/vue_present/_base/MPromptNotice/MPromptNotice.vue'
import PrintRawReceiptModalCorrectionReceipt
  from '@/vue_apps/catalogs_root/Workplaces/components/PrintRawReceiptModal/PrintRawReceiptModalCorrectionReceipt.vue'
import { PrintRawReceiptApi } from '@/vue_apps/catalogs_root/Workplaces/classes/PrintRawPeceipt/PrintRawReceiptApi'
import PrintRawReceiptModalReceiptEntriesItem
  from '@/vue_apps/catalogs_root/Workplaces/components/PrintRawReceiptModal/PrintRawReceiptModalReceiptEntriesItem.vue'
import PrintRawReceiptModalReceiptPaymentsItem
  from '@/vue_apps/catalogs_root/Workplaces/components/PrintRawReceiptModal/PrintRawReceiptModalReceiptPaymentsItem.vue'
import { isArray } from '@/helpers/typeofHelpers'
import {
  PrintRawReceiptEntriesItem,
} from '@/vue_apps/catalogs_root/Workplaces/classes/PrintRawPeceipt/PrintRawReceiptEntriesItem'
import { checkOFDBannedTaxSchemes } from '@/specific/payments/checkOFDBannedTaxSchemes'
import MCard from '@/vue_present/_base/MCard/MCard.vue'

export default defineComponent({
  name: 'PrintRawReceiptModal',

  components: {
    MCard,
    PrintRawReceiptModalReceiptPaymentsItem,
    PrintRawReceiptModalCorrectionReceipt,
    PrintRawReceiptModalReceiptEntriesItem,
    MPromptNotice,
    MSelect,
    MButton,
    MModal,
  },

  mixins: [
    ModalMixin,
    i18nScopeMixin,
    ValidationParentMixin,
  ],

  data () {
    return {
      receipt: new PrintRawReceiptApi(),
      collectCorrectionReceipt: null,
    }
  },

  computed: {
    receiptKindsItems () {
      return this.receipt
        .receiptInfo
        .receiptKinds
        .map((receiptKind: string) => ({
          id: receiptKind,
          title: t(`fiscal.receiptKinds.${receiptKind}`),
        }))
    },

    receiptEntries () {
      return this.receipt.entries.getArr()
    },

    receiptPayments () {
      return this.receipt.payments.getArr()
    },

    correctionReceipt () {
      return this.receipt.kind && this.receipt.kind.id.includes('correction')
    },

    receiptEntriesSum () {
      return this.receipt.getSumOfItems(this.receiptEntries, 'sum')
    },

    receiptEntriesNdsSum () {
      return this.receipt.getSumOfItems(this.receiptEntries, 'ndsValue')
    },

    receiptPaymentsSum () {
      return this.receipt.getSumOfItems(this.receiptPayments, 'sum')
    },

    entriesNotice () {
      return this.t$('receiptEntriesNotice', {
        sum: this.receiptEntriesSum,
        nds: this.receiptEntriesNdsSum,
      })
    },

    paymentItemsNotice () {
      return this.t$('receiptPaymentsNotice', {
        sum: this.receiptPaymentsSum,
      })
    },

    addEntryButtonShow () {
      return !this.correctionReceipt || !this.receiptEntries.length
    },
  },

  created () {
    this.setI18nScope('fiscal')
  },

  methods: {
    openModal () {
      this.receipt.requestReceiptInfo()
      this.modalVisible = true
      this.clear()
    },

    closeModal () {
      this.modalVisible = false
      this.clear()
    },

    clear () {
      this.receipt.clear()
      this.resetValidations()
    },

    nestedFormsHasErrors () {
      return Object.values(this.$refs)
        .map((_ref: Array<Record<string, any>> | Record<string, any>) => {
          const ref = isArray(_ref)
            ? _ref[0]
            : _ref

          return ref?.hasErrors()
        })
        .some(Boolean)
    },

    onSubmit () {
      if (this.hasErrors() || this.nestedFormsHasErrors()) { return }
      if (!this.receipt.validate()) { return }

      const receiptEntries = this.receiptEntries.map((item: PrintRawReceiptEntriesItem) => ({
        title: item.title,
        taxScheme: item.taxScheme.id,
      }))

      const { cancel } = checkOFDBannedTaxSchemes(receiptEntries)
      if (cancel) { return }

      const correction = this.correctionReceipt
        ? this.collectCorrectionReceipt()
        : { date: Utils.getBaseFormattedDate() }

      this.receipt.sendReceipt(correction)
      this.closeModal()
    },
  },
})
</script>
