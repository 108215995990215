<template>
  <lazy-tree-node-component
    :data="data"
    :node="node"
    item-icon="clinicalGuideline"
    :item-icon-color="itemIconColor"
    :item-icon-tooltip="itemIconTooltip"
  />
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import LazyTreeNodeComponent from '@/vue_present/Reuse/LazyTree/components/LazyTreeNodeComponent.vue'
import {
  getClinicalGuidelineReleaseStatusCssClassColor,
} from '@/vue_apps/catalogs_root/ClinicalGuidelinesCatalog/consts/clinicalGuidelineReleaseStatus'
import { camelCase } from 'lodash'

export default defineComponent({
  name: 'ClinicalGuidelinesCatalogTreeNode',

  components: { LazyTreeNodeComponent },

  props: {
    node: { type: Object, required: true },
    data: { type: Object, required: true },
  },

  computed: {
    itemIconColor () {
      return getClinicalGuidelineReleaseStatusCssClassColor(this.data.extraAttributes.releaseStatus)
    },

    itemIconTooltip () {
      const releaseStatus = camelCase(this.data.extraAttributes.releaseStatus)

      return t(`clinicalGuidelines.releaseStatus.${releaseStatus}`)
    },
  },
})
</script>
