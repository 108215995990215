import { IWorkplaceKKMInfo } from '@/vue_apps/catalogs_root/Workplaces/interfaces/IWorkplaceKKMInfo'
import { FISCAL_PRINTER_STATUSES } from '@/vue_apps/catalogs_root/Workplaces/consts/fiscalPrinterStatuses'

export const getFiscalPrinterStatus = ({ opened, expired }: IWorkplaceKKMInfo): FISCAL_PRINTER_STATUSES => {
  if (opened === null) return FISCAL_PRINTER_STATUSES.UNKNOWN
  if (opened && expired === null) return FISCAL_PRINTER_STATUSES.IS_OPENED
  if (!opened) return FISCAL_PRINTER_STATUSES.IS_CLOSED

  return expired
    ? FISCAL_PRINTER_STATUSES.IS_OVER_24H
    : FISCAL_PRINTER_STATUSES.IS_LESS_24H
}
