import { ICatalog } from '@/_declarations/ICatalog'
import { tScoped } from '@/helpers/i18nHelpers'
import { camelCase } from 'lodash'
import { orNull } from '@/_declarations/orNull'

export class CatalogHelpers {
  static getCatalogItemsFromEnum<EnumType = string> (eNum: object, i18nScope?: string): Array<ICatalog<EnumType>> {
    const getTitle = i18nScope
      ? (key: string) => tScoped(i18nScope)(camelCase(key))
      : (id: string) => id

    return Object.values(eNum).map((id) => ({
      id,
      title: getTitle(id),
    }))
  }

  static getItemFromCatalogItems<IdType = string | number> (catalogItems: Array<ICatalog<IdType>>, id: string | number): orNull<ICatalog<IdType>> {
    return Object.values(catalogItems)
      .find((item) => item.id === id) ?? null
  }

  static getNumerableCatalogItemsByRange<IdType extends number = number> (from: number, to: number): Array<ICatalog<IdType>> {
    return Array.from(
      { length: to - from + 1 },
      (_, i) => ({
        id: from + i as IdType,
        title: `${from + i}`,
      })
    )
  }

  static buildCatalog<IdType = string | number> (id: IdType, title?: string): ICatalog<IdType> {
    return {
      id,
      title: title ?? id.toString(),
    }
  }
}
