<template>
  <div class="m-switch">
    <el-switch
      :value="value"
      :class="customClass"
      :active-text="activeText"
      :inactive-text="inactiveText"
      :active-color="activeColor"
      :inactive-color="inactiveColor"
      :disabled="canManage.disabled"
      :active-value="activeValue"
      :inactive-value="inactiveValue"
      @focus="$emit('focus', $event)"
      @change="$emit('change', $event)"
    />

    <m-icon
      v-show="questionMarkTooltip && !canManage.disabled"
      v-tooltip="questionMarkTooltip"
      icon="questionCircle"
      color="gray"
    />
  </div>
</template>

<script>
import { DisabledMixin } from '@/vue_present/mixins/DisabledMixin'
import MIcon from '@/vue_present/_base/MIcon/MIcon.vue'

export default {
  name: 'MSwitch',
  components: { MIcon },

  mixins: [DisabledMixin],

  model: {
    prop: 'value',
    event: 'change',
  },

  props: {
    value: { type: [Boolean, String, Number], default: null },
    customClass: { type: String, default: null },
    activeColor: { type: String, default: null },
    inactiveColor: { type: String, default: null },
    activeText: { type: String, default: null },
    inactiveText: { type: String, default: null },
    activeValue: { type: String, default: undefined },
    inactiveValue: { type: String, default: undefined },

    questionMarkTooltip: { type: String, default: '' },
  },
}
</script>
