import { INsiEntry } from '@/_declarations/INsiEntry'

export const defaultSearchFilter = (filterValue = '', searchField = 'title') =>
  (item: object) => item[searchField].toLowerCase().includes(filterValue?.trim()?.toLowerCase())

export const defaultNumberSorting = (a: number, b: number) => a - b
export const defaultStringSorting =
  (a = '', b = '') => a.toLowerCase() < b.toLowerCase() ? -1 : 1

export const defaultObjectsSorting =
  (field = 'title') => (a = '', b = '') => defaultStringSorting(a[field], b[field])

export const nsiEntrySearchFilter = (filterValue = '') =>
  (item: INsiEntry) =>
    defaultSearchFilter(filterValue)(item) || defaultSearchFilter(filterValue, 'nsiId')(item)

/**
 * value => !value
 */
export const not = (value: any) => !value
