import { MEASURE_UNITS_ROUTES_NAMES } from './measureUnitsRoutesNames'
import MeasureUnitsEditor from '../components/MeasureUnitsEditor.vue'

const getDefaultRoute = (path = '') => `/measure_units${path}`

export const measureUnitsRoutes = [
  {
    path: getDefaultRoute('/:id/edit'),
    name: MEASURE_UNITS_ROUTES_NAMES.EDIT,
    component: MeasureUnitsEditor,
  },

  {
    path: getDefaultRoute('/new'),
    name: MEASURE_UNITS_ROUTES_NAMES.NEW,
    component: MeasureUnitsEditor,
  },

  {
    path: getDefaultRoute(),
    name: MEASURE_UNITS_ROUTES_NAMES.SHOW,
  },

  {
    path: getDefaultRoute('/*'),
    redirect: { name: MEASURE_UNITS_ROUTES_NAMES.SHOW },
  },
]
