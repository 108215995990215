import { camelCase, isPlainObject, snakeCase } from 'lodash'
import { WITHOUT_SUCCESS_NOTIFY } from '@/_api/_requests/const'

/**
 * Трансформирует ключи всех объектов передаваемой сущности, в том числе ключи
 * сущности-объекта (ключи начинающиеся с '_' пропускают преобразование).
 * @param {Function} transform возвращает строку с трансформированным ключом
 * @param {[Object, Array]} entity сущность, по ключам объектов которой
 * идет трансформация
 * @returns {[Object, Array]} возвращает копию передаваемой сущности с
 * трансформированными ключами
 */
export const transformKeys = (transformer) => (entity) => {
  if (Array.isArray(entity)) {
    return entity.map((item) => transformKeys(transformer)(item))
  } else if (typeof entity === 'object' && entity !== null) {
    return transformObjectKeys(entity, transformer)
  }

  return entity
}

function transformObjectKeys (object, transformer) {
  const newObject = {}

  for (const key in object) {
    const crutchedKey = key.startsWith('_') || key === 'phone1' || key === 'phone2'
      ? key
      : transformer(key)
    newObject[crutchedKey] = transformKeys(transformer)(object[key])
  }

  return newObject
}

export const snakeToCamel = (data) => transformKeys(camelCase)(data)
export const camelToSnake = (data) => transformKeys(snakeCase)(data)

// utils_report_error.ru.yml
export const reportErrorText = (errorMessage, errorEntity) =>
  t(errorMessage, { scope: 'utilsReport.errors', entity: t(errorEntity, { scope: 'utilsReport.entities' }) })

// utils_report_success.ru.yml
export const reportSuccessText = (successMessage, successEntity) =>
  t(successMessage, { scope: 'utilsReport.successes', entity: t(successEntity, { scope: 'utilsReport.entities' }) })

export const getLocationTemplate = (location, entity, method) => `${location}::${entity}::${method}`

export const isWithoutSuccessNotify = (CRUDMethod) => WITHOUT_SUCCESS_NOTIFY.includes(CRUDMethod)

/**
 * @param {object | Array<any>} data
 * @param {{
 *   toJson?: boolean,
 *   toJSON?: boolean,
 *   toClientAdapter?: function,
 *   toServerAdapter?: function,
 * }} config
 * @returns {{
 *  data: object,
 *  headers: JSONHeaders | {},
 * }}
 */
export const extractRequestParams = (data, config = {}) => {
  const toJSON = config?.toJson || config?.toJSON

  return {
    data: toJSON
      ? JSON.stringify(camelToSnake(data))
      : data,

    headers: toJSON
      ? { contentType: 'application/json', dataType: 'json' }
      : {},
  }
}

/**
 * @param data
 * @param {{
 *   toJson?: boolean,
 *   toClientAdapter?: function,
 *   toServerAdapter?: function,
 * }} config
 * @returns {Promise<TListServiceResult> | Promise<*> | null}
 */
export const extractRequestData = (data, config = {}) => {
  /**
   * На бекенде не смогли определиться с названием,
   * поэтому пусть пока полежит так
   */
  if (isPlainObject(data) && (data.searchQuery || data.search_query)) {
    data.searchString = data.searchQuery || data.search_query
  }

  return config.toServerAdapter
    ? config.toServerAdapter(data)
    : data
}
