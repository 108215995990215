import { MTableCell } from '@/vue_present/_base/Tables/MTableGenerator/MTableCell'
import { MListServiceItem } from '@/_api/_requests/MListServiceItem'
import { DateHelpers } from '@/helpers/DateHelpers'
import { ORDERS_STATUSES_ICONS } from '@/vue_apps/FinanceModule/FinanceModuleIndex/scripts/consts'
import { vueFilters } from '@/plugins/vue/filtersPlugin/vueFilters'

export class OrdersListCellItem extends MListServiceItem {
  constructor (rawData) {
    super()
    const date = DateHelpers.getTodayTomorrowYesterday(rawData.date)
    this.id = rawData.id

    this.number = new MTableCell(rawData.id).addTypeUrl(Routes.order_path(rawData.id))

    this.date = new MTableCell(date.formattedDate).addClasses(date.dateCss)

    this.status = rawData.paidStatus
      ? new MTableCell()
        .addTypeIcon(ORDERS_STATUSES_ICONS[rawData.paidStatus].icon)
        .addClasses(ORDERS_STATUSES_ICONS[rawData.paidStatus].color)
        .addTooltip(t(`finance.ordersStatuses.${rawData.paidStatus}`))
      : new MTableCell()

    this.client = new MTableCell(rawData.clientFullName)

    this.finalSum = new MTableCell(vueFilters.price(rawData.finalSum))

    this.paidSum = new MTableCell(vueFilters.price(rawData.paidSum))
  }
}
