import { vueFilters } from '@/plugins/vue/filtersPlugin/vueFilters'
import { camelToSnake } from '@/_api/_requests/helpers'

export const userListAdapter = {
  toServer (data) {
    return {
      ...data,
      searchString: data.searchQuery || data.search_query,
    }
  },

  toClient (response) {
    const adaptedData = response.data.map((item) => ({
      ...item,
      fullName: vueFilters.fullName(item),
      shortName: vueFilters.shortName(item),
    }))

    return {
      ...response,
      data: adaptedData,
    }
  },

  toClientRaw (response) {
    const adaptedData = response.data.map((item) => ({
      ...camelToSnake(item),
      full_name: vueFilters.fullName(item),
      short_name: vueFilters.shortName(item),
    }))

    return {
      ...response,
      data: adaptedData,
    }
  },
}
