import BaseEditor from './base_editor'
import applicationFactory from '../../application_factory'
import { BuildEntryVariableModalEvent, BuildPrintAreaModalEvent, ShowPanelEvent } from '../../events/EventEntities'
import {
  CALCULATE,
  COMPLAINTS,
  CONCLUSIONS,
  DESTINATIONS,
  EXTENSIBLE_LIST,
  FIX_LIST,
  VARIABLES,
} from '../../configuration/reducer_types'
import { toolbar3Params } from '@/plugins/dynamic_forms/configuration/config'

export default class ConstructorModeEditor extends BaseEditor {
  isFromScratch () {
    const templateRecordId = document.getElementById('template_record_id')

    return !(templateRecordId && templateRecordId.value)
  }

  /**
   * @param {import('tinymce').Editor} editor
   */
  addButtons (editor) {
    super.addButtons(editor)

    editor.addButton('print_area', {
      icon: 'custom fad fa-crop',
      tooltip: t('print_area'),
      classes: 'variable-btn ',
      onclick: () => {
        this.dispatcher.dispatch(new BuildPrintAreaModalEvent())
      },
    })
    editor.addButton('entry-variables', {
      text: '',
      id: 'add-var',
      tooltip: t('common_variable'),
      icon: 'custom fad fa-brackets-curly',
      classes: 'variable-btn ',
      onclick: () => {
        this.dispatcher.dispatch(new BuildEntryVariableModalEvent())
      },
    })
    editor.addButton('variables', {
      text: '',
      icon: 'custom fad fa-code',
      classes: 'variable-btn ',
      tooltip: t('numeric_variable'),
      onclick: () => {
        this.entityManager.build(VARIABLES)
      },
    })
    editor.addButton('math_form', {
      icon: 'custom fab fa-lastfm',
      classes: 'variable-btn ',
      tooltip: t('formula'),
      onclick: () => {
        this.entityManager.build(CALCULATE)
      },
    })
    editor.addButton('fix_list', {
      icon: 'custom fad fa-list-alt',
      classes: 'variable-btn ',
      tooltip: t('fix_list'),
      onclick: () => {
        this.entityManager.build(FIX_LIST)
      },
    })
    editor.addButton('extended_list', {
      icon: 'custom fad fa-list-ul',
      classes: 'variable-btn ',
      tooltip: t('open_list'),
      onclick: () => {
        this.entityManager.build(EXTENSIBLE_LIST)
      },
    })
    // ===========================
    editor.addButton('conclusions', {
      icon: 'custom fad fa-user-md',
      classes: 'protocol-entities ',
      tooltip: t('conclusions'),
      onclick: () => {
        this.entityManager.build(CONCLUSIONS)
      },
    })
    editor.addButton('complaints', {
      icon: 'custom fad fa-user-minus',
      classes: 'protocol-entities ',
      tooltip: t('complaints'),
      onclick: () => {
        this.entityManager.build(COMPLAINTS)
      },
    })
    editor.addButton('recommendations', {
      icon: 'custom fad fa-user-edit',
      classes: 'protocol-entities ',
      tooltip: t('assigneds'),
      onclick: () => {
        this.entityManager.build(DESTINATIONS)
      },
    })
    editor.addButton('diagnoses', {
      icon: 'custom fad fa-users',
      classes: 'protocol-entities ',
      tooltip: t('diagnoses'),
      onclick: () => {
        this.buildCategory()
      },
    })

    //=========================
    editor.addButton('headtype', {
      icon: 'custom fad fa-eye',
      tooltip: t('show_head_types'),
      onclick: this._showHeadTypes.bind(this),
    })
    editor.addButton('delete2', {
      icon: 'custom fad fa-trash',
      tooltip: t('delete_selection'),
      onclick: this.deleteContent.bind(this),
      onPostRender () {
        const ctrl = this
        editor.on('NodeChange', function (e) {
          const sel = tinymce.activeEditor.selection
          const content = sel.getContent()
          ctrl.disabled(content.length === 0)
        })
      },
    })
    editor.addButton('testform', {
      text: t('test_template_button_text'),
      tooltip: t('test_template'),
      onclick: this._testForm.bind(this),
    })
    editor.addButton('insert_template_record', {
      icon: 'custom fad fa-upload',
      tooltip: t('load_in_template_catalogs'),
      onclick: this.loadTemplateRecordsProtokol.bind(this),
    })
    editor.addButton('info', {
      icon: 'custom fad fa-question-circle',
      tooltip: t('help'),
      onclick: this._showInfo.bind(this),
    })

    editor.addButton(...toolbar3Params)
  }

  _showInfo () {
    $('#form-info').find('.modal-footer').hide()
    $('#form-info').megamodal('show')
  }

  _testForm () {
    this.saveDataForTestApp()
    applicationFactory.generate('test')
  }

  saveDataForTestApp () {
    const data = this.container.get('store').getState()
    const formHtmlClean = tinymce.activeEditor
      .getContent({ format: 'raw' })
      .replace(/(<p>\s*<\/p>){3}/g, '<br>')
      .replace(/<p>\s*<\/p>/g, '')

    localStorage.setItem('formData', JSON.stringify(data))
    localStorage.setItem('formHtml', formHtmlClean)
  }

  _showHeadTypes (showHeadTypes) {
    if (showHeadTypes !== false) showHeadTypes = this.showHeadTypes
    if (!tinymce.activeEditor) return
    this.entityManager.setEntitiesHead(showHeadTypes)
    this.showHeadTypes = !this.showHeadTypes
  }

  bindEditorEvents (editor) {
    super.bindEditorEvents(editor)
    editor.on('click', this._handleClick.bind(this))
  }

  /**
   * @private
   */

  lazyLoad (cb, item) {
    const promise = new Promise((resolve, reject) => {
      $.ajax({
        url: `/template_records/${item.id}`,
        success (data) {
          resolve(data)
        },
      })
    })
    promise
      .then(cb)
      .catch((error) => {
        $(this.modalBody).loadSpin('stop')
        alert(t('errors.error_has_occurred'))
        console.error(error)
      })
  }

  loadTemplateRecordsProtokol () {
    $('#template_record-list-modal').megamodal('show')
    $('#template_record-list-modal').find('.modal-footer').remove()

    const categoryContainer = $('#category_list')
    categoryContainer.categoriesx({
      url: '/categories/template_records',
      editable: false,
      groupEdit: false,
      onClick: (item) => {
        bootbox.dialog({
          message: t('select_actions'),
          buttons: {
            rewrite: {
              label: t('rewrite'),
              className: 'btn-primary',
              callback: () => {
                this.lazyLoad((item) => {
                  this.loadData(item.template_data)
                  this.loadHtml(item.template_html)
                  this.repairVariables()
                }, item)
                $('#template_record-list-modal').megamodal('hide')
              },
            },
            add: {
              label: t('add'),
              className: 'btn-primary',
              callback: () => {
                this.lazyLoad((item) => {
                  this._mergeData(item)
                }, item)
              },
            },
            cancel: {
              label: t('cancel'),
              className: 'btn-primary',
            },
          },
          callback (result) {
            console.info('This was logged in the callback: ' + result)
          },
        })
      },
    })
  }

  _mergeData (item) {
    this.entityManager.merge(item)
  }

  _handleClick (editor) {
    this.dispatcher.dispatch(new ShowPanelEvent(editor.target))
  }
}
