import Entity from './Entity'
import { formatTree } from '@/plugins/dynamic_forms/_core/libs/tree'

class EntryEntity extends Entity {
  edit () {
    const modal = this.container.get('entities_modal')
    this.container.get('api').protocol_entities(this.type)
      .then((data) => formatTree(data, { selected: this.data.categories }))
      .then((tree) => {
        modal.mount(tree, this)
      })
      .then(() => modal.show())
  }

  elementHead (showTypes) {
    let head = false
    if (showTypes) {
      head = this.data.defaultValue || this.data.title
    } else {
      head = this.data.name
    }
    this.element.innerHTML = head
  }

  get saveCollbak () { }

  // eslint-disable-next-line
  get isManualInput () {
    return true
  }

  afterCreate () {
    this.edit()
  }
}
export default EntryEntity
