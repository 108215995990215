import { MCategoriesPresenter } from '@/_api/MCategories/MCategoriesPresenter'
import { camelToSnake } from '@/_api/_requests/helpers'
import { GLOBAL_CATEGORY_KINDS } from '@/_global_scripts/GLOBAL_CONSTS'

export class ProtocolsCategoriesPresenter extends MCategoriesPresenter {
  constructor ({ useItems = true } = { useItems: true }) {
    super()
    this.location = 'ProtocolsCategoriesPresenter'
    this.useItems = useItems
  }

  fetchNode (id) {
    return this.post({
      url: Routes.template_records_categories_path(),
      data: camelToSnake({
        id,
        categoryType: GLOBAL_CATEGORY_KINDS.TEMPLATE_RECORDS,
        useItems: this.useItems,
      }),
    }).promise
  }

  submit (data, config = {}) {
    return super.submit({
      category: {
        ...data,
        categoryType: GLOBAL_CATEGORY_KINDS.TEMPLATE_RECORDS,
      },
    }, config)
  }
}
