<template>
  <div class="clinical-guidelines-catalog-editor-item">
    <!-- Наименование -->
    <m-input
      :value="clinicalGuideline.title"
      :label="t('title')"
      :disabled="!$security.canManageClinicalGuideline"
      required
      validator-name="title"
      @registerValidator="$registerValidator($event)"
      @input="clinicalGuideline.setValue('title', $event)"
    />

    <m-prompt-notice
      class="mb-indent-validation"
      :text="t$('catalogPromptNotice')"
      type="alert"
    />

    <div class="clinical-guidelines-catalog__line-container">
      <!-- Категория -->
      <m-select-lazy
        :value="clinicalGuideline.category"
        :label="t('category')"
        :disabled="!$security.canManageClinicalGuideline"
        :fetch-method="() => clinicalGuideline.fetchCategories()"
        use-first-time-fetch
        filterable
        @change="clinicalGuideline.setCategory($event, true)"
      />

      <!-- Статус -->
      <m-select
        :value="clinicalGuideline.releaseStatus"
        :label="t('status')"
        :items="CLINICAL_GUIDELINE_RELEASE_STATUS_ITEMS"
        :disabled="!$security.canManageClinicalGuideline"
        required
        validator-name="releaseStatus"
        @registerValidator="$registerValidator($event)"
        @change="clinicalGuideline.setValue('releaseStatus', $event)"
      />
    </div>

    <!-- Ссылка на рубрикатор МинЗдрава -->
    <m-input
      :value="clinicalGuideline.ministryUrl"
      :label="t$('ministryUrl')"
      :disabled="!$security.canManageClinicalGuideline"
      :required="urlValidator"
      validator-name="ministryUrl"
      @registerValidator="$registerValidator($event)"
      @input="clinicalGuideline.setValue('ministryUrl', $event)"
    >
      <template
        v-if="$utils.isValidUrl(clinicalGuideline.ministryUrl)"
        #append
      >
        <m-icon
          v-tooltip="t$('ministryUrlTooltip')"
          class="pointer"
          icon="link"
          color="primary"
          @click="$utils.openInNewTab(clinicalGuideline.ministryUrl)"
        />
      </template>
    </m-input>

    <div class="clinical-guidelines-catalog__line-container">
      <!-- ID МинЗдрава -->
      <m-input
        :value="clinicalGuideline.ministryId"
        :label="t$('ministryId')"
        :disabled="!$security.canManageClinicalGuideline"
        required
        validator-name="ministryId"
        @registerValidator="$registerValidator($event)"
        @input="clinicalGuideline.setValue('ministryId', $event)"
      />

      <!-- Год выхода -->
      <m-select
        :value="clinicalGuideline.releaseYear"
        :items="CLINICAL_GUIDELINE_RELEASE_YEAR_ITEMS"
        :label="t$('releaseYear')"
        :disabled="!$security.canManageClinicalGuideline"
        required
        validator-name="releaseYear"
        @registerValidator="$registerValidator($event)"
        @change="clinicalGuideline.setValue('releaseYear', $event)"
      />
    </div>

    <div class="clinical-guidelines-catalog__line-container">
      <reusable-m-k-b10
        :value="clinicalGuideline.diseases"
        option-label="codeString"
        option-notice="title"
        :disabled="!$security.canManageClinicalGuideline"
        multiple
        required
        validator-name="diseases"
        @registerValidator="$registerValidator($event)"
        @changeByLazy="onDiseasesChange($event)"
      />

      <!-- Возраст -->
      <m-select
        :label="t('age')"
        :value="clinicalGuideline.age"
        :items="CLINICAL_GUIDELINE_AGE_ITEMS"
        :disabled="!$security.canManageClinicalGuideline"
        required
        validator-name="age"
        @registerValidator="$registerValidator($event)"
        @change="clinicalGuideline.setValue('age', $event)"
      />
    </div>

    <!-- Анамнез -->
    <m-textarea
      :value="clinicalGuideline.anamnesis"
      :label="t$('anamnesis')"
      :disabled="!$security.canManageClinicalGuideline"
      required
      validator-name="anamnesis"
      @registerValidator="$registerValidator($event)"
      @input="clinicalGuideline.setValue('anamnesis', $event)"
    />

    <div class="mb-indent">
      <!-- Создано -->
      <m-prompt-notice
        v-if="!isNewMode"
        class="mb-indent-small"
        :text="createdByText"
      />

      <!-- Изменено -->
      <m-prompt-notice
        v-if="!isNewMode && clinicalGuideline.updatedAt"
        class="mb-0"
        :text="updatedByText"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { i18nScopeMixin } from '@/vue_present/mixins/i18nScopeMixin'
import {
  ClinicalGuidelineApi,
} from '@/vue_apps/catalogs_root/ClinicalGuidelinesCatalog/classes/ClinicalGuideline/ClinicalGuidelineApi'
import MInput from '@/vue_present/_base/inputs/MInput/MInput.vue'
import MSelect from '@/vue_present/_base/inputs/MSelect/MSelect.vue'
import {
  CLINICAL_GUIDELINE_RELEASE_STATUS_ITEMS,
} from '@/vue_apps/catalogs_root/ClinicalGuidelinesCatalog/consts/clinicalGuidelineReleaseStatus'
import MIcon from '@/vue_present/_base/MIcon/MIcon.vue'
import {
  CLINICAL_GUIDELINE_RELEASE_YEAR_ITEMS,
} from '@/vue_apps/catalogs_root/ClinicalGuidelinesCatalog/consts/clinicalGuidelineReleaseYear'
import { urlValidator } from '@/lib/validators/urlValidator'
import ReusableMKB10 from '@/vue_present/Reuse/Lists/MKB10/ReusableMKB10.vue'
import {
  CLINICAL_GUIDELINE_AGE_ITEMS,
} from '@/vue_apps/catalogs_root/ClinicalGuidelinesCatalog/consts/clinicalGuidelineAge'
import MTextarea from '@/vue_present/_base/inputs/MTextarea/MTextarea.vue'
import MPromptNotice from '@/vue_present/_base/MPromptNotice/MPromptNotice.vue'
import { RouterModeMixin } from '@/vue_present/mixins/RouterModeMixin'
import MSelectLazy from '@/vue_present/_base/MSelectLazy/MSelectLazy.vue'
import {
  IClinicalGuidelineDisease,
} from '@/vue_apps/catalogs_root/ClinicalGuidelinesCatalog/interfaces/IClinicalGuidelineDisease'

export default defineComponent({
  name: 'ClinicalGuidelinesCatalogEditorItem',

  components: {
    MSelectLazy,
    MPromptNotice,
    MTextarea,
    ReusableMKB10,
    MIcon,
    MSelect,
    MInput,
  },

  mixins: [
    i18nScopeMixin,
    RouterModeMixin,
  ],

  props: {
    clinicalGuideline: { type: ClinicalGuidelineApi, required: true },
  },

  data () {
    return {
      CLINICAL_GUIDELINE_RELEASE_STATUS_ITEMS,
      CLINICAL_GUIDELINE_RELEASE_YEAR_ITEMS,
      CLINICAL_GUIDELINE_AGE_ITEMS,
    }
  },

  computed: {
    createdByText () {
      return this.noticeText('createdBy', 'createdAt')
    },

    updatedByText () {
      return this.noticeText('updatedBy', 'updatedAt')
    },
  },

  created () {
    this.setI18nScope('clinicalGuidelines.catalog')
  },

  methods: {
    urlValidator,

    noticeText (propBy: 'createdBy' | 'updatedBy', propAt: 'createdAt' | 'updatedAt') {
      return this.t$(propBy, {
        user: this.clinicalGuideline[propBy]
          ? this.$filters.shortName(this.clinicalGuideline[propBy])
          : t('userUnspecified').toLowerCase(),
        date: this.$filters.date(this.clinicalGuideline[propAt]),
      })
    },

    onDiseasesChange (value: IClinicalGuidelineDisease[]) {
      const prevFirstDiseaseCodeString = this.clinicalGuideline.diseases.at(0)?.codeString
      this.clinicalGuideline.setValue('diseases', value)

      if (this.clinicalGuideline.category && (this.isEditMode || this.clinicalGuideline.categoryManual)) { return }
      if (!value.length) { return this.clinicalGuideline.setCategory(null, false) }

      const newFirstDiseaseCodeString = value.at(0).codeString

      if (this.clinicalGuideline.category && prevFirstDiseaseCodeString === newFirstDiseaseCodeString) { return }
      this.clinicalGuideline.fetchCategoryByCodeString(newFirstDiseaseCodeString)
    },
  },
})
</script>
