import { EntityWithDefaultHtml } from '@/plugins/dynamic_forms/entities/items/EntityWithDefaultHtml'
import { ICatalog } from '@/_declarations/ICatalog'

class List extends EntityWithDefaultHtml {
  edit () {
    this.container.get('list').show(this)
  }

  elementHead (showTypes) {
    const data = this.data as Record<string, any>

    this.element.innerHTML = showTypes
      ? data.defaulValue || data.title
      : data.name
  }

  /**
   * inherit
   * redefine parent method
   */
  get value () {
    const data = this.data as Record<string, any>
    const value = data.defaultValue || List.defaultHtml

    return this.formatValue(value)
  }

  /**
   * @inheritdoc
   */
  get isManualInput () {
    return this.type === 'ext_list'
  }

  setValue (newValue: string | ICatalog) {
    const value = typeof newValue === 'string'
      ? newValue
      : newValue?.title

    this.element.innerText = value || ''
    this.__updateStore(this.element.innerText)
  }
}
export default List
