import { ItemBase } from '@/_api/_classes/ItemBase'
import { WorkplaceUsersListItem } from '@/vue_apps/catalogs_root/Workplaces/classes/WorkplaceUsersListItem'
import { IWorkplace } from '@/vue_apps/catalogs_root/Workplaces/interfaces/IWorkplace'
import { IWorkplaceKKMInfo } from '@/vue_apps/catalogs_root/Workplaces/interfaces/IWorkplaceKKMInfo'
import { IUser } from '@/_declarations/IUser'

export class WorkplaceModel extends ItemBase {
  _permit = [
    'id',
    'title',
    'key',
    'kkm',
    'useCurrentUser',
    'cashier',
  ]

  id: number

  title: string

  originalTitle: string

  key: string

  kkm: IWorkplaceKKMInfo

  useCurrentUser: boolean = true

  cashier: IUser = null

  users: Record<string, WorkplaceUsersListItem> = {}

  constructor (props?: IWorkplace) {
    super()

    if (!props) { return }

    this.fillProps(props)
  }

  getAttributes (attributes: string[] = this._permit): Record<string, unknown> {
    return {
      ...super.getAttributes(attributes),
      ...this.getUsersAttributes(),
    }
  }

  getUsersAttributes () {
    return {
      users: Object.values(this.users).map((user) => user.getValues()),
    }
  }

  fillProps (props: IWorkplace) {
    this._fillProps(props)
    this.originalTitle = props.title
    if (!props.users) { return }
    this.users = props.users.reduce((acc, user) => ({
      ...acc,
      [user.id]: new WorkplaceUsersListItem(user),
    }), {})
  }
}
