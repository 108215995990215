import { MSiSchema } from '@/vue_present/_base/Tables/MSiGenerator/MSiSchema'
import { MTableHeader } from '@/vue_present/_base/Tables/MTableGenerator/MTableHeader'
import { MTableSchema } from '@/vue_present/_base/Tables/MTableGenerator/MTableSchema'

export const getWorkplacesCatalogMsiSchema = () => new MSiSchema()
  .addPagination()
  .addTableSchema(
    new MTableSchema({
      headers: {
        icon: new MTableHeader(),
        title: new MTableHeader(),
      },
    })
  )
  .addCreateButton()

export const getWorkplacesCatalogMsiSchemaButtonConfig = () => ({
  icon: 'rectangleLandscape',
  tooltip: t('add_workplace'),
  canManage: 'Workplace',
})

export const getWorkplacesCatalogEmployersMsiSchema = () => new MSiSchema()
  .addPagination()
  .addTableSchema(
    new MTableSchema({
      headers: {
        avatar: new MTableHeader().addFixedWidth('50px'),
        fullName: new MTableHeader(),
        specialties: new MTableHeader(),
        status: new MTableHeader().addFixedWidth('100px'),
        validationErrorsIcon: new MTableHeader().addFixedWidth('40px'),
      },
    })
      .addDeleteIcon()
  )
