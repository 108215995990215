<template>
  <div class="protocol-entity-view">
    <textarea
      ref="textareaRef"
      v-model="valueProxy"
      class="protocol-entity-view__textarea"
      @click="updateCaretPos"
      @keydown="updateCaretPos"
      @change="updateCaretPos"
    />

    <el-tree
      class="protocol-entity-view__tree"
      style="overflow-y: scroll"
      :data="treeData"
      :props="treeDefaultProps"
      :default-expanded-keys="defaultExpandedKeys"
      :render-content="treeNodeRenderFunction"
      node-key="id"
      @node-click="update"
    />

    <m-checkbox
      v-model="verticalMode"
      class="protocol-entity-view__vertical-mode el-checkbox_big"
      :label="t('vertical_mode')"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import Entity from '@/plugins/dynamic_forms/entities/items/Entity'
import {
  createTreeFromArray,
  createTreeFromNestedArray,
  isContentEqualsToWindowSelection,
  treeNodeRenderFunction,
  updateContent,
} from '@/plugins/dynamic_forms/components/context_menu/editor_mode/ui/TextareaProxyRoot/logic'
import MCheckbox from '@/vue_present/_base/inputs/MCheckbox/MCheckbox.vue'
import Api from '@/plugins/dynamic_forms/api/Api'
import { EXTENSIBLE_LIST } from '@/plugins/dynamic_forms/configuration/reducer_types'

export default defineComponent({
  name: 'ProtocolEntityView',

  components: { MCheckbox },

  props: {
    entity: { type: Object as PropType<Entity>, required: true },
    value: { type: [Object, String], default: '' },
  },

  data () {
    return {
      caretPos: 0,
      verticalMode: false,
      treeData: [],
      treeDefaultProps: {
        children: 'children',
        label: 'title',
      },

      treeNodeRenderFunction,
    }
  },

  computed: {
    spaceSeparator () {
      return this.verticalMode ? '\n' : ' '
    },

    defaultExpandedKeys () {
      return this.treeData?.length
        ? [this.treeData[0].id]
        : []
    },

    valueProxy: {
      get () { return this.value },
      set (value) { this.$updateSync('value', value) },
    },
  },

  created () {
    const text = (this.entity?.element?.innerText || '').trim() || ''
    this.valueProxy = text
    this.caretPos = text.length
  },

  mounted () {
    this.fetchData()
    const lastRange = application.form_builder?.frontend?.globalMap?.lastRange
    if (!lastRange) { return }
    this.setDefaultSelectedRange(lastRange)
  },

  methods: {
    async fetchData () {
      const api: Api = this.entity.container.get('api')
      this.treeData = this.entity.type === EXTENSIBLE_LIST
        ? createTreeFromArray(await api.extensibleList(this.entity.id))
        : createTreeFromNestedArray(await api.protocol_entities(this.entity.type))
    },

    setDefaultSelectedRange ({ start, end }) {
      const hasSelection = start && end // getRangeAt() или что-то подобное вернёт весь тег без start и end
      const hasContent = this.valueProxy.trim?.() || ''

      // выделено всё
      if (!hasSelection && hasContent && isContentEqualsToWindowSelection(this.valueProxy)) {
        return this.$refs.textareaRef.select()
      }

      // выделена часть
      this.$refs.textareaRef.focus()
      this.$nextTick(() => {
        this.$refs.textareaRef.setSelectionRange(start, end)
      })
    },

    updateCaretPos ($event) {
      this.caretPos = $event.target.selectionStart ?? this.valueProxy.length
    },

    update ({ title: value, folder }) {
      if (folder) { return }

      const { selectionStart, selectionEnd } = this.$refs.textareaRef

      this.valueProxy = updateContent(
        this.valueProxy,
        value,
        this.caretPos,
        this.spaceSeparator,
        { selectionStart, selectionEnd }
      )

      this.caretPos = selectionStart !== selectionEnd
        ? selectionStart + value.length // +1 нет, т.к. дополнительный символ уже заложен
        : this.caretPos + value.length + 1
    },
  },
})
</script>
