import { ICRUDConfig } from '@/_api/decorators/api/interfaces/ICRUDConfig'
import { get, omit } from 'lodash'
import { camelToSnake, snakeToCamel } from '@/_api/_requests/helpers'
import { request } from '@/lib/transport/request'
import { objectToFormData } from '@/helpers/objectToFormData'
import { ICRUDBaseConfig } from '@/_api/decorators/api/interfaces/ICRUDBaseConfig'
import { wrapAPIError } from '@/_api/decorators/api/helpers/wrapAPIError'
import { MEDIA_TYPES } from '@/helpers/mediaTypes'

export const crudBase = <Context, Return>({
  context,
  httpMethod,
  routeOrAttribute,
  routeArgs,
  payload = {},
  config,
}: ICRUDBaseConfig<Context>): Return => {
  const options = omit(config, [
    'toClientAdapter',
    'toJson',
    'toFormData',
  ])

  const toClientAdapter = config?.toClientAdapter ?? snakeToCamel

  const route = typeof routeOrAttribute === 'string'
    ? () => get(context, routeOrAttribute)
    : routeOrAttribute

  const params = {
    type: httpMethod,

    url: routeArgs
      ? route(...routeArgs)
      : route(),

    data: getFormattedPayload(payload, config),
    ...getExtraOptions(config),
    ...options,
  }

  const resultType = config.resultType || 'promise'
  const responce = request(params, toClientAdapter)[resultType]
  const result = resultType === 'promise'
    ? wrapAPIError(responce as Promise<unknown>)
    : responce

  return result as Return
}

const getFormattedPayload = (payload: object, config: ICRUDConfig) => {
  if (config.toFormData) {
    const newPayload = config.formDataSnakify === false
      ? payload
      : camelToSnake(payload)

    return objectToFormData(newPayload)
  }

  if (config.toJson) {
    return JSON.stringify(camelToSnake(payload))
  }

  return camelToSnake(payload)
}

const getExtraOptions = (config: ICRUDConfig) => {
  if (config.toFormData) {
    return {
      contentType: false,
      processData: false,
    }
  }

  if (config.toJson) {
    return {
      contentType: MEDIA_TYPES.JSON,
      dataType: 'json',
    }
  }

  return {}
}
