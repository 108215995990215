export enum EAddProtocolSources {
  byScratch = 'byScratch',
  byTemplate = 'byTemplate',
  byPrevProtocol = 'byPrevProtocol',
  byDraft = 'byDraft',
}

export interface IExtraKeys {
  protocolDraft: boolean
}

export const getAddProtocolSources = (extraKeys: IExtraKeys = {} as IExtraKeys) => {
  const list = [
    {
      id: EAddProtocolSources.byScratch,
      title: t(EAddProtocolSources.byScratch, { scope: 'protocols' }),
      icon: 'plus',
      warn: false,
    },
    {
      id: EAddProtocolSources.byPrevProtocol,
      title: t(EAddProtocolSources.byPrevProtocol, { scope: 'protocols' }),
      icon: 'file',
      warn: false,
    },
  ]

  if (extraKeys.protocolDraft) {
    list.push({
      id: EAddProtocolSources.byDraft,
      title: t(EAddProtocolSources.byDraft, { scope: 'protocols' }),
      icon: 'history',
      warn: true,
    })
  }

  return list
}
