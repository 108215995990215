export const CommonInputProps = {
  props: {
    id: { type: String, required: false },

    label: { type: String, default: null },
    clearable: { type: Boolean, default: true },
    mFixedHeight: { type: Boolean, default: true },
    placeholder: { type: String, default: null },
    requiredTooltip: { type: [String, Object], default: null },
    fullWidth: Boolean,
  },
}
