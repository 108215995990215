import { PrintRawReceiptModel } from '@/vue_apps/catalogs_root/Workplaces/classes/PrintRawPeceipt/PrintRawReceiptModel'

export class PrintRawReceiptLogic extends PrintRawReceiptModel {
  clear () {
    super.setValue('kind', null)
    this.entries.purge()
    this.payments.purge()
  }

  getSumOfItems (items: object[], sumPropName: string) {
    const sum = items.reduce(
      (sum: number, item) => sum + (+item[sumPropName] || 0),
      0
    )

    return Utils.toMoney(sum)
  }

  get entriesSum () {
    return this.getSumOfItems(this.entries.getArr(), 'sum')
  }

  get paymentsSum () {
    return this.getSumOfItems(this.payments.getArr(), 'sum')
  }

  validate () {
    if (!this.entries.getArr().length) {
      Notificator.error(t('fiscal.error.entriesShouldBePresent'))

      return false
    }

    if (!this.payments.getArr().length) {
      Notificator.error(t('fiscal.error.paymentsShouldBePresent'))

      return false
    }

    if (this.entriesSum !== this.paymentsSum) {
      Notificator.error(t('fiscal.error.sumMatchError'))

      return false
    }

    return true
  }
}
