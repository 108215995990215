import { AddProtocolFabric, TProtocolQueryParams } from '@/vue_apps/ProtocolsApp/entities/AddProtocolFabric'

interface IOnNewParams {
  query: TProtocolQueryParams
  clientId: number
}

export const onNew = async (params: IOnNewParams) => {
  const protocol = await AddProtocolFabric.Get(params.query)
  protocol.setValue('clientId', params.clientId)

  return protocol
}
