<template>
  <m-button
    template="close"
    :disabled="disabled"
    @click="$emit('close')"
  />
</template>

<script>
import MButton from '@/vue_present/_base/buttons/MButton/MButton.vue'
import { DisabledMixin } from '@/vue_present/mixins/DisabledMixin'

export default {
  name: 'CloseButton',

  components: { MButton },

  mixins: [DisabledMixin],
}
</script>
