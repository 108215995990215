import { ItemBase } from '@/_api/_classes/ItemBase'
import { orNull } from '@/_declarations/orNull'
import { ICatalog } from '@/_declarations/ICatalog'
import { TDateString } from '@/_declarations/TDateString'
import { TUserFullName } from '@/_declarations/IUser'
import {
  CLINICAL_GUIDELINE_RELEASE_STATUS,
} from '@/vue_apps/catalogs_root/ClinicalGuidelinesCatalog/consts/clinicalGuidelineReleaseStatus'
import { CLINICAL_GUIDELINE_AGE } from '@/vue_apps/catalogs_root/ClinicalGuidelinesCatalog/consts/clinicalGuidelineAge'
import { NestedItemsItem } from '@/_api/_classes/NestedItems/classes/NestedItemsItem'
import {
  ClinicalGuidelineEntryItem,
} from '@/vue_apps/catalogs_root/ClinicalGuidelinesCatalog/classes/ClinicalGuidelineEntryItem/ClinicalGuidelineEntryItem'
import { TClinicalGuidelineEntryProps } from '@/vue_apps/catalogs_root/ClinicalGuidelinesCatalog/interfaces/types'
import {
  IClinicalGuidelineDisease,
} from '@/vue_apps/catalogs_root/ClinicalGuidelinesCatalog/interfaces/IClinicalGuidelineDisease'

export class ClinicalGuidelineModel extends ItemBase {
  _permit = [
    'id',
    'title',
    'releaseStatus',
    'releaseYear',
    'ministryId',
    'age',
    'ministryUrl',
    'anamnesis',
    'category',
    'createdAt',
    'updatedAt',
    'createdBy',
    'updatedBy',
    'diseases',
    'clinicalGuidelineEntries',
  ]

  id: orNull<number> = null

  title: string

  releaseStatus: ICatalog<CLINICAL_GUIDELINE_RELEASE_STATUS>

  releaseYear: ICatalog<number>

  ministryId: string

  age: ICatalog<CLINICAL_GUIDELINE_AGE>

  ministryUrl: string

  anamnesis: string

  category: orNull<ICatalog<number>> = null

  categoryManual: boolean = false

  originCategory: ICatalog<number>

  createdAt: orNull<TDateString> = null

  updatedAt: orNull<TDateString> = null

  createdBy: orNull<TUserFullName> = null

  updatedBy: orNull<TUserFullName> = null

  diseases: IClinicalGuidelineDisease[] = []

  clinicalGuidelineEntries = new NestedItemsItem<
    ClinicalGuidelineEntryItem,
    TClinicalGuidelineEntryProps
  >(ClinicalGuidelineEntryItem)
}
