<template>
  <el-popover
    v-model="popoverVisibility"
    v-bind="$attrs"
    :popper-class="`m-popover ${popperClass}`"
    :placement="placement"
    :disabled="disabled"
    :width="popoverWidth"
    v-on="$listeners"
  >
    <slot name="all">
      <div class="m-popover__title">
        <slot name="title" />
      </div>

      <div class="m-popover__message flex align-items-center">
        <slot name="message">
          <m-icon
            :icon="yesIcon"
            color="danger"
          />
          {{ message }}
        </slot>
      </div>

      <slot name="body" />

      <div class="m-popover__footer mt-indent-mid">
        <div class="m-popover__footer_left">
          <slot name="footer-left" />
        </div>
        <div class="m-popover__footer_right">
          <slot name="footer-right">
            <div
              class="m-popover__buttons flex gap-indent-small"
              :class="{ 'flex-row-reverse': reverseButtonsOrder }"
            >
              <slot name="buttons">
                <m-button
                  :type="cancelType"
                  :disabled="disableCancelBtn"
                  @click="handleClick('no')"
                >
                  {{ cancelText }}
                </m-button>
                <m-button
                  :type="yesType"
                  :disabled="disableConfirmBtn"
                  @click="handleClick('yes')"
                >
                  {{ confirmText }}
                </m-button>
              </slot>
            </div>
          </slot>
        </div>
      </div>

      <slot />
    </slot>

    <template #reference>
      <slot name="reference" />
    </template>
  </el-popover>
</template>

<script>
import MButton from '@/vue_present/_base/buttons/MButton/MButton.vue'
import MIcon from '@/vue_present/_base/MIcon/MIcon.vue'

export default {
  name: 'MPopover',

  components: {
    MIcon,
    MButton,
  },

  props: {
    disableCancelBtn: Boolean,
    disableConfirmBtn: Boolean,
    disabled: Boolean,
    inheritedVisibility: Boolean,
    reverseButtonsOrder: Boolean,
    visibility: Boolean,

    cancelText: { type: String, default: t('no') },
    cancelType: { type: String, default: 'default' },
    confirmText: { type: String, default: t('yes') },
    message: { type: String, default: t('delete_confirmation') },
    placement: { type: String, default: 'top-start' },
    popoverWidth: { type: String, default: undefined },
    popperClass: { type: String, default: '' },
    yesIcon: { type: String, default: 'questionCircle' },
    yesType: { type: String, default: 'danger' },
  },

  data () {
    return {
      isVisible: false,
    }
  },

  computed: {
    popoverVisibility: {
      get () {
        return this.inheritedVisibility
          ? this.visibility
          : this.isVisible
      },

      set (value) {
        this.inheritedVisibility
          ? this.$updateSync('visibility', value)
          : this.isVisible = value
      },
    },
  },

  methods: {
    handleClick (actionName) {
      this.$emit(actionName)
      this.isVisible = false
    },

    closePopover () {
      this.popoverVisibility = false
    },
  },
}
</script>
