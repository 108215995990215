import { defaultObjectsSorting } from '@/helpers/lambda'

export const SEMD_TYPE = Object.freeze({
  CONSULTATION: 'consultation_protocol',
  ELMK_MEDICAL_ASSESSMENT: 'elmk_medical_assessment',
  INSTRUMENTAL_RESEARCH_PROTOCOL: 'instrumental_research_protocol',
})

export const SEMD_TYPE_ENUM = Object.freeze({
  [SEMD_TYPE.CONSULTATION]: 1,
  [SEMD_TYPE.ELMK_MEDICAL_ASSESSMENT]: 2,
  [SEMD_TYPE.INSTRUMENTAL_RESEARCH_PROTOCOL]: 3,
})

export const SEMD_TYPE_INVERTED_ENUM = Object.freeze({
  1: SEMD_TYPE.CONSULTATION,
  2: SEMD_TYPE.ELMK_MEDICAL_ASSESSMENT,
  3: SEMD_TYPE.INSTRUMENTAL_RESEARCH_PROTOCOL,
})

const SEMD_TYPES_LIST = Object.freeze(
  Object.entries(SEMD_TYPE)
    .map(([key, value]) =>
      ({ id: SEMD_TYPE[key], title: t(value, { scope: 'semds.semdType' }) }))
    .sort(defaultObjectsSorting() as any)
)

export const getSemdTypesStringList = () => {
  return SEMD_TYPES_LIST
}
