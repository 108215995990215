import { Api } from '@/_api/decorators/api/Api'
import { ICRUDReturn } from '@/_api/decorators/api/interfaces/ICRUDReturn'
import { diseaseAdapter } from '@/_api/catalogs/Disease/diseaseAdapter'
import { IApiErrors } from '@/_declarations/IApiErrors'

export class ClinicalGuidelineTreeApi {
  @Api.notify('fetchMessage', 'clinicalGuideline', { success: false })
  @Api.clientAdapter(diseaseAdapter)
  @Api.post(Routes.clinical_guidelines_api_internal_categories_path)
  fetchNode (id: number) {
    return {
      payload: { id },
    } as ICRUDReturn as Promise<object | IApiErrors>
  }
}
