import { tScoped } from '@/helpers/i18nHelpers'

const ts = tScoped('finance.operationTypes')

export enum ACQUIRING_OPERATION_TYPE {
  PAY = 'pay',
  REFUND = 'refund',
  CANCEL = 'cancel',
}

export const ACQUIRING_OPERATION_TYPE_TITLES = {
  [ACQUIRING_OPERATION_TYPE.PAY]: ts(ACQUIRING_OPERATION_TYPE.PAY),
  [ACQUIRING_OPERATION_TYPE.REFUND]: ts(ACQUIRING_OPERATION_TYPE.REFUND),
  [ACQUIRING_OPERATION_TYPE.CANCEL]: ts(ACQUIRING_OPERATION_TYPE.CANCEL),
} as const
