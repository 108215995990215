import {
  loadModalPositionFromCache,
  saveModalPositionToCache,
} from '@/plugins/dynamic_forms/components/context_menu/editor_mode/ui/TextareaProxyRoot/cache'

const MIN_START_MARGIN = 0
const MOUSE_LEFT_BUTTON = 0

export const DRAGGABLE_AREA = 'm-popover__title'
export const draggablePopover = (popover, key = undefined) => {
  let windowProperties = loadModalPositionFromCache(key)
  let isDown = false

  /** @type {HTMLElement} realPopover*/
  const realPopover = popover.querySelector('.m-popover')

  popover.addEventListener('mousedown', function (e) {
    if (e.button !== MOUSE_LEFT_BUTTON) { return }

    const id = e.target.id
    const nodeIsDraggable = e.target.dataset.id === DRAGGABLE_AREA
    const parentNodeIsDraggable = e.target.parentNode.dataset.id === DRAGGABLE_AREA
    if (id !== DRAGGABLE_AREA && !nodeIsDraggable && !parentNodeIsDraggable) { return }

    isDown = true
    windowProperties = {
      left: popover.offsetLeft - e.clientX,
      top: popover.offsetTop - e.clientY,
      width: realPopover.clientWidth,
      height: realPopover.clientHeight,
    }
  }, true)

  popover.addEventListener('mouseup', function () {
    isDown = false

    if (realPopover.style.display === 'none') { return }

    windowProperties = {
      left: parseInt(popover.style.left),
      top: parseInt(popover.style.top),
      width: realPopover.clientWidth,
      height: realPopover.clientHeight,
    }

    saveModalPositionToCache(windowProperties)
  }, true)

  document.addEventListener('mousemove', function (event) {
    if (!isDown) { return }

    const { clientHeight, clientWidth } = document.documentElement

    const shiftX = Math.max(
      Math.min(
        event.clientX + windowProperties.left,
        clientWidth - windowProperties.width
      ),
      MIN_START_MARGIN
    )
    const shiftY = Math.max(
      Math.min(
        event.clientY + windowProperties.top,
        clientHeight - windowProperties.height
      ),
      MIN_START_MARGIN
    )

    popover.style.left = shiftX + 'px'
    popover.style.top = shiftY + 'px'
  })
}
