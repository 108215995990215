import { MeasureUnitModel } from './MeasureUnitModel'
import { checkMeasureUnitEditable } from '../const/helpers'

export class MeasureUnitLogic extends MeasureUnitModel {
  constructor (data = {}) {
    super()
    this._fillProps(data)
  }

  get isEditable (): boolean {
    return checkMeasureUnitEditable(this)
  }
}
