import { Base } from '@/_api/_classes/Base'
import { cloneDeep, pick } from 'lodash'

export class ItemBase<
    SetValueKeyType extends string | number | symbol = string,
    // @ts-ignore TS2526: A "this" type is available only in a non-static member of a class or interface
    ReturnThisClass = this
> extends Base<SetValueKeyType, ReturnThisClass> {
  protected _permit: string[] = []

  getAttributes (attributes: string[] = this._permit): Record<string, any> {
    return pick(this, attributes)
  }

  protected _fillProps (props = {}) {
    if (!this?._permit) { return }

    this._permit.forEach((param) => {
      if (!Object.hasOwn(props, param)) { return }
      if (typeof props[param] === 'object') {
        this[param] = cloneDeep(props[param])

        return
      }

      this[param] = props[param]
    })
  }
}
