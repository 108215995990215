import { ICheckupClient } from '@/vue_apps/CheckupsModule/interfaces/ICheckupClientSearch'
import { FastCreateClientApi } from '@/vue_apps/CheckupsModule/classes/FastCreateClient/FastCreateClientApi'
import {
  FAST_CREATE_DISABLED_FIELDS,
  FAST_CREATE_NEXT_DATE_FROM_NOW_IN_YEARS,
} from '@/vue_apps/CheckupsModule/store/personalCheckupFastCreate/fastCreateConsts'
import { IFastCreateClient } from '@/vue_apps/CheckupsModule/interfaces/IFastCreateClient'
import { FAST_CREATE_STATUSES } from '@/vue_apps/CheckupsModule/store/personalCheckupFastCreate/fastCreateStatuses'

export class FastCreateClientLogic extends FastCreateClientApi {
  constructor (props?: Partial<IFastCreateClient>) {
    super(props)

    if (!props?.date) this._initDate()
    if (!props?.nextDate) this._initNextDate()
  }

  _initDate () {
    this.date = [
      Utils.getBaseFormattedDate(),
      Utils.getBaseFormattedDate(moment().add('1', 'day')),
    ]
  }

  _initNextDate () {
    const nextDate = moment()
      .year(moment().year() + FAST_CREATE_NEXT_DATE_FROM_NOW_IN_YEARS)

    this.nextDate = Utils.getBaseFormattedDate(nextDate)
  }

  iconStatusClick () {
    switch (this.status) {
      case FAST_CREATE_STATUSES.SEARCH_FOR_MATCHES: return this.searchForMatchesStatus()
      case FAST_CREATE_STATUSES.DATA_FOUND: return this._dataFoundStatus()
      case FAST_CREATE_STATUSES.DUPLICATE_FOUND: return this._duplicateFound()
    }
  }

  async searchForMatchesStatus () {
    this.searchResult = await this.searchClient()
    this.selectedClient = null

    if (this.searchResult.data.length) {
      if (this.noDuplicates) {
        this.status = FAST_CREATE_STATUSES.DATA_FOUND

        this.selectedClient = this.searchResult.data.at(0)

        await this._dataFoundStatus()

        return
      }

      this.status = FAST_CREATE_STATUSES.DUPLICATE_FOUND

      return
    }

    this.status = FAST_CREATE_STATUSES.NO_MATCHES_FOUND
  }

  async _dataFoundStatus () {
    if (this.noDuplicates) {
      this._setClientData()

      await this.tryLoadDataFromLastPersonalCheckup(this.id)
    }

    this.status = FAST_CREATE_STATUSES.DATA_LOADED

    this._setDisabledFields()
  }

  _duplicateFound () {
    if (!this.selectedClient) { return }

    this._dataFoundStatus()
  }

  async _selectedClientHandler (client: ICheckupClient | null) {
    super.setValue('selectedClient', client)

    if (!client) {
      this.status = FAST_CREATE_STATUSES.DUPLICATE_FOUND

      return
    }

    if (!this.noDuplicates) {
      this.snils = null
      this.position = null
      this.nsiProfession = null
      this.factors = null
    }

    this._setClientData()

    await this.tryLoadDataFromLastPersonalCheckup(this.id)

    this.status = FAST_CREATE_STATUSES.DATA_FOUND
  }

  _setClientData () {
    this.id = this.selectedClient.id
    this.patientCardNumber = this.selectedClient.emkNumber
    this.name = this.selectedClient.name
    this.surname = this.selectedClient.surname
    this.secondName = this.selectedClient.secondName
    this.phone = this.selectedClient.phone
    this.position = this.selectedClient.position
    this.nsiProfession = this.selectedClient.nsiProfession
    this.sex = this.selectedClient.sex
    this.snils = this.selectedClient.snils
  }

  _setDisabledFields () {
    this.disabledFields = FAST_CREATE_DISABLED_FIELDS.reduce((acc, field) => {
      acc[field] = !!this[field]

      return acc
    }, {})
  }

  setValue (key: string, value: unknown) {
    switch (key) {
      case 'selectedClient': return this._selectedClientHandler(value as ICheckupClient | null)
    }

    super.setValue(key, value)

    switch (key) {
      case 'name':
      case 'surname':
      case 'birthdate':
        this.status = FAST_CREATE_STATUSES.SEARCH_FOR_MATCHES
        break
    }
  }
}
