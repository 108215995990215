import { MListServiceItem } from '@/_api/_requests/MListServiceItem'

export class MListServiceNestedItemsItem<idType = number | string> extends MListServiceItem<idType> {
  dbId: number | null = null

  _keep: boolean = false

  _destroy: boolean = false

  constructor (id: Exclude<idType, null>, item: unknown) {
    super()

    this.id = id
    this.dbId = (item as any)?.id || null
  }
}
