import { GRPCReceiverAbstract } from '@/_api/_classes/GRPC/Receiver/GRPCReceiverAbstract'

export abstract class GRPCReceiverSendStrategyAbstract<TData, TResult, TConfig = undefined> {
  protected context: GRPCReceiverAbstract

  protected config: TConfig

  abstract send (data: TData): TResult

  constructor (context: GRPCReceiverAbstract, config?: TConfig) {
    this.context = context
    this.config = config
  }
}
