import { orNull } from '@/_declarations/orNull'
import { IgRPCMeta } from '@/_api/_classes/GRPC/interfaces/IgRPCMeta'
import { IWSMessage } from '@/_declarations/IWSMessage'
import { snakeToCamel } from '@/_api/_requests/helpers'
import { GRPC_META_TYPE } from '@/_api/_classes/GRPC/consts/consts'
import { IgRPCReceiverConfig } from '@/_api/_classes/GRPC/interfaces/IgRPCReceiverConfig'
import { TAdapter } from '@/_api/decorators/api/interfaces/IAdapter'
import { IApiError } from '@/_declarations/IApiErrors'
import { GRPCStream } from '@/_api/_classes/GRPC/Receiver/GRPCStream'
import {
  GRPCReceiverSendStrategyAbstract,
} from '@/_api/_classes/GRPC/Receiver/SendStrategy/GRPCReceiverSendStrategyAbstract'
import { GRPCReceiverSendStrategyHTTP } from '@/_api/_classes/GRPC/Receiver/SendStrategy/GRPCReceiverSendStrategyHTTP'

export abstract class GRPCReceiverAbstract<
  Data = object | string,
  BatchData = string,
  FillDataReturn = void,
  SendStrategyData = Record<string, any>,
  SendStrategyResult = Promise<void>
> {
  protected data: Data[] = []

  requestId: orNull<IgRPCMeta['requestId']> = null

  toServer: TAdapter

  toClient: TAdapter

  src: string

  readonly ws: any

  private readonly sendStrategy: GRPCReceiverSendStrategyAbstract<SendStrategyData, SendStrategyResult>

  private streams: Record<IgRPCMeta['requestId'], GRPCStream<BatchData>> = {}

  private _loading: boolean = false

  get loading (): boolean { return this._loading }

  private timeoutId: orNull<NodeJS.Timeout> = null

  private readonly successCallback: IgRPCReceiverConfig<FillDataReturn>['successCallback']

  private readonly errorCallback: IgRPCReceiverConfig['errorCallback']

  protected constructor (config: IgRPCReceiverConfig<FillDataReturn>) {
    this.sendStrategy = config.sendStrategy
      // eslint-disable-next-line new-cap
      ? new config.sendStrategy(this, config.sendStrategyConfig)
      : new GRPCReceiverSendStrategyHTTP<SendStrategyData>(this as GRPCReceiverAbstract)
    this.src = config.src
    this.toServer = config.adapter?.toServer || ((data: unknown) => data)
    this.toClient = config.adapter?.toClient || ((data: unknown) => data)
    this.successCallback = config.successCallback || (() => {})
    this.errorCallback = config.errorCallback || (() => {})

    this.ws = Services.wsSubscriptions[config.wsChannel].connect(this.requestHandler.bind(this))
  }

  protected abstract processData (data: BatchData[]): void

  protected abstract fillData (): FillDataReturn

  setRequestId (requestId: orNull<IgRPCMeta['requestId']> = null) {
    this.requestId = requestId
  }

  send (payload: SendStrategyData): SendStrategyResult {
    return this.sendStrategy.send(payload)
  }

  startLoading () {
    this._loading = true
  }

  stopLoading () {
    this._loading = false
    clearTimeout(this.timeoutId)
  }

  fetchCallback (data: IgRPCMeta) {
    this.setRequestId(data.requestId)

    const stream = this.getStream(this.requestId)
    if (!stream) { return this.setTimeout() }

    this.processStreamState(stream)
  }

  requestErrorHandler (data?: IApiError) {
    this.resetProps()
    this.stopLoading()
    this.errorCallback(data)
  }

  setTimeout () {
    clearTimeout(this.timeoutId)
    this.timeoutId = setTimeout(this.requestErrorHandler.bind(this), GRPCStream.timeout)
  }

  protected resetProps () {
    this.setRequestId()
    Object.values(this.streams).forEach(this.destroyStream.bind(this))
  }

  private requestHandler (rawPayload: IWSMessage<string | IApiError, string, IgRPCMeta>) {
    const {
      data,
      meta: {
        requestId,
        type,
        index,
      },
    } = snakeToCamel(rawPayload) as IWSMessage<string | IApiError, string, IgRPCMeta>

    const stream = this.getStream(requestId) || this.appendStream(requestId)
    if (stream.getErrors()) { return }

    type === GRPC_META_TYPE.ERROR
      ? stream.setErrors(data as IApiError)
      : GRPCStream.isEndPartOfStream(data as string)
        ? stream.setStreamLength(index)
        : stream.appendBatch(index, data as BatchData)

    if (requestId !== this.requestId) { return }
    this.processStreamState(stream)
    clearTimeout(this.timeoutId)
  }

  private onEndOfStream () {
    this.stopLoading()
    this.processData(this.getStream(this.requestId).getBatches())
    this.successCallback(this.fillData())
    this.resetProps()
  }

  private appendStream (requestId: IgRPCMeta['requestId']) {
    this.streams[requestId] = new GRPCStream<BatchData>(requestId, this.streamAbandonedCallback.bind(this))

    return this.streams[requestId]
  }

  private getStream (requestId: IgRPCMeta['requestId']) {
    return this.streams[requestId]
  }

  private processStreamState (stream: GRPCStream<BatchData>) {
    const errors = stream.getErrors()
    errors
      ? this.requestErrorHandler(errors)
      : stream.hasAllParts() && this.onEndOfStream()
  }

  private streamAbandonedCallback (requestId: IgRPCMeta['requestId']) {
    this.requestId === requestId
      ? this.requestErrorHandler()
      : this.destroyStream(requestId)
  }

  private destroyStream (streamOrRequestId: GRPCStream<BatchData> | IgRPCMeta['requestId']) {
    const stream = streamOrRequestId instanceof GRPCStream
      ? streamOrRequestId
      : this.getStream(streamOrRequestId)

    if (!stream) { return }

    stream.destroy()
    delete this.streams[stream.getRequestId()]
  }
}
