import { MTableCell } from '@/vue_present/_base/Tables/MTableGenerator/MTableCell'
import {
  AcquiringListItemBase,
} from '@/vue_apps/FinanceModule/FinanceModuleIndex/classes/Acquiring/AcquiringListItemBase'
import {
  IAcquiringSearchTransaction,
} from '@/vue_apps/FinanceModule/FinanceModuleIndex/interfaces/Acquiring/IAcquiringSearchTransaction'
import {
  ACQUIRING_PAYMENT_TYPE,
} from '@/vue_apps/FinanceModule/FinanceModuleIndex/consts/Acquiring/AcquiringPaymentType'
import { tScoped } from '@/helpers/i18nHelpers'
import { vueFilters } from '@/plugins/vue/filtersPlugin/vueFilters'
import {
  ACQUIRING_OPERATION_TYPE,
} from '@/vue_apps/FinanceModule/FinanceModuleIndex/consts/Acquiring/AcquiringOperationType'

const ts = tScoped('finance')

export class AcquiringListItem extends AcquiringListItemBase {
  idCell: MTableCell

  status: IAcquiringSearchTransaction['status']

  constructor (item: IAcquiringSearchTransaction) {
    super(item)

    this.idCell = new MTableCell(item.id)
    this.status = item.status
    this.setPaymentTypeCell(item.paymentType)
    this.setAmount(item)
  }

  private setPaymentTypeCell (paymentType: ACQUIRING_PAYMENT_TYPE) {
    this.paymentTypeCell = paymentType === ACQUIRING_PAYMENT_TYPE.CARD
      ? new MTableCell()
        .addTypeIcon('billed')
        .addTooltip(ts('byCard'))
      : new MTableCell()
        .addTypeIcon('')
        .addClasses('report-acquiring_sbp-icon')
        .addTooltip(ts('bySBP'))
  }

  private setAmount (item: IAcquiringSearchTransaction) {
    const amount = item.operationType === ACQUIRING_OPERATION_TYPE.PAY
      ? item.amount
      : -item.amount

    this.amountCell = new MTableCell(vueFilters.currency(amount))
      .addTooltip(true)
  }
}
