import {
  PersonalCheckupLaboratoryResearchResultsModel,
} from '@/vue_apps/CheckupsModule/classes/PersonalCheckupLaboratoryResearchResults/PersonalCheckupLaboratoryResearchResultsModel'
import {
  PERSONAL_CHECKUP_LABORATORY_RESEARCH_INFECTION_NOT_FOUND_NSI_CODE,
} from '@/vue_apps/CheckupsModule/store/personalCheckupResearchResults/personalCheckupResearchResultsConsts'

export class PersonalCheckupLaboratoryResearchResultsLogic extends PersonalCheckupLaboratoryResearchResultsModel {
  setDefaultMedicalAssessment () {
    this.medicalAssessment = this.medicalAssessments
      .find((item) =>
        +item.nsiId === PERSONAL_CHECKUP_LABORATORY_RESEARCH_INFECTION_NOT_FOUND_NSI_CODE)
  }

  append () {
    this.nestedItems.append({
      research: this.research,
      date: this.date,
      medicalAssessment: this.medicalAssessment,
    })
  }

  clear () {
    this.research = null
    this.date = Utils.getBaseFormattedDate()
    this.setDefaultMedicalAssessment()
  }
}
