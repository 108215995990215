import { IListStrategy } from '@/_api/_requests/MListServiceApi/strategies/_base/IListStrategy'
import { MListServiceApi } from '@/_api/_requests/MListServiceApi/MListServiceApi'
import { IMListServiceApiConfig } from '@/_declarations/IMListServiceConfigApi'
import { TAdapter } from '@/_api/decorators/api/interfaces/IAdapter'
import { DEFAULT_TOTAL_PAGES } from '@/vue_components/store/modules/filters_base'
import { Callback } from '@/_api/decorators/callback'
import { Api } from '@/_api/decorators/api/Api'
import { ICRUDReturn } from '@/_api/decorators/api/interfaces/ICRUDReturn'

/**
 * @deprecated
 */
export class CategoriesSearchStrategy<DataItem, FiltersMap> implements IListStrategy<DataItem, FiltersMap> {
  context: MListServiceApi<DataItem, FiltersMap>

  config: IMListServiceApiConfig<DataItem, FiltersMap>

  src: string

  toServer: TAdapter

  toClient: TAdapter

  constructor (
    context: MListServiceApi<DataItem, FiltersMap>,
    config: IMListServiceApiConfig<DataItem, FiltersMap>
  ) {
    this.context = context
    this.config = config
    this.src = config.src
    this.toServer = () => ({ title: this.context.searchQuery })
    this.toClient = (data) => ({ data })
  }

  @Callback('fillData')
  @Api.clientAdapter('toClient')
  @Api.get('src')
  @Api.serverAdapter('toServer')
  fetchAll (): Promise<void> {
    return {
      payload: {
        title: this.context.searchQuery,
      },
    } as ICRUDReturn as Promise<void>
  }

  // @ts-ignore
  fillData ({ data }: { data: DataItem[] }): void {
    this.context
      .setData(data.map((item) => new this.config.DataItemEntity(item)))

    this.context.setTotalItems(data.length)
    this.context.setTotalPages(DEFAULT_TOTAL_PAGES)

    this.context.stopLoading()
  }
}
