import { CatalogHelpers } from '@/helpers/CatalogHelpers'

export enum CLINICAL_GUIDELINE_ENTRY_STEP {
  DIAGNOSTICS = 'diagnostics',
  TREATMENT = 'treatment',
  REHABILITATION = 'rehabilitation',
  PREVENTION = 'prevention',
}

export const CLINICAL_GUIDELINE_ENTRY_STEP_ITEMS = CatalogHelpers.getCatalogItemsFromEnum<CLINICAL_GUIDELINE_ENTRY_STEP>(
  CLINICAL_GUIDELINE_ENTRY_STEP,
  'clinicalGuidelines.step'
)
