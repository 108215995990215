<template>
  <div
    class="document-field-writable"
    :class="{
      'center': textCenter,
      'document-field-writable__input': !multiline,
      'document-field-writable_disabled': disabled
    }"
  >
    <m-textarea
      v-if="multiline"
      class="document-field-writable__textarea"
      :autosize="autosize"
      :value="valueAdapted"
      :m-fixed-height="false"
      :disabled="disabled"
      :style="{ minWidth, width: widthComputed, maxWidth }"
      @input="$emit('input', $event)"
    />

    <m-input
      v-else
      class="document-field-writable__input"
      :value="value"
      :clearable="false"
      :m-fixed-height="false"
      :disabled="disabled"
      :style="{ minWidth, width: widthComputed, maxWidth }"
      @input="$emit('input', $event)"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import MTextarea from '@/vue_present/_base/inputs/MTextarea/MTextarea.vue'
import MInput from '@/vue_present/_base/inputs/MInput/MInput.vue'
import { isObject } from '@/helpers/typeofHelpers'

const minRows = 2

type TMultiline = {
  minRows?: number
  maxRows?: number
}

export default defineComponent({
  name: 'DocumentFieldWritable',

  components: { MInput, MTextarea },

  props: {
    value: { type: [String, Number], default: '' },

    multiline: {
      type: [
        Boolean,
        Object,
      ] as PropType<boolean | TMultiline>,
      default: false,
    },
    textCenter: Boolean,
    minWidth: { type: String, default: '30px' },
    width: { type: String, default: 'auto' },
    maxWidth: { type: String, default: 'auto' },

    disabled: Boolean,

    fillAvailableWidth: { type: Boolean, default: false },
  },

  computed: {
    valueAdapted () {
      return this.value?.toString() || ''
    },

    widthComputed () {
      return this.fillAvailableWidth
        ? '100%'
        : this.width
    },

    autosize () {
      const multiline: TMultiline = this.multiline

      return isObject<TMultiline>(multiline)
        ? {
            minRows: multiline.minRows || minRows,

            maxRows: multiline.maxRows || multiline.minRows || minRows,
          }
        : this.multiline
    },
  },
})
</script>
