import { specSymbolsSanitize } from '@/specific/entries/submit_sanitizer'
import { ProtocolBuilder } from '@/vue_apps/ProtocolsApp/entities/ProtocolBuilder'

export const onSubmitPrepareProtocolData =
  (protocolBuilder: ProtocolBuilder, editorMountPoint: HTMLDivElement) => {
    const storeState = protocolBuilder.getSanitizedData(protocolBuilder.store.getState())
    const html = protocolBuilder.getSanitizedHtml(editorMountPoint.innerHTML)

    return {
      templateHtml: specSymbolsSanitize(html),
      templateData: specSymbolsSanitize(JSON.stringify(storeState)),
    }
  }
