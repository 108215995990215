<template>
  <m-table
    class="m-table-generator"
    :loading="loading"
    :use-context-menu="useContextMenu"
    :use-min-width="useMinWidth"
    :class="{
      'use-hidden-scrollbars': useHiddenScrollbars
    }"
  >
    <template #caption>
      <slot name="caption" />
    </template>

    <!-- Шапка -->
    <template #header>
      <slot name="header">
        <m-table-generator-table-header-row
          v-if="structuredTableSchema.options.useHeader"
          :table-headers="structuredTableSchema.headers"
          :indeterminate="massSelectIndeterminate"
          :mass-check-box-value.sync="massSelectCheckBoxValue"
          :use-checkboxes="useCheckboxes"
          :use-sortable="useSortable"
          :use-edit="useEdit"
          :use-delete="useDelete"
          @sortChange="$emit('sortChange', $event)"
        />
      </slot>
    </template>

    <!-- Тело -->
    <template #body>
      <slot name="body">
        <slot
          v-for="item in items"
          name="rowItem"
        >
          <m-table-generator-table-row
            :key="`item:${item.id}`"
            :table-headers="structuredTableSchema.headers"
            :item="item"
            :use-checkboxes="useCheckboxes"
            :use-edit="useEdit"
            :use-delete="useDelete"
            :use-ignore-click="useIgnoreClick"
            :active-item-id="activeItemId"
            @onRowClick="$emit('onItemClick', item); $emit('onRowClick', $event)"
            @onRightClick="$emit('onItemContextMenuClick', item)"
            @onEditClick="$emit('onItemEdit', item)"
            @onDeleteClick="$emit('onItemDelete', item)"
            @updateSyncGC="$emit('updateSyncGC', { ...$event, item })"
            @onCellClick="$emit('onCellClick', $event)"
          >
            <template
              v-for="(slotContent, slotName) in $scopedSlots"
              #[slotName]="slotProps"
            >
              <slot
                :name="slotName"
                v-bind="slotProps"
              />
            </template>
          </m-table-generator-table-row>

          <slot
            name="afterRowItem"
            :item="item"
          />
        </slot>
      </slot>
    </template>

    <!-- Дно -->
    <template #footer>
      <slot name="footer">
        <m-table-generator-table-footer-row
          v-if="structuredTableSchema.options.useFooter && total"
          :total="total"
          :table-headers="structuredTableSchema.headers"
          :indeterminate="massSelectIndeterminate"
          :mass-check-box-value.sync="massSelectCheckBoxValue"
          :use-checkboxes="useCheckboxes"
          :use-sortable="useSortable"
          :use-edit="useEdit"
          :use-delete="useDelete"
        >
          <template
            v-for="(slotContent, slotName) in $scopedSlots"
            #[slotName]="slotProps"
          >
            <slot
              :name="slotName"
              v-bind="slotProps"
            />
          </template>
        </m-table-generator-table-footer-row>
      </slot>
    </template>

    <!-- Контекстное меню -->
    <template #contextMenu>
      <slot name="contextMenu" />
    </template>
  </m-table>
</template>

<script>
import { getTableSchema } from './logic/getTableSchema'
import MTable from '../MTable/MTable.vue'
import { PropsTypes } from '../../PropsTypes'
import MTableGeneratorTableHeaderRow from './MTableGeneratorTableHeaderRow.vue'
import MTableGeneratorTableRow from './MTableGeneratorTableRow.vue'
import MTableGeneratorTableFooterRow from '@/vue_present/_base/Tables/MTableGenerator/MTableGeneratorTableFooterRow.vue'

export default {
  name: 'MTableGenerator',

  components: { MTableGeneratorTableFooterRow, MTableGeneratorTableRow, MTableGeneratorTableHeaderRow, MTable },

  props: {
    /** @type {TUserTableSchema} */
    tableSchema: {
      type: Object,
      required: true,
    },

    /** @type {TTableSchemaItem[]} */
    items: {
      type: Array,
      required: true,
    },

    activeItemId: { type: [String, Number], default: null },

    /** @type {TTableSchemaItem} */
    total: PropsTypes.Object({}),

    valueKey: PropsTypes.String('id'),

    loading: Boolean,
    useContextMenu: Boolean,
    useHiddenScrollbars: Boolean,
  },

  emits: [
    'sortChange',
    'onItemClick',
    'onItemContextMenuClick',
    'onItemEdit',
    'onItemDelete',
    'setSelectedItems',
    'updateSyncGC',
    'onCellClick',
    'onRowClick',
  ],

  data () {
    return {
      selectableCount: 0,
      massSelectCheckBoxValue: false,
    }
  },

  computed: {
    /** @returns {TTableSchemaItem[]} */
    itemsTyped () {
      return this.items
    },

    /** @returns {TTableSchema} */
    structuredTableSchema () {
      return getTableSchema(this.tableSchema)
    },

    /** @return {boolean} */
    useCheckboxes () {
      return this.structuredTableSchema.options.useCheckboxes
    },

    /** @return {boolean} */
    useEdit () {
      return this.structuredTableSchema.options.useEdit
    },

    /** @return {boolean} */
    useDelete () {
      return this.structuredTableSchema.options.useDelete
    },

    /** @return {boolean} */
    useSortable () {
      return this.structuredTableSchema.options.useSortable
    },

    /** @return {boolean} */
    useIgnoreClick () {
      return this.structuredTableSchema.options.useIgnoreClick
    },

    useMinWidth () {
      return this.structuredTableSchema.options.useMinWidth
    },

    /** @return {TTableSchemaItem[]|[]} */
    selectedItems () {
      return this.useCheckboxes
        ? this.itemsTyped.filter((item) => item.__selected)
        : []
    },

    /** @return {number} */
    selectedCount () {
      return this.selectedItems.length
    },

    /** @return {boolean} */
    massSelectIndeterminate () {
      return this.selectedCount > 0 && this.selectedCount < this.selectableCount
    },
  },

  watch: {
    items () {
      this.calculateSelectableCount()
      this.$nextTick(() => {
        Services.telephony.reset()
      })
    },

    massSelectCheckBoxValue (to) {
      this.setSelectAllFields(to)
    },

    selectedCount (to) {
      if (to === 0) {
        this.massSelectCheckBoxValue = false
      } else if (to === this.selectableCount) {
        this.massSelectCheckBoxValue = true
      }
    },

    selectedItems () {
      this.$emit('setSelectedItems', this.selectedItems)
    },
  },

  created () {
    this.calculateSelectableCount()
  },

  methods: {
    calculateSelectableCount () {
      if (!this.useCheckboxes) { return 0 }
      this.selectableCount = this.itemsTyped
        .reduce((acc, item) => acc + Number(item.__selectable), 0)
    },

    setSelectAllFields (value) {
      if (!this.useCheckboxes) { return }
      this.itemsTyped.forEach((item) => {
        if (!item.__selectable) { return }
        item.__selected = value
      })
    },
  },
}
</script>
