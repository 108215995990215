<template>
  <div
    v-if="type === 'general'"
    class="m-prompt-notice prompt-notice"
    :class="{[types[type]]: true}"
  >
    {{ text }}
  </div>

  <m-alert
    v-else
    type="warning"
    :text="text"
    italic
    show-icon
    class="m-prompt-notice"
  />
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import MAlert from '@/vue_present/_base/MAlert/MAlert.vue'

const types = {
  general: 'mb-indent-validation',
  alert: 'highlight',
}

export default defineComponent({
  name: 'MPromptNotice',
  components: { MAlert },

  props: {
    text: { type: String, required: true },
    type: { type: String as PropType<keyof typeof types>, default: 'general' },
  },

  data () {
    return {
      types,
    }
  },
})
</script>
