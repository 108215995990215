import { WORKPLACES_CATALOG_ROUTE_NAMES } from '@/vue_apps/catalogs_root/Workplaces/router/routeNames'
import { routePrefixHelper } from '@/helpers/routePrefixHelper'
import FiscalPrinter from '@/vue_apps/catalogs_root/Workplaces/components/FiscalPrinter.vue'
import WorkplacesCatalogForm from '@/vue_apps/catalogs_root/Workplaces/components/WorkplacesCatalogForm.vue'
import WorkplacesCatalogApp from '@/vue_apps/catalogs_root/Workplaces/WorkplacesCatalogApp.vue'

const getRoute = routePrefixHelper('/workplaces')

export const workplacesCatalogRoutes = [
  {
    path: getRoute(),
    name: WORKPLACES_CATALOG_ROUTE_NAMES.INDEX,
    component: WorkplacesCatalogApp,

    children: [
      {
        path: 'new',
        name: WORKPLACES_CATALOG_ROUTE_NAMES.NEW,
        component: WorkplacesCatalogForm,
      },

      {
        path: ':workplaceId/edit',
        name: WORKPLACES_CATALOG_ROUTE_NAMES.EDIT,
        component: WorkplacesCatalogForm,
      },
    ],
  },

  {
    path: getRoute('/:workplaceId/fiscal-printer'),
    name: WORKPLACES_CATALOG_ROUTE_NAMES.FISCAL_PRINTER,
    component: FiscalPrinter,
  },
]
