<template>
  <div
    class="m-form-show-page"
    style="max-width: 40%"
  >
    <m-panel
      :title="headingTitle"
      :icon="headingIcon"
      class="flex flex-grow-1"
      body-css-classes="p-0"
    >
      <m-form-show
        :form="formFields"
        use-form-show-scope
      >
        <template #m-show-value-email>
          <a href="mailto:mail@mail.ru">Ссылка на почту здесь. Жмак!</a>
        </template>
      </m-form-show>
    </m-panel>
  </div>
</template>

<script>
import MFormShow from '@/vue_present/_base/MFormShow/MFormShow'
import MPanel from '@/vue_present/_base/MPanel/MPanel'

export default {
  name: 'FrontBookPageMFormShow',
  components: { MFormShow, MPanel },

  data () {
    return {
      formFields: {
        name: { label: t('form_show.name'), value: 'Иван' },
        surname: { label: t('form_show.surname'), value: 'Иванов' },
        secondName: { label: t('form_show.secondName'), value: 'Иванович' },
        sexId: { label: t('form_show.sexId'), value: 'Мужской' },
        email: { label: t('form_show.email'), value: 'mail@mail.ru' },
      },
      headingIcon: 'fad fa-user',
      headingTitle: t('client'),

      editorValue: 'Шаблон',
    }
  },
}
</script>
