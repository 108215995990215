<template>
  <div class="m-form-show">
    <slot>
      <m-form-show-row
        v-for="[fieldName, fieldContent] in fields"
        :key="`m-form-show-row-${fieldName}`"
        :class="{
          'hidden-print': fieldContent.hiddenPrint
        }"
      >
        <template v-if="!fieldContent.hidden">
          <m-form-show-label
            :key="`m-show-label:${fieldName}`"
            :class="`m-form-show__label_${fieldName}`"
          >
            <slot :name="`m-show-label-${fieldName}`">
              {{ fieldContent.label }}
            </slot>
          </m-form-show-label>

          <m-form-show-value
            :key="`m-show-value:${fieldName}`"
            :class="`m-form-show__value_${fieldName}`"
          >
            <slot :name="`m-show-value-${fieldName}`">
              {{ fieldContent.value }}
            </slot>
          </m-form-show-value>
        </template>
      </m-form-show-row>
    </slot>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import MFormShowRow from '@/vue_present/_base/MFormShow/MFormShowRow.vue'
import MFormShowLabel from '@/vue_present/_base/MFormShow/MFormShowLabel.vue'
import MFormShowValue from '@/vue_present/_base/MFormShow/MFormShowValue.vue'

interface IFormShowField {
  label: string
  value: any
  hidden?: boolean
  hiddenPrint?: boolean
}

type TFormShowFields = Array<[string, IFormShowField]>

export type TFormShowForm = Record<string, IFormShowField>

export default defineComponent({
  name: 'MFormShow',

  components: {
    MFormShowValue,
    MFormShowLabel,
    MFormShowRow,
  },

  props: {
    form: {
      type: Object as PropType<TFormShowForm>,
      default: () => ({}),
    },
  },

  computed: {
    fields (): TFormShowFields {
      return Object.entries(this.form || {})
        .filter((objectEntry) => !objectEntry[1].hidden)
    },
  },
})
</script>
