<template>
  <m-select-lazy
    :value="value"
    :label="label"
    :fetch-method="selectedFetchMethod"
    :m-fixed-height="mFixedHeight"
    class="reusable-doctor-list"
    :option-label="optionLabel"
    :use-first-time-fetch="useFirstTimeFetch"
    :use-first-time-fetch-on-open="useFirstTimeFetchOnOpen"
    :placeholder="placeholder"
    :required="required"
    :validator-name="validatorName"
    :disabled="disabled"
    :clearable="clearable"
    :use-user-mode="useUserMode"
    :use-custom-result="useCustomResult"
    :full-width="fullWidth"
    :multiple="multiple"
    :use-first-time-fetch-one-if404="useFirstTimeFetchOneIf404"
    :fetch-limit="fetchLimit"
    filterable
    @changeByLazy="onDoctorChange"
    @registerValidator="$registerValidator($event)"
  />
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import MSelectLazy from '@/vue_present/_base/MSelectLazy/MSelectLazy.vue'
import { USER_GROUP_IDS } from '@/_global_scripts/GLOBAL_CONSTS'
import { MUserPresenter } from '@/_api/MUser/MUserPresenter'
import { isArray } from '@/helpers/typeofHelpers'
import { extractId, orUndefined } from '@/_medods_standart_library/msl'
import { DEFAULT_LIMIT } from '@/vue_components/store/modules/filters_base'
import { IUser } from '@/_declarations/IUser'
import { IListServiceBaseParams } from '@/_declarations/IListServiceBaseParams'
import { userListAdapter } from '@/_api/MUser/userListAdapter'

/**
 * Переиспользуемый компонент для списка врачей
 */
export default defineComponent({
  name: 'ReusableDoctorsList',
  components: { MSelectLazy },

  model: {
    prop: 'value',
    event: 'onDoctorChange',
  },

  props: {
    value: { type: [Number, Object, Array], default: null },
    label: { type: String, default: t('doctor') },
    optionLabel: { type: String, default: 'shortName' },
    fetchMethod: { type: Function, default: null },
    useFirstTimeFetch: { type: Boolean, default: true },
    useFirstTimeFetchOnOpen: Boolean,
    mFixedHeight: Boolean,
    placeholder: { type: String, default: t('no_letter_order') },
    required: Boolean,
    validatorName: { type: String, default: null },
    disabled: Boolean,
    fullWidth: Boolean,
    multiple: Boolean,
    clearable: { type: Boolean, default: true },
    useUserMode: { type: Boolean, default: true },

    useCustomResult: { type: String, default: null },

    // настройки sortParams
    currentClinic: {
      type: [Boolean, Number, Array],
      default: false,
    },

    // Если текущий пользователь подходит под условия currentClinic и userGroup,
    // то эмитится onDoctorChange с этим пользователем
    useCurrentUser: Boolean,

    userGroup: { type: [Array, Number] as PropType<readonly number[] | number[] | number>, default: () => USER_GROUP_IDS.MEDICAL_GROUP },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    useFirstTimeFetchOneIf404: { type: Function, async default (id: number) {} },
    fetchLimit: { type: Number, default: DEFAULT_LIMIT },

    /**
     * @deprecated
     */
    useRawData: Boolean,
  },

  emits: [
    'onDoctorChange',
    'tryCurrentUserSelect',
    'registerValidator',
  ],

  computed: {
    currentClinicId () {
      if (typeof this.currentClinic === 'boolean') {
        return (this.currentClinic && gon.application.current_clinic.id)
      }

      return this.currentClinic
    },

    selectedFetchMethod () {
      if (typeof this.fetchMethod === 'function') {
        return this.fetchMethod
      }

      return this.fetchPerformer()
    },
  },

  mounted () {
    if (this.useCurrentUser) {
      this.tryCurrentUserSelect()

      this.$emit('tryCurrentUserSelect', this.tryCurrentUserSelect)
    }
  },

  methods: {
    fetchPerformer () {
      const clinicId = this.currentClinicId

      return (sortParams: IListServiceBaseParams) => {
        const toClientAdapter = orUndefined(
          this.useRawData &&
            userListAdapter.toClientRaw
        )

        return new MUserPresenter().list({
          ...sortParams,
          searchString: sortParams.searchQuery,
          userGroupIds: this.userGroup,
          clinicIds: clinicId
            ? Array.isArray(clinicId)
              ? clinicId
              : [clinicId]
            : undefined,
        }, {
          toClientAdapter,
        })
      }
    },

    onDoctorChange (doctor: IUser) {
      this.$emit('onDoctorChange', doctor)
    },

    tryCurrentUserSelect () {
      const currentUser = gon.application.current_user

      if (this.currentClinic && !this.tryCurrentUserSelectCurrentClinic(currentUser.clinic_ids)) { return }

      if (!this.tryCurrentUserSelectCurrentGroup()) { return }

      this.onDoctorChange({
        id: currentUser.id,
        name: currentUser.name,
        surname: currentUser.surname,
        secondName: currentUser.second_name,
        shortName: currentUser.short_name,
        fullName: currentUser.full_name,
      })
    },

    tryCurrentUserSelectCurrentClinic (currentUserClinicIds: number[]) {
      const clinicIds = this.currentClinic === true
        ? [gon.application.current_clinic.id]
        : isArray(this.currentClinic)
          ? this.currentClinic
          : [this.currentClinic]

      return currentUserClinicIds.some((clinicId: number) => clinicIds.includes(clinicId))
    },

    tryCurrentUserSelectCurrentGroup () {
      const currentUserGroupsIds = gon.application.current_user_groups
        .map(extractId)

      const userGroups = isArray(this.userGroup)
        ? this.userGroup
        : [this.userGroup]

      return currentUserGroupsIds.some((groupId: number) => userGroups.includes(groupId))
    },
  },
})
</script>
