import { tScoped } from '@/helpers/i18nHelpers'
import {
  ACQUIRING_PAYMENT_TYPE,
} from '@/vue_apps/FinanceModule/FinanceModuleIndex/consts/Acquiring/AcquiringPaymentType'

const ts = tScoped('finance')

export const PAYMENT_TYPE_FILTER_ITEMS = [
  {
    id: ACQUIRING_PAYMENT_TYPE.CARD,
    title: ts('byCard'),
  },
  {
    id: ACQUIRING_PAYMENT_TYPE.SBP,
    title: ts('bySBP'),
  },
] as const
