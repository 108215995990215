import { EntriesListItem } from '@/vue_apps/MedicalRecords/MedicalRecordsTabs/components/EntriesTab/api/EntriesListItem'
import {
  EntriesListTotalItem,
} from '@/vue_apps/MedicalRecords/MedicalRecordsTabs/components/EntriesTab/api/EntriesListTotalItem'
import { inArrayOrUndefined, orUndefined } from '@/_medods_standart_library/msl'

const extractPerformerIds = (data) => {
  if (data.mine) {
    return [gon.application.current_user.id]
  }

  return data.performer ? [data.performer.id] : undefined
}

export const entriesListAdapter = {
  toClient (response) {
    return {
      ...response,
      data: response.data.map((item) => new EntriesListItem(item)),
      total: new EntriesListTotalItem(response.data),
    }
  },

  toServer (data) {
    const kinds = data.kind
      ? inArrayOrUndefined(data.kind?.id)
      : data.kinds

    return {
      clientId: data.clientId,
      limit: data.limit,
      offset: data.offset,

      date: orUndefined(data.date),
      clinicIds: data.clinic ? [data.clinic.id] : undefined,
      kinds,
      specialtyIds: data.specialty ? [data.specialty.id] : undefined,
      performerIds: extractPerformerIds(data),
      teethIds: orUndefined(data.teethIds, true),
    }
  },
}
