import { FINANCE_ROUTE_NAMES } from '@/vue_apps/FinanceModule/FinanceModuleIndex/router/routeNames'
import OrdersPage from '@/vue_apps/FinanceModule/FinanceModuleIndex/pages/OrdersPage.vue'
import PaymentsPage from '@/vue_apps/FinanceModule/FinanceModuleIndex/pages/PaymentsPage.vue'
import PaymentPage from '@/vue_apps/FinanceModule/FinanceModuleIndex/pages/PaymentPage.vue'
import ExpensesPage from '@/vue_apps/FinanceModule/FinanceModuleIndex/pages/ExpensesPage.vue'
import AcquiringPage from '@/vue_apps/FinanceModule/FinanceModuleIndex/pages/AcquiringPage.vue'

const getDefaultRoute = (path = '*') => `/finance${path}`

export const ordersRoute = {
  path: getDefaultRoute('/orders'),
  name: FINANCE_ROUTE_NAMES.ORDERS,
  component: OrdersPage,
  meta: {
    label: t('finance.orders'),
    icon: 'shopping-cart',
    tooltip: t('finance.orders'),
  },
}

export const paymentsRoute = {
  path: getDefaultRoute('/payments'),
  name: FINANCE_ROUTE_NAMES.PAYMENTS,
  component: PaymentsPage,
  meta: {
    label: t('finance.payments'),
    icon: 'money-bill-wave-alt',
    tooltip: t('finance.payments'),
  },
}

export const acquiringRoute = {
  path: getDefaultRoute('/acquiring'),
  name: FINANCE_ROUTE_NAMES.ACQUIRING,
  component: AcquiringPage,
  meta: {
    label: t('finance.acquiring'),
    icon: 'credit-card',
    tooltip: t('finance.acquiring'),
  },
}

export const paymentRoutes = [
  {
    path: '/payments/new',
    name: FINANCE_ROUTE_NAMES.PAYMENT_NEW,
    component: PaymentPage,
  },
  {
    path: '/payments/:id',
    name: FINANCE_ROUTE_NAMES.PAYMENT_SHOW,
    component: PaymentPage,
  },
]

export const expensesRoute = {
  path: getDefaultRoute('/expenses'),
  name: FINANCE_ROUTE_NAMES.EXPENSES,
  component: ExpensesPage,
  meta: {
    label: t('finance.expenses'),
    icon: 'dollar-sign',
    tooltip: t('finance.expenses'),
  },
}

export const financeRoutes = [
  ordersRoute,
  paymentsRoute,
  ...paymentRoutes,
  acquiringRoute,
  expensesRoute,

  {
    path: getDefaultRoute('*'),
    name: FINANCE_ROUTE_NAMES.INDEX,
    redirect: { name: FINANCE_ROUTE_NAMES.ORDERS },
  },
]
