import { FiscalPrinterLogic } from '@/vue_apps/catalogs_root/Workplaces/classes/FiscalPrinter/FiscalPrinterLogic'
import {
  FISCAL_PRINTER_EVENT,
  FISCAL_PRINTER_REPORT_TYPE,
  getWorkplacesFiscalPrinterEvent,
} from '@/vue_apps/catalogs_root/Workplaces/consts/fiscalPrinterEvent'
import { IWSMessage } from '@/_declarations/IWSMessage'
import { IWorkplaceGetInfo } from '@/vue_apps/catalogs_root/Workplaces/interfaces/IWorkplaceGetInfo'
import { IWorkplacePrintReport } from '@/vue_apps/catalogs_root/Workplaces/interfaces/IWorkplacePrintReport'
import { TStringNumber } from '@/_declarations/TStringNumber'
import { store } from '@/vue_components/store/store'
import { snakeToCamel } from '@/_api/_requests/helpers'

export class FiscalPrinterApi extends FiscalPrinterLogic {
  initSubscribes () {
    this.getInfoSubscribes()
    this.dayStatusSubscribes()
    this.printReportSubscribe()
  }

  beep () {
    Services.workplace.sendMessage(FISCAL_PRINTER_EVENT.BEEP, { workplace_id: this.workplaceId })
  }

  openDay () {
    Services.workplace.sendMessage(FISCAL_PRINTER_EVENT.OPEN_DAY, { workplace_id: this.workplaceId })
  }

  cancelReceipt () {
    Services.workplace.sendMessage(FISCAL_PRINTER_EVENT.CANCEL_RECEIPT, { workplace_id: this.workplaceId })
  }

  printReport (type: FISCAL_PRINTER_REPORT_TYPE = this.receipt?.id, payload = {}) {
    if (!type) { return }
    Services.workplace.printReport(type, this.workplaceId, payload)
  }

  printDocumentByNumber (docNumber: TStringNumber) {
    this.printReport(FISCAL_PRINTER_REPORT_TYPE.DOC_BY_NUMBER, { doc_number: docNumber })
  }

  private getInfoSubscribes () {
    Services.pubSub.subscribe(getWorkplacesFiscalPrinterEvent(FISCAL_PRINTER_EVENT.GET_INFO), (msg: IWSMessage<IWorkplaceGetInfo>) => {
      if (msg.data.workplace_id !== this.workplaceId) { return }
      super.setValue('info', msg.data.info)
    })

    const isHandshakeComplete = store.getters['wsUserChannel/GET_IS_HANDSHAKE_COMPLETE']
    if (isHandshakeComplete) {
      this.sendGetInfo()
    }

    Services.pubSub.subscribe(getWorkplacesFiscalPrinterEvent(FISCAL_PRINTER_EVENT.HANDSHAKE), () => this.sendGetInfo.bind(this))
  }

  private sendGetInfo () {
    Services.workplace.sendMessage(FISCAL_PRINTER_EVENT.GET_INFO, { workplace_id: this.workplaceId, data: { as_dictionary: true } })
  }

  private dayStatusSubscribes () {
    Services.pubSub.subscribe(getWorkplacesFiscalPrinterEvent(FISCAL_PRINTER_EVENT.OPEN_DAY), () => {
      this.dayOpenedHandler(true)
    })

    Services.pubSub.subscribe('fiscalPrinterIsDayOpened', (opened: boolean) => {
      this.dayOpenedHandler(opened)
    })

    Services.pubSub.subscribe('broadcast:fiscalPrinterIsDayOpened', (opened: boolean) => {
      this.dayOpenedHandler(opened)
    })
  }

  private printReportSubscribe () {
    Services.pubSub.subscribe(getWorkplacesFiscalPrinterEvent(FISCAL_PRINTER_EVENT.PRINT_REPORT), (msg: IWSMessage<IWorkplacePrintReport>) => {
      const { workplaceId, type } = snakeToCamel(msg.data) as IWorkplacePrintReport
      if (workplaceId !== this.workplaceId || type !== FISCAL_PRINTER_REPORT_TYPE.Z_MAIN) { return }

      Services.pubSub.emit('fiscalPrinterIsDayOpened', false, true)
    })
  }
}
