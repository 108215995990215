import BaseModal from './document_type_base'
import FormatterInitializer from '@/modules/document_types/formatter_initializer.js'
import { cleanUpEditor, VALID_WORD_ELEMENTS } from '@/plugins/dynamic_forms/tinymce_helpers'

let formatterInitializer
let tmceLoaded
let variablesListLoaded

let isNew = false

const DOCUMENT_NEXT_NUMBER_DEFAULT_VALUE = 1

export default class DocumentTypeModal extends BaseModal {
  _bindEvents () {
    super._bindEvents()
    initializeFormatter()
    // true new flag
    this.newItemButton.onclick = () => {
      isNew = true
      this._open()
      loadTinyMCE()
    }

    if (this.categoriesListWrap) {
      this.categoriesListWrap.onclick = this._showNotify.bind(this)
    }

    $(this.form).remote_form({
      model: 'document_type',
      saveButton: this.saveButton,
      onSuccess: (data) => {
        const oldDocTypeKind = this.item ? this.item.kinds_value : null
        const newDocTypeKind = data.kinds_value

        if (oldDocTypeKind !== newDocTypeKind && !isNew) {
          bootbox.confirmYN(T.doc_type_kind_was_changed, (confirmed) => {
            if (!confirmed) return

            $.ajax({
              url: Routes.update_kinds_document_path(data.id),
              method: 'PATCH',
              data: {
                kinds_value: newDocTypeKind,
              },
              success () {
                Notificator.success(T.doc_type_successful_update)
              },
              error (err) {
                console.error(err)
                Notificator.error(T.error_occurred)
              },
            })
          }, { title: T.doc_type_modal_header })
        }
        $(this.modalWindow).modal('hide')
        $(this.categoryTreeContainer).categoriesx('forceReload')
        if (this.currentTemplateContainer) {
          this._loadCurrentTemplate(data)
        }
        $(this.saveButton).loadSpin('stop')
        isNew = false
      },
    })

    $(this.modalWindow).on('hidden.bs.modal', () => { isNew = false })
    this.modalFooter
      .find('.modal-save')
      .attr('form', $(this.form).attr('id'))
      .attr('type', 'submit')
  }

  _showNotify () {
    if (this.categoryTreeContainer.classList.contains('disabled-block')) {
      Notificator.info('unbind_current_template')
    }
  }

  _loadCurrentTemplate (item) {
    this.forceClose = false
    this.currentItem = item
    this.categoryTreeContainer.classList.add('disabled-block')
    this.categoriesListWrap.style.cursor = 'not-allowed'
    this.recordIdInput.value = item.id
    $('#not-bind-template-info').hide()
    $('#template-items-table').show()
  }

  _open () {
    super._open()
    tinymce.remove()
    this.load(false)

    $(this.form).remote_form('clear')

    if (isNew) {
      $('#document_type_next_available_number')
        .val(DOCUMENT_NEXT_NUMBER_DEFAULT_VALUE)
    }

    if (
      $('#category').length > 0 &&
      this.appId === 'document_type_constuctor_mode'
    ) {
      this.categoryIdInput.value = $('#category').categoriesx('getCategory').id
    } else this.categoryIdInput.value = 0
    $(this.form).attr('action', Routes.document_types_path())
    $(this.form).attr('method', 'POST')
    this.modalFooter.css('float', 'left')
  }

  _openItem () {
    if (!this.currentItem) {
      bootbox.alert(t('template_not_set'))

      return false
    }
    this.start(this.currentItem)
  }

  load (item) {
    if (isNew) $(this.modalBody).loadSpin('stop')
    if (!item) {
      return
    }

    $(this.form).remote_form('update', item)
    $(this.form).attr('action', Routes.document_type_path(item.id))
    $(this.form).attr('method', 'PATCH')
    if ($('#category').length > 0) {
      this.categoryIdInput.value = $('#category').categoriesx('getCategory').id
    }

    //Activate s2b buttons in form on load
    if (item.print_header) {
      $('#document_type_print_header')
        .val(item.print_header.toString())
        .trigger('change')
    }

    if (item.need_document_dates) {
      $('#document_type_need_document_dates')
        .val(item.need_document_dates.toString())
        .trigger('change')
    }

    const kindsValues = []
    if (item.kinds_value > 2) {
      kindsValues.push(1, 2)
    } else {
      kindsValues.push(item.kinds_value)
    }
    $('#document_type_kinds').val(kindsValues).change()

    loadTinyMCE(item)
    $(this.modalBody).loadSpin('stop')
  }

  copy (item) {
    isNew = true
    if (!Utils.isPromise()) {
      item = Promise.resolve(item)
    }

    this._open()
    item.then((data) => {
      $(this.form).remote_form('update', data)
      loadTinyMCE()
      $(this.modalBody).loadSpin('stop')
    })
  }
}

function generateHTMLTable (modelName, tableData) {
  let table = "<table border='1' class='doc_type_generated'><thead><tr>"
  const keys = Object.keys(tableData)
  keys.forEach(function (element) {
    table = table + '<th>' + t(element) + '</th>'
  })
  table = table + '</tr></thead><tbody><% @' + modelName + '.each do |entry| %><tr>'
  keys.forEach(function (element) {
    table = table + '<td><%= entry.' + tableData[element] + ' %></td>'
  })
  table = table + '</tr><% end %></tbody></table>'

  return table
}

function initializeFormatter () {
  tmceLoaded = false
  variablesListLoaded = false
  formatterInitializer = new FormatterInitializer({
    url: Routes.document_types_set_attribute_list_path(),
    editorName: 'document_type[data]',
    onLoad () {
      variablesListLoaded = true
      if (tmceLoaded) formatterInitializer.visualize()
    },
    onItemSelectedSpecificAction (v) {
      const categoryName = v.source[0]
      const variableName = v.source[1]
      if (categoryName === 'order' && variableName === 'entries') {
        const orderEntries = generateHTMLTable('order&.entries_for_receipt_print', {
          code: 'number',
          title: 'title',
          measureUnitShort: 'measure_unit.short_title',
          price: 'price',
          amount_short: 'amount',
          discount: 'formatted_discount',
          tax_scheme: 'localized_tax_scheme',
          tax_scheme_sum: 'evaluated_nds',
          sum_price: 'final_sum',
          comment: 'comment',
        })
        formatterInitializer.editor.insertContent(orderEntries)

        return false
      } else if (categoryName === 'dental_order' && variableName === 'dental_order_entries') {
        const dentalOrderEntries = generateHTMLTable('dental_order&.dental_order_entries', {
          title: 'title',
          price: 'price',
          amount_short: 'amount',
          comment: 'comment',
          sum_price: 'sum',
        })
        formatterInitializer.editor.insertContent(dentalOrderEntries)

        return false
      }
    },
  })

  const $form = $('#document_type_form')
  $form.on('ajax:error', () => formatterInitializer.visualize())
  $form.parents('.modal').find('.modal-save').click((e) => {
    const invalidVariables = formatterInitializer.checkForInvalidVariables().map((v) => v.join('.')).join(', ')
    if (invalidVariables) {
      e.preventDefault()
      // prevent for from being sent with ajax
      // e.stopPropagation()
      Notificator.error(`${T.invalid_variables}: ${invalidVariables}`)
    } else {
      formatterInitializer.originalize()
    }
  })
}

function loadTinyMCE (item) {
  tinymce.init({
    selector: '.document-type-data-editor',
    fixed_toolbar_container: '#document_type_data_toolbar',
    inline: 'true',
    browser_spellcheck: true,
    auto_focus: true,
    paste_word_valid_elements: VALID_WORD_ELEMENTS,
    paste_remove_spans: true,
    paste_merge_formats: true,
    height: 350,
    theme: 'modern',
    width: '210mm',
    padding: '15px',
    relative_urls: false,
    protect: [
      /<% [a-z&_]+\.[a-z_]+\.[a-z]+ do \|[a-z]*\| %>/g,
      /<% @[a-z&_]+\.[a-z_]+\.[a-z]+ do \|[a-z]*\| %>/g,
      /<% [a-z]* %>/g,
    ],
    plugins: ['lists advlist image imagetools preview fullscreen table pagebreak visualblocks lineheight code paste'],
    toolbar1: 'undo redo | variables | showTest | fontselect fontsizeselect lineheightselect',
    toolbar2: 'alignleft aligncenter alignright alignjustify | underline bold italic | subscript superscript | bullist numlist outdent indent | pagebreak | fullscreen  | image',
    image_advtab: true,
    language: gon.localization.locale,
    images_upload_url: Routes.text_editor_document_template_path(),
    table_default_attributes: Utils.tinymceTableDefaults,
    table_default_styles: Utils.tinymceTableStyles,
    file_picker_types: 'image',
    fontsize_formats: '8px 9px 10px 11px 12px 13px 14px 15px 16px 17px 18px 24px 36px',
    lineheight_formats: '1 1.1 1.2 1.3 1.4 1.5 1.6 1.7 1.8 1.9 2.0',
    setup (editor) {
      $("span:contains('variables_1')").parent().addClass('mce-green')
      $('.mce-i-image').parent().addClass('mce-green')
      editor.addButton('variables', {
        text: t('variables_1'),
        id: 'add-var',
        icon: false,
        onclick: () => formatterInitializer.show(),
      })
      editor.addButton('showTest', {
        text: t('test_template_button_text'),
        id: 'show-test',
        tooltip: t('test_template'),
        icon: false,
        onclick () {
          loadTestTemplateData()
        },
      })

      editor.on('paste', () => {
        cleanUpEditor()
      })
    },
    init_instance_callback () {
      $('#add-var').find('button').addClass('mce-green')
      tmceLoaded = true
      if (variablesListLoaded) formatterInitializer.visualize()
      cleanUpEditor()
    },
    content_css: ['/tinymce_custom.css'],
    pagebreak_separator: '<div class="mce-pagebreak"></div>',
  }).then((editors) => {
    editors.forEach((editor) => {
      editor.on('FullscreenStateChanged', (e) => {
        const $body = $(editor.editorContainer.firstElementChild)
        const $editArea = $('.mce-edit-area', editor.editorContainer)

        if (e.state) {
          editor.editorContainer.style.height = '100%'
          $body.css({
            display: 'flex',
            'flex-direction': 'column',
            height: '100%',
          })
          $editArea.css({
            'max-height': `calc(100% - ${$editArea.offset().top}px)`,
            height: '100%',
          })
          editor.editorContainer.querySelector('iframe').style.height = '100%'
        } else {
          editor.editorContainer.style.height = 'auto'
          $body.css({
            display: 'block',
            height: 'auto',
          })
          $editArea.css({
            'max-height': 'initial',
            height: 'auto',
          })
        }
      })
    })
  })
}

function loadTinyMCEForSample (data) {
  tinymce.init({
    selector: 'textarea#show_test',
    browser_spellcheck: true,
    paste_word_valid_elements: VALID_WORD_ELEMENTS,
    width: '220mm',
    theme: 'modern',
    toolbar: 'undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent |  link image | customPrint',
    statusbar: false,
    menubar: false,
    branding: false,
    relative_urls: false,
    plugins: ['print image imagetools preview fullscreen table pagebreak visualblocks lineheight code'],
    image_advtab: true,
    language: gon.localization.locale,
    table_default_attributes: Utils.tinymceTableDefaults,
    table_default_styles: Utils.tinymceTableStyles,
    content_style: `
      .visible-print-inline-block {
        display: none !important;
      }

      @media print {
        .visible-print-inline-block {
          display: block !important;
        }
      }

      table, th, td { border-collapse: collapse; }

      p { font-size: 12px; }
    `.trim(),
    setup (editor) {
      editor.addButton('customPrint', {
        text: t('print'),
        /**
         * @param {PointerEvent} event
         * @returns {*}
         */
        onClick (event) {
          if (event.target.dataset.isCustomPrintClicked) { return }
          event.target.dataset.isCustomPrintClicked = 'true'

          const printHeader =
            document.querySelector('[name="document_type[print_header]"]')?.value === 'true'

          if (!printHeader) { return editor.execCommand('mcePrint') }

          const content = editor.getContent()
          const headerBody = document.querySelector('.print-area__clinic-header table')
          if (headerBody) {
            headerBody.style = 'border: none'
            headerBody.classList.add('visible-print-inline-block')
            headerBody.querySelectorAll('p').forEach((node) => {
              node.style = 'margin: 0'
            })
          }

          if (content.indexOf('visible-print-inline-block') === -1) {
            editor.setContent((headerBody?.outerHTML || '') + content)
          }

          setTimeout(() => {
            editor.execCommand('mcePrint')
            delete event.target.dataset.isCustomPrintClicked
          }, 500)
        },
      })
    },
  })
}

function loadTestTemplateData () {
  const id = $('#document_type_id').val()
  const htmlData = formatterInitializer.getOriginalContent()

  $.ajax({
    type: 'POST',
    url: Routes.show_test_template_documents_path(),
    data: {
      doc_id: id,
      templ_html: htmlData,
    },
    success (data) {
      $('#show_test').empty()
      if (tinymce.get('show_test')) {
        tinymce.get('show_test').remove()
      }
      if (data) {
        $('#show_test').val(data.data)
      } else {
        $('#show_test').val('')
      }
      $('#show_test_modal').modal('show')

      loadTinyMCEForSample(data)
    },
    error (err) {
      console.error(err)
    },
  })
}
