import Container from '@/plugins/dynamic_forms/_core/container/Container'
import { IReduxStore } from '@/plugins/dynamic_forms/declarations/IReduxStore'
import { IExtList } from '@/plugins/dynamic_forms/api/declarations/IProtocolList'

const fetch = window.fetch

class Api {
  container: Container

  constructor (container: Container) {
    this.container = container
  }

  getRelationCategories (categories) {
    const url =
      `/protocol_entities/assign_categories?${$.param({
        categories,
      })}`

    return fetch(url, {
      credentials: 'same-origin',
    }).then((response) => response.json())
  }

  getAssignDiagnosesCategories (categories) {
    const url =
      `/protocol_entities/mkb_categories?${$.param({
        categories,
      })}`

    return fetch(url, {
      credentials: 'same-origin',
    }).then((response) => response.json())
  }

  protocol_entities (type) {
    const url = this.container.get('config')[type].fetch

    return fetch(url, {
      credentials: 'same-origin',
    }).then((response) => response.json())
  }

  async extensibleList (listId: number) {
    const store: IReduxStore = this.container.get('store')

    const list: IExtList = store.getState().ext_list?.find(({ id }) => id === listId)
    if (!list?.value) { return [] }

    return this.__extractList(list.value)
  }

  fixedList (listId: number) {
    const store: IReduxStore = this.container.get('store')

    const list: IExtList = store.getState().fix_list?.find(({ id }) => id === listId)
    if (!list?.value) { return [] }

    return this.__extractList(list.value)
  }

  __extractList (value: IExtList['value']) {
    /* Клиники часто отделяют пустой строкой группы вариантов,
       для переменной, это равноценно новому элементу в массиве */
    return value.flat().filter(Boolean)
  }

  diseases (categoryId) {
    if (!categoryId) {
      categoryId = 0
    }
    const url = '/categories/diseases'

    return fetch(url, {
      credentials: 'same-origin',
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        category_type: 2,
        id: categoryId,
      }),
    }).then((response) => {
      if (response.ok) {
        return response.json()
      }

      return Promise.reject(response)
    })
  }

  /**
   * fetch entry attribute list
   */
  fetchEntryAttributes (url: string) {
    if (!url) {
      url = '/template_records/entry_attribute_list'
    }

    return fetch(url,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-CSRF-Token': (document.querySelector('meta[name="csrf-token"]') as HTMLMetaElement).content,
        },
        credentials: 'same-origin',
      }
    ).then((response) => {
      if (response.ok) {
        return response.json()
      }

      return Promise.reject(response)
    })
  }
}

export default Api
