<template>
  <div
    class="tooth-container"
    :data-number="toothNumber"
  >
    <div class="tooth-number">
      {{ toothNumber }}
    </div>

    <div
      v-tooltip="t(toothState)"
      class="tooth-statable tooth-image"
      :class="toothStateClass"
    />

    <div class="tooth-circle">
      <div class="tooth-circle-inner">
        <div
          v-for="pos in circlePositions"
          :key="pos"
        >
          <div
            v-tooltip="t(tooth[pos] || ETeethStates.normal)"
            class="tooth-circle-part tooth-statable"
            :class="[`tooth-state-${tooth[pos]}`, `tooth-circle-position-${pos}`]"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import { ITooth } from '@/_declarations/ITooth'
import { ETeethStates } from '@/_declarations/enums/ETeethStates'

export default defineComponent({
  name: 'ToothContainer',

  props: {
    toothNumber: { type: Number, required: true },
    tooth: { type: Object as PropType<ITooth>, default: () => ({}) },
  },

  data () {
    return {
      ETeethStates,
    }
  },

  computed: {
    circlePositions () {
      return ['center', 'top', 'right', 'left', 'bottom']
    },

    toothState () {
      return this.tooth.main || ETeethStates.normal
    },

    toothStateClass () {
      return `tooth-state-${this.toothState}`
    },
  },
})
</script>
