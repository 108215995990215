<template>
  <validation-wrapper
    :m-fixed-height="mFixedHeight"
    :errors="validationErrors"
    :class="{
      'mt-6': label
    }"
  >
    <div
      :id="id"
      v-tooltip="canManage.tooltip"
      class="m-buttons-group m-buttons-group_legacy"
    >
      <m-button
        v-for="item in adaptedItems"
        :key="`button:${item[valueKey]}`"
        v-tooltip="item.tooltip"
        :text="item[labelAttribute]"
        :disabled="item.disabled || canManage.disabled"
        :class="{
          'm-buttons-group_legacy__button_active': isActive(item),
          'hide-button-mb': hideButtonMb,
        }"
        :icon="item.icon"
        :type="buttonsType"
        :size="size"
        @click="onItemClick(item)"
      />

      <slot name="additional-button" />
    </div>

    <m-label
      v-if="label"
      :id="id"
      :label="label"
      :required="isRequiredField && !requiredWhenValue"
      :disabled="disabled"
      :focused="focused"
      class="m-buttons-group_label"
    />
  </validation-wrapper>
</template>

<script>
import MButton from '@/vue_present/_base/buttons/MButton/MButton'
import { MButtonsGroupPropsMixin } from '@/vue_present/_base/buttons/MButtonsGroup/MButtonsGroupPropsMixin'
import ValidationWrapper from '@/vue_components/common/validation_wrapper'
import { ValidationChildMixin } from '@/vue_present/mixins/ValidationChildMixin'
import { DisabledMixin } from '@/vue_present/mixins/DisabledMixin'
import MLabel from '@/vue_present/_base/inputs/MLabel'
import { CommonInputProps } from '@/vue_present/_base/inputs/mixins/CommonInputProps'
import { CommonInputMethods } from '@/vue_present/_base/inputs/mixins/CommonInputMethods'
import { DEFAULT_RESULT_TYPES } from '@/vue_present/_base/buttons/MButtonsGroup/MButtonsGroupsConst'

export default {
  name: 'MButtonsGroup',

  components: { MLabel, ValidationWrapper, MButton },

  mixins: [
    MButtonsGroupPropsMixin,

    CommonInputProps,
    CommonInputMethods,
    ValidationChildMixin,
    DisabledMixin,
  ],

  model: {
    event: 'change',
    prop: 'value',
  },

  data () {
    return {
      activeItems: new Set(),
      mappedItems: {},
      focused: false,
    }
  },

  computed: {
    adaptedItems () {
      return (this.items || []).map(this.__adaptData)
    },

    adaptedValue () {
      if (!this.value) { return [] }
      if (Array.isArray(this.value)) { return this.value.map(this.__adaptData) }

      return [this.__adaptData(this.value)]
    },

    valueIds () {
      return this.adaptedValue.map((item) => item[this.valueKey])
    },
  },

  watch: {
    adaptedItems: {
      immediate: true,
      handler (to) {
        this.mappedItems = Utils.arrayToMap(to, this.valueKey)
      },
    },

    adaptedValue (to = []) {
      this.resetActiveItems(to)
    },
  },

  created () {
    this.resetActiveItems(this.adaptedValue)
  },

  methods: {
    resetActiveItems (to = []) {
      this.activeItems = new Set(to.map((item) => item[this.valueKey]))
    },

    __adaptData (item) {
      const isStringOrNumber = typeof item === 'number' || typeof item === 'string'
      if (isStringOrNumber) {
        return {
          [this.valueKey]: item,
          [this.labelAttribute]: this.useTScope || this.useTScope === ''
            ? t(item, { scope: Utils.ternary(this.useTScope !== true, this.useTScope) })
            : item,
        }
      }

      return item
    },

    onItemClick (item) {
      const value = item[this.valueKey]

      if (this.unselect && this.value?.[this.valueKey] === value) {
        this.$updateSync('value', null)
        this.$emit('change', null)

        return
      }

      if (this.radio) {
        this.__radioCase(value)

        return
      }

      this.activeItems.has(value)
        ? this.__minCase(value)
        : this.__maxCase(value)
    },

    __radioCase (value) {
      this.activeItems = new Set([value])
      this.buildNewValue()
    },

    __minCase (value) {
      if (this.min && this.activeItems.size <= this.min) { return }
      this.activeItems.delete(value)
      this.buildNewValue()
    },

    __maxCase (value) {
      if (this.max && this.activeItems.size >= this.max) { return }
      this.activeItems.add(value)
      this.buildNewValue()
    },

    isActive (item) {
      return this.valueIds.includes(item[this.valueKey])
    },

    __createCustomResult (items = []) {
      if (this.radio) {
        const item = items[0] || { [this.valueKey]: null }
        switch (this.useCustomResult) {
          case DEFAULT_RESULT_TYPES.SIMPLE: return item[this.valueKey]
          default: return item
        }
      }

      switch (this.useCustomResult) {
        case DEFAULT_RESULT_TYPES.SIMPLE: return items.map((item) => item[this.valueKey])
        default: return items
      }
    },

    buildNewValue () {
      const items = Array.from(this.activeItems).map((id) => this.mappedItems[id])
      const newValue = this.useCustomResult
        ? this.__createCustomResult(items)
        : items

      this.$updateSync('value', newValue)
      this.$emit('change', newValue)
    },
  },
}
</script>
