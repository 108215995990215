import { IPrintArea } from '@/vue_apps/ProtocolsApp/entities/printAreas/IPrintArea'

export class PrintAreasTransformer {
  static readonly PRINT_AREA_START = '.print_area_start'

  static readonly PRINT_AREA_END = '.print_area_end'

  private html: string

  /**
   * @type {JQuery<HTMLElement>}
   * @private
   */
  private $body

  constructor (html: string) {
    this.html = html
    this.$body = $(`<div>${html}</div>`)
  }

  transform (printAreas: IPrintArea[]) {
    if (!printAreas.length) { return this.html }

    return printAreas.reduce((acc, { id }) => {
      const $start = this.$body.find(`${PrintAreasTransformer.PRINT_AREA_START}[data-id="${id}"]`)
      const $end = this.$body.find(`${PrintAreasTransformer.PRINT_AREA_END}[data-id="${id}"]`)

      return acc + this.__getSafeHtml($start) + this.$body
        .find($start)
        .nextUntil($end)
        .toArray()
        .reduce((acc, { outerHTML }) => acc + outerHTML, '') + this.__getSafeHtml($end)
    }, '')
  }

  __getSafeHtml ($html?: any) {
    return $html?.[0]?.outerHTML || ''
  }
}
