<template>
  <m-panel
    class="fiscal-printer"
    icon="cashRegister"
    :title="title"
  >
    <fiscal-printer-k-k-m />

    <fiscal-printer-acquiring-terminal />

    <m-button-back />
  </m-panel>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import MPanel from '@/vue_present/_base/MPanel/MPanel.vue'
import FiscalPrinterAcquiringTerminal
  from '@/vue_apps/catalogs_root/Workplaces/components/FiscalPrinter/FiscalPrinterAcquiringTerminal.vue'
import FiscalPrinterKKM from '@/vue_apps/catalogs_root/Workplaces/components/FiscalPrinter/FiscalPrinterKKM.vue'
import MButtonBack from '@/vue_present/_base/buttons/MButtonBack/MButtonBack.vue'

export default defineComponent({
  name: 'WorkplacesCatalogFiscalPrinter',

  components: {
    FiscalPrinterAcquiringTerminal,
    FiscalPrinterKKM,
    MButtonBack,
    MPanel,
  },

  computed: {
    workplaceId (): string {
      return this.$route.params.workplaceId
    },

    title () {
      const title = this.$route.params.workplaceTitle || gon.specific.workplace.title

      return `${t('workplace')} "${title}"`
    },
  },
})
</script>
