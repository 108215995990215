import { MListServiceNestedItemsItem } from '@/_api/_requests/MListServiceNestedItemsItem'
import {
  IPrintRawReceiptPaymentItem,
} from '@/vue_apps/catalogs_root/Workplaces/interfaces/PrintRawReceipt/IPrintRawReceiptPaymentItem'
import { ICatalog } from '@/_declarations/ICatalog'

export class PrintRawReceiptPaymentItem
  extends MListServiceNestedItemsItem<number>
  implements IPrintRawReceiptPaymentItem {
  kind: ICatalog<string> = null

  sum: number = 0
}
