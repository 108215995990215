import { IElTreeStore } from '@/_declarations/IElTreeStore'
import { LazyTreeNode } from '@/vue_present/Reuse/LazyTree/store/LazyTreeNode'
import { defaultStringSorting } from '@/helpers/lambda'

export const sortElTreeNode =
  (nodesMap: IElTreeStore['nodesMap'], parentNode: LazyTreeNode) => {
    const treeParentNode = nodesMap[parentNode.id]
    if (!treeParentNode) { return }

    // @ts-ignore
    const childNodes = (treeParentNode.childNodes) || []

    const childCatalogs = childNodes
      .filter((node) => !node.data.isLeaf)
      .sort((a, b) => defaultStringSorting(a.data.title, b.data.title))

    const childItems = childNodes
      .filter((node) => node.data.isLeaf)
      .sort((a, b) => defaultStringSorting(a.data.title, b.data.title))

    // @ts-ignore
    treeParentNode.childNodes = [
      ...childCatalogs,
      ...childItems,
    ]
  }
