<template>
  <m-draggable-window
    class="clinical-guideline"
    icon="clinicalGuideline"
    :visibility.sync="visibility"
    :title="t$('title')"
    :body-loading="loading"
    :draggable-area-data-id="DRAGGABLE_AREA"
    pub-sub-event-name="ClinicalGuidelines"
    position-cache-key="protocolModalPosition"
  >
    <m-prompt-notice
      v-if="diseaseIds?.length"
      class="mb-indent-mid"
      :text="t$('diseasePromptNotice')"
      type="alert"
    />

    <div class="flex gap-indent-small align-items-center">
      <reusable-clinical-guidelines-list
        v-if="diseaseIds"
        :value="clinicalGuidelineListItem"
        :label="t('title')"
        :disease-ids="diseaseIds"
        full-width
        @changeByLazy="onClinicalGuidelineListItemChange"
      />

      <m-icon
        v-if="clinicalGuideline?.id"
        v-tooltip="t$('catalog.ministryUrlTooltip')"
        class="pointer mb-indent-validation"
        icon="link"
        color="primary"
        @click="$utils.openInNewTab(clinicalGuideline.ministryUrl)"
      />
    </div>

    <clinical-guideline-collapse
      v-if="clinicalGuideline?.id"
      :clinical-guideline="clinicalGuideline"
    />

    <m-not-found-results
      v-else
      small
    />
  </m-draggable-window>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { i18nScopeMixin } from '@/vue_present/mixins/i18nScopeMixin'
import ReusableClinicalGuidelinesList
  from '@/vue_present/Reuse/Lists/ClinicalGuidelines/ReusableClinicalGuidelinesList.vue'
import {
  IClinicalGuidelineListItem,
} from '@/vue_present/Reuse/Lists/ClinicalGuidelines/interfaces/IClinicalGuidelineListItem'
import {
  ClinicalGuidelineApi,
} from '@/vue_apps/catalogs_root/ClinicalGuidelinesCatalog/classes/ClinicalGuideline/ClinicalGuidelineApi'
import { SpinnerHolder } from '@/vue_components/mixins/spinner_holder'
import ClinicalGuidelineCollapse from '@/vue_apps/ClinicalGuidelines/components/ClinicalGuidelineCollapse.vue'
import MNotFoundResults from '@/vue_present/_base/Tables/MNotFoundResults/MNotFoundResults.vue'
import MIcon from '@/vue_present/_base/MIcon/MIcon.vue'
import MDraggableWindow from '@/vue_present/_base/MDraggableWindow/MDraggableWindow.vue'
import {
  DRAGGABLE_AREA,
} from '@/plugins/dynamic_forms/components/context_menu/editor_mode/ui/TextareaProxyRoot/draggable'
import { getReduxStore } from '@/specific/entries/utils'
import MPromptNotice from '@/vue_present/_base/MPromptNotice/MPromptNotice.vue'

export default defineComponent({
  name: 'ClinicalGuidelinesApp',

  components: {
    MPromptNotice,
    MDraggableWindow,
    MIcon,
    MNotFoundResults,
    ReusableClinicalGuidelinesList,
    ClinicalGuidelineCollapse,
  },

  mixins: [
    i18nScopeMixin,
    SpinnerHolder,
  ],

  data () {
    return {
      DRAGGABLE_AREA,
      visibility: false,
      clinicalGuidelineListItem: null,
      clinicalGuideline: null,
      diseaseIds: null,
    }
  },

  mounted () {
    this.setI18nScope('clinicalGuidelines')
    this.subscribes()
  },

  methods: {
    subscribes () {
      this.$pubSub.subscribe('ProtocolPopoverConnector:Activate', () => {
        this.visibility = false
      })

      this.$pubSub.subscribe('MDraggableWindow:ClinicalGuidelines:Open', () => {
        this.fetchDiagnoses()
      })
    },

    onClinicalGuidelineListItemChange (value: IClinicalGuidelineListItem) {
      this.clinicalGuidelineListItem = value

      if (!value) {
        this.clinicalGuideline = null

        return
      }

      this.clinicalGuideline = new ClinicalGuidelineApi()
      this.withSpinner(this.clinicalGuideline.fetch(value.id))
    },

    async fetchDiagnoses () {
      this.diseaseIds = null

      await this.$nextTick()

      const reduxStore = getReduxStore()
      this.diseaseIds = reduxStore
        ? reduxStore
          .getState()
          .diagnoses
          .map((diagnosis) => diagnosis.item.id)
        : []
    },
  },
})
</script>
