<template>
  <div
    v-loading="loading"
    class="clinical-guidelines-catalog-editor"
  >
    <clinical-guidelines-catalog-editor-item
      :clinical-guideline="clinicalGuideline"
      @registerValidator="onRegisterValidator"
    />

    <div
      v-if="$security.canManageClinicalGuideline"
      class="flex gap-indent-small mb-indent"
    >
      <m-button-submit
        :disabled="loading"
        @click="onSubmit"
      />

      <m-button-delete
        v-if="!isNewMode"
        :popover-message="t('clinicalGuidelines.catalog.destroyPopoverMessage')"
        :disabled="loading"
        use-button
        @yes="onDestroy"
      />
    </div>

    <clinical-guidelines-catalog-editor-entries
      v-if="!isNewMode"
      :clinical-guideline="clinicalGuideline"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import ClinicalGuidelinesCatalogEditorItem
  from '@/vue_apps/catalogs_root/ClinicalGuidelinesCatalog/components/ClinicalGuidelinesCatalogEditorItem.vue'
import {
  ClinicalGuidelineApi,
} from '@/vue_apps/catalogs_root/ClinicalGuidelinesCatalog/classes/ClinicalGuideline/ClinicalGuidelineApi'
import MButtonSubmit from '@/vue_present/_base/buttons/MButtonSubmit/MButtonSubmit.vue'
import { ValidationParentMixin } from '@/vue_present/mixins/ValidationParentMixin'
import ClinicalGuidelinesCatalogEditorEntries
  from '@/vue_apps/catalogs_root/ClinicalGuidelinesCatalog/components/ClinicalGuidelinesCatalogEditorEntries.vue'
import MButtonDelete from '@/vue_present/_base/buttons/MButtonDelete/MButtonDelete.vue'
import {
  CLINICAL_GUIDELINES_CATALOG_ROUTE_NAMES,
} from '@/vue_apps/catalogs_root/ClinicalGuidelinesCatalog/router/clinicalGuidelinesCatalogRouteNames'
import { SpinnerHolder } from '@/vue_components/mixins/spinner_holder'
import { RouterModeMixin } from '@/vue_present/mixins/RouterModeMixin'

export default defineComponent({
  name: 'ClinicalGuidelinesCatalogEditor',

  components: {
    MButtonDelete,
    ClinicalGuidelinesCatalogEditorEntries,
    MButtonSubmit,
    ClinicalGuidelinesCatalogEditorItem,
  },

  mixins: [
    ValidationParentMixin,
    SpinnerHolder,
    RouterModeMixin,
  ],

  beforeRouteEnter (to, from, next) {
    next((vm: any) => {
      vm.onRouteChange(to.params.id)
    })
  },

  beforeRouteUpdate (to, from, next) {
    this.onRouteChange(to.params.id)

    // TODO VUE 3: убрать next() там он не нужОн
    next()
  },

  props: {
    clinicalGuideline: ClinicalGuidelineApi,
  },

  emits: ['close'],

  methods: {
    async onRouteChange (id?: string) {
      this.resetValidations()
      if (!id) { return }
      await this.$nextTick()
      await this.withSpinner(this.clinicalGuideline.fetch(Number(id)))
    },

    async onSubmit () {
      if (this.hasErrors()) { return }
      const originCategory = this.clinicalGuideline.originCategory
      const { id } = await this.withSpinnerSimple(this.clinicalGuideline.submit())
      if (!id) { return }

      const pubSubPayload = {
        ...this.clinicalGuideline.getAttributes(),
        originCategory,
      }

      this.isEditMode
        ? this.$pubSub.emit('clinicalGuidelineUpdate', pubSubPayload)
        : this.$pubSub.emit('clinicalGuidelineCreate', pubSubPayload)

      if (this.isEditMode) { return }
      await this.$router.replace({ name: CLINICAL_GUIDELINES_CATALOG_ROUTE_NAMES.EDIT, params: { id } })
    },

    async onDestroy () {
      const hasErrors = await this.withSpinner(this.clinicalGuideline.destroy())
      if (hasErrors) { return }
      this.$pubSub.emit('clinicalGuidelineDestroy', this.clinicalGuideline.id)
      this.$emit('close')
    },
  },
})
</script>
