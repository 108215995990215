<template>
  <div
    :class="modeClass"
    class="flex flex-column align-center"
  >
    <m-switch
      v-model="teethMode"
      :inactive-text="t(ETeeth.adult)"
      :active-text="t(ETeeth.kids)"
      :inactive-value="ETeeth.adult"
      :active-value="ETeeth.kids"
      :inactive-color="inactiveColor"
      class="mr-10 mb-indent-mid"
    />

    <div class="dent-container dental-card">
      <div class="dent-upper-jaw">
        <div class="dent-left">
          <div :class="toothWrapperClass">
            <tooth-container
              v-for="toothNumber in teethMap.topLeft"
              :key="toothNumber"
              :tooth="dentalCard[toothNumber]"
              :tooth-number="toothNumber"
            />
          </div>
        </div>
        <div class="dent-right">
          <div :class="toothWrapperClass">
            <tooth-container
              v-for="toothNumber in teethMap.topRight"
              :key="toothNumber"
              :tooth="dentalCard[toothNumber]"
              :tooth-number="toothNumber"
            />
          </div>
        </div>
      </div>
      <div class="dent-lower-jaw">
        <div class="dent-left">
          <div :class="toothWrapperClass">
            <tooth-container
              v-for="toothNumber in teethMap.bottomLeft"
              :key="toothNumber"
              :tooth="dentalCard[toothNumber]"
              :tooth-number="toothNumber"
            />
          </div>
        </div>
        <div class="dent-right">
          <div :class="toothWrapperClass">
            <tooth-container
              v-for="toothNumber in teethMap.bottomRight"
              :key="toothNumber"
              :tooth="dentalCard[toothNumber]"
              :tooth-number="toothNumber"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import MSwitch from '@/vue_present/_base/inputs/MSwitch/MSwitch.vue'
import { ITooth } from '@/_declarations/ITooth'
import ToothContainer from '@/vue_apps/MedicalRecords/DentalCard/ToothContainer.vue'
import { ETeeth } from '@/_declarations/enums/ETeeth'
import { colorsMap } from '@/modules/utils/medods_ui/colorsMap'

export default defineComponent({
  name: 'DentalCard',

  components: { ToothContainer, MSwitch },

  props: {
    dentalCard: { type: Object as PropType<Record<string, ITooth>>, required: true },
    mode: { type: String as PropType<'show' | 'edit'>, default: 'show' },
  },

  data () {
    return {
      teethMode: ETeeth.adult,
      ETeeth,
    }
  },

  computed: {
    inactiveColor () {
      return colorsMap.primary
    },

    modeClass () {
      return `dental-map-${this.mode}`
    },

    toothWrapperClass () {
      return this.teethMode === ETeeth.adult
        ? 'dent-adult'
        : 'dent-kid'
    },

    teethMap () {
      if (this.teethMode === ETeeth.adult) {
        return {
          topLeft: [18, 17, 16, 15, 14, 13, 12, 11],
          topRight: [21, 22, 23, 24, 25, 26, 27, 28],
          bottomLeft: [48, 47, 46, 45, 44, 43, 42, 41],
          bottomRight: [31, 32, 33, 34, 35, 36, 37, 38],
        }
      }

      return {
        topLeft: [55, 54, 53, 52, 51],
        topRight: [61, 62, 63, 64, 65],
        bottomLeft: [85, 84, 83, 82, 81],
        bottomRight: [71, 72, 73, 74, 75],
      }
    },
  },
})
</script>
