
export enum EApplicationBuilderType {
  backend = 'backend',
  frontend = 'frontend',
  templateRec = 'templateRec',
  documentRec = 'documentRec',
  test = 'test',
  complexMember = 'complexMember',
  templates = 'templates'
}
