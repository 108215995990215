import { CatalogHelpers } from '@/helpers/CatalogHelpers'

export enum CLINICAL_GUIDELINE_COLLAPSE_ITEM {
  META_INFORMATION = 'metaInformation',
  ANAMNESIS = 'anamnesis',
  DIAGNOSTICS = 'diagnostics',
  TREATMENT = 'treatment',
  MEDICAL_REHABILITATION = 'medicalRehabilitation',
  PREVENTION_AND_DISPENSARY_OBSERVATION = 'preventionAndDispensaryObservation',
}

export const clinicalGuidelineCollapseItems = CatalogHelpers.getCatalogItemsFromEnum(
  CLINICAL_GUIDELINE_COLLAPSE_ITEM,
  'clinicalGuidelines.collapseItem'
)
