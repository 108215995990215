import { paymentAdapter } from '@/vue_apps/FinanceModule/FinanceModuleIndex/api/paymentAdapter'
import {
  IAcquiringAdapterData,
} from '@/vue_apps/FinanceModule/FinanceModuleIndex/interfaces/Acquiring/IAcquiringAdapterData'

export const acquiringAdapter = {
  toServer (data: IAcquiringAdapterData) {
    return {
      ...data,
      payment: paymentAdapter.toServer(data.payment),
    }
  },
}
