import EntryEntity from '@/plugins/dynamic_forms/entities/items/entry_entity'
import { IEntityConfig } from '@/plugins/dynamic_forms/declarations/IEntityConfig'

export class EntryEntityWithDefaultHtml extends EntryEntity {
  constructor (id, element, options: IEntityConfig, container = false) {
    super(id, element, options, container)

    if (gon.page.controller === 'template_records') { return }

    this.element.oncontextmenu = (e) => {
      e.preventDefault()
      Services.pubSub.emit('ProtocolPopoverConnector:Activate', this)
    }

    this.element.onclick = this.element.oncontextmenu
  }
}
