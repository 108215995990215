import { store } from '@/vue_components/store/store'
import { WorkplaceChannelService } from '@/services/WorkplaceChannelService'

class Workplace {
  _workplaceChannelService = new WorkplaceChannelService()

  init () {
    this.subscribeToWebSockets()
    this._workplaceChannelService.init()
  }

  subscribeToWebSockets () {
    this.subscription = Services.wsSubscriptions.user.advancedConnection({
      received: this.receiveMessage,
      connected: this.onConnected.bind(this),
    })
  }

  receiveMessage (msg) {
    if (msg.meta.type === 'CLINIC_SWITCH.CHANGED_CURRENT_CLINIC') {
      Services.clinicSwitch.enqueueRefresh(
        msg.data.changed_current_clinic_id,
        gon.application.current_clinic.id
      )
    }

    Services.pubSub.emitAsync(`workplace.${msg.meta.type}`, msg)
  }

  baseMessage (type, params = {}) {
    return {
      meta: {
        type,
      },
      ...params,
    }
  }

  onConnected () {
    store.commit('wsUserChannel/SET_IS_HANDSHAKE_COMPLETE', true)
  }

  sendMessage (messageType, params = {}) {
    this.subscription.send(this.baseMessage(messageType, params))
  }

  printReport (type, workplaceId = null, params = {}) {
    if (workplaceId) {
      const data = {
        type,
        workplace_id: workplaceId,
        ...params,
      }

      return this.sendMessage('print_report', { data, workplace_id: workplaceId })
    }

    return this.sendMessage('print_report', { data: { type, ...params } })
  }
}

const instance = new Workplace()

export default {
  init: () => instance.init(),
  printReport: (type, workplaceId, params) => instance.printReport(type, workplaceId, params),
  sendMessage: (msgType, params) => instance.sendMessage(msgType, params),
}
