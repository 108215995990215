import { ICRUDReturn } from '@/_api/decorators/api/interfaces/ICRUDReturn'
import { Api } from '@/_api/decorators/api/Api'
import { IList } from '@/_declarations/IList'
import { IMKB10Item } from '@/vue_present/Reuse/Lists/MKB10/api/interfaces/IMKB10Item'
import { IListServiceBaseParams } from '@/_declarations/IListServiceBaseParams'

export class MKB10Api {
  @Api.notify('listMessage', 'diagnoses', { success: false })
  @Api.list(Routes.search_api_internal_diseases_path)
  fetch (payload: IListServiceBaseParams) {
    return {
      payload,
    } as ICRUDReturn as Promise<IList<IMKB10Item>>
  }
}
