<template>
  <div class="protocol-fixed-list-view flex gap-indent-small">
    <m-select
      v-model="valueProxy"
      v-tooltip="valueProxy?.title"
      :items="items"
      :placeholder="t('enums.unselected.it')"
      :m-fixed-height="false"
      full-width
    />

    <m-button
      v-tooltip="t('clear')"
      icon="clear"
      @click="valueProxy = null"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import MSelect from '@/vue_present/_base/inputs/MSelect/MSelect.vue'
import Entity from '@/plugins/dynamic_forms/entities/items/Entity'
import {
  createTreeFromArray,
} from '@/plugins/dynamic_forms/components/context_menu/editor_mode/ui/TextareaProxyRoot/logic'
import MButton from '@/vue_present/_base/buttons/MButton/MButton.vue'

export default defineComponent({
  name: 'ProtocolFixedListView',

  components: { MButton, MSelect },

  props: {
    entity: { type: Object as PropType<Entity>, required: true },
    value: { type: [Object, String], default: null },
  },

  data () {
    return {
      items: [],
    }
  },

  computed: {
    valueProxy: {
      get () { return this.value },
      set (value) {
        this.$updateSync('value', value)
        this.entity.setValue(value)
      },
    },
  },

  created () {
    this.items = createTreeFromArray(this.entity.container.get('api').fixedList(this.entity.id))
    this.valueProxy = this.items.find(({ title }) => title === this.entity.element.innerText.trim())
  },
})
</script>
