import DocumentTypeModal from '../components/modal/document_type_modal'
import { registerListeners } from '../listeners'

import CategoriesWrap from '../components/common/document_type_catogories_wrap'
import BaseTemplateBuilder from './BaseTemplateBuilder'
import ContextMenu from '../components/context_menu/constructor_mode_context_menu'
import { entityConfigs } from '../configuration/main_config'
import { getMap } from '../configuration/mapping'
import EntityManager from '../entities/EntityManager'

export class DocumentTypeBuilder extends BaseTemplateBuilder {
  setMapping () {
    const customMap = {
      appId: 'document_type_constuctor_mode',
      newItemButton: document.querySelector('#add-item'),
      form: document.querySelector('#document_type_form'),
      categoryIdInput: document.querySelector('#document_type_category_id'),
      categoryTreeContainer: document.querySelector('#category'),
      printHeader: document.querySelector('#document_type_print_header'),
    }

    const baseMap = getMap('backend')
    this.map = Object.assign({}, baseMap, customMap)
    this.panelMap = getMap('panel')
    this.categoriesMap = {
      autoRun: true,
      category: $('#category'),
      addCategoryButton: $('#add-category'),
      search: $('#document_types_catalog_search'),
    }
  }

  initComponents () {
    const entityManager = new EntityManager(this.container)
    registerListeners(this.container)
    this.container.add('entity_manager', entityManager)
    this.container.add('contextMenu', new ContextMenu(this.map, this))
    this.container.add('config', entityConfigs)
    this.addComponent('categories', new CategoriesWrap(this.categoriesMap, this))
    this.addComponent('modal', new DocumentTypeModal(this.map, this))
  }

  create () {
    return this
  }
}
