<template>
  <m-popover
    :cancel-text="t('close')"
    :confirm-text="t('save')"
    yes-type="warning"
    cancel-type="primary"
    reverse-buttons-order
    inherited-visibility
    :visibility.sync="visibility"
    @hide="onHide"
    @yes="onYes"
    @no="close"
  >
    <template #message>
      <span class="mb-indent">
        {{ t$('changeReleaseStatusPopoverTitle') }}
      </span>
    </template>

    <template #body>
      <m-select
        :value="releaseStatus"
        :label="t('status')"
        :items="CLINICAL_GUIDELINE_RELEASE_STATUS_ITEMS"
        required
        full-width
        validator-name="releaseStatus"
        @registerValidator="onRegisterValidator"
        @change="releaseStatus = $event"
      />
    </template>

    <template #reference>
      <m-button
        icon="change"
        type="warning"
        :text="t('change_status')"
        :tooltip="t$('changeReleaseStatusPopoverTooltip')"
        :disabled="disabled"
      />
    </template>
  </m-popover>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import MButton from '@/vue_present/_base/buttons/MButton/MButton.vue'
import MPopover from '@/vue_present/_base/MPopover/MPopover.vue'
import MSelect from '@/vue_present/_base/inputs/MSelect/MSelect.vue'
import { ValidationParentMixin } from '@/vue_present/mixins/ValidationParentMixin'
import {
  CLINICAL_GUIDELINE_RELEASE_STATUS_ITEMS,
} from '@/vue_apps/catalogs_root/ClinicalGuidelinesCatalog/consts/clinicalGuidelineReleaseStatus'
import { i18nScopeMixin } from '@/vue_present/mixins/i18nScopeMixin'

export default defineComponent({
  name: 'ClinicalGuidelinesCatalogTreeChangeReleaseStatusPopover',

  components: {
    MSelect,
    MPopover,
    MButton,
  },

  mixins: [
    i18nScopeMixin,
    ValidationParentMixin,
  ],

  props: {
    disabled: Boolean,
  },

  emits: ['onReleaseStatusChange'],

  data () {
    return {
      visibility: false,
      CLINICAL_GUIDELINE_RELEASE_STATUS_ITEMS,
      releaseStatus: null,
    }
  },

  created () {
    this.setI18nScope('clinicalGuidelines.catalog')
  },

  methods: {
    onHide () {
      this.resetValidations()
    },

    onYes () {
      if (this.hasErrors()) { return }
      this.$emit('onReleaseStatusChange', this.releaseStatus)
      this.close()
    },

    close () {
      this.visibility = false
    },
  },
})
</script>
