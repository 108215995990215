
export enum EWSChannels {
  ANALYSIS = 'analysis',
  APPOINTMENT = 'appointment',
  CALLS = 'calls',
  CLIENT = 'client',
  CLINICAL_GUIDELINES = 'clinicalGuidelines',
  CLINIC_WORKLOAD = 'clinicWorkload',
  EGISZ = 'egisz',
  EMK_LAST = 'emkLast',
  ENTRY = 'entry',
  ENTRY_TYPE = 'entryType',
  EXPORT = 'export',
  FEEDBACK = 'feedback',
  GENERAL = 'general',
  IP = 'ip',
  NOTIFICATION = 'notification',
  REMINDER = 'reminder',
  REPORT = 'report',
  TRADE_ACQUIRING = 'tradeAcquiring',
  USER = 'user',
  WAITING_LIST = 'waitingList',
  WAZZUP = 'wazzup',
  WORK_TIME = 'workTime',
  WORKPLACE = 'workplace',
}
