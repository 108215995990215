import { Api } from '@/_api/decorators/api/Api'
import { ICRUDReturn } from '@/_api/decorators/api/interfaces/ICRUDReturn'
import { TStringNumber } from '@/_declarations/TStringNumber'
import {
  ACQUIRING_MANUAL_TRANSACTION_STATUS,
} from '@/vue_apps/FinanceModule/FinanceModuleIndex/consts/Acquiring/AcquiringManualTransactionStatusItems'
import {
  IAcquiringTransaction,
} from '@/vue_apps/FinanceModule/FinanceModuleIndex/interfaces/Acquiring/IAcquiringTransaction'
import { IError } from '@/_declarations/IError'
import {
  ITradeAcquiringConfigurationToClientDTO,
} from '@/vue_apps/app_configuration/tradeAcquiring/interfaces/ITradeAcquiringConfigurationToClientDTO'

export class AcquiringPresenter {
  @Api.notify('updateMessage', 'transaction')
  @Api.post(Routes.force_complete_api_internal_acquiring_trade_transaction_path)
  forceComplete (idTransaction: TStringNumber, manualOperationType: ACQUIRING_MANUAL_TRANSACTION_STATUS) {
    return {
      routeArgs: [idTransaction],
      payload: {
        status: manualOperationType,
      },
    } as ICRUDReturn as Promise<IAcquiringTransaction | IError>
  }

  @Api.get(Routes.api_internal_configurations_acquiring_path)
  fetchAcquiringConfigurations () {
    return {} as ICRUDReturn as Promise<ITradeAcquiringConfigurationToClientDTO | IError>
  }
}
