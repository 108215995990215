<template>
  <div class="clinical-guidelines-catalog-tree">
    <div class="clinical-guidelines-catalog-tree__search-container">
      <m-button
        v-if="$security.canManageClinicalGuideline"
        v-tooltip="t$('tooltip')"
        icon="clinicalGuideline"
        type="success"
        plus-icon
        @click="onAdd"
      />

      <reusable-clinical-guidelines-list
        full-width
        @changeByLazy="openEditForm($event.id)"
      />
    </div>

    <reusable-lazy-tree
      v-if="showTree"
      class="mb-indent-mid flex-grow-1"
      :tree="tree"
      :use-root-node="{ title: t$('title') }"
      :allow-drag="allowDrag"
      :allow-drop="allowDrop"
      :node-drop="nodeDrop"
      :show-checkboxes="$security.canManageClinicalGuideline"
      draggable
      @setTreeRef="treeRef = $event"
      @onNodeClick="onNodeClick"
      @onCheckChange="onCheckChange"
    >
      <template #default="{ node, data }">
        <clinical-guidelines-catalog-tree-node
          :node="node"
          :data="data"
        />
      </template>
    </reusable-lazy-tree>

    <clinical-guidelines-catalog-tree-actions
      v-if="treeRef && $security.canManageClinicalGuideline"
      :clinical-guideline="clinicalGuideline"
      :tree-ref="treeRef"
      :disabled="!hasCheckedNotes"
      @setLoading="$emit('setLoading', $event)"
      @resetCheckedNotes="onResetCheckedNotes"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import ReusableLazyTree from '@/vue_present/Reuse/LazyTree/ReusableLazyTree.vue'
import { LazyTreeNode } from '@/vue_present/Reuse/LazyTree/store/LazyTreeNode'
import {
  ClinicalGuidelineTreeApi,
} from '@/vue_apps/catalogs_root/ClinicalGuidelinesCatalog/api/ClinicalGuidelineTreeApi'
import { i18nScopeMixin } from '@/vue_present/mixins/i18nScopeMixin'
import MButton from '@/vue_present/_base/buttons/MButton/MButton.vue'
import ReusableClinicalGuidelinesList
  from '@/vue_present/Reuse/Lists/ClinicalGuidelines/ReusableClinicalGuidelinesList.vue'
import {
  CLINICAL_GUIDELINES_CATALOG_ROUTE_NAMES,
} from '@/vue_apps/catalogs_root/ClinicalGuidelinesCatalog/router/clinicalGuidelinesCatalogRouteNames'
import LazyTree from '@/vue_present/Reuse/LazyTree/store/LazyTree'
import { extractItemId } from '@/vue_present/Reuse/DocumentTree/store/extractItemId'
import {
  clinicalGuidelineTreeAllowDropLevel,
  clinicalGuidelineTreeExtraAttributes,
} from '@/vue_apps/catalogs_root/ClinicalGuidelinesCatalog/consts/consts'
import {
  ClinicalGuidelineApi,
} from '@/vue_apps/catalogs_root/ClinicalGuidelinesCatalog/classes/ClinicalGuideline/ClinicalGuidelineApi'
import { ICatalog } from '@/_declarations/ICatalog'
import { getLazyTreeLeafNodeId } from '@/vue_present/Reuse/LazyTree/store/lazyTreeLeafNodeIdPrefix'
import {
  ClinicalGuidelineModel,
} from '@/vue_apps/catalogs_root/ClinicalGuidelinesCatalog/classes/ClinicalGuideline/ClinicalGuidelineModel'
import ClinicalGuidelinesCatalogTreeActions
  from '@/vue_apps/catalogs_root/ClinicalGuidelinesCatalog/components/ClinicalGuidelinesCatalogTreeActions.vue'
import ClinicalGuidelinesCatalogTreeNode
  from '@/vue_apps/catalogs_root/ClinicalGuidelinesCatalog/components/ClinicalGuidelinesCatalogTreeNode.vue'
import { TLazyTreeRef } from '@/vue_present/Reuse/LazyTree/interfaces/types'
import { appendLazyTreeNode } from '@/vue_present/Reuse/LazyTree/helpers/appendLazyTreeNode'
import { updateLazyTreeNodeById } from '@/vue_present/Reuse/LazyTree/helpers/updateLazyTreeNodeById'
import { sortLazyTreeNodeChildren } from '@/vue_present/Reuse/LazyTree/helpers/sortLazyTreeNodeChildren'
import { isLeafNode } from '@/vue_present/Reuse/LazyTree/helpers/isLeafNode'

export default defineComponent({
  name: 'ClinicalGuidelinesCatalogTree',

  components: {
    ClinicalGuidelinesCatalogTreeNode,
    ClinicalGuidelinesCatalogTreeActions,
    ReusableClinicalGuidelinesList,
    MButton,
    ReusableLazyTree,
  },

  mixins: [i18nScopeMixin],

  props: {
    clinicalGuideline: { type: ClinicalGuidelineApi, required: true },
  },

  emits: ['setLoading'],

  data () {
    return {
      showTree: true,
      tree: new LazyTree(
        new ClinicalGuidelineTreeApi(),
        { extraAttributes: clinicalGuidelineTreeExtraAttributes }
      ),
      treeRef: null as TLazyTreeRef,
      hasCheckedNotes: false,
    }
  },

  created () {
    this.setI18nScope('clinicalGuidelines')
    this.$pubSub.subscribe('clinicalGuidelineCreate', this.onClinicalGuidelineCreate.bind(this))
    this.$pubSub.subscribe('clinicalGuidelineUpdate', this.onClinicalGuidelineUpdate.bind(this))
    this.$pubSub.subscribe('clinicalGuidelineDestroy', this.onClinicalGuidelineDestroy.bind(this))
    this.subscribeToClinicalGuidelineImport()
  },

  methods: {
    async rerenderTree () {
      this.showTree = false
      await this.$nextTick()
      this.showTree = true
    },

    onCheckChange () {
      this.hasCheckedNotes = Boolean(this.treeRef.getCheckedNodes().length)
    },

    onResetCheckedNotes () {
      this.treeRef.setCheckedNodes([])
      this.hasCheckedNotes = false
    },

    onNodeClick (node: LazyTreeNode) {
      if (!node.isLeaf) return
      this.openEditForm(extractItemId(node.id))
    },

    allowDrag (draggingNode: ElTreeNode) {
      return this.$security.canManageClinicalGuideline
        ? isLeafNode(draggingNode)
        : false
    },

    allowDrop (draggingNode: ElTreeNode, dropNode: ElTreeNode, type: ElTreeDropType) {
      if (type !== 'inner') return false

      return dropNode.level === clinicalGuidelineTreeAllowDropLevel
    },

    sortLazyTreeNodeChildren (nodeId: number) {
      return sortLazyTreeNodeChildren(this.treeRef, nodeId, {
        catalogAlphabet: false,
      })
    },

    async nodeDrop (draggingNode: ElTreeNode, dropNode: ElTreeNode) {
      const clinicalGuideline = new ClinicalGuidelineApi()
      const id = Number(extractItemId(draggingNode.data.id))

      await clinicalGuideline.fetch(id)
      if (!clinicalGuideline.id) { return }

      const category: ICatalog<number> = {
        id: dropNode.data.id,
        title: dropNode.data.title,
      }

      this.sortLazyTreeNodeChildren(category.id)

      clinicalGuideline.setValue('category', category)
      const data = await clinicalGuideline.submit()
      if ('errors' in data) { return }

      this.updateActiveFormCategory(id, category)
    },

    onAdd () {
      if (this.$route.name === CLINICAL_GUIDELINES_CATALOG_ROUTE_NAMES.NEW) { return }
      this.$router.push({ name: CLINICAL_GUIDELINES_CATALOG_ROUTE_NAMES.NEW })
    },

    openEditForm (id: string | number) {
      const idString = id.toString()
      if (this.$route.params.id?.toString() === idString) { return }

      this.$router.replace({
        name: CLINICAL_GUIDELINES_CATALOG_ROUTE_NAMES.EDIT,
        params: { id: idString },
      })
    },

    updateActiveFormCategory (id: number, category: ICatalog<number>) {
      if (this.clinicalGuideline.id !== id) { return }
      this.clinicalGuideline.setValue('category', category)
      this.clinicalGuideline.updateOriginCategory()
    },

    onClinicalGuidelineCreate (item: ClinicalGuidelineModel) {
      const rawNode = {
        id: item.id,
        title: item.title,
        releaseStatus: item.releaseStatus.id,
        categoryId: item.category.id,
      }

      const node = new LazyTreeNode(this.tree, rawNode, true, clinicalGuidelineTreeExtraAttributes)
      appendLazyTreeNode(this.treeRef, item.category.id, node)
      this.sortLazyTreeNodeChildren(item.category.id)
    },

    onClinicalGuidelineUpdate (item: ClinicalGuidelineModel) {
      if (item.category.id !== item.originCategory.id) {
        this.onClinicalGuidelineDestroy(item.id)
        this.onClinicalGuidelineCreate(item)

        return
      }

      const updateData = {
        title: item.title,
        extraAttributes: {
          categoryId: item.category.id,
          releaseStatus: item.releaseStatus.id,
        },
      }
      updateLazyTreeNodeById(this.treeRef, item.category.id, getLazyTreeLeafNodeId(item.id), updateData)
      this.sortLazyTreeNodeChildren(item.category.id)
    },

    onClinicalGuidelineDestroy (id: number) {
      this.treeRef.remove(getLazyTreeLeafNodeId(id))
    },

    subscribeToClinicalGuidelineImport () {
      this.$pubSub.subscribe('clinicalGuidelineImport', () => {
        this.rerenderTree()
        if (this.$route.name !== CLINICAL_GUIDELINES_CATALOG_ROUTE_NAMES.EDIT) { return }
        this.$router.replace({ name: CLINICAL_GUIDELINES_CATALOG_ROUTE_NAMES.INDEX })
      })
    },
  },
})
</script>
