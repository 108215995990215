import { DOCUMENT_TYPES } from '@/vue_present/Reuse/DocumentTree/store/documentTypes'

export const getDocumentRoute = ({ documentId, documentType, isEdit = false }) => {
  const edit = (isEdit && '/edit') || ''

  switch (documentType) {
    case DOCUMENT_TYPES.OLD_DOCUMENT:
      return Routes.document_path(documentId)
    default:
      return `/checkup/documents/${documentType}/${documentId}${edit}`
  }
}

export const getPersonalPeriodicCheckupRoute = (companyCheckupId: number, id: number, isEdit = false) =>
  `/checkup/company-checkup/${companyCheckupId}/${id}${isEdit ? '/edit' : ''}`
