import { MSiSchema } from '@/vue_present/_base/Tables/MSiGenerator/MSiSchema'
import { MTableSchema } from '@/vue_present/_base/Tables/MTableGenerator/MTableSchema'
import { MTableHeader } from '@/vue_present/_base/Tables/MTableGenerator/MTableHeader'
import { tScoped } from '@/helpers/i18nHelpers'

const ts = tScoped('clinicalGuidelines.catalog.entry')

export const getClinicalGuidelineEntrySchema = () =>
  new MSiSchema()
    .addPagination()
    .addCreateButton(Services.security.canManageClinicalGuideline)
    .addTableSchema(
      new MTableSchema()
        .addHeaders({
          arrowIconCell: new MTableHeader().addFixedWidth('40px'),
          iconCell: new MTableHeader().addFixedWidth('40px'),
          titleCell: new MTableHeader(t('title')),
          stepCell: new MTableHeader(ts('step')).addFixedWidth('150px'),
          uurCell: new MTableHeader(ts('uurShort'))
            .addFixedWidth('50px')
            .addTooltip(ts('uurLong')),
          uddCell: new MTableHeader(ts('uddShort'))
            .addFixedWidth('55px')
            .addTooltip(ts('uddLong')),
        })
        .addEditIcon(Services.security.canManageClinicalGuideline)
        .addDeleteIcon(Services.security.canManageClinicalGuideline)
    )

export const getClinicalGuidelineEntryAddButtonConfig = () => ({
  icon: 'clinicalGuideline',
  tooltip: ts('tooltip'),
})
