import { EAddProtocolSources } from '@/vue_apps/ProtocolsApp/consts/addProtocolSources'
import { ProtocolApi } from '@/vue_apps/ProtocolsApp/entities/ProtocolApi'
import { omit, pick } from 'lodash'
import { TemplateRecordApi } from '@/vue_apps/catalogs_root/TemplateRecords/entities/TemplateRecordApi'
import { ITemplateRecordsTreeItem } from '@/vue_present/Reuse/ProtocolsTree/ITemplateRecordsTreeItem'
import { snakeToCamel } from '@/_api/_requests/helpers'
import { not } from '@/helpers/lambda'
import { vueFilters } from '@/plugins/vue/filtersPlugin/vueFilters'

export type TByScratchId = -1
export type TProtocolQueryParams = Record<EAddProtocolSources, string | TByScratchId>
export interface IProtocolQueryEntryParams {
  ['entry[id]']: string
  ['entry[title]']: string
  ['entry[date]']: string
}

export class AddProtocolFabric {
  static BY_SCRATCH_ID: TByScratchId = -1

  private static sources = {
    async [EAddProtocolSources.byScratch] (id: number, query: TProtocolQueryParams) {
      const protocol = new ProtocolApi()
      protocol.fillPrintingTools({
        entryInfo: gon.specific.confPrintEntryInfo,
        entryTitle: gon.specific.confPrintEntryTitle,
        clinicHeader: gon.specific.confPrintClinicHeader,
        doctorSignature: gon.specific.confPrintDoctorSignature,
      })

      AddProtocolFabric.setDefaults(protocol, query)

      return protocol
    },

    async [EAddProtocolSources.byTemplate] (id: number, query: TProtocolQueryParams) {
      const templateRecord = new TemplateRecordApi({ id })
      const result = await templateRecord.load()

      const protocol = new ProtocolApi()

      if (result?.errors) { return protocol }
      protocol.fillByTemplate(templateRecord.getAttributes() as ITemplateRecordsTreeItem)
      AddProtocolFabric.setDefaults(protocol, query)

      return protocol
    },

    async [EAddProtocolSources.byPrevProtocol] (id: number, query: TProtocolQueryParams) {
      const prevProtocol = new ProtocolApi({ id })
      const result = await prevProtocol.load()
      if (!result) { return }

      const newData = {
        ...omit(prevProtocol.getAttributes(), ['id']),
        doctor: pick(snakeToCamel(gon.application.current_user), ['id', 'fullName', 'name', 'secondName', 'surname', 'shortName']),
        date: Utils.getBaseFormattedDate(),
        entries: [],
        entryIds: [],
      }

      const protocol = new ProtocolApi(newData)
      protocol.semdsDocumentDetailsAttributes.id = null
      AddProtocolFabric.setEntry(protocol, query as unknown as IProtocolQueryEntryParams)

      return protocol
    },
  }

  static Get (query: TProtocolQueryParams): Promise<ProtocolApi<unknown>> {
    const [source, id] = this.extractProtocolSource(query)
    if (!this.sources[source]) {
      return this.sources[EAddProtocolSources.byScratch](+id, query)
    }

    return this.sources[source](+id, query)
  }

  private static extractProtocolSource (query: TProtocolQueryParams): [string, string | TByScratchId] {
    return Object.entries(query).find(([key, value]) => key in EAddProtocolSources && value !== undefined) ||
      [EAddProtocolSources.byScratch, AddProtocolFabric.BY_SCRATCH_ID]
  }

  private static setDefaults (protocol: ProtocolApi<unknown>, query: TProtocolQueryParams) {
    protocol.setValue('date', Utils.getBaseFormattedDate())
    protocol.setValue('clinic', pick(snakeToCamel(gon.application.current_clinic), ['id', 'title']))
    protocol.setValue('doctor', pick(snakeToCamel(gon.application.current_user), ['id', 'fullName']))

    this.setEntry(protocol, query as unknown as IProtocolQueryEntryParams)
  }

  private static setEntry (protocol: ProtocolApi<unknown>, query: IProtocolQueryEntryParams) {
    if (not(query['entry[id]']) || not(query['entry[title]']) || not(query['entry[date]'])) {
      return
    }

    const entryId = parseInt(query['entry[id]'])
    if (not(entryId)) { return }

    protocol.setValue('entries', [{
      id: entryId,
      title: query['entry[title]'],
      date: vueFilters.date(query['entry[date]']),
    }])
  }
}
