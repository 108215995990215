export enum FINANCE_ROUTE_NAMES {
  INDEX = 'index',
  ORDERS = 'orders',
  PAYMENTS = 'payments',
  ACQUIRING = 'acquiring',
  EXPENSES = 'expenses',

  PAYMENT_NEW = 'payment-new',
  PAYMENT_SHOW = 'payment-show',
}
