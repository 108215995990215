import { TemplateRecordLogic } from '@/vue_apps/catalogs_root/TemplateRecords/entities/TemplateRecordLogic'
import { Callback } from '@/_api/decorators/callback'
import { ICRUDReturn } from '@/_api/decorators/api/interfaces/ICRUDReturn'
import { inArrayOrUndefined } from '@/_medods_standart_library/msl'
import { IApiErrors } from '@/_declarations/IApiErrors'
import { Api } from '@/_api/decorators/api/Api'

export class TemplateRecordApi extends TemplateRecordLogic {
  constructor ({ id }) {
    super({ id })
  }

  @Callback('_fillProps')
  @Api.notify('fetchMessage', 'templateRecord', { success: false })
  @Api.get(Routes.template_record_path)
  load () {
    return {
      routeArgs: [this.id],
    } as ICRUDReturn as Promise<void & IApiErrors>
  }

  @Callback('_fillProps')
  @Api.notify('submitMessage', 'templateRecord')
  @Api.submit(() => Routes.template_records_path('json'))
  submit () {
    return {
      payload: { templateRecord: this.getAttributes() },
      routeArgs: inArrayOrUndefined(this.id),
    } as ICRUDReturn as Promise<void & IApiErrors>
  }

  @Api.notify('deleteMessage', 'templateRecord')
  @Api.delete(Routes.template_record_path)
  destroy () {
    return {
      routeArgs: [this.id],
    } as ICRUDReturn as Promise<void & IApiErrors>
  }
}
