import { ItemBase } from '@/_api/_classes/ItemBase'
import { IWorkplaceKKMInfo } from '@/vue_apps/catalogs_root/Workplaces/interfaces/IWorkplaceKKMInfo'
import { orNull } from '@/_declarations/orNull'
import { FISCAL_PRINTER_REPORT_TYPE } from '@/vue_apps/catalogs_root/Workplaces/consts/fiscalPrinterEvent'
import { ICatalog } from '@/_declarations/ICatalog'

export class FiscalPrinterModel extends ItemBase {
  workplaceId: number = null

  info: orNull<IWorkplaceKKMInfo> = null

  receipt: orNull<ICatalog<FISCAL_PRINTER_REPORT_TYPE>> = null
}
