import { specSymbolsSanitize } from '@/specific/entries/submit_sanitizer'
import { orUndefined } from '@/_medods_standart_library/msl'
import { ProtocolModel } from '@/vue_apps/ProtocolsApp/entities/ProtocolModel'

export const getDiagnosesAttributes = (diagnoses) => {
  if (!diagnoses) { return [] }

  const getExtraAttributes = (value) => {
    if (!value) { return {} }

    return {
      text: value.text,
      firstTime: value.first,
      chronic: value.chronical,
      occupationalIllness: value.prof,
    }
  }

  return (diagnoses || []).map((d) => {
    return {
      diseaseId: d.item.id,
      title: d.item.title,
      codeString: d.item.code_string,
      ...getExtraAttributes(d.value),
    }
  })
}

export const getEntitiesAttributes = (entities) => {
  if (!entities) { return [] }

  return (entities || [])
    .map((e) => ({ text: specSymbolsSanitize(e.value) }))
    .filter((e) => e.text)
}

export const extractSemdsDocumentDetailsAttributes = (data: ProtocolModel<any>) => {
  const { semdsDocumentDetailsAttributes: semdAttributes } = data

  return data.semdType && semdAttributes
    ? [{
        id: orUndefined(semdAttributes.id),
        clinicId: data.clinic.id,
        displayInWidgetsPersonalAccount: data.onlineAccess,
        data: semdAttributes.data,
      }]
    : undefined
}
