<template>
  <div class="client-search-create flex gap-indent-small">
    <client-search-input
      v-model="vmValue"
      :list="list"
      :required="required"
      :disabled="disabled"
      :m-fixed-height="mFixedHeight"
      @registerValidator="$registerValidator($event)"
      @onItemClick="$emit('input', $event)"
    />

    <m-button
      v-if="!disabled && !hideAdd"
      v-tooltip="t('add_client')"
      :disabled="disabled"
      template="add"
      @click="openModal"
    />

    <m-popover
      v-if="defaultClient.id && useEditing && useEditing.visible"
      inherited-visibility
      :visibility="!!showClientEditTooltip"
      placement="top"
      trigger="manual"
    >
      <template #all>
        <span class="danger font-size-12">{{ t('checkups.clientUpdateRequired') }}</span>
      </template>

      <template #reference>
        <m-button
          template="short_edit"
          :disabled="useEditing.disabled"
          @click="onEditClick"
        />
      </template>
    </m-popover>

    <m-modal
      :type="modalType"
      :visible.sync="modalVisible"
      width="550px"
      @close="closeModal"
    >
      <template #header>
        <m-icon icon="client" />
        <span>{{ modalTitle }}</span>
      </template>

      <div
        v-if="modalVisible"
        class="client-search-create-form-short"
      >
        <div class="client-search-create-form-short__col">
          <m-input
            v-if="!client.id"
            v-model="client.surname"
            :label="t('surname')"
            :required="!client.id"
            validator-name="surname"
            @registerValidator="onRegisterValidator($event)"
          />

          <m-input
            v-if="!client.id"
            v-model="client.name"
            :label="t('name')"
            :required="!client.id"
            validator-name="name"
            @registerValidator="onRegisterValidator($event)"
          />

          <m-input
            v-if="!client.id"
            v-model="client.secondName"
            :label="t('second_name')"
          />

          <m-phone-input
            v-model="client.phone"
            :label="t('phone')"
            required
            validator-name="phone"
            @registerValidator="onRegisterValidator($event)"
          />

          <m-date-picker
            v-model="client.birthdate"
            required
            :label="t('birthdate')"
            value-format="yyyy-MM-dd"
            validator-name="birthdate"
            @registerValidator="onRegisterValidator($event)"
          />

          <reusable-sex
            v-model="client.sex"
            required
            @registerValidator="onRegisterValidator($event)"
          />
        </div>

        <div class="client-search-create-form-short__col">
          <reusable-local-n-s-i-list
            v-if="egiszModuleEnabled"
            v-tooltip="client.nsiProfession?.title"
            :label="t('checkups.personal.info.nsiPosition')"
            :value="client.nsiProfession"
            :dictionary="NSI.PROFESSIONS"
            @changeByLazy="client.nsiProfession = $event"
          />

          <m-input
            v-model="client.position"
            v-tooltip="t('positionPlaceholder')"
            :label="t('position')"
          />

          <!-- СНИЛС -->
          <m-input
            v-model="client.snils"
            class="flex-basis-50"
            :label="t('snils')"
            :required="!!client.snils && snilsValidator"
            :mask="SNILS_THE_MASK"
            :placeholder="SNILS_MASK_PLACEHOLDER"
            validator-name="snils"
            @registerValidator="onRegisterValidator($event)"
          />
        </div>
      </div>

      <template #footer-left>
        <m-button
          :template="submitTemplate"
          :disabled="loading"
          @click="onSubmit"
        />
      </template>
    </m-modal>
  </div>
</template>

<script>
import { MInputsWrapperMiddlewareMixin } from '@/vue_present/mixins/MInputsWrapperMiddlewareMixin'
import { PropsTypes } from '@/vue_present/_base/PropsTypes'
import { ClientsSearchList } from '@/vue_apps/CheckupsModule/components/ClientSearchCreateShort/ClientsSearchList'
import { MListService } from '@/_api/_requests/MListService'
import MButton from '@/vue_present/_base/buttons/MButton/MButton.vue'
import MInput from '@/vue_present/_base/inputs/MInput/MInput.vue'
import MPhoneInput from '@/vue_present/_base/MPhoneInput/MPhoneInput.vue'
import MDatePicker from '@/vue_present/_base/inputs/MDatePicker/MDatePicker.vue'
import ReusableSex from '@/vue_present/Reuse/Lists/Sex/ReusableSex.vue'
import MModal from '@/vue_present/_base/MModal/MModal.vue'
import { ModalMixin } from '@/vue_present/mixins/ModalMixins/ModalMixin'
import { ValidationParentMixin } from '@/vue_present/mixins/ValidationParentMixin'
import { MClientPresenter } from '@/_api/MClient/MClientPresenter'
import { clientAdapter } from '@/vue_apps/CheckupsModule/api/clientAdapter'
import MIcon from '@/vue_present/_base/MIcon/MIcon.vue'
import { cloneDeep } from 'lodash'
import MPopover from '@/vue_present/_base/MPopover/MPopover.vue'
import ClientSearchInput from '@/vue_present/Reuse/Lists/ClientSearchInput/ClientSearchInput.vue'
import { SNILS_MASK_PLACEHOLDER, SNILS_THE_MASK } from '@/specific/clients/methods/snils'
import { snilsValidator } from '@/lib/validators/snilsValidator'
import ReusableLocalNSIList from '@/vue_present/Reuse/Lists/NSI/ReusableLocalNSIList.vue'
import { NSI_DICTIONARIES } from '@/vue_present/Reuse/Lists/NSI/nsiDictionaries'
import { SpinnerHolder } from '@/vue_components/mixins/spinner_holder'

const getDefaultClient = () => ({
  id: null,
  surname: null,
  name: null,
  secondName: null,
  phone: '79000000000',
  sex: null,
  birthdate: null,
  position: null,
  nsiProfession: null,
  snils: null,
})

export default {
  name: 'ClientSearchEditorShort',
  components: {
    ReusableLocalNSIList,
    ClientSearchInput,
    MPopover,
    MIcon,
    MModal,
    ReusableSex,
    MDatePicker,
    MPhoneInput,
    MInput,
    MButton,
  },

  mixins: [
    MInputsWrapperMiddlewareMixin,
    ModalMixin,
    ValidationParentMixin,
    SpinnerHolder,
  ],

  props: {
    value: PropsTypes.String(),
    list: { type: MListService, default: () => new ClientsSearchList() },
    disabled: Boolean,
    hideAdd: Boolean,
    mFixedHeight: { type: Boolean, default: true },

    defaultClient: { type: Object, default: () => getDefaultClient() },

    useEditing: {
      type: Object,
      default: () => ({
        visible: false,
        disabled: false,
      }),
    },
  },

  data () {
    return {
      NSI: NSI_DICTIONARIES,
      SNILS_MASK_PLACEHOLDER,
      SNILS_THE_MASK,
      egiszModuleEnabled: gon.application.egisz_module_enabled,
      client: getDefaultClient(),
      showClientEditTooltip: false,
    }
  },

  computed: {
    vmValue: {
      get () { return this.value },
      set (value) { this.$emit('input', value) },
    },

    modalType () {
      return this.defaultClient?.id
        ? 'warning'
        : 'success'
    },

    modalTitle () {
      return this.defaultClient?.id
        ? this.t('edit')
        : this.t('new_client')
    },

    submitTemplate () {
      return this.defaultClient?.id
        ? 'save_orange'
        : 'save'
    },
  },

  watch: {
    defaultClient (to) {
      if (to?.id) { return }
      this.client = getDefaultClient()
    },
  },

  methods: {
    snilsValidator,

    closeModal () {
      this.modalVisible = false
      this.client = getDefaultClient()
    },

    onEditClick () {
      this.client = cloneDeep(this.defaultClient)

      this.openModal()
    },

    async onSubmit () {
      if (this.hasValidationErrors()) { return }

      const { errors, id, fullname } = await this.withSpinnerSimple(new MClientPresenter().submit(this.client, {
        toServerAdapter: clientAdapter.toServerShort,
        toJson: true,
      }))

      if (errors) { return this.resetValidations(errors) }

      this.$emit('onClientCreate', {
        ...this.client,
        id,
        fullName: fullname,
      })
      this.closeModal()
    },

    triggerClientTooltip () {
      this.showClientEditTooltip = clearTimeout(this.showClientEditTooltip) || null

      this.showClientEditTooltip = setTimeout(() => {
        this.showClientEditTooltip = clearTimeout(this.showClientEditTooltip) || null
      }, 3000)
    },
  },
}
</script>
