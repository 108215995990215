import { PROTOCOLS_ROUTE_NAMES } from '@/vue_apps/ProtocolsApp/router/protocolsRouteNames'
import ProtocolShowView from '@/vue_apps/ProtocolsApp/pages/ProtocolShowView.vue'
import ProtocolEditorView from '@/vue_apps/ProtocolsApp/pages/ProtocolEditorView.vue'
import ProtocolView from '@/vue_apps/ProtocolsApp/pages/ProtocolView.vue'
import ProtocolPrintView from '@/vue_apps/ProtocolsApp/pages/ProtocolPrintView.vue'

export const protocolsRoutes = [
  {
    path: '/medical_records/:medicalRecordId/protocols/print',
    name: PROTOCOLS_ROUTE_NAMES.PRINT,
    component: ProtocolPrintView,
  },

  {
    path: '/medical_records/:medicalRecordId/protocols/new',
    name: PROTOCOLS_ROUTE_NAMES.NEW,
    component: ProtocolEditorView,
  },

  {
    path: '/medical_records/:medicalRecordId/protocols/:id/edit',
    name: PROTOCOLS_ROUTE_NAMES.EDIT,
    component: ProtocolEditorView,
  },

  {
    path: '/medical_records/:medicalRecordId/protocols/:id',
    name: PROTOCOLS_ROUTE_NAMES.SHOW,
    component: ProtocolShowView,
  },

  {
    path: '/medical_records/:medicalRecordId/protocols',
    name: PROTOCOLS_ROUTE_NAMES.INDEX,
    component: ProtocolView,
  },

  {
    path: '/medical_records/:medicalRecordId',
    name: PROTOCOLS_ROUTE_NAMES.INDEX_EMK,
    component: ProtocolView,
  },
]
