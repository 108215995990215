import { IDisease } from '@/vue_apps/catalogs_root/Diseases/store/types'

export class Disease {
  id: number

  title: string

  code_string: string

  category_id: number

  fullTitle: string

  isLeaf = true

  constructor (props: IDisease) {
    this.id = props.id as number
    this.title = props.title
    this.code_string = props.codeString
    this.category_id = props.categoryId as number

    this.setTitle()
  }

  private setTitle () {
    this.fullTitle = `${this.code_string}: ${this.title}`
  }
}
