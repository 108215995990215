export const BY_OMS = '1'
export const BY_DMS = '2'
export const BY_DOCUMENT = '3'

/**
 * "Средства ОМС" ➝ тип документа "Полис ОМС"
 * "Средства ДМС" ➝ "Полис ДМС"
 * "Средства пациента" ➝ "Договор на оказание ..."
 * "Средства третьих лиц (физических)" ➝ "Договор на оказание"
 * "Средства третьих лиц (юридических)" ➝ "Договор на оказание" + селектор компании
 *
 *  другие типы источника ➝ документ основание не нужен
 *
 *
 * @param {string} paymentSourceNsiId
 * @return {string|null}
 */
const foundationDocumentTypeNsiIdByPaymentSourceNsiIdMap = Object.freeze({
  1: BY_OMS,
  3: BY_DMS,
  4: BY_DOCUMENT,
  5: BY_DOCUMENT,
  6: BY_DOCUMENT,
})

export const getFoundationDocumentTypeNsiIdByPaymentSourceNsiId = (paymentSourceNsiId) => {
  return foundationDocumentTypeNsiIdByPaymentSourceNsiIdMap[paymentSourceNsiId]
}
