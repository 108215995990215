export class ArrayHelpers {
  static arrayToMap (items: Array<Record<any, any>>, keyAttribute: string = 'id') {
    if (!Array.isArray(items)) { return {} }

    return items.reduce(function (acc, item) {
      acc[item[keyAttribute]] = item

      return acc
    }, {})
  }

  static orArray<T> (value: T) {
    return value || []
  }
}
