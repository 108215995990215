import {
  AcquiringTerminalLogicBase,
} from '@/vue_apps/catalogs_root/Workplaces/classes/AcquiringTerminalBase/AcquiringTerminalLogicBase'
import { IWSMessage } from '@/_declarations/IWSMessage'
import { IAcquiringWSMeta } from '@/vue_apps/FinanceModule/FinanceModuleIndex/interfaces/Acquiring/IAcquiringWSMeta'
import { snakeToCamel } from '@/_api/_requests/helpers'
import { TAcquiringWSData } from '@/vue_apps/FinanceModule/FinanceModuleIndex/interfaces/Acquiring/IAcquiringWSData'
import { ACQUIRING_ACTION } from '@/vue_apps/FinanceModule/FinanceModuleIndex/consts/Acquiring/AcquiringAction'
import {
  ACQUIRING_MODULE_STATUSES,
} from '@/vue_apps/FinanceModule/FinanceModuleIndex/consts/Acquiring/AcquiringModuleStatuses'
import { ACQUIRING_STATUS_CODE } from '@/vue_apps/FinanceModule/FinanceModuleIndex/consts/Acquiring/AcquiringStatus'
import {
  IAcquiringCheckingConnection,
} from '@/vue_apps/FinanceModule/FinanceModuleIndex/interfaces/Acquiring/IAcquiringCheckingConnection'
import {
  IAcquiringRequestAwaitListItem,
} from '@/vue_apps/FinanceModule/FinanceModuleIndex/interfaces/Acquiring/IAcquiringRequestAwaitListItem'
import { Callback } from '@/_api/decorators/callback'
import { Api } from '@/_api/decorators/api/Api'
import { ICRUDReturn } from '@/_api/decorators/api/interfaces/ICRUDReturn'
import { ACQUIRING_OPERATION } from '@/vue_apps/FinanceModule/FinanceModuleIndex/consts/Acquiring/AcquiringOperation'
import {
  IAcquiringCopyOfReceipt,
} from '@/vue_apps/catalogs_root/Workplaces/interfaces/Acquiring/IAcquiringCopyOfReceipt'
import {
  ACQUIRING_TERMINAL_CONNECTION_STATUS,
} from '@/vue_apps/FinanceModule/FinanceModuleIndex/consts/Acquiring/AcquiringTerminalConnectionStatus'
import {
  TAcquiringSubscribeToWSConfigActionHandler,
  TAcquiringSubscribeToWSConfigWsErrorsHandler,
  TAcquiringSubscribeToWSConfigWsErrorsHandlerExternalRequestHandler,
} from '@/vue_apps/catalogs_root/Workplaces/interfaces/Acquiring/AcquiringSubscribeToWSConfig'
import { IAcquiringErrors } from '@/vue_apps/FinanceModule/FinanceModuleIndex/interfaces/Acquiring/IAcquiringErrors'

export class AcquiringTerminalApiBase extends AcquiringTerminalLogicBase {
  @Callback('checkForTerminalAvailabilityCallback', true)
  @Api.post(Routes.api_internal_acquiring_trade_operations_terminal_info_path)
  checkForTerminalAvailability () {
    return {
      payload: {
        workplaceId: this.workplaceId,
      },
    } as ICRUDReturn as Promise<IAcquiringWSMeta | IAcquiringErrors>
  }

  @Api.notify('checkConnectionMessage', 'terminal', { success: false })
  @Api.post(Routes.api_internal_acquiring_trade_operations_checking_connection_path)
  checkingConnectionRaw () {
    return {
      payload: {
        workplaceId: this.workplaceId,
      },
    } as ICRUDReturn as Promise<IAcquiringWSMeta>
  }

  @Callback(function (data: IAcquiringWSMeta | IAcquiringErrors) {
    this.requestHandler(ACQUIRING_OPERATION.CHECKING_CONNECTION, data)
  }, true)
  @Api.post(Routes.api_internal_acquiring_trade_operations_checking_connection_path)
  checkingConnection () {
    return {
      payload: {
        workplaceId: this.workplaceId,
      },
    } as ICRUDReturn as Promise<void>
  }

  protected subscribeToWS (
    actionHandler: TAcquiringSubscribeToWSConfigActionHandler,
    wsErrorsHandler: TAcquiringSubscribeToWSConfigWsErrorsHandler,
    externalRequestHandler?: TAcquiringSubscribeToWSConfigWsErrorsHandlerExternalRequestHandler
  ) {
    Services.wsSubscriptions.tradeAcquiring.connect((payload: IWSMessage<object, IAcquiringWSMeta>) => {
      const {
        action,
        data,
        meta,
      } = snakeToCamel(payload) as IWSMessage<TAcquiringWSData, ACQUIRING_ACTION, IAcquiringWSMeta>

      if (action === ACQUIRING_ACTION.MODULE_STATUSES && 'status' in data && data.status === ACQUIRING_MODULE_STATUSES.DISABLED) {
        return this.moduleDisabledHandler()
      }

      const hasRequestInAwaitList = this.hasRequestInAwaitList(meta.requestId)
      const hasErrors = 'errors' in data
      const statusFailure = 'status' in data && data.status === ACQUIRING_STATUS_CODE.FAILURE
      const isSuccess = !hasErrors && !statusFailure

      if (!hasRequestInAwaitList) {
        this.externalRequestHandler(action, data, isSuccess)

        if (externalRequestHandler) {
          externalRequestHandler(action, data, isSuccess)
        }

        return
      }

      const awaitRequestListItem = this.removeRequestFromAwaitList(meta.requestId)

      if (!isSuccess) {
        return this.wsErrorsHandlerBase(wsErrorsHandler, awaitRequestListItem, data)
      }

      this.wsSuccessHandlerBase(action, awaitRequestListItem, data)
      actionHandler(action, awaitRequestListItem, data)
    })
  }

  private wsErrorsHandlerBase (
    wsErrorsHandler: TAcquiringSubscribeToWSConfigWsErrorsHandler,
    awaitRequestListItem: IAcquiringRequestAwaitListItem,
    data: TAcquiringWSData
  ) {
    if (awaitRequestListItem.operation === ACQUIRING_OPERATION.CHECKING_CONNECTION) {
      this.setConnectionStatus(ACQUIRING_TERMINAL_CONNECTION_STATUS.INACTIVE)
    }

    return wsErrorsHandler(awaitRequestListItem, data)
  }

  private wsSuccessHandlerBase (
    action: ACQUIRING_ACTION,
    awaitRequestListItem: IAcquiringRequestAwaitListItem,
    data: TAcquiringWSData
  ) {
    switch (action) {
      case ACQUIRING_ACTION.CHECKING_CONNECTION: return this.checkingConnectionHandler(data as IAcquiringCheckingConnection, awaitRequestListItem)
      case ACQUIRING_ACTION.COPY_OF_RECEIPT: return this.copyOfReceiptHandler(awaitRequestListItem, data as IAcquiringCopyOfReceipt)
    }
  }
}
