<template>
  <div class="payment-fields">
    <payment-fields-fund
      v-if="showPaymentFields"
      :payment="payment"
    />

    <payment-fields-refund
      v-if="payment.distributor.baseKind === PAYMENT_BASE_KIND.REFUND"
      :payment="payment"
    />

    <!-- Комментарий -->
    <m-textarea
      class="payment-fields__field"
      :value="payment.comment"
      :label="t('comment')"
      @input="payment.setValue('comment', $event)"
    />

    <acquiring-modal
      v-if="payment.distributor.baseKind === PAYMENT_BASE_KIND.REFUND"
      :payment="payment"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { PaymentApi } from '@/vue_apps/FinanceModule/FinanceModuleIndex/classes/Payment/PaymentApi'
import MTextarea from '@/vue_present/_base/inputs/MTextarea/MTextarea.vue'
import { PAYMENT_BASE_KIND } from '@/vue_apps/FinanceModule/FinanceModuleIndex/consts/paymentBaseKind'
import PaymentFieldsFund from '@/vue_apps/FinanceModule/FinanceModuleIndex/components/Payment/PaymentFieldsFund.vue'
import PaymentFieldsRefund from '@/vue_apps/FinanceModule/FinanceModuleIndex/components/Payment/PaymentFieldsRefund.vue'
import { PAYMENT_KINDS } from '@/vue_apps/FinanceModule/FinanceModuleIndex/consts/paymentKinds'
import AcquiringModal from '@/vue_apps/FinanceModule/FinanceModuleIndex/components/Payment/Acquiring/AcquiringModal.vue'

export default defineComponent({
  name: 'PaymentFields',

  components: {
    AcquiringModal,
    PaymentFieldsRefund,
    PaymentFieldsFund,
    MTextarea,
  },

  props: {
    payment: PaymentApi,
  },

  data () {
    return {
      PAYMENT_BASE_KIND,
    }
  },

  computed: {
    showPaymentFields () {
      switch (this.payment.distributor.initialKind) {
        case PAYMENT_KINDS.ORDER_INITIAL:
        case PAYMENT_KINDS.ORDER_SUBSEQUENT:
        case PAYMENT_KINDS.CASH_IN: return true
        default: return false
      }
    },
  },
})
</script>
