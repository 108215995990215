<template>
  <div class="protocol-diagnose-view">
    <m-checkbox
      v-model="valueProxy.first"
      :label="t('set_first')"
    />

    <m-checkbox
      v-model="valueProxy.chronical"
      :label="t('chronical')"
    />

    <m-checkbox
      v-model="valueProxy.prof"
      class="mb-indent-mid"
      :label="t('professional')"
    />

    <b>{{ codeString }}: {{ title }}</b>

    <m-textarea
      v-model="valueProxy.text"
      :autosize="{ minRows: 3 }"
      :m-fixed-height="false"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import Diagnose from '@/plugins/dynamic_forms/entities/items/Diagnose'
import MTextarea from '@/vue_present/_base/inputs/MTextarea/MTextarea.vue'
import MCheckbox from '@/vue_present/_base/inputs/MCheckbox/MCheckbox.vue'

export default defineComponent({
  name: 'ProtocolDiagnoseView',

  components: { MCheckbox, MTextarea },

  props: {
    entity: { type: Object as PropType<Diagnose>, required: true },
    value: { type: Object, default: () => ({}) },
  },

  computed: {
    codeString () {
      return this.entity?.data.item.code_string || ''
    },

    title () {
      return this.entity?.data.item.title || ''
    },

    valueProxy: {
      get () {
        return this.value || {
          text: '',
          first: false,
          chronical: false,
          prof: false,
        }
      },
      set (value) { this.$updateSync('value', value) },
    },
  },

  created () {
    this.valueProxy = {
      text: this.entity.data.value.text || '',
      first: this.entity.data.value.first || false,
      chronical: this.entity.data.value.chronical || false,
      prof: this.entity.data.value.prof || false,
    }
  },
})
</script>
