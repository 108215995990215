import {
  ClinicalGuidelineImportModel,
} from '@/vue_apps/catalogs_root/ClinicalGuidelinesCatalog/classes/ClinicalGuidelineImport/ClinicalGuidelineImportModel'
import {
  IClinicalGuidelineImportJson,
} from '@/vue_apps/catalogs_root/ClinicalGuidelinesCatalog/interfaces/IClinicalGuidelineImport'
import {
  ClinicalGuidelineImportItem,
} from '@/vue_apps/catalogs_root/ClinicalGuidelinesCatalog/classes/ClinicalGuidelineImport/ClinicalGuidelineImportItem'

export class ClinicalGuidelineImportLogic extends ClinicalGuidelineImportModel {
  fillClinicalGuidelines ({ clinicalGuidelines }: IClinicalGuidelineImportJson) {
    this.clinicalGuidelines = clinicalGuidelines
      .reduce((acc, clinicalGuideline, index) => {
        const key = index + 1
        acc[key] = new ClinicalGuidelineImportItem(key, clinicalGuideline)

        return acc
      }, {})
  }

  setDuplicateWarning (selectedItems: ClinicalGuidelineImportItem[] = []) {
    if (!this.replaceExisting || !selectedItems.length) { return this.resetDuplicateWarning() }

    const clinicalGuidelines = Object.values(this.clinicalGuidelines)
    const selectedItemsIds = new Set(selectedItems.map((item) => item.id))
    const duplicateMap = this.getDuplicateMap(clinicalGuidelines, selectedItemsIds)

    this.setDuplicateWarningOnDuplicates(Object.values(duplicateMap))
  }

  setReplaceExisting (value: boolean, selectedItems: ClinicalGuidelineImportItem[]) {
    super.setValue('replaceExisting', value)
    value
      ? this.setDuplicateWarning(selectedItems)
      : this.resetDuplicateWarning()
  }

  resetClinicalGuidelines () {
    this.clinicalGuidelines = {}
  }

  private getCombinationKey (item: ClinicalGuidelineImportItem) {
    return `${item.clinicalGuideline.title}-${item.clinicalGuideline.ministryId}`
  }

  private getDuplicateMap (clinicalGuidelines: ClinicalGuidelineImportItem[], selectedItemsIds: Set<number>) {
    return clinicalGuidelines.reduce((acc, item) => {
      const key = this.getCombinationKey(item)
      item.setDuplicateWarning(false)
      if (!selectedItemsIds.has(item.id)) { return acc }

      if (!acc[key]) { acc[key] = [] }
      acc[key].push(item.id)

      return acc
    }, {} as Record<string, number[]>)
  }

  private setDuplicateWarningOnDuplicates (duplicateIds: number[][]) {
    duplicateIds
      .forEach((keysArr) => {
        if (keysArr.length === 1) { return }

        keysArr.forEach((key) => this.clinicalGuidelines[key].setDuplicateWarning(true))
      })
  }

  private resetDuplicateWarning () {
    Object.values(this.clinicalGuidelines)
      .forEach((item) => item.setDuplicateWarning(false))
  }
}
