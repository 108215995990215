import { PrintRawReceiptLogic } from '@/vue_apps/catalogs_root/Workplaces/classes/PrintRawPeceipt/PrintRawReceiptLogic'
import {
  IPrintRawReceiptReceipt,
  IPrintRawReceiptReceiptCorrection,
} from '@/vue_apps/catalogs_root/Workplaces/interfaces/PrintRawReceipt/IPrintRawReceiptReceipt'
import {
  PrintRawReceiptEntriesItem,
} from '@/vue_apps/catalogs_root/Workplaces/classes/PrintRawPeceipt/PrintRawReceiptEntriesItem'
import {
  PrintRawReceiptPaymentItem,
} from '@/vue_apps/catalogs_root/Workplaces/classes/PrintRawPeceipt/PrintRawReceiptPaymentItem'

export class PrintRawReceiptApi extends PrintRawReceiptLogic {
  requestReceiptInfo () {
    if (this.receiptInfoLoaded) return
    super.setValue('loading', true)
    Services.pubSub.subscribe('workplace.get_receipt_info', this.saveReceiptInfo.bind(this))
    Services.workplace.sendMessage('get_receipt_info')
  }

  saveReceiptInfo ({ data }) {
    this.receiptInfo = data
    super.setValue('loading', false)
    super.setValue('receiptInfoLoaded', true)
  }

  sendReceipt (correction: IPrintRawReceiptReceiptCorrection) {
    const data: IPrintRawReceiptReceipt = {
      kind: this.kind.id,

      correction,

      entries: this.entries
        .getValues()
        .map((item: PrintRawReceiptEntriesItem) => ({
          title: item.title,
          kind: item.kind.id,
          price: item.price,
          amount: item.amount,
          measureUnit: item.measureUnit.kind,
          taxScheme: item.taxScheme.id,
        })),

      payments: this.payments
        .getValues()
        .map((item: PrintRawReceiptPaymentItem) => ({
          kind: item.kind.id,
          sum: item.sum,
        })),
    }

    Services.workplace.sendMessage('print_custom_receipt', { data })
  }
}
