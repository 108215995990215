import { MListServiceNestedItemsItem } from '@/_api/_requests/MListServiceNestedItemsItem'
import { ICatalog } from '@/_declarations/ICatalog'
import {
  IPrintRawReceiptEntriesItem,
} from '@/vue_apps/catalogs_root/Workplaces/interfaces/PrintRawReceipt/IPrintRawReceiptEntriesItem'
import { TAX_RATES } from '@/vue_apps/Taxes/const/const'
import { IMeasureUnit } from '@/_declarations/IMeasureUnit'
import { getNDSValue } from '@/vue_apps/FinanceModule/NDSCalculator/getNDSValue'
import { orNull } from '@/_declarations/orNull'

export class PrintRawReceiptEntriesItem
  extends MListServiceNestedItemsItem<number>
  implements IPrintRawReceiptEntriesItem {
  title: string = null

  kind: ICatalog<string> = null

  price: number = 0

  amount: number = 0

  sum: number = 0

  measureUnit: IMeasureUnit = null

  taxScheme: ICatalog<TAX_RATES> = this.getDefaultTaxScheme()

  ndsValue: number | string = null

  setValue (key: string, value: unknown) {
    switch (key) {
      case 'price': return this.setPrice(value as number)
      case 'amount': return this.setAmount(value as number)
      case 'taxScheme': return this.setTaxScheme(value as ICatalog<TAX_RATES>)
    }

    super.setValue(key, value)
  }

  private setPrice (price: number) {
    super.setValue('price', price)

    this.updateSum()
  }

  private setAmount (amount: number) {
    super.setValue('amount', amount)

    this.updateSum()
  }

  private setTaxScheme (taxScheme: orNull<ICatalog<TAX_RATES>>) {
    super.setValue('taxScheme', taxScheme || this.getDefaultTaxScheme())
    this.updateNDS()
  }

  private updateSum () {
    super.setValue('sum', Utils.toMoney(this.price * this.amount))
    this.updateNDS()
  }

  private updateNDS () {
    const ndsValue = this.taxScheme.id === TAX_RATES.NDS_NONE
      ? t(TAX_RATES.NDS_NONE)
      : getNDSValue(this.sum, this.taxScheme.id)

    super.setValue('ndsValue', ndsValue)
  }

  private getDefaultTaxScheme (): ICatalog<TAX_RATES> {
    return {
      id: TAX_RATES.NDS_NONE,
      title: t(TAX_RATES.NDS_NONE),
    }
  }
}
