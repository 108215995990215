import { IEntityAction } from '@/plugins/dynamic_forms/declarations/IEntityAction'

export const ENTRIES_VARIABLE_ACTIONS = {
  ADD: 'ADD_ENTRIES_VAR',
  LOAD: 'LOAD_ENTRIES_VAR',
  DELETE: 'DELETE_ENTRIES_VAR',
  RESTORE: 'RESTORE_ENTRIES_VAR',
  CHANGE: 'CHANGE_ENTRIES_VAR',
  UPDATE: 'UPDATE_ENTRIES_VAR',
}
export const CONCLUSIONS_ACTIONS: IEntityAction = {
  ADD: 'ADD_ZZ',
  LOAD: 'LOAD_ZZ',
  DELETE: 'DELETE_ZZ',
  RESTORE: 'RESTORE_ZZ',
  CHANGE: 'CHANGE_ZZ',
  UPDATE: 'UPDATE_ZZ',
}

export const COMPLAINTS_ACTIONS: IEntityAction = {
  ADD: 'ADD_CC',
  LOAD: 'LOAD_CC',
  DELETE: 'DELETE_CC',
  RESTORE: 'RESTORE_CC',
  CHANGE: 'CHANGE_CC',
  UPDATE: 'UPDATE_CC',
}

export const DESTINATION_ACTIONS: IEntityAction = {
  ADD: 'ADD_RR',
  LOAD: 'LOAD_RR',
  DELETE: 'DELETE_RR',
  RESTORE: 'RESTORE_RR',
  CHANGE: 'CHANGE_RR',
  UPDATE: 'UPDATE_RR',
}

export const FIX_LIST_ACTIONS: IEntityAction = {
  ADD: 'ADD_FL',
  DELETE: 'DELETE_FL',
  CHANGE: 'CHANGE_FIX_LIST',
  UPDATE: 'UPDATES_FL',
  LOAD: 'LOAD_FL',
  RESTORE: 'RESTORE_FL',
}

export const EXTENSIBLE_LIST_ACTIONS: IEntityAction = {
  ADD: 'ADD_EL',
  DELETE: 'DELETE_EL',
  CHANGE: 'CHANGE_EXTENDED_LIST',
  UPDATE: 'UPDATES_EL',
  LOAD: 'LOAD_EL',
  RESTORE: 'RESTORE_EL',
}

export const VARIABLES_ACTIONS: IEntityAction = {
  ADD: 'ADD_V',
  DELETE: 'DELETE_V',
  UPDATE: 'UPDATE_V',
  LOAD: 'LOAD_V',
  RESTORE: 'RESTORE_V',
}

export const CALCULATE_ACTIONS: IEntityAction = {
  ADD: 'ADD_M',
  DELETE: 'DELETE_M',
  UPDATE: 'UPDATE_M',
  RESTORE: 'RESTORE_M',
  UPDATES_DATA: 'UPDATES_M',
  LOAD: 'LOAD_M',
}

export const DIAGNOSE_ACTIONS: IEntityAction = {
  ADD: 'ADD_DIAGNOSE',
  LOAD: 'LOAD_DIAGNOSE',
  DELETE: 'DELETE_DIAGNOSE',
  RESTORE: 'RESTORE_DIAGNOSE',
  CHANGE: 'CHANGE_DIAGNOSE',
  UPDATE: 'UPDATE_DIAGNOSE',
}

export const DIAGNOSES_CONSTRUCTOR_ACTIONS: IEntityAction = {
  ADD: 'ADD_DIAGNOSE_CONSTRUCTOR',
  LOAD: 'LOAD_DIAGNOSE_CONSTRUCTOR',
  DELETE: 'DELETE_DIAGNOSE_CONSTRUCTOR',
  RESTORE: 'RESTORE_DIAGNOSE_CONSTRUCTOR',
  CHANGE: 'CHANGE_DIAGNOSE_CONSTRUCTOR',
  UPDATE: 'UPDATE_DIAGNOSE_CONSTRUCTOR',
}

export const PRINT_AREA_ACTIONS: IEntityAction = {
  ADD: 'ADD_PRINT_AREA',
  LOAD: 'LOAD_PRINT_AREA',
  DELETE: 'DELETE_PRINT_AREA',
  UPDATE: 'UPDATE_PRINT_AREA',
}
