import { TDateString } from '@/_declarations/TDateString'
import { GLOBAL_TIMES } from '@/_global_scripts/GLOBAL_CONSTS'
import { FISCAL_PRINTER_STATUSES } from '@/vue_apps/catalogs_root/Workplaces/consts/fiscalPrinterStatuses'

export const getAcquiringReconciliationOfResultsStatus = (lastReconciliationOfResults: TDateString) => {
  const now = moment()
  const lastReconciliationOfResultsMoment = moment(lastReconciliationOfResults)

  return now.diff(lastReconciliationOfResultsMoment) >= GLOBAL_TIMES.ONE_DAY
    ? FISCAL_PRINTER_STATUSES.IS_OVER_24H
    : FISCAL_PRINTER_STATUSES.IS_LESS_24H
}
