
export default class Container {
  _components = {}

  constructor (components?) {
    for (const c in components) {
      this.add(c, components[c])
    }
  }

  /**
   *
   */
  add (name, component) {
    this._components[name] = component
  }

  /**
   *
   */
  get<ReturnType> (name: string): ReturnType {
    const component = this._components[name]
    if (!component) {
      return false as ReturnType
    }

    return component
  }

  /**
   *
   */
  isset (name) {}

  /**
   *
   */
  delete (name) {}
}
