import { MListServiceItem } from '@/_api/_requests/MListServiceItem'
import {
  ACQUIRING_STATUS,
  ACQUIRING_STATUS_CODE,
  ACQUIRING_STATUS_CODE_TO_TITLE_MAP,
} from '@/vue_apps/FinanceModule/FinanceModuleIndex/consts/Acquiring/AcquiringStatus'
import { MTableCell } from '@/vue_present/_base/Tables/MTableGenerator/MTableCell'
import {
  IAcquiringReportTransaction,
} from '@/vue_apps/ReportsModule/AcquiringReport/interfaces/IAcquiringReportTransaction'
import {
  ACQUIRING_OPERATION_TYPE_CODE,
  getOperationTypeByCode,
} from '@/vue_apps/ReportsModule/AcquiringReport/consts/acquiringOperationTypeCode'
import { vueFilters } from '@/plugins/vue/filtersPlugin/vueFilters'
import { tScoped } from '@/helpers/i18nHelpers'
import {
  ACQUIRING_OPERATION_TYPE,
} from '@/vue_apps/FinanceModule/FinanceModuleIndex/consts/Acquiring/AcquiringOperationType'
import {
  IAcquiringSearchTransaction,
} from '@/vue_apps/FinanceModule/FinanceModuleIndex/interfaces/Acquiring/IAcquiringSearchTransaction'

const ts = tScoped('finance')

export class AcquiringListItemBase extends MListServiceItem {
  paymentTypeCell: MTableCell

  operationTypeCell: MTableCell

  createdAtCell: MTableCell

  statusCell: MTableCell

  amountCell: MTableCell

  userCell: MTableCell

  completedByCell: MTableCell

  paymentCell: MTableCell

  workplaceCell: MTableCell

  constructor (item: IAcquiringSearchTransaction | IAcquiringReportTransaction) {
    super()

    this.id = item.id

    this.setOperationTypeCell(item.operationType)
    this.setStatusCell(item.status)

    this.createdAtCell = new MTableCell(Utils.getFormattedDate(item.createdAt, Utils.momentDateTimeFormat))

    this.userCell = new MTableCell(item.user?.shortName || vueFilters.shortName(item.user))
      .addTooltip(true)

    this.completedByCell = new MTableCell(item.completedBy?.shortName || vueFilters.shortName(item.completedBy))
      .addTooltip(true)

    this.setPaymentCell(item.paymentId)

    this.workplaceCell = new MTableCell(item.workplace.title)
      .addTooltip(true)
  }

  private setOperationTypeCell (operationTypeOrOperationTypeCode: ACQUIRING_OPERATION_TYPE | ACQUIRING_OPERATION_TYPE_CODE) {
    const operationType = typeof operationTypeOrOperationTypeCode === 'number'
      ? getOperationTypeByCode(operationTypeOrOperationTypeCode)
      : operationTypeOrOperationTypeCode

    const title = ts(`operationTypes.${operationType}`)
    this.operationTypeCell = new MTableCell(title)
  }

  private setStatusCell (statusOrStatusCode: ACQUIRING_STATUS_CODE | ACQUIRING_STATUS) {
    const status = typeof statusOrStatusCode === 'number'
      ? ACQUIRING_STATUS_CODE_TO_TITLE_MAP[statusOrStatusCode]
      : statusOrStatusCode

    if (status === ACQUIRING_STATUS.INIT) {
      this.statusCell = new MTableCell(ts('transactionStatus.init'))
        .addClasses('warning')

      return
    }

    if (status === ACQUIRING_STATUS.SUCCESS) {
      this.statusCell = new MTableCell(ts('transactionStatus.success'))
        .addClasses('success')

      return
    }

    if (status === ACQUIRING_STATUS.FAILURE) {
      this.statusCell = new MTableCell(ts('transactionStatus.failure'))

      return
    }

    if (status === ACQUIRING_STATUS.IN_PROGRESS) {
      this.statusCell = new MTableCell(ts('transactionStatus.inProgress'))
        .addClasses('warning')

      return
    }

    if (status === ACQUIRING_STATUS.EXECUTION_ERROR) {
      this.statusCell = new MTableCell(ts('transactionStatus.executionError'))
        .addClasses('danger')
    }
  }

  private setPaymentCell (paymentId: IAcquiringReportTransaction['paymentId']) {
    if (!paymentId) {
      this.paymentCell = new MTableCell()

      return
    }

    this.paymentCell = new MTableCell(paymentId)
      .addTypeUrl(Routes.payment_path(paymentId))
  }
}
