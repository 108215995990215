import Vue from 'vue/dist/vue.esm'
import VueRouter from 'vue-router'
import companiesRoutes from './modules/companies/routes.js'
import supportRoutes from './modules/support/routes.js'
import {routes as frontBookRoutes} from '@/vue_present/_FrontBook/router/routes'
import {routes as smsConfigurationRoutes} from '@/vue_apps/app_configuration/messages/router/routes'
import {referralRoutes} from '@/vue_apps/catalogs_root/Referrals/routes/referralRoutes'
import {checkupsModuleRoutes} from '@/vue_apps/CheckupsModule/router/checkupsModuleRoutes'
import {financeRoutes} from '@/vue_apps/FinanceModule/FinanceModuleIndex/router/financeModuleRoutes'
import {checkupCatalogsRoutes} from '@/vue_apps/catalogs_root/CheckupCatalogs/router/checkupCatalogsRoutes'
import {entryPacksRoutes} from '@/vue_apps/catalogs_root/EntryPacks/router/routes'
import {fixedDocumentModuleRoutes} from '@/vue_apps/Documents/FixedDocuments/router/fixedDocumentModuleRoutes'
import {clinicsCatalogRoutes} from '@/vue_apps/catalogs_root/ClinicsCatalog/routes/clinicsCatalogRoutes'
import {measureUnitsRoutes} from '@/vue_apps/catalogs_root/MeasureUnits/routes/measureUnitsRoutes'
import egiszRoutes from '@/vue_components/egisz/egisz_module/router/routes'
import {clientFeedbacksRoutes} from '@/vue_components/client_feedbacks/router/clientFeedbacksRoutes'
import {protocolsRoutes} from '@/vue_apps/ProtocolsApp/router/protocolsRoutes'
import {workplacesCatalogRoutes} from '@/vue_apps/catalogs_root/Workplaces/router/routes'
import {
  clinicalGuidelinesCatalogsRoutes,
} from '@/vue_apps/catalogs_root/ClinicalGuidelinesCatalog/router/clinicalGuidelinesCatalogsRoutes'


Vue.use(VueRouter)

const routes = [
  ...companiesRoutes,
  ...supportRoutes,
  ...egiszRoutes,
  ...frontBookRoutes,
  ...smsConfigurationRoutes,
  ...financeRoutes,

  ...referralRoutes,
  ...checkupsModuleRoutes,
  ...checkupCatalogsRoutes,
  ...entryPacksRoutes,
  ...fixedDocumentModuleRoutes,
  ...clinicsCatalogRoutes,
  ...measureUnitsRoutes,
  ...clientFeedbacksRoutes,
  ...workplacesCatalogRoutes,
  ...protocolsRoutes,
  clinicalGuidelinesCatalogsRoutes,
]

let router

export const createRouter = () => {
  if (router) { return router }

  router = new VueRouter({
    mode: 'history',
    routes,
  })

  router.afterEach((to, from) => {
    window.HistoryBrowser.updateVue(to.path)
  })

  return router
}
