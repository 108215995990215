import { TTinyMCEEditor } from '@/vue_apps/ProtocolsApp/_SemdProtocolEditor/interfaces/TTinyMCE'
import { el } from 'redom'
import { MConfirm } from '@/vue_present/_base/MConfirm/MConfirm'
import { IMCEKeeperAttributes, TMCEKeeperAttributes } from '@/_api/_classes/MCEKeeper/interfaces/IMCEKeeperAttributes'

export class MCEKeeperHTML<Attributes extends IMCEKeeperAttributes = TMCEKeeperAttributes> {
  static readonly mceKeeperCssClass = 'mce-keeper'

  readonly itemsClass: string

  private readonly idAttribute: string = 'data-id'

  private valuesAttributes: string[] = []

  private editor: TTinyMCEEditor = tinymce.activeEditor

  private useDeleteIcon: boolean = false

  constructor (itemsClass: string) {
    this.itemsClass = itemsClass
  }

  getEditor () {
    return this.editor
  }

  setValuesAttributes (valuesAttributes: string[]) {
    this.valuesAttributes = valuesAttributes
  }

  setEditor (editor: TTinyMCEEditor) {
    this.editor = editor
  }

  setDeleteIcon (value: boolean) {
    this.useDeleteIcon = value
  }

  init () {
    const nodes = this.collectNodes()

    nodes.forEach((node) => {
      this.addDeleteIcon(node)
    })
  }

  collectItems (): Record<string, Attributes> {
    const nodes = this.collectNodes()

    return nodes.reduce((acc, node) => {
      const id = node.getAttribute(this.idAttribute)
      if (!id) { return acc }

      acc[id] = this.getNodeAttributes(node)

      return acc
    }, {})
  }

  getItemNode (id: string): Element | null {
    return this.editor
      .getBody()
      .querySelector(`${this.getClassSelector()}[${this.idAttribute}="${id}"]`)
  }

  addItemNode (id: string, data: Attributes) {
    this.editor.insertContent(this.buildTemplate(id, data))
    const node = this.getItemNode(id)
    if (!node) { return }
    this.addDeleteIcon(node)
  }

  removeItemNode (id: string) {
    const node = this.getItemNode(id)
    node?.remove()
  }

  private collectNodes () {
    return Object.values<Element>(
      this.editor
        .getBody()
        .querySelectorAll(this.getClassSelector())
    )
  }

  private getNodeAttributes (node: Element): Attributes {
    return this.valuesAttributes
      .reduce((acc, attr) => {
        acc[attr] = node.getAttribute(attr)

        return acc
      }, {} as Attributes)
  }

  private buildTemplate (id: string, data: Attributes) {
    const params = {
      'data-id': id,
      'data-title': data.title,
      'data-type': this.itemsClass,
      class: `${this.getItemsClasses()} mceNonEditable t-element`,
    }

    return el('span', params, data.title).outerHTML
  }

  private addDeleteIcon (node: Element) {
    if (!this.useDeleteIcon) { return }

    const currentDeleteIconNode = node.querySelector('.mce-keeper-btn.fa-trash-alt') as HTMLButtonElement
    const deleteIconNode = currentDeleteIconNode || el('i.tree-icon.mce-keeper-btn.fa-border.fad.fa-trash-alt.control')

    deleteIconNode.onclick = async (e) => {
      e.preventDefault()
      e.stopPropagation()
      const { cancel } = await MConfirm.warning(`${t('delete')}?`)
      if (cancel) { return }

      const itemNode = (e.target as Element).parentNode as Element
      const id = itemNode.getAttribute('data-id')
      const dataType = itemNode.getAttribute('data-type')

      Services.pubSub.emit(`MCEKeeper:${dataType}:Delete`, id)
    }

    if (currentDeleteIconNode) { return }
    node.appendChild(deleteIconNode)
  }

  private getItemsClasses () {
    return `${MCEKeeperHTML.mceKeeperCssClass} ${this.itemsClass}`
  }

  private getClassSelector () {
    return `.${MCEKeeperHTML.mceKeeperCssClass}.${this.itemsClass}`
  }
}
