import { tScoped } from '@/helpers/i18nHelpers'
import { getAcquiringError } from '@/vue_apps/FinanceModule/FinanceModuleIndex/consts/Acquiring/AcquiringError'
import { IAcquiringErrors } from '@/vue_apps/FinanceModule/FinanceModuleIndex/interfaces/Acquiring/IAcquiringErrors'
import {
  TAcquiringArrayErrors,
} from '@/vue_apps/FinanceModule/FinanceModuleIndex/interfaces/Acquiring/TAcquiringArrayErrors'

const ts = tScoped('finance.errors')

export const acquiringExtractError = (data: IAcquiringErrors): string => {
  if (!('errors' in data)) {
    return ts('unknownError')
  }

  if (Array.isArray(data.errors)) {
    return extractArrayErrors(data.errors)
  }

  if ('user' in data.errors) {
    return getAcquiringError(data.errors.user)
  }

  if ('workplace' in data.errors) {
    return getAcquiringError(data.errors.workplace)
  }

  return ts('unknownError')
}

const extractArrayErrors = (errors: TAcquiringArrayErrors): string => {
  const error = errors.at(0)

  if (typeof error === 'string') {
    return getAcquiringError(error)
  }

  if ('workplace' in error) {
    return getAcquiringError(error.workplace)
  }

  if (!('paymentData' in error)) {
    return ts('unknownError')
  }

  if ('_popup_' in error.paymentData) {
    return error.paymentData._popup_.join(', ')
  }

  if ('payment' in error.paymentData) {
    return error.paymentData.payment.join(', ')
  }

  return ts('unknownError')
}
