import {
  GRPCReceiverSendStrategyAbstract,
} from '@/_api/_classes/GRPC/Receiver/SendStrategy/GRPCReceiverSendStrategyAbstract'
import {
  IgRPCReceiverSendStrategyWSConfig,
} from '@/_api/_classes/GRPC/Receiver/SendStrategy/interfaces/IgRPCReceiverSendStrategyWSConfig'
import { GRPC_END_OF_STREAM, GRPC_META_TYPE } from '@/_api/_classes/GRPC/consts/consts'
import { IWSMessage } from '@/_declarations/IWSMessage'
import { IgRPCMeta } from '@/_api/_classes/GRPC/interfaces/IgRPCMeta'
import { orUndefined } from '@/_medods_standart_library/msl'
import { camelToSnake } from '@/_api/_requests/helpers'

export class GRPCReceiverSendStrategyWS<TData> extends GRPCReceiverSendStrategyAbstract<
  TData,
  void,
  IgRPCReceiverSendStrategyWSConfig
> {
  send (payload: TData) {
    this.context.startLoading()
    this.context.setRequestId(Utils.newGUID())
    this.context.ws.send(this.getPayload(payload))
    this.context.setTimeout()
  }

  private getPayload (data: TData | typeof GRPC_END_OF_STREAM): IWSMessage<unknown, string, IgRPCMeta> {
    const payload = {
      data: this.context.toServer(data),
      action: orUndefined(this.config.action),
      type: orUndefined(this.config.type),
      meta: {
        requestId: this.context.requestId,
        action: orUndefined(this.config.metaAction),
        type: GRPC_META_TYPE.RESULT,
      },
    }

    return camelToSnake(payload)
  }
}
