<template>
  <div class="admission-assessments-section">
    <div class="admission-assessments-control">
      <m-date-picker
        :value="selectedDateRange"
        type="daterange"
        value-format="yyyy-MM-dd"
        :m-fixed-height="false"
        :disabled="waitingLoadAssessmentsDynamics"
        @change="onDateRangeChange"
      />

      <reusable-doctors-list
        :value="user"
        class="si-control-item"
        :m-fixed-height="false"
        :disabled="waitingLoadAssessmentsDynamics"
        label=""
        :clearable="false"
        @onDoctorChange="onDoctorChange"
      />

      <!-- Сбросить фильтры -->
      <m-button
        type="warning"
        :tooltip="t('reset')"
        no-use-fw
        icon="no-filter"
        :disabled="waitingLoadAssessmentsDynamics"
        @click="reset"
      />
    </div>

    <div class="admission-assessments-dynamics">
      <div id="chart_container" />
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import MDatePicker from '@/vue_present/_base/inputs/MDatePicker/MDatePicker.vue'
import ReusableDoctorsList from '@/vue_present/Reuse/Lists/Doctors/ReusableDoctorsList.vue'
import MButton from '@/vue_present/_base/buttons/MButton/MButton.vue'
import { MAdmissionAssessmentPresenter } from '@/_api/MFeedback/MAdmissionAssessmentPresenter'
import { snakeToCamel } from '@/_api/_requests/helpers'
import { MLocalStorage } from '@/_api/_storage/MLocalStorage'

const DEFAULT_RANGE = [
  Utils.getBaseFormattedDate(moment().subtract(1, 'years').startOf('month')),
  Utils.getBaseFormattedDate(moment().endOf('month')),
]

export default defineComponent({
  components: { MButton, ReusableDoctorsList, MDatePicker },

  data () {
    return {
      user: snakeToCamel(gon.application.current_user),
      selectedDateRange: DEFAULT_RANGE,
      waitingLoadAssessmentsDynamics: false,
      cache: new MLocalStorage(LOCAL_STORAGE_KEY),
    }
  },

  mounted () {
    const cacheData = this.cache.restore()
    this.user = cacheData?.user
    this.selectedDateRange = cacheData?.selectedDateRange || DEFAULT_RANGE

    this.fetchAssessments()

    if (this.$route.params?.employee) {
      this.onDoctorChange(this.$route.params?.employee)
    }
  },

  beforeDestroy () {
    this.cache.save({
      user: this.user,
      selectedDateRange: this.selectedDateRange,
    })
  },

  methods: {
    async fetchAssessments () {
      const data = {
        userId: this.user?.id,
        dateStart: this.selectedDateRange[0],
        dateEnd: this.selectedDateRange[1],
      }

      const response = await new MAdmissionAssessmentPresenter().fetchAssessments(data)

      if (!response) {
        this.waitingLoadAssessmentsDynamics = false
      }

      this.buildAdmissionAssessmentDynamics(response)
    },

    onDoctorChange (doctor) {
      this.user = doctor
      this.fetchAssessments()
    },

    onDateRangeChange (range) {
      this.selectedDateRange = range || []
      this.fetchAssessments()
    },

    buildAdmissionAssessmentDynamics (data) {
      Report.drawChart.admissionAssessmentDynamics(data)
    },

    reset () {
      this.user = null
      this.selectedDateRange = DEFAULT_RANGE
      this.fetchAssessments()
    },
  },
})
</script>
