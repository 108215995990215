import { tScoped } from '@/helpers/i18nHelpers'
import { PAYMENT_REFUND_METHODS } from '@/vue_apps/FinanceModule/FinanceModuleIndex/consts/paymentRefundMethods'

const ts = tScoped('finance.paymentsPaymentSpecies')

export const paymentRefundRefundMethodItems = [
  {
    id: PAYMENT_REFUND_METHODS.BY_CASH,
    title: ts(PAYMENT_REFUND_METHODS.BY_CASH),
  },
  {
    id: PAYMENT_REFUND_METHODS.BY_CASHLESS,
    title: ts(PAYMENT_REFUND_METHODS.BY_CASHLESS),
  },
  {
    id: PAYMENT_REFUND_METHODS.BY_CARD,
    title: ts(PAYMENT_REFUND_METHODS.BY_CARD),
  },
] as const
