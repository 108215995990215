import { MSiSchema } from '@/vue_present/_base/Tables/MSiGenerator/MSiSchema'
import { MTableSchema } from '@/vue_present/_base/Tables/MTableGenerator/MTableSchema'
import { MTableHeader } from '@/vue_present/_base/Tables/MTableGenerator/MTableHeader'

export const getClinicalGuidelineImportSchema = () =>
  new MSiSchema()
    .addTableSchema(
      new MTableSchema()
        .addHeaders({
          titleCell: new MTableHeader(t('title'))
            .addFixedWidth('100%'),
          duplicateWarningCell: new MTableHeader()
            .addFixedWidth('50px'),
        })
        .addCheckboxes()
    )
