import { EXTENSIBLE_LIST_ACTIONS as actions } from '../actions/entityActions'
import { findById, getNewEntity, sort } from './helpers'

const OpenListReducer = (state = [], { type, payload }) => {
  let newState = [...state]
  let item = false
  if (payload && payload.id) {
    item = findById(newState, payload.id)
  }

  // define actions
  switch (type) {
    case actions.LOAD:
      return payload || newState

    case actions.ADD : {
      const newEntity = getNewEntity(newState, payload)
      newEntity.defaultValue = false
      if (payload.data && payload.data.data) {
        newEntity.defaultValue = payload.data.data.defaultValue
        newEntity.value = payload.data.data.value
      }
      newState.push(newEntity)

      return newState
    }

    case actions.RESTORE :
      newState = [...state]
      newState.push(payload.item)
      newState = sort(newState)

      return newState

    case actions.DELETE:
      newState = [...state].filter(
        (item) => +item.id !== +payload
      )

      return newState

    case actions.CHANGE:
      item.value = payload.value
      item.defaultValue = payload.defaultValue

      return newState

    case actions.UPDATE:
      item.defaultValue = payload.value

      return newState

    default:
      return state
  }
}

export default OpenListReducer
