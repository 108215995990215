
export class NumberHelpers {
  static toMoney (value: any, fractionDigits = 2) {
    return parseFloat(parseFloat(value).toFixed(fractionDigits) || '0') || 0
  }

  static clampToRange (num: number, min: number, max: number) {
    if (typeof num !== 'number') { return min }
    if (isNaN(num)) { return min }

    return Math.min(Math.max(num, min), max)
  }
}
