<template>
  <div
    v-loading="loading"
    class="protocol-view__show protocol-show position-relative"
  >
    <div class="protocol-show__buttons flex w-100 hidden-print-fixed mb-indent gap-indent-small position-absolute justify-content-end">
      <print-areas-view
        v-if="protocolPrintAreas?.printAreas.length"
        :protocol-print-areas="protocolPrintAreas"
      />

      <m-button
        size="mini"
        template="print"
        type="dark"
        @click="onPrintClick()"
      />

      <protocol-save-as-template :protocol="protocol" />

      <m-button
        size="mini"
        type="dark"
        :text="t('close')"
        @click="onBackToEmk"
      />
    </div>

    <protocol-info
      :medical-record-id="medicalRecord.id"
      :protocol="protocol"
      :client="client"
      class="w-100"
    />

    <div class="protokol-content-wrapper flex-grow-1">
      <div class="protocol-content-margins">
        <div
          id="protokol-content"
          ref="protokolContent"
          class="editor-mount"
          v-html="displayedHtml"
        />

        <div
          v-if="protocol.printDoctorSignature"
          class="protokol-content-wrapper__doctor-signature"
        >
          {{ protocol.doctor.fullName }} _____________________
        </div>
      </div>
    </div>

    <div
      v-if="id"
      class="protocol-show__footer"
    >
      <m-button
        template="edit"
        :disabled="canManageSelfProtocols"
        @click="onEditClick()"
      />

      <semd-modal-wrapper
        v-if="protocol.semdType"
        :is-source-changed="false"
        :source="protocol"
        type="button"
      >
        <template #source-connector="{ semds }">
          <semds-source-connector
            :source="protocol"
            :semds="semds"
            @registerApi="connectorApi = $event"
          />
        </template>
      </semd-modal-wrapper>

      <m-button-delete
        v-if="!canManageSelfProtocols.disabled"
        class="ml-auto"
        @yes="onProtocolDelete"
      />
    </div>
  </div>
</template>

<script lang="ts">

import { defineComponent, PropType } from 'vue'
import { ProtocolApi } from '@/vue_apps/ProtocolsApp/entities/ProtocolApi'
import htmlHelper from '@/plugins/dynamic_forms/helpers/htmlHelper'
import MButton from '@/vue_present/_base/buttons/MButton/MButton.vue'
import { PrintAreas } from '@/vue_apps/ProtocolsApp/entities/printAreas/PrintAreas'
import { PrintAreasTransformer } from '@/vue_apps/ProtocolsApp/entities/printAreas/PrintAreasTransformer'
import PrintAreasView from '@/vue_apps/ProtocolsApp/components/ProtocolShowView/PrintAreasView.vue'
import ProtocolInfo from '@/vue_apps/ProtocolsApp/components/ProtocolShowView/ProtocolInfo.vue'
import SemdModalWrapper from '@/vue_apps/Semds/SemdModal/SemdModalWrapper.vue'
import SemdsSourceConnector from '@/vue_apps/Semds/SemdSourceConnector/SemdsSourceConnector.vue'
import { extractProtocolEditPath } from '@/vue_apps/ProtocolsApp/consts/extractProtocolEditPath'
import {
  ISemdsSourceConnectorAPI,
} from '@/vue_apps/Semds/SemdSourceConnector/declarations/semdsSourceConnectorDeclarations'
import ProtocolSaveAsTemplate from '@/vue_apps/ProtocolsApp/components/ProtocolShowView/ProtocolSaveAsTemplate.vue'
import MButtonDelete from '@/vue_present/_base/buttons/MButtonDelete/MButtonDelete.vue'
import { IMedicalRecord } from '@/_declarations/IMedicalRecord'
import { PROTOCOLS_ROUTE_NAMES } from '@/vue_apps/ProtocolsApp/router/protocolsRouteNames'
import { mapGetters } from 'vuex'
import { SpinnerHolder } from '@/vue_components/mixins/spinner_holder'
import { IClient } from '@/_declarations/IClient'

export default defineComponent({
  name: 'ProtocolShowView',

  components: {
    MButtonDelete,
    ProtocolSaveAsTemplate,
    SemdsSourceConnector,
    SemdModalWrapper,
    ProtocolInfo,
    PrintAreasView,
    MButton,
  },

  mixins: [SpinnerHolder],

  beforeRouteUpdate (to, from, next) {
    if (to.path === from.path) { return }

    const id = +to.params.id

    if (id) { this.fetchProtocol(id) }

    next()
  },

  props: {
    id: { type: Number, required: true },
    client: { type: Object as PropType<IClient>, required: true },
    medicalRecord: { type: Object as PropType<IMedicalRecord>, required: true },
  },

  emits: [
    'setProtocolTitle',
  ],

  data () {
    return {
      protocolPrintAreas: null as PrintAreas,
      protocol: new ProtocolApi(),
      printAreasTransformer: null as PrintAreasTransformer,
      connectorApi: null as ISemdsSourceConnectorAPI,
    }
  },

  computed: {
    ...mapGetters('globalCatalogs/doctorStore', {
      currentDoctor: 'vxGetCurrentDoctor',
    }),

    displayedHtml () {
      if (!this.printAreasTransformer) { return '' }

      return this.printAreasTransformer.transform(
        this.protocolPrintAreas.printAreas.filter((printArea) => printArea.checked)
      )
    },

    canManageSelfProtocols () {
      const enabled = this.$security.canManageEntry ||
          (!this.$security.canManageEntry && this.protocol.doctor?.id === this.currentDoctor.id)

      return {
        disabled: !enabled,
        tooltip: !enabled && t('insufficient_access_rights'),
      }
    },
  },

  created () {
    this.fetchProtocol(this.id)
  },

  methods: {
    async fetchProtocol (id: number) {
      this.protocol = new ProtocolApi({ id })
      const result = await this.withSpinner(this.protocol.load(), undefined, 500)
      if (result?.errors) { return }
      this.$emit('setProtocolTitle', this.protocol.title)

      this.redrawVariables()
      this.redrawHeader()
    },

    redrawVariables () {
      this.protocolPrintAreas = new PrintAreas(
        (JSON.parse(this.protocol.templateData || '""')?.print_area || [])
      )

      this.printAreasTransformer = new PrintAreasTransformer(this.protocol.templateHtml)

      this.$nextTick(() => {
        htmlHelper.clearHtml()
      })
    },

    redrawHeader () {
      const header: HTMLElement = document.querySelector('.clinic-header')
      if (!header) { return }

      const method = this.protocol.printClinicHeader
        ? 'remove'
        : 'add'

      header.classList[method]('display-none')
    },

    async onEditClick () {
      if (this.protocol.semdType) {
        const { cancel } = await this.connectorApi.beforeEdit()
        if (cancel) { return }
      }

      Utils.openInNewTab(extractProtocolEditPath({
        protocol: this.protocol,
        medicalRecordId: this.medicalRecord.id,
      }))
    },

    onPrintClick () {
      window.print()
    },

    async onProtocolDelete () {
      await this.protocol.delete()
      await this.$router.replace({ name: PROTOCOLS_ROUTE_NAMES.INDEX })
      this.$pubSub.emit('ProtocolEditorView:ProtocolChanged', this.protocol.id, true)
    },

    onBackToEmk () {
      this.$router.push({ name: PROTOCOLS_ROUTE_NAMES.INDEX })
    },
  },
})
</script>
