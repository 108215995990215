import { MListServiceItem } from '@/_api/_requests/MListServiceItem'
import { IPaymentEntryToClientDTO } from '@/vue_apps/FinanceModule/FinanceModuleIndex/interfaces/IPaymentEntryToClientDTO'
import { MTableCell } from '@/vue_present/_base/Tables/MTableGenerator/MTableCell'
import { PAYMENT_ENTRY_KINDS } from '@/vue_apps/FinanceModule/FinanceModuleIndex/consts/paymentEntryKinds'
import { PAYMENT_BASE_KIND } from '@/vue_apps/FinanceModule/FinanceModuleIndex/consts/paymentBaseKind'
import { PAYMENT_KINDS } from '@/vue_apps/FinanceModule/FinanceModuleIndex/consts/paymentKinds'
import { vueFilters } from '@/plugins/vue/filtersPlugin/vueFilters'
import { TPaymentEntryMember } from '@/vue_apps/FinanceModule/FinanceModuleIndex/interfaces/IPaymentEntryMember'
import {
  PAYMENT_ENTRY_MEMBER_IS_COMPLEX_MEMBER,
  PAYMENT_ENTRY_MEMBER_IS_GENERAL_ENTRY,
} from '@/vue_apps/FinanceModule/FinanceModuleIndex/consts/paymentEntryMemberTypes'
import { PAYMENT_ENTRY_ACTION } from '@/vue_apps/FinanceModule/FinanceModuleIndex/consts/paymentEntryAction'

export class PaymentEntryItem extends MListServiceItem {
  static getKind (initialKind: PAYMENT_KINDS, sum: number, unpaidSum: number) {
    const sumMonetize = Utils.toMoney(sum)
    const unpaidSumMonetize = Utils.toMoney(unpaidSum)

    if (initialKind === PAYMENT_KINDS.ORDER_INITIAL) {
      if (!unpaidSumMonetize || unpaidSumMonetize === sumMonetize) {
        return PAYMENT_ENTRY_KINDS.FULL
      }

      if (unpaidSumMonetize > 0) {
        if (sumMonetize > 0) {
          return PAYMENT_ENTRY_KINDS.INITIAL
        }

        if (!sumMonetize) {
          return PAYMENT_ENTRY_KINDS.CREDIT
        }
      }
    }

    if (initialKind === PAYMENT_KINDS.ORDER_SUBSEQUENT) {
      if (!unpaidSumMonetize || !sumMonetize) {
        return PAYMENT_ENTRY_KINDS.EMPTY
      }

      if (sumMonetize > 0) {
        return PAYMENT_ENTRY_KINDS.SUBSEQUENT
      }
    }
  }

  entryId: number

  title: MTableCell

  finalSum: number

  finalSumCell: MTableCell

  paidSum: number

  paidSumCell: MTableCell

  unpaidSum: number

  sum: number

  sumCell: MTableCell

  action: MTableCell

  keepAction: MTableCell

  kind: PAYMENT_ENTRY_KINDS

  baseKind: PAYMENT_BASE_KIND

  members: TPaymentEntryMember[] = []

  member: typeof PAYMENT_ENTRY_MEMBER_IS_GENERAL_ENTRY | typeof PAYMENT_ENTRY_MEMBER_IS_COMPLEX_MEMBER

  constructor (item: IPaymentEntryToClientDTO, initialKind: PAYMENT_KINDS) {
    super()

    this.id = item.id

    this.entryId = item.entryId

    this.title = new MTableCell(item.title)
      .addTooltip(true)
      .addCut()

    this.finalSum = Utils.toMoney(item.finalSum)

    this.paidSum = Utils.toMoney(item.paidSum)

    this.finalSumCell = new MTableCell(vueFilters.currency(this.finalSum + this.paidSum))
      .addTooltip(true)
      .addCut()

    this.paidSumCell = new MTableCell(vueFilters.currency(this.paidSum))
      .addTooltip(true)
      .addCut()

    this.unpaidSum = Utils.toMoney(item.unpaidSum)

    this.action = new MTableCell()

    this.keepAction = new MTableCell()
      .addTypeIcon(PAYMENT_ENTRY_ACTION.PLUS_SQUARE)
      .addClasses('success')

    this.members = item.members?.map((entryMember) => ({
      ...entryMember,
      kind: entryMember.kind || item.kind || initialKind,
      paidSum: Utils.toMoney(entryMember.paidSum),
      unpaidSum: Utils.toMoney(entryMember.unpaidSum),
      sum: Utils.toMoney(entryMember.sum),
    })) ?? []

    this.setMember(item)

    this.setSum(item.sum)

    if (item.kind) {
      this.kind = item.kind

      return
    }

    this.setKind(initialKind)
  }

  setActionVisibility (value: boolean) {
    if (Boolean(this.action.value) === value) {
      return
    }

    if (!value) {
      this.setValue('keepAction', this.action)

      this.setValue('action', new MTableCell())

      return
    }

    this.setValue('action', this.keepAction)

    this.setValue('keepAction', new MTableCell())
  }

  setSum (value: number) {
    const sumMonetize = Utils.toMoney(value)
    this.setValue('sum', sumMonetize)

    this.setValue('sumCell', new MTableCell(vueFilters.currency(this.sum))
      .addTooltip(true)
      .addCut()
      .addPreventDefault()
    )

    const action = new MTableCell()
      .addTypeIcon(sumMonetize
        ? PAYMENT_ENTRY_ACTION.MINUS_SQUARE
        : PAYMENT_ENTRY_ACTION.PLUS_SQUARE)
      .addClasses(sumMonetize
        ? 'danger'
        : 'success')
      .addPreventDefault()

    if (this.action.value) {
      this.setValue('action', action)
    }

    if (this.keepAction.value) {
      this.setValue('keepAction', action)
    }
  }

  setKind (initialKind: PAYMENT_KINDS, sum: number = this.sum) {
    this.setValue('kind', PaymentEntryItem.getKind(initialKind, sum, this.unpaidSum))
  }

  setBaseKind (baseKind: PAYMENT_BASE_KIND) {
    this.setValue('baseKind', baseKind)
    this.setMemberField('baseKind', baseKind)
  }

  private setMemberField (field: keyof TPaymentEntryMember, value: unknown) {
    this.members.forEach((member) => {
      member[field] = value
    })
  }

  private setMember (item: IPaymentEntryToClientDTO) {
    this.member = item.member
      ? PAYMENT_ENTRY_MEMBER_IS_COMPLEX_MEMBER
      : PAYMENT_ENTRY_MEMBER_IS_GENERAL_ENTRY
  }
}
