import {
  ACQUIRING_PAYMENT_TYPE,
} from '@/vue_apps/FinanceModule/FinanceModuleIndex/consts/Acquiring/AcquiringPaymentType'
import {
  ACQUIRING_OPERATION_TYPE,
} from '@/vue_apps/FinanceModule/FinanceModuleIndex/consts/Acquiring/AcquiringOperationType'
import { ICatalog } from '@/_declarations/ICatalog'
import { PaymentApi } from '@/vue_apps/FinanceModule/FinanceModuleIndex/classes/Payment/PaymentApi'
import {
  PaymentDistributorFundLogic,
} from '@/vue_apps/FinanceModule/FinanceModuleIndex/classes/Payment/PaymentDistributorFundLogic'
import {
  PaymentDistributorRefundLogic,
} from '@/vue_apps/FinanceModule/FinanceModuleIndex/classes/Payment/PaymentDistributorRefundLogic'
import {
  AcquiringTerminalApiBase,
} from '@/vue_apps/catalogs_root/Workplaces/classes/AcquiringTerminalBase/AcquiringTerminalApiBase'
import {
  IAcquiringTransaction,
} from '@/vue_apps/FinanceModule/FinanceModuleIndex/interfaces/Acquiring/IAcquiringTransaction'
import { orNull } from '@/_declarations/orNull'
import {
  ACQUIRING_MANUAL_TRANSACTION_STATUS,
} from '@/vue_apps/FinanceModule/FinanceModuleIndex/consts/Acquiring/AcquiringManualTransactionStatusItems'

export class AcquiringModel extends AcquiringTerminalApiBase {
  payment: PaymentApi<PaymentDistributorFundLogic | PaymentDistributorRefundLogic>

  paymentType: ACQUIRING_PAYMENT_TYPE = null

  operationType: ACQUIRING_OPERATION_TYPE

  lastTransaction: orNull<IAcquiringTransaction> = null

  manualOperationType: ICatalog<ACQUIRING_MANUAL_TRANSACTION_STATUS>

  sbpFetchStatusTimeout: orNull<NodeJS.Timeout> = null
}
