import ClinicalGuidelinesCatalogEditor
  from '@/vue_apps/catalogs_root/ClinicalGuidelinesCatalog/components/ClinicalGuidelinesCatalogEditor.vue'
import ClinicalGuidelinesCatalog from '@/vue_apps/catalogs_root/ClinicalGuidelinesCatalog/ClinicalGuidelinesCatalog.vue'
import {
  CLINICAL_GUIDELINES_CATALOG_ROUTE_NAMES,
} from '@/vue_apps/catalogs_root/ClinicalGuidelinesCatalog/router/clinicalGuidelinesCatalogRouteNames'
import { tScoped } from '@/helpers/i18nHelpers'

const ts = tScoped('clinicalGuidelines.catalog.title')

export const clinicalGuidelinesCatalogsRoutes = {
  path: '/clinical-guidelines-catalog',
  name: CLINICAL_GUIDELINES_CATALOG_ROUTE_NAMES.INDEX,

  component: ClinicalGuidelinesCatalog,

  children: [
    {
      path: 'new',
      name: CLINICAL_GUIDELINES_CATALOG_ROUTE_NAMES.NEW,
      meta: { title: ts('create') },
      component: ClinicalGuidelinesCatalogEditor,
    },
    {
      path: ':id/edit',
      name: CLINICAL_GUIDELINES_CATALOG_ROUTE_NAMES.EDIT,
      meta: { title: ts('edit') },
      component: ClinicalGuidelinesCatalogEditor,
    },
  ],
}
