<template>
  <div class="semd-entity-select-wrapper">
    <semd-entity-select
      :semd-type="semdType"
      :selected-semd-entities="selectedSemdEntities"
      :semd-entities="semdEntities"
      @removeTag="removeSemdEntity"
      @handleOptionClick="handleOptionClick"
      @clear="$emit('clear', $event)"
    />

    <semd-entity-context-popup
      v-model="activeSemdEntity"
      :editor="editor"
      :semd-entities="semdEntities"
      :is-template-records="isTemplateRecords"
      @editActiveSemdEntity="onActiveSemdEntityEdit"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import { ISemdEntity } from '@/vue_apps/ProtocolsApp/_SemdProtocolEditor/interfaces/ISemdEntity'
import { FormBuilderReduxStore } from '@/vue_apps/ProtocolsApp/entities/reduxStore/FormBuilderReduxStore'
import SemdEntitySelect
  from '@/vue_apps/ProtocolsApp/_SemdProtocolEditor/components/SemdEntitySelectWrapper/SemdEntitySelect.vue'
import { SEMD_ENTITIES_ACTIONS } from '@/vue_apps/ProtocolsApp/_SemdProtocolEditor/reducers/semdEntitiesActions'
import { TTinyMCEEditor } from '@/vue_apps/ProtocolsApp/_SemdProtocolEditor/interfaces/TTinyMCE'
import SemdEntityContextPopup
  from '@/vue_apps/ProtocolsApp/_SemdProtocolEditor/components/SemdEntitySelectWrapper/SemdEntityContextPopup.vue'
import { SemdArea } from '@/vue_apps/ProtocolsApp/_SemdProtocolEditor/_SemdEntities/_semdBaseEntities/SemdArea'
import { SEMD_TYPE_ENUM } from '@/vue_apps/Semds/const/semdTypes'
import { dropSemdAreasColor } from '@/vue_apps/ProtocolsApp/_SemdProtocolEditor/const/dropSemdAreasColor'
import { onConfirmSemdLayoutClear } from '@/vue_apps/ProtocolsApp/logic/onConfirmSemdLayoutClear'

export default defineComponent({
  name: 'SemdEntitySelectWrapper',

  components: {
    SemdEntityContextPopup,
    SemdEntitySelect,
  },

  props: {
    semdType: {
      type: String as PropType<keyof typeof SEMD_TYPE_ENUM>,
      required: true,
    },

    selectedSemdEntities: {
      type: Array as PropType<ISemdEntity[]>,
      required: true,
    },

    semdEntities: {
      type: Array as PropType<ISemdEntity[]>,
      required: true,
    },

    formBuilderReduxStore: {
      type: Object as PropType<FormBuilderReduxStore>,
      required: true,
    },

    editor: {
      type: Object as PropType<TTinyMCEEditor>,
      required: true,
    },

    isTemplateRecords: Boolean,
  },

  emits: [
    'clear',
  ],

  data () {
    return {
      activeSemdEntity: null as ISemdEntity | null,
    }
  },

  computed: {
    selectedSemdEntitiesMap () {
      return Utils.arrayToMap(this.selectedSemdEntities)
    },

    semdEntitiesIds () {
      return this.semdEntities.map((sE) => sE.id)
    },
  },

  mounted () {
    this.$pubSub.subscribe(`removeSemdEntity:${this.editor.id}`, this.removeSemdEntityById)
    this.$pubSub.subscribe(`onDeleteContentArea:${this.editor.id}`, this.onDeleteContentArea)
    this.$pubSub.subscribe(`linkSemdSelectItems:${this.editor.id}`, this.linkElement)
  },

  beforeDestroy () {
    this.$pubSub.unsubscribe(`removeSemdEntity:${this.editor.id}`, this.removeSemdEntityById)
    this.$pubSub.unsubscribe(`onDeleteContentArea:${this.editor.id}`, this.onDeleteContentArea)
    this.$pubSub.unsubscribe(`linkSemdSelectItems:${this.editor.id}`, this.linkElement)
  },

  methods: {
    /** @param {ISemdEntity} item */
    __onSetSelected (item) {
      item.createElement(this.editor)

      this.formBuilderReduxStore.dispatch(
        SEMD_ENTITIES_ACTIONS.SET_VALUE,
        { field: item.id, value: true }
      )
    },

    /** @param {ISemdEntity} item */
    __onSetUnselected (item) {
      this.resetActiveSemdEntity()

      item.destroy(this.editor)
      this.formBuilderReduxStore.dispatch(
        SEMD_ENTITIES_ACTIONS.DROP_FIELD,
        { field: item.id }
      )
    },

    resetActiveSemdEntity () {
      this.activeSemdEntity = null
    },

    async removeSemdEntity (semdEntity: ISemdEntity) {
      const { cancel } = await onConfirmSemdLayoutClear(
        t('semds.delete.layout', { title: semdEntity.title })
      )

      if (cancel) { return }

      this.__onSetUnselected(semdEntity)
    },

    async handleOptionClick (item: { option: ISemdEntity; selected: boolean }) {
      if (!item.selected) {
        const { cancel } = await onConfirmSemdLayoutClear(
          t('semds.delete.layout',
            { title: item.option.title })
        )
        if (cancel) { return }
      }

      item.selected
        ? this.__onSetSelected(item.option)
        : this.__onSetUnselected(item.option)
    },

    onActiveSemdEntityEdit (payload: { semdEntity: ISemdEntity; newValue: any }) {
      payload.semdEntity.update(this.editor, payload.newValue)

      this.formBuilderReduxStore.dispatch(
        SEMD_ENTITIES_ACTIONS.SET_VALUE,
        { field: payload.semdEntity.id, value: payload.newValue }
      )

      this.$pubSub.emit('ProtocolEditorView:onTemplateHtmlChange')
    },

    /********************************* PUBSUBS *************************************/
    removeSemdEntityById (semdEntityId: string) {
      const semdEntity = this.selectedSemdEntitiesMap[semdEntityId]
      if (!semdEntity) { return }
      this.removeSemdEntity(semdEntity)
    },

    /** @param {JQuery} $content */
    onDeleteContentArea ($content) {
      this.semdEntitiesIds
        .map((entityId) =>
          $content.find(`[data-id="${entityId}"]`).length &&
              this.selectedSemdEntitiesMap[entityId]
        )
        .filter(Boolean)
        .forEach((item) => this.__onSetUnselected(item))
    },

    linkElement (semdEntityId: string | 'all') {
      dropSemdAreasColor(this.editor)
      this.selectedSemdEntities
        .filter((semdEntity) => semdEntity instanceof SemdArea)
        .forEach((semdEntity) => semdEntity.linkWithElement(this.editor))

      if (semdEntityId === 'all') { return }

      const semdEntity = this.semdEntities.find(({ id }) => id === semdEntityId)
      setTimeout(() => {
        if (semdEntity instanceof SemdArea) { return }
        if (!this.selectedSemdEntitiesMap[semdEntityId]) { return }

        semdEntity.linkWithElement(this.editor)
        this.activeSemdEntity = semdEntity
      })
    },
  },
})
</script>
