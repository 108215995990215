<template>
  <div class="protocols-tree-modal">
    <m-modal
      :visible.sync="modalVisibility"
      :dialog-title="t('template_record')"
      modal-title-icon="templateRecord"
      type="primary"
      @close="closeModal"
    >
      <slot name="beforeTree" />

      <m-search-input-lazy
        v-if="useSearch"
        :list="templateList"
        clear-input-after-item-click
        @onItemClick="onNodeClick"
      />

      <reusable-lazy-tree
        v-slot="{ node, data}"
        :tree="tree"
        :show-checkboxes="showCheckboxes"
        :check-strictly="checkStrictly"
        :check-on-click-node="checkOnClickNode"
        :use-radio-check="useRadioCheck"
        :use-root-node="useRootNode"
        @onNodeClick="onNodeClick"
        @onCheckChange="$emit('onCheckChange', $event)"
        @setTreeRef="treeRef = $event"
      >
        <slot
          name="treeNode"
          :node="node"
          :data="data"
        >
          <lazy-tree-node-component
            :data="data"
            :node="node"
          />
        </slot>
      </reusable-lazy-tree>

      <template #footer-left>
        <slot name="footer-left" />
      </template>
    </m-modal>

    <slot
      :activator="openModal"
      :close="closeModal"
    >
      <m-button
        :text="t('change_template_protokol')"
        icon="reset"
        disabled="TemplateRecord:View"
        @click="modalVisibility = true"
      />
    </slot>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import MButton from '@/vue_present/_base/buttons/MButton/MButton.vue'
import MModal from '@/vue_present/_base/MModal/MModal.vue'
import ReusableLazyTree from '@/vue_present/Reuse/LazyTree/ReusableLazyTree.vue'
import LazyTree from '@/vue_present/Reuse/LazyTree/store/LazyTree'
import { ProtocolsCategoriesPresenter } from '@/vue_present/Reuse/ProtocolsTree/api/ProtocolsCategoriesPresenter'
import LazyTreeNodeComponent from '@/vue_present/Reuse/LazyTree/components/LazyTreeNodeComponent.vue'
import { extractItemId } from '@/vue_apps/catalogs_root/EntryPacks/const/extractItemId'
import MSearchInputLazy from '@/vue_present/_base/MSearchInputLazy/MSearchInputLazy.vue'
import { MListServiceApi } from '@/_api/_requests/MListServiceApi/MListServiceApi'
import { CategoriesSearchStrategy } from '@/_api/_requests/MListServiceApi/strategies/CategoriesSearchStrategy'
import { TemplateRecordsListItem } from '@/vue_present/Reuse/ProtocolsTree/api/TemplateRecordsListItem'

export default defineComponent({
  name: 'ReusableTemplateRecordsTreeModal',

  components: {
    MSearchInputLazy,
    LazyTreeNodeComponent,
    ReusableLazyTree,
    MModal,
    MButton,
  },

  props: {
    useItems: { type: Boolean, default: true },
    showCheckboxes: Boolean,
    checkStrictly: Boolean,
    checkOnClickNode: Boolean,
    useRadioCheck: Boolean,
    useRootNode: Boolean,
    useSearch: Boolean,
  },

  emits: [
    'registerApi',
    /** @param {ITemplateRecordsTreeItem} item */
    'templateClick',
    'onCheckChange',
  ],

  data () {
    return {
      modalVisibility: false,
      tree: new LazyTree(
        new ProtocolsCategoriesPresenter({ useItems: this.useItems }),
        {
          extraAttributes: [
            'templateData',
            'templateHtml',
            'printClinicHeader',
            'printDoctorSignature',
            'printEntryInfo',
            'printEntryTitle',
            'semdTemplateDocumentDetail',
          ],
        }
      ),

      templateList: new MListServiceApi({
        src: Routes.search_template_records_path(),
        DataItemEntity: TemplateRecordsListItem,
        defaultFilters: { title: '' },
        Strategy: CategoriesSearchStrategy,
      }),

      treeRef: null,
    }
  },

  created () {
    this.$emit('registerApi', {
      openModal: this.openModal.bind(this),
      closeModal: this.closeModal.bind(this),
    })
  },

  methods: {
    openModal () {
      this.modalVisibility = true
    },

    closeModal () {
      this.modalVisibility = false

      if (this.showCheckboxes) {
        this.treeRef.getCheckedKeys()
          .forEach((id) => this.treeRef.setChecked(id, false))
      }
    },

    /** @param {LazyTreeNode} item */
    onNodeClick (item) {
      if (!item.isLeaf) { return }

      this.closeModal()

      /** @type {ITemplateRecordsTreeItem}*/
      const protocolItem = {
        id: +extractItemId(item.id),
        title: item.title,
        ...item.extraAttributes,
      }

      this.$emit('templateClick', protocolItem)
    },
  },
})
</script>
