export enum ACQUIRING_ACTION {
  MODULE_STATUSES = 'module_statuses',
  CHECKING_CONNECTION = 'checking_connection',
  REPORT = 'report',
  TERMINAL_INFO = 'terminal_info',
  RECONCILIATION_OF_RESULTS = 'reconciliation_of_results',
  COPY_OF_RECEIPT = 'copy_of_receipt',
  PAY = 'pay',
  REFUND = 'refund',
  CANCEL = 'cancel',
  PAYMENT_STATUS = 'payment_status',
  REFUND_STATUS = 'refund_status',
}
