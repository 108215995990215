<template>
  <m-collapse-item
    :name="collapseItem.id"
    :title="collapseItem.title"
    :active="active"
    active-icon="folderOpen"
    inactive-icon="folder"
    active-icon-color="warning"
    inactive-icon-color="warning"
  >
    <template #afterTitle>
      <slot name="afterTitle" />
    </template>

    <slot />
  </m-collapse-item>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import MCollapseItem from '@/vue_present/_base/MCollapse/MCollapseItem.vue'
import {
  CLINICAL_GUIDELINE_COLLAPSE_ITEM,
  clinicalGuidelineCollapseItems,
} from '@/vue_apps/ClinicalGuidelines/consts/clinicalGuidelineCollapseItems'
import { CatalogHelpers } from '@/helpers/CatalogHelpers'

export default defineComponent({
  name: 'ClinicalGuidelineCollapseItem',

  components: { MCollapseItem },

  props: {
    activeNames: { type: Array as PropType<Array<string | number>>, required: true },
    collapseItemId: { type: String as PropType<CLINICAL_GUIDELINE_COLLAPSE_ITEM>, required: true },
  },

  computed: {
    collapseItem () {
      return CatalogHelpers.getItemFromCatalogItems(
        clinicalGuidelineCollapseItems,
        this.collapseItemId
      )
    },

    active () {
      return this.activeNames.includes(this.collapseItemId)
    },
  },
})
</script>
