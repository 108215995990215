import { CALCULATE_ACTIONS } from '../actions/entityActions'

class UpdateFormulasListener {
  /**
   * insert data to tinymce to fix element
   */
  handle (event) {
    const oldData = event.oldData
    const manager = event.manager
    const store = manager.store
    const findMathRegExp = new RegExp(`${oldData.data.name}`)
    const calc = store.getState().calculate.find(
      (item) => item.value.match(findMathRegExp)
    )
    if (calc) {
      const newValue = calc.value.replace(findMathRegExp, event.newData.name)
      store.dispatch({
        type: CALCULATE_ACTIONS.UPDATE,
        payload: { id: calc.id, value: newValue },
      })
    }
  }
}

export default UpdateFormulasListener
