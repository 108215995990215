import { getTableSchema } from '@/vue_present/_base/Tables/MTableGenerator/logic/getTableSchema'

export class MTableSchema {
  /** @type {TTableSchemaHeaders} */
  headers = {}

  /**  @type {TTableSchemaOptions} */
  options = {
    useCheckboxes: false,
    useHeader: false,
    useSortable: false,
    useFooter: false,
    useEdit: false,
    useDelete: false,
    useIgnoreClick: false,
    useMinWidth: false,
  }

  /** @param {TTableSchema | null} [tableSchema] */
  constructor (tableSchema = null) {
    if (tableSchema) { this.__fillTableSchema(tableSchema) }
  }

  /**
   * @param {TTableSchema} tableSchema
   * @private
   */
  __fillTableSchema (tableSchema) {
    const { headers, options } = getTableSchema(tableSchema)

    this.headers = headers
    Object.entries(options).forEach(([key, value]) => {
      this.options[key] = value
    })
  }

  addCheckboxes (value = true) {
    this.options.useCheckboxes = value

    return this
  }

  /**
   * @type {TTableSchemaHeaders} tableHeaders
   * @return MTableSchema
   */
  addHeaders (tableHeaders, showHeader = true) {
    this.options.useHeader = showHeader
    this.headers = tableHeaders

    return this
  }

  /**
   * @type {TTableSchemaHeaders} tableHeaders
   * @return MTableSchema
   */
  appendHeaders (tableHeaders) {
    Object.assign(this.headers, tableHeaders)

    return this
  }

  addSorting () {
    this.options.useSortable = true

    return this
  }

  addFooter (value = true) {
    this.options.useFooter = value

    return this
  }

  addEditIcon (value = true) {
    this.options.useEdit = value

    return this
  }

  addDeleteIcon (value = true) {
    this.options.useDelete = value

    return this
  }

  addIgnoreClick (value = true) {
    this.options.useIgnoreClick = value

    return this
  }

  /**
   * Добавляет минимальную ширину для таблицы,
   * требуется когда таблица слишком широкая и нужен горизонтальный скролл
   *
   * @param minWidth
   * @return {MTableSchema}
   */
  addMinWidth (minWidth = '') {
    this.options.useMinWidth = minWidth

    return this
  }
}
