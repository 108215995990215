import { lazyTreeLeafNodeIdPrefix } from '@/vue_present/Reuse/LazyTree/store/lazyTreeLeafNodeIdPrefix'

/** @implements ILazyTreeNode */
export class LazyTreeNode {
  /** @type {number | string} */
  id

  /** @type {string} */
  title

  /** @type {boolean} */
  isLeaf

  /** @type {ILazyTreeNode} */
  parent = null

  /** @type {ILazyTreeNode[]} */
  children = []

  selectable = true

  selected = false

  extraAttributes = {}

  /**  @type {LazyTree} */
  tree

  /**
   * @param {ILazyTree} tree
   * @param {Object} rawNode
   * @param {boolean} isLeaf
   * @param {string[]}extraAttributes
   */
  constructor (tree, rawNode, isLeaf = false, extraAttributes = []) {
    this.tree = tree
    this.id = isLeaf ? `${lazyTreeLeafNodeIdPrefix}${rawNode.id}` : rawNode.id
    this.title = rawNode.title || rawNode.name
    this.isLeaf = isLeaf

    if (rawNode.id) {
      this.parent = tree.treeMap[rawNode.parentId || 0]
    }

    if (isLeaf) { this.children = null }

    // TODO: пока что через этот Object.hasOwn костыль
    if (Object.hasOwn(rawNode, 'kind')) {
      this.kind = rawNode.kind
    }

    if (Object.hasOwn(rawNode, 'price')) {
      this.price = Utils.toMoney(rawNode.price)
    }

    extraAttributes.forEach((eA) => {
      this.extraAttributes[eA] = rawNode[eA]
    })
  }

  select (selected = false) {
    if (!this.selectable && selected) { return }
    this.selected = selected
    this.tree.selectNode(this.id, selected)

    if (this.children) {
      this.children.forEach((child) => { child.select(selected) })
    }
  }
}
