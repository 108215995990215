import { AcquiringModel } from '@/vue_apps/FinanceModule/FinanceModuleIndex/classes/Acquiring/AcquiringModel'
import {
  ACQUIRING_STATUS,
  ACQUIRING_STATUS_CODE,
  ACQUIRING_STATUS_CODE_TO_TITLE_MAP,
} from '@/vue_apps/FinanceModule/FinanceModuleIndex/consts/Acquiring/AcquiringStatus'
import {
  IAcquiringRequestAwaitListItem,
} from '@/vue_apps/FinanceModule/FinanceModuleIndex/interfaces/Acquiring/IAcquiringRequestAwaitListItem'
import { IAcquiringData } from '@/vue_apps/FinanceModule/FinanceModuleIndex/interfaces/Acquiring/IAcquiringData'
import {
  IAcquiringSBPStatus,
} from '@/vue_apps/FinanceModule/FinanceModuleIndex/interfaces/Acquiring/IAcquiringSBPStatus'
import { TAcquiringWSData } from '@/vue_apps/FinanceModule/FinanceModuleIndex/interfaces/Acquiring/IAcquiringWSData'
import {
  acquiringExtractError,
} from '@/vue_apps/FinanceModule/FinanceModuleIndex/consts/Acquiring/AcquiringExtractError'
import { IErrorArray } from '@/_declarations/IErrorArray'
import {
  IAcquiringTransaction,
} from '@/vue_apps/FinanceModule/FinanceModuleIndex/interfaces/Acquiring/IAcquiringTransaction'
import { ACQUIRING_ACTION } from '@/vue_apps/FinanceModule/FinanceModuleIndex/consts/Acquiring/AcquiringAction'
import {
  isTransactionInCurrentOperationDay,
} from '@/vue_apps/FinanceModule/FinanceModuleIndex/consts/Acquiring/isTransactionInCurrentOperationDay'
import {
  ACQUIRING_PAYMENT_TYPE,
} from '@/vue_apps/FinanceModule/FinanceModuleIndex/consts/Acquiring/AcquiringPaymentType'
import {
  ACQUIRING_OPERATION_TYPE,
} from '@/vue_apps/FinanceModule/FinanceModuleIndex/consts/Acquiring/AcquiringOperationType'
import { IError } from '@/_declarations/IError'
import { ACQUIRING_OPERATION } from '@/vue_apps/FinanceModule/FinanceModuleIndex/consts/Acquiring/AcquiringOperation'
import {
  ACQUIRING_SBP_IN_PROGRESS_TIMEOUT,
} from '@/vue_apps/FinanceModule/FinanceModuleIndex/consts/Acquiring/AcquiringTimeout'
import { IWSMessage } from '@/_declarations/IWSMessage'
import {
  IAcquiringPrintSlipData,
} from '@/vue_apps/FinanceModule/FinanceModuleIndex/interfaces/Acquiring/IAcquiringPrintSlipData'
import { tScoped } from '@/helpers/i18nHelpers'
import { camelCase } from 'lodash'

const ts = tScoped('finance')

export class AcquiringLogic extends AcquiringModel {
  constructor () {
    super()

    this.printSlipReceiptPubSubHandler()
  }

  protected wsErrorsHandler (awaitRequestListItem: IAcquiringRequestAwaitListItem, data: TAcquiringWSData) {
    const isPaymentOperation = this.isPaymentOperation(awaitRequestListItem.operation)
    const hasTransaction = 'transaction' in data
    const hasDescription = 'description' in data

    if (hasTransaction) {
      this.wsErrorsHandlerTransaction(data.transaction)
    }

    if (hasDescription) {
      this.setTransactionDescription(data.description)
    }

    if (hasDescription && !isPaymentOperation) {
      return this.addFailureTerminalLog(awaitRequestListItem.operation, data.description)
    }

    if (hasTransaction && isPaymentOperation) {
      return this.wsErrorsHandlerPaymentOperationHandler(awaitRequestListItem, data)
    }

    if (isPaymentOperation) {
      this.resetPaymentOperation()
    }

    this.addFailureTerminalLog(awaitRequestListItem.operation, acquiringExtractError(data as IErrorArray))
  }

  protected transactionHandler (awaitRequestListItem: IAcquiringRequestAwaitListItem, data: IAcquiringData) {
    super.setValue('lastTransaction', data.transaction)
    super.setValue('status', data.status)
    this.setTransactionDescription(data.description)

    if (data.status === ACQUIRING_STATUS_CODE.IN_PROGRESS) return

    data.status === ACQUIRING_STATUS_CODE.SUCCESS
      ? this.addSuccessTerminalLog(awaitRequestListItem.operation)
      : this.addFailureTerminalLog(awaitRequestListItem.operation)
  }

  protected externalRequestHandler (action: ACQUIRING_ACTION, data: TAcquiringWSData, isSuccess: boolean) {
    if (!isSuccess) return

    if (action === ACQUIRING_ACTION.CHECKING_CONNECTION || action === ACQUIRING_ACTION.RECONCILIATION_OF_RESULTS) {
      this.setRefundOperationType()
    }
  }

  protected startSBPFetchStatusTimeout (
    awaitRequestListItem: IAcquiringRequestAwaitListItem,
    data: TAcquiringWSData,
    fetchSBPOperationStatus: () => void,
    fetchSBPRefundStatus: () => void
  ) {
    if (!('status' in data)) return

    const operation = awaitRequestListItem.operation
    if ((operation !== ACQUIRING_OPERATION.PAY_SBP && operation !== ACQUIRING_OPERATION.REFUND_SBP) ||
        data.status !== ACQUIRING_STATUS_CODE.IN_PROGRESS) return

    this.sbpFetchStatusTimeout = setTimeout(() => {
      operation === ACQUIRING_OPERATION.PAY_SBP
        ? fetchSBPOperationStatus()
        : fetchSBPRefundStatus()
    }, ACQUIRING_SBP_IN_PROGRESS_TIMEOUT)
  }

  protected sbpStatusHandler (data: IAcquiringSBPStatus, awaitRequestListItem: IAcquiringRequestAwaitListItem, fetchSBPStatus: () => void) {
    const statusTitle = camelCase(ACQUIRING_STATUS_CODE_TO_TITLE_MAP[data.status])
    const customMessage = `${ts('operationStatus')} "${ts(`transactionStatus.${statusTitle}`)}"`
    this.addSuccessTerminalLog(awaitRequestListItem.operation, customMessage)

    if (data.status !== ACQUIRING_STATUS_CODE.IN_PROGRESS) return

    clearTimeout(this.sbpFetchStatusTimeout)

    this.sbpFetchStatusTimeout = setTimeout(() => {
      fetchSBPStatus()
    }, ACQUIRING_SBP_IN_PROGRESS_TIMEOUT)
  }

  protected setRefundOperationType () {
    if (!this.payment.lastTransaction) return
    const operationType = this.getRefundOperationType()
    super.setValue('operationType', operationType)
  }

  protected forceCompleteHandler (transaction: IAcquiringTransaction | IError) {
    if ('errors' in transaction) {
      return this.addFailureTerminalLog(ACQUIRING_OPERATION.FORCE_COMPLETE)
    }

    this.addSuccessTerminalLog(ACQUIRING_OPERATION.FORCE_COMPLETE)
    super.setValue('manualOperationType', null)
    super.setValue('lastTransaction', transaction)

    if (transaction.status === ACQUIRING_STATUS.FAILURE) {
      return this.resetPaymentOperation()
    }

    if (transaction.status === ACQUIRING_STATUS.SUCCESS) {
      super.setValue('status', ACQUIRING_STATUS_CODE.SUCCESS)
    }
  }

  private resetPaymentOperation () {
    super.setValue('status', ACQUIRING_STATUS_CODE.INIT)
    super.setValue('paymentType', null)
  }

  private wsErrorsHandlerTransaction (transaction: IAcquiringTransaction) {
    super.setValue('lastTransaction', transaction)
  }

  private wsErrorsHandlerPaymentOperationHandler (awaitRequestListItem: IAcquiringRequestAwaitListItem, data: TAcquiringWSData) {
    const hasStatus = 'status' in data
    const status = hasStatus
      ? data.status
      : ACQUIRING_STATUS_CODE.EXECUTION_ERROR

    status === ACQUIRING_STATUS_CODE.FAILURE
      ? this.resetPaymentOperation()
      : super.setValue('status', status)

    const customMessage = 'description' in data
      ? data.description
      : null

    this.addFailureTerminalLog(awaitRequestListItem.operation, customMessage)
  }

  private printSlipReceiptPubSubHandler () {
    Services.pubSub.subscribe('workplace.print_slip_receipt', ({ data }: IWSMessage<IAcquiringPrintSlipData>) => {
      data.transaction_id
        ? this.addSuccessTerminalLog(ACQUIRING_OPERATION.PRINT_SLIP)
        : this.addFailureTerminalLog(ACQUIRING_OPERATION.PRINT_SLIP)
    })
  }

  private getRefundOperationType () {
    if (this.payment.lastTransaction.paymentType === ACQUIRING_PAYMENT_TYPE.SBP) {
      return ACQUIRING_OPERATION_TYPE.REFUND
    }

    return isTransactionInCurrentOperationDay(this.payment.lastTransaction, this.lastReconciliationOfResults)
      ? ACQUIRING_OPERATION_TYPE.CANCEL
      : ACQUIRING_OPERATION_TYPE.REFUND
  }

  private setTransactionDescription (value: string) {
    super.setValue('transactionDescription', capitaliseFirstLetter(value))
  }
}
