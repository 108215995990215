import { stringSanitize } from '@/plugins/dynamic_forms/reducers/helpers'
import { cloneDeep } from 'lodash'

export const getSanitizedData = (state: Record<string, any>) => {
  const storeState = cloneDeep(state)
  storeState.conclusions = storeState.conclusions.map((conclusion) => ({
    ...conclusion,
    value: stringSanitize(conclusion.value),
  }))

  storeState.complaints = storeState.complaints.map((complaint) => ({
    ...complaint,
    value: stringSanitize(complaint.value),
  }))

  storeState.destinations = storeState.destinations.map((destination) => ({
    ...destination,
    value: stringSanitize(destination.value),
  }))

  return storeState
}
