export interface IDynamicFormEntity {
  getListener: () => string
}

export class InsertDynamicEntity implements IDynamicFormEntity {
  value: any

  element: any

  constructor (value, element) {
    this.value = value
    this.element = element
  }

  getListener () {
    return 'insert_dynamic_entity'
  }
}

export class InsertFixEntity implements IDynamicFormEntity {
  value: any

  element: any

  constructor (value, element) {
    this.value = value
    this.element = element
  }

  getListener () {
    return 'insert_fix_entity'
  }
}

export class DeleteEntity implements IDynamicFormEntity {
  element: any

  constructor (element) {
    this.element = element
  }

  getListener () {
    return 'delete_entity'
  }
}

export class ShowPanelEvent implements IDynamicFormEntity {
  element: any

  constructor (element) {
    this.element = element
  }

  getListener () {
    return 'show_panel'
  }
}
export class UpdateFormulasEvent implements IDynamicFormEntity {
  oldData: any

  newData: any

  manager: any

  constructor (oldData, newData, manager) {
    this.oldData = oldData
    this.newData = newData
    this.manager = manager
  }

  getListener () {
    return 'update_formulas'
  }
}

export class BuildEntryVariableModalEvent implements IDynamicFormEntity {
  getListener () {
    return 'build_entry_variable_modal'
  }
}

export class BuildPrintAreaModalEvent implements IDynamicFormEntity {
  getListener () {
    return 'build_print_area_modal'
  }
}
