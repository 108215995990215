<template>
  <div class="m-form-show__label">
    <slot>
      {{ label }}
    </slot>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'MFormShowLabel',

  props: {
    label: { type: String, default: '' },
  },
})
</script>
