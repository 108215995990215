import { TAdapter } from '@/_api/decorators/api/interfaces/IAdapter'
import { IgRPCMeta } from '@/_api/_classes/GRPC/interfaces/IgRPCMeta'
import { IgRPCTransmitterConfig } from '@/_api/_classes/GRPC/interfaces/IgRPCTransmitterConfig'
import { IWSMessage } from '@/_declarations/IWSMessage'
import { camelToSnake, snakeToCamel } from '@/_api/_requests/helpers'
import { GRPC_END_OF_STREAM, GRPC_META_TYPE } from '@/_api/_classes/GRPC/consts/consts'
import { orUndefined } from '@/_medods_standart_library/msl'

export abstract class GRPCTransmitterAbstract<
  TData = unknown,
  TDataBatch = unknown,
  TSuccessCallbackData = unknown,
  TErrorCallbackData = unknown
> {
  protected data: TData

  protected toServer: TAdapter

  protected toClient: TAdapter

  isSubscribed: boolean = false

  hasResponse = false

  private readonly wsAction?: IgRPCTransmitterConfig['wsAction']

  private readonly wsMetaAction?: IgRPCTransmitterConfig['wsMetaAction']

  private readonly wsType?: IgRPCTransmitterConfig['wsType']

  private readonly successCallback: IgRPCTransmitterConfig<TSuccessCallbackData>['successCallback']

  private readonly errorCallback: IgRPCTransmitterConfig<TErrorCallbackData>['errorCallback']

  private ws: any

  private requestId: IgRPCMeta['requestId'] = null

  constructor ({
    wsChannel,
    wsAction,
    wsMetaAction,
    wsType,
    adapter,
    errorCallback,
    successCallback,
  }: IgRPCTransmitterConfig) {
    this.wsAction = wsAction
    this.wsType = wsType
    this.wsMetaAction = wsMetaAction

    this.toServer = adapter?.toServer || ((data: unknown) => data)
    this.toClient = adapter?.toClient || ((data: unknown) => data)

    this.successCallback = successCallback || (() => {})
    this.errorCallback = errorCallback || (() => {})

    this.ws = Services.wsSubscriptions[wsChannel].advancedConnection({
      connected: this.onConnected.bind(this),
      received: this.onReceived.bind(this),
    })
  }

  protected abstract getBatches (): TDataBatch[]

  send (data: TData) {
    if (!this.isSubscribed) { return }
    this.requestId = Utils.newGUID()
    this.hasResponse = false
    this.data = data
    const batches = this.getBatches()

    const isAllBatchesSent = !batches.some((data, index) => {
      return this.hasResponse
        ? true
        : this.ws.send(this.getPayload(data, index)) && false
    })

    if (!isAllBatchesSent) { return }

    this.ws.send(this.getPayload(GRPC_END_OF_STREAM, batches.length))
  }

  private getPayload (data: TDataBatch | typeof GRPC_END_OF_STREAM, index: number): IWSMessage<unknown, string, IgRPCMeta> {
    const payload = {
      data,
      action: orUndefined(this.wsAction),
      type: orUndefined(this.wsType),
      meta: {
        requestId: this.requestId,
        action: orUndefined(this.wsMetaAction),
        type: GRPC_META_TYPE.RESULT,
        index,
      },
    }

    return camelToSnake(payload)
  }

  private onConnected () {
    this.isSubscribed = true
  }

  private onReceived (rawPayload: IWSMessage<TSuccessCallbackData | TErrorCallbackData, string, IgRPCMeta>) {
    const {
      data,
      meta: {
        requestId,
        type,
      },
    } = snakeToCamel(rawPayload) as IWSMessage<TSuccessCallbackData | TErrorCallbackData, string, IgRPCMeta>

    if (this.requestId !== requestId) { return }

    type === GRPC_META_TYPE.RESULT
      ? this.successCallback(this.toClient(data) as TSuccessCallbackData)
      : this.errorCallback(data as TErrorCallbackData)

    this.hasResponse = true
  }
}
