import { omit, pick } from 'lodash'
import { PaymentEntryItem } from '@/vue_apps/FinanceModule/FinanceModuleIndex/classes/PaymentEntryItem'

export const paymentDestinationOrderAdapter = {
  toClient (data: Record<string, any>) {
    const destinationOrder = omit(data, ['entries', 'client'])
    const paymentEntries = pick(data, ['entries']).entries
    const destinationClient = pick(data, ['client']).client

    return {
      paymentEntries: paymentEntries.map((item) => new PaymentEntryItem({
        ...item,
        sum: 0,
        finalSum: item.unpaidSum,
      }, data.kind)),
      destinationOrder,
      destinationClient,
      clinic: {
        id: data.clinicId,
        title: null,
      },
    }
  },
}
