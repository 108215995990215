<template>
  <div class="m-form-show-row">
    <slot>
      <m-form-show-label :label="label">
        <template #default>
          <slot name="label" />
        </template>
      </m-form-show-label>

      <m-form-show-value :value="value">
        <template #default>
          <slot name="value" />
        </template>
      </m-form-show-value>
    </slot>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import MFormShowLabel from '@/vue_present/_base/MFormShow/MFormShowLabel.vue'
import MFormShowValue from '@/vue_present/_base/MFormShow/MFormShowValue.vue'

export default defineComponent({
  name: 'MFormShowRow',

  components: {
    MFormShowValue,
    MFormShowLabel,
  },

  props: {
    label: { type: String, default: '' },
    value: { type: [String, Number], default: '' },
  },
})
</script>
