<template>
  <m-si-generator
    class="payment-entries"
    :items="items"
    :si-generator-schema="msiSchema"
    @onCellClick="onCellClick"
  />
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'

import MSiGenerator from '@/vue_present/_base/Tables/MSiGenerator/MSiGenerator.vue'
import {
  fundPaymentEntriesMsiSchema,
  refundPaymentEntriesMsiSchema,
  showPaymentEntriesMsiSchema,
  showRefundPaymentEntriesMsiSchema,
} from '@/vue_apps/FinanceModule/FinanceModuleIndex/consts/paymentEntriesSchemas'
import { PAYMENT_BASE_KIND } from '@/vue_apps/FinanceModule/FinanceModuleIndex/consts/paymentBaseKind'
import { PaymentModesMixin } from '@/vue_apps/FinanceModule/FinanceModuleIndex/mixins/PaymentModesMixin'
import { PaymentApi } from '@/vue_apps/FinanceModule/FinanceModuleIndex/classes/Payment/PaymentApi'
import { PAYMENT_ENTRY_ACTION } from '@/vue_apps/FinanceModule/FinanceModuleIndex/consts/paymentEntryAction'
import {
  PaymentDistributorFundLogic,
} from '@/vue_apps/FinanceModule/FinanceModuleIndex/classes/Payment/PaymentDistributorFundLogic'

export default defineComponent({
  name: 'PaymentEntries',

  components: { MSiGenerator },

  mixins: [PaymentModesMixin],

  props: {
    payment: { type: Object as PropType<PaymentApi<PaymentDistributorFundLogic>>, required: true },
  },

  computed: {
    msiSchema () {
      if (this.isShowMode) {
        return this.payment.distributor.baseKind === PAYMENT_BASE_KIND.REFUND
          ? showRefundPaymentEntriesMsiSchema
          : showPaymentEntriesMsiSchema
      }

      return this.payment.distributor.baseKind === PAYMENT_BASE_KIND.FUND
        ? fundPaymentEntriesMsiSchema
        : refundPaymentEntriesMsiSchema
    },

    items () {
      return this.payment.distributor.paymentEntries.filter(({ member }) => !member)
    },
  },

  methods: {
    onCellClick ({ item }) {
      const action = item.action.value

      if (!action) { return }

      action === PAYMENT_ENTRY_ACTION.MINUS_SQUARE
        ? this.payment.distributor.cancelMoneyFromEntry(item)
        : this.payment.distributor.addMoneyToEntry(item)
    },
  },
})
</script>
