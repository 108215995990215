<template>
  <div class="print-raw-receipt-modal-correction-receipt">
    <m-prompt-notice :text="t$('correctionReceiptNotice')" />

    <div class="flex gap-indent-mid">
      <!-- Тип коррекции -->
      <m-select
        v-model="type"
        :items="catalogItems"
        class="print-raw-receipt-modal-correction-receipt__first-column"
        :label="t$('correctionType')"
        :placeholder="t('select')"
        required
        validator-name="type"
        @registerValidator="onRegisterValidator"
      />

      <!-- Описание коррекции -->
      <m-input
        v-model="reason"
        class="w-100"
        :label="t$('correctionReason')"
        required
        validator-name="reason"
        @registerValidator="onRegisterValidator"
      />
    </div>

    <div class="flex gap-indent-mid">
      <!-- Дата документа основания коррекции -->
      <m-date-picker
        v-model="date"
        class="print-raw-receipt-modal-correction-receipt__first-column"
        :label="t$('correctionDate')"
        value-format="yyyy-MM-dd"
        required
        validator-name="date"
        @registerValidator="onRegisterValidator"
      />

      <!-- Номер документа основания коррекции -->
      <m-input
        v-model="number"
        class="w-100"
        :label="t$('correctionNumber')"
        required
        validator-name="number"
        @registerValidator="onRegisterValidator"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import MPromptNotice from '@/vue_present/_base/MPromptNotice/MPromptNotice.vue'
import { i18nScopeMixin } from '@/vue_present/mixins/i18nScopeMixin'
import MSelect from '@/vue_present/_base/inputs/MSelect/MSelect.vue'
import { ValidationParentMixin } from '@/vue_present/mixins/ValidationParentMixin'
import MInput from '@/vue_present/_base/inputs/MInput/MInput.vue'
import MDatePicker from '@/vue_present/_base/inputs/MDatePicker/MDatePicker.vue'
import {
  PrintRawReceiptModalItemsMixin,
} from '@/vue_apps/catalogs_root/Workplaces/mixins/PrintRawReceiptModalItemsMixin'
import {
  IPrintRawReceiptReceiptCorrection,
} from '@/vue_apps/catalogs_root/Workplaces/interfaces/PrintRawReceipt/IPrintRawReceiptReceipt'

export default defineComponent({
  name: 'PrintRawReceiptModalCorrectionReceipt',

  components: {
    MDatePicker,
    MInput,
    MSelect,
    MPromptNotice,
  },

  mixins: [
    i18nScopeMixin,
    ValidationParentMixin,
    PrintRawReceiptModalItemsMixin,
  ],

  emits: ['collectCorrectionReceipt'],

  data () {
    return {
      type: null,
      reason: null,
      date: null,
      number: null,
    }
  },

  created () {
    this.setI18nScope('fiscal')
    this.setCatalogItemsPrefix('fiscal.correctionTypes')
    this.$emit('collectCorrectionReceipt', this.collectCorrectionReceipt)
  },

  methods: {
    collectCorrectionReceipt (): IPrintRawReceiptReceiptCorrection {
      return {
        type: this.type.id,
        reason: this.reason,
        date: this.date,
        number: this.number,
      }
    },
  },
})
</script>
