import { DateHelpers } from '@/helpers/DateHelpers'

export class MTableCell {
  value = null

  /** @type {TTableSchemaItemAttrType} */
  type = 'struct'

  /** @type {TTooltip} */
  tooltip = null

  /** @type {TVueCss} */
  css = null

  useCut = false

  usePreventDefault = false

  drawable = true

  /**
   * @param {string | number | TTableSchemaItemAttr} [cell=null]
   */
  constructor (cell = null) {
    if (cell && typeof cell === 'object' && !Array.isArray(cell)) {
      this.value = cell.value
      this.type = cell.type
      this.tooltip = cell.tooltip
      this.css = cell.css
      this.useCut = cell.useCut

      return
    }

    this.value = cell ?? null
  }

  /**
   * @param {TTooltip | Boolean} tooltip - если true - то value
   * @return {MTableCell}
   */
  addTooltip (tooltip) {
    this.tooltip = tooltip === true
      ? this.value
      : tooltip

    return this
  }

  /**
   * @param {TVueCss} css
   * @return {MTableCell}
   */
  addClasses (css = '') {
    this.css = css

    return this
  }

  /**
   * @param {string} icon
   * @return {MTableCell}
   */
  addTypeIcon (icon) {
    this.type = 'icon'
    this.value = icon

    return this
  }

  /**
   * Использование префикса fab для иконки
   * @param icon
   * @return {MTableCell}
   */
  addFaBrand (icon) {
    this.addTypeIcon(icon)
    this.useBrand = true

    return this
  }

  /**
   * @param {number} clientId
   * @param {string | null} phone
   * @return {MTableCell}
   */
  addTypePhone (clientId, phone = null) {
    this.type = 'phone'
    this.clientId = clientId
    this.value = phone || this.value

    return this
  }

  addTypeDateTime (useTime = false, date = null) {
    this.type = 'dateTime'
    const { formattedDate, dateCss } = DateHelpers.getTodayTomorrowYesterday(date || this.value, useTime)
    this.value = formattedDate
    this.css = dateCss

    return this
  }

  /**
   * @param {number} userId
   * @param {number} avatarTag
   * @param [value]
   */
  addTypeUserWithAvatar (userId, avatarTag = 0, value = null) {
    this.type = 'userWithAvatar'
    this.userId = userId
    this.avatarTag = avatarTag
    this.value = value || this.value

    return this
  }

  addTypeUrl (url = '') {
    this.type = 'url'
    this.url = url

    return this
  }

  addTypeNumber (min, max, precision = 0) {
    this.type = 'number'
    this.size = 'mini'
    if (min) { this.min = min }
    if (max) { this.max = max }
    if (precision >= 0) { this.precision = precision }

    return this
  }

  addTypeDownload (url = '') {
    this.type = 'download'
    this.url = url

    return this
  }

  addCut () {
    this.useCut = true

    return this
  }

  /**
   * Эмитит onCellClick событие вместо onRowClick
   * @returns {MTableCell}
   */
  addPreventDefault () {
    this.usePreventDefault = true

    return this
  }

  addInvisibility () {
    this.drawable = false

    return this
  }
}
