var render = function render(){var _vm=this,_c=_vm._self._c;return _c('validation-wrapper',{staticClass:"m-input",class:{
    'm-input-wrapper__has-prefix': _vm.$slots.prefix,
    'm-input-wrapper__has-suffix': _vm.$slots.suffix,
    'w-100': _vm.fullWidth
  },attrs:{"m-fixed-height":_vm.mFixedHeight,"errors":_vm.validationErrors}},[(_vm.isTextType && _vm.mask)?_c('el-input',{directives:[{name:"mask",rawName:"v-mask",value:(_vm.mask),expression:"mask"}],ref:"input",staticClass:"m-input",class:{
      'w-100': _vm.fullWidth
    },attrs:{"id":_vm.id,"type":_vm.type,"value":_vm.value,"clearable":_vm.clearable,"disabled":_vm.canManage.disabled,"placeholder":_vm.placeholder,"label":_vm.label,"minlength":_vm.minLength,"maxlength":_vm.maxLength,"show-word-limit":_vm.showWordLimit,"size":_vm.size,"readonly":_vm.readonly},on:{"input":_vm.onInput,"change":_vm.onChange,"clear":_vm.onClear,"focus":function($event){_vm.focused = true},"blur":function($event){_vm.focused = false; _vm.$emit('blur', $event); _vm.__validate()},"click":function($event){return _vm.$emit('click', $event)}},nativeOn:{"keydown":function($event){_vm.useNumbersOnly && _vm.onKeydown($event)}},scopedSlots:_vm._u([{key:"prefix",fn:function(){return [_vm._t("prefix")]},proxy:true},{key:"suffix",fn:function(){return [_vm._t("suffix")]},proxy:true},{key:"prepend",fn:function(){return [_vm._t("prepend")]},proxy:true},{key:"append",fn:function(){return [_vm._t("append")]},proxy:true}],null,true)}):(_vm.isTextType)?_c('el-input',{ref:"input",staticClass:"m-input",class:{
      'w-100': _vm.fullWidth
    },attrs:{"id":_vm.id,"type":_vm.type,"value":_vm.value,"clearable":_vm.clearable,"disabled":_vm.canManage.disabled,"placeholder":_vm.placeholder,"label":_vm.label,"minlength":_vm.minLength,"maxlength":_vm.maxLength,"show-word-limit":_vm.showWordLimit,"size":_vm.size,"readonly":_vm.readonly},on:{"input":_vm.onInput,"change":_vm.onChange,"clear":_vm.onClear,"focus":function($event){_vm.focused = true},"blur":function($event){_vm.focused = false; _vm.$emit('blur', $event); _vm.__validate()},"click":function($event){return _vm.$emit('click', $event)}},nativeOn:{"keydown":function($event){_vm.useNumbersOnly && _vm.onKeydown($event)}},scopedSlots:_vm._u([{key:"prefix",fn:function(){return [_vm._t("prefix")]},proxy:true},{key:"suffix",fn:function(){return [_vm._t("suffix")]},proxy:true},{key:"prepend",fn:function(){return [_vm._t("prepend")]},proxy:true},{key:"append",fn:function(){return [_vm._t("append")]},proxy:true}],null,true)}):_c('div',{staticClass:"m-input-wrapper"},[_c('div',{staticClass:"m-input-wrapper__prefix"},[_vm._t("prefix")],2),_vm._v(" "),_c('div',{staticClass:"m-input-wrapper__suffix"},[_vm._t("suffix")],2),_vm._v(" "),_c('el-input-number',{ref:"input",staticClass:"m-input",class:{
        'w-100': _vm.fullWidth
      },attrs:{"id":_vm.id,"value":_vm.value,"clearable":_vm.clearable,"disabled":_vm.canManage.disabled,"placeholder":_vm.placeholder,"label":_vm.label,"controls":_vm.controls,"precision":_vm.precision,"min":_vm.min,"max":_vm.max,"size":_vm.size,"controls-position":"right"},on:{"input":_vm.onInput,"change":_vm.onChange,"focus":function($event){_vm.focused = true},"blur":function($event){_vm.focused = false; _vm.$emit('blur', $event); _vm.__validate()},"clear":_vm.onClear,"click":function($event){return _vm.$emit('click', $event)}},nativeOn:{"keydown":function($event){return _vm.onKeydown.apply(null, arguments)},"keyup":function($event){return _vm.onKeyup.apply(null, arguments)}}})],1),_vm._v(" "),(_vm.label)?_c('m-label',{attrs:{"id":_vm.id,"label":_vm.label,"required":_vm.isRequiredField && !_vm.requiredWhenValue,"required-tooltip":_vm.requiredTooltip,"disabled":_vm.disabled,"focused":_vm.focused}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }