<template>
  <m-time-picker
    :start-time="semdEntity.value"
    :label="semdEntity.title"
    :m-fixed-height="false"
    @changeStartTime="onChange"
  />
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import MTimePicker from '@/vue_present/_base/inputs/MTimePicker/MTimePicker.vue'
import { SemdTimePicker } from '@/vue_apps/ProtocolsApp/_SemdProtocolEditor/_SemdEntities/SemdTimePicker/SemdTimePicker'

export default defineComponent({
  name: 'SemdTimePickerView',

  components: { MTimePicker },

  props: {
    semdEntity: {
      type: Object as PropType<SemdTimePicker>,
      required: true,
    },
  },

  emits: [
    'change',
  ],

  methods: {
    onChange (value) {
      this.$emit('change', {
        semdEntity: this.semdEntity,
        newValue: value,
      })
    },
  },
})
</script>
