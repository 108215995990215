<template>
  <m-select-lazy
    :label="t('mkb-10Codes')"
    :fetch-method="(params) => api.fetch(params)"
    :additional-displayed-filter="additionalDisplayedFilter"
    :option-label="optionLabel"
    :option-notice="optionNotice"
    :multiple="multiple"
    :disabled="disabled"
    use-keep-search-query
    filterable
    v-bind="$attrs"
    v-on="$listeners"
    @registerApi="mSelectLazyRegisterApi = $event"
  />
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import MSelectLazy from '@/vue_present/_base/MSelectLazy/MSelectLazy.vue'
import { MKB10Api } from '@/vue_present/Reuse/Lists/MKB10/api/MKB10Api'
import { IMKB10Item } from '@/vue_present/Reuse/Lists/MKB10/api/interfaces/IMKB10Item'
import { DisabledMixin } from '@/vue_present/mixins/DisabledMixin'

export default defineComponent({
  name: 'ReusableMKB10',

  components: { MSelectLazy },

  mixins: [DisabledMixin],

  props: {
    optionLabel: { type: String, default: 'title' },
    optionNotice: { type: String, default: 'codeString' },
    multiple: Boolean,
  },

  data () {
    return {
      api: new MKB10Api(),
      mSelectLazyRegisterApi: null,
    }
  },

  watch: {
    '$route' () {
      this.mSelectLazyRegisterApi.reset()
    },
  },

  methods: {
    additionalDisplayedFilter (searchQuery: string, item: IMKB10Item) {
      const searchForAttribute = this.optionLabel === 'title'
        ? 'codeString'
        : 'title'
      const itemAttribute = item[searchForAttribute].toLowerCase()

      return itemAttribute.includes(searchQuery)
    },
  },
})
</script>
