import { IPrintArea, TPrintAreaId } from '@/vue_apps/ProtocolsApp/entities/printAreas/IPrintArea'

export class PrintArea implements IPrintArea {
  id: TPrintAreaId

  title: string

  checked: boolean = false

  disabled: boolean = false

  constructor ({ id, title }: { id: TPrintAreaId; title: string }) {
    this.id = id
    this.title = title
  }

  setChecked (value: boolean = true): void {
    this.checked = value
  }
}
