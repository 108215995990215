<template>
  <div class="semd-protocol-editor">
    <div class="flex gap-indent-small align-center">
      <semd-template-record-watcher
        :is-template-records="isTemplateRecords"
        :semd-type="semdType"
        :selected-semd-entities="selectedSemdEntities"
        @semdTypeChange="setSemdType"
      />

      <template v-if="semdType">
        <semd-entity-select-wrapper
          :semd-type="semdType"
          :selected-semd-entities="selectedSemdEntities"
          :semd-entities="semdEntities"
          :form-builder-redux-store="formBuilderReduxStore"
          :editor="editor"
          :is-template-records="isTemplateRecords"
          @clear="onClear"
        />

        <semd-entities-warning
          :semd-entities="semdEntities"
        />
      </template>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import { SEMD_TYPE_ENUM, SEMD_TYPE_INVERTED_ENUM } from '@/vue_apps/Semds/const/semdTypes'
import { ISemdEntity } from '@/vue_apps/ProtocolsApp/_SemdProtocolEditor/interfaces/ISemdEntity'
import { getSemdEntitiesBySemdType } from '@/vue_apps/ProtocolsApp/_SemdProtocolEditor/const/getSemdEntitiesBySemdType'
import SemdEntitiesWarning
  from '@/vue_apps/ProtocolsApp/_SemdProtocolEditor/components/SemdEntitySelectWrapper/SemdEntitiesWarning.vue'
import SemdEntitySelectWrapper from '@/vue_apps/ProtocolsApp/_SemdProtocolEditor/components/SemdEntitySelectWrapper.vue'
import SemdTemplateRecordWatcher
  from '@/vue_apps/ProtocolsApp/_SemdProtocolEditor/components/SemdTemplateRecordWatcher.vue'
import { FormBuilderReduxStore } from '@/vue_apps/ProtocolsApp/entities/reduxStore/FormBuilderReduxStore'
import { TTinyMCEEditor } from '@/vue_apps/ProtocolsApp/_SemdProtocolEditor/interfaces/TTinyMCE'
import { SEMD_ENTITIES_ACTIONS } from '@/vue_apps/ProtocolsApp/_SemdProtocolEditor/reducers/semdEntitiesActions'
import { dropSemdAreasColor } from '@/vue_apps/ProtocolsApp/_SemdProtocolEditor/const/dropSemdAreasColor'
import { ProtocolApi } from '@/vue_apps/ProtocolsApp/entities/ProtocolApi'
import { onConfirmSemdLayoutClear } from '@/vue_apps/ProtocolsApp/logic/onConfirmSemdLayoutClear'

export default defineComponent({
  name: 'SemdProtocolEditorApp',

  components: {
    SemdTemplateRecordWatcher,
    SemdEntitySelectWrapper,
    SemdEntitiesWarning,
  },

  props: {
    editor: {
      type: Object as PropType<TTinyMCEEditor>,
      required: true,
    },

    protocol: ProtocolApi,
  },

  data () {
    return {
      semdType: null as keyof typeof SEMD_TYPE_ENUM,
      semdEntities: [] as ISemdEntity[],

      formBuilderReduxStore: null as FormBuilderReduxStore,
      protocolWatcher: null as Function,
      selectedSemdEntitiesWatcher: null as Function,
    }
  },

  computed: {
    isTemplateRecords () {
      return gon.page.controller === 'template_records'
    },

    selectedSemdEntities () {
      return this.semdEntities
        .filter((entity) => entity.element && !entity.disabled)
    },
  },

  created () {
    this.resetStore()

    this.$pubSub.subscribe(
      'ProtocolEditorView:onSemdTypeChange',
      (semdType) => this.setSemdType(semdType)
    )
  },

  beforeDestroy () {
    this.$pubSub.unsubscribeAll('ProtocolEditorView:onSemdTypeChange')

    if (this.protocolWatcher) {
      this.protocolWatcher()
      this.selectedSemdEntitiesWatcher()
    }
  },

  mounted () {
    if (this.protocol) {
      this.setSemdType(SEMD_TYPE_INVERTED_ENUM[this.protocol.semdType])
      this.setProtocolSemdTypeWatchers()
    }

    setTimeout(() => { this.init() })
  },

  methods: {
    setProtocolSemdTypeWatchers () {
      this.protocolWatcher = this.$watch('protocol.semdType', async () => {
        this.setSemdType(SEMD_TYPE_INVERTED_ENUM[this.protocol.semdType])
      })

      this.selectedSemdEntitiesWatcher = this.$watch('selectedSemdEntities', (to: ISemdEntity[]) => {
        this.protocol.setValue('semdEntities', to)
      })
    },

    init () {
      this.linkWithExists()
      setTimeout(() => { this.syncWithStore() })
    },

    setSemdType (semdType: keyof typeof SEMD_TYPE_ENUM = null) {
      dropSemdAreasColor(this.editor)
      this.onClear(true)
      this.semdType = semdType
      this.fillSemdEntities()
      this.resetStore()
    },

    fillSemdEntities () {
      this.semdEntities = getSemdEntitiesBySemdType(this.semdType)
    },

    resetStore () {
      this.formBuilderReduxStore = new FormBuilderReduxStore()
    },

    linkWithExists () {
      this.semdEntities
        .filter((semdEntity) => !semdEntity.disabled)
        .forEach((semdEntity) => semdEntity.linkWithElement(this.editor))
    },

    syncWithStore () {
      this.selectedSemdEntities.forEach((semdEntities) => {
        this.formBuilderReduxStore.dispatch(
          SEMD_ENTITIES_ACTIONS.SET_VALUE,
          { field: semdEntities.id, value: semdEntities.value || true }
        )
      })
    },

    async onClear (skipConfirm = false) {
      if (!skipConfirm) {
        const { cancel } = await onConfirmSemdLayoutClear()
        if (cancel) { return }
      }

      this.selectedSemdEntities.forEach((semdEntity) => {
        semdEntity.destroy(this.editor)
      })

      this.formBuilderReduxStore.dispatch(SEMD_ENTITIES_ACTIONS.CLEAR)
    },
  },
})
</script>
