<template>
  <div class="acquiring-terminal-connection">
    <!-- Проверка соединения -->
    <m-button
      v-tooltip="t$('checkConnection')"
      class="min-content"
      :type="type"
      icon="signalStream"
      :disabled="checkingConnectionDisabled"
      @click="onCheckingConnection()"
    />

    <m-icon
      v-tooltip="connectionStatus.tooltip"
      :icon="connectionStatus.icon"
      :class="connectionStatus.cssClass"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import MButton from '@/vue_present/_base/buttons/MButton/MButton.vue'
import { i18nScopeMixin } from '@/vue_present/mixins/i18nScopeMixin'
import MIcon from '@/vue_present/_base/MIcon/MIcon.vue'
import { AcquiringApi } from '@/vue_apps/FinanceModule/FinanceModuleIndex/classes/Acquiring/AcquiringApi'
import { AcquiringTerminalApi } from '@/vue_apps/catalogs_root/Workplaces/classes/AcquiringTerminal/AcquiringTerminalApi'
import { CONNECTION_STATUS_ICON } from '@/vue_present/Reuse/AcquiringTerminalConnection/consts/connectionStatusIcon'
import {
  ACQUIRING_TERMINAL_CONNECTION_STATUS,
} from '@/vue_apps/FinanceModule/FinanceModuleIndex/consts/Acquiring/AcquiringTerminalConnectionStatus'

export default defineComponent({
  name: 'AcquiringTerminalConnection',

  components: {
    MIcon,
    MButton,
  },

  mixins: [i18nScopeMixin],

  props: {
    acquiring: { type: Object as PropType<AcquiringApi | AcquiringTerminalApi>, required: true },
    type: { type: String as PropType<'primary' | 'warning'>, default: 'warning' },
  },

  computed: {
    connectionStatus () {
      const connectionStatus = this.acquiring.connectionStatus || ACQUIRING_TERMINAL_CONNECTION_STATUS.NOT_CONNECTED

      return CONNECTION_STATUS_ICON[connectionStatus]
    },

    hasRequestInAwaitList () {
      return Boolean(Object.keys(this.acquiring.awaitRequestList).length)
    },

    checkingConnectionDisabled () {
      return this.acquiring.isConnectionStatusNotConnectedOrConnecting() ||
          this.hasRequestInAwaitList || Boolean(this.acquiring.paymentType)
    },
  },

  created () {
    this.setI18nScope('finance')
  },

  methods: {
    onCheckingConnection () {
      this.acquiring.setConnectionStatus(ACQUIRING_TERMINAL_CONNECTION_STATUS.CONNECTING)
      this.acquiring.checkingConnection()
    },
  },
})
</script>
