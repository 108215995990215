import { createVueApp } from '@/vue_components/create_vue_app'
import ClinicalGuidelinesApp from '@/vue_apps/ClinicalGuidelines/ClinicalGuidelinesApp.vue'

let vueApp = null

export const createClinicalGuidelinesApp = (el = '#vue_clinical_guidelines_app_root') => {
  if (vueApp?.$destroy) { vueApp.$destroy() }

  vueApp = createVueApp({
    el,
    name: 'ClinicalGuidelinesApp',
    render: (h) => h(ClinicalGuidelinesApp),
  })
}
