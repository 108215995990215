import { IPaymentToClientDTO } from '@/vue_apps/FinanceModule/FinanceModuleIndex/interfaces/IPaymentToClientDTO'
import { PaymentEntryItem } from '@/vue_apps/FinanceModule/FinanceModuleIndex/classes/PaymentEntryItem'
import { IPaymentToServerDTO } from '@/vue_apps/FinanceModule/FinanceModuleIndex/interfaces/IPaymentToServerDTO'
import { PaymentModel } from '@/vue_apps/FinanceModule/FinanceModuleIndex/classes/Payment/PaymentModel'
import {
  TPaymentDestinationOrderUser,
} from '@/vue_apps/FinanceModule/FinanceModuleIndex/interfaces/TPaymentDestinationOrderUser'
import {
  IPaymentDestinationOrder,
} from '@/vue_apps/FinanceModule/FinanceModuleIndex/interfaces/IPaymentDestinationOrder'
import { IPaymentPayerCompany } from '@/vue_apps/FinanceModule/FinanceModuleIndex/interfaces/IPaymentPayerCompany'
import { IPaymentPayerClient } from '@/vue_apps/FinanceModule/FinanceModuleIndex/interfaces/IPaymentPayerClient'
import { IPaymentRefundable } from '@/vue_apps/FinanceModule/FinanceModuleIndex/interfaces/IPaymentRefundable'
import {
  PAYMENT_ENTRY_MEMBER_IS_COMPLEX_MEMBER,
} from '@/vue_apps/FinanceModule/FinanceModuleIndex/consts/paymentEntryMemberTypes'
import {
  PaymentDistributorModel,
} from '@/vue_apps/FinanceModule/FinanceModuleIndex/classes/Payment/PaymentDistributorModel'
import {
  PaymentDistributorFundLogic,
} from '@/vue_apps/FinanceModule/FinanceModuleIndex/classes/Payment/PaymentDistributorFundLogic'
import {
  PaymentDistributorRefundLogic,
} from '@/vue_apps/FinanceModule/FinanceModuleIndex/classes/Payment/PaymentDistributorRefundLogic'

export const paymentAdapter = {
  toClient (data: IPaymentToClientDTO) {
    return {
      ...data,

      destinationOrder: getDestinationOrder(data.destinationOrder),
      payerCompany: getPayerCompany(data.payerCompany),
      payerClient: getPayerClient(data.payerClient),

      byCash: +data.byCash || 0,
      byCashless: +data.byCashless || 0,
      byCard: +data.byCard || 0,
      byBalance: +data.byBalance || 0,
      byCredit: +data.byCredit || 0,
      deposit: +data.deposit || 0,
      totalIncome: +data.totalIncome || 0,
      totalPaid: +data.totalPaid || 0,
      surrender: +data.surrender || 0,

      comment: gon.page.action === 'new'
        ? ''
        : data.comment,

      createdBy: `${data.user.shortName} ${Utils.getFormattedDate(data.createdAt, Utils.momentDateTimeFormat)}`,
      paymentEntries: data.paymentEntries.map((item) => new PaymentEntryItem(item, data.kind)),
      refundable: getRefundable(data.refundable),
    }
  },

  toServer (payload: PaymentModel<PaymentDistributorFundLogic | PaymentDistributorRefundLogic> & PaymentDistributorModel): IPaymentToServerDTO {
    return {
      kind: payload.kind,
      distribution: payload.distribution,
      destinationOrderId: payload.destinationOrder?.id,
      destinationClientId: payload.destinationClient?.id || payload.payerClient?.id,
      payerCompanyId: payload.payerCompany?.id,
      payerClientId: payload.payerClient?.id,
      byCash: payload.byCash,
      byCashless: payload.byCashless,
      byCard: payload.byCard,
      byBalance: payload.byBalance,
      byCredit: payload.byCredit,
      comment: payload.comment,
      baseKind: payload.baseKind,
      surrender: payload.surrender,
      deposit: payload.deposit,
      totalIncome: payload.totalIncome,
      totalPaid: payload.totalPaid,
      refundableId: payload.refundable?.id,
      clinicId: payload.clinic?.id,
      userId: payload.user?.id,
      paymentEntriesAttributes: getPaymentEntriesAttributes(payload.paymentEntries),
    }
  },
}

export const extractEntryData = (entry: PaymentEntryItem) => ({
  entryId: entry.entryId || entry.id,
  sum: entry.sum,
  kind: entry.kind,
  baseKind: entry.baseKind,
  member: entry.member ?? PAYMENT_ENTRY_MEMBER_IS_COMPLEX_MEMBER,
})

export const getPaymentEntriesAttributes = (paymentEntries: PaymentEntryItem[]) =>
  paymentEntries.flatMap((entry) => {
    const extractedEntryData = extractEntryData(entry)

    return entry.members.length
      ? [
          extractedEntryData,
          ...getPaymentEntriesAttributes(entry.members),
        ]
      : extractedEntryData
  })

export const getDestinationOrder = (destinationOrder: IPaymentDestinationOrder) => {
  if (!destinationOrder) {
    return destinationOrder
  }

  return {
    ...destinationOrder,

    assistants: destinationOrder.assistants.map((assistant: TPaymentDestinationOrderUser) => ({
      ...assistant,
      link: Routes.user_path(assistant.id),
    })),

    date: Utils.getFormattedDate(destinationOrder.date),
    finalSum: +destinationOrder.finalSum || 0,
    paidSum: +destinationOrder.paidSum || 0,
  }
}

export const getPayerCompany = (payerCompany: IPaymentPayerCompany) => {
  if (!payerCompany) {
    return payerCompany
  }

  return {
    ...payerCompany,
    finance: +payerCompany.finance || 0,
    companyFinance: {
      id: payerCompany.companyFinance.id,
      creditDebt: +payerCompany.companyFinance.creditDebt,
    },
  }
}

export const getPayerClient = (payerClient: IPaymentPayerClient) => {
  if (!payerClient) {
    return payerClient
  }

  return {
    ...payerClient,
    finance: +payerClient.finance || 0,
    creditDebt: +payerClient.creditDebt || 0,
  }
}

export const getRefundable = (refundable: IPaymentRefundable) => {
  if (!refundable) {
    return refundable
  }

  return {
    id: +refundable.id || 0,
    totalPaid: +refundable.totalPaid,
  }
}
