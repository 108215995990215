import { SEMD_AREA_INTERNALS_ATTR } from '@/vue_apps/ProtocolsApp/consts/const'

/**
 * @param {TTinyMCEEditor} editor
 */
export const dropSemdAreasColor = (editor) => {
  $(editor.getBody())
    .find(`[${SEMD_AREA_INTERNALS_ATTR}]`)
    .removeAttr(SEMD_AREA_INTERNALS_ATTR)
}
