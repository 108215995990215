import Entity from './Entity'
import { el, mount } from 'redom'
import { DIAGNOSE_ACTIONS } from '@/plugins/dynamic_forms/actions/entityActions'
import Container from '@/plugins/dynamic_forms/_core/container/Container'
import { PROTOCOL_BUILDER_NAME } from '@/vue_apps/ProtocolsApp/consts/const'
import ContextMenu from '@/plugins/dynamic_forms/components/context_menu/base'
import { IDiagnoseData } from '@/plugins/dynamic_forms/declarations/IDiagnoseData'
import { MConfirm } from '@/vue_present/_base/MConfirm/MConfirm'

class Diagnose extends Entity {
  static buildTemplate (data, options) {
    let template = super.buildTemplate(data, options)
    template += '<br>'

    return template
  }

  //create html component entity
  static createElement (data, options) {
    const template = this.buildTemplate(data, options)
    $(template).insertBefore(data.emitter)
  }

  contextMenu: ContextMenu

  constructor (id: number, element: HTMLElement, options: Record<string, any>, container: Container) {
    super(id, element, options, container)

    if (container._components.builderName === PROTOCOL_BUILDER_NAME) {
      element.oncontextmenu = (e: MouseEvent) => {
        e.preventDefault()
        Services.pubSub.emit('ProtocolPopoverConnector:Activate', this)
      }
    }
  }

  edit () {
    console.debug('herabora')
  }

  /**
   * redefined
   */
  // @ts-ignore
  update (formData) {
    if (!formData) {
      return
    }

    this.store.dispatch({
      type: this.actions.UPDATE,
      payload: {
        id: this.id,
        data: formData,
      },
    })

    this._updateElement()
  }

  setValue (newValue) {
    this.update(newValue)
  }

  showValue () {
    this._updateElement()
  }

  _updateElement () {
    this.contextMenu = this.container.get('contextMenu')
    const value = this.data.value
    const first = value.first ? t('set_first') : null
    const chronical = value.chronical ? ` ${t('chronical')}` : null
    const prof = value.prof ? ` ${t('professional')}` : null
    const item = this.data.item
    const props = [first, chronical, prof].filter((prop) => prop != null)
    const propsString = props.length ? `/ ${props.join(' / ')} /` : ''

    const text = `
      <span class="entry_template_diagnosis_code">${item.code_string}</span>: ${item.title} 
      <i class="entry_template_diagnosis_props">${propsString}</i>
      <i>${this.value.text || ''}</i>
    `
    this.element.innerHTML = text
    this.element.setAttribute('contenteditable', 'false')
    const editBtn = el('i.tree-icon.diagnosis-btn.fa-border.fa.fa-pencil.control')
    const deleteBtn = el('i.tree-icon.diagnosis-btn.fa-border.fad.fa-trash-alt.control')

    deleteBtn.onclick = async (e) => {
      e.preventDefault()
      e.stopPropagation()
      const { cancel } = await MConfirm.warning(`${t('delete')}?`)
      if (cancel) { return }

      const data = this.data
      const nested = $('#nested-diagnoses .disease_id')
        .filter(() => parseInt(this.value) === data.item.id)
      nested.parents('.diagnosis_item_data').find('.destroy-field').addClass('destroy-item')

      // Специально оставляю это для ориентирования
      // После завершения заполнения происходит очистка ненужных полей
      // Я сделал так, чтобы эта очистка происходила сразу
      // const dispatcher = this.container.get('dispatcher')
      // const deleteEntity = new DeleteEntity(this.element)
      // dispatcher.dispatch(deleteEntity)

      // удаление элемента из редактора
      $(this.element).remove()
      // удаление инпута с айдишником
      nested.find('.destroy-item').remove()

      const store = this.container._components.store
      store.dispatch({
        type: DIAGNOSE_ACTIONS.DELETE,
        payload: data.id,
      })

      Services.pubSub.emit('ProtocolEditorView:onTemplateHtmlChange')
    }

    editBtn.style.marginLeft = '3px'
    deleteBtn.style.marginLeft = '3px'

    mount(this.element, deleteBtn)
  }

  /**
   * inherit
   */

  elementHead (showTypes) {
    this.element.innerHTML = showTypes
      ? `${this.data.name}: ${this.data.item.title}`
      : this.data.name
  }

  // eslint-disable-next-line
  get switch () {
    return false
  }

  get data (): IDiagnoseData {
    return super.data as IDiagnoseData
  }
}
export default Diagnose
