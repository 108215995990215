import { IPrintArea, TPrintAreaId } from '@/vue_apps/ProtocolsApp/entities/printAreas/IPrintArea'

export class PrintAreaWholeDocument implements IPrintArea {
  static readonly WHOLE_DOCUMENT_ID = 'wholeDocument'

  id: TPrintAreaId = PrintAreaWholeDocument.WHOLE_DOCUMENT_ID

  title: string = t('whole_document')

  checked: boolean = true

  disabled: boolean = true

  setChecked (value: boolean = true): void {
    this.checked = value
    this.disabled = this.checked
  }
}
