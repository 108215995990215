import {
  AcquiringTerminalLogic,
} from '@/vue_apps/catalogs_root/Workplaces/classes/AcquiringTerminal/AcquiringTerminalLogic'
import { Api } from '@/_api/decorators/api/Api'
import { Callback } from '@/_api/decorators/callback'
import { IAcquiringWSMeta } from '@/vue_apps/FinanceModule/FinanceModuleIndex/interfaces/Acquiring/IAcquiringWSMeta'
import { ACQUIRING_OPERATION } from '@/vue_apps/FinanceModule/FinanceModuleIndex/consts/Acquiring/AcquiringOperation'
import { ICRUDReturn } from '@/_api/decorators/api/interfaces/ICRUDReturn'
import { ACQUIRING_REPORT_TYPE } from '@/vue_apps/catalogs_root/Workplaces/consts/acquiringReportType'
import { IAcquiringErrors } from '@/vue_apps/FinanceModule/FinanceModuleIndex/interfaces/Acquiring/IAcquiringErrors'

export class AcquiringTerminalApi extends AcquiringTerminalLogic {
  constructor () {
    super()
    this.subscribeToWS(this.actionHandler.bind(this), this.wsErrorsHandler.bind(this))
  }

  @Callback(function (data: IAcquiringWSMeta | IAcquiringErrors) {
    this.requestHandler(ACQUIRING_OPERATION.RECONCILIATION_OF_RESULTS, data)
  }, true)
  @Api.post(Routes.api_internal_acquiring_trade_operations_reconciliation_of_results_path)
  reconciliationOfResults () {
    return {
      payload: {
        workplaceId: this.workplaceId,
      },
    } as ICRUDReturn as Promise<void>
  }

  @Callback(function (data: IAcquiringWSMeta | IAcquiringErrors) {
    this.requestHandler(ACQUIRING_OPERATION.REPORT_FULL, data)
  }, true)
  @Api.post(Routes.api_internal_acquiring_trade_operations_report_path)
  reportFull () {
    return {
      payload: {
        reportType: ACQUIRING_REPORT_TYPE.FULL,
        workplaceId: this.workplaceId,
      },
    } as ICRUDReturn as Promise<void>
  }

  @Callback(function (data: IAcquiringWSMeta | IAcquiringErrors) {
    this.requestHandler(ACQUIRING_OPERATION.REPORT_SUMMARY, data)
  }, true)
  @Api.post(Routes.api_internal_acquiring_trade_operations_report_path)
  reportSummary () {
    return {
      payload: {
        reportType: ACQUIRING_REPORT_TYPE.SUMMARY,
        workplaceId: this.workplaceId,
      },
    } as ICRUDReturn as Promise<void>
  }

  @Callback(function (data: IAcquiringWSMeta | IAcquiringErrors) {
    this.requestHandler(ACQUIRING_OPERATION.COPY_OF_RECEIPT, data)
  }, true)
  @Api.post(Routes.api_internal_acquiring_trade_operations_copy_of_receipt_path)
  copyOfReceipt () {
    return {
      payload: {
        lastReceipt: false,
        workplaceId: this.workplaceId,
      },
    } as ICRUDReturn as Promise<void>
  }
}
